import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonHomeIndividual = (props) => {

    return (
        <SkeletonTheme color="#d8d3d3" highlightColor="#e8e8e8">
            <section className="individual-header" id="individual-home">
                <div className="individual-header-container d-flex flex-nowrap py-5 ">
                    <div className="col-lg-6">
                        <Skeleton count={2} height={60} width={500} />
                    </div>
                    <div className="col-lg-6 d-flex justify-content-end align-items-end">
                        <Skeleton className="individual-header-image" circle={true} height={400} width={400} />
                    </div>
                </div>
            </section>

            <section className="individual-books">
                <div className=" container-individual-books" id="container-individual-books">
                    {((i) => {
                        const libroIndividual = [];
                        for (let i = 0; i < 4; i++) {
                            libroIndividual.push(
                                <div key={i} className="individual-book">
                                    <div className={`individual-book-cover mb-3`} data-state="close">
                                        <Skeleton height={500}/>
                                    </div>
                                    <Skeleton height={80}/>
                                </div>
                            )
                        }
                        return libroIndividual;
                    })()}
                </div>
            </section>
        </SkeletonTheme>
    )
}

export default SkeletonHomeIndividual;
