/**
 * Objeto que se usa para ordenar los resultados de búsqueda, se pasa el método deseado al API de búsqueda junto a los filtros.
 */
export const OrdenamientoBusqueda = [
    {
        id: 'az',
        ordenamiento: {
            tipo:'title',
            translationId:'orden-alfabetico',
            modo:'ASC'
        },
        name: {
            defaultValue: 'Alfabético:A a la Z',
            translationId: 'az'
        }
    },
    {
        id: 'za',
        ordenamiento: {
            tipo:'title',
            translationId:'orden-alfabetico',
            modo:'DESC'
        },
        name: {
            defaultValue: 'Alfabético:Z a la A',
            translationId: 'za'
        }
    },
    {
        id: 'mayor-menor',
        ordenamiento: {
            tipo:'duracion',
            translationId:'tiempo-lectura',
            modo:'DESC'
        },
        name: {
            defaultValue: 'Tiempo de lectura:Mayor a menor',
            translationId: 'mayor-menor'
        }
    },
    {
        id: 'menor-mayor',
        ordenamiento: {
            tipo:'duracion',
            translationId:'tiempo-lectura',
            modo:'ASC'
        },
        name: {
            defaultValue: 'Tiempo de lectura:Menor a mayor',
            translationId: 'menor-mayor'
        }
    },
]
