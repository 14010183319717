import React from "react";
import { useState } from "react";
import { useEffect } from "react";

/**
 * Este hook verifica cada cierto tiempo si el cliente está utilizando Classics en modo aplicación, es decir cuando el cliente ya instaló MakeMake en su dispositivo y está desde la aplicación que instaló y no en el navegador.
 * @returns {boolean} retorna true o false si está en modo aplicación.
 */
export const useModoAplicacion = ()=>{
    const [modoAplicacion, setModoAplicacion]= useState(false);

    const esAplicacion = ()=>{
        if (window.navigator.standalone) return true

        // For Android
        if (window.matchMedia('(display-mode: standalone)').matches) return true

        // If neither is true, it's not installed
        return false
    }

    useEffect(()=>{
        const intervalVerificacion = setInterval(()=>{
         setModoAplicacion(esAplicacion());
        },15 * 1000)

        return ()=>{
            clearInterval(intervalVerificacion);
        }
    },[])

    return modoAplicacion;
}