import MicroModal from 'micromodal';

/**
 * Este hook se usa para mostrar u ocultar modales con la librería MicroModal.
 * 
 * @returns {{setVisible:Function}}
 */
export const useModalManager = ()=>{
          /**
     * función para mostrar u ocultar un modal con base a su id.
     * @param {string} modalId - Id del modal que se quiere mostrar u ocultar.
     * @param {boolean} visible - estado booleano de visibilidad.
     */
    const setVisible = (modalId, visible)=>{
        try {
           if(visible){
            MicroModal.show(modalId, {
                awaitCloseAnimation: true
              });
           }else{
            MicroModal.close(modalId, {
                awaitCloseAnimation: true
              });
           }
        } catch (error) {
            console.log('Error al intentar mostrar u ocultar el modal con ID:', modalId);
        }
    }
    return{
          /**
     * función para mostrar u ocultar un modal con base a su id.
     * @param {string} modalId - Id del modal que se quiere mostrar u ocultar.
     * @param {boolean} visible - estado booleano de visibilidad.
     */
        setVisible
    }
}