
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Este servicio envía una solicitud de demo a makemake.
 * @param {{abortSignal:AbortSignal, data:{
      fullName:string,
      email:string,
      message:string
 * }}}
 * @returns {Promise<boolean>} retorna true si se envió correctamente la solicitud de demo.
 */
const solicitarDemo = async ({ abortSignal = null, data }) => {
  try {
    axios.defaults.baseURL = 'https://makemake.com.co/assets/';
    axios.defaults.withCredentials = true;

    const fullData = {
      nombreP: data?.fullName || 'N/A',
      correoP: data?.email || 'N/A',
      textoOtroP: data?.message || 'N/A',
    }

    const response = await axios({
      method: 'POST',
      url: `quieroDemo/enviarCorreoDemoClever`,
      signal: abortSignal || null,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data:new URLSearchParams(fullData)
    })
    const datos = response.data;
    if (datos === 'Error') {
      return new Error(datos);
    }
    return true;

  } catch (error) {
    return Promise.reject(error);
  }

}

export const DemoCleverAPI = {
  solicitarDemo,
}