import React, { useState } from "react";

import { SearchFormBlog } from "./components/SearchFormBlog";
import { Newsletter } from "../que-es/home/components/newsletter";
import { Posts } from "./components/Posts";

import { useSearchPost } from "./hooks/use-search-post";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";

import { Helmet } from "react-helmet";

/**
 * Este componente no recibe props. Las funciones principales son obtener la información de las entradas según los apartados sch, date y tag.
 * Este renderiza la página principal de blog y hace uso de los componentes SearchFormBlog, Posts y Newsletter
 * @returns {JSX.Element} Blog
 */
const Blog = () => {
  const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
  const textosBlog = useTranslations({ section: "blog", lang: lang, array: false });


  const [options, setOptions] = useState({
    sch: "",
    date: {},
    tag: ""
  });

  const posts = useSearchPost(options);

  return (
    <>
      <Helmet>
        <title>{textosBlog?.metadata?.title || 'MakeMake - MakeBlog'}</title>
        <meta name="description" content={textosBlog?.metadata?.description || 'Complementa tu experiencia de lectura con el MakeBlog, encontrarás actividades, reseñas de libros y mucho más.'} />
      </Helmet>

      <div className="makeBlog">
        <div className="titlePage  blog-bg">
          <div className="container h-100">
            <div className="row align-items-center h-100">
              <div className="col-12 col-lg-5">
                <h1 className="text-title onsecondary--color">Makeblog</h1>
              </div>
              <div className="col-12 col-lg-4 onsecondary--color">
                <p>{textosBlog?.blog?.titulo || 'Actividades y reseñas de nuestros libros'}</p>
              </div>
            </div>
          </div>
        </div>


        <div className="blog-contenido">
          <div className="container blog-container">

            <SearchFormBlog
              textosBlog={textosBlog}
              setOptions={setOptions}
              options={options}
            />

            <div className="d-lg-flex flex-nowrap justify-content-center align-items-center mt-4">
              <h4 className="flex-grow onprimary--color secondary-font text-24 font-weight-bold">
                {options?.sch !== "" &&
                  <>
                    {textosBlog?.blog?.buscando_por || "Estas buscando por "}: <span className="green-inverse--color">{options?.sch}</span>
                  </>
                }

                {options?.tag !== "" &&
                  <>
                    {textosBlog?.blog?.buscando_por || "Estas buscando por "}: <span className="green-inverse--color">{options?.tag}</span>
                  </>
                }

                {options?.date?.value !== "" &&
                  <>
                    {textosBlog?.blog?.buscando_por || "Estas buscando por "}: <span className="green-inverse--color">{options?.date?.value}</span>
                  </>
                }


                {
                  options?.sch === "" && options?.tag === "" && options?.date?.value === "" &&
                  <>
                    {textosBlog?.blog?.mostrando || "Todas las actividades y reseñas"}
                  </>
                }

              </h4>
              <div className="d-none d-lg-block flex-grow-1 secondary-inverse--bg ml-4" style={{ height: 2 }}></div>

              {
                (options?.sch !== "" || options?.tag !== "" || options?.date?.value !== "") &&
                <button
                  className="btn btn-ver-entradas ml-lg-3 mt-4 mt-lg-0"
                  onClick={() => {
                    setOptions({
                      ...options,
                      sch: "",
                      date: {
                        value: "",
                        array: []
                      },
                      tag: ""
                    })
                  }}
                >
                  {textosBlog?.botones?.ver_entradas || 'Ver todas las entradas'}
                </button>
              }

            </div>

            <Posts resena={posts} textosBlog={textosBlog} />


          </div>

          <Newsletter />
        </div>
      </div>

    </>
  );
};

export default Blog;
