/**
 * Esta función lee una url y devuelve el valor de un query param si existe.
 * @param {string} parameter  - El nombre del query param
 * @param {*} defaultvalue  - El valor por defecto que se devuelve si no existe el query param
 * @returns {undefined | boolean | string}  - Retorna el valor del query param, o retorna falso o undefined si no existe el parametro.
 */
export default function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}

function getUrlVars() {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}