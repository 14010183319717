import { GENRES } from "./genres";
import { READING_LEVEL } from "./reading-level";
import { SUBJECTS } from "./subjects";

/**
 * Arreglo con todos los generos, materias y niveles de lectura que hay en MakeMake
 */
export const ADVANCED_SEARCH_ITEMS_DATA = [
    ...GENRES,
    ...SUBJECTS,
    ...READING_LEVEL
]