
/**
 * Verifica si una cadena de texto es un JSON válido.
 *
 * @param {string} stringValue - La cadena de texto a verificar.
 * @returns {boolean} Devuelve true si la cadena de texto es un JSON válido, de lo contrario, devuelve false.
 */
export const isJSON = (stringValue)=>{
    try {
      JSON.parse(stringValue); 
    } catch (error) {
        return false;
    }
    return true;
}
