import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';

import { Link } from "react-router-dom";
import { ASSETS_URL } from "./Store";

/**
 * Componente Libro, se utiliza en algunas partes de la aplicación, deberá ser reemplazado en su totalidad por 'Book' en el futuro.
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {number} props.alto - Altura del libro.
 * @param {Object} props.libro - Información del libro.
 * @param {string} props.portada - URL de la portada del libro.
 * @param {string} props.webUrl - URL del libro.
 * @param {boolean} props.conTitulo - Indica si se debe mostrar el título del libro.
 * @returns {JSX.Element} Componente de Libro.
 */
const Libro = (props) => {

    const { alto, libro, portada, webUrl, conTitulo } = props;
    const [height, setHeight] = useState(alto !== undefined ? alto : 250);
    const [imagen, setImagen] = useState(new Image());
    const [imagenCargada, setImagenCargada] = useState(false);
  

    useEffect(()=>{
        setHeight(imagenCargada===false ? "auto" : height);
    }, [imagenCargada])

    
  useEffect(() => {
    if (imagen) {
      imagen.onload = () => {
        setImagenCargada(true);
      }
      imagen.onerror = () => {
        setImagenCargada(false);
        imagen.src = `/img/no-imagen.png`;
      }
    }
    imagen.src = `${ASSETS_URL}${portada}`;
  }, [imagen, portada])




    return (
        <div className={'focusable-by-children-secondary'}>
        <Link aria-label={`${libro.nombreLibro || libro?.titulo || libro?.title || libro?.nombre || 'N/A'}`} className="img-fluid" src={imagen?.src} to={webUrl} >
            <div className="cover">
                <div className="relative cover-wrapper" style={{ height: height }}>
                    <img aria-hidden={true} alt={"Cover"} className="img-fluid" src={imagen?.src} />
                    {
                        imagenCargada===false && <Skeleton className="position-absolute col-12 skeleton-libro" />
                    }
                    {(() => {
                        if (parseInt(libro.gratis) === 1) {
                            return <img className="additionalIcon" src="/img/freeIcon.png" alt="icono adicional" />
                        } else if (parseInt(libro.gratis) === 0 && parseInt(libro.interactivo) === 1) {
                            return <img className="additionalIcon" src="/img/inticon.png" alt="icono adicional" />
                        } else if (parseInt(libro.gratis) === 0 && parseInt(libro.interactivo) === 0 && parseInt(libro.sonido) === 1) {
                            return <img className="additionalIcon" src='/img/soundIcon.png' alt="icono adicional" />
                        }
                    })()}
                </div>
                
                {
                    conTitulo && (
                        <p className="my-4 text-center">{libro.nombreLibro || libro?.titulo || libro?.title || libro?.nombre || 'N/A'}</p>
                    )
                }
            </div>
        </Link>
        </div>

    )
}

export default Libro;