import React, { useRef, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MicroModal from "micromodal";

import postAjax from "../js/utilities/postAjax";
import getAjax from "../js/utilities/getAjax";
import getUrlParam from "../js/utilities/getUrl";

import { GENERAL_URL_API, store } from "../components/Store";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";
import ModalAlertas from "../components/ModalAlertas";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";
import { Helmet } from "react-helmet";

/**
 * Este se encarga de renderizar la pantalla de login. Sus principales funciones son validar la información de formulario de Inicio de sesión y actualizar la información de la sesión activa en el localStorage.
 * @returns Login
 */
export const AdminLogin = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const textosLogin = useTranslations({ section: "login", lang: lang });

	const globalState = useContext(store);
	const { dispatch, state } = globalState;

	const [messageLogin, setMessageLogin] = useState("");
	const [sendingLogin, setSendingLogin] = useState(false);
	const [message, setMessage] = useState("");
	const [description, setDescription] = useState("");

	const loginForm = useRef(null);
	const userName = useRef(null);
	const password = useRef(null);

	const history = useHistory();

	useEffect(() => {
		window.scroll({ top: 0 });
	}, []);

	useEffect(() => {
		let ezproxy = "";
		if (typeof getUrlParam("ezproxy") !== "undefined") {
			ezproxy = decodeURIComponent(getUrlParam("ezproxy")).replace(/\+/g, " ");
			if (ezproxy !== "") {
				postAjax(`${GENERAL_URL_API}/assets/ezLogin`, { ip: ezproxy }, (response) => {
					if (response.includes("Exito")) {
						getAjax(`${GENERAL_URL_API}/assets/datosHeader`, {}, (response) => {
							let makemakeData = JSON.parse(response).makemakeData;
							let categoriasMenu = JSON.parse(response).categoriasMenu;
							window.localStorage.setItem(
								"makemakeData",
								JSON.stringify(makemakeData)
							);
							window.localStorage.setItem(
								"categoriasMenu",
								JSON.stringify(categoriasMenu)
							);
							dispatch({
								type: "CHANGE_VALUE",
								property: "pais",
								value: makemakeData.pais,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "sesionActiva",
								value: makemakeData.sesionActiva,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "userType",
								value: makemakeData.userType,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "username",
								value: makemakeData.username,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "datosHeader",
								value: makemakeData.datosHeader,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "categoriasMenu",
								value: categoriasMenu,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "tipo",
								value: makemakeData.tipo,
							});
                            history.replace('/home');
                            window.location.reload();
						});
					}
				});
			}
		}
	}, []);


	const iniciarSesion = (e) => {
		if (loginForm.current.checkValidity()) {
			e.preventDefault();
			setSendingLogin(true);
			postAjax(
				`${GENERAL_URL_API}/assets/login`,
				{ username: userName.current.value, password: password.current.value },
				(response) => {
					if (response.includes("Exito")) {
						getAjax(`${GENERAL_URL_API}/assets/datosHeader`, {}, (response) => {
							let makemakeData = JSON.parse(response).makemakeData;
							let categoriasMenu = JSON.parse(response).categoriasMenu;
							window.localStorage.setItem(
								"makemakeData",
								JSON.stringify(makemakeData)
							);
							window.localStorage.setItem(
								"categoriasMenu",
								JSON.stringify(categoriasMenu)
							);
							dispatch({
								type: "CHANGE_VALUE",
								property: "pais",
								value: makemakeData.pais,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "sesionActiva",
								value: makemakeData.sesionActiva,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "userType",
								value: makemakeData.userType,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "username",
								value: makemakeData.username,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "datosHeader",
								value: makemakeData.datosHeader,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "categoriasMenu",
								value: categoriasMenu,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "tipo",
								value: makemakeData?.tipo,
							});
							setSendingLogin(false);
						});
                        history.replace('/home');
                        window.location.reload();
					} else {
						setSendingLogin(false);
						if (response.includes("Error, usuario o contraseña")) {
							setMessageLogin(
								textosLogin?.mensaje_alerta?.login ||
									"Datos incorrectos. Intenta ingresar con tu institución"
							);
						} else if (response.includes("Error, concurrencias copadas")) {
							try {
								MicroModal.show("modal-alertas-autenticacion", {
									awaitCloseAnimation: true,
								});
							} catch (error) {
								console.log("modal alertas error", error);
							}
							setMessage(
								textosLogin?.mensaje_alerta?.limite?.mensaje ||
									"Has superado el límite de concurrencias"
							);
							setDescription(
								textosLogin?.mensaje_alerta?.limite?.descripcion ||
									"En este momento tienes más usuarios conectados de los que admite tu suscripción. Comunícate con ventas para aumentar la capacidad de usuarios simultáneos. Escríbenos a contacto@makemake.com.co"
							);
						} else if (response.includes("Error, suscripcion caducada")) {
							try {
								MicroModal.show("modal-alertas-autenticacion", {
									awaitCloseAnimation: true,
								});
							} catch (error) {
								console.log("modal alertas error", error);
							}

							setMessage(
								textosLogin?.mensaje_alerta?.caducado?.mensaje ||
									"Tu suscripción ha caducado"
							);
							setDescription(
								textosLogin?.mensaje_alerta?.caducado?.descripcion ||
									"Comunícate con ventas para renovar el período de la suscripción o escríbenos a contacto@makemake.com.co"
							);
						} else {
							console.error(response);
						}
					}
				}
			);
		}
	};

	return (
		<div className="login">
			<Helmet>
				<title>{textosLogin?.metadata?.title || "MakeMake - Iniciar sesión"}</title>
				<meta
					name="description"
					content={
						textosLogin?.metadata?.description ||
						"Empieza a leer en MakeMake. Inicia sesión o busca tu intitución."
					}
				/>
			</Helmet>

			<div className="img-login">
				<img src="/img/login/books-back.png" />
			</div>

			<div className="wrapper-login">
				<div className="content-login pb-3 position-relative">
					<h1 className="secondary-font onsecondary--color mb-3">
						{textosLogin?.titulos?.iniciar || "Iniciar sesión"}
					</h1>

					<div className="d-flex justify-content-center align-items-center my-4">
						<p
							className="text-center p-3 third-inverse--bg onsecondary-inverse--color font-weight-bold text-uppercase"
							style={{ borderRadius: ".5rem" }}
						>
							{lang === "es" ? "Administrador" : "Admin"}
						</p>
					</div>

					<div className="pb-3">
						<form ref={loginForm} id="loginctfId" name="loginctf" method="post">
							<strong>
								{(() => {
									if (messageLogin !== "") {
										return (
											<p
												className="text-center mb-3 onsecondary--color"
												role={"alert"}
												aria-live={"assertive"}
												dangerouslySetInnerHTML={{
													__html: messageLogin ? messageLogin : "",
												}}
											></p>
										);
									}
								})()}
							</strong>

							{/* usuario */}
							<fieldset>
								<label
									htmlFor="inputUser"
									className="mb-2 onsecondary--color font-weight-bold"
								>
									{textosLogin?.input_usuario?.usuario?.texto ||
										"Nombre de usuario"}
								</label>
								<input
									ref={userName}
									id="inputUser"
									onClick={() => {
										setMessageLogin("");
									}}
									autoComplete="username"
									type="text"
									className="form-control form-control-sm focusable-red mb-2"
									placeholder={
										textosLogin?.input_usuario?.usuario?.placeholder ||
										"Escribe tu nombre de usuario"
									}
									name="user"
									required
								/>
							</fieldset>

							{/*contraseña  */}
							<fieldset className="mt-3">
								<label
									htmlFor="inputPassword"
									className="mb-2 onsecondary--color font-weight-bold"
								>
									{textosLogin?.input_usuario?.contrasenia?.texto ||
										"Contraseña:"}
								</label>
								<input
									ref={password}
									autoComplete="off"
									type="password"
									className="form-control form-control-sm focusable-red mb-2"
									id="inputPassword"
									placeholder={
										textosLogin?.input_usuario?.contrasenia?.placeholder ||
										"Escribe tu contraseña"
									}
									name="password"
									required
								/>
							</fieldset>

							<div className="row align-items-center justify-content-end mx-0 mt-3">
								<div className="col-12 col-md-6 px-0 d-flex justify-content-end mt-3 mt-md-0">
									<button
										disabled={sendingLogin}
										onClick={(e) => iniciarSesion(e)}
										className="btn-iniciar-sesion focusable-red px-5 py-2 d-block mx-auto mx-md-0 "
									>
										{(() => {
											if (sendingLogin) {
												return <i className="fas fa-spinner fa-pulse"></i>;
											} else {
												return (
													textosLogin?.login?.botones?.iniciar_sesion ||
													"Iniciar sesión"
												);
											}
										})()}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<ModalAlertas
				id={"modal-alertas-autenticacion"}
				message={message}
				description={description}
			/>
		</div>
	);
};
