import React, { useState, useRef } from "react";
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { Link } from "react-router-dom";
import platform from "platform";
import { BaseModal } from "./base-modal";

/**
 * Componente de modal para mostrar un mensaje de recomendación de navegadores.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.visible - indica si el modal debe mostrarse o no.
 * @param {(visible:boolean)=>{}} props.setVisible - Función que cambia el estado de visibilidad del modal.
 * @returns {JSX.Element} Elemento JSX que representa el componente.
 */
export const ModalNavegadorRecomendado = ({ visible, setVisible }) => {
	const { textosSubSecciones: textosHome } = useObtenerSubsecciones("home");
	const [check, setCheck] = useState(false);

	const prueba = useRef();

	const close = () => {
		setVisible && setVisible(false);
	};

	const gestionarCheckboxVisibilidad = (visible) => {
		setCheck(visible);
		localStorage.setItem(
			"modal-navegador-recomendado",
			JSON.stringify({
				mostrar: !visible,
			})
		);
	};

	return (
		<>
			<BaseModal
				windowClassName={"default-modal-window modal-navegador-recomendado"}
				visible={visible}
				setVisible={setVisible}
			>
				<div className="d-flex flex-row-reverse">
					<button
						aria-label="Cerrar ventana de dialogo"
						className="close-button onbackground-primary--color focusable-red"
						data-micromodal-close
						onClick={() => close()}
					>
						<Icon icon={"mdi:close-thick"} width={35} />
					</button>
				</div>
				<div className="mx-md-4">
					<h2
						id="titulo_navegador"
						className={`secondary-inverse--color titulo-modal-navegador d-flex align-items-center justify-content-center mb-3 text-center`}
					>
						<Icon icon="material-symbols:info" width={32} />
						{textosHome?.modal_navegador?.titulo ||
							"Este navegador no es 100% compatible"}
					</h2>
					{/* <p className="my-3 bold size onbackground-third--color">
						{textosHome?.modal_navegador?.plataforma_actual?.navegador ||
							"Tu navegador actual:"}{" "}
						{`${platform?.name}, versión ${platform.version}`}
						<br />
						{textosHome?.modal_navegador?.plataforma_actual?.os ||
							"Tu sistema operativo:"}{" "}
						{`${platform?.os?.family} ${platform?.os?.version}`}
					</p> */}
					<p id="dialogo-navegador" className="my-3 mt-4 onbackground-third--color">
						{textosHome?.modal_navegador?.descripcion ||
							"Para un perfecto funcionamiento makemake te recomienda usar los siguientes navegadores según tu sistema operativo."}
						<a className="link-dudas focusable-red" href="https://www.google.com/intl/es-419/chrome/" target="_blank" rel="noopener noreferrer" > {textosHome?.modal_navegador?.instalar_chrome ||
							"Instalar Google Chrome"}</a>
					</p>

					{/* <div className="d-flex w-100 justify-content-center align-items-center">
						<img
							src="/img/instrucciones/navegadores-recomendados.png"
							className="img-fluid mx-auto"
							alt="Para los sistemas operativos windows, android, mac OS y Linux recomendamos el navegador google chrome versión mayor a 103. Para sistemas operativos ios recomendamos el navegador safari en su versión mayor a 11.3"
						/>
					</div> */}

					<div className="mt-4 w-100 d-flex flex-column flex-md-row justify-content-between align-items-center">
					<label
						className="d-flex align-items-center focusable-red pointer my-2"
						tabIndex={0}
						role="checkbox"
						checked={check}
						aria-checked={check}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								gestionarCheckboxVisibilidad(!check);
							}
						}}
					>
						<input
							type="checkbox"
							className="modal-checkbox"
							ref={prueba}
							checked={check}
							onChange={(e) => gestionarCheckboxVisibilidad(e?.target.checked)}
						/>
						<i className="check"></i>
						<p className="ml-2 onbackground-primary--color">
							{textosHome?.modal_navegador?.check || "No volver a mostrar"}
						</p>
					</label>
					<Link
						to={"/ayuda"}
						onClick={() => close()}
						className={`link-dudas text-center secondary-inverse--color d-block focusable-red`}
					>
						{textosHome?.modal_navegador?.enlace ||
							"¿Tienes dudas? consulta nuestro soporte"}
					</Link>

					</div>
			
				</div>
			</BaseModal>
		</>
	);
};
