import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";

/**
 * Esta API /assets/datosPaises, también puede devolver una lista completa de paises si no se le pasa un filtro o se puede especificar USA o Latam por separado para tener la lista unica de paises.
 */

/**
 * Este servicio devuelve el arreglo de paises latinoamericanos más USA. 
 * @param {{abortSignal:AbortSignal}} abortSignal 
 * @returns {Promise<Array>}  arreglo de paises
 */
const obtenerLatamUSA = async ({ abortSignal = null }) => {
  try{
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials= true;

    const formData = new FormData();
    formData.append('filtro', 'LatamUSA')
    const response= await axios({
      method: 'POST',
      url:'/assets/datosPaises',
      signal: abortSignal || null,
      headers:{"Content-Type":"multipart/form-data"},
      data: formData
    })
    const datos = response.data;
    if(datos.paises === null){
      return new Error(datos);
    }
    return datos.paises || [];
  } catch (error){
    return Promise.reject(error);
  }
}

export const DatosPaisesAPI = {
  obtenerLatamUSA,
}