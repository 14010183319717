import React from "react";//Swiper
import SwiperCore, { Navigation, Pagination, Keyboard, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper.scss';
import Skeleton from "react-loading-skeleton";

SwiperCore.use([Navigation, Pagination, Keyboard, Virtual]);
const SkeletonColeccion = (props) => {
  return (
    <>
      <Swiper
        autoplay={{ delay: 10000 }}
        loop={true}
        preloadImages={true}
        keyboard={true}
        breakpoints={{
          1200: {
            spaceBetween: 60,
          },
        }}
        centeredSlides={true}
        className="home-collections py-3"
        slidesPerView="auto"
        spaceBetween={20}
      >
        {(() => {
          var banners = [];
          for (let i = 0; i < 20; i++) {
            banners.push(
              <SwiperSlide className="swiper-slide py-3" key={`coleccion-${i}`}>
                <div className="cover">
                  <Skeleton className='img-fluid' height={200} />
                </div>
              </SwiperSlide>
            )
          }
          return banners;
        })()}
      </Swiper>
    </>
  );
}

export default SkeletonColeccion;
