import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Dropdown } from "../components/shared/dropdown";
import { store } from "../components/Store";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { OrdenamientoBusqueda } from "./ordenamiento-busqueda";

/**
 * Componente de selección desplegable para ordenamiento.
 * Permite al usuario seleccionar un método de ordenamiento y notifica el cambio mediante la función onChange.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {function} props.onChange - Función que se ejecuta cuando cambia el método de ordenamiento.
 * @returns {JSX.Element} Elemento JSX que representa el componente DropdownOrdenamiento.
 */
export const DropdownOrdenamiento = ({ onChange }) => {
    const { textosSubSecciones:traducciones} = useObtenerSubsecciones('busqueda');
    const [items, setItems] = useState([]);

    const actualizarMetodoOrdenamiento = (metodo)=>{
        if(Boolean(metodo)){
            onChange&&onChange({
                orderBy:metodo?.ordenamiento?.tipo,
                orderType:metodo?.ordenamiento?.modo
            })
        }
    }

    useEffect(()=>{
        const adaptedItems = [...OrdenamientoBusqueda]
            .map(it=>{
                const traduccion = traducciones?.libros?.ordenar_por?.[it?.ordenamiento?.translationId]?.[it?.name?.translationId];
                const textoPorDefecto = it?.name?.defaultValue
            return {
                ...it,
                name: traduccion || textoPorDefecto || 'N/A'
            }
        })
        

        setItems(adaptedItems)
        console.log(traducciones)

    },[traducciones])


    return (<>
        <Dropdown.Regular
            className={''}
            title={traducciones?.libros?.ordenar_por?.titulo || 'Ordenar por'}
            itemsData={items}
            onChange={(currentItem) => actualizarMetodoOrdenamiento(currentItem)}
        />
    </>)
}
