
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Este servicio devuelve los metadatos de un libro que se muestra en la ficha.
 * @param {{abortSignal:AbortSignal, idLibro:string} param0 
 * @returns {Promise<{object}>} retorna un objeto con los datos del libro
 */
const obtenerDatosFichaLibro = async ({abortSignal=null, idLibro})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const formData = new FormData();
        formData.append('idLibro', idLibro)
        const response = await axios({
          method: 'POST',
          url: 'assets/datosLibroMeta',
          signal: abortSignal || null,
          headers:{"Content-Type":"multipart/form-data"},
          data: formData
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos || {};
    
      } catch (error) {
        return Promise.reject(error);
      }  
}

/**
 * Este servicio devuelve un arreglo con los metadatos de libros recomendados.
 * @param {{abortSignal:AbortSignal, idLibro:string} param0 
 * @returns {Promise<{Array<object>}>} retorna un arreglo con los libros recomendados
 */
const obtenerRecomendados = async ({abortSignal=null, idLibro})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const formData = new FormData();
        formData.append('idLibro', idLibro)
        const response = await axios({
          method: 'POST',
          url: 'assets/datosLibroRecomendados',
          signal: abortSignal || null,
          headers:{"Content-Type":"multipart/form-data"},
          data: formData
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  Array.isArray(datos?.recomendados)?datos?.recomendados:[];
    
      } catch (error) {
        return Promise.reject(error);
      }  
}

/**
 * Este servicio devuelve los metadatos de los temas recomendados
 * @param {{abortSignal:AbortSignal, idLibro:string} param0 
* @returns {Promise<{object}>} retorna un objeto con los temas
*/
const obtenerTemas = async ({ abortSignal = null, idLibro }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('idLibro', idLibro)
    const response = await axios({
      method: 'POST',
      url: 'assets/temasRelacionados',
      signal: abortSignal || null,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    })
    const datos = response.data;
    if (datos === 'Error') {
      return new Error(datos);
    }
    return datos?.tags || {};

  }catch (error){
    return Promise.reject(error);
  }
}


export const LibrosAPIs = {
   obtenerDatosFichaLibro,
   obtenerRecomendados,
   obtenerTemas
}