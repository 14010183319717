import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ASSETS_URL } from "../../components/Store";
import { Img } from "../../components/shared/img";
import { Icon } from '@iconify/react';
import { FavoritesStorage } from "../../util/favorites-storage";
import { store } from "../../components/Store";
import { useFavoritesBooks } from "../../hooks/useFavoritesBooks";

/**
 * Componente de libro con efecto 'flip', solo se muestra en el home de un usuario con pocos libros.
 * @param {Object} props - Props del componente.
 * @param {string} props.portada - URL de la portada del libro.
 * @param {string} props.titulo - Título del libro.
 * @param {string} props.genero - Género del libro.
 * @param {string} props.autor - Autor del libro.
 * @param {string} props.editorial - Editorial del libro.
 * @param {string} props.descripcion - Descripción del libro.
 * @param {string} props.url - URL del libro.
 * @returns {JSX.Element} Elemento JSX que representa el libro con efecto de volteo.
 */
export const FlipBook = ({
  portada,
  titulo,
  genero,
  autor,
  editorial,
  descripcion,
  url,
  idLibro
}) => {
  const [isFlipCard, setIsFlipCard] = useState(false);
  const [linkFocused, setLinkFocused] = useState(false);
  const [favorite, setFavorite] = useState(false);
  let clase = isFlipCard ? "rotateficha" : "";

  //Libro favorito
  const globalState = useContext(store);
  const user = globalState.state.username;
  const favorites = useFavoritesBooks({ userName: user })

   /**
   * Define si el libro pertenece a los favoritos.
   */
   useEffect(()=>{
    let isFavorite = favorites.includes(idLibro);
    setFavorite(isFavorite);
  },[idLibro, favorites])

  const flipCard = () => {
      setIsFlipCard(!isFlipCard);
  };

  const onEnterHandler = (e)=>{
    console.log(linkFocused)
    if(e.key === 'Enter' && linkFocused===false){
      flipCard();
    }
  }

  //   format <br> to real break
  const breakLine = (text) => {
    return text.replace(/<br>/g, "\n");
  };

  const agregarRemoverAFavoritos = () => {
    if (favorite === false) {
      FavoritesStorage.saveFavorites(user, idLibro);
    } else {
      FavoritesStorage.removeFavorites(user, idLibro);
    }
  }

  return (
    <div className="individual-book align-self-stretch" role={"tablist"}>
      <div className={`w-100 h-100 focusable-secondary individual-book-cover ${clase}`}>

        <div
          tabIndex={0}
          aria-label={titulo}
          role={"tab"}
          aria-expanded={isFlipCard}
          onClickCapture={(e) => {
            flipCard();
          }}
          onKeyDownCapture={(e)=>onEnterHandler(e)}
          data-state="close"
          className="side-a w-100 min-h-100"
          style={{ pointerEvents: isFlipCard ? 'none' : 'all', zIndex: isFlipCard ? -100 : 'auto' }}
        >
          <div className="mx-auto img-side-a" >
            <Img
              className="clickArea"
              src={`${ASSETS_URL}${portada}`}
              role={"presentation"}
              alt={titulo}
              placeholderSrc={"/img/no-imagen.png"}
            />
          </div>
        </div>
        {
          isFlipCard&&
          <div className="individual-book-des side-b">
          <div
            className="clickArea individual-book-desc"
              tabIndex={0}
              aria-label={titulo}
              role={"tab"}
              aria-expanded={isFlipCard}
              onClickCapture={(e) => {
                flipCard();
              }}
              onKeyDownCapture={(e) => onEnterHandler(e)}
              data-state="open"
          >
            <div className="tag-genero">{genero}</div>

            <div className="book-information">
              <h3 tabIndex={0} className={"focusable-secondary"}>{autor}</h3>
              <p tabIndex={0} className={"focusable-secondary"}>Autor</p>

              <h3 tabIndex={0} className={"focusable-secondary"}>{editorial}</h3>
              <p tabIndex={0} className={"focusable-secondary"}>Editorial</p>
            </div>
            <div className="book-description" >
              <p tabIndex={0} className={"focusable-secondary"}>{breakLine(descripcion)} </p>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-lg-3">

              {/* favorito */}
              <button
                className="btn-favorito focusable-secondary read-button mr-3"
                onClick={() => agregarRemoverAFavoritos()}
              >
                {favorite ?
                  <Icon icon="material-symbols:favorite" className=" mr-1 red-inverse--color" width={30} />
                  :
                  <Icon icon="material-symbols:favorite-outline" className="mr-1" width={30} />
                }
                Favorito
              </button>
              <Link
                to={`/visor/${url}`}
                className={"focusable-secondary read-button"}
                onFocus={()=>setLinkFocused(true)}
                onBlur={()=>setLinkFocused(false)}
              >
                Leer
              </Link>
          </div>
        </div>
        }
      <h2 aria-hidden={true} className="individual-book-title" style={{pointerEvents:isFlipCard?'none':'all', zIndex:isFlipCard?-100:'auto'}}>{titulo}</h2>
      </div>
    </div>
  );
};


