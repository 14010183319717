import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store"

/**
 * Este servicio devuelve los datos de la institución 
 * @param {{abortSignal:AbortSignal, data:{institucion:String}}} param0 
 * @return {Promise<{
    "logo": String,
    "colorHeader": String,
    "colorLetra" : String,
    "nombreMostrar" : String,
    "telefono": String,
    "correo": String,
    "direccion": String,
    "tipoFormulario": String
 * }>} retorna un objeto con los datos de la institución
 */

const obtenerDatos = async ({ abortSignal, data }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API; 
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('inst', data)

    const response = await axios({
      method: 'POST',
      url: `assets/formulario/obtenerInstituto`,
      signal: abortSignal || null,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    })

    const datos = response.data;
    if (datos === 'Error') {
      return new Error(datos);
    }
    return datos || {};
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Este servicio inicia una sesión institucional
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<object>} Si la respuesta es afirmativa se redirecciona automaticamente. En caso contrario se retornara status 0 y en info el porque de el fallo
 */
const enviarDatos = async({abortSignal, data}) =>{
  try {
    axios.defaults.baseURL = GENERAL_URL_API; 
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('inst', data?.inst || '');
    formData.append('nombre', data?.nombre || '');
    formData.append('cedula', data?.cedula || '');
    formData.append('departamento', data?.departamento || '');
    formData.append('libraryID', data?.libraryID || '');
    formData.append('correo', data?.correo || '');
    formData.append('celular', data?.celular || '');
    formData.append('genero', data?.genero || '');
    formData.append('fechaNacimiento', data?.fechaNacimiento || '');
    formData.append('tipoId', data?.tipoId || '');
    formData.append('tipoIngreso', data?.tipoIngreso || '')

    const response = await axios({
      method: 'POST',
      url: `assets/formulario/loginFormulario`,
      signal: abortSignal || null,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    })

    const datos = response.data;
    if (datos === 'Error') {
      return new Error(datos);
    }
    return datos || {};

  }catch(error){
    return Promise.reject(error);
  }
}

export const DatosFormInstitutoAPI = {
  obtenerDatos,
  enviarDatos
}