/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from "react";
import { store } from "../Store";
import { useLocation } from "react-router-dom";
import { PublicHeader } from "./public-header/index.js";
import { IndividualHeader } from "./individual-header/index.js";
import { GeneralHeader } from "./general-header/index.js";

import { AccesibilidadContext } from "../../contexts/AccesibilidadContext.js";

/**
 * Este componente renderiza el header del proyecto. Su principal función es identificar si la sesión esta activa. A partir de esto, asocia el tipo de usuario para mostrar el Header correspondiente. 
 * @returns {JSX.Element} Header
 */
const Header = () => {
	const globalState = useContext(store);
	const { state } = globalState;
	const accebilidadState = useContext(AccesibilidadContext);

	const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
	const location = useLocation();
	const isIndividualUser = state?.cantidadLibros <= 40 && globalState?.state?.cantidadLibros >= 0;

	const [esTemaClasico, setEsTemaClasico] = useState(true);

	/*Actualiza el estado de EsTemaClasico. */
	useEffect(() => {
		let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
		setEsTemaClasico(tema);
	}, [accebilidadState])


	return (
		<>
			<header
				style={{
					height: !location.pathname.includes('/visor') ? 50 : 0,
					background: isLogged ?
						isIndividualUser ?
							esTemaClasico ?
							'#262626'
							:
							'#000'
							:
							esTemaClasico ?
								state?.datosHeader?.colorHeader !== null ?
									state?.datosHeader?.colorHeader
									: 
									'#262626'
								:
								'#000'
						:
							esTemaClasico ?
								'#262626'
								:
								'#000'
				}}
			>
				{isLogged && !location.pathname.includes('/visor') && state.cantidadLibros <= 40 && globalState?.state?.cantidadLibros >= 0 &&
					<IndividualHeader />
				}

				{isLogged && !location.pathname.includes('/visor') && state.cantidadLibros !== "NULL" && state.cantidadLibros > 40 &&
					<GeneralHeader />
				}

				{!isLogged && !location.pathname.includes('/visor') &&
					<PublicHeader />
				}
			</header>
		</>
	);
};

export default Header;
