import React from "react";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { CheckBox } from "./checkbox";
import { Link } from "react-router-dom";
import { Img } from "./img";

/**
 * Componente que recibe una serie de props necesarios para las portadas de los libros.
 */
export const Book = ({
  libro,
  alAgregarFav,
  alRemoverFav,
  alSeleccionar,
  alCambiarEstadoCheckbox,
  clasesPersonalizadas,
  ocultarOpciones,
  estadoInicial,
  esVisible,
  elementosAdicionales
}) => {

  const SinImagen = "/img/no-imagen.png"

  const [esFavorito, setEsFavorito] = useState(false);
  const [esCheckboxActivado, setEsCheckboxActivado] = useState(false);
  const [imagen, setImagen] = useState(libro?.portada || SinImagen)


  const agregarAFavorito = (favorito) => {
    if (favorito) {
      if (alAgregarFav) alAgregarFav(libro);
    } else {
      if (alRemoverFav) alRemoverFav(libro);
    }
    setEsFavorito(favorito);
  }

  const marcarCheckbox = (activar) => {
    setEsCheckboxActivado(activar);
    alCambiarEstadoCheckbox && alCambiarEstadoCheckbox({ ...libro, checked: activar })
  }


  useEffect(() => {
    if (Boolean(libro.portada) === false) {
      setImagen(SinImagen)
    } else {
      setImagen(libro?.portada)
    }
  }, [libro.portada])


  useEffect(() => {
    setEsCheckboxActivado(estadoInicial?.esCheckboxMarcado)
  }, [estadoInicial?.esCheckboxMarcado])

  useEffect(() => {
    setEsFavorito(estadoInicial?.esFavorito)
  }, [estadoInicial?.esFavorito])

  return (
    <div className={`libro-tarjeta focusable-secondary ${clasesPersonalizadas?.contenedor}`} tabIndex={0} role={'region'} aria-label={`Book Cover/ Portada libro:  ${libro?.title||libro?.titulo||libro?.name||libro?.nombre || 'N/A'}`}>
      <div className={`portada ${clasesPersonalizadas?.portada} `} >
        <Img
          defaultImage={SinImagen}
          className={`w-100 h-100 ${Boolean(esVisible) === false && 'gray-scale'}`}
          wrapperClassName={'w-100 h-100'}
          src={imagen}
          alt={libro?.title}
          placeholderSrc={SinImagen}
          width={500}
          height={800}
          threshold={200}
        />
        <div className="ver-mas">
          <Link className="icon focusable-primary w-auto" aria-label={'Ver más información del libro/See more information about the book'} replace={true} to={libro?.webUrl || libro?.weburl || libro?.link || ''} onClick={() => alSeleccionar && alSeleccionar(libro)}>
            <Icon icon="ion:open" width={30} />
          </Link>
        </div>

        {
          Boolean(ocultarOpciones?.checkbox) == false &&
          <div className={"checkbox-container collection-step3"}>

            <CheckBox ariaLabel={'libro seleccionado/selected book'} focusColor={'focusable-primary'} checked={esCheckboxActivado} onChange={(e) => {
              e.persist();
              marcarCheckbox(!esCheckboxActivado)
            }} />
          </div>

        }

        {Boolean(ocultarOpciones?.botonFavoritos) === false &&
          <button
            style={{width:30, height:30}}
            aria-label={`favorito/favorite: ${esFavorito?'añadido a favoritos/addded to favorites':''}`}
            className={`fav focusable-primary ${esFavorito && 'added'}`}
            onClick={(e) => agregarAFavorito(!esFavorito)}>
            <Icon icon="mdi:cards-heart" />
          </button>
        }

        {Boolean(ocultarOpciones?.bookType) !== true &&
          <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
            {(() => {
              if (parseInt(libro.gratis) === 1) {
                return <img className="additionalIcon" src="/img/freeIcon.png" alt="icono adicional" />
              } else if (parseInt(libro.gratis) === 0 && parseInt(libro.interactivo) === 1) {
                return <img className="additionalIcon" src="/img/inticon.png" alt="icono adicional" />
              } else if (parseInt(libro.gratis) === 0 && parseInt(libro.interactivo) === 0 && parseInt(libro.sonido) === 1) {
                return <img className="additionalIcon" src='/img/soundIcon.png' alt="icono adicional" />
              }
            })()}
          </div>
        }

      

        {
          elementosAdicionales?.portada &&
          <div className="wrapper-elementos-adicionales-portada absolute top-0 left-0 w-full h-full">
            {elementosAdicionales?.portada}
          </div>
        }

      </div>
      <Link className={`focusable-secondary titulo ${clasesPersonalizadas?.titulo}`} replace={true} to={libro?.webUrl || libro?.weburl || libro?.link || ''} onClick={(e) => alSeleccionar && alSeleccionar(libro)}>{libro?.title || 'N/A'}</Link>
    </div>
  )
}

Book.propTypes = {
  /**
   * el objeto libro que se cargara, es requerido
   */
  libro: PropTypes.object.isRequired,
  /**
* callback utilizada para cuando el libro sea seleccionado para realizar acciones de ver, abrir, mostrar libro.
Este evento se dispara al hacer click en el titulo y en el icono que aparece en la mitad al hacer hover
* @type{Function(libro:Libro):void} - callback
*/
  alSeleccionar: PropTypes.func,
  /**
    * callback utilizada para verificar si el checkbox esta seleccionado o no, devuelve el libro y el si esta seleccionado el checkbox o no
    * @type{Function(libro:Libro, estaSeleccionado:boolean):void} - callback
    */
  alCambiarEstadoCheckbox: PropTypes.func,
  /**
* callback utilizada para cuando se desmarque la opción de favoritos 
* @type{Function(libro:Libro):void} - callback
*/
  alRemoverFav: PropTypes.func,
  /**
* callback utilizada para cuando se marque la opción de favoritos 
* @type{Function(libro:Libro):void} - callback
*/
  alAgregarFav: PropTypes.func,
  /**
  *objeto para personalizar clases de portada, titulo y container principal
  * @type{{
  contenedor:string,
  portada:string,
  titulo:string}} - objeto de clases personalizadas
  */
  clasesPersonalizadas: PropTypes.object,
  /**
  *objeto en el cual se define si una opción ( por ejemplo favoritos) se debe mostrar o no
  * @type{{
  n  botonFavoritos:boolean,
    botonMultiSeleccion:boolean,
    seleccionado:boolea
  */
  ocultarOpciones: PropTypes.object,
  /**
 *objeto en donde se pasa propiedades iniciales por defecto al componente, se puede pasar si el boton de favorito debe estar marcado, al igual que otras caracteristicas. 
 * @type{{
   botonFavoritos:boolean,
   checkbox:boolean,
   bookType:boolean,
 * }}
 */
  estadoInicial: PropTypes.object,
  /**
*propiedad booleana en donde se define si es visible o no el libro, si es visible se vera a color, si no es as[i, se vera en escala de grises]
* @type{boolean}
*/
  esVisible: PropTypes.bool,
  /**
   * Objeto el cual permite agregar elementos html o componentes react adicionales al libro.
   * @example 
      {
        "portada":<button>Botón personalizado que ira dentro de la portada</button>
      }
 
   */
  elementosAdicionales: PropTypes.shape({
    portada: PropTypes.element,
  })
}
