import React from "react";
import { Link } from "react-router-dom";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente de "Descubre los mejores libros.." para que es bibliotecas
 * @returns {JSX.Element} Componente Discover.
 */
export const Discover = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textosBibliotecas = useTranslations({ section: "que_es_biblioteca", lang: lang });

	return (
		<div
			tabIndex={0}
			role={"region"}
			aria-label={textosBibliotecas?.nuestro_plan?.parte_uno || "Conoce nuestro plan"}
			className="focusable-primary highlight-container discover demo--instruction-3 py-4 "
		>
			<div className="row mx-0 py-3">
				<div className="col-lg-8 mx-auto">
					<div className="align-items-center justify-content-between flex-column">
						<div className="mx-auto mb-3">
							<h3 className="onsecondary--color text-center font-weight-bold ">
								{textosBibliotecas?.nuestro_plan?.parte_uno ||
									"Descubre los mejores libros para promover"}
								<br />
								{textosBibliotecas?.nuestro_plan?.parte_dos ||
									"el amor a la lectura en un click"}
							</h3>
						</div>

						<div className="d-flex justify-content-center align-items-center">
							<Link
								to="/home"
								className="focusable-primary d-inline-flex learn-more-button btn btn-primary py-2 px-4 d-block mx-auto"
							>
								{textosBibliotecas?.botones?.descubrelo || "Descúbrelo"}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
