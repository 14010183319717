import React, { useContext, useEffect, useState } from "react";
import { store } from "../components/Store";
import { useFavoritesBooks } from "../hooks/useFavoritesBooks";
import { FavoritesStorage } from "../util/favorites-storage";
import { useUserBooks } from "../hooks/useUserBooks";
import { Img } from "../components/shared/img";
import { Link, useHistory } from "react-router-dom";
import { Book } from "../components/shared/book";
import { ASSETS_URL } from "../components/Store";
import useObtenerTraducciones from "../js/translations/useObtenerTraducciones";
import InfiniteScroll from "react-infinite-scroll-component";
import { Icon } from "@iconify/react";
/**
 * Este componente renderiza la pantalla con la lista de libros favoritos
 */
export const Favoritos = () => {
  const globalState = useContext(store);
  const user = globalState.state.username;
  const favorites = useFavoritesBooks({ userName: user })
  const userBooksData = useUserBooks();
  const userBooks = userBooksData?.books;
  const isIndividualUser = globalState?.state?.cantidadLibros < 40 && globalState?.state?.cantidadLibros >=0;

  const [favoritesBooks, setFavoritesBooks] = useState([]);
  const [librosAMostrar, setLibrosAMostrar] = useState([]);
  const cantidadLibrosAMostrar = 21;
  const [librosCargados, setLibrosCargados] = useState(false)
  const [actualizarFavoritos, setActualizarFavoritos] = useState(true)
  const history = useHistory();

  //Hooks personalizados
  const { textosSeccion: textosInterfaz } = useObtenerTraducciones('favoritos');

  useEffect(() => {
    if(actualizarFavoritos){
      filtrarLibros();
    }    
  }, [userBooks, actualizarFavoritos])

  /**Filtra entre los libros del usuario, los libros favoritos */
  const filtrarLibros = () => {
    if (userBooks.length !== 0) {
      let books = userBooks.filter(res => favorites.some(item => item === res.idLibro))
      setFavoritesBooks(books)
      setActualizarFavoritos(false)
    }
  }

  useEffect(() => {
    // Carga los 10 primeros libros 
    const cargaInicial = () => {
      if (Array.isArray(favoritesBooks)) {
        setLibrosAMostrar(favoritesBooks.slice(0, cantidadLibrosAMostrar))
        favoritesBooks.length <= cantidadLibrosAMostrar && setLibrosCargados(true)
      }
    }

    setLibrosCargados(false);
    cargaInicial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoritesBooks])

  useEffect(() => {
    if (librosAMostrar.length >= favoritesBooks.length) {
      setLibrosCargados(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [librosAMostrar])

  // Funcion que agrega 10 libros a los libros que se estan mostrando en pantalla
  const cargaProgresiva = async () => {
    let timer = null;
    return await new Promise((resolve) => {
      if (timer) { clearTimeout(timer) }
      timer = setTimeout(() => {
        let contador = 0;
        const nuevosLibros = [];
        for (let i = librosAMostrar.length; i < favoritesBooks.length; i++) {
          const libro = favoritesBooks[i];
          if (contador < cantidadLibrosAMostrar) {
            nuevosLibros.push(libro)
          }
          contador++;
        }
        setLibrosAMostrar([...librosAMostrar, ...nuevosLibros])
        if (librosAMostrar.length === favoritesBooks.length) {
          setLibrosCargados(true);
        }
        resolve(true);
      }, 1000);
    })
  }

  /**
     * Función que remueve el libro de favoritos en el localstorage
     * @param {string} libro -ide libro
     */
  const agregarRemoverAFavoritos = (libro) => {
    const bookID = libro?.idLibro || 'N/A';
    FavoritesStorage.removeFavorites(user, bookID);
    setActualizarFavoritos(true);
  }

  return (
    <div className="focusable-secondary main-container">
      <div className="container py-5">
        <div className="surface--bg container main-content p-3 p-lg-5 mx-auto ">
          <h2 tabIndex={0} className="focusable-secondary mb-2 text-center secondary-inverse--color" >{textosInterfaz?.titulo || 'Tus favoritos'}</h2>

          {
            favorites.length === 0 ?
              <div >

                <Img style={{ maxWidth: '300px' }} alt="Blop sin favoritos" className="mx-auto mt-3" src={'./img/favoritos.png'} />
                <div className="text-center mt-3 onprimary--color">
                  <h3>{textosInterfaz?.subtitulo || 'Aún no tienes libros favoritos'}</h3>
                  <p>{textosInterfaz?.recuerda || 'Recuerda que los libros solo quedan guardados en tu navegador'}</p>
                  <Link to={'/busqueda?sch='} className="btn btn-primary mt-4">{textosInterfaz?.buscar || 'Buscar libros'}</Link>

                </div>

              </div>
              :
              <div className="">
                <InfiniteScroll
                  style={{ overflow: 'visible' }}
                  className="row  justify-content-between justify-content-md-start  favorite mt-5"
                  dataLength={librosAMostrar.length}
                  next={cargaProgresiva}
                  hasMore={librosCargados ? false : true}
                  scrollThreshold={0.86}
                  loader={
                    <div
                      className="secondary-inverse--color container-fluid w-100 d-flex justify-content-center align-items-center col-md-10 col-lg-10">
                      <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                    </div>
                  }
                >
                  {librosAMostrar.map((item, index) => {
                    return (
                      <div
                        className={`cover-book  mb-4`}
                        key={`libro${index}`}
                      >
                        <Book
                          libro={{
                            ...item,
                            portada: `${ASSETS_URL}${item?.portada}`,
                            title: item.nombreLibro || item?.titulo || item?.title || item?.nombre || 'N/A'
                          }}
                          alSeleccionar={() => isIndividualUser ? history.push( `/visor/${item?.idLibro}`) : history.push(item?.webUrl)}
                          ocultarOpciones={{
                            checkbox: true,
                            bookType: false,
                            botonFavoritos: false
                          }}
                          estadoInicial={{
                            esFavorito: favorites.includes(item?.idLibro)
                          }}
                          alRemoverFav={(item) => agregarRemoverAFavoritos(item)}
                        />
                      </div>
                    )
                  })}
                </InfiniteScroll>

                {
                  librosCargados === false &&

                  <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
                    <p className='secondary-inverse--color bold'>
                      {`${textosInterfaz?.scroll ||
                        ' Desplazate hacia abajo para cargar más libros.'}`
                      }

                    </p>
                    <Icon icon="mdi:mouse-move-down" className='secondary-inverse--color text-28 mx-2' width={30} />
                  </div>
                }
              </div>

          }

        </div>
      </div>
    </div>
  )
}