import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

/**
 * skeleton del header con login - usuario individual
 * @returns {JSX.Element} SkeletonHeaderIndividual
 */
export const SkeletonHeaderIndividual = () => {
  return (
    <SkeletonTheme color="#636363" highlightColor="#262626">
      <nav className="row mx-0">
        <div className="col-6 col-lg-2 col-xl-4 d-flex aling-items-center">
          <Skeleton height={40} width={40}/>
        </div>

        <div className="col-6 col-lg-10 col-xl-8">
          <ul className="option-nav d-flex justify-content-end aling-items-center">
            <Skeleton height={40} width={110} className="mr-3" />
            <Skeleton count={2} height={40} width={40}  className="mr-3 d-none d-lg-inline-block" />
          </ul>
        </div>
      </nav>
    </SkeletonTheme>
  )
}