import { useContext, useEffect, useState } from "react";
import { store } from "../components/Store";
import { SesionAPI } from "../services/api/Sesion";
import { CancelRequestController } from "../util/cancel-request-controller";

/**
 * Hook que se utiliza para obtener información sobre la sesión actual.
 * @returns {{
*  autenticado: boolean,
*  usuario: {
*    userType: string | undefined,
*    username: string | undefined
*  }
* }} - Objeto con propiedades relacionadas a la sesión actual.
* @property {boolean} autenticado - Indica si el usuario está autenticado.
* @property {Object} usuario - Objeto con información del usuario.
* @property {string | undefined} usuario.userType - Tipo de usuario.
* @property {string | undefined} usuario.username - Nombre de usuario.
*/

export const useSesionActual = () => {
    const [autenticado, setAutenticado] = useState(false);
    const {state} = useContext(store)
    const makemakeData = JSON.parse(localStorage.getItem('makemakeData'));

    useEffect(() => {
        const abortSignal = new CancelRequestController();
        const verificaciones = async ({abortSignal})=>{
            const estaAutenticado = await SesionAPI.usuarioAutenticado({ abortSignal: abortSignal.signal }).catch(err=>console.log(err))
            if(makemakeData?.sesionActiva === 'SI' || estaAutenticado === true){
                setAutenticado(true)
            }
            else{
                setAutenticado(false)
            }
        }
        verificaciones({abortSignal});
      
        return () => {
            abortSignal.abort();
        }
    },[state?.sesionActiva])


    return {
        autenticado: autenticado,
        usuario: {
            userType: state?.userType || makemakeData?.userType,
            username: state?.username || makemakeData?.username,
        }
    }
}