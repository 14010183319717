import React from "react";
import { Icon } from "@iconify/react";
export const TutorialCard = ({ data, onClick, isVideo }) => {
	const href = isVideo?'#':data?.tutorialLink || ''
	return (
		<a href={href} target="_blank" rel="noopener noreferrer" className="d-flex w-100 h-100 tutorial" onClick={(e) => {
			if(isVideo){
				e.preventDefault()
			}
			onClick && onClick()
		}}>
			<div className="d-flex flex-column h-100">
				<div className="w-100 position-relative flex-grow-1">
					<img
						src={data?.imagePath}
						className="img img-fluid"
						alt={data?.description || ""}
					/>
					<div
						className="position-absolute m-2"
						style={{ bottom: "0%", right: "5%", color: "#fff" }}
					>
						{isVideo === true ? (
							<Icon
								className={""}
								style={{ transform: "scale(1.5)" }}
								icon="mdi:video"
								width={26}
							/>
						) : (
							<></>
						)}
					</div>
				</div>
				<div className="col-12">
					<p className="py-4 px-2 font-weight-bold background--bg onbackground-primary--color">
						{data?.description}
					</p>
				</div>
			</div>
		</a>
	);
};
