import { PLANES_DATA } from "./planes";

const PLAN = PLANES_DATA;
/**
 * Arreglo de objetos que tiene la información de las tarjetas de PLAN CASA - LEER EN MKMK
 */
export const CARDS_DATA = [
	{
		...PLAN.semestral.find((plan) => plan?.id.includes("favorito")),
	},
	{
		...PLAN.semestral.find((plan) => plan?.id.includes("lecturas")),
	},
	{
		...PLAN.trimestral.find((plan) => plan?.id.includes("coleccion")),
	},
];
