import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import _ from "lodash";

import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";

import { CARDS_DATA } from "../data/cards-data";

import { store } from "../../../components/Store";
import { ReadFlag } from "../components/read-flag";
import { PlanCard } from "../components/plan-card";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente que renderiza las fichas de planes para la casa
 * @returns {JSX.Element} LeerMakeMake
 */
export const LeerMakeMake = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosTienda = useTranslations({ section: "tienda_makemake", lang: lang });

    const plans = CARDS_DATA;
    const globalState = useContext(store);
    const { state } = globalState;
    const { pais } = state;

    const history = useHistory();

    const goToPlan = ({ pathname, search }) => {
        history.push({
            pathname: pathname || '/',
            search: search || ''
        })
    }

    return (
        <div>
            <ReadFlag title={textosTienda?.tabpanel?.leer || "Leer en MakeMake"} />

            <div className="d-flex flex-nowrap justify-content-center align-items-center mt-4">
                <p className="flex-grow secondary-inverse--color secondary-font text-16 font-weight-bold">
                    {textosTienda?.tienda?.casa?.subtitulo1 || "¡Explora los paquetes de libros que tenemos para ti!"}
                </p>
                <div className="flex-grow-1 secondary-inverse--bg ml-4" style={{ height: 2 }}></div>
            </div>

            <div className="col-xl-10 mx-auto px-0 mt-4">
                {
                    plans.map((plan, index) => {
                        const finalPrice = plan?.precio && pais === 'CO' ? plan?.precio?.cop || 'n/a' : plan?.precio?.usd || 'n/a';
                        const moneda = pais === 'CO' ? 'COP' : 'USD';
                        const isOffer = plan?.descuento?.activado;
                        const offer = plan?.descuento && pais === 'CO' ? plan?.descuento?.cop || 'n/a' : plan?.descuento?.usd || 'n/a';

                        return (
                            <PlanCard.box key={`plan-${index}`}>
                                <PlanCard.columnLeft
                                    className={plan?.className}
                                    image={plan?.imgUrl?.normal}
                                >
                                    <div className="">
                                        <h3 className="secondary-font text-28">
                                            {_.get(textosTienda, plan?.nombre?.translationPath) || plan?.nombre?.default}
                                        </h3>
                                    </div>
                                </PlanCard.columnLeft>

                                <PlanCard.columnRight className={'column-right'}>
                                    <PlanCard.details>
                                        <div className="py-4 p-md-4">
                                            <h4 className="secondary-font violet-inverse--color text-22 text-line-h-22">
                                                {_.get(textosTienda, plan?.descripcion?.translationPath) || plan?.descripcion?.default}
                                            </h4>

                                            <ul className="my-3 features-list">
                                                {plan?.detalles.map((element, i) => {
                                                    return (
                                                        <li key={`element-${i}`} className="d-flex align-items-center text-16 mb-2">
                                                            <i className="fas fa-solid fa-check mt-1 mr-3 violet-inverse--color"></i>
                                                            <p
                                                                className="onprimary--color"
                                                                dangerouslySetInnerHTML={{ __html: _.get(textosTienda, element?.translationPath) || element?.default || 'N/A' }}
                                                            ></p>
                                                        </li>
                                                    )
                                                })}
                                            </ul>

                                            {plan?.extraInformation?.activado &&
                                                <>
                                                    <p
                                                        className="red-inverse--color font-weight-bold"
                                                        dangerouslySetInnerHTML={{ __html: _.get(textosTienda, plan?.extraInformation?.titulo?.translationPath) || plan?.extraInformation?.titulo?.default || 'N/A' }}
                                                    ></p>

                                                    <ul className="features-list mb-4">
                                                        {plan.extraInformation.detalles.map((element, i) => {
                                                            return (
                                                                <li key={`element-${i}`} dangerouslySetInnerHTML={{ __html: _.get(textosTienda, element?.translationPath) || element?.default || 'N/A' }}></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            }

                                            <Link to={'/home'} className="btn-ver-catalogo text-14">
                                                {textosTienda?.tienda?.ver_catalogo || 'Ver catálogo de libros'}
                                            </Link>
                                        </div>
                                    </PlanCard.details>

                                    <PlanCard.PriceDescription>
                                        <div>
                                            {isOffer &&
                                                <p className="font-weight-normal" >
                                                    <span style={{ fontSize: '.7em', textDecorationLine: 'line-through' }}>
                                                        ${finalPrice} {moneda}
                                                    </span>
                                                    <span className="font-weight-bold" style={{ fontSize: '.7em' }}> {offer?.cantidad} OFF</span>
                                                </p>
                                            }
                                            <p className="font-weight-bold onprimary--color secondary-font">
                                                <span className="currency">{moneda}</span>
                                                <br />
                                                <span className="text-20">${isOffer ? offer?.precio : finalPrice}</span>
                                            </p>
                                        </div>
                                    </PlanCard.PriceDescription>

                                    <PlanCard.getButtons>
                                        <button
                                            className={`btn-tarjeta-plan focusable-red`}
                                            onClick={() => goToPlan(plan?.sectionUrl)}
                                        >
                                            {textosTienda?.individual?.interfaz?.btn_quiero || '¡Lo quiero!'}
                                        </button>
                                    </PlanCard.getButtons>
                                </PlanCard.columnRight>
                            </PlanCard.box>
                        )
                    })
                }

            </div>
        </div>
    )
}