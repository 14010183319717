import { useEffect, useState } from "react";
import { clearDB, deleteDB, diasVencimientoDescarga, getAllFromIndexDB } from "../../components/Store";
import moment from "moment";

/**
 * Hook personalizado para gestionar los libros descargados en la aplicación.
 *
 * @returns {Object} Objeto con las siguientes propiedades y funciones:
 * - `loading` {boolean}: Indica si los libros se están cargando.
 * - `books` {Array}: Lista de libros disponibles, con metadatos como título, autor, portada, fecha de descarga y fecha de vencimiento.
 * - `deleteAll` {Function}: Elimina todos los libros descargados de la base de datos.
 * - `deleteOne` {Function}: Elimina un libro específico por su `bookId`.
 * 
 * @description
 * Este hook permite gestionar los libros descargados en la aplicación, incluyendo la carga de los libros desde IndexedDB, filtrado de los que están desactualizados y su eliminación cuando caducan.
 * También proporciona la funcionalidad para eliminar libros individuales o todos los libros almacenados.
 */
export const useDownloadedBooksManager = () => {
	const [loading, setLoading] = useState(true);
	const [books, setBooks] = useState([]);

	const noOutdatedBooks = async ({ books }) => {
		const outdatedBooksIds = [];
		const filtered = books?.filter((book) => {
			const today = moment();
			const duedate = book?.dueDate;
			const outdated = moment(today).isSameOrAfter(duedate);
			if (outdated) {
				outdatedBooksIds.push(book.bookdId);
			}
			return !outdated;
		});
		await Promise.all(
			outdatedBooksIds.map(async (bookId) => {
				return deleteOne({ bookId });
			})
		).catch((error) => console.log(error));
		return filtered;
	};

	const loadBooks = async () => {
		setLoading(true);
		await getAllFromIndexDB("tipo", "metadatos")
			.then(async (results) => {
				const _books = results?.map((book) => {
					const metadata = book?.metadatos;
					const downloadDate = moment(book?.fecha).format("YYYY-MM-DD hh:mm A");
					const dueDate = moment(downloadDate)
						.add(diasVencimientoDescarga, "days")
						.format("YYYY-MM-DD hh:mm A");
					return {
						bookId: book?.idLibro,
						title: metadata?.titulo,
						author: metadata?.autor,
						cover: `/makemake${metadata?.portada}`,
						downloadDate,
						dueDate,
						link: `/visor/${book?.idLibro}`,
					};
				});
				const availableBooks = await noOutdatedBooks({ books: _books });
				setBooks(availableBooks);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		loadBooks();
	}, []);

	const deleteAll = async () => {
		await clearDB();
		setBooks([]);
	};

	const deleteOne = async ({ bookId }) => {
		await getAllFromIndexDB("idLibro", bookId).then(async (resources) => {
			resources.map((resource) => {
				const key = resource?.url;
				deleteDB(key);
			});
		});
		setBooks((books) => books?.filter((book) => book?.bookId !== bookId));
	};

	return {
		loading,
		books,
		deleteAll,
		deleteOne,
	};
};
