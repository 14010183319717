import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonFichaLibro = (props) => {

    const { isLogged } = props;

    //Render ficha
    return (
        <SkeletonTheme color="#e8e8e8" highlightColor="#f7f7f7">
            <div className="main-container">
                <div className="container contenedor-ficha p-4 p-lg-5">
                    <Skeleton
                        style={{ maxWidth: '100%' }}
                        width={400} height={40} />

                    <nav style={{ maxWidth: '100%' }} className="nav-ficha mt-3 mb-5 d-flex justify-content-end">
                        <div style={{ maxWidth: '100%', width: 200 }}>
                            <Skeleton
                                className={`px-3 py-2 nav-button-ficha`} width={'100%'} height={40} />
                        </div>
                        <div style={{ maxWidth: '100%', width: 200 }}>
                            <Skeleton className={`px-3 py-2 ml-3 nav-button-ficha `} width={'100%'} height={40} />
                        </div>
                    </nav>

                    <div className="row">
                        <aside className="col-lg-4 mb-4 mb-lg-0">
                            <Skeleton className={`d-block mx-auto px-3 py-2 ml-3 nav-button-ficha `} width={208} height={324} />

                            <Skeleton className="d-block mx-auto mt-4" width={250} height={40} />

                            <Skeleton className="d-block mx-auto mt-4" width={200} height={40} />
                        </aside>
                        <section className="col-lg-8">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="mb-4">
                                        <Skeleton style={{ minWidth: '70%' }} width={300} height={40} />
                                        <Skeleton width={200} height={30} />
                                    </div>
                                    <div className="mb-4">
                                        <Skeleton style={{ minWidth: '70%' }} width={300} height={40} />
                                        <Skeleton width={200} height={30} />
                                    </div>
                                    <div className="mb-4">
                                        <Skeleton style={{ minWidth: '70%' }} width={300} height={40} />
                                        <Skeleton width={200} height={30} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-md-4 mb-4 mb-md-0">
                                        <Skeleton style={{ minWidth: '100%' }} width={200} height={50} />
                                    </div>
                                    <div className="col-md-4 mb-4 mb-md-0">
                                        <Skeleton style={{ minWidth: '100%' }} width={200} height={50} />
                                    </div>
                                    <div className="col-md-4">
                                        <Skeleton style={{ minWidth: '100%' }} width={200} height={50} />
                                    </div>
                                </div>
                            </div>
                            <div className="fichalibro-data--text my-4">
                                <Skeleton width={'100%'} height={100} />
                            </div>
                        </section>
                    </div>

                    <div className=" d-md-flex mx-0 justify-content-md-end mt-3">
                        <div style={{ maxWidth: '100%', width: 200 }}
                        >
                            <Skeleton className=" mr-md-3 mb-3 mb-md-0" width={'100%'} height={40} />
                        </div>
                        <div tyle={{ maxWidth: '100%', width: 200 }}>
                            <Skeleton width={'100%'} height={40} />
                        </div>

                    </div>

                    <section>
                        <div className="ficha-subtitle mt-4">
                            <Skeleton style={{ maxWidth: '100%' }} width={300} height={30} />
                            <hr />
                        </div>
                        <div className="content-temas my-4">
                            <div className="d-flex justify-content-center justify-content-lg-between flex-wrap">
                                <Skeleton width={200} height={180} />
                            </div>
                        </div>

                    </section>

                    <div className="ficha-subtitle">
                        <Skeleton width={300} height={30} />
                        <hr />
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default SkeletonFichaLibro;
