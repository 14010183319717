import React from "react";

/**
 * Icono de la casita
 * @param {string} colorLetra -color por defecto de la fuente
 * @param {string} className - clase aplicada al icono
 * @returns {JSX.Element} ExploreIcon
 */
export const ExploreIcon = ({ colorLetra, className }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 31 29.8"
      style={{ enable: "new 0 0 31 29.8", width: 30 }}
      space="preserve"
      className={className ? className : ''}
    >
      <path
        style={{ fill: colorLetra ? colorLetra : '#000'}}
        className="st0"
        d="M16,5.3c-0.3-0.2-0.7-0.2-0.9,0L0.7,13c-0.9,0.5-0.6,1.9,0.5,1.9h5.1c0.6,0,1.1,0.5,1,1.2L5.4,28.3
c-0.1,0.6,0.4,1.2,1,1.2h18.2c0.6,0,1.1-0.6,1-1.2L23,16.2c-0.1-0.6,0.3-1.2,1-1.2h5.9c1,0,1.4-1.4,0.5-1.9L16,5.3z M12.5,20.2
c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1v9h-6V20.2z"
      />
      <path
        style={{ fill: colorLetra ? colorLetra : '#000'}}
        className="st1"
        d="M28.7,3.5l-5.3-2.4c-0.1-0.1-0.3,0-0.3,0.2V6l5.6-2.1C28.8,3.8,28.8,3.6,28.7,3.5z"
      />
      <path
        style={{ 
          strokeWidth: colorLetra ? colorLetra : '#000', 
          fill: colorLetra ? colorLetra : '#000'
        }}
        className="st2"
        d="M23.1,11V6 M23.1,6V1.3c0-0.1,0.2-0.2,0.3-0.2l5.3,2.4c0.2,0.1,0.2,0.3,0,0.4L23.1,6z"
      />
    </svg>
  )
}