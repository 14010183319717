
export const TRAINING_DATA = [
  {
    alt: 'imagen plan de acompanamiento',
    src: '/img/bibliotecas-que-es/plan_acompanamiento.png',
    width: '100px',
    text: {
        default: 'Plan de acompañamiento con tres libros recomendados mensuales y actividades asociadas',
        translationPath: 'acompanamiento.plan'
    }
  },
  {
    alt: 'imagen cursos online',
    src: '/img/bibliotecas-que-es/cursos_online.png',
    width: '100px',
    text: {
        default: 'Capacitación para mediadores y promotores',
        translationPath: 'acompanamiento.curso'
    }
  },
  {
    alt: 'imagen marketing',
    src: '/img/bibliotecas-que-es/marketing.png',
    width: '150px',
    text: {
        default: 'Kit de marketing digital con tus logos ',
        translationPath: 'acompanamiento.kit'
    }
  },
  {
    alt: 'icono eventos virtuales',
    src: '/img/bibliotecas-que-es/eventos_virtuales.png',
    width: '130px',
    text: {
        default: 'Eventos virtuales en vivo con autores y expertos en lectura y educación',
        translationPath: 'acompanamiento.eventos'
    }
  },
  
];