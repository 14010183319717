import React from "react";//Swiper
import SwiperCore, { Navigation, Pagination, Keyboard, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper.scss';
import Skeleton from "react-loading-skeleton";

SwiperCore.use([Navigation, Pagination, Keyboard, Virtual]);
const SkeletonLibrosHome = (props) => {

  const { seccion } = props;

  const SlidesLibrosColeccion = () => {
    return (
      <>
        {(() => {
          var libros = [];
          for (let i = 0; i < 10; i++) {
            libros.push(
              <SwiperSlide className="py-3" key={`libro-${seccion}-${i}`}>
                <Skeleton height={250} />
                <Skeleton className="my-4" height={30} />
              </SwiperSlide>
            )
          }
          return libros;
        })()}
      </>
    )
  }

  return (
    <>
      {
        seccion === "interactive-covers" && (
          <Swiper
            autoplay={7000}
            breakpoints={{
              768: {
                slidesPerView: 5,
                slidesPerGroup: 5,
              },
              1200: {
                slidesPerView: 6,
                slidesPerGroup: 6,
              },
            }}
            className="main-covers px-5 pb-3 pb-lg-5"
            keyboard={true}
            loop={true}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
            }}
            spaceBetween={30}
            slidesPerGroup={2}
            slidesPerView={2}
          >
            {SlidesLibrosColeccion()}
          </Swiper>
        )
      }
      {
        seccion === "main-covers" && (
          <Swiper
            breakpoints={{
              526: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              992: {
                slidesPerView: 5,
                slidesPerGroup: 5
              },
              1400: {
                slidesPerView: 6,
                slidesPerGroup: 6
              }
            }}
            className="main-covers px-5 pb-3 pb-lg-5"
            keyboard={true}
            loop={true}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
            }}
            spaceBetween={30}
            slidesPerGroup={2}
            slidesPerView={2}
          >
            {SlidesLibrosColeccion()}
          </Swiper>
        )
      }
    </>
  )
}

export default SkeletonLibrosHome;
