import React from "react";
import PodemosAyudar from "../../PodemosAyudar";

import { TitlePage } from "./components/title-page";
import { Introduction } from "./components/introduction";
import { Training } from "./components/training";
import { Discover } from "./components/discover";
import { Features } from "./components/features";
import { MakeMakeMedia } from "../components/media";
import { Experience } from "./components/experience";

/**
 * Este componente no recibe props. Este renderiza la pantalla de "¿qué es bibliotecas?". Para esto hace uso del componente TitlePage, Introduction, Training, Discover, Features, MakeMakeMedia, Experience, PodemosAyudar y Img. 
 * @returns {JSXElement} QueEsBibliotecas
 */

const QueEsBibliotecas = () => {

  return (
    <div className="que-es-bibliotecas">
      <div className="container-fluid px-0 fuente-semibold margin-top-header">

        <TitlePage />
        <Introduction />
        <Training />
        <Discover />
        <Features />
        <MakeMakeMedia background={'primary--bg'} />
        <Experience />

      </div>
      <PodemosAyudar />
    </div>
  )
}
export default QueEsBibliotecas;