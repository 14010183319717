import React from "react"

import PropTypes from 'prop-types';

/**
 * Componente que se debe usar cuando no hay resultados de una busqueda, o no se encontró algún recurso.
 */
export const NoResults = ({ title, message }) => {
    return (
        <div className="no-results w-100 row">
            <div className="onbackground-primary--color col-lg-7 my-auto">
                <p className="title-no-results onbackground-primary--color font-weight-bold">
                    {title}
                </p>
                <div className="onbackground-primary--color">{message}</div>
            </div>
            <div className="w-100 col-lg-5  text-center mx-auto py-3">
                <img
                    src={`/img/noresults.png`}
                    className="img-no-results w-100 justify-center"
                    alt="no-results"
                />
            </div>
        </div>
    )
}

NoResults.propTypes = {
    /**
     * titulo que se mostrará 
     */
    title:PropTypes.string,
    /**
     * texto, elemento html o un componente de React.
     */
    message:PropTypes.any
}