import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { AccesibilidadContext, TemasDisponibles } from "../../contexts/AccesibilidadContext";
import { ContentHeader } from "./components/content-header";
import { MapSection } from "./components/map-section";
import { BenefitsSection } from "./components/benefits-section";
import { RequirementsSection } from "./components/requirements";
import { HelpSection } from "./components/help-section";
import { Tutorials } from "./components/tutorials";
import { FAQSection } from "./components/faq";
export const PilotoRNBP = () => {
	const title = "Proyecto Piloto de lectura Digital Bibliotecas Públicas - RNBP";
	const mainDescription = "Proyectos de lectura digital para el sector publico, RNBP";
	const accesibility = useContext(AccesibilidadContext);
	const isHighContrast = accesibility.temaActual === TemasDisponibles.altoContraste.id;

	return (
		<div className="piloto-rnbp">
			<Helmet>
				<title>MakeMake - {title}</title>
				<meta name="description" content={mainDescription} />
			</Helmet>
			<div className="">
				<ContentHeader
					title={title}
					backgroundColor={isHighContrast ? "var(--secondary-inverse)" : "#6666cc"}
					color={isHighContrast ? "var(--onsecondary-inverse)" : "#fff"}
				/>
				<div className="container piloto-rnbp-content p-0">
					<p className="secondary-font main-description pt-5 pb-3 p-3 onbackground-primary--color">
						La Red Nacional de Bibliotecas Públicas - RNBP seleccionó{" "}
						<span className="primary-inverse--bg onprimary-inverse--color">
							155 bibliotecas
						</span>{" "}
						para participar en un proyecto piloto de lectura digital por medio de la
						plataforma MakeMake. Las bibliotecas del piloto recibirán capacitación y
						participarán en encuestas y entrevistas.
					</p>
					<MapSection />
					<BenefitsSection />
					<RequirementsSection/>
					<Tutorials/>
					<FAQSection/>
					<HelpSection/>
					<div className="d-flex justify-content-center align-items-center my-5">
					<img className="img-fluid" style={{maxWidth:'800px'}} src="/img/piloto-rnbp/logos.svg" alt="logos"/>
					</div>
				</div>
			</div>
		</div>
	);
};
