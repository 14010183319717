/**
 * Arreglo de objetos que contiene la información de los CURSOS
 * que se venden en el PLAN CASA - APRENDER ALGO NUEVO.
 */
export const CURSOS_DATA = [
	{
		id: "leer-la-web",
		className: "card-blue",
		imgUrl: "/img/tienda-makemake/lectura-web.png",
		nombre: {
			default: "Lectura digital en la web",
			translationPath: "lectura_digital.nombre",
		},
		precio: {
			usd: "47.80",
			cop: "199.000",
		},
		preventa: {
			activado: false,
			text: {
				default: "",
				translationPath: "",
			},
		},
		descuento: {
			activado: false,
			usd: {
				cantidad: "10%",
				precio: "16",
			},
			cop: {
				cantidad: "10%",
				precio: "59.000",
			},
		},
		descripcion: {
			default: "ONLINE - 20 HORAS - CURSO EN ESPAÑOL",
			translationPath: "lectura_digital.descripcion_general",
		},
		autor: {
			default: "Creado por <span>MakeMake</span>",
			translationPath: "lectura_digital.autor",
		},
		trailer: "https://academia.makemake.com.co/cursos/CA00005/ficha",
		detalles: [
			{
				default: `Aprende a <strong>usar la web de forma consciente y crítica</strong>, descubriendo el contexto digital
                como un espacio de aprendizaje y formación constante.`,
				translationPath: "lectura_digital.detalles.li_uno",
			},
			{
				default: `<strong>Incluye 8 módulos </strong> enfocados en desarrollar habilidades de Alfabetización digital.`,
				translationPath: "lectura_digital.detalles.li_dos",
			},
			{
				default: `Ideal para docentes, promotores/as de  lectura,madres y padres de familia.`,
				translationPath: "lectura_digital.detalles.li_tres",
			},
			{
				default: `
				<strong>¡Solo por este año el curso trae dos sesiones sincrónicas!</strong>
					<br/>
					<ul>
					<li style="margin-left:15px"><span class="secondary-inverse--color font-weight-bold">03 de octubre: </span>Nathalia Roa (formadora digital en Bibliored) conversará sobre la IA en los entornos de aprendizaje.</li>
					<li style="margin-left:15px"><span class="secondary-inverse--color font-weight-bold">28 de octubre: </span>Catalina Holguín profundiza en los contenidos del curso.</li>
					</ul>
`,
				translationPath: "lectura_digital.detalles.li_cuatro",
			},
		],
		extraInformation: {
			activado: false,
		},
		epayco: {
			nombre: "Curso - Lectura digital en la web",
			descripcion: "Curso - Lectura digital en la web",
			precio: {
				usd: 47.80,
				cop: 199000,
			},
			descuento: {
				activado: false,
				usd: {
					cantidad: "10%",
					precio: 16,
				},
				cop: {
					cantidad: "10%",
					precio: 59000,
				},
			},
		},
	},

	{
		id: "tiempo-irrepetible",
		className: "card-pink",
		imgUrl: "/img/tienda-makemake/yolanda.png",
		nombre: {
			default: "El tiempo irrepetible",
			translationPath: "tiempo_irrepetible.nombre",
		},
		precio: {
			usd: "89.9",
			cop: "350.000",
		},
		preventa: {
			activado: false,
			text: {
				default: "Preventa hasta el 21 de abril",
				translationPath: "tiempo_irrepetible.temporada.preventa",
			},
		},
		descuento: {
			activado: false,
			usd: {
				cantidad: "10%",
				precio: "25",
			},
			cop: {
				cantidad: "10%",
				precio: "99.000",
			},
		},
		descripcion: {
			default: "ONLINE – 14 HORAS – CURSO EN ESPAÑOL",
			translationPath: "tiempo_irrepetible.descripcion_general",
		},
		autor: {
			default: `Dictado por <strong>Yolanda Reyes</strong>  <br/>
            Un curso de <strong> Espantapájaros </strong>`,
			translationPath: "tiempo_irrepetible.autor",
		},
		trailer: "https://academia.makemake.com.co/cursos/CA00004/ficha",
		detalles: [
			{
				default: `<strong>Aprende a integrar la literatura y el arte en la educación inicial </strong> por medio de guías y
                presentaciones especializadas.`,
				translationPath: "tiempo_irrepetible.detalles.li_uno",
			},
			{
				default: `<strong>Incluye 5 módulos con 14 horas</strong> de lecturas, videos, textos complementarios y materiales interactivos disponibles 24/7.`,
				translationPath: "tiempo_irrepetible.detalles.li_dos",
			},
			{
				default: `Ideal para educadores de pre-escolar, pedagogos, bibliotecarias y padres y madres de familia que quieren fomentar la lectura en niños entre los 0 y 4 años.`,
				translationPath: "tiempo_irrepetible.detalles.li_tres",
			},
		],
		extraInformation: {
			activado: false,
			titulo: {
				default: "Temporada 2024",
				translationPath: "tiempo_irrepetible.temporada.titulo",
			},
			incluye: {
				default: "Incluye:",
				translationPath: "tiempo_irrepetible.temporada.incluye",
			},
			detalles: [
				{
					default: "Curso completo",
					translationPath: "tiempo_irrepetible.temporada.detalles.li_uno",
				},
				{
					default: "Sesión Virtual con Yolanda Reyes",
					translationPath: "tiempo_irrepetible.temporada.detalles.li_dos",
				},
				{
					default:
						"Sesión presencial con Yolanda Reyes y recorrido Espantapájaros (*Se transmite de forma virtual para residentes fuera de Colombia)",
					translationPath: "tiempo_irrepetible.temporada.detalles.li_tres",
				},
				{
					default: "Certificado de Asistencia",
					translationPath: "tiempo_irrepetible.temporada.detalles.li_cuatro",
				},
			],
		},
		epayco: {
			nombre: "Curso - El tiempo irrepetible",
			descripcion: "Curso - El tiempo irrepetible",
			precio: {
				usd: 89.9,
				cop: 350000,
			},
			descuento: {
				activado: false,
				usd: {
					cantidad: "10%",
					precio: 25,
				},
				cop: {
					cantidad: "10%",
					precio: 99000,
				},
			},
		},
	},

	{
		id: "ilustracion-botanica",
		className: "card-green",
		imgUrl: "/img/tienda-makemake/botanica.png",
		nombre: {
			default: "Ilustración botánica en el páramo",
			translationPath: "ilustracion_botanica.nombre",
		},
		precio: {
			usd: "35.99",
			cop: "156.000",
		},
		preventa: {
			activado: false,
			text: {
				default: "",
				translationPath: "",
			},
		},
		descuento: {
			activado: false,
			usd: {
				cantidad: "10%",
				precio: "32.39",
			},
			cop: {
				cantidad: "10%",
				precio: "140.400",
			},
		},
		descripcion: {
			default: "ONLINE- 12-14 HORAS – CURSO EN ESPAÑOL",
			translationPath: "ilustracion_botanica.descripcion_general",
		},
		autor: {
			default: `Dictado por <strong>Lisa Anzellini</strong>  <br/>
            Un curso de <strong> Liebre Lunar</strong>`,
			translationPath: "ilustracion_botanica.autor",
		},
		trailer: "https://academia.makemake.com.co/cursos/CA00001/ficha",
		detalles: [
			{
				default: `Un curso único <strong>para aprender a dibujar y aprender sobre el ecosistema de páramo,</strong> de la mano de la artista Liza Anzellini y Liebre Lunar. `,
				translationPath: "ilustracion_botanica.detalles.li_uno",
			},
			{
				default: `<strong>Incluye 5 módulos</strong>, 7 ejercicios de dibujo y 19 videos sobre técnica de ilustración.`,
				translationPath: "ilustracion_botanica.detalles.li_dos",
			},
			{
				default: `Ideal para amantes del dibujo y la naturaleza `,
				translationPath: "ilustracion_botanica.detalles.li_tres",
			},
		],
		extraInformation: {
			activado: false,
		},
		epayco: {
			nombre: "Curso - Ilustración botánica en el páramo",
			descripcion: "Curso - Ilustración botánica en el páramo",
			precio: {
				usd: 35.99,
				cop: 156000,
			},
			descuento: {
				activado: false,
				usd: {
					cantidad: "10%",
					precio: 32.39,
				},
				cop: {
					cantidad: "10%",
					precio: 140400,
				},
			},
		},
	},
];
