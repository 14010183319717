import platform from "platform";

/**
 * Retorna un array de plataformas recomendadas con sus respectivas versiones.
 * @returns {[{os:{nombre:string, version:string}, navegadores:[{nombre:string, version:string}]}]} Array de objetos que representan las plataformas recomendadas.
 */
const plataformasRecomendadas = () => {
    return [
        {
            os: {
                nombre: 'windows',
                version: ''
            },
            navegadores: [
                {
                    nombre: 'chrome',
                    version: '103+'
                },
                {
                    nombre: 'edge',
                    version: '103+'
                },
            ],
        },
        {
            os: {
                nombre: 'android',
                version: ''
            },
            navegadores: [
                {
                    nombre: 'chrome',
                    version: '103+'
                },
                {
                    nombre: 'edge',
                    version: '103+'
                },
            ],
        },

        {
            os: {
                nombre: 'ios',
                version: ''
            },
            navegadores: [
                {
                    nombre: 'safari',
                    version: '11.3+'
                },
                {
                    nombre: 'edge',
                    version: '103+'
                },
            ],
        },
        {
            os: {
                nombre: 'ipados',
                version: ''
            },
            navegadores: [
                {
                    nombre: 'safari',
                    version: '11.3+'
                },
                {
                    nombre: 'edge',
                    version: '103+'
                },
            ],
        },
        {
            os: {
                nombre: 'os x',
                version: ''
            },
            navegadores: [
                {
                    nombre: 'chrome',
                    version: '103+'
                },
                {
                    nombre: 'edge',
                    version: '103+'
                },
            ],
        },
        {
            os: {
                nombre: 'linux',
                version: ''
            },
            navegadores: [
                {
                    nombre: 'chrome',
                    version: '103+'
                },
                {
                    nombre: 'edge',
                    version: '103+'
                },
            ],
        },
    ]
}

/**
 * Verifica si una plataforma es recomendada.
 * @param {Object} plataforma - Objeto que representa la plataforma con los siguientes campos:
 *   - navegador: Objeto con los campos nombre (String) y version (String).
 *   - os: Objeto con los campos nombre (String) y version (String).
 * @returns {boolean} Devuelve true si la plataforma es recomendada, de lo contrario, false.
 */
const esRecomendada = ({ navegador, os }) => {
    let recomendado = false;
    for (let i = 0; i < plataformasRecomendadas().length; i++) {
        const plataformaRecomendada = plataformasRecomendadas()[i];
        if (
            os?.nombre?.toLowerCase()?.includes(plataformaRecomendada?.os?.nombre?.toLowerCase())
        ) {
            for (let j = 0; j < plataformaRecomendada?.navegadores?.length; j++) {
                const browser = plataformaRecomendada.navegadores[j];
                if (navegador?.nombre?.toLowerCase()?.includes(browser?.nombre?.toLowerCase()) &&
                esVersionSuperiorOIgual(
                    navegador?.version?.toLowerCase(),
                    browser?.version?.toLowerCase()
                )
                ) {
                  recomendado = true;
                    break;
                }
            }
            break;
        }
    }
    return recomendado;
}

/**
 * Compara dos versiones y verifica si la versión A es igual o superior a la versión objetivo.
 * @param {string} versionAevaluar - Versión a evaluar.
 * @param {string} versionObjetivo - Versión objetivo.
 * @returns {boolean} Devuelve true si la versión A es igual o superior a la versión objetivo, de lo contrario, false.
 */
const esVersionSuperiorOIgual = (versionAevaluar, versionObjetivo)=>{
    const normalizedVersionObjetivo = parseFloat(versionObjetivo.replace('+','').split('.')[0]);
    const normalizedVersionAevaluar = parseFloat(versionAevaluar.replace('+','').split('.')[0]);
    return normalizedVersionAevaluar >= normalizedVersionObjetivo?true:false
}

/**
 * Obtiene la plataforma actual del usuario, incluyendo el navegador y el sistema operativo.
 * @returns {Object} Objeto que representa la plataforma actual con los siguientes campos:
 *   - navegador: Objeto con los campos nombre (String) y version (String).
 *   - os: Objeto con los campos nombre (String) y version (String).
 */
const obtenerPlataforma = ()=>{
    const ipadOS = esIpad();
    return {
        navegador:{
            nombre:platform?.name?.toLowerCase(),
            version:platform?.version
        },
        os:{
            nombre:ipadOS ===false?platform?.os?.family?.toLowerCase():'ipados',
            version:platform?.os?.version
        }
    };
}

/**
 * Obtiene información sobre el navegador actual del usuario.
 * @returns {Object} Objeto que representa el navegador actual con los campos nombre (String) y version (String).
 */
const obtenerNavegador = ()=>{
    return {
        nombre:platform?.name?.toLowerCase(),
        version:platform?.version
    }
}

/**
 * Obtiene información sobre el sistema operativo actual del usuario.
 * @returns {Object} Objeto que representa el sistema operativo actual con los campos nombre (String) y version (String).
 */
const obtenerOS = ()=>{
    const ipadOS = esIpad();
    return {
        nombre:ipadOS ===false?platform?.os?.family?.toLowerCase():'ipados',
        version:platform?.os?.version
    }
}

/**
 * Verifica si el dispositivo actual es un iPad.
 * @returns {boolean} Devuelve true si el dispositivo actual es un iPad, de lo contrario, false.
 */
const esIpad = ()=>{
    return (navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 0) || navigator?.platform === 'iPad'
}

/**
 * Verifica si el sistema operativo actual contiene al menos uno de los nombres de sistemas operativos especificados.
 * @param {string|string[]} nombresOS - Nombres de sistemas operativos a verificar.
 * @returns {boolean} Devuelve true si el sistema operativo actual contiene al menos uno de los nombres especificados, de lo contrario, false.
 */
const contieneAlmenosUnOS = (nombresOS)=>{
    let contieneOS = false; 
    const splitted = typeof nombresOS === 'string'?nombresOS?.split(';'):['null'];
    for (let i = 0; i < splitted?.length; i++) {
        const os = splitted[i];
        if(platform?.os?.family?.toLowerCase().includes(os?.toLowerCase())){
            contieneOS = true;
            break;
        } 
    }
    return contieneOS;
}

export const Plataforma = {
    esRecomendada,
    obtenerNavegador,
    obtenerOS,
    obtenerPlataforma,
    plataformasRecomendadas,
    esVersionSuperiorOIgual,
    contieneAlmenosUnOS,
    esIpad
}