import React from "react";
import { Icon } from "@iconify/react";
import { EventCard } from "./event-card";

export const Events = ({ events, loading, lang }) => {

	return (
		<div className="container">
			{loading ? (
				<div
					className="w-100 h-100 d-flex justify-content-center align-items-center secondary-inverse--color"
					style={{ minHeight: 300 }}
				>
					<Icon icon="icomoon-free:spinner9" width={60} className={"spinner-rotation"} />
				</div>
			) : (
				<div className="w-100 d-flex justify-content-center align-items-center flex-column">
					{events?.map((event, i) => {
						return (
							<EventCard
								key={`event-${event?.mes}-${event?.titulo?.es}-${i}`}
								eventData={event}
								lang={lang}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};
