import React from "react";
import { SPONSORS } from "../data/sponsors";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

export const Sponsors = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const texts = useTranslations({ section: "footer", lang: lang });

	const sponsors = [...SPONSORS];
	return (
		<div className="d-flex flex-column flex-row justify-content-center align-items-center">
			<p>{texts?.apoyo_de?.titulo || "Con el apoyo de"} </p>
			<div className="d-flex flex-wrap justify-content-center align-items-center">
				{sponsors?.map((sponsor, i) => {
					return (
						<a
							key={i}
							className="m-2 d-flex justify-content-center align-items-center"
							aria-label="sponsor"
							href={sponsor.url}
							rel="noopener noreferrer"
							target="_blank"
							style={{ width: 100 }}
						>
							<img
								key={i}
								className="img-fluid"
								src={sponsor?.imgUrl}
								alt={`sponsor-${i}`}
							/>
						</a>
					);
				})}
			</div>
		</div>
	);
};
