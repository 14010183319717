import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Icon } from "@iconify/react";
import { store } from "../../Store";

import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { useAdvancedSearch } from "../../../hooks/advanced-search/use-advanced-search";

import { ExploreIcon } from "../../header/components/explore-icon";
import { DropdownGeneralMenu } from "./components/DropdownGeneralMenu";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { MobileBottomNavBarNotificationButton } from "../../header/components/notification-button";

/**
 * Componente que contiene las opciones del menu inferior en modo mobile. 
 * Estas opciones son las de la cuenta general o institucional
 * (explorar, buscar, notificaciones y ajustes)
 * @returns {JSX.Element} IndividualMenu
 */
export const GeneralMenu = () => {
    const location = useLocation();
    const globalState = useContext(store);

    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });
    const advancedSearch = useAdvancedSearch();
    const advancedSearchButtonRef = useRef(null);
    const history = useHistory();

    const [dropdownGeneralMenu, setOpenDropdownGeneralMenu] = useState(false);

    /*Asigna el botón que abre las opciones de búsqueda */
    useEffect(() => {
        if (advancedSearchButtonRef.current) {
            globalState.dispatch({
                type: "CHANGE",
                path: "advancedSearch.triggerButton",
                value: advancedSearchButtonRef,
            });
        }
    }, [advancedSearchButtonRef]);

    return (
        <>
            <div className={`dropdownGeneralMenu ${dropdownGeneralMenu ? 'activeMode' : 'inactiveMode'} `}>
                <DropdownGeneralMenu
                    setOpenDropdown={setOpenDropdownGeneralMenu}
                    openDropdown={dropdownGeneralMenu}
                    textosInterfaz={textosInterfaz}
                />
            </div>

            <ul className="d-flex justify-content-center">
                <li className="col-3 px-0">
                    <Link
                        to="/"
                        className={`btn-option-menu focusable-red ${location.pathname === '/home' ? "activeMode" : ""}`}
                        onClick={() => setOpenDropdownGeneralMenu(false)}
                    >
                        <ExploreIcon colorLetra={location.pathname === '/home' ? 'var(--onsecondary-inverse)' : 'var(--onbackground-primary)'} />
                        <span>{textosInterfaz?.explorar || 'Explorar'}</span>
                    </Link>
                </li>
                <li className="col-3 px-0">
                    <button
                        ref={advancedSearchButtonRef}
                        className={`btn-option-menu focusable-red ${advancedSearch?.state?.visible ? 'activeMode' : ''}`}
                        onClick={() => {
                            setOpenDropdownGeneralMenu(false);
                            advancedSearch.setVisible(!advancedSearch?.state?.visible);
                        }}
                    >
                        <Icon icon="tabler:search" width="30" style={{ color: advancedSearch?.state?.visible ? 'var(--onsecondary-inverse)' : 'var(--onbackground-primary)' }} />
                        <span>{textosInterfaz?.buscar || 'Buscar'}</span>
                    </button>
                </li>
                <MobileBottomNavBarNotificationButton onClick={()=>setOpenDropdownGeneralMenu(false)} translations={textosInterfaz}/>
                <li className="col-3 px-0">
                    <button
                        className={`btn-option-menu focusable-red ${dropdownGeneralMenu ? 'activeMode' : ''}`}
                        onClick={() => setOpenDropdownGeneralMenu(!dropdownGeneralMenu)}
                    >
                        <Icon icon="material-symbols:settings" width="30" />
                        <span>{textosInterfaz?.ajustes || 'Ajustes'}</span>
                    </button>
                </li>
            </ul>
        </>
    )
} 