import React, { useContext, useEffect, useState } from "react";
import { useTimeZonesDates } from "../../hooks/use-timezones-dates";
import { store } from "../../components/Store";

/**
 * Componente `NotibannerHours` que muestra las horas en diferentes zonas horarias y permite la selección de una hora específica.
 *
 * Este componente realiza las siguientes acciones:
 * - Usa el hook `useTimeZonesDates` para obtener las horas en diferentes zonas horarias basadas en una fecha y zona horaria de origen.
 * - Obtiene el país del usuario desde el contexto global `store`.
 * - Define un arreglo `data` que contiene las horas en diferentes zonas horarias con sus respectivos códigos y nombres.
 * - Gestiona el estado `current` para la hora actualmente seleccionada.
 * - Define la función `updateCurrent` para actualizar la hora seleccionada.
 * - Define la función `handleCurrentHourByUserCountry` para establecer la hora predeterminada basada en el país del usuario.

 * @param {Object} props - Propiedades del componente.
 * @param {string} props.date - La fecha completa que se convertirá a diferentes zonas horarias.
 * @param {string} props.color - El color del texto de los botones.
 * @param {string} props.activeColor - El color del texto del botón seleccionado.
 * @param {string} props.activeBackground - El color de fondo del botón seleccionado.
 * @param {Function} props.onSelectHour - Función que se llama cuando se selecciona una hora.
 *
 * @returns {JSX.Element} El elemento JSX que representa la lista de horas en diferentes zonas horarias.
 */

export const NotibannerHours = ({ date, color, activeColor, activeBackground, onSelectHour }) => {
	const globalState = useContext(store);
	const country = globalState?.state?.pais;
	const colombianTimezone = "America/Bogota";
	const timezonesDates = useTimeZonesDates({
		fullDate: date,
		timeZoneSource: colombianTimezone,
	});

	const data = [
		{
			code: "co",
			name: "CO",
			hour: timezonesDates?.hours?.colombia,
		},
		{
			code: "mx",
			name: "MX",
			hour: timezonesDates?.hours?.mexico,
		},
		{
			code: "ar",
			name: "AR",
			hour: timezonesDates?.hours?.argentina,
		},
		{
			code: "cl",
			name: "CL",
			hour: timezonesDates?.hours?.chile,
		},
		{
			code: "us",
			name: `US (${timezonesDates?.timezonesInfo?.usa})`,
			hour: timezonesDates?.hours?.usa,
		},
	];

	const [current, setCurrent] = useState(data[0]);

	const updateCurrent = ({ item }) => {
		setCurrent(item);
	};

	const handleCurrentHourByUserCountry = () => {
		const item = data?.find((item) => item?.code === country?.toLowerCase());
		setCurrent(item ? item : data[0]);
	};

	useEffect(() => {
		onSelectHour && onSelectHour(current?.hour || "");
	}, [current] );

	useEffect(() => {
		handleCurrentHourByUserCountry();
	}, [country]);

	return (
		<div className="notibanner-hours d-flex flex-wrap">
			{data?.map((item, i) => {
				return (
					<div className="d-flex justify-content-center align-items-center">
						<button
							className={`d-flex justify-content-center align-items-center hour-button p-1 m-2 ${
								current?.name === item?.name ? "current" : ""
							}`}
							key={item?.name}
							style={{
								color:
									current?.name === item?.name
										? activeColor || "#000"
										: color || "#fff",
								backgroundColor:
									current?.name === item?.name
										? activeBackground || "#fff"
										: "transparent",
								borderColor:
									current?.name === item?.name
										? activeBackground || "#fff"
										: color || "#fff",
							}}
							onClick={() => updateCurrent({ item })}
						>
							{item?.name}
						</button>
						<p className="d-flex d-sm-none ml-1 secondary-font">{item?.hour}</p>
					</div>
				);
			})}
		</div>
	);
};
