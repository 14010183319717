import React, { useState, useEffect, useContext, useRef } from "react";
import { store, ASSETS_URL } from "../../Store";
import { NavbarBrand } from "../components/NavbarBrand";
import { NavbarNav } from "./components/NavbarNav";
import { DropdownCuentaIndividual } from "./components/DropdownCuentaIndividual";
import { SkeletonHeaderIndividual } from "./components/SkeletonHeaderIndividual";

import useIsMobile from "../../../js/utilities/useIsMobile";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { useIndividualHeaderDropdown } from "../../../hooks/header/useIndividualHeaderDropdown";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente del header individual
 * @returns {JSX.Element} - GeneralHeader
 */
export const IndividualHeader = () => {
  const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
  const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";

  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useIsMobile();

  const showADropdown = useIndividualHeaderDropdown();
  const dropdownButtonRef = useRef(null);

  useEffect(() => {
    if (dropdownButtonRef.current) {
      globalState.dispatch({
        type: "CHANGE",
        path: "individualHeaderDropdown.triggerButtonRef",
        value: dropdownButtonRef,
      });
    }
  }, [dropdownButtonRef.current]);

  useEffect(() => {
    if (state?.datosHeader) {
      setIsLoading(false);

    }
  }, [state?.datosHeader]);

  return (
    <>
      {isLoading ?
        <SkeletonHeaderIndividual />
        :
        <div className="general-header">
          <nav className="h-100 row mx-0">
            <div className="col-6 col-lg-2 col-xl-4 px-0 d-flex">
              <NavbarBrand />
            </div>

            {!isMobile &&
              <div className="col-lg-10 col-xl-8">
                <NavbarNav textosInterfaz={textosInterfaz} />
              </div>
            }

            {isMobile &&
              <div className="col-6 px-0 d-flex justify-content-end">
                <button
                  ref={dropdownButtonRef}
                  className={`btn-cuenta focusable-red`}
                  onClick={() => {
                    showADropdown.setVisible(!showADropdown?.state?.visible)
                  }}
                >
                  <div
                    className="mr-sm-1 mr-lg-3 btn-avatar"
                    style={
                      { backgroundImage: `url('${isLogged ? `${ASSETS_URL}${state.datosHeader?.avatar}` : '/img/profile.png'}')` }
                    }
                  ></div>
                  <span className="d-none d-sm-block">{textosInterfaz?.mi_cuenta || 'Mi cuenta'}</span>
                  <i className="fas fa-chevron-down fa-sm ml-2 arrow d-none d-lg-block"></i>
                </button>
              </div>
            }
          </nav>

          {isMobile &&
            <DropdownCuentaIndividual textosInterfaz={textosInterfaz}/>
          }

        </div>
      }
    </>
  )
}