import React, { useContext, useEffect, useRef } from "react";
import { store } from "../../../Store";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import { ModoPWAContext } from "../../../../contexts/ModoPwaContext";
import { useLogout } from "../../../../hooks/useLogout";
import useIsMobile from "../../../../js/utilities/useIsMobile";

import { useGeneralHeaderDropdown } from "../../../../hooks/header/useGeneralHeaderDropdown";
import { useNetworkStatus } from "../../../../hooks/use-network-status";

/**
 * Componente con las opciones favoritos, descargas, mi panel y cerrar sesión
 * @param {object} textosInterfaz - contiene los textos de interfaz
 * @returns  {JSX.Element} DropdownMiCuenta
 */
export const DropdownMiCuenta = ({ textosInterfaz }) => {
    const pwaState = useContext(ModoPWAContext);
    const globalState = useContext(store);
    const { state } = globalState;
    const location = useLocation();
    const logoutHandler = useLogout();
    const isMobile = useIsMobile();
    const networkStatus = useNetworkStatus();

    const ref = useRef(null);
    const showADropdown = useGeneralHeaderDropdown();
    const visible = showADropdown?.state?.visible;
    const triggerButton = globalState?.state?.generalHeaderDropdown?.triggerButtonRef;


    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 1 && window.scrollY < 10) {
                showADropdown.setVisible(false);
            }
        });
    }, [visible]);

    /**Función que permite cerrar el dropdown dando click fuera de él
    * @param {event} event 
    */
    const handleOutsideClick = (event) => {
        if (triggerButton?.current?.contains(event.target)) return;
        if (ref.current && !ref.current.contains(event.target)) {
            showADropdown.setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [triggerButton]);

    return (
        <div className={`${isMobile ? 'dropdownMenuResponsive' : 'dropdownMenu'} ${visible ? 'activeMode' : 'inactiveMode'}`}>
            <ul ref={ref}>

                {isMobile &&
                    <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                        <Link
                            to="/club-makemake"
                            onClick={() => showADropdown.setVisible(false)}
                            className="link-principal d-flex justify-content-center align-items-center focusable-red"
                        >
                            <Icon icon="iconoir:community" width={20} className="mr-3" />
                            {textosInterfaz?.comunidad || 'Comunidad'}
                        </Link>
                    </li>
                }

                <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                    <Link
                        to="/favoritos"
                        onClick={() => showADropdown.setVisible(false)}
                        className="link-principal d-flex justify-content-center align-items-center focusable-red"
                    >
                        <Icon icon="material-symbols:favorite" width={20} className="mr-3" />
                        {textosInterfaz?.favoritos || 'Favoritos'}
                    </Link>
                </li>

                {
                    pwaState?.clienteHabilitado && pwaState?.compatible &&

                    <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                        <Link
                            to="/descargados"
                            onClick={() => showADropdown.setVisible(false)}
                            className="link-principal d-flex justify-content-center align-items-center focusable-red"
                        >
                            <Icon icon="ri:download-line" width={20} className="mr-3" />
                            {textosInterfaz?.descargas || 'Descargas'}
                        </Link>
                    </li>
                }

                {state.userType === 'Cliente' &&
                    <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                        <Link
                            to="/mi-panel/mi-cuenta"
                            onClick={() => showADropdown.setVisible(false)}
                            className="link-principal d-flex justify-content-center align-items-center focusable-red"
                        >
                            <Icon icon="mage:dashboard-chart" width={20} className="mr-3" />
                            {textosInterfaz?.panel || 'Mi panel'}
                        </Link>
                    </li>
                }

                {!isMobile &&
                    <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                        <Link
                            to="/ayuda"
                            onClick={() => showADropdown.setVisible(false)}
                            className="link-principal d-flex justify-content-center align-items-center focusable-red"
                        >
                            <Icon icon="carbon:help" width={20} className="mr-3" />
                            {textosInterfaz?.ayuda || 'Ayuda'}
                        </Link>
                    </li>
                }

                {isMobile &&
                    <hr className={`${visible ? 'activeMode' : 'inactiveMode'}`} />
                }

                {(!pwaState.appInstalada && networkStatus.online === true) &&
                    <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                        <button
                            tabIndex={0}
                            className="link-secundario w-100 d-flex justify-content-center align-items-center focusable-red"
                            onClick={(e) => {
                                e.preventDefault();
                                logoutHandler.logout();
                                showADropdown.setVisible(false)
                            }}
                        >

                            {logoutHandler?.loading ?
                                <i className="fas fa-spinner fa-pulse"></i>
                                :
                                <>
                                    {textosInterfaz?.cerrar_sesion || 'Cerrar sesión'}
                                </>
                            }
                        </button>
                    </li>
                }

            </ul>
        </div>

    )
}