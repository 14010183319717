import React from "react"
import { Img } from "../shared/img"
import { useLocation } from "react-router"
import { useQueryParams } from "../../hooks/useQueryParams";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";


let timer = null;

export const AppView = () => {
    const params = useQueryParams();
    const location = useLocation();
    window.history.replaceState(null, null, window.location.href);


    useEffect(() => {
        if (location.pathname === '/external-auth/app' || location.pathname === '/external-auth/success') {
            timer = setTimeout(() => {
                backToHome();
            }, 60 * 1000);
            const _authHash = params.get('hash');
            if (!_authHash) {
              backToHome();
            }
        }else{
            clearTimeout(timer);
        }
        return ()=>{
            clearTimeout(timer);
        }
    }, [params.get('hash'), location.pathname])

 

    const backToHome = () => {
        window.history.replaceState(null, null, '/home')
        window.location.replace('/home');
    }
    return (<div className="w-100 min-vh-100 secondary--bg  p-2">
        <div className="container d-flex flex-column align-items-center">
            <Img className="img-fluid m-2 my-3" src="\img\logo-makemake.png" style={{ maxWidth: 200 }} />
            <h1 className="onsecondary--color text-center">Abriendo aplicación...</h1>
            <p className="primary--color m-2 text-center" style={{ fontWeight: 700, fontSize: 13 }}>Volviendo a MakeMake en 1 minuto.</p>
            <Link to={'/home'} replace={true} className="btn btn-primary my-3"  >Volver al inicio</Link>
            <p className="onsecondary--color m-2 my-4 text-center">¿No pudiste abrir la aplicación? recuerda que debes tenerla previamente instalada.</p>
            <div className="row" style={{ maxWidth: 350 }}>
                <a href={'https://apps.apple.com/co/app/apple-store/id375380948'} className="col-12 btn btn-light my-2  p-2 text-left"  ><Icon icon="ri:apple-fill" width={30} /> Instalación en iOS</a>
                <a href={'https://play.google.com/store/apps'} className="col-12 btn btn-light p-2 text-left" > <Icon icon="uil:android" width={30} /> Instalación en Android</a>
            </div>
        </div >

    </div>)

}