import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

/**
 * Este componente renderiza la portada del libro dentro de la ficha. Para esto requiere de la información del libro.
 * @param {object} metadata //contiene la información del libro
 * @returns Cover
 */
export const Cover = ({
    metadata,
}) => {

    const [loading, setLoading] = useState(true);
    const [path, setPath] = useState('');
    const defaultPath = '/img/no-imagen.png';

    useEffect(()=>{
        setPath(metadata?.portada)
    },[metadata?.portada])

    return (
        <div className="ficha-libro-cover-wrapper mx-auto">
            {loading&&
            <div className="position-absolute top-0 left-0 w-100 h-100  d-flex justify-content-center align-items-center" >
                <Icon icon="icomoon-free:spinner9" width={40} className={'spinner-rotation secondary-inverse--color'} />
            </div>
            }
            <img
                className={`img-fluid mx-auto d-block showscreens ficha-libro-cover`}
                style={{opacity:loading?0:1}}
                alt={metadata?.titulo || metadata?.title || 'N/A'}
                src={path}
                onLoad={()=>{
                    setLoading(false);
                }}
                onError={()=>{
                    setLoading(false);
                    setPath(defaultPath);
                }}
            />
            {(() => {
                if (metadata?.gratis === 1) {
                    return (
                        <img
                            className="additionalIcon"
                            src={"/img/freeIcon.png"}
                            alt="icono adicional"
                        />
                    );
                } else if (
                    metadata?.gratis === 0 &&
                    metadata?.interactivo === 1
                ) {
                    return (
                        <img
                            className="additionalIcon"
                            src={"/img/inticon.png"}
                            alt="icono adicional"
                        />
                    );
                } else if (
                    metadata?.gratis === 0 &&
                    metadata?.interactivo === 0 &&
                    metadata?.sonido === 1
                ) {
                    return (
                        <img
                            className="additionalIcon"
                            src={"/img/soundIcon.png"}
                            alt="icono adicional"
                        />
                    );
                }
            })()}
        </div>


    )
}