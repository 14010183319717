import axios from "axios";
import { GENERAL_URL_API } from "../../components/Store";
import moment from "moment";

/**
 * Este servicio devuelve un arreglo con los recursos de makemake.
 * @param {{abortSignal:AbortSignal}} param0
 * @returns {Promise<[]>} retorna un arreglo con las recursos.
 */
const getAll = async ({ abortSignal = null }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const response = await axios({
			method: "GET",
			url: `assets/listaRecursos`,
			signal: abortSignal || null,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		});
		const datos = response.data;
		if (datos === "Error") {
			return new Error(datos);
		}
		if (datos && Array.isArray(datos?.data)) {
			const events = datos?.data;

			events.sort((a, b) => {
				if (a.titulo < b.titulo) {
					return -1;
				}
				if (a.titulo > b.titulo) {
					return 1;
				} else {
					return 0;
				}
			});

			return events;
		} else {
			return [];
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const RecursosAPI = {
	getAll,
};
