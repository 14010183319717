import React from "react";
import { ASSETS_URL } from '../components/Store.js';
import { Link } from "react-router-dom";

/**
 * Componente para mostrar un libro de una colección.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.clase - Clase adicional para el contenedor del libro.
 * @param {Object} props.item - Datos del libro.
 * @param {boolean} props.swiper - Indica si se está utilizando con Swiper.
 * @returns {JSX.Element} Elemento JSX que representa el libro de la colección.
 */
const LibroColeccion = props => {

  const { clase, item, swiper } = props;

  return (
    <div role={"listitem"} className={`cover-book ${clase ? clase : 'col-6 col-lg-4 mb-4'}`}>
      <Link tabIndex={0} to={item.link} className={"focusable-secondary"} aria-label={`${item?.title || item?.titulo || item?.name || item?.nombre || 'N/A'}`}>
        <div className="cover-book-wrapper">
          {(() => {
            if (swiper) {
              return (<img alt={`Portada del libro '${item.idLibro}'`} className="swiper-lazy img-fluid" data-src={`${ASSETS_URL}${item.portada}`} />)
            } else {
              return (<img alt={`Portada del libro '${item.idLibro}'`} className="img-fluid" src={`${ASSETS_URL}${item.portada}`} />)
            }
          })()}
          {(() => {
            if (item.gratis === '1') {
              return <img alt="icono adicional gratuito" className="additionalIcon" src="/img/freeIcon.png" />
            } else if (item.gratis === '0' && item.interactivo === '1') {
              return <img alt="icono adicional interactivo" className="additionalIcon" src="/img/inticon.png" />
            } else if (item.gratis === '0' && item.interactivo === '0' && item.sonido === '1') {
              return <img alt="icono adicional sonido" className="additionalIcon" src="/img/soundIcon.png" />
            }
          })()}

        </div>
        {(() => {
          if (swiper) {
            return (<div className="swiper-lazy-preloader"></div>)
          }
        })()}
      </Link>
    </div>
  )
}

export default LibroColeccion;