/**
 * -TODAS LAS IMAGENES DE INSTITUCIONES DEBEN IR ACÁ, CUALQUIER REFERENCIA A IMAGENES DE INSTITUCIONES DEBE SALIR DE ACÁ.
 */
export const ALL_INSTITUTIONS_IMAGES = [
	{
		location: "",
		src: "/img/instituciones/alianza.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "usa",
		src: "/img/instituciones/Breteau.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/Campestre.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/Caniaverales.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "usa",
		src: "/img/instituciones/chicago-public-schools.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/colegio-albania.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/colegio-anglo-colombiano.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/colegio-canapro.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/colegio-seminario-duitama.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/colegio-tomas-moro.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/Columbus.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/Corazonista.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/Cumbres.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/deutsche.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/gimnasio-la-montaña.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/gimnasio-vermont.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/Jefferson.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "usa",
		src: "/img/instituciones/Madison.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/Marymount.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "usa",
		src: "/img/instituciones/Mercy.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "usa",
		src: "/img/instituciones/milton-school-district.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/Nogales.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "",
		src: "/img/instituciones/NuevaGranada.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "usa",
		src: "/img/instituciones/port-chester.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "usa",
		src: "/img/instituciones/sauk-trail-wolves.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
	{
		location: "usa",
		src: "/img/instituciones/waukesha.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: false,
		},
	},
];
