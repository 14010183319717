import React, { useContext, useState, useEffect } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { AccesibilidadContext } from "../../contexts/AccesibilidadContext";
import moment from "moment";
import { useTimeZonesDates } from "../../hooks/use-timezones-dates";

/**
 * Componente que renderiza el encabezado con el titulo y descripción del formulario de evento
 * @param {{
 *  eventData,
 *  idioma,
 *  lang,
 *  textosInterfaz
 * }} props 
 * @returns {JSX.Element} EventHeader
 */
export const EventHeader = ({ eventData, lang, textosInterfaz }) => {
    const accebilidadState = useContext(AccesibilidadContext);

    const [esTemaClasico, setEsTemaClasico] = useState(true);
    const [visibleTimeZones, setVisibleTimeZones] = useState(false);

    useEffect(() => {
        let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
        setEsTemaClasico(tema);
    }, [accebilidadState])

    const getMainDataByLang = ({ lang }) => {
        const date = eventData?.fechasZonas?.co;
        if (lang === "es") {
            moment.locale("es");
        } else {
            moment.locale("en");
        }
        return {
            month: moment(date).format("MMMM[,] YYYY").charAt(0).toUpperCase() + moment(date).format("MMMM[,] YYYY").slice(1).toLowerCase(),
            onlyMonth:  moment(date).format("MMMM").toLowerCase(),
            year:  moment(date).format("YYYY").toLowerCase(),
            day: moment(date).format("DD"),
            dayName: moment(date).format("dddd").charAt(0).toUpperCase() + moment(date).format("dddd").slice(1).toLowerCase(),
            hour: moment(date).format("h:mm A"),
        };
    };

    const mainDateData = getMainDataByLang({ lang });

    const colombianTimezone = "America/Bogota";
    const date = eventData?.fechasZonas?.co || `${eventData?.fecha} ${eventData?.hora}`;
    const timezonesDates = useTimeZonesDates({
        fullDate: date,
        timeZoneSource: colombianTimezone,
    });

    const timezones = [
        `${"Colombia"} - ${timezonesDates?.hours?.colombia}`,
        `${"México"} - ${timezonesDates?.hours?.mexico}`,
        `${"Argentina"} - ${timezonesDates?.hours?.argentina}`,
        `${"Chile"} - ${timezonesDates?.hours?.chile}`,
        `${`US (${timezonesDates?.timezonesInfo?.usa?.toLowerCase() === "cdt"
            ? "Central Daylight Time"
            : "Central Standard Time"
        })`} - ${timezonesDates?.hours?.usa}`,
    ];


    return (
        <div
            className="event-header p-4"
            style={{
                backgroundColor: esTemaClasico ? eventData?.color?.fondo || "var(--secondary-color)" : "var(--secondary-color)",
                color: esTemaClasico ? eventData?.color?.fuente || "var(--onsecondary-color)" : "var(--onsecondary-color)"
            }}
        >
            <h1 className="secondary-font ">
                {lang === 'es' ? eventData?.titulo?.es || 'titulo' : eventData?.titulo?.en || 'title'}
            </h1>

            <p className="mt-3" style={{ fontWeight: 600 }}>{textosInterfaz?.formularios?.date || 'Fecha:'}

                {
                    lang === 'es' ? 
                    <>
                        {" "}  {mainDateData?.dayName || 'Dayname'} {" "}   
                        {mainDateData?.day || 'day'} {" de "} 
                        {mainDateData?.onlyMonth || 'onlyMonth'}{", "}
                        {mainDateData?.year || 'year'} {" - "}
                        {mainDateData?.hour || '-/-/-'}
                        {" - Colombia "}
                    </>

                    :
                    <>
                        {" "}  {mainDateData?.dayName || 'Dayname'}{", "}   
                        {mainDateData?.onlyMonth || 'onlyMonth'}  {" "}
                        {mainDateData?.day || 'day'}{", "} 
                        {mainDateData?.year || 'year'} {" - "}
                        {mainDateData?.hour || '-/-/-'}
                        {" - Colombia "}
                    </>
                }
                
            </p>

            <button
                className="mt-3 d-flex align-items-center"
                style={{
                    fontWeight: 700,
                    color: esTemaClasico ? eventData?.color?.fuente || "var(--onsecondary-color)" : "var(--onsecondary-color)"
                }}
                onClick={() => setVisibleTimeZones(!visibleTimeZones)}
            >
                {textosInterfaz?.formularios?.times || 'Horarios'}
                <Icon icon="ep:arrow-down-bold" className={` ml-1 times-arrow ${visibleTimeZones ? "visible" : "hidden"}` } width={15}/>
            </button>


            <ul 
                className={`mb-3 eventHeader-timeZones ${visibleTimeZones ? "visible" : "hidden"}`}
                style={{
                    borderColor: esTemaClasico ? eventData?.color?.fuente || "var(--onsecondary-color)" : "var(--onsecondary-color)"
                }}
            >
                {timezones?.map((item, i) => {
                    return (
                        <li
                            key={i}
                            className={`onbackground-primary--color font-weight-bold secondary-font `}
                        >
                            {item}
                        </li>
                    );
                })}
            </ul>

            <p>{lang === 'es' ? eventData?.descripcion?.es || 'descripcion' : eventData?.descripcion?.en || 'description'}</p>
        </div>
    )
}