import React from "react";

const EventIcon = ({ className, width = 30, height = 30 }) => {
	return (
		<svg
			className={`${className}`}
			width={width}
			height={height}
			viewBox="0 0 30 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 6.45898C1 5.35441 1.89543 4.45898 3 4.45898H27C28.1046 4.45898 29 5.35442 29 6.45898V39.0306H1V6.45898Z"
				stroke="#262626"
				strokeWidth="2"
			/>
			<path
				d="M1 6.45899C1 5.35442 1.89543 4.45898 3 4.45898H27C28.1046 4.45898 29 5.35442 29 6.45898V14.32H1V6.45899Z"
				stroke="#262626"
				strokeWidth="2"
			/>
			<path
				d="M4.88574 2C4.88574 1.44772 5.33346 1 5.88574 1H8.10336C8.65564 1 9.10336 1.44771 9.10336 2V9.36685C9.10336 9.91914 8.65564 10.3669 8.10336 10.3669H5.88574C5.33346 10.3669 4.88574 9.91914 4.88574 9.36685V2Z"
				fill="#D9D9D9"
				stroke="#262626"
				strokeWidth="2"
			/>
			<path
				d="M20.7412 2C20.7412 1.44772 21.1889 1 21.7412 1H23.9588C24.5111 1 24.9588 1.44771 24.9588 2V9.36685C24.9588 9.91914 24.5111 10.3669 23.9588 10.3669H21.7412C21.1889 10.3669 20.7412 9.91914 20.7412 9.36685V2Z"
				fill="#D9D9D9"
				stroke="#262626"
				strokeWidth="2"
			/>
			<path d="M7.46094 19.1094H13.6786V25.0399H7.46094V19.1094Z" fill="#6666CC" />
			<path d="M16.166 19.1094H22.3836V25.0399H16.166V19.1094Z" fill="#6666CC" />
			<path d="M16.166 28.1699H22.3836V34.1005H16.166V28.1699Z" fill="#6666CC" />
			<path d="M7.46094 28.1699H13.6786V34.1005H7.46094V28.1699Z" fill="#6666CC" />
		</svg>
	);
};

const RecommendedIcon = ({ className, width = 30, height = 30 }) => {
	return (
		<svg
			className={`${className}`}
			width={width}
			height={height}
			viewBox="0 0 30 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M27.4138 7H29V35.5H5C2.79086 35.5 1 33.7091 1 31.5V4C1 2.34315 2.34315 1 4 1H26.4138V6C26.4138 6.55228 26.8615 7 27.4138 7Z"
				stroke="#262626"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
			<path
				d="M17.5859 20V6.5H24.8273V20L21.2066 17L17.5859 20Z"
				fill="#FFD103"
				stroke="#262626"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
const OfferIcon = ({ className, width = 30, height = 30 }) => {
	return (
		<svg
			className={`${className}`}
			width={width}
			height={height}
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.6248 2.65564C15.4246 -0.885213 20.4698 -0.885212 21.2696 2.65564C21.788 4.95047 24.4214 6.04128 26.4106 4.78513C29.48 2.84693 33.0474 6.41441 31.1092 9.48373C29.8531 11.473 30.9439 14.1064 33.2387 14.6248C36.7796 15.4246 36.7796 20.4698 33.2387 21.2696C30.9439 21.788 29.8531 24.4214 31.1092 26.4106C33.0474 29.48 29.48 33.0474 26.4106 31.1092C24.4214 29.8531 21.788 30.9439 21.2696 33.2387C20.4698 36.7796 15.4246 36.7796 14.6248 33.2387C14.1064 30.9439 11.473 29.8531 9.48373 31.1092C6.41441 33.0474 2.84693 29.48 4.78513 26.4106C6.04128 24.4214 4.95047 21.788 2.65564 21.2696C-0.885213 20.4698 -0.885213 15.4246 2.65564 14.6248C4.95047 14.1064 6.04128 11.473 4.78513 9.48373C2.84693 6.41441 6.41441 2.84693 9.48373 4.78513C11.473 6.04128 14.1064 4.95047 14.6248 2.65564Z"
				fill="#FFD103"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.5756 3.09631C15.7529 6.73861 11.5731 8.46992 8.41587 6.47619C7.14879 5.67605 5.67605 7.14879 6.47619 8.41587C8.46992 11.5731 6.73861 15.7529 3.09631 16.5756C1.63457 16.9058 1.63456 18.9886 3.09631 19.3188C6.73861 20.1415 8.46992 24.3212 6.47619 27.4785C5.67606 28.7456 7.14879 30.2183 8.41587 29.4182C11.5731 27.4244 15.7529 29.1558 16.5756 32.7981C16.9058 34.2598 18.9886 34.2598 19.3188 32.7981C20.1415 29.1558 24.3212 27.4244 27.4785 29.4182C28.7456 30.2183 30.2183 28.7456 29.4182 27.4785C27.4245 24.3212 29.1558 20.1415 32.7981 19.3188C34.2598 18.9886 34.2598 16.9058 32.7981 16.5756C29.1558 15.7529 27.4245 11.5731 29.4182 8.41587C30.2183 7.14879 28.7456 5.67605 27.4785 6.47619C24.3212 8.46992 20.1415 6.73861 19.3188 3.09631C18.9886 1.63456 16.9058 1.63457 16.5756 3.09631ZM21.2696 2.65564C20.4698 -0.885213 15.4246 -0.885213 14.6248 2.65564C14.1064 4.95047 11.473 6.04128 9.48373 4.78513C6.41441 2.84693 2.84693 6.41441 4.78513 9.48373C6.04128 11.473 4.95047 14.1064 2.65564 14.6248C-0.885213 15.4246 -0.885212 20.4698 2.65564 21.2696C4.95047 21.788 6.04128 24.4214 4.78513 26.4106C2.84693 29.48 6.41441 33.0474 9.48373 31.1092C11.473 29.8531 14.1064 30.9439 14.6248 33.2387C15.4246 36.7796 20.4698 36.7796 21.2696 33.2387C21.788 30.9439 24.4214 29.8531 26.4106 31.1092C29.48 33.0474 33.0474 29.48 31.1092 26.4106C29.8531 24.4214 30.9439 21.788 33.2387 21.2696C36.7796 20.4698 36.7796 15.4246 33.2387 14.6248C30.9439 14.1064 29.8531 11.473 31.1092 9.48373C33.0474 6.41441 29.48 2.84693 26.4106 4.78513C24.4214 6.04128 21.788 4.95047 21.2696 2.65564Z"
				fill="#262626"
			/>
			<circle
				cx="14.0973"
				cy="14.0973"
				r="2.15"
				stroke="#262626"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
			<circle
				cx="21.7975"
				cy="21.7975"
				r="2.15"
				stroke="#262626"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
			<path
				d="M11.6475 24.2471L24.2475 11.9971"
				stroke="#262626"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
const MakeBlogIcon = ({ className, width = 30, height = 30 }) => {
	return (
		<svg
			className={`${className}`}
			width={width}
			height={height}
			viewBox="0 0 30 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="28" height="34" stroke="#262626" strokeWidth="2" />
			<rect
				x="7"
				y="7"
				width="16"
				height="14"
				fill="#FFD103"
				stroke="#262626"
				strokeWidth="2"
			/>
			<path d="M6 26H24" stroke="black" strokeWidth="2" strokeLinejoin="round" />
			<path d="M6 30H24" stroke="black" strokeWidth="2" strokeLinejoin="round" />
		</svg>
	);
};

const NewsIcon = ({ className, width = 30, height = 30 }) => {
	return (
		<svg
			className={`${className}`}
			width={width}
			height={height}
			viewBox="0 0 31 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 9C1 8.44772 1.44772 8 2 8H5V22H2C1.44772 22 1 21.5523 1 21V9Z"
				stroke="#262626"
				strokeWidth="2"
			/>
			<path
				d="M7 22.382V7.61803L17.5528 2.34164C18.2177 2.00919 19 2.49269 19 3.23607V26.7639C19 27.5073 18.2177 27.9908 17.5528 27.6584L7 22.382Z"
				fill="#FFD103"
				stroke="#262626"
				strokeWidth="2"
			/>
			<path
				d="M23 9L27.5 5"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M27.5 23L23 19"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M29.0209 13.9685L23.0003 14.0162"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.5 31.5L5 22.5L11 31.5H7.5Z"
				stroke="black"
				strokeWidth="3"
				strokeLinecap="round"
			/>
		</svg>
	);
};

const GoToLinkIcon = ({ className, width = 30, height = 30 }) => {
	return (
		<svg
			className={`${className}`}
			width={width}
			height={height}
			viewBox="0 0 19 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.34962 10.4147C7.02634 10.7739 7.05546 11.3271 7.41466 11.6504C7.77385 11.9737 8.32711 11.9445 8.65038 11.5853L7.34962 10.4147ZM17.8738 0.954011C17.8484 0.47143 17.4366 0.10081 16.954 0.126209L9.0899 0.54011C8.60731 0.565509 8.23669 0.977308 8.26209 1.45989C8.28749 1.94247 8.69929 2.31309 9.18187 2.28769L16.1722 1.91978L16.5401 8.9101C16.5655 9.39269 16.9773 9.7633 17.4599 9.73791C17.9425 9.71251 18.3131 9.30071 18.2877 8.81813L17.8738 0.954011ZM8.65038 11.5853L17.6504 1.58534L16.3496 0.414656L7.34962 10.4147L8.65038 11.5853Z"
				fill="black"
			/>
			<path
				d="M16 19L16 19.875L16.875 19.875L16.875 19L16 19ZM1 19L0.125 19L0.125 19.875L1 19.875L1 19ZM1 3.5L1 2.625L0.125001 2.625L0.125001 3.5L1 3.5ZM6.5 4.375C6.98325 4.375 7.375 3.98325 7.375 3.5C7.375 3.01675 6.98325 2.625 6.5 2.625L6.5 4.375ZM16 18.125L1 18.125L1 19.875L16 19.875L16 18.125ZM1.875 19L1.875 3.5L0.125001 3.5L0.125 19L1.875 19ZM16.875 19L16.875 13L15.125 13L15.125 19L16.875 19ZM1 4.375L6.5 4.375L6.5 2.625L1 2.625L1 4.375Z"
				fill="black"
			/>
		</svg>
	);
};

const EmailIcon = ({ className, width = 30, height = 30 }) => {
	return (
		<svg
			className={`${className}`}
			width={width}
			height={height}
			viewBox="0 0 65 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.09241 2.05837L44.8001 6.94583L41.8199 31.7676L1.11225 26.8801L4.09241 2.05837Z"
				fill="#F1F1F1"
				stroke="#262626"
				strokeWidth="2"
			/>
			<path
				d="M3.854 4.0441C3.98567 2.94741 4.98146 2.16511 6.07815 2.29678L42.8143 6.70742C43.911 6.83909 44.6933 7.83488 44.5616 8.93157L43.9323 14.1732L23.5919 18.3443C23.3845 18.3868 23.169 18.3624 22.9764 18.2746L3.22593 9.27532L3.854 4.0441Z"
				fill="#F1F1F1"
				stroke="#262626"
				strokeWidth="2"
			/>
			<circle
				cx="22.8374"
				cy="17.9055"
				r="4.5"
				transform="rotate(6.84631 22.8374 17.9055)"
				fill="#FFD103"
				stroke="#262626"
				strokeWidth="2"
			/>
			<line
				x1="46.3545"
				y1="21.0537"
				x2="62.3545"
				y2="21.0537"
				stroke="#262626"
				strokeWidth="2"
			/>
			<line x1="46" y1="25.9463" x2="58" y2="25.9463" stroke="#262626" strokeWidth="2" />
			<line x1="46" y1="29.9463" x2="52" y2="29.9463" stroke="#262626" strokeWidth="2" />
		</svg>
	);
};

/**
 * Objeto con los iconos disponibles para la sección de notificaciones.
 * Cada propiedad es un componente que toma las siguientes props: className (string), width (string | number, por defecto 30px) y height (string | number, por defecto 30px).
 */
export const NotificationIcons = {
    /** Icono de evento */
	EventIcon,
    /** Icono recomendado */
	RecommendedIcon,
    /** Icono de oferta */
	OfferIcon,
    /** Icono para crear un blog */
	MakeBlogIcon,
    /** Icono de noticias */
	NewsIcon,
    /** Icono para ir a un enlace */
	GoToLinkIcon,
    /** Icono de correo electrónico */
	EmailIcon,
};