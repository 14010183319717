import React from "react";
import { usePublishersData } from "../hooks/use-publishers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Img } from "../../../components/shared/img";
import { Autoplay, FreeMode } from "swiper";
import { ASSETS_URL } from "../../../components/Store";
import { Icon } from "@iconify/react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
export const OurPublishers = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const publishersTexts = textos?.editoriales;
	const publishersData = usePublishersData();
	const publishers = publishersData.publishers;
	const loadingPublishers = publishersData.loading;

	return (
		<div className="surface--bg py-4 onbackground-primary--color">
			<div className="container p-3 d-flex flex-nowrap justify-content-center align-items-center">
				<p className="flex-grow publisher-title secondary-inverse--color">
					{publishersTexts?.titulo || "Nuestras editoriales"}
				</p>
				<div className="flex-grow-1 secondary-inverse--bg ml-4" style={{ height: 1 }}></div>
			</div>
			{loadingPublishers ? (
				<div className="d-flex justify-content-center align-items-center secondary--color">
					<Icon icon="fa:spinner" width={40} className={`spinner-rotation`} />
				</div>
			) : (
				<>
					<Swiper
						className="px-5"
						style={{ overflowX: "hidden", pointerEvents: "none" }}
						slidesPerView={"auto"}
						autoplay={{
							delay: 1,
							disableOnInteraction: false,
						}}
						speed={2 * 1000}
						loop={true}
						freeMode={true}
						direction="horizontal"
						modules={[Autoplay, FreeMode]}
					>
						{publishers.map((publisher, i) => {
							return (
								<SwiperSlide
									key={i}
									className="d-flex justify-content-center align-items-center mx-3 p-0"
									style={{
										minHeight: 150,
										height: 150,
										maxWidth: 150,
										pointerEvents: "none",
									}}
								>
									<Img
										key={i}
										className="img-fluid"
										style={{
											aspectRatio: "16/9",
											objectPosition: "50% 50%",
											objectFit: "contain",
											filter: "grayscale(100%)",
										}}
										src={`${ASSETS_URL}${publisher?.logo}`}
										alt={publisher?.nombreEditorial}
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</>
			)}
		</div>
	);
};
