import React from "react";
import { useHistory } from "react-router-dom";
import { CuraduriaCard } from "./curaduria-card";
import { trackWindowScroll } from "react-lazy-load-image-component";
import { NoResults } from "../components/shared/no-results";
import { ASSETS_URL } from "../components/Store";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { CountryCard } from "./country-card";
import PropTypes from 'prop-types';
import { useSyncPagination } from "../hooks/usePagination";
import { Icon } from "@iconify/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet";

/**
 * Componente que renderiza el listado de colecciones por países,  recibe como parametro un arreglo de colecciones de tipo país.
 */
const Paises = ({ paises, lang }) => {
    const { textosSubSecciones: textos } = useObtenerSubsecciones('curadurias');
    const history = useHistory();
    paises = Array.isArray(paises) ? paises : [];
    const titulo = paises?.length !== 1 ? `${textos?.paises?.titulo?.plural || 'Países'}` : `${textos?.paises?.titulo?.singular || 'País'}`;
    const pagination = useSyncPagination({ itemsPerPage: 8, data: paises, nextPageDelay: 200 });


    return <div className="w-100 d-flex flex-column">
        <Helmet>
            <title>{textos?.metadata?.paises?.title || 'MakeMake - Paises'}</title>
            <meta name="description" content={textos?.metadata?.paises?.description || 'En la sección Países encontrarás los libros agrupados por el país de los autores.'} />
        </Helmet>
        <h2 className="primary-font p-3 pl-4 onbackground-primary--color">{paises?.length} {titulo}</h2>
        <div className="p-2">
            {
                paises?.length > 0 ?
                    <>
                        <InfiniteScroll
                            style={{ overflow: 'visible' }}
                            className='row align-self-center'
                            dataLength={pagination.paginatedData.length}
                            next={pagination.nextPage}
                            hasMore={pagination.hasMore}
                            scrollThreshold={0.86}
                            loader={
                                <div
                                    className="secondary-inverse--color container-fluid w-100 d-flex justify-content-center align-items-center col-md-10 col-lg-10">
                                    <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                                </div>
                            }
                        >
                            {
                                pagination.paginatedData?.map((pais, i) => {
                                    return <div key={i} className="col-12 col-sm-6 col-md-4 col-lg-2  mx-lg-4 mx-xl-3">
                                        <CountryCard
                                            key={i}
                                            data={{ ...pais, imgUrl: `${ASSETS_URL}${pais?.imgUrl}` }}
                                            // data={{ ...pais,  imgUrl: `https://nf.app.makemake.reallabs.com.co/makemake/coleccion/colombia/img/imagen_coleccion.png` }}
                                            onClick={() => history.push(pais?.url)} lang={lang} />
                                    </div>
                                })
                            }

                        </InfiniteScroll>
                        {
                            pagination.hasMore &&

                            <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
                                <p className='secondary-inverse--color bold'>
                                    {`${textos?.scroll?.descripcion ||
                                        ' Desplazate hacia abajo para cargar más contenido.'}`
                                    }

                                </p>
                                <Icon icon="mdi:mouse-move-down" className='secondary-inverse--color text-28 mx-2' width={30} />
                            </div>
                        }
                    </>
                    : <NoResults title={'Aún no hay países'} message={'No hay países asociadas a tus libros.'} />
            }
        </div>
    </div>
}
Paises.propTypes = {
    /**
     * Arreglo con las colecciones de países
     */
    paises: PropTypes.arrayOf(PropTypes.shape({
        nombre: PropTypes.string,
        descripcion: PropTypes.string,
        url: PropTypes.string,
        imgUrl: PropTypes.string
    })),
          /**
   * Idioma de la sección
   */
  lang:PropTypes.string,
}
export const PaisesList = trackWindowScroll(Paises)
