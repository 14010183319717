import { Button } from "./components/button";
import { Container } from "./components/container";
import { List } from "./components/list";

/**
 * Objeto que exporta los componentes que tiene la base de un select
 */
export const BaseSelect = {
	Container,
    Button,
    List,
};
