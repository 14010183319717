import { useContext, useEffect, useState } from "react";
import { AVAILABLE_LANGUAGES, AVAILABLE_LANGUAGES_ARRAY } from "../config/constants";
import { store } from "../components/Store";

export const useDefaultLangByCountry = ({ country, defaultLang }) => {
	const [countries, setCountries] = useState([]);
	const isArray = Array.isArray(country);

	useEffect(() => {
		if (!isArray) {
			setCountries([country].map((c) => c.toLowerCase()));
		} else {
			setCountries(country?.map((c) => c.toLowerCase()));
		}
	}, [country]);

	const [lang, setLang] = useState("en");
	const appContext = useContext(store);
	const appState = appContext?.state;
	const appLang = appState?.idioma;
	const userCountry = appState?.pais ? appState?.pais?.toLowerCase() : "co";

	useEffect(() => {
		const storageValue = localStorage.getItem("language");
		if (countries.includes(userCountry) && !storageValue) {
			setLang(
				AVAILABLE_LANGUAGES_ARRAY.includes(defaultLang)
					? defaultLang
					: AVAILABLE_LANGUAGES.es
			);
		} else {
			setLang(appLang);
		}
	}, [appLang, userCountry, JSON.stringify(countries), defaultLang]);
	return lang;
};
