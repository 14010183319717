import './scss/styles.scss';
import React, { useState, useEffect, useCallback } from "react";
import useObtenerTraducciones from './js/translations/useObtenerTraducciones';
import { Helmet } from "react-helmet";

/**
 * Este componente no recibe props. Este se encarga de mostrar la pantalla de ERROR 404, el cual se visualiza cuando no se encuentra la página que se busca a través de la url.
 * @returns {JSX.Element} Error404
 */
const Error404 = () => {
  const [height, setHeight] = useState('auto');
  const { isReady, textosSeccion: textoError } = useObtenerTraducciones('error_404');

  const container = useCallback(node => {
    if (node !== null) {
      const resizeListener = () => {
        if (window.innerHeight - 100 > node.offsetHeight) {
          setHeight(window.innerHeight - 100)
        }
      };
      resizeListener();
      window.addEventListener('resize', resizeListener);
      return () => {
        window.removeEventListener('resize', resizeListener);
      }

    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Page Not Found - MakeMake</title>
        <meta name="description" content="Find your way around makemake.com.co" />
      </Helmet>
      {isReady &&
        <div className="main-container container-404" ref={container} style={{ height: height }}>
          <div className="container p-3 p-lg-5">
            <div className="row mx-0 align-items-center">
              <div className="col-lg-6 pr-0">
                <div className="col-5">
                  <img alt="logo de makemake" src="/img/logo-makemake.png" className="img-fluid" />
                </div>
                <p className="error-number">{textoError?.num || '404'}</p>
                <p className="error-description">{textoError?.no_hemos || 'No hemos podido encontrar la página que estabas buscando.'}</p>
              </div>
              <div className="col-lg-6 show-lg pl-0">
                <img src="/img/personaje.png" alt="blop esta sorprendido" className="img-fluid personaje" />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default Error404;


