import React, { useContext, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

import { BooksSection } from "../components/books-section";
import { GenericHomeRowTitle, HomeRow } from "../components/row-section";
import { CollectionSection } from "../components/collection";
import { store } from "../../components/Store";
import { Titles } from "../../components/Titles";
import { SkeletonHomeDefault } from "../../skeletons/SkeletonHome/SkeletonHomeDefault";

import { IntroHome } from "./intro-home";
import { Attributes } from "../../que-es/home/components/attributes";
import { DemoRequestHome } from "./demo-request-home";
import { OurPublishersHome } from "./our-publishers-home";
import { NowRead } from "./now-read";
import { NewStories } from "./new-stories";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

import { Helmet } from "react-helmet";

/**
 * Componente que muestra la página de inicio del usuario que no está autenticado.
 * @param {Object} props - Props del componente.
 * @param {Object} props.data - Datos de la página de inicio del usuario.
 * @returns {JSX.Element} Elemento JSX que representa la página de inicio del usuario.
 */
export const DefaultHome = memo(({ data }) => {

    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textosHome = useTranslations({ section: "home", lang: lang });


    const textos = textosHome?.sin_login || {};
    const homeData = data?.data;
    const globalState = useContext(store);
    const isAuth = globalState?.state?.sesionActiva === 'SI';
    const books = Array.isArray(data?.data?.books) ? data?.data?.books : [];
    const history = useHistory();

    const loading = globalState?.state?.userBooksData?.loading && globalState?.state?.userBooksData?.loadCompleted === false && globalState?.state?.cantidadLibros === -1;

    useEffect(() => {
        if (data?.loaded && isAuth && books?.length <= 0) {
            history.push('/mi-panel/mi-coleccion')
        }
    }, [books, isAuth, data])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>{textosHome?.metadata?.title || 'MakeMake - Libros'}</title>
                <meta name="description" content={textosHome?.metadata?.description || 'Explora el catálogo de MakeMake, una colección de libros narrativos e informativos para niños y jóvenes de altísima calidad.'} />
            </Helmet>

            {
                loading && data?.fetching ?
                    <SkeletonHomeDefault />
                    :

                    <div>
                        <Titles
                            hexBackground="#6666CC"
                            color="onsecondary--color"
                            title={textos?.titulo || 'Catálogo de libros'}
                        />

                        <LazyLoadComponent threshold={.5}>

                            <IntroHome texts={textos} />

                            <HomeRow
                                containerProps={{
                                    className: 'home-row-yellow'
                                }}
                                titleElement={
                                    <GenericHomeRowTitle text={textos?.libros_interactivos || 'Libros Interactivos'} />
                                }
                            >

                                <BooksSection books={homeData?.LibrosInteractivos} autoPlayDelay={5000} />
                            </HomeRow>

                            <Attributes />

                            <HomeRow
                                containerProps={{
                                    className: 'home-row-purple'
                                }}
                                titleElement={
                                    <GenericHomeRowTitle text={textos?.nuestra_coleccion || 'Nuestras colecciones'} />
                                }
                            >
                                <CollectionSection collections={homeData?.colecciones} autoPlayDelay={5500} />
                            </HomeRow>

                            <DemoRequestHome />

                            <HomeRow
                                containerProps={{
                                    className: 'home-row-yellow'
                                }}
                                titleElement={
                                    <GenericHomeRowTitle text={textos?.libros_ilustrados || 'Libros ilustrados'} />
                                }>
                                <BooksSection books={homeData?.LibrosIlustrados} autoPlayDelay={5500} />
                            </HomeRow>

                            <OurPublishersHome />
                            <NowRead text={textos} />
                            <NewStories textLogin={textos} />

                        </LazyLoadComponent>

                    </div>
            }
        </>

    )
})