import React, { useRef, useState, useEffect } from "react";
import { CancelRequestController } from "../util/cancel-request-controller";
import { DatosFormInstitutoAPI } from "../services/api/LoginInstitucional";
import MicroModal from "micromodal";
import ModalAlertas from "../components/ModalAlertas";
import { ASSETS_URL } from "../components/Store";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useQueryParams } from "../hooks/useQueryParams";
import ReCAPTCHA from "react-google-recaptcha";

/**
 * Este componente se encarga de renderizar el formulario de acceso institucional de tipo CardNumber. Su función principal es validar el campo número tarjeta de biblioteca y redireccionar el inicio de sesión. Este hace uso del componente ModalAlertas.
 */
const CardNumber = (props) => {
  const { datosInstituto, instituto, traducciones } = props;

  const { institucional, login } = traducciones;
  const { form } = institucional;

  const [preloader, setPreloader] = useState(false);
  const [notificacionTarjeta, setNotificacionTarjeta] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(false);

  const libraryId = useRef(null);
  const refForm = useRef(null);
  const location = useLocation();
  const params = useQueryParams();

  //reCaptcha
  const captcha = useRef(null);
  const [isDevelopment, setIsdevelopment] = useState(false);
  const [captchaValido, setCaptchaValido] = useState(false);
  const [mostrarMensajeCaptcha, setMostrarMensajeCaptcha] = useState(false);

  useEffect(()=>{
    const origin = window?.location?.origin;
    const development = origin === 'https://makemake.com.co' ?   false : true;
    setIsdevelopment(development)
  },[])

  /**
   * Esta función se encarga de validar los campos del formulario y enviar los datos para luego redireccionar.
   * @param {Event} e 
   */
  const enviarDatosAcceso = (e) => {
    e.preventDefault();

    libraryId.current.addEventListener("invalid", () => { setNotificacionTarjeta(true) });

    if (refForm.current.reportValidity()) {
      if (isDevelopment === false) {
        if (captchaValido === true) {
          enviarDatos()
        } else {
          setMostrarMensajeCaptcha(true)
        }
      } else {
        enviarDatos()
      }


    }
  }

  const enviarDatos = async () => {
    setPreloader(true)
    setMostrarMensajeCaptcha(false)
    let abortController = new CancelRequestController();
    let tipoIngreso = '';
    if (location.pathname === '/institucional/formulario' && params.get('access') === 'app') {
      tipoIngreso = 'app';
    }
    const propiedades = {
      inst: instituto,
      libraryID: libraryId.current.value,
      tipoIngreso: tipoIngreso
    }

    await DatosFormInstitutoAPI.enviarDatos({
      abortSignal: abortController.signal,
      data: propiedades
    }).then(respuesta => {
      const error = respuesta.status;

      if (error === 0) {
        setPreloader(false)
        if (mostrarModal) {
          MicroModal.show('modal-card-institucional', {
            awaitCloseAnimation: true
          });
        } else {
          setMostrarModal(true)
        }
        MicroModal.show('modal-card-institucional', {
          awaitCloseAnimation: true
        });

      } else {
        setPreloader(true)
        window.location.href = window.location.origin + respuesta.info;
      }
    }).catch(error => console.log(error));

    return () => {
      abortController.abort();
    }
  }

  /**
  * Esta función cambia el estado de setCaptchaValido en caso de que el captcha sea correcto
  */
  const onChange = () => {
    if (captcha.current.getValue()) {
      setCaptchaValido(true)
    }
  }

  return (
    <>
      <div className="col-lg-9 mx-auto pt-3 pb-5">
        <img
          src={`${ASSETS_URL}${datosInstituto?.logo || '/img/logo-makemake.png'}`}
          alt={`${datosInstituto?.nombreMostrar || 'Nombre del instituto'}`}
          className='mx-auto d-block'
          style={{ maxWidth: '250px' }}
        />
        <h2 className="mt-3 mb-4 text-center pb-3">{datosInstituto?.nombreMostrar || 'Nombre del instituto'}</h2>

        <form
          className="col-lg-7  mx-auto"
          ref={refForm}
        >
          <p className="secondary-item text-center"><strong>
            {instituto === 'whittier' ?
              institucional?.tarjeta || 'Please type your Library Card Number*:'
              :
              institucional?.tarjeta_perfil || 'Please type your Library Card Number or Library Card Profile*:'
            }
          </strong></p>

          <div className="my-4">
            <label htmlFor="inputCode" className="sr-only" aria-hidden={true}>
              {instituto === 'whittier' ?
                institucional?.tarjeta || 'Please type your Library Card Number*:'
                :
                institucional?.tarjeta_perfil || 'Please type your Library Card Number or Library Card Profile*:'
              }
            </label>
            <input
              type="text"
              className="form-control surface--bg  onbackground-third--color focusable-secondary"
              id="inputCode"
              placeholder={institucional?.label || 'Please type your Library Card Number'}
              required
              ref={libraryId}
              onChange={() => setNotificacionTarjeta(false)}
            />
            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionTarjeta ? institucional?.alert || 'Por favor, escribe tu número de tarjeta de biblioteca' : ''}
            </span>
          </div>
          {isDevelopment === false &&
            < div className="w-100 d-flex justify-content-center">
              <ReCAPTCHA
                ref={captcha}
                sitekey="6LfaebskAAAAAF4jRB9WbC3pOOgmAaPZnOf-kn5-"
                onChange={onChange}
              />
            </div>
          }
          {mostrarMensajeCaptcha &&
            <span role={"alert"} aria-live="assertive" className="notificacion-form d-block w-100 mt-3 text-center">{form?.captcha || 'Por favor acepta el captcha'}</span>
          }

          <button
            className={`btn mm-redimir_submit d-block col-md-10 mx-auto focusable-red mt-5 ${preloader ? 'btn-disabled' : 'btn-primary'}`}
            type="submit"
            onClick={(e) => enviarDatosAcceso(e)}
            disabled={preloader ? true : false}
          >
            {preloader ?
              <i className="fas fa-spinner fa-pulse"></i>
              :
              login?.botones?.iniciar_sesion || 'Log in'
            }
          </button>
        </form >

      </div >

      {mostrarModal &&
        <ModalAlertas
          id={"modal-card-institucional"}
          alertType={form?.modal?.titulo || 'Alerta'}
          message={form?.modal?.mensaje || 'Error de acceso'}
          description={form?.modal?.descripcion2 || 'El número de tarjeta de biblioteca es incorrecto'}
          boton={login?.botones?.aceptar || "Aceptar"} />

      }
    </>
  )
}

export default CardNumber;

CardNumber.propTypes = {
  /**
   * String del nombre del instituto. Este valor se usa para los datos requeridos del api assets/formulario/loginFormulario
   */
  instituto: PropTypes.string.isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  traducciones: PropTypes.object.isRequired,
  /**
   * Este es un objeto que contiene la información del instituto
   */
  datosInstituto: PropTypes.shape({
    colorHeader: PropTypes.string,
    colorLetra: PropTypes.string,
    correo: PropTypes.string,
    direccion: PropTypes.string,
    logo: PropTypes.string,
    nombreMostrar: PropTypes.string,
    telefono: PropTypes.string,
    tipoFormulario: PropTypes.string,
  }).isRequired
}