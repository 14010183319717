import React, { useContext } from "react";
import { store } from "../../../Store";
import { Link } from "react-router-dom";

/**
 * Componente con las opciones Colegios, Bibliotecas y Familias
 * @param {Function} setOpenDropdown - actualiza el estado openDropdown
 * @param {boolean} openDropdown - estado que indica si se debe ver las opciones de planes
 * @param {object} textosInterfaz- contiene los textos de interfaz
 * @returns {JSX.Element} DropdownPlanes
 */
export const DropdownPlanes = ({ setOpenDropdown, openDropdown, textosInterfaz }) => {

  const globalState = useContext(store);
  const { state } = globalState;
  const country = state?.pais?.toLowerCase();

  return (
    <ul>
      <li className={`${openDropdown ? 'activeMode' : 'inactiveMode'}`}>
        <Link
          to="/que-es/colegios"
          className="focusable-red"
          onClick={() => setOpenDropdown(false)}
        >{textosInterfaz?.colegios || 'Colegios'}</Link>
      </li>
      <li className={`${openDropdown ? 'activeMode' : 'inactiveMode'}`}>
        <Link
          to="/que-es/bibliotecas"
          className="focusable-red"
          onClick={() => setOpenDropdown(false)}
        >{textosInterfaz?.bibliotecas || 'Bibliotecas'}</Link>
      </li>

      {
        country !== 'us' &&
        <li className={`${openDropdown ? 'activeMode' : 'inactiveMode'}`}>
          <Link
            to="/tienda/plan-casa"
            className="focusable-red"
            onClick={() => setOpenDropdown(false)}
          >{textosInterfaz?.casa || 'Casa'}</Link>
        </li>
      }

    </ul>
  )
}