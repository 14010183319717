import React, { useContext } from "react";
import { useNotificationInboxVisibility } from "../hooks/use-notification-inbox-visibility";
import { useClickOutside } from "../../hooks/use-click-outside";
import { NotificationIcon } from "./notification-icon";
import { NotificationCard } from "./notification-card";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { NewsLetterForm } from "./newsletter-form";
import { useNotifications } from "../hooks/use-notifications";
import { Icon } from "@iconify/react";
import { useSeenNotificationStorage } from "../hooks/use-notitication-storage";
import { store } from "../../components/Store";
import { useTranslations } from "../../hooks/use-translations";
import _ from "lodash";

/**
 * Componente que renderiza el buzón de notificaciones, internamente usa hooks que se encargan de retornar las notificaciones pertinentes y manejar el estado de las mismas.
 * @returns {JSX.Element} Retorna componente de react.
 */
export const NotificationInbox = () => {
    const seenNotificationsStorage = useSeenNotificationStorage();
    const globalState = useContext(store);
    const isAuth = globalState?.state?.sesionActiva === "SI";
    const institutionUsername = globalState?.state?.username;
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
    const texts = useTranslations({ section: "notificaciones", lang: lang });
	const notificationInboxVisibility = useNotificationInboxVisibility();
	const visible = notificationInboxVisibility.isVisible;
	const notificationManager = useNotifications({ itemsPerPage: 3 });
	const notifications = notificationManager.notifications;

	const handleClickOutside = (target) => {
		if (target.dataset && target.dataset.makemakeNotificationButton !== "true") {
			notificationInboxVisibility.hide();
		}
	};
	const clickedOutside = useClickOutside({ onClickOutside: handleClickOutside });

	const hide = () => {
		notificationInboxVisibility.hide();
	};

	const handleNotificationClick = ({ notification }) => {
        seenNotificationsStorage.saveSeenNotification({isAuth, institutionName:institutionUsername, id:notification?.id  })
		notificationManager.markAsSeen({ id: notification?.id });
	};

	return (
		<div
			className={`notification-inbox-container d-flex justify-content-end ${
				visible ? "visible" : "hidden"
			}`}
		>
			<div className="notification-inbox col-12 col-sm p-0 m-0" ref={clickedOutside}>
				<div className="w-100 d-flex justify-content-between inbox-header secondary-inverse--bg onsecondary-inverse--color  p-3">
					<p
						className="d-flex align-items-center"
						style={{ fontWeight: 700 }}
					>
						<span className={`mx-2 ${visible ? "notification-shake" : ""}`}>
							<NotificationIcon />
						</span>
						{_.get(texts, "buzon.titulo") || "Notificaciones"}
					</p>
					<button
						className="focusable-red d-flex justify-content-center align-items-center d-md-none onsecondary-inverse--color"
						onClick={() => hide()}
						aria-label="Cerrar/close"
					>
						<Icon icon="mdi:close-thick" width={30} />
					</button>
				</div>

				<div className="d-flex flex-column p-3">
					{notificationManager.fetchingCompleted === false ? (
						<>
							<div
								className="secondary-inverse--color d-flex justify-content-center align-items-center"
								style={{ minHeight: "100px" }}
							>
								<Icon
									icon="icomoon-free:spinner9"
									width={60}
									className={"spinner-rotation"}
								/>
							</div>
						</>
					) : (
						<>
							{notificationManager.fetchingCompleted &&
							notificationManager.size <= 0 ? (
								<p className="py-5 text-center font-weight-bold secondary-inverse--color">
									{" "}
									{_.get(texts, "buzon.vacio") ||
										"No tienes notificaciones por el momento."}
								</p>
							) : null}
							{notifications.map((notification, i) => {
								return (
									<NotificationCard
										key={`notification-${notification?.id}-${i}`}
										onClick={() => handleNotificationClick({ notification })}
										lang={lang}
										data={notification}
									/>
								);
							})}
							{notificationManager.hasMore ? (
								<div className="d-flex justify-content-center align-items-center">
									<button
										className="load-more-button p-2 px-4"
										onClick={() => notificationManager.loadMore()}
									>
										{_.get(texts, "buzon.ver_mas") || "Mostrar más"}
										<Icon icon="ion:arrow-down" className="" width={25} />
									</button>
								</div>
							) : null}
						</>
					)}
					<NewsLetterForm lang={lang} onSubmitForm={() => hide()} />
				</div>
			</div>
		</div>
	);
};
