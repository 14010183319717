import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import QueEsBibliotecas from "./bibliotecas";
import QueEsColegios from "./colegios";
import { store } from "../components/Store";
import { useContext } from "react"
import { Home } from "./home";

/**
 * Este componente no recibe props. Este se encarga de mostrar el contenido de la sección del "Que es?" según la URL activa. Para esto hace uso de los componentes QueEsComponent, QueEsBibliotecas y QueEsColegios.
 * @returns {JSX.Element} - QueEsComponent, QueEsBibliotecas ó QueEsColegios
 */
const QueEsRouter = () => {

  const globalState = useContext(store);
  const isAuth = globalState?.state?.sesionActiva === 'SI';

  return (
    <>
      <Switch>
        <Route exact path='/que-es'>
          {isAuth?<Redirect to={'/home'}/>:<Home/>}
        </Route>
        <Route exact path='/que-es/loggedin' component={Home}/>
        <Route exact path='/que-es/bibliotecas' component={QueEsBibliotecas} />
        <Route exact path='/que-es/colegios' component={QueEsColegios} />
      </Switch>
    </>
  )
}

export default QueEsRouter;