export const READING_PLANS = {
	febrero: {
		tema: {
			en: "Friendship",
			es: "Amistad",
		},
		pregunta: {
			en: "How can reading help us connect with our friends?",
			es: "¿Cómo puede la lectura ayudarnos a crear conexiones con nuestros amigos?",
		},
		id_libros: ["MM0879", "MM1908", "MM1500"],
	},
	marzo: {
		tema: {
			en: "Music in Latin America",
			es: "Música en Latinoamérica",
		},
		pregunta: {
			en: "How can music allow us to explore and learn about other cultures?",
			es: "¿Cómo nos permite la música explorar otras culturas y ayudarnos a conocer mas sobre ellas?",
		},
		id_libros: ["MM2475", "MM2195", "MM1249"],
	},
	abril: {
		tema: {
			en: "Read to play",
			es: "Leer para jugar",
		},
		pregunta: {
			en: "How can games inspire memories and transport us to other times?",
			es: "¿Cómo puede el juego inspirar memorias y transportarnos a otras épocas?",
		},
		id_libros: ["MM1766", "MM2442", "MM1671"],
	},
	mayo: {
		tema: {
			en: "Recipes for reading",
			es: "Recetas para leer",
		},
		pregunta: {
			en: "Can stories awaken our senses and show us new flavors?",
			es: "¿Pueden las historias despertar nuestros sentidos y mostrarnos nuevos sabores?",
		},
		id_libros: ["MM0834", "MM1881", "MM2190"],
	},
	junio: {
		tema: {
			en: "Humor",
			es: "Humor",
		},
		pregunta: {
			en: "Why are laughter and fun essential parts of our lives?",
			es: "¿Por qué la risa y la diversión son partes indispensables de nuestra vida?",
		},
		id_libros: ["MM0438", "MM1913", "MM1934"],
	},
	julio: {
		tema: {
			en: "Writing",
			es: "Escritura",
		},
		pregunta: {
			en: "What inspires us to write to other people and why do we keep doing it?",
			es: "¿Qué nos ha inspirado a escribirnos con otras personas a lo largo de la historia y por qué lo seguimos haciendo?",
		},
		id_libros: ["MM1054", "MM2110", "MM0620"],
	},
	agosto: {
		tema: {
			en: "Women who write",
			es: "Mujeres que escriben",
		},
		pregunta: {
			en: "How can stories written by female authors change our perspective of the world?",
			es: "¿Cómo puede cambiar nuestra perspectiva del mundo leer a través de los ojos de autoras?",
		},
		id_libros: ["MM0779", "MM2011", "MM0784"],
	},
	septiembre: {
		tema: {
			en: "Indigenous traditions",
			es: "Tradición indígena",
		},
		pregunta: {
			en: "How are the cultural manifestations of indigenous tradition reflected in our cultures?",
			es: "¿Cómo se reflejan las manifestaciones culturales de la tradición indígena en nuestras culturas?",
		},
		id_libros: ["MM2299", "MM1996", "MM2396"],
	},
	octubre: {
		tema: {
			en: "Migration",
			es: "Migración",
		},
		pregunta: {
			en: "Why is it important to learn and reflect about migration experiences?",
			es: "¿Por qué es importante conocer y reflexionar sobre las experiencias migrantes?",
		},
		id_libros: ["MM2132", "MM2444", "MM2364"],
	},
	noviembre: {
		tema: {
			en: "Let’s debate our ideas",
			es: "Debatamos nuestras ideas",
		},
		pregunta: {
			en: "Why do we debate and defend our ideas?",
			es: "¿Por qué debatimos y defendemos nuestras ideas?",
		},
		id_libros: ["MM1712", "MM1298", "MM0340"],
	},
	diciembre: {
		tema: {
			en: "The lives of our pets",
			es: "La vida de nuestras mascotas",
		},
		pregunta: {
			en: "Can books help us imagine the lives of our pets and other animals?",
			es: "¿Podemos a través de la lectura imaginar como es la vida de nuestras mascotas y los retos que esta presenta?",
		},
		id_libros: ["MM0080", "MM2384", "MM1639"],
	},
	enero_2025: {
		tema: {
			en: "Death and grief",
			es: "Muerte y duelo",
		},
		pregunta: {
			en: "Can literature give us spaces to process and explore grief and death?",
			es: "¿Puede la literatura darnos espacios para procesar y explorar el duelo y la muerte?",
		},
		id_libros: ["MM0041","MM1970", "MM1630"],
	},
};

export const PAST_READING_PLANS = [
	{
		id: "2023",
		name: { default: "", translationPath: "" },
		file: {
			es: "/document/plan_lecturas_2023_act.pdf",
			en: "/document/plan_lecturas_2023_act_en.pdf",
		},
		img: "/img/club-makemake/plan-lectura/2023.jpg",
	},
	{
		id: "2022",
		name: { default: "", translationPath: "" },
		file: {
			es: "/document/plan_lecturas_2022_act.pdf",
			en: "/document/plan_lecturas_2022_act_en.pdf",
		},
		img: "/img/club-makemake/plan-lectura/2022.jpg",
	},
	{
		id: "2021",
		name: { default: "", translationPath: "" },
		file: {
			es: "/document/plan_lecturas_2021_act.pdf",
			en: "/document/plan_lecturas_2021_act.pdf",
		},
		img: "/img/club-makemake/plan-lectura/2021.jpg",
	},
];

export const READING_PLAN_MONTHS = [
	{
		id: "enero",
		date:"2024-01-01",
		name: {
			default: "Enero",
			translationPath: "meses.enero",
		},
		visible: false,
	},
	{
		id: "febrero",
		date:"2024-02-01",
		name: {
			default: "Febrero",
			translationPath: "meses.febrero",
		},
		visible: true,
	},
	{
		id: "marzo",
		date:"2024-03-01",
		name: {
			default: "Marzo",
			translationPath: "meses.marzo",
		},
		visible: true,
	},
	{
		id: "abril",
		date:"2024-04-01",
		name: {
			default: "Abril",
			translationPath: "meses.abril",
		},
		visible: true,
	},
	{
		id: "mayo",
		date:"2024-05-01",
		name: {
			default: "Mayo",
			translationPath: "meses.mayo",
		},
		visible: true,
	},
	{
		id: "junio",
		date:"2024-06-01",
		name: {
			default: "Junio",
			translationPath: "meses.junio",
		},
		visible: true,
	},
	{
		id: "julio",
		date:"2024-07-01",
		name: {
			default: "Julio",
			translationPath: "meses.julio",
		},
		visible: true,
	},
	{
		id: "agosto",
		date:"2024-08-01",
		name: {
			default: "Agosto",
			translationPath: "meses.agosto",
		},
		visible: true,
	},
	{
		id: "septiembre",
		date:"2024-09-01",
		name: {
			default: "Septiembre",
			translationPath: "meses.septiembre",
		},
		visible: true,
	},
	{
		id: "octubre",
		date:"2024-010-01",
		name: {
			default: "Octubre",
			translationPath: "meses.octubre",
		},
		visible: true,
	},
	{
		id: "noviembre",
		date:"2024-011-01",
		name: {
			default: "Noviembre",
			translationPath: "meses.noviembre",
		},
		visible: true,
	},
	{
		id: "diciembre",
		date:"2024-012-01",
		name: {
			default: "Diciembre",
			translationPath: "meses.diciembre",
		},
		visible: true,
	},
	{
		id: "enero_2025",
		date:"2025-01-01",
		name: {
			default: "Enero 2025",
			translationPath: "meses.enero_2025",
		},
		visible: true,
	},
];
