import React from "react";

/**
 * Componente de la bandera en plan casa
 * @param {*} title - texto de la bandera
 * @returns {JSX.Element} ReadFlag
 */
export const ReadFlag = ({ title }) => {
    return (
        <div className="read-flag px-3 py-2">
            {title &&
                <p className="text-24">{title}</p>
            }            
        </div>
    )
}