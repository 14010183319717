import React, { useContext } from "react";
import { Img } from "../../../../components/shared/img";
import { ASSETS_URL, store } from "../../../../components/Store";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useTranslations } from "../../../../hooks/use-translations";
import _ from "lodash";

export const BookCard = ({ book, loading, lang }) => {
	const texts = useTranslations({ section: "comunidad_club", lang: lang });
	const globalState = useContext(store);
	const isAuth = globalState?.state?.sesionActiva === "SI";

	return (
		<article
			className="row  p-0 m-0 w-100 h-100  book-card secondary--bg onsecondary--color overflow-hidden"
			style={{ minHeight: 437 }}
		>
			{loading ? (
				<div className="w-100 h-100 d-flex justify-content-center align-items-center">
					<Icon icon="icomoon-free:spinner9" width={60} className={"spinner-rotation"} />
				</div>
			) : (
				<>
					{/* <p>{book?.idLibro}</p> */}
					<div className="col-12 d-flex d-lg-none p-0 m-0 x justify-content-center align-items-center mt-5 px-2">
						<div className="" style={{ maxWidth: 200, minHeight: 310 }}>
							<Img
								src={`${ASSETS_URL}${book?.portada}`}
								alt="portada"
								className="img img-fluid"
								defaultImage={"/img/no-imagen.png"}
							/>
						</div>
					</div>
					<div className="col-12 col-md px-4 pt-4 h-auto">
						<h2 className="title primary-inverse--color secondary-font">
							{book?.title || book?.titulo || "N/A"}
						</h2>
						<p className="secondary-font font-weight-bold">
							{book?.autor || book?.author || "N/A"}
						</p>
						<hr />
						<div className="d-flex py-2 flex-wrap secondary-font">
							<p
								className="text-uppercase primary-inverse--color"
								style={{ fontWeight: 700, letterSpacing: 2 }}
							>
								{book?.genero || "N/A"}
							</p>
							<div style={{ width: 20 }}></div>
							<p className="" style={{ fontWeight: 500 }}>
								{book?.editorial || "N/A"}
							</p>
						</div>
						<p className="description" dangerouslySetInnerHTML={{
							__html: lang === "en"
								? book?.descripcionEn || book?.descripcion || "N/A"
								: book?.descripcion || "N/A"
						}}>
						</p>
						<div className="">
							<Link
								className="focusable-red btn primary-onsecondary-button my-4 focusable-red px-5 py-2"
								to={`${book?.link}`}
							>
								{_.get(texts, 'planes.tarjeta_plan.boton.leer') || 'Leer'}
							</Link>
						</div>
					</div>
					<div
						className="col-12 col-md p-0 m-0 d-none d-lg-flex justify-content-center align-items-center"
						style={{ maxWidth: 300 }}
					>
						<Img
							src={`${ASSETS_URL}${book?.portada}`}
							alt="portada"
							className="img w-100 h-auto"
							defaultImage={"/img/no-imagen.png"}
						/>
					</div>
				</>
			)}
		</article>
	);
};
