import momentTZ from "moment-timezone";

/**
 * Hook personalizado para convertir una fecha a múltiples zonas horarias y obtener las horas y nombres de las zonas horarias.
 *
 * @param {Object} params - Parámetros para la función.
 * @param {string} params.fullDate - La fecha completa que se convertirá.
 * @param {string} params.timeZoneSource - La zona horaria de origen de la fecha proporcionada.
 *
 * @returns {Object} - Un objeto que contiene:
 *    - `dates`: Un objeto con las fechas convertidas a cada zona horaria.
 *    - `hours`: Un objeto con las horas formateadas correspondientes a cada zona horaria.
 *    - `timezonesInfo`: Un objeto con los nombres de las zonas horarias correspondientes a cada fecha convertida.
 */

export const useTimeZonesDates = ({ fullDate, timeZoneSource }) => {
   
    const timezones = {
        colombia: "America/Bogota",
        mexico: "America/Mexico_City",
        argentina: "America/Argentina/Buenos_Aires",
        chile: "America/Santiago",
        usa: "America/Chicago",
    };

    const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
    const HOUR_FORMAT = "hh:mm A";
    const TZ_FORMAT = "z";

    const getHour = (date) => {
        return momentTZ(date, DATE_FORMAT).format(HOUR_FORMAT);
    };

    const getTimeZoneName = (date, timeZone) => {
        return momentTZ.tz(date, DATE_FORMAT, timeZone).format(TZ_FORMAT);
    };

    const sourceDate = momentTZ.tz(fullDate,DATE_FORMAT, timeZoneSource);

    const dates = {
        colombia: sourceDate.clone().tz(timezones.colombia).format(DATE_FORMAT),
        mexico: sourceDate.clone().tz(timezones.mexico).format(DATE_FORMAT),
        argentina: sourceDate.clone().tz(timezones.argentina).format(DATE_FORMAT),
        chile: sourceDate.clone().tz(timezones.chile).format(DATE_FORMAT),
        usa: sourceDate.clone().tz(timezones.usa).format(DATE_FORMAT),
    };

    const hours = {
        colombia: getHour(dates.colombia),
        mexico: getHour(dates.mexico),
        argentina: getHour(dates.argentina),
        chile: getHour(dates.chile),
        usa: getHour(dates.usa),
    };

    const timezonesInfo = {
        colombia: getTimeZoneName(dates.colombia, timezones.colombia),
        mexico: getTimeZoneName(dates.mexico, timezones.mexico),
        argentina: getTimeZoneName(dates.argentina, timezones.argentina),
        chile: getTimeZoneName(dates.chile, timezones.chile),
        usa: getTimeZoneName(dates.usa, timezones.usa),
    };

    return {
        dates,
        hours,
        timezonesInfo,
    };
};
