import axios from "axios";
import { GENERAL_URL_API } from "../../components/Store";
import moment from "moment";

/**
 * Función asíncrona para obtener la lista de notificaciones del buzón.
 * @param {object} options - Opciones de configuración.
 * @param {AbortSignal} options.abortSignal - Señal de aborto para cancelar la solicitud.
 * @returns {Promise<Array<object>>} - Una promesa que resuelve en una lista de notificaciones.
 */
const getInbox = async ({ abortSignal = null }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const response = await axios({
			method: "GET",
			url: `assets/listaNotibuzones`,
			signal: abortSignal || null,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		});
		const datos = response.data;
		if (datos === "Error") {
			return new Error(datos);
		}
		if (datos && Array.isArray(datos?.data)) {
			const notifications = datos?.data;
			return notifications;
		} else {
			return [];
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * Función asíncrona para obtener el notibanner correspondiente al estado de autenticación del usuario.
 * @param {object} options - Opciones de configuración.
 * @param {AbortSignal} options.abortSignal - Señal de aborto para cancelar la solicitud.
 * @returns {Promise<Object>} - Una promesa que resuelve en el notibanner correspondiente.
 */
const getNotibanner = async ({ abortSignal = null }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const response = await axios({
			method: "GET",
			url: `assets/listaNotibanners`,
			signal: abortSignal || null,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		});
		const datos = response.data;
		if (datos === "Error") {
			return new Error(datos);
		}
		if (datos) {
			const notibanner = datos?.data || {};
			return notibanner;
		} else {
			return [];
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const NotificacionesAPI = {
	getAll: getInbox,
	getNotibanner
};
