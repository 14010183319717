import { useContext, useState, useEffect } from 'react';
import { store } from "../../components/Store";
import ObtenerTraducciones from './obtenerTraducciones';
import { AVAILABLE_LANGUAGES_ARRAY } from '../../config/constants';

/**
 * Retorna un objeto con los textos de la interfaz gráfica de una sección específica
 * @param {string} seccion - Sección (pestaña) del archivo de excel  que se desea obtener los textos.
 * @param {string} subseccion - sección especifica dentro de la sección pasada por parámetro.
 * @param {string} lang - idioma en que se desea el texto "en"|"es";
 * @returns {object} - Objeto con los textos de la sección especificada.
 */
const useObtenerTraducciones = (seccion, subseccion, lang) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { idioma } = state;
  const [isReady, setIsReady] = useState(false);
  const [textosSeccion, setTextosSeccion] = useState({});
  useEffect(() => {
    const customLang = lang && AVAILABLE_LANGUAGES_ARRAY.includes(lang)?lang:idioma;
    const language = customLang;
    let textos = ObtenerTraducciones(seccion, language, subseccion)
    setTextosSeccion(textos);
    setIsReady(true);
  }, [lang, idioma, seccion, subseccion])
  return { isReady, textosSeccion }
}

export default useObtenerTraducciones;