import React from "react";
import { NotificationIcons } from "./icons";
import moment from "moment";
import "moment/locale/es";
import _ from "lodash";
import { useTranslations } from "../../hooks/use-translations";

/**
 * Componente de tarjeta de notificación.
 * 
 * Este componente muestra una tarjeta de notificación con información sobre un evento, recomendación, oferta, entrada de blog o noticia.
 * 
 * @component
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.data - Datos de la notificación.
 * @param {string} props.lang - Idioma utilizado para las traducciones.
 * @param {Function} props.onClick - Función a ejecutar al hacer clic en la tarjeta.
 */

export const NotificationCard = ({ data, lang, onClick }) => {
    const texts = useTranslations({ section: "notificaciones", lang: lang });
	const isSeen = data?.isSeen;
	const availableTypes = ["evento", "recomendado", "oferta", "makeblog", "noticia"];
	const iconSize = 40;
	const icons = {
		evento: <NotificationIcons.EventIcon EventIcon width={iconSize} height={iconSize} />,
		recomendado: (
			<NotificationIcons.RecommendedIcon EventIcon width={iconSize} height={iconSize} />
		),
		oferta: <NotificationIcons.OfferIcon EventIcon width={iconSize} height={iconSize} />,
		makeblog: <NotificationIcons.MakeBlogIcon EventIcon width={iconSize} height={iconSize} />,
		noticia: <NotificationIcons.NewsIcon EventIcon width={iconSize} height={iconSize} />,
	};
	const type = data?.tipoNotificacion?.toLowerCase();
	moment.locale("en");
	const enDate = moment(data?.metadatos?.fecha).format("MMMM D[,] YYYY");
	moment.locale("es");
	const esDate = moment(data?.metadatos?.fecha).format("D [de] MMMM[,] YYYY");
	const date = lang === "en" ? enDate : esDate;
	const hasDate = moment(data?.metadatos?.fecha, "YYYY-MM-DD", true).isValid();
	const title =
		lang === "en"
			? data?.metadatos?.titulo?.en || data?.metadatos?.titulo?.es || "N/A"
			: data?.metadatos?.titulo?.es || "N/A";
	const description =
		lang === "en"
			? data?.metadatos?.descripcion?.en || data?.metadatos?.descripcion?.es 
			: data?.metadatos?.descripcion?.es;
	const link = data?.metadatos?.link;

	const getIconByType = ({ type }) => {
		if (availableTypes.includes(type)) {
			return icons[type];
		} else {
			return <NotificationIcons.EventIcon width={iconSize} height={iconSize} />;
		}
	};

	const handleLink = () => {
		if(!link) return;
		onClick && onClick();
		window.open(link, "_blank");
	};
	return (
		<button
			className={`focusable-secondary notification-card my-2 p-3 ${isSeen?'grayscale':''}`}
			aria-label={`${title}`}
			onClick={() => handleLink()}
		>
			<div className="w-100 d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					{isSeen !== true ? <span className="new-notification-circle m-1"></span> : null}
					<p className="type">{_.get(texts, `buzon.notificacion.tipo.${type}`) || type}</p>
				</div>
				{/* <NotificationIcons.GoToLinkIcon width={20} height={20} /> */}
			</div>
			{hasDate ? <p className="date text-left">{date}</p> : null}
			<div className="d-flex align-items-center mt-1">
				<div>{getIconByType({ type })}</div>
				<div className="pl-2">
					<p className="title text-left">{title}</p>
					<p className="description text-left">{description}</p>
				</div>
			</div>
		</button>
	);
};
