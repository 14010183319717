import { Icon } from "@iconify/react";
import React from "react";

export const EventLayer = ({ children, title, visible, setVisible }) => {
	return (
		<div
			className={`${
				visible ? "visible" : "hidden"
			} event-layer d-flex flex-column w-100 h-100 p-3`}
		>
			<button
				className="focusable-red d-flex align-items-center secondary-inverse--color font-weight-bold secondary-font"
				onClick={() => setVisible && setVisible(false)}
			>
				<Icon icon="akar-icons:arrow-left" className="pr-2" width={30} /> {title || "N/A"}
			</button>
			<div className="p-3 px-4  d-flex w-100 flex-column flex-grow-1">{children}</div>
		</div>
	);
};
