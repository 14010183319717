
import { GENERAL_URL_API} from '../../components/Store.js';
import getAjax from './getAjax';

/**
 * Hace una petición a una API a través del método GET y devuelve en la promesa un valor específico
 * @param {string} url 
 * @param {object} params 
 */
const getPromise = (url, params)=>{
  return new Promise ((resolve, reject) => {
    let options = params ? params : {}
    getAjax(
      `${GENERAL_URL_API}${url}`,
      options,
      (response) => {
        resolve(response)
      }
    )  
  })
}

export default getPromise;