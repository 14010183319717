import React, { useState } from "react";
import { CommunityTitle } from "../../../components/community/community-title";
import { CommunitySelector } from "../../../components/community/community-selector";
import { ResourcesList } from "../resources-list";
import { SECONDARY_SECTION_OPTIONS } from "../../data/secondary-section";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { useResources } from "../../hooks/use-resources";
import { useTranslations } from "../../../hooks/use-translations";
import _ from "lodash";

export const SecondarySection = React.forwardRef((props, ref) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "centro_recursos", lang: lang });
	const color = 'yellow';
	const options = [...SECONDARY_SECTION_OPTIONS].map((option)=>{
		return {
			...option,
			name: _.get(texts, option?.name?.translationPath) || option?.name?.default,
		}
	});
	const [currentOption, setCurrentOption] = useState(options[0]);
	const resourcesManager = useResources({type:currentOption.id})
	const resources = resourcesManager.data;
	const loadingResources = resourcesManager.loading;

	return (
		<div className="primary-section" ref={ref} style={{scrollMarginTop:60}}>
			<div className="container my-5">
				<CommunityTitle.H1 title={_.get(texts, 'aprende.titulo') || 'Aprende todo sobre MakeMake'} />
				<p className="section-description my-3 onbackground-primary--color">
					{_.get(texts, 'aprende.descripcion') || 'Usa estos tutoriales para entrenar al personal de tu institución o para compartir con tu comunidad.'}  
				</p>
				<div className="row m-0 p-0 d-flex justify-content-center">
					<div className="col-12 col-lg p-0 m-0 d-flex justify-content-lg-end align-items-lg-start mt-lg-5">
						<CommunitySelector
							defaultItemId={'option-1'}
							color={color}
							data={options}
							onSelect={(option) => setCurrentOption(option)}
						/>
					</div>
					<div className="col-12 col-lg-9 p-0 m-0">
						<ResourcesList resources={resources} color={color} lang={lang} loading={loadingResources} />
					</div>
				</div>
			</div>
		</div>
	);
});
