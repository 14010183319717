import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 *  Primer componente de introducción para que es colegios
 * @returns {JSX.Element} Componente Introduction.
 */
export const Introduction = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textoColegios = useTranslations({ section: "que_es_colegios", lang: lang });

	return (
		<div className="introduction">
			<div className="container">
				<div className="col-lg-11 mx-auto">
					<div className="row mx-0 align-items-stretch">
						<div className="col-lg-6 px-0 order-lg-2">
							<figure
								className="ilustracion-inicial w-100 h-100"
								alt={"ilustración del plan lector"}
								style={{
									backgroundImage: `url('/img/que-es-lector/ilustracion-plector.png')`,
								}}
							></figure>
						</div>

						<div className="col-lg-6 px-0 order-lg-1 ">
							<div className=" pl-2 mt-lg-5 sub-title">
								<h1
									tabIndex={0}
									className="focusable-secondary onbackground-primary--color secondary-font"
									style={{ lineHeight: "1.3em" }}
								>
									{textoColegios?.intro?.titulo ||
										"Revoluciona la lectura en prescolar y primaria con nuestro plan lector"}
								</h1>
							</div>
							<p className="onbackground-primary--color col-lg-10 px-0 mt-4 mb-5">
								{textoColegios?.intro?.descripcion ||
									"MakeMake ofrece a los colegios un sistema de lectura personalizado que te permite configurar salones de clase y cuentas individuales para docentes y estudiantes, con los mejores libros en español para chicos."}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
