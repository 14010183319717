import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SkeletonRecomendados = () => {

    return (
        <SkeletonTheme color="#e8e8e8" highlightColor="#f7f7f7">
            <div className="row mx-0 justify-content-center my-3">
                {(() => {
                    var libros = [];
                    for (let i = 0; i < 2; i++) {
                        libros.push(
                            <div className="col-6 px-4" key={`skeleton-libro-recomendado-${i}`} >
                                <Skeleton className="ficha-card-libro-skeleton" height={240} />
                            </div>
                        )
                    }
                    return libros;
                })()}
            </div>
        </SkeletonTheme>
    );
};

