import React, {useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import { IconUser } from "./components/IconUser";
import { DropdownPlanes } from "../../header/public-header/components/DropdownPlanes";

import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente que contiene las opciones del menu inferior en modo mobile. 
 * Estas opciones son las de mkmk publico o sin login. 
 * (Agendar demo, planes, libros e iniciar sesión)
 * @returns {JSX.Element} PublicMenu
 */
export const PublicMenu = () => {
    const location = useLocation();
    const [dropdownPublicMenu, setOpenDropdownPublicMenu] = useState(false);
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });
    const [urlDemo, setUrlDemo] = useState('/tienda/institucional/es/cita');

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 1) {
                setOpenDropdownPublicMenu(false)
            }
        });
    }, [])

    useEffect(() => {
        const newUrl= lang === "es" ? "/tienda/institucional/es/cita" : "/tienda/institucional/en/cita";
        setUrlDemo(newUrl)
    },[lang])


    return (
        <>
            <div className={`dropdownPublicMenu ${dropdownPublicMenu ? 'activeMode' : 'inactiveMode'} `}>
                <DropdownPlanes
                    setOpenDropdown={setOpenDropdownPublicMenu}
                    openDropdown={dropdownPublicMenu}
                    textosInterfaz={textosInterfaz}
                />
            </div>

            <ul className="d-flex">
                <li className="col-3 px-0">
                    <Link
                        to={urlDemo}
                        className={`btn-option-menu focusable-red ${location.pathname === '/tienda/institucional/es/demo' || location.pathname === '/tienda/institucional/en/demo' ? "activeMode" : ""}`}
                        onClick={() => setOpenDropdownPublicMenu(false)}
                    >
                        <Icon icon="carbon:demo" width="30" />
                        <span>{textosInterfaz?.demo || 'Demo'}</span>
                    </Link>
                </li>
                <li className="col-3 px-0">
                    <button
                        className={`btn-option-menu focusable-red ${dropdownPublicMenu ? 'activeMode' : ''}`}
                        onClick={() => setOpenDropdownPublicMenu(!dropdownPublicMenu)}
                    >
                        <Icon icon="eos-icons:products-outlined" width="30" style={{ color: dropdownPublicMenu ? 'var(--onsecondary-inverse)' : 'var(--onbackground-primary)' }} />
                        <span>{textosInterfaz?.quiero_make || 'Planes'}</span>
                    </button>
                </li>
                <li className="col-3 px-0">
                    <Link
                        to="/home"
                        className={`btn-option-menu focusable-red ${location.pathname === '/home' ? "activeMode" : ""}`}
                        onClick={() => setOpenDropdownPublicMenu(false)}
                    >
                        <Icon icon="mdi:book-open" width="30" />
                        <span>{textosInterfaz?.libros || 'Libros'}</span>
                    </Link>
                </li>
                <li className="col-3 px-0">
                    <Link
                        to="/login"
                        className={`btn-option-menu focusable-red ${location.pathname === '/login' ? "activeMode" : ""}`}
                        onClick={() => setOpenDropdownPublicMenu(false)}
                    >
                        <IconUser color={location.pathname === '/login' ? 'var(--onsecondary-inverse)' : 'var(--onbackground-primary)'} />
                        <span>{textosInterfaz?.ingreso || 'Ingreso'}</span>
                    </Link>
                </li>
            </ul>
        </>

    )
} 