import React from "react"
import { DataPrivacy } from "./components/data-privacy"
import { Accesibility } from "./components/accesibility"
import { Analytics } from "./components/analytics"

/**
 * El componente EdTechStandard representa una página o sección relacionada con "Estándares Abiertos para Integraciones en EdTech".
 * Incluye diferentes secciones como "Privacidad de Datos", "Accesibilidad WGCA" y "Analíticas".
 *
 * @component
 * @example
 * // Uso del componente EdTechStandard
 * <EdTechStandard />
 *
 * @returns {JSX.Element} - El componente EdTechStandard.
 */

export const EdTechStandard = () => {
    return (
        <>
            <div className="main-container background--bg">
                <div className="surface--bg onbackground-primary--color container main-content px-3 pb-5 p-lg-5"  style={{minHeight:'100vh'}}>
                    <div className="pb-5">
                        <h1 tabIndex={0} className="focusable-secondary text-center mb-5">OPEN STANDARD FOR EDTECH INTEGRATIONS</h1>
                        <div className="px-2 px-lg-5">
                            <div className="mb-4">
                                <h2 tabIndex={0} className="focusable-secondary subseccion-politica mb-2" style={{border:'none', outline:'none'}}>Data Privacy</h2>
                                <DataPrivacy/>
                            </div>
                            <div className="mb-4 w-100">
                                <h2 tabIndex={0} className="focusable-secondary subseccion-politica mb-2" style={{border:'none', outline:'none'}}>Accessibility WGCA</h2>
                               <Accesibility/>
                            </div>
                            {/* <div className="mb-4">
                                <h2 tabIndex={0} className="focusable-secondary subseccion-politica mb-2" style={{border:'none', outline:'none'}}>Analytics</h2>
                               <Analytics/>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}