import React from "react";

/**
 * Icono de comunidad MakeMake
 * @param {string} color -color por defecto de la fuente
 * @param {string} className - clase aplicada al icono
 * @returns {JSX.Element} IconComunidad
 */
export const IconComunidad = ({ color, className }) => {
    return (
        <svg width="21" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className? className : ''}>
            <rect x="7.06508" y="2.08364" width="18.2903" height="20.3226" fill={`${color? color : "#262626"}`}  stroke={`${color? color : "#262626"}`}  strokeWidth="2.70968" strokeLinejoin="round" />
            <mask id="path-2-outside-1_2471_31000" maskUnits="userSpaceOnUse" x="0" y="9.53516" width="19" height="21" fill="black">
                <rect fill="white" y="9.53516" width="19" height="21" />
                <path d="M3 9.53516H18.5806V27.1481H3V9.53516Z" />
            </mask>
            <path d="M3 27.1481H0.290323C0.290323 28.6446 1.50349 29.8577 3 29.8577L3 27.1481ZM18.5806 24.4384H3V29.8577H18.5806V24.4384ZM5.70968 27.1481V9.53516H0.290323V27.1481H5.70968Z" fill={`${color? color : "#262626"}`}  mask="url(#path-2-outside-1_2471_31000)" />
            <path d="M12.4844 9.19629H19.936" stroke="#E3E3F9" strokeWidth="2.70968" />
            <path d="M12.4844 14.9551H19.936" stroke="#E3E3F9" strokeWidth="2.70968" />
        </svg>

    )
}