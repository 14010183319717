import { useEffect, useRef, useState } from "react";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { BlogAPI } from "../../services/api/Blog";

/**
 * Este hook devuelve un objeto con el estado de carga y el arreglo de tags usados en la pagina principal del blog 
 * @returns {object< loading: Boolean; data: array>}
 */
export const useBlogTags = () => {
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const prevAbortController = useRef();

    const fetchTags = async () => {
        if (prevAbortController.current) {
            prevAbortController.current.abort();
        }
        const abortController = new CancelRequestController();
        prevAbortController.current = abortController;
        setLoading(true);

        await BlogAPI.getTags({ abortSignal: abortController.signal })
            .then((_tags) => {
                var newData = _tags;
                newData.sort(function () { return Math.random() - 0.5 });

                setTags(newData)
            })
            .catch((err) => {
                setTags([]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchTags();
        return () => {
            if (prevAbortController.current) {
                prevAbortController.current.abort();
            }
        };
    }, []);

    return {
        loading,
        data: tags,
    };
};
