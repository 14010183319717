import React from "react";
import MicroModal from "micromodal";
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";

/**
 * Componente de modal para mostrar advertencias de instalación.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.idModal - ID del modal.
 * @returns {JSX.Element} Elemento JSX que representa el componente.
 */
export const ModalAdvertenciasInstalacion = (props) => {
  const { idModal } = props;
  const { isReady, textosSubSecciones: textosHome } = useObtenerSubsecciones('home');

  const cerrarModal = () => {
    if (idModal) {
      MicroModal.close(idModal, {
        awaitCloseAnimation: true
      });
    }
  }
  return (
    <>
      {isReady &&
        <div className="micromodal-slide" id={idModal || ''} aria-hidden="true">
          <div className="modal__overlay" data-micromodal-close>
            <div 
              className="modal__container_instalacion background--bg" 
              role="dialog" 
              aria-modal="true" 
              aria-labelledby="titulo-Advertencia"
              aria-describedby="dialogo-Advertencia"
            >
              <div className="d-flex flex-row-reverse">
                <button
                  aria-label="Cerrar ventana de dialogo"
                  className="close-button onbackground-primary--color focusable-red"
                  data-micromodal-close onClick={() => cerrarModal()}
                >
                  <Icon icon={'mdi:close-thick'} width={35} />
                </button>
              </div>
              <div className="row align-items-center justify-content-center mt-3 mt-lg-0">
                <div className="col-sm-3">
                  <img src="./img/blop-alert.png" className="img-instalacion" alt="Personaje blop te alerta"/>
                </div>
                <div className="col-sm-8 onbackground-third--color">
                  <p id="dialogo-Advertencia">{textosHome?.modal_instalacion?.descripcion || 'No se ha podido instalar la aplicación debido a alguna de las siguientes causas:'}</p>
                  <ol className="mt-2 p-3">
                    <li className="mb-2">{textosHome?.modal_instalacion?.causaUno || 'La aplicación ya esta instalada'}</li>
                    <li>{textosHome?.modal_instalacion?.causaDos || 'El navegador no soporta la instalación'}</li>
                  </ol>

                </div>

              </div>
            </div>
          </div>
        </div>}
    </>
  )
}