import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { Titles } from "../../../components/Titles";
import { StaticBagde } from "../../components/badge";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

import { Helmet } from "react-helmet";

/**
 * Componente de titulo principal para que es bibliotecas
 * @returns {JSX.Element} Componente TitlePage.
 */
export const TitlePage = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
	const textosBibliotecas = useTranslations({ section: "que_es_biblioteca", lang: lang });

	return (
		<>
			<Helmet>
				<title>{textosBibliotecas?.metadata?.title || 'MakeMake - Plan bibliotecas'}</title>
				<meta name="description" content={textosBibliotecas?.metadata?.description || 'MakeMake ofrece en plan bibliotecas una estanteria de libros abierta para navegar entre colecciones, temas y curadurias especializadas.'} />
			</Helmet>
			<StaticBagde />
			<Titles
				hexBackground="#4A90E2"
				color="onsecondary--color"
				title={textosBibliotecas?.titulo?.texto || "Bibliotecas"}
			/>
		</>
	);
};
