import { isJSON } from "./isJSON";

/**
 * Esta función añade el usuario y los libros favoritos en el localstorage
 * @param {string} userName - Nombre del usuario
 * @param {string} book - id del libro que se guardará
 */
const saveFavorites = (userName, book) =>{
  let _users = {};
  let _books= [];

  const validStorage = localStorage.getItem("FavoritesBooks") && isJSON(localStorage.getItem("FavoritesBooks"));

  const _userName = userName ? userName : "SinNombre";

  if(validStorage){
    const _usersStorage = JSON.parse(window.localStorage.getItem("FavoritesBooks"));

    _users = _usersStorage;

    const existingUser = _userName in _usersStorage;

    if (existingUser) {
      const _userBooks = _usersStorage?.[userName];
      _books = Array.isArray(_userBooks) ? _userBooks : [];
      const existingBook = _books.includes(book);

      if (existingBook === false){
        _books.push(book)
      }
    }else{
      _books.push(book)
    }

  }else{
    _books.push(book)
  }

  _users[_userName]= _books;

  window.localStorage.setItem("FavoritesBooks", JSON.stringify(_users));
}

/**
 * Esta función remueve el libro de los libros favoritos 
 * @param {string} userName - Nombre del usuario
 * @param {string} book - id del libro que se eliminará
 */
const removeFavorites = (userName, book) =>{
  let _users = {};
  let _books= [];

  const validStorage = localStorage.getItem("FavoritesBooks") && isJSON(localStorage.getItem("FavoritesBooks"));

  if(validStorage){
    const _usersStorage = JSON.parse(window.localStorage.getItem("FavoritesBooks"));

    _users = _usersStorage;

    const existingUser = userName in _usersStorage;

    if (existingUser) {
      const _userBooks = _usersStorage?.[userName];
      const existingBook = _userBooks.includes(book);

      if(existingBook){
        const result = _userBooks.filter((item) => item !== book);
        _books = Array.isArray(result) ? result : [];
      }
    }

    _users[userName]= _books;
    window.localStorage.setItem("FavoritesBooks", JSON.stringify(_users));
  }
}

export const FavoritesStorage = {
  saveFavorites,
  removeFavorites
} 