import React, { useContext } from "react";
import { store } from "../Store";
import { useLocation } from "react-router-dom";

import { PublicMenu } from "./public-menu";
import { GeneralMenu } from "./general-menu";
import { IndividualMenu } from "./individual-menu";

import useIsMobile from "../../js/utilities/useIsMobile";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

/**
 * Este componente renderiza el menu inferior que solo aparece en dispositivos. Su principal función es identificar si la sesión esta activa. A partir de esto, asocia el tipo de usuario para mostrar el menu correspondiente.
 * @returns {JSX.Element} MenuResponsive
 */
export const MenuResponsive = () => {
    const globalState = useContext(store);
    const { state } = globalState;
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });

    const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
    const location = useLocation();
    const isMobile = useIsMobile();

    return (
        <>
            {
                isMobile &&
                <div className="menuMobile">

                    <div className="col-md-10 mx-auto px-0">
                        {isLogged && !location.pathname.includes('/visor') && state.cantidadLibros < 40 && globalState?.state?.cantidadLibros >= 0 &&
                            <IndividualMenu />
                        }

                        {isLogged && !location.pathname.includes('/visor') && state.cantidadLibros !== "NULL" && state.cantidadLibros > 40 &&
                            <GeneralMenu />
                        }

                        {!isLogged && location.pathname !== '/institucional/formulario' &&
                            <PublicMenu textosInterfaz={textosInterfaz} />
                        }
                    </div>
                </div>
            }
        </>
    )
}