import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonPodemosAyudar = () => {
  return (
    <>
      <SkeletonTheme color={'#e8e8e8'} highlightColor={'#cccccc'}>
        <div className="seccion-gris">
          <div className="row mx-auto col-lg-12 pb-lg-5 px-xl-4 px-3">
            <div className="col-lg-5">
              <Skeleton className="my-5 d-block " width={260} height={30} />

              <Skeleton className="my-4 " height={100} />
              <Skeleton className="my-4 " height={60} />

              <Skeleton className="col-lg-9 " height={40} />
              <Skeleton className="col-lg-6 " height={40} />


              <Skeleton className="my-4 mx-auto ml-lg-5" width={240} height={172} />
            </div>
            <div className="col-lg-7 pt-3 pt-md-5 pt-lg-0 d-flex  align-items-center pr-0">
              <form className="mx-auto">
                <div className="pt-lg-5">
                  <div className="d-flex flex-wrap">
                    <div className="mb-4 campos-formulario">
                      <Skeleton width={186} height={30} />
                      <Skeleton height={40} />
                    </div>

                    <div className="mb-4 campos-formulario">
                      <Skeleton width={186} height={30} />
                      <Skeleton height={40} />
                    </div>

                    <div className="mb-4 campos-formulario">
                      <Skeleton width={186} height={30} />
                      <Skeleton height={40} />
                    </div>

                    <div className="mb-4 campos-formulario">
                      <Skeleton width={186} height={30} />
                      <Skeleton height={40} />
                    </div>

                    <div className="mb-4 campos-formulario">
                      <Skeleton width={186} height={30} />
                      <Skeleton height={40} />
                    </div>

                    <div className="mb-4 campos-formulario">
                      <Skeleton width={186} height={30} />
                      <Skeleton height={40} />
                    </div>
                  </div>

                  <div className="d-flex flex-wrap justify-content-between align-items-center px-3 pt-3 pb-5 py-lg-5">
                    <div className="col-md-9 px-0">
                    <Skeleton  height={54} />
                    </div>
                    <Skeleton className="  mt-3 mt-md-0" width={120} height={54} />
                    
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </SkeletonTheme>

    </>
  )
}
export default SkeletonPodemosAyudar;