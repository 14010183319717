import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Navigation, Grid } from "swiper";
import Libro from "../components/Libro";
import { Icon } from "@iconify/react";

/**
 * Componente de swiper de la colección
 * @param {Array} books - arreglo de objetos que contiene la información de los libros
 * @returns {JSX.Element} swiper de libros
 */
export const CollectionBooks = ({ books }) => {
  const swiperRef = useRef(null);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  /**
   * Actualiza los botones de navegación del swiper
   * @param {Element} swiper
   */
  const updateNavigation = (swiper) => {
    swiper.params.navigation.prevEl = prevButtonRef?.current
    swiper.params.navigation.nextEl = nextButtonRef?.current
    swiper.navigation.destroy()
    swiper.navigation.init()
    swiper.navigation.update()
  }

  /**
   *  Función que crea los sliders 
   * @returns {Array} arreglo con los componentes SwiperSlide
   */
  const createSlide = () => {
    let slides = [];
    books.map((item, i) => {
      slides.push(
        <SwiperSlide key={`slide-${i}`}>
          <div className='cover-book'>
            <Libro
              libro={item}
              portada={
                item.portada
              }
              webUrl={
                item.link
              }
            />
          </div>
        </SwiperSlide>
      )
    })
    return slides;
  }

  return (
    <Swiper
      ref={swiperRef}
      className="carrusel-coleccion"
      slidesPerGroup={2}
      slidesPerView={2}
      spaceBetween={40}
      grid={{
        rows: 3,
        fill: 5
      }}
      pagination={{
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 1,
        type: "bullets",
        renderBullet: function (index, className) {
          return (
            '<span class="' +
            className +
            '">' +
            (index + 1) +
            "</span>"
          );
        },
      }}
      breakpoints={{
        526: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          grid: {
            rows: 2,
            fill: 3
          }
        }
      }}
      mousewheel={{
        forceToAxis: true,
      }}
      navigation={{
        nextEl: nextButtonRef?.current,
        prevEl: prevButtonRef?.current,
      }}
      onSwiper={(swiper) => {
        updateNavigation(swiper);
      }}
      modules={[Autoplay, Navigation, Mousewheel, Grid]}
    >
      {createSlide()}
      <div className="position-absolute w-100 d-flex justify-content-between pt-3">
        <button ref={prevButtonRef} aria-label="previous" className="collection-swiper-navigation-buttons mx-2">
          <Icon className="icon" icon="iconamoon:arrow-left-2-bold" width={50} />
        </button>
        <button ref={nextButtonRef} aria-label="next" className="collection-swiper-navigation-buttons">
          <Icon className="icon" icon="iconamoon:arrow-right-2-bold" width={50} />
        </button>
      </div>
    </Swiper >
  )
}