import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard } from "swiper";
import { Img } from "../../../components/shared/img";
import { BIBLIOTECAS_DATA } from "../data/bibliotecas-data";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { ALL_LIBRARYS_IMAGES } from "../../../shared/data/libraries-data";

/**
 * Componente de "Experiencia comprobada" para que es bibliotecas
 * @returns {JSX.Element} Componente Experience.
 */
export const Experience = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textosBibliotecas = useTranslations({ section: "que_es_biblioteca", lang: lang });

	/**
	 * Esta función crea un arreglo con varios SwiperSlide. Cada uno de estos contiene un comentario
	 * @returns {Array<HTMLElement>} arreglo de SwiperSlide
	 */
	const crearSlide = () => {
		let items = [];
		const comentarios = textosBibliotecas?.comentarios || [];

		Object.keys(comentarios).map((item, index) => {
			if (comentarios[item].comentario !== "null") {
				items.push(
					<SwiperSlide
						className={`swiper-slide focusable-secondary comments`}
						key={`slide-${index}`}
						modules={[Autoplay, Keyboard]}
						style={{ fontSize: "0.9em" }}
					>
						<div
							tabIndex={0}
							className={
								"focusable-secondary  experience-libraries py-4 mb-5 mt-3 mx-auto"
							}
						>
							<p className="d-block col-11 mx-auto text-center">
								{comentarios[item].comentario}
							</p>
						</div>
						<p
							tabIndex={0}
							className="focusable-secondary onbackground-primary--color col-lg-10 mx-auto text-center"
						>
							<span className="font-weight-bold">{comentarios[item].nombre} </span>{" "}
							{comentarios[item].cargo !== "null" && comentarios[item].cargo}{" "}
						</p>
						<p
							tabIndex={0}
							className="focusable-secondary onbackground-primary--color col-lg-10 mx-auto text-center font-weight-bold"
						>
							{" "}
							{comentarios[item].lugar}
						</p>
					</SwiperSlide>
				);
			}
		});
		return items;
	};

	/**
	 * Esta función crea un SwiperSlide con un conjunto de Bibliotecas
	 * @returns {Array<HTMLElement>} arreglo de un conjunto de Bibliotecas
	 */
	const librariesSlides = () => {
		const institutionSections = [];
		const sectionLength = Math.ceil(ALL_LIBRARYS_IMAGES.length / 8);
		for (let i = 0; i < sectionLength; i++) {
			const items = ALL_LIBRARYS_IMAGES.slice(i * 8, (i + 1) * 8);
			institutionSections.push(items);
		}
		return institutionSections.map((section, i) => {
			return (
				<SwiperSlide key={`slide-${i}`}>
					<div className="row mx-0">
						{section.map((institution, j) => {
							return (
								<div
									className="col-4 col-lg-3 p-1 p-lg-2 focusable-by-children-secondary"
									key={`slide-${j}`}
								>
									<button aria-label={`colegio ${institution.src}`}>
										<div className="cover">
											<Img
												src={institution?.src}
												alt={`institution-${j}`}
												className="img-fluid"
											/>
										</div>
									</button>
								</div>
							);
						})}
					</div>
				</SwiperSlide>
			);
		});
	};

	return (
		<>
			<div className="experience pb-4">
				<div className="container px-lg-0">
					<div className="col-lg-10 mx-auto py-2">
						<h1
							tabIndex={0}
							className="focusable-secondary secondary-font font-weight-bold py-4 onbackground-primary--color"
						>
							{textosBibliotecas?.experiencia?.titulo || "Experiencia comprobada"}
						</h1>
						<p className="onbackground-primary--color" style={{ fontSize: "0.9em" }}>
							{textosBibliotecas?.experiencia?.descripcion || "descripcion"}
						</p>

						<Swiper
							autoplay={{
								delay: 3000,
							}}
							breakpoints={{
								526: {
									slidesPerView: 1,
									slidesPerGroup: 1,
								},
								992: {
									slidesPerView: 1,
									slidesPerGroup: 1,
								},
								1400: {
									slidesPerView: 1,
									slidesPerGroup: 1,
								},
							}}
							className="py-lg-3 mb-4 swiper-editoriales"
							loop={true}
							modules={[Autoplay, Keyboard]}
							pagination={{
								clickable: true,
								type: "bullets",
							}}
							preloadImages={true}
							slidesPerView={1}
							style={{ height: 400 }}
						>
							{crearSlide()}
						</Swiper>
					</div>
				</div>
			</div>

			<div className="container px-lg-0 py-5">
				<div className="col-lg-10 mx-auto">
					<h2 className="focusable-secondary secondary-font font-weight-bold pb-4 onbackground-primary--color">
						{textosBibliotecas?.experiencia?.subtitulo ||
							"¡Las mejores bibliotecas ya están con makemake!"}
					</h2>

					<Swiper
						className="pt-lg-3  pb-lg-4 swiper-editoriales"
						autoplay={{
							delay: 5000,
						}}
						breakpoints={{
							526: {
								slidesPerView: 1,
								slidesPerGroup: 1,
							},
							992: {
								slidesPerView: 1,
								slidesPerGroup: 1,
							},
							1400: {
								slidesPerView: 1,
								slidesPerGroup: 1,
							},
						}}
						loop={true}
						pagination={{
							clickable: true,
							type: "bullets",
						}}
						preloadImages={true}
						slidesPerView={1}
					>
						{librariesSlides()}
					</Swiper>
				</div>
			</div>
		</>
	);
};
