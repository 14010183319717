import React from "react";
import { Img } from "../../../components/shared/img";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { ALL_LIBRARYS_IMAGES } from "../../../shared/data/libraries-data";
import { ALL_INSTITUTIONS_IMAGES } from "../../../shared/data/institutions-data";

export const Clients = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const textosClientes = textos?.clientes;

	const clients = [...ALL_LIBRARYS_IMAGES, ...ALL_INSTITUTIONS_IMAGES];

	return (
		<div className="surface--bg py-4 onbackground-primary--color">
			<div className="container p-2" style={{ maxWidth: 600 }}>
				<p className="text-center font-weight-bold mt-3">
					{textosClientes?.titulo ||
						"En América Latina y Estados Unidos, bibliotecas y colegios eligen MakeMake"}
				</p>
			</div>
			<Swiper
				className="p-2"
				style={{ overflowX: "hidden", pointerEvents: "none" }}
				slidesPerView={"auto"}
				autoplay={{
					delay: 1,
					disableOnInteraction: false,
				}}
				speed={2 * 1000}
				loop={true}
				freeMode={true}
				direction="horizontal"
				modules={[Autoplay, FreeMode]}
			>
				{clients.map((client, i) => {
					return (
						<SwiperSlide
							key={i}
							className="d-flex justify-content-center align-items-center mx-2 p-0"
							style={{
								minWidth: 200,
								maxWidth: 200,
								height: "auto",
								pointerEvents: "none",
							}}
						>
							<Img
								key={i}
								style={{ minWidth: 200, maxWidth: 200, height: "auto" }}
								src={client?.src}
								alt="client-image"
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};
