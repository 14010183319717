import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

/**
 * skeleton del header sin login
 * @returns {JSX.Element} SkeletonHeader
 */
export const SkeletonHeader = () => {
  return (
    <SkeletonTheme color="#636363" highlightColor="#262626">
      <nav className="row mx-0">
        <div className="col-lg-2 col-xl-4 px-0 d-flex">
          <Skeleton height={40} width={80} className="mr-3" />
          <Skeleton height={40} width={40} />
        </div>

        <div className="col-lg-10 col-xl-8 d-none d-lg-block">
          <ul className="option-nav d-flex justify-content-end aling-items-center">
            <Skeleton count={5} height={40} width={110} className="mr-3" />
            <Skeleton count={2} height={40} width={40}  className="mr-3" />
          </ul>
        </div>
      </nav>
    </SkeletonTheme>
  )
}