import MicroModal from "micromodal";
import React from "react";
/**
 * Componente de Modal para confirmar la eliminación de descargas.
 * 
 * @param {object} props - Propiedades del componente.
 * @param {string} props.message - Mensaje del título del modal.
 * @param {string} props.description - Descripción del modal.
 * @param {Function} props.onConfirm  - función que se ejecuta cuando se da click a 'aceptar'.
 * @param {object} props.textoBotones - Texto de los botones. Opcional.
 * @returns {JSX.Element} Componente ModalConfirmacionEliminarDescargas.
 */
const ModalConfirmacionEliminarDescargas = (props) => {

  const { message, description, textoBotones, onConfirm } = props;

  return (
    <div className="sin-conexion-container modal micromodal-slide" id="modal-eliminar-descarga" aria-hidden="true" style={{zIndex:9999999}}>
      <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
        <div 
          className="surface--bg modal__container alert_container micromodal-slide-eliminar" 
          role="dialog" 
          aria-modal="true" 
          aria-labelledby={'titulo-eliminar-descarga'}
          aria-describedby={'dialogo-eliminar-descarga'}
        >
          <div className="col-sm-9 col-lg-7 mx-auto mb-4">
            <img className="col-12" src="/img/elimiar_todos_libros.png" alt="icono adicional" />
          </div>
          <h2 className="red-inverse--color text-center primary-item " id="titulo-eliminar-descarga">
            {message}
          </h2>
          <div className="modal__content my-2" id="modal-alerts-content">
            <p className="onbackground-primary--color mb-4 mb-sm-5 text-center" id="dialogo-eliminar-descarga">
              {description}
            </p>
            <div className="d-flex justify-content-between col-sm-8 col-lg-7 mx-auto">
              <button 
                className="surface--bg onbackground-primary--color  blue-inverse--bordered px-lg-4 btn btn-cancelar d-block mx-auto focusable-red" 
                data-micromodal-close
              >{textoBotones?.cancelar ||'Cancelar'}</button>
              <button
                className="surface--bg onbackground-primary--color  blue-inverse--bordered px-lg-4 btn btn-delete d-block mx-auto focusable-red" 
                style={{outline:"none", boxShadow:'none'}}
                onClick={() => {
                  onConfirm && onConfirm();
                  MicroModal.close("modal-eliminar-descarga")
                }}
              >
                {textoBotones?.eliminar || 'Eliminar'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default ModalConfirmacionEliminarDescargas;