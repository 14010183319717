import React from "react";
import { Img } from "../../../../components/shared/img";
import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../../config/constants";
import { Helmet } from "react-helmet";

/**
 * Componente que devuelve el contenido de contactanos, de la tienda para instituciones
 * @returns {JSX.Element} Contactanos
 */
export const Contactanos = ({urlLang}) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: urlLang });
    const textos = useTranslations({ section: "tienda_makemake", lang: lang });

    return (
        <>
            <Helmet>
                <title>{textos?.metadata?.contactanos?.title || 'MakeMake - Contáctanos'}</title>
                <meta name="description" content={textos?.metadata?.contactanos?.description || 'Contáctanos a través de estos medios'} />
            </Helmet>

            <div className="subsection">
                <div className={`d-flex pt-4 pb-2 px-3 justify-content-end`}>
                    <div>
                        <Img src="/img/tienda-makemake/icons/contactanos.png" alt="icono brochure" />
                    </div>
                </div>

                <div className="row p-3 p-xl-5" style={{ fontSize: '0.9em' }}>
                    <div className="col-xl-6 mb-4 mb-xl-0">
                        <h3 
                            style={{fontSize: '1.3em'}}
                            className="secondary-font font-weight-bold primary-inverse--color mb-3"
                        >
                            {textos?.contactanos?.colombia || 'Colombia y América Latina'}
                        </h3>
                        <a
                            className="break-words focusable-secondary onsecondary--color"
                            href="mailto:contacto@makemake.com.co"
                            target="_blank"
                        >
                            contacto@makemake.com.co
                        </a>

                        <div className="mt-4">
                            {/* <a
                            href="https://wa.link/vuju91"
                            className="d-block focusable-secondary mb-2 onsecondary--color"
                            target="_blank"
                        >
                            +57 313 4509992
                        </a> */}
                            <a
                                href="https://wa.link/aozzbk"
                                className="d-block focusable-secondary  mb-2 onsecondary--color"
                                target="_blank"
                            >
                                +57 350 2367037
                            </a>
                            <a
                                href="https://wa.link/jpdnk6"
                                className="d-block focusable-secondary onsecondary--color"
                                target="_blank"
                            >
                                +57 350 2011041
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <h3 
                            style={{fontSize: '1.3em'}}
                            className="secondary-font font-weight-bold primary-inverse--color mb-3"
                        >
                            {textos?.contactanos?.usa || 'Estados Unidos'}
                        </h3>

                        <p className="onsecondary--color">Lectorum Publications Inc.</p>
                        <a
                            className="break-words focusable-secondary onsecondary--color"
                            href="mailto:acorrea@lectorum.com"
                            target="_blank"
                        >
                            acorrea@lectorum.com
                        </a>
                        <br/>
                        <a
                            className="break-words focusable-secondary onsecondary--color"
                            href="mailto:makemake@lectorum.com"
                            target="_blank"
                        >
                            makemake@lectorum.com
                        </a>

                        <div className="mt-4">
                            <a
                                href="tel:+1(201)5592200"
                                className="d-block focusable-secondary onsecondary--color"
                                target="_blank"
                            >
                                + 1 (201) 559-2200
                            </a>
                            <a
                                href="tel:+1(800)3455946"
                                className="d-block focusable-secondary onsecondary--color"
                                target="_blank"
                            >
                                + 1 (800) 345-5946
                            </a>
                        </div>

                        <p className="mt-4 onsecondary--color">205 Chubb Avenue, Bldg A-1,</p>
                        <p className="onsecondary--color">Lyndhurst, NJ 07071  </p>
                  

                    </div>
                </div>
            </div>
        </>
    )
}