import React, { useContext, useState, useEffect } from "react";
import { store } from '../components/Store.js';
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones.js";
import getUrlParam from "../js/utilities/getUrl.js";
import { Img } from "../components/shared/img"
import { Link } from "react-router-dom";

/**
 * Este componente no recibe props. Este se encarga de mostrar un mensaje en caso de que el formulario de IngresarDatos (suscripción al boletín) haya sido diligenciado previamente. 
 * @returns {JSX.Element} MensajeInscripcion
 */
const MensajeInscripcion = () => {
  const { isReady, textosSubSecciones: textos } = useObtenerSubsecciones('boletin');

  const globalState = useContext(store);
  const { state } = globalState;
  const [isLoading, setIsloading] = useState();
  const [mensajePlanes, setMensajePlanes] = useState(false);


  useEffect(() => {
    if (state.mainDataLoaded === true && isLoading) {
      setIsloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.mainDataLoaded])

  //actualiza el campo de correo, en caso de que se haya diligenciado el correo con anterioridad
  useEffect(() => {
    const solicitud = getUrlParam("solicitud");
    const incluyeInformacion = solicitud.includes('informacion') ? true : false;
    setMensajePlanes(incluyeInformacion);
  }, []);
  return (
    <div className="main-container mm-redimir" style={{minHeight: '70vh'}}>
      <div className="container main-content py-5">
        <div className="p-3 pt-lg-0 pl-lg-5">

          <div id="mm-mensaje-institucion">
            {
              mensajePlanes ?
                <>
                  <div className="col-lg-10 mx-auto ">
                    <h2 className="mm-redimir_title mb-5 text-center ">{textos?.mensaje?.titulo || '¡Muchas gracias por tu interés en MakeMake!'}</h2>
                    <p className="mm-redmir_textlogin text-center onbackground-third--color">{textos?.mensaje?.descripcion || 'MakeMake es una biblioteca de lectura digital para que niños y adultos disfruten de los mejores libros de América Latina. Manejamos diversos planes que se adaptan a las necesidades y características de cada institución, para brindarte más información es necesario conocer más sobre tu institución. Para ello te invitamos a agendar una reunión con uno de nuestros asesores acá: '}</p>
                    <div className="d-flex justify-content-center align-items-center">
                    <a
                      className=" my-3 text-center btn btn-primary bold focusable-red"
                      href="https://makemake.com.co/tienda/institucional?idioma=es"
                      target="_blank"
                    >{textos?.mensaje?.agendar || 'Agendar reunión'}</a>
                    </div>
                  </div>

                  <p className="text-center onbackground-third--color">{textos?.mensaje?.informacion || 'O comunicarte a'}</p>
                  <a
                    className="mt-3 d-block text-center onbackground-third--color bold focusable-red"
                    href="mailto:comercial@makemake.com.co"
                    aria-label="correo: comercial@makemake.com.co"
                  > comercial@makemake.com.co</a>
                  <a
                    className="my-2 d-block text-center onbackground-third--color bold focusable-red"
                    aria-label="télefono 1: +57 350 236 7037"
                    href="tel:+573502367037"
                  >(+57) 350 236 7037</a>
                  <p className="text-center onbackground-third--color">{textos?.mensaje?.despedida || '¡Hasta pronto!'}</p>
                </>

                :
                <>
                  <div className="col-lg-10 mx-auto ">
                    <h2 className="mm-redimir_title mb-5 text-center ">{textos?.mensaje?.titulo || '¡Muchas gracias por tu interés en MakeMake!'}</h2>
                    <Img
                      alt={`matilda saltando`}
                      src="/img/matilda-saltando.png"
                      width={200}
                    />
                    <p className="mm-redmir_textlogin text-center onbackground-third--color mt-5">{textos?.mensaje?.registrado || 'Tu correo ha sido registrado con éxito'}</p>
                    <Link to={'/busqueda?sch='} className=" d-block text-center secondary--color bold focusable-red">{textos?.mensaje?.explorar || 'Sigue explorando en MakeMake'}</Link>
                    <p className="text-center onbackground-third--color mt-2">{textos?.mensaje?.despedida || '¡Hasta pronto!'}</p>
                  </div>
                </>
            }

          </div>
        </div>
      </div>
    </div>
  )
}


export default MensajeInscripcion;