import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { CuraduriaCard } from "./curaduria-card";
import { ASSETS_URL } from "../components/Store";
import { trackWindowScroll } from "react-lazy-load-image-component";
import { NoResults } from "../components/shared/no-results";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSyncPagination } from "../hooks/usePagination";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet";

/**
 * Componente que renderiza el listado de colecciones,  recibe como parametro un arreglo de colecciones.
 */
const Colecciones = ({ colecciones, lang }) => {
  const { textosSubSecciones: textos } = useObtenerSubsecciones('curadurias');
  const history = useHistory();
  colecciones = Array.isArray(colecciones) ? colecciones : [];
  const titulo = colecciones?.length !== 1 ? `${textos?.colecciones?.titulo?.plural || 'Colecciones'}` : `${textos?.colecciones?.titulo?.singular || 'Coleccion'}`;
  const pagination = useSyncPagination({ itemsPerPage: 8, data: colecciones, nextPageDelay: 200 });


  return <div className="w-100 p-2 d-flex flex-column">
    <Helmet>
      <title>{textos?.metadata?.colecciones?.title || 'MakeMake - Colecciones'}</title>
      <meta name="description" content={textos?.metadata?.colecciones?.description || 'En la sección Colecciones encontrarás curadurías creadas cuidadosamente por MakeMake en torno a diferentes temáticas'} />
    </Helmet>
    <h2 className="primary-font p-3 pl-4 onbackground-primary--color">{colecciones?.length} {titulo}</h2>
    <div className="px-2">
      {
        colecciones?.length > 0 ?
          <>
            <InfiniteScroll
              style={{ overflow: 'visible' }}
              className='row align-self-center'
              dataLength={pagination.paginatedData.length}
              next={pagination.nextPage}
              hasMore={pagination.hasMore}
              scrollThreshold={0.86}
              loader={
                <div
                  className="secondary-inverse--color container-fluid w-100 d-flex justify-content-center align-items-center col-md-10 col-lg-10">
                  <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                </div>
              }
            >
              {
                pagination.paginatedData?.map((coleccion, i) => {
                  return <div key={i} className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                    <CuraduriaCard
                      key={i}
                      // data={{ ...coleccion, imgUrl: `https://nf.app.makemake.reallabs.com.co/makemake/coleccion/manualidades/img/imagenHomeOptim.png` }}
                      data={{ ...coleccion, imgUrl: `${ASSETS_URL}${coleccion?.imgUrl}` }}
                      onClick={() => history.push(coleccion?.url)} lang={lang} />
                  </div>
                })
              }

            </InfiniteScroll>
            {
              pagination.hasMore &&

              <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
                <p className='secondary-inverse--color bold'>
                  {`${textos?.scroll?.descripcion ||
                    ' Desplazate hacia abajo para cargar más contenido.'}`
                  }

                </p>
                <Icon icon="mdi:mouse-move-down" className='secondary-inverse--color text-28 mx-2' width={30} />
              </div>
            }
          </>
          : <NoResults title={'Aún no hay colecciones'} message={'No hay colecciones asociadas a tus libros.'} />
      }
    </div>
  </div>
}
Colecciones.propTypes = {
  /**
   * Arreglo con las colecciones
   */
  colecciones: PropTypes.arrayOf(PropTypes.shape({
    nombre: PropTypes.string,
    descripcion: PropTypes.string,
    url: PropTypes.string,
    imgUrl: PropTypes.string
  }))
}
export const ColeccionesList = trackWindowScroll(Colecciones)