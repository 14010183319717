import React, { useState, useEffect, useRef } from "react";
import postAjax from './../js/utilities/postAjax';
import { GENERAL_URL_API } from "./Store";
import { useHistory } from 'react-router-dom';
import ModalAlertas from "./ModalAlertas";
import getUrlParam from './../js/utilities/getUrl';
import MicroModal from 'micromodal';

/**
 * Componente de formulario para llenar datos para acceder a un demo
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.textosDemo - Textos de demostración.
 * @returns {JSX.Element} Elemento JSX que representa el formulario de llenado de datos.
 */
const FormLlenarDatos = (props) => {
  const { textosDemo } = props;
  const { formulario, botones } = textosDemo;
  const { nombre_institucion, tipo_institucion, cargo, telefono, nombre, correo, confirmar_correo, edad, ciudad, pais, terminos, moda_alerta } = formulario;
  const [paises, setPaises] = useState([]);
  const [preloader, setPreloader] = useState(false);
  const [respuestaApi, setRespuestaApi] = useState({
    description: '',
    message: ''
  })

  const refFormulario = useRef(null);

  const refNombreInstitucion = useRef(null);
  const refTipoInstitucion = useRef(null);
  const refCargo = useRef(null);
  const refTelefono = useRef(null);

  const refNombre = useRef(null);
  const refCorreo = useRef(null);
  const refConfirmarCorreo = useRef(null);
  const refEdad = useRef(null);
  const refCiudad = useRef(null);
  const refPais = useRef(null);

  /***notificaciones */
  const [notificationAge, setNotificationAge] = useState('');

  const history = useHistory()

  useEffect(() => {
    refEdad.current.addEventListener("invalid", () => { 
      setNotificationAge(edad.alert) 
    });
  }, [refEdad, edad]);

  useEffect(() => {
      setNotificationAge(edad.alert) 
  }, [edad]);

  useEffect(() => {
    const sent = getUrlParam('sent');
    if (!!sent) {
      if (sent === 'false3') {
        setRespuestaApi({
          description: moda_alerta?.descripcion || 'Tu correo ya aparece en nuestra base de datos. Esto quiere decir que ya has solicitado un demo anteriormente o que solicitaste los datos de una institución.',
          message: moda_alerta?.mensaje || 'Ya has inscrito este correo',
        })
        MicroModal.show('modal-alerts', {
          awaitCloseAnimation: true
        })
      }
    }

    postAjax(`${GENERAL_URL_API}/assets/datosPaises`, {}, response => {
      const data = JSON.parse(response).paises;
      setPaises(data);
    });
  }, [])



  const enviarFormulario = (e) => {
    e.preventDefault();
    if (refFormulario.current.reportValidity()) {
      setPreloader(true);
      const nombreP = refNombre.current.value;
      const correoP = refCorreo.current.value;
      const institucionP = refNombreInstitucion.current.value;
      const telefonoP = refTelefono.current.value;
      const referenciaP = '';
      const paisP = refPais.current.value;
      const ciudadP = refCiudad.current.value;
      const edadP = refEdad.current.value;
      const otraRazonP = '';
      const textoOtroP = '';

      const propiedades = { nombreP, correoP, institucionP, telefonoP, referenciaP, paisP, ciudadP, edadP, otraRazonP, textoOtroP }

      postAjax(`${GENERAL_URL_API}/assets/demo/enviarCodigoVerificacion`, propiedades, response => {
        const error = response.status === 0;
        if (!error) {
          history.push(`/demo/demo-confirmar-correo?nombre=${nombreP}&correo=${correoP}&institucion=${institucionP}&telefono=${telefonoP}&pais=${paisP}&ciudad=${ciudadP}&edad=${edadP}`);
          setPreloader(false);
        }
      })
    }
  }

  const beneficios = () => {
    const puntos = [];
    const textoPuntos = formulario.lista;
    Object.keys(textoPuntos).map((item, index) => {
      puntos.push(
        <li key={`opcion${index}`} className="onbackground-third--color"><i className="fas fa-check primary-item mr-2 red-inverse--color"></i>{textoPuntos[item]}</li>
      )
    })

    return puntos;
  }

  const tipoInstitucion = () => {
    const puntos = [];
    const textoPuntos = tipo_institucion.opciones;
    Object.keys(textoPuntos).map((item, index) => {
      puntos.push(
        <option key={`beneficios${index}`} value={textoPuntos[item]}>{textoPuntos[item]}</option>
      )
    })

    return puntos;
  }

  return (
    <>
      <form ref={refFormulario} id="contact-form" name="contactoForm" className="pb-5 mb-5 surface--bg">
        <div id="frmTest" className="mm-redimir-interesa p-0 mm-redimir_data py-5 mb-3">
          <div className="col-lg-9 mx-auto pt-3">
            <div className="mm-redimir_createaccount mb-5" style={{ display: 'none' }} />

            <div id="info-instituto">
              <div className="mm-demo-comprar secondary--bg onsecondary--bordered  p-3 p-lg-5 my-4">
                <div className="row mx-auto">
                  <div className="col-lg-10 mx-auto">
                    <div className="pl-content-container surface--bg py-5">
                      <p className="text-center bold secondary-item secondary-inverse--color mb-4">{formulario.titulo || 'Beneficios para instituciones'}</p>
                      <div className="px-4">
                        <ul>
                          {beneficios(formulario.opciones)}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-4">
                {/* <!--<p className="my-4">Para entregarte un demo necesitamos saber más de ti</p>--> */}
                <div className="col-lg-6 mb-3">
                  <p className="secondary-item secondary-inverse--color"><strong>{nombre_institucion?.texto || 'Nombre de institución:'}</strong></p>
                  <label htmlFor="institutoName" className="sr-only" aria-hidden={true}>{nombre_institucion?.label || 'Instituto'}</label>
                  <input
                    ref={refNombreInstitucion}
                    type="text"
                    className="form-control surface--bg  onbackground-third--color focusable-secondary"
                    id="institutoName"
                    placeholder={nombre_institucion?.placeholder || "Escribe el nombre de tu instituto o biblioteca"}
                    name="institucionP"
                    required
                  />
                </div>

                <div className="col-lg-6 mb-3">
                  <p className="secondary-item secondary-inverse--color"><strong>{tipo_institucion?.texto || 'Tipo de institución:'}</strong></p>
                  <label htmlFor="tipoInstitucion" className="sr-only" aria-hidden={true}>{tipo_institucion?.label || 'Tipo de institución:'}</label>
                  <select
                    ref={refTipoInstitucion}
                    className="form-control surface--bg  onbackground-third--color focusable-secondary"
                    id="tipoInstitucion"
                    name="tipoInstitucion"
                    required
                  >
                    <option hidden value="">{tipo_institucion?.placeholder || 'Escoge un tipo de institución'}</option>
                    {tipoInstitucion()}
                  </select>

                </div>

                <div className="col-lg-6 mb-3">
                  <p className="secondary-item secondary-inverse--color"><strong>{cargo?.texto || 'Cargo que ocupas:'}</strong></p>
                  <label htmlFor="institutoCargo" className="sr-only" aria-hidden={true}>{cargo?.label || 'Cargo que ocupas'}</label>
                  <input
                    ref={refCargo}
                    type="text"
                    className="form-control surface--bg  onbackground-third--color focusable-secondary"
                    id="institutoCargo"
                    placeholder={cargo?.placeholder || "Escribe tu cargo"}
                    name="institutoCargo"
                    required
                  />
                </div>

                <div className="col-lg-6  mb-3 mb-lg-0">
                  <p className="secondary-item secondary-inverse--color"><strong>{telefono?.texto || 'Teléfono:'}</strong></p>
                  <label htmlFor="inputPhone" className="sr-only" aria-hidden={true}>{telefono?.label || 'Teléfono'}</label>
                  <input
                    ref={refTelefono}
                    type="text"
                    className="form-control surface--bg  onbackground-third--color focusable-secondary"
                    id="inputPhone"
                    placeholder={telefono?.placeholder || "Escribe tu teléfono"}
                    name="telefonoP"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="mm-redimir_createaccount col-lg-9 mx-auto pt-3 pb-5">
          <div className="row my-4">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <p className="secondary-item secondary-inverse--color"><strong>{nombre?.texto || 'Nombre completo*:'}</strong></p>
              <label htmlFor="inputName" className="sr-only" aria-hidden={true}>{nombre?.label || 'Nombre completo'}</label>
              <input
                ref={refNombre}
                type="text"
                className="form-control surface--bg  onbackground-third--color focusable-secondary"
                id="inputName"
                placeholder={nombre?.placeholder || "Escribe tu nombre y apellido aquí"}
                name="nombreP"
                required
              />
            </div>
            <div className="col-lg-6">
              <p className="secondary-item secondary-inverse--color"><strong>{correo?.texto || 'Correo electrónico*:'}</strong></p>
              <label htmlFor="inputEmail" className="sr-only" aria-hidden={true}>{correo?.label || 'Correo electrónico'}</label>
              <input
                ref={refCorreo}
                type="email"
                className="form-control surface--bg  onbackground-third--color focusable-secondary"
                id="inputEmail" placeholder={correo?.placeholder || "Escribe tu correo electrónico"}
                name="correoP"
                required
              />
            </div>
          </div>

          <div className="row my-4">
            <div className="col-lg-6  mb-4 mb-lg-0">
              <p className="secondary-item secondary-inverse--color"><strong>{confirmar_correo?.texto || 'Confirmar correo electrónico*:'}</strong></p>
              <label htmlFor="inputEmail" className="sr-only" aria-hidden={true}>{confirmar_correo?.label || 'Confirmar correo electrónico'}</label>
              <input
                ref={refConfirmarCorreo}
                type="email"
                className="form-control surface--bg  onbackground-third--color focusable-secondary"
                id="inputEmailConfirm"
                placeholder={confirmar_correo?.placeholder || "Escribe de nuevo tu correo electrónico"}
                name="correoPConfirm"
                required
              />

            </div>

            <div className="col-lg-6">
              <p className="secondary-item secondary-inverse--color mm-redimir_questioncodeactivate pointer">
                <strong>{edad?.texto || 'Edad*:'} </strong>
                <i

                  style={{ fontSize: 15, color: 'var(--onbackground-third)' }}
                  className="fas fa-question-circle tooltip"

                >
                  <span className="tooltiptext" role="tooltip" id="description-tooltip" aria-hidden={false}
                  >{edad?.tooltip || 'Es necesario que seas mayor de edad para solicitar un demo y crear una cuenta'}</span>
                </i>
              </p>

              <label htmlFor="inputAge" className="sr-only" aria-hidden={true}>{edad?.label || 'Edad'}</label>
              <input
                ref={refEdad}
                type="number"
                min={18}
                className="form-control surface--bg  onbackground-third--color focusable-secondary"
                id="inputAge"
                placeholder={edad?.placeholder || "Escribe tu edad"}
                name="edadP"
                required
                onChange={() => setNotificationAge('')}
              />

              <span role={"alert"} aria-live="assertive" className="notificacion-form">{notificationAge}</span>
            </div>
          </div>

          <div className="row my-4">

            <div className="col-lg-6  mb-4 mb-lg-0">
              <p className="secondary-item secondary-inverse--color"><strong>{ciudad?.texto || 'Ciudad:'}</strong></p>
              <label htmlFor="inputPhone" className="sr-only" aria-hidden={true}>{ciudad?.ciudad || 'Ciudad'}</label>
              <input
                ref={refCiudad}
                type="text"
                className="form-control surface--bg  onbackground-third--color focusable-secondary"
                id="inputCiudad" placeholder={ciudad?.placeholder || "Escribe tu ciudad"}
                name="ciudadP"
                required
              />

            </div>

            <div className="col-lg-6">
              <p className="secondary-item secondary-inverse--color"><strong>{pais?.texto || 'País:'}</strong></p>
              <label htmlFor="institutoId" className="sr-only" aria-hidden={true}>{pais?.label || 'País'}</label>

              <select
                name="paisP"
                className="form-control surface--bg  onbackground-third--color focusable-secondary"
                id="form-select-pais"
                ref={refPais}
              >
                <option value="" hidden>{pais?.placeholder || 'Escoge tu país'}</option>
                {
                  paises.length !== 0 && paises.map(pais => <option key={pais.codigo} value={pais.codigo}>{pais.nombre}</option>)
                }
              </select>
            </div>
          </div>
        </div>


        <div className="col-lg-10 mx-auto">
          <p className="p-5 mm-redimir-terms">{terminos?.ingresar || 'Al ingresar tus datos estás aceptando'} <a className="links-tyc secondary-item focusable-red" href="/terminos-y-condiciones.php">{terminos?.terminos || 'Términos y condiciones'}</a> {terminos?.y || 'y'} <a className="links-tyc secondary-item focusable-red" href="/politica-de-privacidad.php">{terminos?.politica || 'Política de privacidad'}</a></p>
        </div>

        <div className="row mx-0">
          <button className="btn btn-primary col-lg-4 d-block mx-auto focusable-red" type="submit" id="btn-enviar-datos" onClick={enviarFormulario}>
            {
              preloader
                ? <i className="fas fa-spinner fa-pulse"></i>
                : botones?.enviar || 'Enviar'
            }
          </button>
        </div>
      </form>

      <ModalAlertas message={respuestaApi.message} description={respuestaApi.description} id="modal-alerts" />

    </>
  )
}
export default FormLlenarDatos;