/**
 * -TODAS LAS IMAGENES DE BIBLIOTECAS DEBEN IR ACÁ, CUALQUIER REFERENCIA A IMAGENES DE BIBLIOTECAS DEBE SALIR DE ACÁ.
 */
export const ALL_LIBRARYS_IMAGES = [
	{
		location: "",
		src: "/img/bibliotecas/Andes.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/austin.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/Banrep.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/Berkeley.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/BiblioNal.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/Bibliored.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/biblioteca-santa-marta.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/Boston.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/cajacopi.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/casaliteraturaperuana.jpg",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/centro-gomez-morin.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/Charlotte.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/colsubsidio.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/comfacauca.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/comfama.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/Comfamiliar Risaralda.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/comfamiliarHuila.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/Comfenalco Valle.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/ComfenalcoAnt.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/ComfenalcoAtl.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},

	{
		location: "",
		src: "/img/bibliotecas/comfenalcoQuin.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/compensar.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/corvallis-benton-county.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/denver.jpg",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/epm.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/Fresno.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/Fundalectura.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/garfield-county-libraries.jpg",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/Hialeah.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/Javeriana.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/king-county.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/LBPL.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/unab.jpg",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/LosAngeles.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/Miami.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/mineducacion.jpg",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/ministerio-de-educacion.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/north-miami-beach-public-library.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},

	{
		location: "usa",
		src: "/img/bibliotecas/Oakland.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/pflugerville.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/piloto.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/pioneer-library-system.jpg",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/prince.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/Queens.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/RBPC.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/sacramento-public-library.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/sanjose-public-library.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},

	{
		location: "",
		src: "/img/bibliotecas/secretaria-de-educacion.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/sonoma-county-library.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/u-los-andes.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/universidad-la-salle.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/upb.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/uptc.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/w-j-niederkorn-library.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "usa",
		src: "/img/bibliotecas/whittier.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
	{
		location: "",
		src: "/img/bibliotecas/Wigberto.png",
		visibleFor: {
			"que-es": true,
			bibliotecas: true,
		},
	},
];
