import moment from "moment";
import { useCallback } from "react";

export const useScheduleEventLinkGenerator = ({ eventData, lang }) => {
	const title =
		lang === "en"
			? eventData?.titulo?.en || eventData?.titulo?.es || "N/A"
			: eventData?.titulo?.es || "N/A";
	const description =
		lang === "en"
			? eventData?.descripcion?.en || eventData?.descripcion?.es || "N/A"
			: eventData?.descripcion?.es || "N/A";

	const location = "Bogotá";
	const timeZone = "America/Bogota (GMT-05:00)";
	const outlookDates = {
		start: moment(eventData?.fechasZonas?.co).parseZone(timeZone).format("YYYY-MM-DDTHH:mm:ss"),
		end: moment(eventData?.fechasZonas?.co)
			.parseZone(timeZone)
			.add(1, "hours")
			.add(30, "minutes")
			.format("YYYY-MM-DDTHH:mm:ss"),
	};
	
	const gmailDates = {
		start: moment(eventData?.fechasZonas?.co).parseZone(timeZone).format("YYYYMMDDTHHmmss"),
		end: moment(eventData?.fechasZonas?.co)
		.parseZone(timeZone)
		.add(1, "hours")
		.add(30, "minutes")
		.format("YYYYMMDDTHHmmss"),
	};
	
	const offce365Dates = { ...outlookDates };
	const yahooDates = { ...gmailDates };

	const allDay = false;

	const encodedData = {
		allDay: allDay,
		title: encodeURIComponent(title),
		description: encodeURIComponent(description),
		location: encodeURIComponent(location),
		dates: {
			outlook: {
				start: decodeURIComponent(outlookDates?.start),
				end: decodeURIComponent(outlookDates?.end),
			},
			gmail: {
				start: decodeURIComponent(gmailDates?.start),
				end: decodeURIComponent(gmailDates?.end),
			},
			office365: {
				start: decodeURIComponent(offce365Dates?.start),
				end: decodeURIComponent(offce365Dates?.end),
			},
			yahoo: {
				start: decodeURIComponent(yahooDates?.start),
				end: decodeURIComponent(yahooDates?.end),
			},
		},
	};

	const outlookLink = useCallback(() => {
		if (eventData) {
			const link =
				"https://outlook.live.com/calendar/0/action/compose?" +
				"allday=" +
				encodedData?.allDay +
				"&body=" +
				encodedData?.description +
				"&enddt=" +
				encodedData?.dates?.outlook?.end +
				"&location=" +
				encodedData?.location +
				"&path=%2Fcalendar%2Faction%2Fcompose" +
				"&rru=addevent" +
				"&startdt=" +
				encodedData?.dates?.outlook?.start +
				"&subject=" +
				encodedData?.title;

			return link;
		}
		return "outlook.com";
	}, [eventData, lang]);

	const gmailLink = useCallback(() => {
		if (eventData) {
			const link =
				"https://calendar.google.com/calendar/render?" +
				"action=TEMPLATE" +
				"&dates=" +
				encodedData.dates.gmail.start +
				"%2F" +
				encodedData.dates.gmail.end +
				"&details=" +
				encodedData.description +
				"&location=" +
				encodedData.location +
				"&text=" +
				encodedData.title;
			return link;
		}
		return "gmail.com";
	}, [eventData, lang]);

	const office365Link = useCallback(() => {
		if (eventData) {
			const link =
				"https://outlook.office.com/calendar/0/action/compose?" +
				"allday=" +
				encodedData?.allDay +
				"&body=" +
				encodedData?.description +
				"&enddt=" +
				encodedData?.dates?.office365?.end +
				"&location=" +
				encodedData?.location +
				"&path=%2Fcalendar%2Faction%2Fcompose" +
				"&rru=addevent" +
				"&startdt=" +
				encodedData?.dates?.office365?.start +
				"&subject=" +
				encodedData?.title;

			return link;
		}
		return "outlook.office.com";
	}, [eventData, lang]);

	const yahooLink = useCallback(() => {
		if (eventData) {
			const link =
				"https://calendar.yahoo.com/?" +
				"desc=" +
				description +
				"&dur=false" +
				"&et=" +
				encodedData.dates.yahoo.end +
				"&in_loc=" +
				location +
				"&st=" +
				encodedData.dates.yahoo.start +
				"&title=" +
				title +
				"&v=60";

			// const link = `
			// https://calendar.yahoo.com/?
			// desc=Descripci%25C3%25B3n
			// &dur=false
			// &et=20240411T161500Z
			// &in_loc=Bogot%C3%A1
			// &st=20240411T161500Z
			// &title=titulo
			// &v=60
			// `;
			return link;
		}
		return "https://calendar.yahoo.com";
	}, [eventData, lang]);

	return {
		outlookLink,
		gmailLink,
		office365Link,
		yahooLink,
	};
};
