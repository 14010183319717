import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

export const CommunitySelector = ({ data, onSelect, defaultItemId, color }) => {
	const [activeLevel, setActiveLevel] = useState(data?.[0]);

	const handleSelection = (selected) => {
		if (activeLevel?.id !== selected?.id) {
			setActiveLevel(selected);
			onSelect && onSelect(selected);
		}
	};

	useEffect(() => {
		if (Array.isArray(data)) {
			const defaultItem = data?.find(
				(data) => data?.id?.toLowerCase() === defaultItemId?.toLowerCase()
			);
			if (defaultItem) {
				handleSelection(defaultItem);
			}
		}
	}, [defaultItemId]);

	return (
		<div className={`community-selector w-100`}>
			<ul className="d-flex flex-column my-3">
				{data?.map((item, i) => {
					return (
						<li key={i} className="w-100 my-lg-2">
							<button
								key={i}
								className={`focusable-red item ${color?color:'purple'} d-flex justify-content-between align-items-center w-100 p-2 ${
									activeLevel?.id === item?.id ? "active" : ""
								}`}
								onClick={() => handleSelection(item)}
							>
								{item?.name}
								<Icon
									className="d-none d-lg-flex"
									style={{
										visibility:
											activeLevel?.id === item?.id ? "visible" : "hidden",
									}}
									icon="iconamoon:arrow-right-2-bold"
									width={30}
								/>
							</button>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
