import { Icon } from "@iconify/react";
import React, { useEffect, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { Book } from "../components/shared/book";
import { ASSETS_URL } from "../components/Store";
import { store } from "../components/Store";
import { FavoritesStorage } from "../util/favorites-storage";
import { useFavoritesBooks } from "../hooks/useFavoritesBooks";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

/**
 * Componente SeccionLibros
 *
 * Representa una sección de libros.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.books - Array de libros.
 * @param {boolean} props.isLoading - Indicador de carga.
 * @returns {JSX.Element} Elemento de la sección de libros.
 */
export const SeccionLibros = ({ books, isLoading }) => {
    const { textosSubSecciones: textosBusqueda } = useObtenerSubsecciones('busqueda');
    const [librosAMostrar, setLibrosAMostrar] = useState([]);
    const [librosCargados, setLibrosCargados] = useState(false);
    const globalState = useContext(store);
    const isAuthenticatedUser = globalState?.state?.sesionActiva === 'SI';
    const user = globalState.state.username;
    const favorites = useFavoritesBooks({userName: user})
    const history = useHistory();
    const isLogged = globalState.state.sesionActiva !== null && globalState.state.sesionActiva === "SI";

    const cantidadLibrosAMostrar = isLogged ? 21 : 20;

    // Funcion que agrega 10 libros a los libros que se estan mostrando en pantalla
    const cargaProgresiva = async () => {
        let timer = null;
        if (isLogged) {
            return await new Promise((resolve) => {
                if (timer) { clearTimeout(timer) }
                timer = setTimeout(() => {
                    let contador = 0;
                    const nuevosLibros = [];
                    for (let i = librosAMostrar.length; i < books.length; i++) {
                        const libro = books[i];
                        if (contador < cantidadLibrosAMostrar) {
                            nuevosLibros.push(libro)
                        }
                        contador++;
                    }
                    setLibrosAMostrar([...librosAMostrar, ...nuevosLibros])
                    if (librosAMostrar.length === books.length) {
                        setLibrosCargados(true);
                    }
                    resolve(true);
                }, 1000);
            })
        } else {
            setLibrosCargados(true);
        }
    }

    useEffect(() => {
        // Carga los 10 primeros libros 
        const cargaInicial = () => {
            if (Array.isArray(books)) {
                setLibrosAMostrar(books.slice(0, cantidadLibrosAMostrar))
                books.length <= cantidadLibrosAMostrar && setLibrosCargados(true)
            }
        }

        setLibrosCargados(false);
        cargaInicial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [books])

    useEffect(() => {
        if (librosAMostrar.length >= books.length) {
            setLibrosCargados(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [librosAMostrar])

    /**
     * Función que añade o remueve el libro de favoritos en el localstorage
     * @param {string} libro -ide libro
     * @param {boolean} favorito -indica el estado del botón de favoritos
     */
    const agregarRemoverAFavoritos =  (libro, favorito) => {
        const bookID = libro?.idLibro || 'N/A';
        if(favorito === true){
            FavoritesStorage.saveFavorites(user, bookID);
        }else{
            FavoritesStorage.removeFavorites(user, bookID);
        }
    }

    return (
        <>
            <Helmet>
                <title>{textosBusqueda?.metadata?.title || 'MakeMake- búsqueda avanzada'}</title>
                <meta name="description" content={textosBusqueda?.metadata?.description || 'Encuentra tus próximas lecturas y tus libros favoritos más fácil y rápido con los filtros de búsqueda avanzada.'} />
            </Helmet>
            {
                isLoading ?
                    (
                        <div
                            className="secondary-inverse--color container-fluid w-100 d-flex justify-content-center align-items-center col-md-10 col-lg-10 mt-5">
                            <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                        </div>
                    ) : (<>
                        <h3 tabIndex={0} className="focusable-secondary secondary-inverse--color mt-4 mb-3">
                            {`${textosBusqueda?.libros?.resultados?.titulo || 'Resultados'} (${Array.isArray(books) ? books.length : 0})`}
                        </h3>
                        {
                            books.length === 0 ?
                                <>
                                    <div className="onbackground-primary--color col-lg-7 my-auto">
                                        <p>
                                            {textosBusqueda?.filtro?.no_hay_resultados?.lo_sentimos || 'Lo sentimos, no hay resultados para tu búsqueda. Puede que tu error sea por alguna de estas razones:'}
                                        </p>
                                        <ul className="mt-4" style={{ listStyle: "disc" }}>
                                            <li>{textosBusqueda?.filtro?.no_hay_resultados?.razon_uno || 'Escribiste mal alguna letra'}</li>
                                            <li>{textosBusqueda?.filtro?.no_hay_resultados?.razon_dos || 'Tienes filtros activados'}</li>
                                            <li>{textosBusqueda?.filtro?.no_hay_resultados?.razon_tres || 'No tenemos ese libro actualmente :('}</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-5 col-5 text-center mx-auto py-3">
                                        <img
                                            src={`/img/noresults.png`}
                                            className="img-fluid"
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <InfiniteScroll
                                        style={{ overflow: 'visible' }}
                                        className='row row-cols-2  row-cols-md-3 row-cols-lg-4'
                                        dataLength={librosAMostrar.length}
                                        next={cargaProgresiva}
                                        hasMore={librosCargados ? false : true}
                                        scrollThreshold={0.86}
                                        loader={
                                            <div
                                                className="secondary-inverse--color container-fluid w-100 d-flex justify-content-center align-items-center col-md-10 col-lg-10">
                                                <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                                            </div>
                                        }
                                    >
                                        {librosAMostrar.map((item, index) => {
                                            return (
                                                <div
                                                    className={`cover-book col-6 col-md-4 col-lg-3 mb-4`}
                                                    key={`libro${index}`}
                                                >
                                                    <Book
                                                        libro={{
                                                            ...item,
                                                            portada: `${ASSETS_URL}${item?.portada}`,
                                                            title: item.nombreLibro || item?.titulo || item?.title || item?.nombre || 'N/A'
                                                        }}
                                                        alSeleccionar={() => history.push(item?.webUrl)}
                                                        ocultarOpciones={{
                                                            checkbox: true,
                                                            bookType: false,
                                                            botonFavoritos: isAuthenticatedUser ? false : true
                                                        }}
                                                        alAgregarFav={(item) => agregarRemoverAFavoritos(item, true)}
                                                        alRemoverFav={(item) => agregarRemoverAFavoritos(item, false)}
                                                        estadoInicial={{
                                                            esFavorito: favorites.includes(item?.idLibro) 
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}

                                    </InfiniteScroll>
                                    {
                                        isLogged && librosCargados === false &&

                                        <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
                                            <p className='secondary-inverse--color bold'>
                                                {`${textosBusqueda?.libros?.carga?.informacion?.scroll ||
                                                    'Desplázate hacia abajo para cargar más libros.'}`
                                                }

                                            </p>
                                            <Icon icon="mdi:mouse-move-down" className='secondary-inverse--color text-28 mx-2' width={30} />
                                        </div>
                                    }



                                </>

                        }
                    </>)
            }
        </>
    )
}