import React, { useContext } from "react";
import { ModoPWAContext } from "../contexts/ModoPwaContext";
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Componente para mostrar el estado de actualización de la aplicación.
 *
 * @returns {React.Element} Componente que muestra mensajes y iconos según el estado de actualización de la aplicación.
 *
 * @description
 * Este componente utiliza el contexto `ModoPWAContext` para obtener el estado de actualización de la aplicación (`updateStatus`).
 * - Si la actualización está en curso (`updateStatus === "UPDATING"`), muestra un mensaje de actualización en curso y un icono de carga.
 * - Si la actualización ha finalizado (`updateStatus === "FINISHED"`), muestra un mensaje de actualización completada con un icono de verificación.
 *
 * Si el estado de actualización es `NONE`, el componente no renderiza nada.
 */
export const AppUpdating = () => {
	const { textosSubSecciones: textos } = useObtenerSubsecciones("global");
	const pwaContext = useContext(ModoPWAContext);
	const updateStatus = pwaContext.updateStatus;

	const updatingView = () => {
		return (
			<>
				<p className="description">
					{textos?.actualizacion?.en_curso?.descripcion ||
						"Actualizando MakeMake, por favor no cierres esta ventana."}
				</p>
			</>
		);
	};

	const finishedView = () => {
		return (
			<>
				<p className="description">
					{textos?.actualizacion?.finalizada?.descripcion ||
						"¡MakeMake se ha actualizado!"}
				</p>
			</>
		);
	};

	if (updateStatus !== "NONE") {
		return (
			<div className="updating-status visible m-2 px-3 py-2">
				<div className="d-flex align-items-center">
					<Icon
						icon="dashicons:update"
						width={40}
						style={{minWidth:40, minHeight:40, maxWidth:40, maxHeight:40}}
						className={`mr-2 secondary-inverse--color ${
							updateStatus === "UPDATING" ? "icon spinner-rotation" : ""
						}`}
					/>
					{updateStatus === "UPDATING" ? <>{updatingView()}</> : <>{finishedView()}</>}
				</div>
			</div>
		);
	}

	return <></>;
};
