import React from "react";
import { CommunityTitle } from "../../../components/community/community-title";
import { Link } from "react-router-dom";
import { PostCard } from "./components/post-card";
import { useBlogPosts } from "../../hooks/use-blog-post";
import { Icon } from "@iconify/react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { useTranslations } from "../../../hooks/use-translations";
import _ from "lodash";

export const Blog = React.forwardRef((props, ref) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "comunidad_club", lang: lang });
	const posts = useBlogPosts();
	const recentPosts = posts.data;
	const loading = posts.loading;

	return (
		<div className="blog-section w-100 my-5">
			<div className="container mb-5">
				<div ref={ref} style={{scrollMarginTop:60}}>
					<CommunityTitle.H1 title={_.get(texts, 'blog.titulo') || `Blog`} />
				</div>
			</div>
			<div className="container">
				{loading ? (
					<div className="w-100 h-100 d-flex justify-content-center align-items-center secondary-inverse--color">
						<Icon
							icon="icomoon-free:spinner9"
							width={60}
							className={"spinner-rotation"}
						/>
					</div>
				) : (
					<div className="d-flex justify-content-center align-items-center">
						<ul className="row w-100 p-0 m-0 justify-content-between">
							{recentPosts?.map((post, i) => {
								return (
									<li
										className="col-12 col-md-6 col-lg my-3 d-flex justify-content-center align-items-center"
										key={`post-${post?.idBlog}-${i}`}
									>
										<PostCard
											key={`post-${post?.idBlog}-${i}`}
											data={{
												...post,
												cover: post?.portada,
												date: post?.fecha,
												id: post?.idBlog,
											}}
										/>
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>

			<div className="container  my-5 d-flex justify-content-center align-items-center">
				<Link
					className="focusable-red btn btn-primary p-2 px-3"
					to={"/blog"}
				>{_.get(texts, 'blog.boton.explorar_blog') ||  `Explora nuestro Blog`}</Link>
			</div>
		</div>
	);
});
