export const METADATA_TRANSLATE_FICHA = {
  nivel: [
    {
      nombre: 'primeros-lectores',
      pathTranslate: 'info_basica.nivel.primeros'
    },
    {
      nombre: 'lectores-intermedios',
      pathTranslate: 'info_basica.nivel.intermedios'
    },
    {
      nombre: 'lectores-avanzados',
      pathTranslate: 'info_basica.nivel.avanzados'
    }
  ],
  materia: [
    {
      nombre: 'ciencias',
      pathTranslate: 'info_basica.materias.ciencias'
    },
    {
      nombre: 'historia-y-ensayo',
      pathTranslate: 'info_basica.materias.historia_ensayo'
    },
    {
      nombre: 'letras-y-numeros',
      pathTranslate: 'info_basica.materias.letras_numeros'
    },
    {
      nombre: 'artes-y-manualidades',
      pathTranslate: 'info_basica.materias.artes_manualidades'
    },
    {
      nombre: 'cancion-y-poesia',
      pathTranslate: 'info_basica.materias.cancion_poesia'
    },
    {
      nombre: 'narrativa',
      pathTranslate: 'info_basica.materias.narrativa'
    },
  ],
  genero: [
    {
      nombre: 'comic',
      pathTranslate: 'info_basica.generos.comic'
    },
    {
      nombre: 'libro-album',
      pathTranslate: 'info_basica.generos.libro_album'
    },
    {
      nombre: 'cuento-ilustrado',
      pathTranslate: 'info_basica.generos.cuento_ilustrado'
    },
    {
      nombre: 'poesia',
      pathTranslate: 'info_basica.generos.poesia'
    },
    {
      nombre: 'cronica-y-ensayo',
      pathTranslate: 'info_basica.generos.cronica'
    },
    {
      nombre: 'interactivo',
      pathTranslate: 'info_basica.generos.interactivo'
    },
    {
      nombre: 'didactico',
      pathTranslate: 'info_basica.generos.didactico'
    },
    {
      nombre: 'teatro',
      pathTranslate: 'info_basica.generos.teatro'
    },
    {
      nombre: 'cuento',
      pathTranslate: 'info_basica.generos.cuento'
    },
    {
      nombre: 'novela',
      pathTranslate: 'info_basica.generos.novela'
    },
    {
      nombre: 'informativo',
      pathTranslate: 'info_basica.generos.informativo'
    }
  ]
}

