import React, { useState, useRef, useEffect } from "react";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Componente CheckBox
 *
 * Representa un elemento de casilla de verificación.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.itemData - Datos del elemento de casilla de verificación.
 * @param {Function} props.onChange - Función de cambio para manejar el estado de la casilla de verificación.
 * @returns {JSX.Element} Elemento de casilla de verificación.
 */
 const CheckBox = ({ itemData, onChange }) => {
  const {textosSubSecciones:traducciones} = useObtenerSubsecciones('busqueda');
  const {filtro:textosFiltros} = traducciones || {};

   const onKeyEnter = (event) => {
     if ((event.keyCode ? event.keyCode : event.which) == 13) {
       onChange && onChange({ ...itemData, checked: (itemData.checked === true || itemData.checked === false) ? !itemData.checked : false })
     }
   }

  return (<>
    <label className="focusable-by-children-secondary">
      <input
        className="tema-check"
        type="checkbox"
        checked={itemData?.checked}
        defaultValue={itemData?.checked}
        onKeyUp={(e)=>onKeyEnter(e)}
        onChange={(e) => {
          onChange && onChange({ ...itemData, checked: e.target.checked })
        }}
      />
      <span className="checkmark"></span>
      {textosFiltros?.nombre_filtro[itemData?.name?.translationId] || itemData?.name?.defaultValue || 'N/A'}
    </label>
  </>)
}



/**
 * Componente CheckBoxSelector
 *
 * Representa un selector de casillas de verificación.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.itemData - Datos del selector de casillas de verificación.
 * @param {Function} props.onChange - Función de cambio para manejar el estado del selector.
 * @returns {JSX.Element} Elemento del selector de casillas de verificación.
 */

export const CheckBoxSelector = ({ itemData, onChange }) => {
  const {textosSubSecciones:traducciones} = useObtenerSubsecciones('busqueda');
  const {filtro:textosFiltros} = traducciones || {};

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(itemData);

  const heightContainer = useRef();

  const onKeyEnter = (event, item) => {
    if ((event.keyCode ? event.keyCode : event.which) == 13) {
      updateItemCheckState(item,(item.checked === true || item.checked === false) ? !item.checked : false ) 
    }
  }

  const handleVisibility = () => {
    const container = heightContainer.current;
    if (container) {
      isOpen ? container.style.height = 'auto' : container.style.height = '0px'
    }
  }

  const updateItemCheckState = (modifiedItem, checked)=>{
    if(Array.isArray(data?.items)) {
      const items = [...data?.items].map(item=>{
        return modifiedItem?.id === item?.id?{...modifiedItem, checked}:item
      });

      const udpatedData = {
        ...data,
        items:items
      }
      setData(udpatedData)
      onChange&&onChange(udpatedData)
    } 
  }

  const getSelectedLength = ()=>{
    if(Array.isArray(data?.items)) {
      const selected = data?.items.reduce((acc,curr)=>{
        if(curr?.checked){
          acc++;
        }
        return acc;
      },0)
      return selected;
    } else{
      return 0;
    }
  }

  useEffect(()=>{
    setData(itemData);
  },[itemData])

  useEffect(() => {
    handleVisibility();
  }, [isOpen])


  return (
    <>
      <li className="search-group" >
        <button 
          role={"combobox"} 
          aria-controls={''} 
          aria-haspopup="true" 
          aria-expanded={isOpen} 
          aria-label={textosFiltros?.nombre_filtro[data?.name?.translationId] || data?.name?.defaultValue || 'N/A'}  
          className="focusable-secondary dropbtn my-2 rotatebtn" onClick={() => setIsOpen(!isOpen)}>
            {textosFiltros?.nombre_filtro[data?.name?.translationId] || data?.name?.defaultValue || 'N/A'}
            {(() => {
              if (isOpen) {
                return (
                  <i className="fa fa-caret-up" />
                )
              } else {
                return (
                  <i className="fa fa-caret-down" />
                )
              }
            })()}
            {(() => {
              if (getSelectedLength() > 0) {
                return (
                  <span className="numTema">{` (${getSelectedLength()})`}</span>
                )
              }
            })()}
        </button>
        <ul  role={"listbox"} aria-label={textosFiltros?.nombre_filtro[data?.name?.translationId] || data?.name?.defaultValue || 'N/A'} className="p-0 px-3 drop-content" ref={heightContainer}>
          {isOpen && Array.isArray(data?.items) && 
          data?.items.map((item, index) => {
            return (
              <li key={index} className="search-item mb-2">
                <label className="focusable-by-children-secondary">
                  <input
                    role={"menuitemcheckbox"}
                    aria-live="assertive"
                    className="mr-2 tema-check"
                    type="checkbox"
                    checked={item?.checked}
                    aria-checked={item?.checked}
                    onKeyUp={(e)=>onKeyEnter(e,item)}
                    onChange={(e) => {
                      updateItemCheckState(item, e.target.checked)
                    }}
                  />
                  <span className="checkmark"></span>
                  <p style={{ textAlign: "start", width: "100%" }}>{textosFiltros?.categorias[item?.name?.translationId] || item?.name?.defaultValue || 'N/A'}</p>
                </label>
              </li>
            )
          })}
        </ul>
      </li>
    </>
  )
}


export const SearchItems = {
  CheckBox,
  CheckBoxSelector
}