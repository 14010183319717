import React from "react";

/**
 * Componente que representa el botón del Select.
 * @param {Object} props - Propiedades del componente `Button`.
 * @param {ReactNode} props.children - Elementos secundarios renderizados dentro del botón.
 * @returns {JSX.Element} Elemento JSX que representa el componente `Button`.
 */
export const Button = ({ children }) => {
	return <div className="button">{children}</div>;
};

