import React from "react"
import { useQueryParams } from "../../hooks/useQueryParams"
import { Redirect, Route, Switch } from "react-router";
import { Success } from "./success";
import { AppView } from "./app-view";

export const ExternalAuth = ()=>{

    return (
        <Switch>
            <Redirect exact from="/external-auth"  to={'/external-auth/success'}/>
            <Route exact path={"/external-auth/success"} component={Success} />
            {/* <Route exact path={"/external-auth/app"} component={AppView} /> */}
        </Switch>
    )
}