import React, { useContext, useState } from "react";
import { AccesibilidadContext, TemasDisponibles } from "../../contexts/AccesibilidadContext";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { Icon } from "@iconify/react";
import { useNotiBanner } from "../hooks/use-notibanner";
import { ASSETS_URL } from "../../components/Store";
import moment from "moment";
import "moment/locale/es";
import { NotibannerHours } from "./notibanner-hours";

/**
 * Componente `NotiBanner` que muestra un banner de notificaciones.
 *
 * Este componente realiza las siguientes acciones:
 * - Usa el hook `useNotiBanner` para obtener los datos del banner.
 * - Gestiona la visibilidad del banner a través del estado `visible`.
 * - Determina el idioma predeterminado basado en el país usando el hook `useDefaultLangByCountry`.
 * - Obtiene el contexto de accesibilidad desde `AccesibilidadContext`.
 * - Configura los colores del banner y el botón basado en los datos obtenidos.
 * - Determina el título, subtítulo y texto del botón basado en el idioma actual.
 * - Verifica si el banner debe ser visible según la fecha actual y las fechas de visibilidad del banner.
 * - Proporciona una función para cerrar el banner.
 * - Usa `useEffect` para realizar una acción cuando los datos del banner están cargados.
 *
 * @returns {JSX.Element} El elemento JSX que representa el banner de notificaciones.
 */

export const NotiBanner = () => {
	const notibanner = useNotiBanner();
	const [visible, setVisible] = useState(true);
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const accesibility = useContext(AccesibilidadContext);
	const primaryColor = notibanner?.data?.colorFondo?.colorPrimario;
	const secondaryColor = notibanner?.data?.colorFondo?.colorSecundario;
	const isClassicTheme = accesibility.temaActual === TemasDisponibles.clasico.id;
	const buttonColor = {
		background: isClassicTheme? notibanner?.data?.colorBoton?.fondo: 'var(--secondary-inverse)',
		foreground: isClassicTheme? notibanner?.data?.colorBoton?.texto: 'var(--onsecondary-inverse)',
	};
	const title =
		lang === "en"
			? notibanner?.data?.metadatos?.titulo?.en || notibanner?.data?.metadatos?.titulo?.es
			: notibanner?.data?.metadatos?.titulo?.es;
	const subtitle =
		lang === "en"
			? notibanner?.data?.metadatos?.subtitulo?.en ||
			  notibanner?.data?.metadatos?.subtitulo?.es
			: notibanner?.data?.metadatos?.subtitulo?.es;
	const buttonText =
		lang === "en"
			? notibanner?.data?.metadatos?.textoBoton?.en ||
			  notibanner?.data?.metadatos?.textoBoton?.es
			: notibanner?.data?.metadatos?.textoBoton?.es;
	const link = notibanner?.data?.metadatos?.link;
	moment.locale(lang);
	const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss z";
	const rawDate = moment(notibanner?.data?.metadatos?.fecha, DATE_FORMAT); // Fecha en hora colombiana
	const enDayName =   moment(rawDate).format("dddd").charAt(0).toUpperCase() + moment(rawDate).format("dddd").slice(1).toLowerCase();;
	const enDate = moment(rawDate).format(`[${enDayName}], MMMM D[,] YYYY`);
	const esDayName =  moment(rawDate).format("dddd").charAt(0).toUpperCase() + moment(rawDate).format("dddd").slice(1).toLowerCase();
	const esDate = moment(rawDate).format(`[${esDayName}], D [de] MMMM [de] YYYY`);
	const date = lang === "en" ? enDate : esDate;

	const imageUrl = `${ASSETS_URL}${notibanner?.data?.urlImagen}`;

	const background = isClassicTheme
		? `linear-gradient(90deg, ${primaryColor} 10% , ${secondaryColor} 60%)`
		: "var(--background-color)";
	const foreground = isClassicTheme ? notibanner?.data?.colorFondo?.colorTexto : "var(--onbackground-primary)";
	const hasDate = moment(rawDate, "YYYY-MM-DD", false).isValid();
	const startDate = moment(notibanner?.data?.fechaVisibilidad?.inicio);
	const endDate = moment(notibanner?.data?.fechaVisibilidad?.fin);
	const today = moment(moment().format("YYYY-MM-DD"));
	const shouldBeVisible = today.isBetween(
		startDate,
		endDate,
		undefined,
		"[]"
	);

	const hasTitle = notibanner?.data?.metadatos?.titulo?.en && notibanner?.data?.metadatos?.titulo?.es;
	const [hour, setHour] = useState('');

	const close = () => {
		setVisible(false);
	};

	const closeButtonComponent = () => {
		return (
			<button className="focusable-red" style={{ color: foreground }} onClick={() => close()} aria-label="Cerrar/close">
				<Icon icon="mdi:close-thick" width={30} />
			</button>
		);
	};

	const linkButton = () => {
		return (
			<>
				{link ? (
					<a
						className="main-button px-3 py-2 my-3 focusable-red"
						href={link || "/"}
						target="_blank"
						rel="noopener noreferrer"
						style={{
							background: buttonColor?.background,
							color: buttonColor?.foreground,
						}}
					>
						{buttonText || "N/A"}
					</a>
				) : null}
			</>
		);
	};

	return (
		<>
			{notibanner.loaded && hasTitle && shouldBeVisible ? (
				<div
					className={`notibanner w-100 ${visible ? "visible" : "hidden"}`}
					style={{ background: background }}
				>
					<div
						className="mx-auto row p-0 m-0 w-100 justify-content-between"
						style={{ minHeight: 150, maxWidth: 1300 }}
					>
						<div className="col-12 d-flex d-lg-none w-100 justify-content-end px-2 pt-2 pb-0">
							{closeButtonComponent()}
						</div>
						<div className="section 1 col-12 col-md-6 d-flex align-items-center">
							<div className="d-flex">
								<img
									className="d-none d-lg-flex img img-fluid mt-3 mr-3"
									src={imageUrl}
									alt="img"
									loading="lazy"
								/>
								<div
									className="d-flex flex-column justify-content-center"
									style={{ color: foreground }}
								>
									<p className="description text-18" style={{ fontWeight: 600 }}>
										{subtitle}
									</p>
									<p
										className="title secondary-font text-24"
										style={{ fontWeight: 600 }}
									>
										{title}
									</p>
									<div className="d-flex d-lg-none my-3">
										{hasDate !== true && linkButton()}
									</div>
								</div>
							</div>
						</div>

						<div className="section 2 col-12 col-md-6 d-flex flex-column align-items-start flex-lg-row align-items-lg-center  justify-content-around">
							{hasDate ? (
								<>
									<div
										className="d-flex flex-column justify-content-center my-3"
										style={{ color: foreground }}
									>
										<p className="text-18 font-weight-bold secondary-font">
											{date} - {hour}
										</p>
										<NotibannerHours
											date={notibanner?.data?.metadatos?.fecha}
											onSelectHour={(hour) => setHour(hour)}
											color={foreground}
											activeBackground={buttonColor.background}
											activeColor={buttonColor.foreground}
										/>
									</div>
									{linkButton()}
								</>
							) : null}
							<div className="d-none d-lg-flex items-center">
								{hasDate !== true && (
									<div className="mx-lg-5 px-lg-5 my-3">{linkButton()}</div>
								)}
								{closeButtonComponent()}
							</div>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
