import React from "react";
import { ASSETS_URL } from "../../Store";
import { Img } from "../../shared/img";

export const ClientFooter = ({ clientData }) => {
    return (
      <div className="client-footer" style={{
        backgroundColor: clientData?.colorHeader,
        color: clientData?.colorLetra
      }}>
        <div className="row justify-content-center py-4 m-0">
          <div className="col-lg-8">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <Img alt="Logo institución" className="mx-auto d-block brand img-fluid" id="logo-footer" src={`${ASSETS_URL}${clientData?.logo}`} />
              </div>
              <div className="col-12 col-lg-6 p-3 p-lg-3 contact-data text-center text-lg-left">
                {clientData?.telefono !== 'NULL' && <p id="instituto-telefono">{clientData?.telefono}</p>}
                {clientData?.direccion !== 'NULL' && <p id="instituto-direccion">{clientData?.direccion}</p>}
                {clientData?.correo !== 'NULL' && <p id="instituto-correo">{clientData?.correo}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>)
  }