import React from "react";
import { SectionTitle } from "./section-title";

export const FAQSection = () => {
	return (
		<section className="p-3 faq">
			<SectionTitle title="Preguntas frecuentes" />
			<div className="p-3  justify-content-center align-items-center">
				<section className="my-3">
					<h2>1. ¿Cuál es la diferencia entre los dos accesos que me enviaron?</h2>
					<p>
						Se enviaron dos accesos por región: uno para bibliotecarios, promotores de
						lectura y personal asociado a la biblioteca, y otro para los usuarios de la
						biblioteca (profesores, estudiantes, padres y madres de familia, etc.). Si
						aún tienes dudas sobre las claves de acceso de tu región, por favor escribe
						a <a href="mailto:contacto@makemake.com.co">contacto@makemake.com.co </a>
						aclarando tu nombre, el nombre de la biblioteca a la que perteneces y el
						municipio.
					</p>
				</section>

				<section className="my-3">
					<h2>
						2. ¿Puedo compartir la clave de acceso de los usuarios? ¿Cómo puedo hacerlo?
					</h2>
					<p>
						Sí, puedes compartir la clave de acceso de los usuarios. Puedes hacerlo a
						través de la cartelera de la biblioteca, en los chats de WhatsApp con los
						usuarios o directamente a quienes lo soliciten.
					</p>
					<p>
						Sin embargo, las claves no deben compartirse en redes sociales ni en páginas
						web, ya que esta información es exclusiva para los usuarios de la
						biblioteca. Esto nos permite medir mejor el impacto directo que tienen las
						bibliotecas en sus comunidades al promover este servicio de lectura digital.
					</p>
				</section>

				<section className="my-3">
					<h2>3. ¿Cómo sé a qué región pertenezco?</h2>
					<p>
						En la sección <strong>"Bibliotecas participantes"</strong> de este
						micrositio (ver el mapa arriba), encontrarás el nombre de las bibliotecas
						del piloto, organizadas por región.
					</p>
				</section>

				<section className="my-3">
					<h2>
						4. Aún no formo parte del grupo de WhatsApp de mi región, ¿qué debo hacer?
					</h2>
					<p>
						Si aún no estás en ningún grupo de WhatsApp, puedes escribirnos a
						<a href="mailto:contacto@makemake.com.co"> contacto@makemake.com.co </a>
						con la siguiente información: nombre completo, nombre de la biblioteca a la
						que perteneces, municipio y número de celular.
					</p>
					<p>
						El grupo de WhatsApp es el canal principal por donde enviaremos
						comunicaciones del piloto. También enviaremos comunicaciones a tu correo
						electrónico registrado.
					</p>
				</section>

				<section className="my-3">
					<h2>5. ¿Es necesario descargar la app para usar MakeMake?</h2>
					<p>
						No, no es necesario descargar la app para utilizar la Biblioteca Digital
						MakeMake. Puedes usar MakeMake directamente en el navegador de tu teléfono,
						tableta o computador. Te recomendamos que uses el navegador
						<a
							href="https://www.google.com/chrome"
							target="_blank"
							rel="noreferrer noopener"
						>
							{" "}
							Google Chrome
						</a>
						.
					</p>
				</section>

				<section className="my-3">
					<h2>6. ¿Cómo descargo las apps de MakeMake y qué ventajas tiene hacerlo?</h2>
					<p>
						MakeMake tiene apps disponibles para teléfonos y tabletas Android y Apple, y
						también para computadores. En este sitio puedes encontrar las instrucciones
						para descargar las apps:{" "}
						<a href="https://makemake.app" target="_blank" rel="noreferrer noopener">
							https://makemake.app
						</a>
					</p>
					<p>
						La app de MakeMake es útil, ya que te ayuda a tener acceso directo, recordar
						tus datos, lo que facilita ingresar y leer más rápidamente. Además, te
						permite descargar libros y disfrutar de la lectura sin necesidad de conexión
						a internet.
					</p>
				</section>

				<section className="my-3">
					<h2>7. ¿Cómo descargo libros?</h2>
					<ol className="ml-5">
						<li>
							Descarga la app para tu teléfono, tableta o computador. Puedes seguir
							los pasos usando las instrucciones{" "}
							<a
								href="https://makemake.app/"
								target="_blank"
								rel="noreferrer noopener"
							>
								aquí
							</a>
							.
						</li>
						<li>
							Inicia sesión en la app con tus credenciales de bibliotecario. Si estás
							asistiendo a un usuario, proporciónale sus claves de acceso (ver
							pregunta 1).
						</li>
						<li>Accede a la ficha del libro haciendo clic en su portada.</li>
						<li>
							Haz clic en el botón "Descargar" en la parte inferior derecha de la
							ficha.
						</li>
						<li>Una vez descargado, el botón cambiará a "Descargado".</li>
						<li>
							Puedes consultar el libro descargado en la sección "Descargas" del botón
							"Mi Cuenta".
						</li>
						<li>
							Si quieres más información sobre la descarga, consulta estos videos:
						</li>
						<ul>
							<li>
								<a
									href="https://vimeo.com/1023316893/5b97f98077"
									target="_blank"
									rel="noopener noreferrer"
								>
									Descarga de libros en tu computador
								</a>
							</li>
							<li>
								<a
									href="https://vimeo.com/995529495/7a1bf61a8b"
									target="_blank"
									rel="noopener noreferrer"
								>
									Descarga de libros en tu celular o tableta
								</a>
							</li>
						</ul>
					</ol>
					<p>
						<strong>Nota importante:</strong> Podrás descargar un máximo de dos libros,
						los cuales estarán disponibles en tu dispositivo por 15 días. Puedes borrar
						los libros y descargar otros si así lo deseas.
					</p>
				</section>

				<section className="my-3">
					<h2>8. ¿Cómo comunico a mi comunidad sobre este servicio?</h2>
					<p>
						En este micrositio encontrarás materiales para contarle a tu comunidad que
						existe MakeMake y que pueden hacer uso de la plataforma. Si quieres más
						piezas gráficas para comunicar MakeMake, puedes usar el
						<a
							href="https://makemake.com.co/centro-de-recursos"
							target="_blank"
							rel="noreferrer noopener"
						>
							{" "}
							Centro de Recursos
						</a>
						.
					</p>
					<p>
						<strong>Nota importante:</strong> Para descargar los materiales del Centro
						de Recursos debes previamente iniciar sesión en MakeMake con tu usuario y
						contraseña.
					</p>
				</section>

				<section className="my-3">
					<h2>9. ¿Puedo compartir el Centro de recursos con mis usuarios?</h2>
					<p>
						Sí, puedes compartir el Centro de recursos con los usuarios para
						comunicarles sobre el nuevo servicio de lectura digital en MakeMake.
					</p>
				</section>
			</div>
		</section>
	);
};
