import React, { useEffect } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router"
import { InstitutionalSearch } from "./components/institutional-search"
import { InstitutionNotFound } from "./components/institution-not-found"
import { ContactFormInstitution } from "./components/contact-form"
import { ContactInfoInstitution } from "./components/contact-info"
import { InstitutionalAuth } from "./components/institutional-auth"
import { store } from "../components/Store"
import { useContext } from "react"



export const InstitutionalAuthRouter = () => {

    const location = useLocation();
    const storeContext = useContext(store);
    const isAuth = storeContext?.state?.sesionActiva?.toLowerCase() === 'si'?true:false;

    useEffect(()=>{
        if(location.pathname.includes('/institutional-auth')){
            window.scrollTo({
                left:0,
                top:0
            })
        } 
    },[location])

    // Escuchar si en otra pestaña el usuario está autenticado, si es asi se cierra la pestaña actual o se recarga dirigiendo al home.
    useEffect(() => {
        if (window.BroadcastChannel) {
            const bc = new BroadcastChannel('isAuth');
            bc.addEventListener('message', (e) => {
                if (e?.data?.eventName === 'isAuth' && e?.origin === window.location.origin) {
                    window.location.href = '/home';
                    try {
                        window.close();
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
            })
        }
    }, [])


    return (<Switch>
        {
            isAuth&&
        <Redirect to={"/home"} />
        }
        <Redirect exact from="/institutional-auth" to={"/institutional-auth/search"} />
        <Route exact path={"/institutional-auth/search"} component={InstitutionalSearch} />
        <Route exact path={"/institutional-auth/search/auth"} component={InstitutionalAuth} />
        <Route exact path={"/institutional-auth/institution-not-found"} component={InstitutionNotFound} />
        <Route exact path={"/institutional-auth/contact/form"} component={ContactFormInstitution} />
        <Route exact path={"/institutional-auth/contact/info"} component={ContactInfoInstitution} />
    </Switch>)
}