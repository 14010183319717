import React from "react";
import { BGShape } from "./bg-shape";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import _ from "lodash";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

export const ContentHeader = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "comunidad_club", lang: lang });
	return (
		<div className="content-header titlePage club-bg">
			<BGShape />
			<div className="container h-100">
				<div className="row p-0 m-0 justify-content-center align-items-center h-100">
					<h1 className="col-12 title">
						{_.get(texts, "header.titulo") || "Club MakeMake"}
					</h1>
					{/* <p className="col-12 col-md-6 description my-3 text-18">
						{_.get(texts, "header.description") ||
							"MakeMake te acompaña con eventos, recomendaciones y marketing digital para hacer crecer tu comunidad lectora."}
					</p> */}
				</div>
			</div>
		</div>
	);
};
