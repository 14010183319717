import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useObtenerTraducciones from "../../js/translations/useObtenerTraducciones";
import getUrlParam from "../../js/utilities/getUrl";

/**
 * Componente que muestra el estado de una transacción de epayco, indica si esta fue aceptada, rechazada o esta pendiente.
 * La información que muestre depende de los query params que se envien en la url.
 */
export const EstadoTransaccion = () => {

  const { isReady, textosSeccion: transaccion } = useObtenerTraducciones('resultadoTransaccion');
  const [dataTransaccion, setDataTransaccion] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [estadoTransaccion, setEstadoTransaccion] = useState('');
  const history = useHistory();

  const clases = {
    'Aceptada': 'acepted',
    'Rechazada': 'transaccion-rejected',
    'Pendiente': 'transaccion-pending',
  }

  useEffect(() => {
    const ref_payco = getUrlParam('ref_payco');


    if (ref_payco) {
      var misCabeceras = new Headers();
      var miInit = {
        method: 'GET',
        headers: misCabeceras,
        mode: 'cors',
        cache: 'default'
      };
      fetch(`https://secure.epayco.co/validation/v1/reference/${ref_payco}`, miInit).then(function (response) {
        return response.json();
      })
        .then(function (response) {
          console.log('epayco response', response);
          if (response?.data?.status === "error" && response?.status === false) {
            history.push('/tienda');
          }
          let data = response.data;
          // window.ga('ec:setAction', 'purchase', {
          //   'id': data.x_ref_payco,
          //   'affiliation': 'epayco',
          //   'revenue': data.x_amount,
          //   'value': data.x_amount,
          //   'description': data.x_description	    // User added a coupon at checkout.
          // });
          setEstadoTransaccion(data.x_respuesta);
          setDataTransaccion(data);
          setIsLoading(false);
        });
    }
  }, [])



  return (
    <>
      <div className="surface--bg onbackground-primary--color main-container mm-redimir" id="demo-transaccion">
        <div className="container main-content">
          {
            isReady &&
            <div className="p-lg-3 pl-lg-5">

              <div className="col-lg-10 mx-auto pt-3 pb-4">
                <div id="contact-data">
                  <h2 className="mm-redimir_title mb-5 text-center">{transaccion?.titulo || 'Resultado de transacción'}</h2>
                  {
                    isLoading === false ?
                      <>
                        <div className="transaccion-container" id="transaccion-acepted">
                          <div className={`mb-4 transaccion-status ${clases[estadoTransaccion]} p-4 text-center`}>
                            {
                              estadoTransaccion === 'Aceptada' && <p dangerouslySetInnerHTML={{ __html: transaccion?.transaccion_aceptada || 'Tu transacción fue éxitosa.<br /> En unas horas te llegará tu usuario y contraseña al correo que registraste al momento de la compra en nuestra plataforma.' }}></p>
                            }
                            {
                              estadoTransaccion === 'Rechazada' && <p dangerouslySetInnerHTML={{ __html: transaccion?.transaccion_rechazada || 'Tu transacción fue rechazada.<br /> Vuelve a realizar el proceso en nuestra plataforma.' }}></p>
                            }
                            {
                              estadoTransaccion === 'Pendiente' && <p dangerouslySetInnerHTML={{ __html: transaccion?.transaccion_pendiente || 'Tu transacción está pendiente de aprobación por la entidad financiera. Tan pronto cómo la apruebe el banco te enviaremos tu usuario y contraseña al correo que registraste al momento de la compra en nuestra plataforma.' }}></p>
                            }

                          </div>
                        </div>

                        <h3 className="text-center mb-3"><strong>{transaccion?.informacion || 'Información de la transacción'}</strong></h3>
                        <div id="informacion-orden">
                          <div className="mx-auto px-0 col-lg-10 table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                </tr>
                              </thead>
                              <tbody id="response-table" className="w-100">
                                <tr>
                                  <th>{transaccion?.referencia || 'Referencia'}</th>
                                  <td>{dataTransaccion.x_ref_payco}</td>
                                </tr>
                                <tr>
                                  <th>{transaccion?.producto || 'Producto'}</th>
                                  <td>{decodeURIComponent(escape(dataTransaccion.x_description||''))}</td>
                                </tr>
                                <tr>
                                  <th>{transaccion?.fecha || 'Fecha'}</th>
                                  <td>{dataTransaccion.x_fecha_transaccion}</td>
                                </tr>
                                <tr>
                                  <th>{transaccion?.respuesta || 'Respuesta'}</th>
                                  <td>{dataTransaccion.x_respuesta}</td>
                                </tr>
                               <tr>
                                  <th>{transaccion?.motivo || 'Motivo'}</th>
                                  <td>{dataTransaccion.x_response_reason_text}</td>
                                </tr>
                                <tr>
                                  <th>{transaccion?.banco || 'Banco'}</th>
                                  <td>{dataTransaccion.x_bank_name}</td>
                                </tr>
                                <tr>
                                  <th>{transaccion?.recibo || 'Recibo'}</th>
                                  <td>{dataTransaccion.x_id_factura}</td>
                                </tr>
                                <tr>
                                  <th>{transaccion?.total || 'Total'}</th>
                                  <td>{dataTransaccion.x_currency_code} {dataTransaccion.x_amount}</td>
                                </tr> 
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="py-4 text-center">
                          <p>{transaccion?.puedes || 'Puedes contactarte con nosotros si tienes alguna duda'}</p>
                          <p><span className="bold">{transaccion?.correo || 'Correo:'}</span> contacto@makemake.com.co</p>
                          <p><span className="bold">{transaccion?.whatsapp || 'Whatsapp:'}</span> 57 350 236 7037</p>
                        </div>
                      </>
                    :
                      <div className="w-100 d-flex justify-content-center align-items-center">
                      <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation secondary-inverse--color'} />
                      </div>
                  } 
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </>
  )
}
