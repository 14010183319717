import React, { useEffect, useRef, useState } from "react";

const H1 = ({ title, titleClassName }) => {
	return (
		<CommunityTitleBase>
			<h1 className={`title text-center ${titleClassName}`}>{title}</h1>
		</CommunityTitleBase>
	);
};
const H2 = ({ title, titleClassName }) => {
	return (
		<CommunityTitleBase>
			<h2 className={`title text-center ${titleClassName}`}>{title}</h2>
		</CommunityTitleBase>
	);
};
const H3 = ({ title, titleClassName }) => {
	return (
		<CommunityTitleBase>
			<h3 className={`title text-center ${titleClassName}`}>{title}</h3>
		</CommunityTitleBase>
	);
};

const H4 = ({ title, titleClassName }) => {
	return (
		<CommunityTitleBase>
			<h4 className={`title text-center ${titleClassName}`}>{title}</h4>
		</CommunityTitleBase>
	);
};

const CommunityTitleBase = ({ children }) => {
	const [showHorizontalDivider, setShowHorizontalDivider] = useState(true);
	const horizontalDividerRef = useRef();

	const handleBorderVisbility = () => {
		if (horizontalDividerRef.current && horizontalDividerRef?.current?.clientWidth <= 50) {
			setShowHorizontalDivider(false);
		} else {
			setShowHorizontalDivider(true);
		}
	};

	useEffect(() => {
		handleBorderVisbility();
		window.addEventListener("resize", handleBorderVisbility);
		return () => {
			window.removeEventListener("resize", handleBorderVisbility);
		};
	}, [horizontalDividerRef.current]);

	return (
		<div className="onbackground-primary--color  w-100 community-title d-flex flex-nowrap align-items-center">
			{children}
			<div
				ref={horizontalDividerRef}
				className="d-flex mx-2 flex-grow-1 line secondary-inverse--bg"
				style={{ opacity: showHorizontalDivider ? "1" : "0" }}
			></div>
		</div>
	);
};

export const CommunityTitle = {
	H1,
	H2,
	H3,
	H4,
};
