import { useContext } from "react";
import { store } from "../../components/Store";

/**
 * Este hook permite mostrar u ocultar el dropdown en responsive del Header publico,
 * así como tambíen devuelve el estado actual desde el global state.
 * @returns{{
    setVisible:(visible:boolean),
    state:object
 * }}
 */
export const usePublicHeaderDropdown = () => {
    const storeContext = useContext(store);
    const globalState = storeContext?.state;

    const setVisible = (visible) => {
        storeContext.dispatch({
            type: "CHANGE",
            path: "publicHeaderDropdown.visible",
            value: visible ? true : false,
        });
    }

    return {
        setVisible,
        state: globalState?.publicHeaderDropdown
    }
}