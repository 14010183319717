import React from "react";
import { Link } from "react-router-dom";

/**
 * Retorna la franja de " ¿quieres leer ahora?"
 * @param {object} text - contiene los textos de interfaz
 * @returns {JSX.Element} NowRead
 */
export const NowRead = ({text}) => {
    return (
        <div className="p-3 py-lg-5 onprimary-inverse--bg onred--color">
            <div className="container">
                <div className="col-12 col-lg-8 mx-auto">
                    <h2 className="text-center secondary-font w-100 text-35" >
                        {text?.leer || "¿Quieres leer ahora?"}
                    </h2>

                    <Link
                        to="/que-es?section=MainPlans"
                        className="btn btn-primary p-2 px-3 d-block mx-auto my-3 focusable-primary"
                        style={{ maxWidth: 500 }}
                    >
                        {text?.btn_leer || 'Conoce nuestros planes de suscripción'}
                    </Link>
                </div>
            </div>
        </div>
    )
}