import React from 'react';
import { FocusScope } from 'react-aria';
import { Link } from 'react-router-dom';
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones.js";
import PropTypes from "prop-types";

/**
 * Este componente recibe  una función para actualizar el estado de alerta limite que muestra u oculta la ventana modal. Su principal función es renderizar la ventana modal de Alerta, esta se muestra si no se puede descargar el libro porque se llego al limite de descargas.  
 * @returns AlertaLimites
 */
const AlertaLimites = ({ setAlertaLimite }) => {

  const { isReady, textosSubSecciones: textoFicha } = useObtenerSubsecciones('ficha_libro');

  return (
    <>
      {isReady &&
        <FocusScope contain autoFocus restoreFocus>
          <div 
            role={'dialog'}
            
            className="sin-conexion-container alerta-limite-container" 
            onClick={() => setAlertaLimite(false)}
          >
            <div className="d-flex justify-content-center align-items-center">
              <div className="p-4 sinconexion-message limite-descarga position-relative">
                <button
                  className="btn btn-cerrar-alerta focusable-red">Cerrar ventana de alerta</button>
                <img className="col-11" src="/img/limite_descarga.png" alt="imagen limite de libros" />
                <p>{textoFicha?.modal?.descripcion || 'Debes eliminar una de tus descargas para hacer espacio a un nuevo libro.'} </p>
                <div>
                  <Link
                    to="/descargados"
                    className=" mx-auto focusable-red btn btn-primary px-4  btn-primary--lower d-block mx-auto mt-3"
                  >
                    {textoFicha?.modal?.boton || 'Gestionar mis descargas'}
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </FocusScope>
      }
    </>

  )
}

export default AlertaLimites;

AlertaLimites.propTypes = {
  /**
   * Función que actualiza el estado de alertaLimite
   */
  setAlertaLimite: PropTypes.func.isRequired
}