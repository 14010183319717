export const LIBRARIES =[
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "ARCABUO",
        "libraryName": "Biblioteca Pública Municipal Liberar Arcabuco"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "BOYACA- BOYACA",
        "libraryName": "Biblioteca Pública Municipal San Martin De Boyacá"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "Caldas",
        "libraryName": "Biblioteca Pública Municipal Jairo Elberto González Castillo "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "Cuítiva",
        "libraryName": "Biblioteca Pública Municipal Bochica "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "GARAGOA",
        "libraryName": "Biblioteca Pública Odilia Camacho Gómez"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "Guateque",
        "libraryName": "Biblioteca Pública Manuel María Franco"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "Paz de Rio ",
        "libraryName": "Biblioteca Pública Municipal De Paz De Rio "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "Soracá",
        "libraryName": "Biblioteca Pública Municipal San Francisco De Soracá"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "TOGUI ",
        "libraryName": "Biblioteca Pública Municipal Tiberio Vanegas Pinzón "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "Tota",
        "libraryName": "Biblioteca Pública Municipal De Tota "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Boyacá ",
        "Municipio": "Tutaza ",
        "libraryName": "Biblioteca Pública Municipal de Tutazá "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Cundinamarca ",
        "Municipio": "Agua de Dios",
        "libraryName": "Biblioteca Municipal José Olimpo Álvarez Sánchez"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Cundinamarca ",
        "Municipio": "ANOLAIMA",
        "libraryName": "Biblioteca Pública de Anolaima"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Cundinamarca ",
        "Municipio": "Facatativá ",
        "libraryName": "Biblioteca Pública Municipal de Facatativá"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Cundinamarca ",
        "Municipio": "LA PALMA",
        "libraryName": "Biblioteca Pública Municipal del Municipio de La Palma"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Cundinamarca ",
        "Municipio": "Nemocón",
        "libraryName": "Biblioteca Pública Municipal Ricardo Moros Urbina"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Cundinamarca ",
        "Municipio": "Puerto Salgar",
        "libraryName": "Biblioteca Pública Municipal \"Henry Moreno Guevara”"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Cundinamarca ",
        "Municipio": "San Antonio del tequendama",
        "libraryName": "Biblioteca Pública Del Corregimiento De Santandercito"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Cundinamarca ",
        "Municipio": "Sibaté ",
        "libraryName": "Biblioteca Pública Municipal La Quinta De Samper "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Cundinamarca ",
        "Municipio": "SIMIJACA",
        "libraryName": "Biblioteca Pública Municipal De Simijaca "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Huila",
        "Municipio": "ALGECIRAS",
        "libraryName": "Biblioteca Pública del Municipio de Algeciras Jorge Humberto Trujillo"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Huila",
        "Municipio": "BARAYA",
        "libraryName": "Biblioteca Pública Municipal Antonio Palomar Avilés"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Huila",
        "Municipio": "Garzón",
        "libraryName": "Biblioteca Pública Municipal De Garzón Luis Calixto Leiva Charry"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Norte de Santander",
        "Municipio": "ARBOLEDAS",
        "libraryName": "Biblioteca Pública María Ofelia Villamizar Buitrago"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Norte de Santander",
        "Municipio": "Convención",
        "libraryName": "Biblioteca Pública Municipal Sesquicentenario "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Norte de Santander",
        "Municipio": "El Carmen",
        "libraryName": "Biblioteca Pública Municipal Manuel Guillermo Giraldo"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Norte de Santander",
        "Municipio": "El Tarra",
        "libraryName": "Biblioteca Pública Municipal Barira"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Norte de Santander",
        "Municipio": "La Playa de Belén",
        "libraryName": "Biblioteca Pública Municipal Francisco Arévalo Claro"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Norte de Santander",
        "Municipio": "LOS PATIOS",
        "libraryName": "Biblioteca Pública Municipal José Ignacio Rangel Rojas"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Norte de Santander",
        "Municipio": "Tibu",
        "libraryName": "Biblioteca Pública Municipal Monseñor Juan José Diaz Plata "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Santander ",
        "Municipio": "Bucaramanga",
        "libraryName": "Biblioteca Urbanización Campo Madrid"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Santander ",
        "Municipio": "Coromoro ",
        "libraryName": "Biblioteca Pública Municipal Antonia Santos Plata "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Santander ",
        "Municipio": "FLORIDABLANCA",
        "libraryName": "Biblioteca Pública José Antonio Galán"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Santander ",
        "Municipio": "GALAN",
        "libraryName": "Biblioteca Pública Municipal De Galán "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Santander ",
        "Municipio": "Guaca",
        "libraryName": "Biblioteca Pública Municipal Fuente del Saber"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Santander ",
        "Municipio": "Mogotes",
        "libraryName": "Biblioteca Pública Epifanio Blanco Peña"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Tolima ",
        "Municipio": "CHAPARRAL ",
        "libraryName": "Biblioteca Pública Darío Ortiz Vidales "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Tolima ",
        "Municipio": "Ibagué ",
        "libraryName": "Biblioteca Pública Soledad Rengifo "
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Tolima ",
        "Municipio": "Ibagué ",
        "libraryName": "Biblioteca Casa de la Cultura El Salado"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Tolima ",
        "Municipio": "Lérida",
        "libraryName": "Biblioteca Popular Lisímaco Suárez Reyes"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Tolima ",
        "Municipio": "SALDAÑA",
        "libraryName": "Biblioteca Municipal del municipio de Saldaña"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Tolima ",
        "Municipio": "san antonio",
        "libraryName": "Biblioteca Pública Municipal Germán Guzmán Campos"
    },
    {
        "Región": "Andina Centroriental",
        "regionId": "region1",
        "Departamento": "Tolima ",
        "Municipio": "Villarrica",
        "libraryName": "Biblioteca Pública Municipal De Villarrica Tolima"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "Andes",
        "libraryName": "Biblioteca Municipal Gonzalo Arango Arias "
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "Barbosa",
        "libraryName": "Biblioteca Pública Isolda Echavarría"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "Caldas",
        "libraryName": "Biblioteca Pública Municipal Francisco José De Caldas"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "CAROLINA DEL PRÍNCIPE",
        "libraryName": "Biblioteca Pública Municipal Francisco Duque Betancur"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "DABEIBA",
        "libraryName": "Biblioteca Víctor Cárdenas Jaramillo"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "DONMATÍAS",
        "libraryName": "Biblioteca Pública Luis López De Mesa"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "El Carmen de Viboral ",
        "libraryName": "Biblioteca Pública Municipal Jesús Antonio Arango Gallo "
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "Ituango ",
        "libraryName": "Biblioteca Pública Miguel Ángel Osorio"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "MACEO",
        "libraryName": "Biblioteca Publica Héctor Gómez Gallego"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "Marinilla",
        "libraryName": "Biblioteca Pública Berenice Gómez Acevedo"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "Nechi",
        "libraryName": "Biblioteca Pública Municipal Eulises Rivera "
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "PEQUE",
        "libraryName": "Biblioteca Publica Jesús Antonio Valle"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "Puerto Triunfo",
        "libraryName": "Biblioteca Publica Doradal – Puerto Triunfo"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "San Vicente Ferrer",
        "libraryName": "Biblioteca Pública Marco Tulio Torres"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "SEGOVIA ",
        "libraryName": "Biblioteca Pública Municipal Arturo Vargas Medina "
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "Turbo",
        "libraryName": "Biblioteca Pública Hernando Delgado Orrego"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Antioquia ",
        "Municipio": "Yolombó",
        "libraryName": "Biblioteca Pública Presbítero Jesús Emilio Ramírez."
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Caldas",
        "Municipio": "Aranzazu",
        "libraryName": "Biblioteca Pública Juan De Dios Aranzazu"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Caldas",
        "Municipio": "Chinchiná",
        "libraryName": "Biblioteca Pública Municipal De Chinchiná"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Caldas",
        "Municipio": "La Dorada",
        "libraryName": "Biblioteca Pública Municipal De La Dorada"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Caldas",
        "Municipio": "Manizales ",
        "libraryName": "Biblioteca Pública Satélite La Enea"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Caldas",
        "Municipio": "Neira",
        "libraryName": "Biblioteca Pública Municipal Joaquín Ospina Vallejo"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Caldas",
        "Municipio": "PÁCORA",
        "libraryName": "Biblioteca Pública Municipal Cervantes"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Caldas",
        "Municipio": "San José ",
        "libraryName": "Biblioteca Pública Municipal \"Octavio Hernández Jiménez\""
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Quindío ",
        "Municipio": "ARMENIA ",
        "libraryName": "Biblioteca Pública Municipal de Armenia Carmelina Soto Valencia  "
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Quindío ",
        "Municipio": "Génova",
        "libraryName": "Biblioteca Pública Juan Bautista Arboleda "
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Risaralda",
        "Municipio": "DOSQUEBRADAS ",
        "libraryName": "Biblioteca Pública Municipal José Guillermo Álzate Fernández "
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Risaralda",
        "Municipio": "MARSELLA",
        "libraryName": "Biblioteca Pública Municipal León De Greiff"
    },
    {
        "Región": "Andina Occidental",
        "regionId": "region2",
        "Departamento": "Risaralda",
        "Municipio": "Santa Rosa de Cabal",
        "libraryName": "Biblioteca Pública Municipal José Eusebio Caro"
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Atlántico ",
        "Municipio": "Barranquilla",
        "libraryName": "Biblioteca La Manga"
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Atlántico ",
        "Municipio": "campo de la cruz ",
        "libraryName": "Biblioteca Municipal Campo De La Cruz "
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Cesar",
        "Municipio": "LA GLORIA",
        "libraryName": "Biblioteca Pública Municipal de La Gloria"
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Cesar",
        "Municipio": "San Alberto ",
        "libraryName": "Biblioteca Pública Municipal De San Alberto "
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Cesar",
        "Municipio": "San Diego ",
        "libraryName": "Biblioteca Pública Municipal de San Alberto Marta Lucia Guerra "
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Cesar",
        "Municipio": "San Martín",
        "libraryName": "Biblioteca Pública Argemira Pedroza De Angarita"
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "La Guajira",
        "Municipio": "Riohacha",
        "libraryName": "Biblioteca Pública Departamental Hermana Josefina Zuñiga Deluque"
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Magdalena",
        "Municipio": "ARACATACA ",
        "libraryName": "Biblioteca Pública Municipal Gabriel García Márquez "
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Magdalena",
        "Municipio": "Fundación",
        "libraryName": "Biblioteca Municipal Carlos López Riveira"
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Magdalena",
        "Municipio": "PEDRAZA",
        "libraryName": "Biblioteca Publica Ignacio Ospino García"
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Magdalena",
        "Municipio": "Remolino ",
        "libraryName": "Biblioteca Pública Municipal de Remolino"
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Magdalena",
        "Municipio": "San Zenón ",
        "libraryName": "Biblioteca Pública Municipal De San Zenón "
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Magdalena",
        "Municipio": "SANTA ANA",
        "libraryName": "Biblioteca Pública Municipal De Santa Ana Magdalena"
    },
    {
        "Región": "Caribe norte",
        "regionId": "region3",
        "Departamento": "Magdalena",
        "Municipio": "Zapayán ",
        "libraryName": "Biblioteca Pública Orlando Salgado Parody "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Bolívar ",
        "Municipio": "Cartagena ",
        "libraryName": "Biblioteca Distrital Juan De Dios Amador "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Bolívar ",
        "Municipio": "Cartagena ",
        "libraryName": "Biblioteca Distrital Jorge Artel "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Bolívar ",
        "Municipio": "Cartagena ",
        "libraryName": "Biblioteca Pública Comunitaria Encarnación Tovar. Corregimiento Boquilla "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Bolívar ",
        "Municipio": "Cartagena ",
        "libraryName": "Biblioteca Pública de Bayunca Juan Carlos Arango "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Bolívar ",
        "Municipio": "Cartagena ",
        "libraryName": "Mega Biblioteca Juan José Nieto De Rosedal"
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Bolívar ",
        "Municipio": "SAN CRISTOBAL ",
        "libraryName": "Biblioteca Pública Municipal Mariano Abel Guerrero Romero "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Bolívar ",
        "Municipio": "San Pablo",
        "libraryName": "Biblioteca Pública Vicente Guaitero "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Bolívar ",
        "Municipio": "Simiti",
        "libraryName": "Biblioteca Pública Municipal de Simití"
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Bolívar ",
        "Municipio": "Zambrano",
        "libraryName": "Biblioteca Pública Walberto Ordoñez Hernández "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Córdoba",
        "Municipio": "Chinú ",
        "libraryName": "Biblioteca Pública Municipal Luís Felipe Pineda "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Córdoba",
        "Municipio": "MOMIL",
        "libraryName": "Biblioteca Pública Municipal Momil"
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Córdoba",
        "Municipio": "MOÑITOS",
        "libraryName": "Biblioteca Pública Municipal Pedro Regalado Barrios Diaz"
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Córdoba",
        "Municipio": "Planeta rica",
        "libraryName": "Biblioteca Pública Municipal Petronio Rentería Mosquera"
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Córdoba",
        "Municipio": "Puerto Escondido ",
        "libraryName": "Biblioteca Pública Municipal de Puerto Escondido "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Córdoba",
        "Municipio": "TUCHIN ",
        "libraryName": "Biblioteca Pública Municipal Manuel Francisco Banqueth Vaquero \"El Indio Banqueth\""
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Sucre",
        "Municipio": "Ovejas ",
        "libraryName": "Biblioteca Pública Municipal Hugo Luis Salcedo García "
    },
    {
        "Región": "Caribe sur",
        "regionId": "region4",
        "Departamento": "Sucre",
        "Municipio": "Santiago de Tolù",
        "libraryName": "Biblioteca Pública Municipal Héctor Rojas Herazo"
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Casanare",
        "Municipio": "CHAMEZA",
        "libraryName": "Biblioteca Pública Municipal de Chameza"
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Casanare",
        "Municipio": "Orocué ",
        "libraryName": "Biblioteca Pública Municipal José Eustasio Rivera "
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Casanare",
        "Municipio": "Villanueva",
        "libraryName": "Biblioteca Pública Municipal de Villanueva Casanare"
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Guainía ",
        "Municipio": "Inírida ",
        "libraryName": "Biblioteca Pública Municipal Princesa Inírida"
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Guainía ",
        "Municipio": "Inírida ",
        "libraryName": "Biblioteca Pública Departamental Gabriel García Márquez "
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Guaviare",
        "Municipio": "San José del Guaviare",
        "libraryName": "Biblioteca Pública Departamental Y Patrimonial Del Guaviare"
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Meta",
        "Municipio": "CASTILLA LA NUEVA ",
        "libraryName": "Ecobiblioteca Biblioteca Pública Municipal Castilla La Nueva "
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Meta",
        "Municipio": "Mapiripan ",
        "libraryName": "Biblioteca Pública Municipal De Mapiripán "
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Meta",
        "Municipio": "SAN JUANITO",
        "libraryName": "Biblioteca Pública Municipal Ernesto María Peña Romero"
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Meta",
        "Municipio": "URIBE",
        "libraryName": "Biblioteca Pública Municipal Reinerio Arenas "
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Meta",
        "Municipio": "Vista Hermosa",
        "libraryName": "Biblioteca Pública Luis Carlos Galán Sarmiento. "
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Vichada",
        "Municipio": "La Primavera ",
        "libraryName": "Biblioteca Pública Municipal José Armando Curbelo Pérez "
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Vichada",
        "Municipio": "Puerto Carreño ",
        "libraryName": "Biblioteca Departamental Manuel María Villalba Velásquez"
    },
    {
        "Región": "Oriente",
        "regionId": "region5",
        "Departamento": "Vichada",
        "Municipio": "Santa Rosalía",
        "libraryName": "Biblioteca Pública Municipal de Santa Rosalía"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "ALMAGUER",
        "libraryName": "Biblioteca Pública Municipal De Almaguer"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "BUENOS AIRES",
        "libraryName": "Biblioteca Pública Municipal  de Buenos Aires Gabriel García Márquez"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "Corinto ",
        "libraryName": "Biblioteca Pública Gobierno Del Japón "
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "FLORENCIA",
        "libraryName": "Biblioteca Pública Municipal de Florencia Cauca"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "Miranda",
        "libraryName": "Biblioteca Pública Municipal del Municipio de Miranda"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "Puracé ",
        "libraryName": "Biblioteca Resguardo Indígena Puracé Anatolio Quirá"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "SANTA ROSA ",
        "libraryName": "Biblioteca Pública Municipal Gabriel García Márquez "
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "SOTARÁ",
        "libraryName": "Biblioteca Pública Municipal De Sotará"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "TORIBIO ",
        "libraryName": "Biblioteca Pública Municipal De Toribio"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Cauca",
        "Municipio": "Villa Rica",
        "libraryName": "Biblioteca Pública Municipal Simón Bolívar"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Chocó ",
        "Municipio": "Acandi",
        "libraryName": "Biblioteca Pública Miguel Vicente Garrido "
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Chocó ",
        "Municipio": "Atrato",
        "libraryName": "Biblioteca Pública Municipal de Atrato"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Valle del Cauca",
        "Municipio": "Ansermanuevo",
        "libraryName": "Biblioteca Pública Municipal Simón Bolívar"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Valle del Cauca",
        "Municipio": "Buenaventura",
        "libraryName": "Biblioteca Pública Distrital Fidel Ordoñez Santos"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Valle del Cauca",
        "Municipio": "Bugalagrande",
        "libraryName": "Biblioteca Pública Municipal de Bugalagrande"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Valle del Cauca",
        "Municipio": "Cali",
        "libraryName": "Biblioteca Departamental Jorge Garcés Borrero"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Valle del Cauca",
        "Municipio": "Cartago ",
        "libraryName": "Biblioteca Pública Marco Fidel Suárez "
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Valle del Cauca",
        "Municipio": "DAGUA ",
        "libraryName": "Biblioteca Pública Municipal de Dagua Miguel Ángel Jaramillo "
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Valle del Cauca",
        "Municipio": "EL AGUILA",
        "libraryName": "Biblioteca Pública Municipal Francisco Velásquez del Águila"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Valle del Cauca",
        "Municipio": "El Dovio",
        "libraryName": "Biblioteca Pública Municipal Duvardo Piedrahita Cardona"
    },
    {
        "Región": "Pacífico",
        "regionId": "region6",
        "Departamento": "Valle del Cauca",
        "Municipio": "YOTOCO",
        "libraryName": "Biblioteca Pública Municipal Alejandro Arce Rojas"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Amazonas ",
        "Municipio": "Leticia ",
        "libraryName": "Biblioteca Pública Municipal de Leticia "
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Amazonas ",
        "Municipio": "Puerto Nariño ",
        "libraryName": "Biblioteca Pública Municipal Poperapata"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Caquetá",
        "Municipio": "BELÉN DE LOS ANDAQUÍES",
        "libraryName": "Biblioteca Pública Municipal De Belén De Los Andaquíes"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "Barbacoas",
        "libraryName": "Biblioteca Pública Municipal Barbacoas Lee"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "Consaca",
        "libraryName": "Biblioteca Pública Municipal Páginas para la Convivencia y la Paz"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "Contadero ",
        "libraryName": "Biblioteca Pública Municipal de El Contadero "
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "CORDOBA ",
        "libraryName": "Biblioteca Pública Monseñor Remigio Narváez Benavides "
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "Cumbal ",
        "libraryName": "Biblioteca Pública Municipal de Cumbal "
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "GUACHUCAL",
        "libraryName": "Biblioteca Pública Municipal De Guachucal"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "Gualmatán",
        "libraryName": "Biblioteca Pública Milciades Chaves Chamorro"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "Olaya Herrera",
        "libraryName": "Biblioteca Pública Municipal De Olaya Herrera "
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "Pasto",
        "libraryName": "Biblioteca Pública Municipal de Los Barrios Surorientales de Comfamiliar De Nariño"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "Ricaurte",
        "libraryName": "Biblioteca Pública Municipal De Ricaurte"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "San Andrés de Tumaco",
        "libraryName": "Biblioteca Pública Piedad Ayora De Quiñones "
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "San Pablo ",
        "libraryName": "Biblioteca Pública Municipal Monseñor José Antonio Bolaños"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Nariño ",
        "Municipio": "Sapuyes ",
        "libraryName": "Biblioteca Pública Municipal de Sapuyes"
    },
    {
        "Región": "Sur",
        "regionId": "region7",
        "Departamento": "Putumayo",
        "Municipio": "San Francisco",
        "libraryName": "Biblioteca Pública Gabriel García Márquez"
    }
]