import React from "react";
import { EventLayer } from "./event-layer";
import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../../config/constants";
import { useTranslations } from "../../../../hooks/use-translations";
import _ from "lodash";

export const TimeZones = ({ list, visible, setVisible }) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "comunidad_club", lang: lang });

	return (
		<EventLayer title={_.get(texts, 'makemake_en_vivo.evento.boton.horarios') || "Horarios"} visible={visible} setVisible={setVisible}>
			<ul className="d-flex w-100 flex-column flex-grow-1">
				{list?.map((item, i) => {
					return (
						<li
							key={i}
							className="onbackground-primary--color font-weight-bold secondary-font"
						>
							{item}
						</li>
					);
				})}
			</ul>
		</EventLayer>
	);
};
