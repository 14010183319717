export const DATA_AWARDS = [
  {
    alt: 'Bologna ragazzi crossmedia award 2024',
    img: '/img/bologna.png',
    year: '2024',
    link: 'https://www.bolognachildrensbookfair.com/en/awards/bolognaragazzi-crossmedia-award/bolognarag[…]4-winners/digital-reading-experience-2024/13263.html'
  },
  {
    alt: 'Lapíz de acero',
    img: '/img/lapiz-acero2.png',
    year: '2023',
    link: 'https://www.lapizdeacero.org/winners/biblioteca-digital-makemake/'
  },
  {
    alt: 'Sena innova 2023',
    img: '/img/innova.png',
    year: '2023',
  },
  {
    alt: 'Sena innova 2021',
    img: '/img/innova.png',
    year: '2021',
  }
]