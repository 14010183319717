import React from "react";
import { Link } from "react-router-dom";
import { BaseModal } from "../../components/modals/base-modal";
import { Icon } from "@iconify/react/dist/iconify.js";

/**
 * Ventana modal que indica el limite de inscritos al formulario de eventos
 * @param {object} props
 * @returns {JSX.Element} ModalLimitAlert
 */
export const ModalLimitAlert = ({ visible, setVisible, textosInterfaz }) => {
    return (
        <BaseModal
            windowClassName={"modal-events col-10 col-sm-9 col-md-6"}
            visible={visible}
            setVisible={setVisible}
            backdropClose={true}
        >
            <div className="d-flex flex-row-reverse">
                <button
                    aria-label="Cerrar ventana de dialogo"
                    className="close-button onbackground-primary--color focusable-red"
                    data-micromodal-close
                    onClick={() => setVisible && setVisible(false)}
                >
                    <Icon icon={'mdi:close-thick'} width={35} />
                </button>
            </div>

            <p className="text-center">
                <Icon icon="ooui:alert" className="red-inverse--color" width={100} />
            </p>

            <div className="modal__content my-2" id="dialogo-modal-alertas">
                <p
                    className="second-category-item no-lineheight text-center mb-3 onbackground-third--color"
                    id="alert-message"
                >
                    {textosInterfaz?.limite?.title || '¡ups! Hemos llegado al limite de inscritos'}
                </p>
                <p className="mb-4 text-center onbackground-third--color" id="alert-description">
                    {textosInterfaz?.limite?.description || 'Los cupos de este evento son limitados y ya se ha excedido el número de participantes. Te hemos inscrito en la lista de espera, si alguno de los usuarios registrados no puede participar se te enviará la información de acceso.'}
                </p>

            </div>
            <Link
                to={'/club-makemake?section=makemake-en-vivo'}
                className="btn btn-primary col-lg-6 d-block mx-auto focusable-red"
                onClick={() => setVisible(false)}
                data-micromodal-close
            >
                {textosInterfaz?.limite?.button || 'Ver más eventos'}
            </Link>
        </BaseModal>
    )
}