import { useEffect, useRef, useState } from "react"
import { CancelRequestController } from "../../util/cancel-request-controller";
import { NotificacionesAPI } from "../../services/api/notificaciones";

/**
 * Hook personalizado para obtener y gestionar el estado de los datos del banner de notificaciones.
 *
 * Este hook realiza las siguientes acciones:
 * - Inicializa las variables de estado `data` y `loaded`.
 * - Define una función `fetchData` que:
 *    - Aborta cualquier solicitud en curso utilizando un `CancelRequestController` si existe uno.
 *    - Crea un nuevo `CancelRequestController` para la solicitud actual.
 *    - Restablece el estado `loaded` a `false` para indicar que la carga de datos está en progreso.
 *    - Envía una solicitud para obtener los datos del banner de notificaciones utilizando el método `NotificacionesAPI.getNotibanner`.
 *    - Actualiza el estado `data` con la respuesta y establece `loaded` a `true` tras una obtención exitosa.
 *    - Registra cualquier error encontrado durante la operación de obtención.
 * - Utiliza el hook `useEffect` para llamar a `fetchData` una vez al montar el componente.
 *
 * @returns {Object} - Un objeto que contiene:
 *    - `data`: Los datos obtenidos del banner de notificaciones.
 *    - `loaded`: Un booleano que indica si los datos se han cargado correctamente.
 */

export const useNotiBanner = ()=>{
    const [data, setData] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const prevController = useRef();


    const fetchData = async ()=>{
        if(prevController.current){
            prevController.current.abort();
        }
        const controller = new CancelRequestController();
        prevController.current = controller;
        setLoaded(false);
        await NotificacionesAPI.getNotibanner({abortSignal:controller.signal})
        .then(res=>{
            setData(res);
            setLoaded(true);
        })
        .catch(error=>console.log(error))
    }
    useEffect(()=>{
        	fetchData();
    },[])

    return {
        data,
        loaded
    }
}