  /**
  * Hace una petición a una API indicada a través del método GET y devueve una respuesta. 
  * @param {string} url - hace referencia al nombre la API
  * @param {object} data 
  * @param {Function} success 
  */
  
  function getAjax(url, data, success) {
    var params = typeof data === 'string' ? data : Object.keys(data).map(
            function(k){ return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
        ).join('&');
  
    var xhr =  new XMLHttpRequest();// IE : new ActiveXObject("Microsoft.XMLHTTP");
  
    xhr.open('GET', url + '?' + params);
    xhr.withCredentials = true;  //quitar en producción
    xhr.onreadystatechange = function() {
  
        if (xhr.readyState>3 && xhr.status===200) { success(xhr.responseText); }
        else if(xhr.readyState>3 && xhr.status!==200){ success("Error"); }

  
    };
  
    //xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  
    xhr.send(null);
  
    return xhr;
  
  }
  
  
  export default getAjax;
  