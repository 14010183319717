import React, { useContext, useState } from "react";
import { store } from "../../../components/Store";
import useObtenerSubsecciones from "../../../js/translations/useObtenerSubsecciones";
import PropTypes from 'prop-types';
import { Icon } from "@iconify/react";
/**
 * Componente que muestra el precio del producto y el botón para ir a epayco .
 */
export const BarraCompra = ({ enableBuyButton, stylePrice ,priceText, onClick, offer }) => {
    const { textosSubSecciones: textos } = useObtenerSubsecciones('tienda_makemake');
    const [loading,setLoading] = useState(false);
    const globalState = useContext(store);
    const country = globalState?.state?.pais;

    const finalPrice = priceText && country === 'CO' ? priceText?.cop || 'n/a' : priceText?.usd || 'n/a';
    const finalOffer = offer && country === 'CO' ? offer?.cop || 'n/a' : offer?.usd || 'n/a';
    const moneda = country === 'CO' ? 'COP' : 'USD';
    const isOffer = offer?.activado;
    /* console.log('test',finalOffer) */
  

    return (<div className={stylePrice}>
        <div className="pr-3 text-align-right">
            <p className="price-title">{textos?.barra_compra?.titulo || 'Total de tu compra'}</p>
            {
            isOffer&&
            <p className="font-weight-normal" >
              <span style={{fontSize:'.7em', textDecorationLine:'line-through'}}>
              ${finalPrice} {moneda}
              </span>
            <span className="font-weight-bold" style={{fontSize:'.7em'}}> {finalOffer?.cantidad} OFF</span>
            </p>
          }
            <span className="price"><span className="moneda">{moneda}</span> ${isOffer?finalOffer?.precio:finalPrice}</span>
        </div>
        <button 
            className="buy-button focusable-red" 
            disabled={!enableBuyButton} 
            onClick={() => {
                onClick && onClick();
                setLoading(true);
            }}>
                {textos?.barra_compra?.boton_comprar || 'Comprar'} {loading&&<Icon icon="fa:spinner"  className={'spinner-rotation'} />}
        </button>
    </div>)
}

BarraCompra.propTypes = {
    /**
     * Propiedad que indica si el botón de compra debe estar habilitado o no.
     */
    enableBuyButton: PropTypes.bool,
    /**
     * String que indica el estilo de la barra de compra.
     */
    stylePrice: PropTypes.string,
    /**
     * Objeto con la info del precio del producto en pesos colombianos y en dolares.
     */
    priceText: PropTypes.shape({
        cop: PropTypes.string,
        usd: PropTypes.string
    }),
    /**
     * función callback que se ejecuta cuando se de click en el botón de compra.
     */
    onClick: PropTypes.func
}

