import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { EstadoTransaccion } from "./epayco-views/estado-transaccion";
import { useContext } from "react";
import { store } from "../components/Store";

import { PlanInstituciones } from "./planInstituciones";
import { PlanCasa } from "./planCasa";
import Error404 from "../404";

/**
 * Contenedor principal que contiene el enrutamiento de la tienda y también la definición del script del API de epayco.
 */
export const TiendaMakeMake = ({ }) => {
	const appState = useContext(store);

	useEffect(() => {
		const script = document.createElement("script");
		script.async = true;
		script.src = "https://checkout.epayco.co/checkout.js";
		document.body.appendChild(script);

		script.onload = () => {
			const handler = window?.ePayco?.checkout
				? window.ePayco.checkout.configure({
					key: "215ad17b84c9c1db3947a4919b7f09ab",
					// test:window.location.hostname==='makemake.com.co'?false: true
					test: false,
				})
				: false;
			window.epaycoHandler = handler;
		};

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<>
			<Switch>
				<Redirect exact from="/tienda/individual" to={"/tienda/plan-casa"} />
				<Redirect exact from="/tienda/cursos" to={"/tienda/plan-casa/aprender"} />
				<Route exact path="/tienda/plan-casa">
					<PlanCasa />
				</Route>
				<Route exact path="/tienda/plan-casa/:plan">
					<PlanCasa />
				</Route>

				<Redirect exact from="/tienda/institucional" to={"/tienda/institucional/es/cita"} /> 
				<Redirect exact from="/tienda/institucional/cita" to={"/tienda/institucional/es/cita"} /> 
				<Route
					exact
					path={["/tienda/institucional/es/cita", "/tienda/institucional/en/cita"]}
					render={() => <PlanInstituciones />}
				/>

				<Redirect exact from="/tienda/demo" to={"/tienda/institucional/es/demo"} /> 
				<Redirect exact from="/tienda/institucional/demo" to={"/tienda/institucional/es/demo"} /> 
				<Route
					exact
					path={["/tienda/institucional/es/demo", "/tienda/institucional/en/demo"]}
					render={() => <PlanInstituciones />}
				/>
				
				<Redirect exact from="/tienda/brochure" to={"/tienda/institucional/es/brochure"} /> 
				<Redirect exact from="/tienda/institucional/brochure" to={"/tienda/institucional/es/brochure"} /> 
				<Route
					exact
					path={["/tienda/institucional/es/brochure", "/tienda/institucional/en/brochure"]}
					render={() => <PlanInstituciones />}
				/>

				<Redirect exact from="/tienda/contactanos" to={"/tienda/institucional/es/contactanos"} /> 
				<Redirect exact from="/tienda/institucional/contactanos" to={"/tienda/institucional/es/contactanos"} /> 
				<Route
					exact
					path={["/tienda/institucional/es/contactanos", "/tienda/institucional/en/contactanos"]}
					render={() => <PlanInstituciones />}
				/>

				<Route
					exact
					path="/tienda/compra/estado-transaccion"
					component={EstadoTransaccion}
				/>
				<Redirect exact from="/tienda/" to={"/tienda/institucional"} />
				<Route component={Error404} />
			</Switch>
		</>
	);
};

