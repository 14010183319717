import React, { useEffect, useRef, useState } from "react";
import { ContentHeader } from "./components/content-header";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";
import { CommunityNav } from "../components/community/community-nav";
import { ReadingPlans } from "./components/reading-plans";
import { MakeMakeLive } from "./components/makemake-live";
import { Blog } from "./components/blog";
import { CommunityDemoRequest } from "../components/community/demo-request";
import { CommunityNewsletter } from "../components/community/newsletter";
import { Resources } from "./components/resources";
import _ from "lodash";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";
import { useQueryParams } from "../hooks/useQueryParams";

import { Helmet } from "react-helmet";

export const Club = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "comunidad_club", lang: lang });
	const mainSectionRef = useRef();
	const readingPlansRef = useRef();
	const makemakeLiveRef = useRef();
	const blogRef = useRef();
	const resourcesCenterRef = useRef();
	const params = useQueryParams();

	const SECTIONS = [
		{
			id: "reading-plans",
			title: _.get(texts, "nav.item.planes") || "Planes de lectura",
			ref: readingPlansRef,
		},
		{
			id: "makemake-live",
			title: _.get(texts, "nav.item.makemake_en_vivo") || "MakeMake en vivo",
			ref: makemakeLiveRef,
		},
		{
			id: "blog",
			title: _.get(texts, "nav.item.blog") || "Blog",
			ref: blogRef,
		},
	];

	const sectionsParamsValues = {
		readingPlans: "planes-de-lectura",
		makemakeLive: "makemake-en-vivo",
		blog: "blog",
		resourcesCenter: "centro-de-recursos",
	};

	const defaulSection = SECTIONS[0];
	const [currentSectionId, setCurrentSectionId] = useState(defaulSection.id);

	const goToSection = ({ref, smooth}) => {
		if (ref?.current) {
			const element = ref?.current;
			element.scrollIntoView({ behavior: smooth?"smooth":"auto", block: "start" });
		}
	};

	const handleSectionVisiblityByUrlParams = (section) => {
		switch (section) {
			case sectionsParamsValues.readingPlans:
				goToSection({ref:readingPlansRef, smooth:false });
				break;
			case sectionsParamsValues.makemakeLive:
				goToSection({ref:makemakeLiveRef, smooth:false });
				break;
			case sectionsParamsValues.blog:
				goToSection({ref:blogRef, smooth:false });
				break;
			case sectionsParamsValues.resourcesCenter:
				goToSection({ref:resourcesCenterRef, smooth:false });
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		let delay;
		const section = params?.get("section");
		if (section) {
			handleSectionVisiblityByUrlParams(section);
		}
		return () => {
			clearTimeout(delay);
		};
	}, [params]);

	return (
		<div className="club-makemake w-100 min-vh-100" ref={mainSectionRef}>
			<Helmet>
				<title>{texts?.metadata?.title || 'MakeMake - Club'}</title>
				<meta name="description" content={texts?.metadata?.description || 'Club MakeMake reúne estrategias de promoción de lectura para acompañar el proceso lector de los usuarios y sacarle el provecho a la colección'} />
			</Helmet>
			<ContentHeader />
			<CommunityNav.Container>
				{SECTIONS.map((section, i) => {
					return (
						<CommunityNav.Item
							key={i}
							title={section.title}
							onClick={() => {
								// setCurrentSectionId(section.id);
								goToSection({ref:section.ref, smooth:true});
							}}
							// active={section.id === currentSectionId}
						/>
					);
				})}
			</CommunityNav.Container>
			<ReadingPlans ref={readingPlansRef} />
			<CommunityDemoRequest />
			<MakeMakeLive ref={makemakeLiveRef} />
			<CommunityNewsletter />
			<Blog ref={blogRef} />
			<Resources ref={resourcesCenterRef} />
		</div>
	);
};
