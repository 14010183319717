import React from "react";
import { Icon } from "@iconify/react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { Img } from "../../../components/shared/img";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente de "Características clave" para que es colegios
 * @returns {JSX.Element} Componente Features.
 */
export const Features = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textoColegios = useTranslations({ section: "que_es_colegios", lang: lang });

	const FeaturesTeachers = () => {
		const puntos = [];
		const textoPuntos = textoColegios?.caracteristicas?.docente;
		if (!textoPuntos) return;

		Object.keys(textoPuntos).map((item, index) => {
			if (item === `text${index}`) {
				puntos.push(
					<li
						key={`feature${index}`}
						tabIndex={0}
						className="focusable-secondary d-flex mt-3"
					>
						<div width={"10%"}>
							<Icon
								className="secondary-inverse--color"
								icon="tabler:point-filled"
								width={"30px"}
							/>
						</div>

						<p
							width={"90%"}
							style={{ fontSize: "0.9em" }}
							className=" ml-2"
							dangerouslySetInnerHTML={{ __html: textoPuntos[item] || "N/A" }}
						></p>
					</li>
				);
			}
		});

		return puntos;
	};

	const FeaturesStudents = () => {
		const puntos = [];
		const textoPuntos = textoColegios?.caracteristicas?.estudiantes;
		if (!textoPuntos) return;

		Object.keys(textoPuntos).map((item, index) => {
			if (item === `text${index}`) {
				puntos.push(
					<li
						key={`feature${index}`}
						tabIndex={0}
						className="focusable-secondary d-flex mt-3"
					>
						<div width={"10%"}>
							<Icon
								className="secondary-inverse--color"
								icon="tabler:point-filled"
								width={"30px"}
							/>
						</div>

						<p
							width={"90%"}
							style={{ fontSize: "0.9em" }}
							className=" ml-2"
							dangerouslySetInnerHTML={{ __html: textoPuntos[item] || "N/A" }}
						></p>
					</li>
				);
			}
		});

		return puntos;
	};

	return (
		<>
			<div className="feature-background surface--bg onbackground-primary--color">
				<div className="container">
					<div className="col-lg-10 mx-auto px-0 px-lg-4 py-3 py-lg-5">
						<h1
							tabIndex={0}
							className="focusable-secondary secondary-font py-4 font-weight-bold onbackground-primary--color"
						>
							{textoColegios?.caracteristicas?.titulo || "Características clave"}
						</h1>

						<img
							className="img-fluid"
							alt="imagen caracteristicas claves"
							src="/img/que-es-lector/caracteristicas2.png"
						/>

						<div className="onbackground-primary--color row col col-md-10 mx-auto col-lg-12 px-0 mt-3">
							<div className="col-lg-6">
								<p
									tabIndex={0}
									className="focusable-secondary important-information pt-4 font-weight-bold onprimary--color ml-lg-4"
								>
									{textoColegios?.caracteristicas?.docente?.titulo || "Docentes"}
								</p>

								<ul>{FeaturesTeachers()}</ul>
							</div>

							<div className="col-lg-6">
								<p
									tabIndex={0}
									className="focusable-secondary important-information pt-4 font-weight-bold ml-lg-4 onprimary--color"
								>
									{textoColegios?.caracteristicas?.estudiantes?.titulo ||
										"Estudiantes"}
								</p>

								<ul>{FeaturesStudents()}</ul>
							</div>

							<div className="col">
								<p
									tabIndex={0}
									className="focusable-secondary important-information pt-4 font-weight-bold ml-lg-4 onprimary--color"
								>
									{textoColegios?.caracteristicas?.tecnicas?.titulo ||
										"Características técnicas"}
								</p>

								<ul>
									<li tabIndex={0} className="focusable-secondary  d-flex mt-3">
										<div width={"10%"}>
											<Icon
												className="secondary-inverse--color"
												icon="tabler:point-filled"
												width={"30px"}
											/>
										</div>
										<p
											width={"90%"}
											style={{ fontSize: "0.9em" }}
											className=" ml-2"
											dangerouslySetInnerHTML={{
												__html:
													textoColegios?.caracteristicas?.tecnicas
														?.text2 ||
													"implementación de estándares de privacidad, analítica y accesibilidad de IMS Global.",
											}}
										></p>
									</li>

									<li className="focusable-secondary d-flex mt-3">
										<div width={"10%"}>
											<Icon
												className="secondary-inverse--color"
												icon="tabler:point-filled"
												width={"30px"}
											/>
										</div>
										<p
											width={"90%"}
											style={{ fontSize: "0.9em" }}
											className=" ml-2"
											dangerouslySetInnerHTML={{
												__html:
													textoColegios?.caracteristicas?.tecnicas
														?.text1 || "Single-Sign-On con:",
											}}
										></p>
									</li>
									<div className="row ml-5 my-3">
										<div className="col-md-6 col-lg-3 mb-3 px-0">
											<img
												src={"/img/que-es-lector/clever.png"}
												style={{ width: 100 }}
											/>
										</div>
										<div className="col-md-6 col-lg-3 mb-3 px-0">
											<img
												src={"/img/que-es-lector/classlink.png"}
												style={{ width: 150 }}
											/>
										</div>
										<div className="col-md-6 col-lg-3 mb-3 px-0">
											<img
												src={"/img/que-es-lector/gsuite.png"}
												style={{ width: 100 }}
											/>
										</div>
										<div className="col-md-6 col-lg-3 mb-3 px-0">
											<img
												src={"/img/que-es-lector/microsoft-365.png"}
												style={{ width: 150 }}
											/>
										</div>
									</div>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
