
export const MAIN_PLANS = [
    {
        imgUrl:'/img/que-es/home/plans/classics-bibliotecas.png',
        mobileImgUrl:'/img/que-es/home/plans/classics-bibliotecas-mobile.png',
        title:{
            default:'Plan biblioteca',
            translationPath:'biblioteca.titulo'
        },
        description:{
            default:'Complementa el catálogo de tu biblioteca con los libros de las mejores editoriales latinoamericanas.',
            translationPath:'biblioteca.descripcion'
        },
        demoUrl:'/tienda/demo',
        moreInfoUrl:'/que-es/bibliotecas',
        features:[
            {
                description:{
                    default:'Incrementa tu oferta de servicios remotos con contenidos 24/7',
                    translationPath:'biblioteca.caracteristica1'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Servicio multiusuario sin listas de espera',
                    translationPath:'biblioteca.caracteristica2'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Acceso a nuestra comunidad de promoción de lectura',
                    translationPath:'biblioteca.caracteristica3'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Colección gratuita de clásicos de la literatura',
                    translationPath:'biblioteca.caracteristica4'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Panel estadístico en tiempo real',
                    translationPath:'biblioteca.caracteristica5'
                },
                tooltip:null,
                /* tooltip:{
                    default:'Pautas de Accesibilidad para el Contenido Web (WCAG)',
                    translationPath:''
                } */
            },
            {
                description:{
                    default:'Capacitación gratuita y kit de marketing digital',
                    translationPath:'biblioteca.caracteristica6'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Registros MARC',
                    translationPath:'biblioteca.caracteristica7'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Múltiples opciones de autenticación (Ez Proxy, direcciones IP, intranet)',
                    translationPath:'biblioteca.caracteristica8'
                },
                tooltip:null
            },
            // {
            //     description:{
            //         default:'Descargable para facilitar la lectura sin datos',
            //        translationPath:'biblioteca.caracteristica7'
            //     },
            //     tooltip:{
            //         default:'Tooltip',
            //         translationPath:''
            //     }
            // },
        ]
    },
    {
        imgUrl:'/img/que-es/home/plans/plan-lector.png',
        mobileImgUrl:'/img/que-es/home/plans/plan-lector-mobile.png',
        title:{
            default:'Plan colegio',
            translationPath:'plan_lector.titulo'
        },
        description:{
            default:'Promueve la lectura en tus estudiantes trabajando tu plan lector con MakeMake',
            translationPath:'plan_lector.descripcion'
        },
        demoUrl:'/tienda/demo',
        moreInfoUrl:'/que-es/colegios',
        features:[
            {
                description:{
                    default:'Asignación de lecturas por edades y grados',
                    translationPath:'plan_lector.caracteristica1'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Seguimiento estadístico individual y grupal',
                    translationPath:'plan_lector.caracteristica2'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Panel personalizado para alumnos',
                    translationPath:'plan_lector.caracteristica3'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Creación de clubes de lectura',
                    translationPath:'plan_lector.caracteristica4'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Cientos de guías de lectura descargables',
                    translationPath:'plan_lector.caracteristica5'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Colección gratuita de clásicos de la literatura',
                    translationPath:'plan_lector.caracteristica6'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Interoperable con GSuite, Microsoft 365, Clever y ClassLink',
                    translationPath:'plan_lector.caracteristica7'
                },
                tooltip:null,
                /* tooltip:{
                    default:'Pautas de Accesibilidad para el Contenido Web (WCAG)',
                    translationPath:''
                } */
            },
            {
                description:{
                    default:'Acceso a nuestra comunidad de promoción de lectura',
                    translationPath:'plan_lector.caracteristica8'
                },
                tooltip:null
            },
            {
                description:{
                    default:'Capacitación gratuita y kit de marketing digital',
                    translationPath:'plan_lector.caracteristica9'
                },
                tooltip:null
            },
            // {
            //     description:{
            //         default:'Descargable para facilitar la lectura sin datos',
            //        translationPath:'planlector.caracteristica9'
            //     },
            //     tooltip:{
            //         default:'Tooltip',
            //         translationPath:''
            //     }
            // },
        ]
    }
]