import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import _ from "lodash";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

const Container = ({ children }) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textosClub = useTranslations({ section: "comunidad_club", lang: lang });

	const iconSize = 30;
	return (
		<div className="community-nav container d-flex flex-column-reverse flex-lg-row justify-content-between py-2">
			<ul className="d-none d-md-flex flex-column flex-sm-row justify-content-center align-items-center mt-2 mt-sm-0">
				{children}
			</ul>

			<ul className="d-flex  justify-content-center align-items-center p-0 my-2">
				<li>
					<a
						className="link focusable-secondary mr-3"
						aria-label="Youtube link"
						href="https://chat.whatsapp.com/BFdd39DjpHPJYN5BxmUzWh"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Icon
							className="social-media-button whastapp"
							icon="ic:baseline-whatsapp"
							width={iconSize}
						/>
					</a>
				</li>
				<li>
					<a
						className="link focusable-secondary mr-3"
						aria-label="Facebook link"
						href="https://www.facebook.com/Bookmakemake/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Icon
							className="social-media-button facebook"
							icon="ic:baseline-facebook"
							width={iconSize}
						/>
					</a>
				</li>
				<li>
					<a
						className="link focusable-secondary mr-3"
						aria-label="Instagram link"
						href="https://www.instagram.com/makemakelibros"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Icon
							className="social-media-button instagram"
							icon="mdi:instagram"
							width={iconSize}
						/>
					</a>
				</li>
				<li>
					<a
						className="link focusable-secondary mr-3"
						aria-label="Youtube link"
						href="https://www.youtube.com/channel/UCAGRlTJB3fUDRfkVRPVNdzQ"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Icon
							className="social-media-button youtube"
							icon="bi:youtube"
							width={iconSize}
						/>
					</a>
				</li>
				<li>
					<Link
						className="link underline focusable-secondary mr-3"
						aria-label="Boletín Link"
						to={"/boletin/ingresar-datos"}
					>
						{_.get(textosClub, 'nav.item.boletin') || 'Boletín'}
					</Link>
				</li>
			</ul>
		</div>
	);
};

const Item = ({ title, onClick, active }) => {
	return (
		<li>
			<button
				className={`focusable-secondary ${active?"active":""} p-2 mr-4 item secondary-font text-16`}
				onClick={() => onClick && onClick()}
			>
				{title}
			</button>
		</li>
	);
};

export const CommunityNav = {
	Container,
	Item,
};
