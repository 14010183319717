import React from 'react';
import { useDefaultLangByCountry } from './hooks/use-default-lang-by-country';
import { useTranslations } from './hooks/use-translations';
import { LANGUAGE_RULE_COUNTRIES } from './config/constants';

/**
 * Este componente no recibe props. Este se encarga de renderizar la pantalla de política de soporte del sitio.  
 * @returns {JSX.Element} PoliticaSoporte
 */
const PoliticaSoporte = () => {
  const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textoSoporte = useTranslations({ section: "politica_soporte", lang: lang});

  /**
   * Esta función retorna los encabezados de la tabla de criterios
   * @returns {HTMLElement} -etiqueta de encabezado de la tabla
   */
  const encabezadoTabla = () => {
    const puntos = [];
    const textoPuntos = textoSoporte?.criterios?.tabla_encabezado;
    if(!textoPuntos) return;
    Object.keys(textoPuntos).map((item, index) => {
        puntos.push(
          <th key={`encabezado${index}`}>{textoPuntos[item]}</th>
        )
      })
    
    return puntos;
  }

  /**
   * Esta función retorna el contenido de la fila de una tabla 
   * @param {string} textos 
   * @param {string} clave 
   * @returns {HTMLElement} etiqueta fila de la tabla
   */
  const filaTabla = (textos, clave) => {
    const puntos = [];
    if(!textos) return;
      /* const textoPuntos = textoSoporte?.criterios?.tabla_encabezado; */
      Object.keys(textos).map((item, index) => {
        puntos.push(
          <td key={`${clave}${index}`}>{textos[item]}</td>
        )
      })
    
    return puntos;
  }
  return (
    <>

        <div className="main-container">
          <div className="container main-content px-3 py-5 p-lg-5">
            <div className="pb-5">
              <h1 className="text-center mb-5">{textoSoporte?.soporte?.titulo_uno || 'POLITICA DE SOPORTE'} <em>{textoSoporte?.soporte?.titulo_dos || 'MAKEMAKE'}</em></h1>

              <div className="px-2 px-lg-5 politica-soporte" >
                <div className="mb-4 onbackground-third--color">
                  <p><em><strong>{textoSoporte?.soporte?.empresa || 'MÁKINA EDITORIAL'}</strong></em> {textoSoporte?.parrafo_uno?.parte_uno} <em><strong>{textoSoporte?.soporte?.suscriptor || 'SUSCRIPTOR'}</strong></em> {textoSoporte?.parrafo_uno?.parte_dos}</p>

                  <p>{textoSoporte?.parrafo_dos?.parte_uno}  <em><strong>{textoSoporte?.soporte?.empresa || 'MÁKINA EDITORIAL'}</strong></em> {textoSoporte?.parrafo_dos?.parte_dos} <em><strong>{textoSoporte?.soporte?.empresa || 'MÁKINA EDITORIAL'}</strong></em>{textoSoporte?.parrafo_dos?.parte_tres} <em><strong>{textoSoporte?.soporte?.suscriptor || 'SUSCRIPTOR'}</strong></em> {textoSoporte?.parrafo_dos?.parte_cuatro}</p>

                  <p>{textoSoporte?.parrafo_tres?.parte_uno} <em><strong>{textoSoporte?.soporte?.empresa || 'MÁKINA EDITORIAL'}</strong></em> {textoSoporte?.parrafo_tres?.parte_dos} <em><strong>{textoSoporte?.soporte?.suscriptor || 'SUSCRIPTOR'}</strong></em> {textoSoporte?.parrafo_tres?.parte_tres}</p>

                  <p>{textoSoporte?.parrafo_cuatro?.parte || 'El Soporte Técnico no se refiere a dificultades que tengan los usuarios con el acceso o al uso de MakeMake relacionados con su conocimiento sobre el modo de acceso o a problemas con la conectividad de internet del cliente. Para tal fin, se disponen de capacitaciones, así como materiales didácticos tales como videos o presentaciones y acceso al curso Uso y apropiación de MakeMake, entregadas al cliente junto con la información de acceso.'} </p>

                  <p>{textoSoporte?.parrafo_cinco?.parte || 'Las instancias de respuesta y atención de Soporte Técnico son:'}</p>
                </div>

                <div className="mb-4">
                  <h3 className="subseccion-politica mb-2">{textoSoporte?.correo?.subtitulo || 'Correo electrónico:'} </h3>
                  <ol className='lista-disc'>
                    <li><a href='mailto:soporte@makinaeditorial.com' className='focusable-red px-2'>soporte@makinaeditorial.com</a></li>
                    <li><a href='mailto:contacto@makemake.com.co' className='focusable-red px-2'>contacto@makemake.com.co</a></li>
                    <li><a href='mailto:catalina@makinaeditorial.com' className='focusable-red px-2'>catalina@makinaeditorial.com </a></li>
                  </ol>
                </div>

                <div className="mb-4">
                  <h3 className="subseccion-politica mb-2">{textoSoporte?.telefono?.subtitulo || 'WhatsApp o Teléfono:'} </h3>
                  <ol className='lista-disc'>
                    <li>{textoSoporte?.telefono?.horario_uno || 'Lunes a Viernes, de 9am a 6pm (hora Colombia):'}{textoSoporte?.telefono?.numero_uno || '350 2367037 - 350 2011041'} </li>
                    <li>{textoSoporte?.telefono?.horario_dos || 'Lunes a Viernes, después de las 6pm (hora Colombia) - Sábados, Domingos y Festivos:'} {textoSoporte?.telefono?.numero_dos || '313 450 99 92'} </li>
                  </ol>
                </div>

                <div className="mb-4 onbackground-third--color">
                  <p>{textoSoporte?.parrafo_seis?.parte || 'Cualquier inquietud técnica o de soporte enviada por correo electrónico y/o WhatsApp en los horarios indicados según el nivel de clasificación del problema, que se detalla a continuación. No obstante, propenderemos para solucionar lo más oportunamente posible las inquietudes o problemas que surjan. Nuestro compromiso es ayudar a las instituciones afiliadas a dar el máximo provecho a los libros adquiridos.'}</p>
                  <p>{textoSoporte?.parrafo_siete?.parte || 'A continuación, se establecen los criterios para la gestión de fallas y/o errores en sus distintos niveles y el servicio para la atención de cada una de ellas.'}</p>
                </div>

                <div className='mb-4'>
                  <table className="table statistics-table table-responsive" >
                    <thead>
                      <tr>
                        {encabezadoTabla()}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {filaTabla(textoSoporte?.criterios?.fila_uno, 'fila1-')}
                      </tr>
                      <tr>
                        {filaTabla(textoSoporte?.criterios?.fila_dos, 'fila2-')}
                      </tr>
                      <tr>
                        {filaTabla(textoSoporte?.criterios?.fila_tres, 'fila3-')}
                      </tr>
                      <tr>
                        {filaTabla(textoSoporte?.criterios?.fila_cuatro, 'fila4-')}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className='mb-4 onbackground-third--color'>
                  <p>{textoSoporte?.parrafo_ocho?.parte || 'Para brindar mejor atención, agradecemos que se documente el problema que surja así:'}</p>
                  <ol className='lista-disc'>
                    <li>{textoSoporte?.documentacion?.punto_uno || 'Descripción precisa del error apoyándose con pantallazos o videos que den cuenta de los mensajes o comportamientos de la aplicación.'}</li>
                    <li>{textoSoporte?.documentacion?.punto_dos || 'Es de gran utilidad adicionalmente y de ser posible adjuntar la versión de sistema operativo, navegador en el que se está usando y la versión del navegador. '}</li>
                  </ol>
                  <p>{textoSoporte?.ultimo_parrafo?.parte || 'Si con esta información aún no es suficiente para determinar el problema, se procederá a agendar una reunión o llamada para la resolución oportuna del mismo.'}</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      
    </>

  )
}

export default PoliticaSoporte;