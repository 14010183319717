import { useContext, useEffect, useState } from "react"
import { store } from "../../../components/Store";
import { CancelRequestController } from "../../../util/cancel-request-controller";
import { EditorialesAPI } from "../../../services/api/Editoriales";


export const usePublishersData = () => {

    const [loading, setLoading] = useState(true);
    const [publishers, setPublishers] = useState([]);
    const stateContext = useContext(store);
    const publishersDataFromState = stateContext?.state?.publishers;
    const loadedInState = publishersDataFromState?.loaded;

    const fetchData = async ({ signal }) => {
        setLoading(true);
        await EditorialesAPI.obtenerTodas({ abortSignal: signal })
            .then(result => {
                setPublishers(result);
                stateContext.dispatch({
                    type: 'CHANGE',
                    path: 'publishers',
                    value: {
                        data: result,
                        loaded: true,
                        fetching: false
                    }
                })
            })
            .catch(error => console.log(error))
            .finally(() => {
                setLoading(false);
            })
    }

    const handleDataFromState = () => {
        setLoading(false);
        setPublishers(publishersDataFromState?.data);
    }

    useEffect(() => {
        const cancelRequestController = new CancelRequestController();
        if (loadedInState === true) {
            handleDataFromState();
        } else {
            fetchData({ signal: cancelRequestController.signal });
        }
        return () => {
            cancelRequestController.abort();
        }
    }, [loadedInState])




    return {
        loading,
        publishers
    }
}