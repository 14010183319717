export const FOOTER_CONTACTS = [
    {
        title: {
            default: 'Colombia',
            translationPath: 'informacion_comercial.colombia'
        },
        emails: [
            {
                contactName: null,
                email: 'contacto@makemake.com.co'
            }
        ],
        contactNumbers: [
            '(57) 350 236 7037',
            '(57) 350 201 1041',
            '(57) 313 450 9992',
            '+1 (800) 345-5946 ext 2241',
        ],
        adresses: [
            'Cra 13 No. 33-74. Bogotá, Colombia'
        ]
    },
    {
        title: {
            default: 'Estados Unidos',
            translationPath: 'informacion_comercial.estados_unidos'
        },
        emails: [
            {
                contactName: 'Lectorum Publications Inc.',
                email: 'acorrea@lectorum.com'
            }
        ],
        contactNumbers: [
            '+1 (201) 559-2200',
            '+1 (800) 345-5946',
        ],
        adresses: [
            '205 Chubb Avenue, Bldg A-1, Lyndhurst, NJ 07071'
        ]
    },
    {
        title: {
            default: 'México',
            translationPath: 'informacion_comercial.mexico'
        },
        emails: [
            {
                contactName: null,
                email: 'contacto@infoestrategica.com'
            }
        ],
        contactNumbers: [
            '+52 55 90000210',
        ],
        adresses: [
            'Montecito 38, Piso 4 Oficina 22, Col. Nápoles, Benito Juárez, 03810 Ciudad de México, CDMX'
        ]
    },

    {
        title: {
            default: 'Perú - Ecuador',
            translationPath: 'informacion_comercial.peru_ecuador'
        },
        emails: [
            {
                contactName: 'Maskhay Corp',
                website:'https://maskhaycorp.com',
                email: 'm.alvino@maskhayperu.com'
            }
            ,
        ],
        contactNumbers: [
            'Perú +51 922 242 477',
            // 'Ecuador +593 98 779 82712',
        ],
        adresses: [
            'Jirón Bartolomé de las Casas 345, Lima 15048, Peru'
        ]
    },
]