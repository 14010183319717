import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import PropTypes from 'prop-types';
/**
 * Este componente renderiza los botones de los filtros que actualmente están activos, también realiza la lógica para actualizar los filtros actuales y removerlos desde los botones.
 */
export const FilterCardsContainer = ({ setFilters, filters, currentFilters, setCurrentFilters, onDeleteCard }) => {
    const {textosSubSecciones:textos} = useObtenerSubsecciones('busqueda');
    const [activeFilters, setActiveFilters] = useState([]);


    useEffect(()=>{
        if(Array.isArray(currentFilters)){
           const x = currentFilters.reduce((acc,curr)=>{
                if (curr.renderType === 'checkbox-selector') {
                    curr.items.forEach(el=>{
                        acc = [...acc, {...el, parentId:curr?.id}]
                    })
                  }
                else{
                    acc = [...acc, curr]
                }
                return acc;
            },[])
            setActiveFilters(x)
        }
    },[currentFilters])


    const deleteFilter = (filter) => {
      if (filters) {
        Object.entries(filters).forEach((element, i) => {
          if (element[1].renderType === 'checkbox-selector') {
            const itemIndex = element[1].items.findIndex(item => item?.id === filter?.id);
            setFilters(draft => {
              draft[element[0]].items[itemIndex] = {
                ...draft[element[0]].items[itemIndex],
                checked: false
              }
            })
          }
          else if (element[1].renderType === 'checkbox') {
            if (element[1]?.id === filter?.id) setFilters(draft => {
              draft[element[0]] = { ...draft[element[0]], checked: false }
            })
          }
          else if (element[1].renderType === 'input-text') {
            if (element[1]?.id === filter?.id) setFilters(draft => {
                draft[element[0]] = { ...draft[element[0]], value: '' }
              })
          }
        })
        if (filter?.parentId) {
            setCurrentFilters(draft => {
                const elementIndex = draft?.findIndex(el => el?.id === filter?.parentId);
                draft[elementIndex].items = draft[elementIndex].items.filter(el => el?.id !== filter?.id)
            })
        }
        else {
          setCurrentFilters([...currentFilters].filter(el=>el?.id!==filter?.id))
        }
        onDeleteCard&&onDeleteCard(filter);
      }
    }
  
  

    return (
      <div tabIndex={0} className={'focusable-secondary'} role={'region'} aria-label= {textos?.filtro?.filtros_aplicados?.titulo || `Filtros aplicados`}>
        {activeFilters.length > 0 &&
              <h3 tabIndex={0} className="focusable-secondary secondary-inverse--color mt-4 mb-3">
              {textos?.filtro?.filtros_aplicados?.titulo || `Filtros aplicados`}
            </h3>
        }
        <div className="d-flex flex-row flex-wrap">
          {
            activeFilters.map((filter,i) => {
              return (
                <div className="" key={i}>
                  <FilterCard
                    name={filter?.parentId?
                        `${textos?.filtro?.categorias?.[filter?.name?.translationId] || filter?.name?.defaultValue}` :
                        `${ textos?.filtro?.nombre_filtro?.[filter?.name?.translationId] || filter?.name?.defaultValue}`}
                    data={filter}
                    onDeleteCard={(filterData) => deleteFilter(filterData)} />
                </div>
  
              )
            })
          }
        </div>
      </div>
  
    )
  }
  FilterCardsContainer.propTypes = {
    /**
     * función de tipo useState o useImmer que permite actualizar los filtros.
     */
    setFilters:PropTypes.func.isRequired, 
    /**
     * objeto con la información de todos los filtros disponibles.
     */
    filters:PropTypes.object.isRequired, 
    /**
     * Arreglo con los filtros que están aplicados actualmente.
     */
    currentFilters:PropTypes.array.isRequired,
    /**
     * función de tipo useState o useImmer que permite actualizar los filtros aplicados.
     */ 
    setCurrentFilters:PropTypes.func.isRequired, 
    /**
     * función que se ejecuta cuando se elimina un botón de filtro aplicado, como parametro devuelve el filro en cuestión.
     * onDeleteCard(filtroEliminado)
     */
    onDeleteCard:PropTypes.func
  }

//=================================================================================================


  /**
 * Este componente renderiza un botón el cual hace referencia a un filtro aplicado
 */
  const FilterCard = ({ name, data, onDeleteCard }) => {
    return (<div className="current-filter-removable-button" aria-label={name} role={"region"}>
      <p>{name}</p>
      <button className="focusable-secondary" onClick={() => onDeleteCard(data)}>
      <Icon icon="mdi:close-thick" />
      </button>
    </div>)
  }
  
  FilterCard.propTypes = {
    /**
     * nombre que llevará el botón
     */
    name:PropTypes.string.isRequired,
    /**
     * objet con la información del filtro
     */
    data:PropTypes.object.isRequired,
    /**
     * función que se ejecuta cuando se da click en cerrar el botón
     */
    onDeleteCard:PropTypes.func,
  }

  
  