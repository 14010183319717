import React from "react";
import { Icon } from "@iconify/react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente de "Características clave" para que es bibliotecas
 * @returns {JSX.Element} Componente Features.
 */
export const Features = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textosBibliotecas = useTranslations({ section: "que_es_biblioteca", lang: lang });

	const firstFeatures = () => {
		const puntos = [];
		const textoPuntos = textosBibliotecas?.caracteristicas;
		if (!textoPuntos) return;
		Object.keys(textoPuntos).map((item, index) => {
			if (item === `text${index}` && index < 5) {
				puntos.push(
					<li
						key={`feature${index}`}
						tabIndex={0}
						className="focusable-secondary d-flex mt-3"
					>
						<div width={"10%"}>
							<Icon
								className="secondary-inverse--color"
								icon="tabler:point-filled"
								width={"30px"}
							/>
						</div>

						<p
							width={"90%"}
							style={{ fontSize: "0.9em" }}
							className=" ml-2"
							dangerouslySetInnerHTML={{ __html: textoPuntos[item] || "N/A" }}
						></p>
					</li>
				);
			}
		});
		return puntos;
	};

	const latestFeatures = () => {
		const puntos = [];
		const textoPuntos = textosBibliotecas?.caracteristicas;
		if (!textoPuntos) return;
		Object.keys(textoPuntos).map((item, index) => {
			if (item === `text${index}` && index >= 5) {
				puntos.push(
					<li
						key={`latestfeature${index}`}
						tabIndex={0}
						className="focusable-secondary d-flex mt-3"
					>
						<div width={"10%"}>
							<Icon
								className="secondary-inverse--color"
								icon="tabler:point-filled"
								width={"30px"}
							/>
						</div>

						<p
							width={"90%"}
							style={{ fontSize: "0.9em" }}
							className=" ml-2"
							dangerouslySetInnerHTML={{ __html: textoPuntos[item] || "N/A" }}
						></p>
					</li>
				);
			}
		});
		return puntos;
	};

	return (
		<div className="feature-background surface--bg onbackground-primary--color">
			<div className="container">
				<div className="col-lg-10 mx-auto px-0 px-lg-4 py-3 py-lg-5">
					<h1
						tabIndex={0}
						className="focusable-secondary secondary-font py-4 font-weight-bold onbackground-primary--color"
					>
						{textosBibliotecas?.caracteristicas?.titulo || "Características clave"}
					</h1>

					<img
						className="img-fluid"
						alt="imagen caracteristicas claves"
						src="/img/bibliotecas-que-es/computador_caracteristicas_clave.png"
					/>

					<ul className="onbackground-primary--color row col col-md-10 mx-auto col-lg-12 px-0 mt-3 mt-lg-5">
						<div className="col-lg-6">{firstFeatures()}</div>
						<div className="col-lg-6">{latestFeatures()}</div>
					</ul>
				</div>
			</div>
		</div>
	);
};
