import React, { useRef, useEffect } from "react";
import MicroModal from "micromodal";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { FocusScope } from "react-aria";
import { Icon } from "@iconify/react";
/**
 * Componente de Modal para mostrar alertas.
 * 
 * @param {object} props - Propiedades del componente.
 * @param {string} props.id - ID del modal. Opcional.
 * @param {string} props.icono - Nombre del icono a mostrar. Opcional.
 * @param {string} props.color - Color del texto del título. Opcional.
 * @param {string} props.alertType - Tipo de alerta. Opcional.
 * @param {string} props.message - Mensaje de la alerta.
 * @param {string} props.description - Descripción de la alerta.
 * @param {string} props.boton - Texto del botón de confirmación. Opcional.
 * @returns {JSX.Element} Componente ModalAlertas.
 */
const ModalAlertas = (props) => {
  const { id, icono, color, alertType, message, description, boton } = props;
  const { textosSubSecciones: textos } = useObtenerSubsecciones('global');

  const refAceptar = useRef(null);

  useEffect(() => {
    if (refAceptar.current) {
      refAceptar.current.focus();
    }
  }, [])

  const cerrarModal = () => {
    MicroModal.close(id || "modal-alertas", {
      awaitCloseAnimation: true
    });
  }

  return (
    <FocusScope contain restoreFocus>
      <div
        className="modal micromodal-slide"
        id={id || "modal-alertas"}
        aria-hidden="true"
      >
        <div className="modal__overlay" data-micromodal-close>
          <div
            className="modal__container alert_container 
            background--bg"
            role="dialog"
            aria-modal="true"
            aria-labelledby={'titulo-modal-alertas'}
            aria-describedby={'dialogo-modal-alertas'}
          >
            <div className="d-flex flex-row-reverse">
              <button
                aria-label="Cerrar ventana de dialogo"
                className="close-button onbackground-primary--color focusable-red"
                data-micromodal-close
                onClick={() => cerrarModal()}
              >
                <Icon icon={'mdi:close-thick'} width={35} />
              </button>
            </div>

            <p className="text-center">
              <i
                className={`fas fa-${!!icono ? icono : "exclamation-triangle primary-item"
                  } fa-4x red-inverse--color `}
              ></i>
            </p>
            <h2
              className={`text-center red-inverse--color ${!!color ? color : "primary-item"} `}
              id="titulo-modal-alertas"
            >
              {alertType || `${textos?.modal?.alertas?.tipo?.Alerta || 'Alerta'}`}
            </h2>
            <div className="modal__content my-2" id="dialogo-modal-alertas">
              <p
                className="second-category-item no-lineheight text-center mb-3 onbackground-third--color"
                id="alert-message"
              >
                {message}
              </p>
              <p className="mb-5 text-center onbackground-third--color" id="alert-description">
                {description}
              </p>

            </div>
            <button
              className="btn btn-primary d-block mx-auto focusable-red"
              onClick={() => cerrarModal()}
              data-micromodal-close
            >
              {boton || `${textos?.modal?.alertas?.boton?.confirmacion || 'Aceptar'}`}
            </button>
          </div>
        </div>
      </div>
    </FocusScope>

  );
};

export default ModalAlertas;
