import { useState } from "react";
import { useEffect } from "react";
import { useLibraryList } from "./use-library-list";
import normalize from "../../js/utilities/normalizeString";

/**
 * Este hook recibe un objeto con el parametro 'text',
 *
 * A partir de ese parametro se realiza una búsqueda de concidencias en el listado completo de instituciones y bibliotecas.
 *
 * Las coincidencias se filtran con base a: nombre de la institución y nombre de biblioteca.
 *
 * @param {string} text - texto que se quiere buscar
 * @returns {{matches:[]}}
 */
export const useLibrarySearch = ({ text }) => {
	const [matches, setMatches] = useState([{}, {}, {}]);
	const librariesList = useLibraryList();
	const libraries = librariesList?.data;
	text = typeof text === "string" ? text.trim() : "";

	/**
	 * Organiza los resultados primero por instituto y por cada instituto organiza las sedes (A-Z).
	 * @param {Array} _matches
	 * @returns {Array} arreglo ordenado.
	 */
	const getSortedMatches = (_matches) => {
		_matches = Array.isArray(_matches) ? _matches : [];
		const sorted = _matches.sort((a, b) => {
			const nameA = a?.instituto?.nombre?.toUpperCase(); 
			const nameB = b?.instituto.nombre?.toUpperCase();
			const sedeA = a?.nombreSede?.toUpperCase();
			const sedeB = b?.nombreSede?.toUpperCase();

			// Manejo de objetos con nombres vacíos
			if (nameA === "" && nameB !== "") {
				return 1; // Mover objetos vacíos hacia el final
			}
			if (nameA !== "" && nameB === "") {
				return -1; // Mover objetos vacíos hacia el final
			}

			// Comparación por nombre de instituto
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// Si los nombres de instituto son iguales, comparación por nombre de sede
			if (sedeA < sedeB) {
				return -1;
			}
			if (sedeA > sedeB) {
				return 1;
			}

			if (sedeA === "" && sedeB !== "") {
				return 1; // Mover objetos vacíos hacia el final
			}
			if (sedeA !== "" && sedeB === "") {
				return -1; // Mover objetos vacíos hacia el final
			}

			return 0;
		});
		return sorted;
	};

	/**
	 * con base al nombre del instituto y a los nombres de las sedes se generan las coincidencias de búsqueda.
	 */
	const loadMatches = () => {
		const _text = normalize(text);
		const _matches = libraries.filter((library) => {
			return (
				normalize(library?.instituto?.nombre).includes(_text) ||
				normalize(library?.nombreSede).includes(_text) ||
				normalize(library?.direccionSede).includes(_text) || 
				normalize(library?.pais).includes(_text) ||
				normalize(library?.ciudad).includes(_text) 
			);
		});
		const sorted = getSortedMatches(_matches);
		setMatches(sorted);
	};

	useEffect(() => {
		loadMatches();
	}, [text, libraries]);

	return {
		matches,
	};
};
