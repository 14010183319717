import React from "react";

import SkeletonBanner from "./SkeletonBanner";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SkeletonInstruccion from "./SkeletonInstruccion";
import SkeletonLibrosHome from "./SkeletonLibrosHome";
import SkeletonColeccion from "./SkeletonColeccion";
import SkeletonPodemosAyudar from "../../SkeletonPodemosAyudar";

const SkeletonHomeGeneral = (props) => {
    const { isLogged } = props;

    const caracteristicas = () => {
        let items = [];
        for (var i = 1; i <= 4; i++) {
            let num = i;
            items.push(
                <div key={`caracteristica-${num}`} className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0">
                    <div className="col-md-6 mx-auto mb-3 d-flex justify-content-center align-items-center">
                        <Skeleton height={80} width={80} />
                    </div>
                    <div className="skeleton" >
                        <Skeleton height={'100%'} width={'100%'} />
                    </div>
                </div>
            )
        }
        return items;
    }

    return (
        <SkeletonTheme color="#e8e8e8" highlightColor="#f7f7f7">
            <SkeletonBanner />

            <div className="d-flex col-md-10 mx-auto mt-5 mt-lg-0 pb-3 py-lg-5 flex-wrap justify-content-around contenedor-caracteristicas">
                {caracteristicas()}
            </div>
            <div className="highlight-container py-5 interactiveCovers-container">
                <h3 className="pl-3 pl-lg-5 mb-4">
                    <Skeleton height={50} width={300} />
                </h3>
                <SkeletonLibrosHome seccion="interactive-covers" />
            </div>

            <div className="highlight-container py-5">
                <h3 className="pl-3 pl-lg-5 mb-4">
                    <Skeleton height={50} width={300} />
                </h3>
                <SkeletonColeccion />
            </div>
       
            <div className="highlight-container py-5 ilustratedCovers-container ">
                <h3 className="pl-3 pl-lg-5 mb-4">
                    <Skeleton height={50} width={300} />
                </h3>

                <SkeletonLibrosHome seccion="main-covers" />
            </div>

            {!isLogged && <SkeletonInstruccion />}
           
            <SkeletonPodemosAyudar/>

        </SkeletonTheme>

      
    );
}

export default SkeletonHomeGeneral;
