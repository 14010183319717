/**
 * Hook que gestiona información del dispositivo actual.
 * Retorna la función isMobile que permite saber si el dispositivo actual es alguno de los siguientes: android|webos|iphone|ipad|ipod|blackberry|windows phone
 * @returns {{isMobile:<()string>}}
 */
export const useDevice = () => {
	const isMobile = () => {
		const userAgent = navigator.userAgent.toLowerCase();
		const isDesktop =
			/(win|mac|linux)/.test(userAgent) &&
			!/(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(userAgent);
		return isDesktop ? false : true;
	};

	return {
		isMobile,
	};
};
