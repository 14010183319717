const normalizeString = (function () {
  var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
    to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
    mapping = {};

  for (var i = 0, j = from.length; i < j; i++)
    mapping[from.charAt(i)] = to.charAt(i);

  return function (str = '') {
    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
      var c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
      else ret.push(c);
    }
    return ret.join("");
  };
})();

/**
 * Retorna una cadena de caracteres sin simbolos, caracteres especiales y en minúsculas.
 * @param {string} str - Cadena de texto a normalizar
 * @returns {string} - Cadena de texto normalizada
 */
function normalize(str='') {
  let string = normalizeString(str || '')
    .replace(/["']/g, "")
    .replace(/:\s*/g, " ")
    .replace(/,/g, "")
    .replace(/\?/g, "")
    .replace(/\¿/g, "")
    .split(" ")
    .join("-")
    .toLowerCase();
  return string;
}

export default normalize;
