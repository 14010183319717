import React, { useEffect, useState, useRef } from "react";
import getUrlParam from './../js/utilities/getUrl';
import { useHistory } from 'react-router-dom';
import postAjax from './../js/utilities/postAjax';
import { GENERAL_URL_API } from "../components/Store";
import ModalAlertas from "../components/ModalAlertas";
import MicroModal from 'micromodal';
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Componente que no recibe props. Este componente se encarga de renderizar el formulario de confirmar correo. Su principal función es validar la información y redireccionar a la siguiente página. Este hace uso del componente ModalAlertas para emitir alertas si el fomulario no esta bien diligenciado.
 * @returns DemoConfirmarCorreo
 */
const DemoConfirmarCorreo = () => {
  const { isReady, textosSubSecciones: textosDemo } = useObtenerSubsecciones('demo');

  const refFormulario = useRef(null);
  const refCodigo = useRef(null);
  const [notificationCodigo, setNotificationCodigo] = useState(false);

  const history = useHistory()

  const [preloader, setPreloader] = useState(false)
  const [properties, setProperties] = useState({
    referenciaP: '',
    textoOtroP: '',
  })
  const [respuestaApi, setRespuestaApi] = useState({
    description: '',
    message: ''
  })

  const enviarFormulario = (e) => {
    e.preventDefault();
    if (refFormulario.current.reportValidity()) {
      setPreloader(true);
      const propertiesApi = {
        ...properties,
        codigoVerificacion: refCodigo.current.value
      }

      postAjax(`${GENERAL_URL_API}/assets/demo/enviarCorreoDemo`, propertiesApi, response => {
        setPreloader(false);
        const respuesta = JSON.parse(response);
        console.log(respuesta);
        const error = respuesta.status === 0;
        const info = respuesta.info;

        if (!error) {
          history.push(`/demo/demo-mensaje?em=${properties.correoP}`)
        } else {

          let message = {}
          switch (info) {
            case "Error, codigo invalido":
              message = {
                description: textosDemo?.confirmar_correo?.codigo_invalido?.descripcion || 'Por favor verifica el código ingresado.',
                message: textosDemo?.confirmar_correo?.codigo_invalido?.mensaje || 'Código inválido'
              }
              break;
            case "Error, correo existente":
              history.push('/demo/demo-ingresar-datos?sent=false3')
              break;
            case "Error, no se pudo enviar el correo a MakeMake":
              message = {
                description: textosDemo?.confirmar_correo?.no_enviado?.mensaje || 'Correo existente',
                message: textosDemo?.confirmar_correo?.no_enviado?.descripcion || 'El correo ingresado ya se encuentra regiistrado'
              }
              break;
            case "Error, no se pudo enviar el correo al cliente":
              message = {
                description: textosDemo?.confirmar_correo?.no_enviado?.mensaje || 'Correo existente',
                message: textosDemo?.confirmar_correo?.no_enviado?.descripcion || 'El correo ingresado ya se encuentra regiistrado'
              }
              break;
            default: message = { description: '', message: '' }
          }
          setRespuestaApi(message)
          MicroModal.show('modal-alerts', {
            awaitCloseAnimation: true
          })
        }
      })
    } else {
      setNotificationCodigo(true)
    }
  }

  useEffect(() => {
    const nombreP = decodeURIComponent(getUrlParam('nombre'));
    const correoP = decodeURIComponent(getUrlParam('correo'));
    const institucionP = decodeURIComponent(getUrlParam('institucion'));
    const telefonoP = decodeURIComponent(getUrlParam('telefono'));
    const paisP = decodeURIComponent(getUrlParam('pais'));
    const ciudadP = decodeURIComponent(getUrlParam('ciudad'));
    const edadP = decodeURIComponent(getUrlParam('edad'));

    setProperties({ ...properties, nombreP, correoP, institucionP, telefonoP, paisP, ciudadP, edadP })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      {isReady &&
        <div className="col-lg-8 mx-auto ">
          <>
            <div className="col-lg-10 mx-auto py-4 px-0">
              <h2 className="mm-redimir_title my-4 text-center">{textosDemo?.confirmar_correo?.titulo || 'Ingresa el código de verificación'}</h2>
              <p className="text-center onbackground-third--color">
                {textosDemo?.confirmar_correo?.descripcion || 'Ingresa el código que hemos enviado al correo'} <br />
              </p>
              <p className="text-center onbackground-third--color">
                <strong>{properties.correoP}</strong>
              </p>

            </div>


            <form ref={refFormulario}>
              <p className="secondary-item d-block"><strong>{textosDemo?.confirmar_correo?.texto || 'Código:'}</strong></p>
              <div className="row mb-4 align-items-start">

                <div className="col-lg-8 mb-4 mb-lg-0">
                  <input
                    ref={refCodigo}
                    type="text"
                    className="form-control surface--bg  onbackground-third--color focusable-secondary"
                    name="codigoVerificacion"
                    placeholder={textosDemo?.confirmar_correo?.placeholder || "Escribe el código de verificación"}
                    required
                    onChange={() => setNotificationCodigo(false)}
                  />
                  <span role={"alert"} aria-live="assertive" className="notificacion-form">
                    {notificationCodigo ? textosDemo?.confirmar_correo?.alert || 'Para enviar este formulario debes ingresar un código' : ''}
                  </span>
                </div>
                <div className="col-lg-4">
                  <button onClick={(e) => enviarFormulario(e)} type="submit" className="btn btn-primary d-block col-12 focusable-red">
                    {
                      preloader
                        ? <i className="fas fa-spinner fa-pulse"></i>
                        : textosDemo?.botones?.solicitar || 'Solicitar Demo'
                    }
                  </button>
                </div>
              </div>
            </form>
          </>
        </div>
      }

      <ModalAlertas id="modal-alerts" message={respuestaApi.message} description={respuestaApi.description} />
    </>
  )
}
export default DemoConfirmarCorreo;