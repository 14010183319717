
import React, { useEffect, useState, useContext } from "react";

import { store } from "../../components/Store";
import { Img } from "../../components/shared/img";
import { EventsLogInRequest } from "./events-log-in-request";

/**
 * Componente que renderiza la pantalla de ¡Revive los mejores momentos!
 * @param {Object} props contiene  dataViewEvent los datos del formulario y textosInterfaz 
 * @returns {JSX.Element} ViewEvent
 */
export const ViewEvent = ({ dataViewEvent, textosInterfaz }) => {
    const globalState = useContext(store);
    const { state } = globalState;
    const idioma = state.idioma;
    const isLogged = globalState.state.sesionActiva === "SI";

    const [link, setLink] = useState("/");
    const [showModalLogIn, setShowModalLogIn] = useState(false);

    useEffect(() => {
        let enlace = dataViewEvent?.linkEvento || "/";
        setLink(enlace)
    }, [dataViewEvent])

    return (
        <div className="row">
            
            <div className="col-lg-7 d-flex justify-content-center align-items-center m-3 mx-lg-0 my-lg-5 order-lg-2">
                <div className="py-lg-5 secondary-font onprimary--color">
                    <p className="text-center mt-lg-5 text-18" style={{fontWeight: 600}}>{textosInterfaz?.ver_evento?.title || '¡Revive los mejores momentos!'} </p>
                    <h1 className="text-center text-line-h-20 my-3">
                        {idioma === 'es' ? dataViewEvent?.titulo?.es || 'TITULO DEL EVENTO' : dataViewEvent?.titulo?.en || 'EVENT TITLE'}
                    </h1>
                    {
                        dataViewEvent?.tipoEvento === "publico" ?

                            <a href={link} target="_blank" className="focusable-secondary btn btn-ver-evento">{textosInterfaz?.ver_evento?.button || 'Ver evento'}</a>
                            :
                            isLogged ?
                                <a href={link} target="_blank" className="focusable-secondary btn btn-ver-evento">{textosInterfaz?.ver_evento?.button || 'Ver evento'}</a>
                                :
                                <button
                                    onClick={() => setShowModalLogIn(true)}
                                    className="focusable-secondary btn btn-ver-evento"
                                >
                                    {textosInterfaz?.ver_evento?.button || 'Ver evento'}
                                </button>
                    }
                </div>

            </div>

            <div className="col-lg-5 order-lg-1">
                <Img src="/img/personajes/blop-event.png" className="w-100 p-4 p-lg-0" style={{maxWidth:300}}/>
            </div>

            <EventsLogInRequest
                visible={showModalLogIn}
                setVisible={setShowModalLogIn}
                redirectionParam={`/eventos/formularios/${dataViewEvent?.id}`}
                textosInterfaz={textosInterfaz}
            />
        </div>
    )
}