import { useEffect, useRef, useState } from "react";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { BlogAPI } from "../../services/api/Blog";


/**
 * Este hook devuelve un objeto con el estado de carga y la información de las reseñas para la pagina principal del blog 
 * @param {object< date: object; sch: string; tag: string>} props - contiene la información de busqueda 
 * @returns {object< loading: Boolean; data: array>}
 */
export const useSearchPost = ({date, sch, tag}) => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);

	const prevAbortController = useRef();

	const fetchPosts = async () => {
		if (prevAbortController.current) {
			prevAbortController.current.abort();
		}
		const abortController = new CancelRequestController();
		prevAbortController.current = abortController;
		setLoading(true);
       
        let data = "";

		if(sch !== ""){
			data = `?sch=${sch ? sch : ''}`;
		}else if (tag !== ""){
			data = `?tags=${tag ? tag : ''}`;
		}else{
			data = "";
		}

		await BlogAPI.searchPost({ abortSignal: abortController.signal,  data})
			.then((_posts) => {
                const newPost = [];
				
                if(date?.value !== ""){
					_posts.map(resena =>{
						date.array.map(idResena=>{
							if(resena?.idBlog === idResena?.idBlog){
								newPost.push(resena)
							}							
						})
					})
					setLoading(false);
					setPosts(newPost)
					

                }else{
					setLoading(false);
					setPosts(_posts)
				}		
			})
			.catch((err) => {
				setPosts([]);
			})
			.finally(() => {
				/* setLoading(false); */
			});
	};

	useEffect(() => {
		fetchPosts();
		return () => {
			if (prevAbortController.current) {
				prevAbortController.current.abort();
			}
		};
	}, [date, sch, tag]);

	return {
        loading,
		data:  posts
	};
};
