import React, { useContext, useEffect, useState } from "react";
import { Img } from "../../components/shared/img";
import { Icon } from "@iconify/react";
import { ASSETS_URL, store } from "../../components/Store";
import { useTranslations } from "../../hooks/use-translations";
import _ from "lodash";
import { CommunitySignInRequestModal } from "../../components/community/community-sign-in-request-modal";

export const ResourcesList = ({ resources, color, lang, loading, editableResource=false }) => {
	const globalStateContext = useContext(store);
	const state = globalStateContext?.state;
	const isAuth = state?.sesionActiva !== null && state?.sesionActiva === "SI";
	return (
		<div className={`w-100 h-100 resources-list p-3 ${color}`} style={{ minHeight: 300 }}>
			{loading ? (
				<div
					className="w-100 h-100 d-flex justify-content-center align-items-center"
					style={{ minHeight: "100%" }}
				>
					<Icon icon="icomoon-free:spinner9" width={50} className={`spinner-rotation`} />
				</div>
			) : (
				<div className="row m-0 p-0">
					{resources?.map((resource, i) => {
						return (
							<div
								className="col-12 col-sm-6 col-xl-4 my-3 d-flex justify-content-center align-items-center"
								key={`resource-${resource?.idRecursos}-${i}`}
							>
								<div className="w-100 h-100" style={{ maxWidth: 300 }}>
									<ResourceCard
										key={`resource-${resource?.idRecursos}-${i}`}
										data={resource}
										lang={lang}
										isAuth={isAuth}
										editableResource={editableResource}
									/>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export const ResourceCard = ({ data, lang, isAuth, editableResource=false }) => {
	const texts = useTranslations({ section: "centro_recursos", lang: lang });
	const resourceFileType = data?.tipoDeArchivo?.toLowerCase();
	const zipFile = isAuth?`${ASSETS_URL}${data?.archivoZip}`:'/';
	const fileLink = isAuth?`${data?.enlace}`:'/';
	const [showNoAuthModal, setShowNoAuthModal] = useState(false);

	const handleNoAuthUser = ()=>{
		setShowNoAuthModal(true);
	}

	return (
		<div className="p-0 m-0 resource-card w-100 h-100">
			<div className="p-0 m-0">
				<Img
					className="w-100 h-100"
					src={`${ASSETS_URL}${data?.imagen}` || "/img/no-imagen3.png"}
					defaultImage={"/img/no-imagen3.png"}
				/>
			</div>
			<div className="p-2 m-0 onbackground-primary--color ">
				<p
					className="text-uppercase secondary-font secondary-inverse--color text-14"
					style={{ fontWeight: 700 }}
				>
					{lang === "en"
						? data?.descripcionEng || data?.descripcion
						: data?.descripcion}
				</p>
				<h3 className="text-20 font-secondary" style={{ fontWeight: 700 }}>
					{lang === "en"
						? data?.tituloEng || data?.titulo || "N/A"
						: data?.titulo || "N/A"}
				</h3>
				<div className="d-flex">
					{resourceFileType === "zip" || resourceFileType === "ambos" ? (
						<a
							className="d-flex align-items-center button p-2 my-2 mr-3"
							href={zipFile}
							onClick={(e) => {
								if (isAuth !== true) {
									e.preventDefault();
									handleNoAuthUser();
								}
							}}
							download={true}
						>
							{_.get(texts, "tarjeta_recurso.boton.descargar") || "Descargar"}
							<Icon icon="fe:download" className="ml-2" width={24} />
						</a>
					) : null}
					{resourceFileType === "enlace" || resourceFileType === "ambos" ? (
						<a
							className="d-flex align-items-center button p-2 my-2"
							href={fileLink}
							target="_blank"
							rel="noopener noreferrer"
							onClick={(e) => {
								if (isAuth !== true) {
									e.preventDefault();
									handleNoAuthUser();
								}
							}}
						>
							{editableResource ? _.get(texts, "tarjeta_recurso.boton.editar") || "Editar": _.get(texts, "tarjeta_recurso.boton.ver") || "Ver"}
						</a>
					) : null}
				</div>
			</div>
			<CommunitySignInRequestModal
				visible={showNoAuthModal}
				setVisible={setShowNoAuthModal}
				title={texts?.popups?.iniciar_sesion?.titulo || "Contenido exclusivo!"}
				description={
					texts?.popups?.iniciar_sesion?.descripcion ||
					"Para acceder a este contenido inicia sesión."
				}
				buttonText={texts?.popups?.iniciar_sesion?.boton || "Iniciar sesión"}
				redirectionParam={"/centro-de-recursos"}
			/>
		</div>
	);
};
