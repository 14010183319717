
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Este servicio devuelve un objeto con un arreglo de colecciones, series y paises.
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<{
  colecciones:Array<{
    nombre:string,
    descripcion:string,
    url:string,
    imgUrl:string
  }>,
  series:Array<{
    nombre:string,
    descripcion:string,
    url:string,
    imgUrl:string
  }>,
  paises:[]
}
>
}
>} retorna un objeto con los datos de la colección de libros
 */
const getAll = async ({abortSignal=null})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const response = await axios({
          method: 'POST',
          url: 'assets/curadurias',
          signal: abortSignal || null,
          headers:{"Content-Type":"multipart/form-data"},
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  {
          colecciones:Array.isArray(datos?.colecciones)?datos?.colecciones:[],
          series:Array.isArray(datos?.autoresYseries)?datos?.autoresYseries:[],
          paises:Array.isArray(datos?.paises)?datos?.paises:[],
        };
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}


export const CuraduriasAPI = {
    getAll,
}