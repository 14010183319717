import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

export const CommunityNewsletter = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const newsletterTexts = textos?.boletin;
	const history = useHistory();
	const [email, setEmail] = useState("");

	const handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		history.push({
			pathname: "/boletin/ingresar-datos",
			search: `?email=${email}`,
		});
	};
	return (
		<div className="community-newsletter-cta onbackground-primary--color">
			<div className="container">
				<div className="d-md-flex justify-content-center">
					<div className="shape">
						{/* <Img className="img-fluid accesible-image" src="" alt="newsletter-speaker"/> */}
					</div>
					<div
						className="py-3 col d-flex flex-column justify-content-center align-items-center"
						style={{ maxWidth: 600 }}
					>
						<h1
							className="text-center text-md-left secondary-font"
							style={{ fontSize: "1.3em", lineHeight: "1.3em" }}
						>
							{newsletterTexts?.titulo ||
								"Suscríbete a nuestro boletín y entérate de eventos, promociones y actividades"}
						</h1>
						<form
							className="m-0 p-0 my-2 row align-items-center"
							onSubmit={(e) => handleSubmit(e)}
						>
							<input
								className="form-control col-12 col-md focusable-secondary"
								onChange={(e) => setEmail(e?.target?.value)}
								placeholder={
									newsletterTexts?.input?.placeholder || "Correo electrónico"
								}
								type="email"
								required
							/>
							<div className="d-flex d-md-block col m-0 p-0 justify-content-end">
								<button className="btn btn-primary p-2 px-3 mx-0 my-3 m-md-3 focusable-primary">
									{newsletterTexts?.boton || "Suscribirme"}
								</button>
							</div>
							<p
								className="py-3 text-left font-weight-light"
								style={{ fontSize: "0.9em" }}
							>
								{textos?.global?.terminos_condiciones_politica1 ||
									"Al ingresar tus datos estás aceptando"}{" "}
								<Link
									className="focusable-secondary onbackground-primary--color font-weight-bold"
									to="/terminos-y-condiciones"
								>
									{textos?.global?.terminos_condiciones_politica2 ||
										"Términos y condiciones"}
								</Link>{" "}
								{textos?.global?.terminos_condiciones_politica3 || "y"}{" "}
								<Link
									className="focusable-secondary onbackground-primary--color font-weight-bold"
									to="/politica-de-privacidad"
								>
									{textos?.global?.terminos_condiciones_politica4 ||
										"Política de privacidad"}
								</Link>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
