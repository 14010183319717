import axios from "axios";
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Obtiene la lista de todos los institutos.
 *
 * @param {Object} options - Opciones para la petición.
 * @param {AbortSignal} options.abortSignal - Señal de aborto para la petición.
 * @returns {Promise<Array>} Una promesa que se resuelve en un arreglo de objetos que representan los institutos.
 * @throws {Error} Si hay un error en la petición o se recibe una respuesta de error.
 */
const getAllInstitutions = async ({ abortSignal }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const response = await axios({
			method: "GET",
			url: `assetsApp/listaInstitutos?web=true`,
			signal: abortSignal || null,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		const responseData = response?.data;
		if (
			responseData?.error ||
			(typeof responseData === "string" && responseData?.toLowerCase()?.includes("error"))
		) {
			throw new Error(responseData?.error || responseData);
		}
		return Array.isArray(responseData?.institutos)?responseData?.institutos:[];
	} catch (error) {
		console.log("API ERROR:", error);
		return Promise.reject(error);
	}
};

/**
 * API de institutos que proporciona métodos para gestionar y manipular información de los institutos.
 */
export const InstitutionalAPI = {
    getAllInstitutions
};
