import React from "react";
import { Link } from "react-router-dom";

import { Img } from "../../components/shared/img";
import { ASSETS_URL } from "../../components/Store";

import normalize from "../../js/utilities/normalizeString";

/**
 * Renderiza la tarjeta de las entradas o reseñas 
 * @param {object} InfoCard -contiene la información de la reseña
 * @param {object} textosBlog -contiene los textos de interfaz
 * @returns {JSX.Element} BlogCard
 */
export const BlogCard = ({InfoCard, textosBlog}) => {
    return (
        <div className="row  mx-0 blog-card">
            <div className="col-6 col-sm-4 col-md-3 col-lg-5  col-xl-4  px-0">
                <div className="blog-card-img">
                    <Img src={`${ASSETS_URL}${InfoCard?.portada}`} defaultImage={"/img/no-imagen.png"} className="img" />
                </div>
            </div>
            <div className="col-6 col-sm-8 col-md-9 col-lg-7  col-xl-8 px-0">
                <div className="p-3 h-100 d-lg-flex flex-column justify-content-between" >
                    <div style={{ fontWeight: 700 }} className="mb-3 mb-lg-5">
                        <p className="secondary-inverse--color text-16" >
                            {InfoCard?.fecha || '-/-/-'}
                        </p>
                        <p className="secondary-font text-18 onprimary--color my-2">{InfoCard?.title || 'title'}</p>
                        <p className="text-16 onprimary--color" style={{ fontWeight: 400 }}>{InfoCard?.description || 'description'}</p>
                        
                    </div>
                    <div>
                        <Link 
                            to={`/blog/${InfoCard?.idBlog}/${normalize(InfoCard?.title)}`}
                            className="blog-btn-card d-block text-center">
                            {textosBlog?.botones?.leer || 'Leer'}
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}