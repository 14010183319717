import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { Icon } from "@iconify/react/dist/iconify.js";

import { EventosFormularioAPI } from "../services/api/FormularioEventos";

import { PublicEvent } from "./components/PublicEvent/index";
import { ExclusiveEvent } from "./components/ExclusiveEvent/index";
import { FormDontExist } from "./components/FormDontExist";

import normalize from "../js/utilities/normalizeString";
import { CancelRequestController } from "../util/cancel-request-controller";

/**
 * Componente que retorna el formulario correspondiente a eventos públicos o exclusivos
 * @returns {JSX.Element} Events 
 */
export const Events = () => {
    const [loading, setLoading] = useState(true);
    const [urlId, setUrlId] = useState(false);
    const [data, setData] = useState({});
    const prevAbortControllerRef = useRef();
    const params = useParams();
    const { idForm } = params;

    const fetchEventInformation = async ({ form }) => {
        if (prevAbortControllerRef.current) {
            prevAbortControllerRef.current.abort();
        }
        const abortController = new CancelRequestController();
        prevAbortControllerRef.current = abortController;
        setLoading(true);
        await EventosFormularioAPI.getEventInformation({
            abortSignal: abortController.signal,
            idForm: form
        })
            .then((response) => {
                const data = response;
                const error = data.status === 0;

                if (!error) {

                    const infoForm = {}
                    Object.keys(data?.data[0]).map((item) => {
                        if (item === 'tipoEvento') {
                            infoForm[item] = normalize(data?.data[0][item]);
                        } else {
                            infoForm[item] = data?.data[0][item];
                        }
                    })
                    setData(infoForm || {})
                    setUrlId(false)
                } else {
                    console.log('error-----', error)
                    setUrlId(true)
                }
            })
            .catch((error) => {
                console.log('catch error-----', error)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchEventInformation({ form: idForm })
    }, [])


    return (
        <>
            {
                loading ?
                    <>
                        <div
                            className="w-100 h-100 d-flex justify-content-center align-items-center secondary-inverse--color"
                            style={{ minHeight: 300 }}
                        >
                            <Icon icon="icomoon-free:spinner9" width={60} className={"spinner-rotation"} />
                        </div>
                    </>
                    :
                    <>
                        {
                            urlId ?
                                <FormDontExist />
                                :
                                <>
                                    {data?.tipoEvento === "publico" ?
                                        <PublicEvent eventData={data} />
                                        :
                                        <ExclusiveEvent eventData={data} />
                                    }
                                </>

                        }
                    </>
            }

        </>
    )
}