import { useEffect } from "react";

/**
 * Hook para gestionar la apertura de links
 */
export const useWindowOpen = ()=>{
  const ID = 'pwa_window_open_ref';
  const getLinkElement = ()=>{
    return  document.getElementById(ID);
  }
  /**
   * método que abre un nuevo tab en el navegador
   * @param {"_blank"|"_parent"|"_self"|"_top"} target  
   * @param {string} url 
   */
  const open = (target, url)=>{
    const element = getLinkElement();
    if(element){
      element.href = url;
      element.target = target;
      element.click();
    }
  }

  useEffect(()=>{
    const a = document.createElement('a');
    a.id = ID;
    a.style.width = 0;
    a.style.height = 0;
    a.style.visibility = "hidden";

    const linkElement = getLinkElement();
    if(linkElement){
      document.body.removeChild(linkElement);
      document.body.appendChild(a);
    }
    else{
      document.body.appendChild(a);
    }
  },[])

  return {
    open:open
  }
}