import React from "react";
import {Icon} from '@iconify/react';

/**
 * Componente de icono de notificación.
 * 
 * Este componente muestra un icono de notificación opcionalmente con un círculo de notificación que representa cuando hay notificaciones nuevas.
 * 
 * @component
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {number} props.width - Ancho del icono. Por defecto es 20.
 * @param {boolean} props.newNotification - Indica si hay una nueva notificación. Por defecto es false.
 * @param {Object} props.circleOffset - Offset del círculo de notificación. Por defecto es {x: 0, y: 0}.
 * @param {number} props.circleOffset.x - Offset horizontal del círculo de notificación.
 * @param {number} props.circleOffset.y - Offset vertical del círculo de notificación.
 */

export const NotificationIcon = ({ width = 20, newNotification = false, circleOffset={x:0, y:0} }) => {
    return <span style={{ pointerEvents: 'none' }}>
        <Icon
            className={`icon ${newNotification?"notification-shake":""}`}
            icon="zondicons:notification"

            width={width}
        />
        {
            newNotification ?
                <div className="new-notification-circle" style={{top:circleOffset.y, left:circleOffset.x}}></div>
                :
                null
        }
    </span>
}