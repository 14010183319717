
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Este servicio devuelve los libros con base a los filtros que se pasen como parametro.
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<{
    "nombreContacto": String,
    "correoContacto": String,
    "telefonoContacto":String,
    "link1Mostrar": String,
    "link2Mostrar": String,
}>} retorna un objeto con los libros encontrados
 */
const obtenerTodas = async ({abortSignal=null, data})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const response = await axios({
          method: 'GET',
          url: 'assets/editoriales',
          signal: abortSignal || null,
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos?.editoriales || [];
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}

export const EditorialesAPI = {
  obtenerTodas,
}