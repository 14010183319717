export const MEDIA_DATA = [
  {
    title:{
        default:'Especial de libros en español.',
        translationPath:'school.titulo'
    },
    medio:{
      default:'School Library Journal',
      translationPath:'school.medio'
    },
    link:'https://www.slj.com/story/kids-books-in-spanish-more-variety-and-authenticity-abound-slj230901',
    img:'/img/bibliotecas-que-es/thumbs-media/thumb1.jpg'
  },
  {
    title:{
        default:'Reseña de la plataforma, a cargo de Selenia Paz.',
        translationPath:'resena_selenia.titulo'
    },
    medio:{
      default:'School Library Journal',
      translationPath:'resena_selenia.medio'
    },
    link:'https://www.slj.com/story/makemake-spanish-ebooks-for-children-database-review',
    img:'/img/bibliotecas-que-es/thumbs-media/thumb2.jpg'
  },
  {
    title:{
        default:'Una mirada al libro en español en los Estados Unidos. ',
        translationPath:'weekly.titulo'
    },
    medio:{
      default:'Publisher’s Weekly',
      translationPath:'weekly.medio'
    },
    link:'https://publishersweekly.es/una-mirada-al-libro-en-espanol-en-los-estados-unidos/',
    img:'/img/bibliotecas-que-es/thumbs-media/thumb3.jpg'
  },
  {
    title:{
        default:'Modelos de negocio innovadores en la industria editorial de América Latina.',
        translationPath:'unesco.titulo'
    },
    medio:{
      default:'CERLALC-UNESCO',
      translationPath:'unesco.medio'
    },
    link:'https://cerlalc.org/wp-content/uploads/2023/05/Cerlalc_PCL_Modelos_innovadores_en_Latam_180523.pdf',
    img:'/img/bibliotecas-que-es/thumbs-media/thumb4.jpg'
  },
  {
    title:{
        default:'Foro #BibliotecasEnCasa',
        translationPath:'infotecarios.titulo'
    },
    medio:{
      default:'Infotecarios',
      translationPath:'infotecarios.medio'
    },
    link:'https://www.youtube.com/watch?app=desktop&v=VleJRL9khjg',
    img:'/img/bibliotecas-que-es/thumbs-media/thumb5.jpg'
  },
  {
    title:{
        default:'Para leer en casa: MakeMake libera libros durante la pandemia.',
        translationPath:'semana.titulo'
    },
    medio:{
      default:'Revista Semana',
      translationPath:'semana.medio'
    },
    link:'https://www.semana.com/libros/articulo/libros-gratis-makemake-libera-el-acceso-a-su-catalogo-de-editoriales-independientes/81176/',
    img:'/img/bibliotecas-que-es/thumbs-media/thumb6.jpg'
  },
  {
    title:{
        default:'Educación y periodismo: desafíos comunes en la era digital.',
        translationPath:'FGGM.titulo'
    },
    medio:{
      default:'Fundación Gabriel García Márquez',
      translationPath:'FGGM.medio'
    },
    link:'https://www.facebook.com/CentroGaboOficial/videos/charla-web-educaci%C3%B3n-y-periodismo-desaf%C3%ADos-comunes-en-la-era-digital/1585376824989013',
    img:'/img/bibliotecas-que-es/thumbs-media/thumb7.jpg'
  }
]