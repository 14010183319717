import { Icon } from "@iconify/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';

/**
 * Elemento contenedor de las migas de pan (links)
 */
const Box = ({ className, children }) => {
    return (<ul className={className ? className : 'd-flex flex-wrap m-0 p-1'}>
        {children}
    </ul>)
}

Box.propTypes = {
    /**
     * cadena de clases css.
     */
    className: PropTypes.string,
    /**
     * elementos hijos que deben de ser de tipo Breadcrumbs.Crumb
     */
    children: PropTypes.any
}


/**
 * Este componente debe ir dentro del componente BreadCrumb.Box, por defecto retorna un link, pero se puede poner un elmeento html personalizado,
 * si se pasa un elemento children las uncias props que seguiran activas seran className, activeClassName y last.  
 */
const Crumb = ({ name, url, className, activeClassName, children, last }) => {
    const location = useLocation();
    const activeClass = activeClassName ? activeClassName : 'font-weight-bold';

    return (
        <li className={`${location.pathname === url && activeClass} ${className ? className : `onbackground-primary--color`}`}>
            {
                children ? children : <Link className="focusable-primary" to={url || ''}>{name || 'N/A'}</Link>
            }
            {
                !last &&
                <Icon className="ml-1 mr-1" icon="material-symbols:arrow-forward-ios-rounded" />
            }
        </li>)
}

Crumb.propTypes = {
    /**
     * texto que se mostrará en la miga.
     */
    name: PropTypes.string,
    /**
     * url del sitio al que debe llevar el link.
     */
    url: PropTypes.string.isRequired,
    /**
     * cadena de clases css.
     */
    className: PropTypes.string,
    /**
     * cadena de clases css que se mostrarán cuando el link está activo,
     *  es decir cuanto la url pasada como props coincide con la actual ubicación en el navegador.
     */
    activeClassName: PropTypes.string,
    /**
   * texto que se mostrará en la miga.
   */
    last: PropTypes.bool,
    /**
  /**
   * elementos hijos de tipo html o react component, si se pasa esta propiedad no se tendran en cuenta las props name y url.
   */
    children: PropTypes.any
}


/**
 * Objeto que devuelve los componentes necesarios para crear una miga de pan.
 */
export const Breadcrumbs = {
    Box,
    Crumb
}
