import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FOOTER_LINKS } from "../data/links";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { store } from "../../Store";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

export const FooterLinks = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const texts = useTranslations({ section: "footer", lang: lang });
	const globalStateContext = useContext(store);
	const state = globalStateContext?.state;
	const isAuth = state?.sesionActiva === "SI";

	const links = [...FOOTER_LINKS];
	return (
		<ul className="my-3 footer-links d-flex align-items-center justify-content-center flex-wrap flex-lg-column align-items-lg-end text-center text-lg-right">
			{links?.map((link, i) => {
				return (
					<li key={i} className="m-2">
						{link.external ? (
							<a
								className="link font-weight-bold focusable-primary"
								key={i}
								href={link?.link}
								target="_blank"
							>
								{_.get(texts, link?.text?.translationPath) || link?.text?.default}
							</a>
						) : (
							<Link
								className="link font-weight-bold focusable-primary"
								key={i}
								to={link?.byAuth === true ? link.link(isAuth) : link.link}
							>
								{_.get(texts, link?.text?.translationPath) || link?.text?.default}
							</Link>
						)}
					</li>
				);
			})}
			<Link to={"/admin/login"}
							className="d-flex my-3 align-items-center justify-content-center  text-center p-2 third-inverse--bg onsecondary-inverse--color font-weight-bold text-uppercase"
							style={{ borderRadius: ".5rem", fontSize:'.8em' }}
						>
							{lang === "es" ? "Iniciar sesión como administrador" : "Admin Login"}
						</Link>
		</ul>
	);
};
