import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { BackButtonHeader } from "./back-button";
import { LibrarySelect } from "./library-select";
import { useContext } from "react";
import { store } from "../../components/Store";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { Helmet } from "react-helmet";

/**
 * Componente de presentación,  que muestra la sección de busqúeda de institución.
 * @returns {JSX.Element} - Sección de búsqueda de instituciones y sedes.
 */
export const InstitutionalSearch = ({}) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	const storeContext = useContext(store);
	const appState = storeContext?.state;
	const fetching = appState.institutionalAuth?.fetchCompleted === true ? false : true;
	const [librarySelectExpanded, setLibrarySelectExpanded] = useState(false);

	return (
		<div className="institutional-auth">
			<Helmet>
                <title>{textos?.metadata?.title || 'MakeMake - Buscar una institución'}</title>
                <meta name="description" content={textos?.metadata?.description || 'Busca tu institución para ingresar a MakeMake y disfruta tu colección.'} />
            </Helmet>
			<div className="relative">
				<div className="bg-shape"></div>
				<div className="container page-content d-flex flex-column align-items-center">
					<BackButtonHeader customLink={"/login"} />
					<h1 className="onsecondary-title mt-5">
						{textos?.busqueda?.titulo || "Empieza a leer con MakeMake."}
					</h1>
					<h1 className="onsecondary-title mb-5">
						{textos?.busqueda?.titulo2 ||
							"Busca tu colegio o biblioteca para registrar tu ingreso:"}
					</h1>
					<LibrarySelect
						onExpand={() => setLibrarySelectExpanded(true)}
						onCollapse={() => setLibrarySelectExpanded(false)}
					/>
					<Link
						className="onsecondary-link mt-5 mx-5 focusable-primary text-center"
						to="/institutional-auth/institution-not-found"
					>
						{textos?.busqueda?.links?.institucion_no_encontrada ||
							"¿No encuentras tu institución?"}
					</Link>
				</div>
			</div>
		</div>
	);
};
