import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Error404 from "../404";
import { TabPanelCuradurias } from "./tab-panel";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import { CancelRequestController } from "../util/cancel-request-controller";
import { CuraduriasAPI } from "../services/api/curadurias.";
import { useState } from "react";
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import _ from "lodash";
import { LATAM_COUNTRIES } from "./latam-countries";

/**
 * Componente principal de Curadurías, contiene todo el enrutamiento.
 */
export const Curadurias = () => {
    return (<>
        <Switch>
            <Route exact path="/curadurias/colecciones" component={MainSection} />
            <Route exact path="/curadurias/series" component={MainSection} />
            <Route exact path="/curadurias/paises" component={MainSection} />
            <Redirect exact from="/curadurias/" to={'/curadurias/colecciones'} />
            <Route component={Error404} />
        </Switch>
    </>)


}

/**
 * Componente que muestra la sección principal de curadurías, se encarga de solicitar los datos desde el API y pasarlos a los diferentes componentes internos.
 */
const MainSection = () => {
    const {textosSubSecciones:textos} = useObtenerSubsecciones('curadurias');
    const [curadurias, setCuradurias] = useImmer({
        colecciones:[],
        series:[],
        paises:[]
    })
    const [loading, setLoading] = useState(true);

    const sortedCountries = (countries) => {
        const lowerCaseLatam = LATAM_COUNTRIES.map(country => country.toLowerCase());
        const latamCountries = [];
        const otherCountries = [];
        countries.forEach(country => {
            if (lowerCaseLatam.includes(country?.nombre?.toLowerCase())) {
                latamCountries.push(country);
            } else {
                otherCountries.push(country);
            }
        });
        const sortedCountries = [
            ..._.sortBy(latamCountries, (country) => country?.nombre),
            ..._.sortBy(otherCountries, (country) => country?.nombre),
        ]
        return sortedCountries;
    }

    const fetchCuradurias = async (abortSignal)=>{
        await CuraduriasAPI.getAll({abortSignal:abortSignal})
        .then(result=>{
            setCuradurias(draft=>{
                draft.colecciones =_.sortBy(result.colecciones, (e)=>e.nombre);
                draft.series = _.sortBy(result.series, (e)=>e.nombre);
                draft.paises = sortedCountries(result.paises);
            })
            setLoading(false);
        })
        .catch(error=>console.log(error))
    }

    useEffect(()=>{
        let cancelRequestController = new CancelRequestController();
        fetchCuradurias(cancelRequestController.signal);
        return ()=>{
            cancelRequestController.abort();
        }
    },[])

    return (
        <section className="w-100 h-100 curadurias-background">
            <section className="curadurias-page container main-content">
                <div className="curadurias-header header-bg p-4 justify-content-center align-items-center">
                    <h1 className="secondary-font onbackground-primary--color">{`${textos?.main?.titulo || 'Curadurías'}`}</h1>
                    <p className="primary-font onbackground-primary--color">{`${textos?.main?.descripcion || 'En esta sección encontrarás colecciones y curadurías creadas cuidadosamente por MakeMake en torno a diferentes temas, series de una editorial o libros de un mismo autor. Además conocerás los países de tus autores favoritos en la categoría "Países".'}`}</p>
                </div>
                {loading ?
                    <div className="p-3 w-100 d-flex justify-content-center align-items-center secondary-inverse--color">
                        <Icon icon="icomoon-free:spinner9" width={60} className={`spinner-rotation`} />
                    </div>
                    :
                    <TabPanelCuradurias curadurias={curadurias} />

                }
            </section>
        </section>

    )
}