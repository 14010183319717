import React from "react";
import { useScheduleEventLinkGenerator } from "../../../hooks/use-schedule-event-link-generator";
import { EventLayer } from "./event-layer";
import { Icon } from "@iconify/react";
import { useTranslations } from "../../../../hooks/use-translations";
import _ from "lodash";

export const ScheduleEvent = ({ eventData, visible, setVisible, lang }) => {
	const texts = useTranslations({ section: "comunidad_club", lang: lang });
	const scheduleEventLinkGenerator = useScheduleEventLinkGenerator({
		eventData: eventData,
		lang,
	});
	const outlookLink = scheduleEventLinkGenerator?.outlookLink();
	const gmailLink = scheduleEventLinkGenerator?.gmailLink();
	const offce365link = scheduleEventLinkGenerator?.office365Link();
	const yahooLink = scheduleEventLinkGenerator?.yahooLink();
	return (
		<EventLayer
			title={_.get(texts, "makemake_en_vivo.evento.boton.agendar") || "Agendar"}
			visible={visible}
			setVisible={setVisible}
		>
			<div className="schedule-event d-flex w-100 flex-column flex-grow-1">
				<a
					className="focusable-red schedule-event-link my-1 d-flex justify-content-start align-items-center"
					href={gmailLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Icon className="mx-2" icon="skill-icons:gmail-light" width={30} /> Gmail
				</a>
				<a
					className="focusable-red schedule-event-link my-1 d-flex justify-content-start align-items-center"
					href={outlookLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Icon className="mx-2" icon="vscode-icons:file-type-outlook" width={30} />{" "}
					Outlook
				</a>
				<a
					className="focusable-red schedule-event-link my-1 d-flex justify-content-start align-items-center"
					href={offce365link}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Icon className="mx-2 office-365" icon="mdi:microsoft-office" width={30} />{" "}
					Office 365
				</a>
				<a
					className="focusable-red schedule-event-link my-1 d-flex justify-content-start align-items-center"
					href={yahooLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Icon className="mx-2 yahoo" icon="foundation:social-yahoo" width={30} /> Yahoo!
				</a>
			</div>
		</EventLayer>
	);
};
