import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { store } from "../components/Store"
import { LibrosAPIs } from "../services/api/Libros";

/**
 * Este hook permite acceder a la info de la ficha de un libro, gestiona las peticiones y almacena en el store las fichas en el store que ya se abrieron para mejorar el performance y evitar peticiones innecesarias.
 * @param {*} id 
 * @returns 
 */
export const useBookData = (id) => {
    const globalState = useContext(store);
    const { dispatch, state } = globalState;
    const [savedInStore, setSavedInStore] = useState(undefined);
    const [metadata, setMetadata] = useState({});
    const [recommendedBooks, setRecommendBooks] = useState([]);
    const [recommendedTags, setRecommendedTags] = useState([]);
    const [loadingBookData, setLoadingBookData] = useState(true);
    const [loadingRecommendedBooks, setLoadingRecommendedBooks] = useState(true);
    const [loadingRecommendedTags, setLoadingRecommendedTags] = useState(true);

    const getBookDataFromStore = () => {
        return state?.fichasLibros?.find(book => book?.idLibro === id);
    }

    const saveInStore = useCallback((data)=>{
        const currentbook = getBookDataFromStore(id);
        if(currentbook){
          dispatch({
            type: "CHANGE_VALUE",
            property: "fichasLibros",
            value: [...state?.fichasLibros.filter(ficha=>ficha?.idLibro!==id), {...currentbook, ...data}],
          });
        }else{
          dispatch({
            type: "CHANGE_VALUE",
            property: "fichasLibros",
            value: [...state?.fichasLibros, {idLibro:id, ...data}],
          });
        }
    
      }, [id, state?.fichasLibros])

      
    const fetchData = async ()=>{

      let _book = {
        metadata:{},
        recommendedBooks:[],
        recommendedTags:[]
      }
        await LibrosAPIs.obtenerDatosFichaLibro({abortSignal:null, idLibro:id})
        .then(book=>{
          _book.metadata = book;
          setLoadingBookData(false)
          setMetadata(_book?.metadata);
        })
        .catch(error=>console.log(error))

        await LibrosAPIs.obtenerRecomendados({abortSignal:null, idLibro:id})
        .then(recommended=>{
          _book.recommendedBooks = recommended;
          setLoadingRecommendedBooks(false);
          setRecommendBooks(_book?.recommendedBooks);
        })
        .catch(error=>console.log(error))
        
        await LibrosAPIs.obtenerTemas({ abortSignal: null, idLibro: id })
        .then(tags => {
          _book.recommendedTags = tags;
          setLoadingRecommendedTags(false);
          setRecommendedTags(_book?.recommendedTags);
        })
        .catch(error => console.log(error))
        saveInStore({..._book})
    }

    useEffect(()=>{
        const saved = state?.fichasLibros?.find(book => book?.idLibro === id) ? true : false;
        setSavedInStore(saved);
    },[id, state?.fichasLibros?.length])


    useEffect(() => {
        const bookData = getBookDataFromStore();
        if (savedInStore===true) {
          setMetadata(bookData?.metadata)
          setRecommendBooks(bookData?.recommendedBooks)
          setRecommendedTags(bookData?.recommendedTags)
          setLoadingBookData(false);
          setLoadingRecommendedBooks(false);
          setLoadingRecommendedTags(false);
        } else if(savedInStore===false){
            setLoadingBookData(true);
            setLoadingRecommendedBooks(true);
            setLoadingRecommendedTags(true);
            fetchData();
        }

    }, [id, savedInStore])


    return {
        id,
        metadata: metadata || {},
        recommendedBooks: Array.isArray(recommendedBooks)?recommendedBooks: [],
        recommendedTags: Array.isArray(recommendedTags)?recommendedTags: [],
        loading:{
            recommendedBooksData:loadingRecommendedBooks,
            bookData:loadingBookData,
            recommendedTagsData: loadingRecommendedTags
        },
    }
}