import React, { useEffect, useState } from "react";
import { Switch, Route, useParams } from "react-router-dom";
//Componentes
import IngresarCorreo from './IngresarCorreo';
import DatosInstitucion from './DatosInstitucion';
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Componente que muestra la información institucional de MakeMake en diferentes pasos, tambíen se encarga de manejar el enrutamiento de toda esta sección, es el componente principal (index).
 */
const InformacionInstitucional = () => {
  const { isReady, textosSubSecciones: textosInfo } = useObtenerSubsecciones('informacion');

  const pasos = [
    {
      url: '',
      nombre: '¿Ya estás en MakeMake?',
      traduccion: 'paso_uno',
    },
    {
      url: 'ingresar-correo',
      nombre: 'Ingresa tus datos',
      traduccion: 'paso_dos',
    },
    {
      url: 'datos-institucion',
      nombre: 'Datos de tu institución',
      traduccion: 'paso_tres',
    }
  ];

  const [pasoActivo, setPasoActivo] = useState(0)

  const param = useParams();
  const { section } = param;

  useEffect(() => {
    const pasoUrl = pasos.findIndex(paso => paso.url === section);
    setPasoActivo(pasoUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  return (
    <>
      {isReady &&
        <div className="main-container mm-redimir" style={{minHeight: '70vh'}}>
          <div className="container main-content surface--bg px-0 pb-0">
            <ol className="mm-redimir_steps p-0 mb-5 mx-5 mx-md-2 mx-lg-5 row justify-content-between align-items-start">
              {
                pasos.map((paso, index) => (
                  <>
                    <li key={`pasos_informacion${index}`} aria-current={pasoActivo === index ? 'step' : false} >
                      <div className="cont-paso">
                        <p className="mx-auto text-center onbackground-third--color">
                          {
                            index < pasoActivo
                              ? <i style={{ color: '#F0581F', fontSize: 24 }} className="fas fa-check-circle fa-fw mr-2"></i>
                              : <span className={`step mr-2 ${pasoActivo === index ? 'step-active' : ''}`}>{index + 1}</span>
                          }
                          {textosInfo?.informacion?.pasos[paso.traduccion] || paso.nombre}
                        </p>
                      </div>

                      {index + 1 !== pasos.length && <hr aria-hidden={true} />}
                    </li>
                  </>
                ))
              }
            </ol>


            <div className="contenedor-altura-minima">
              <Switch>
                <Route exact path='/informacion/ingresar-correo' component={IngresarCorreo} />
                <Route exact path='/informacion/datos-institucion' component={DatosInstitucion} />
                {/* <Route exact path='/informacion/datos-institucion' component={() => <DatosInstitucion />} /> */}
              </Switch>
            </div>

          </div>
        </div>
      }
    </>
  )
}


export default InformacionInstitucional;