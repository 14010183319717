import { useEffect, useState } from "react";
import { useUserBooks } from "../../hooks/useUserBooks";
import { READING_PLANS } from "../data/reading-plans";

export const useReadingPlan = ({ month }) => {
	const booksData = useUserBooks();
	const [plan, setPlan] = useState({
		theme: { en: "", es: "" },
		question: { en: "", es: "" },
		books: [],
        month:''
	});

	const loadPlan = (month) => {
		let _plan = READING_PLANS?.[month];
		if (_plan) {
			_plan = {
                month,
				theme: { ..._plan?.tema },
				question: { ..._plan?.pregunta },
				books: [..._plan?.id_libros],
			};
            setPlan(_plan);
		}
	};

	useEffect(() => {
		loadPlan(month);
	}, [month]);

	return {
		plan,
		loading: booksData?.loadCompleted ? false : true,
	};
};
