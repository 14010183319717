import React, { useContext, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ModoPWAContext } from "../contexts/ModoPwaContext";
import { useNetworkStatus } from "../hooks/use-network-status";
import { useSesionActual } from "../hooks/useSesionActual";
import { BarraConexion } from "./BarraConexion";
import { SinConexionPopup } from "./SinConexionPopup";
import { store } from "./Store";
import { SplashScreen } from "./splash-screen";

/**
 * Componente para manejar la conexión a Internet, decide que mostrar con respecto al estado de conexión.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {React.ReactNode} props.children - Componentes hijos.
 * @returns {JSX.Element} Componente de ManejadorConexionInternet.
 */
export const ManejadorConexionInternet = ({ children }) => {
	const globalState = useContext(store);
	const { dispatch } = globalState;
	const history = useHistory();
	const location = useLocation();
	const conexion = useNetworkStatus();
	const isAuth = globalState?.state?.sesionActiva === "SI";
	const sesionActual = useSesionActual();
	const pwaState = useContext(ModoPWAContext);
	const prevIsOnline = useRef(undefined);

	useEffect(() => {
		if (
			conexion?.online === true ||
			(conexion?.online === false && sesionActual?.autenticado === true)
		) {
			document.getElementsByTagName("html")[0].style.overflow = "auto";
		} else {
			document.getElementsByTagName("html")[0].style.overflow = "hidden";
		}
	}, [conexion?.online, sesionActual.autenticado]);

	useEffect(() => {
		if (
			sesionActual.autenticado === true &&
			conexion.online === false &&
			pwaState?.clienteHabilitado === true &&
			location.pathname !== "/descargados" &&
			location.pathname.startsWith("/visor") === false
		) {
			history.push("/descargados");
		}
		// console.log('TEST', history.location.pathname, sesionActual?.autenticado, conexion.online)
	}, [
		conexion.online,
		history,
		location.pathname,
		pwaState.clienteHabilitado,
		sesionActual.autenticado,
	]);

	useEffect(() => {
		if (conexion.online && prevIsOnline.current === false) {
			window.location.reload();
		}
		return () => {
			prevIsOnline.current = conexion.online;
		};
	}, [conexion.online]);

	return (
		<>
			{conexion.online === undefined ? (
                <SplashScreen/>
			) : (
				<>
					{conexion.online ? (
						<>{children}</>
					) : (
						<>
							{isAuth ? <>{children}</> : <SinConexionPopup />}
						</>
					)}
					<BarraConexion />
				</>
			)}
		</>
	);
};
