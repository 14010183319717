import React from "react";

/**
 * El componente Accesibility representa una sección de la página "Estándares Abiertos para Integraciones en EdTech" relacionada con la accesibilidad.
 * Renderiza un iframe que muestra contenido HTML específico para la accesibilidad.
 *
 * @component
 * @example
 * // Uso del componente Accesibility
 * <Accesibility />
 *
 * @returns {JSX.Element} - El componente Accesibility.
 */
export const Accesibility = () => {
    return (<div className="w-100 overflow-auto">
        <iframe className="w-100 onbackground-primary--bordered" src="/edtech/accesibility.html" style={{height:'1000px'}}/>
    </div>)
}