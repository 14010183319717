import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { store } from "./Store";
/**
 * Componente para mostrar una ventana emergente de descarga de libro.
 *
 * @component
 * @returns {JSX.Element} Elemento JSX que representa el componente.
 */
export const BookDownloadingPopUp = () => {
    const { textosSubSecciones: textos } = useObtenerSubsecciones('global');
    const { state, dispatch } = useContext(store);


    useEffect(() => {
        let timer = setTimeout(() => {
            if (state?.errorDescargandoLibro) {
                dispatch({ type: "CHANGE_VALUE", property: "descargando", value: false });
            }
        }, 5000);
        return () => {
            clearTimeout(timer);
        }
    }, [state?.errorDescargandoLibro])


    return <div className="book-download-progress pt-3 pb-4" role="alert" aria-live="assertive">
        {
            state?.errorDescargandoLibro ?
                <div className="mx-auto book-download-alert py-2 mb-2" >
                    <p>{textos?.popup_descargando_libro?.error_descargando || 'Ocurrio un error en la descarga del libro, vuelve a intentarlo.'}</p>
                </div>
                :
                <>
                    <div className="mx-auto book-download-alert py-2 mb-2" >
                        <p>{textos?.popup_descargando_libro?.advertencia || 'No cierres esta ventana hasta que se descargue el libro'}</p>
                    </div>
                    <div className="mx-auto book-download-info">
                        <div className=" d-flex mb-2 align-items-center">
                            <div className="progress col-12 px-0">
                                <div
                                    className="progress-bar"
                                    style={{ width: `${state.progresoDescarga}` }}
                                    aria-hidden={true}
                                />
                            </div>
                            {/* <i className="ml-3 fas fa-times-circle" onClick={() => {state.abortCtrl.abort(); console.log("Abortando"); console.log(state.abortCtrl)}}></i> */}
                        </div>
                        <p>
                            {textos?.popup_descargando_libro?.descargando || 'Descargando'} {state.tituloDescargando}{" "}
                            <span
                                className="progress-number bold"
                                aria-hidden={true}
                            >
                                {state.progresoDescarga}
                            </span>
                        </p>
                    </div>
                </>
        }

    </div>
}