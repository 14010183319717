import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { DATA_AWARDS } from "../data/awards";
import { Img } from "../../../components/shared/img";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente de "Premios y reconocimientos" para que es principal
 * @returns {JSX.Element} Componente Awards.
 */
export const Awards = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const awards = [...DATA_AWARDS];

	return (
		<div className="awards surface--bg py-4 onbackground-primary--color">
			<div className="container p-3">
				<div className=" d-flex flex-nowrap justify-content-center align-items-center">
					<p className="flex-grow publisher-title secondary-inverse--color">
						{textos?.premios?.titulo || "Premios y Reconocimientos"}
					</p>
					<div
						className="flex-grow-1 secondary-inverse--bg ml-4"
						style={{ height: 1 }}
					></div>
				</div>

				<div className="row">
					{awards.map((item, index) => {
						return (
							<div className="col-12 col-sm-6 col-md-3" key={`award${index}`}>
								<div 
									className="d-flex flex-column justify-content-center align-items-center"
									style={{ height: 130}}
								>
									<a
										className={`mt-2 ${item?.link && "active"}`}
										style={{ maxWidth: 200, maxHeight: 200, backgroundColor: "#fff" }}
										href={item?.link}
										target="_blank"
										rel={"noopener noreferrer"}
									>
										<Img
											className="img-fluid"
											src={item?.img}
											alt={item?.alt}
										/>
									</a>
								</div>
								<p
									className="text-center onbackground-primary--color font-secondary font-weight-bold"
									style={{ fontSize: "0.9em" }}
								>
									{item?.year || "N/A"}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
