
import React, { useContext } from "react"
import { DefaultHome } from "./default-home";
import { IndividualUserHome } from "./individual-user-home";
import { UserHome } from "./user-home";
import { store } from "../components/Store";
import { memo } from "react";
import { AppInstallButton } from "../components/app-install-button";
import { useUserHomeData } from "../hooks/home/use-user-home";
import SkeletonHome from "../skeletons/SkeletonHome";

/**
 * Componente principal que se encargá de renderizar el 'home' correspondiente según el tipo de usuario y el estado de autenticación, asi como también de pasar la información necesaria.
 * @returns {JSX.Element} Elemento JSX que representa la página de inicio.
 */
export const Home = memo(() => {

    const globalState = useContext(store);
    const isAuthenticatedUser = globalState?.state?.sesionActiva === 'SI';
    const isIndividualUser = globalState?.state?.cantidadLibros < 40 && globalState?.state?.cantidadLibros >=0;
    const loading = globalState?.state?.userBooksData?.loading && globalState?.state?.userBooksData?.loadCompleted === false && globalState?.state?.cantidadLibros === -1;
    const homeData = useUserHomeData();

    return (<>
    {
        loading?
        <SkeletonHome />
        :
        <>
           {
            !isAuthenticatedUser && globalState?.state?.userBooksData?.loadCompleted &&
            <DefaultHome data={homeData} />
        }
        {
            isAuthenticatedUser && !isIndividualUser && globalState?.state?.userBooksData?.loadCompleted &&
            <UserHome data={homeData} />
        }
        {
            isAuthenticatedUser && isIndividualUser && globalState?.state?.userBooksData?.loadCompleted &&
            <IndividualUserHome />
        }
        </>

    }
     
        <AppInstallButton />
    </>)
})