import { isJSON } from "../util/isJSON";

/**
 * Hook que permite obtener los libros favoritos
 * @param {string} userName - nombre del usuario
 * @returns {Array} - arreglo con los ids de los libros marcados como favoritos
 */
export const useFavoritesBooks = ({ userName }) => {

  let favoritesBooks = [];
  const validStorage = localStorage.getItem("FavoritesBooks") && isJSON(localStorage.getItem("FavoritesBooks"));

  if(validStorage){
    const _usersStorage = JSON.parse(window.localStorage.getItem("FavoritesBooks"));
    const user = userName ? userName : 'N/A'
    const existingUser = user in _usersStorage;
  
    if (existingUser) {
      const books = _usersStorage[user];
      favoritesBooks = Array.isArray(books) ? books : [];
    }    
  }

  return favoritesBooks;

}