import React from "react";
import { SectionTitle } from "./section-title";

export const BenefitsSection = () => {
	const images = [
		"/img/piloto-rnbp/beneficio1.png",
		"/img/piloto-rnbp/beneficio2.png",
		"/img/piloto-rnbp/beneficio3.png",
		"/img/piloto-rnbp/beneficio4.png",
	];
	return (
		<section className="p-3">
			<SectionTitle title="Beneficios" />

			<div className="row p-3 pt-5 justify-content-center align-items-center"  style={{background:'url(/img/backs/books.svg)'}}>
				{images.map((image, index) => (
					<div key={index} className="d-flex justify-content-center align-items-center col-12 col-sm-6 col-lg-3 p-2 overflow-hidden" >
						<img className="img-fluid" src={image} alt={`"beneficios-"${index}`} style={{borderRadius:'.5rem'}}/>
					</div>
				))}
			</div>
		</section>
	);
};
