import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { store } from "../components/Store";

/**
 * Este link se deshabilita cuando el usuario tiene una colección pequeña, por lo tanto no redirige a ningun lado mientras sea colección pequeña, si no lo es todas sus props y estado se ejecutarán con normalidad.
 * @param {*} props todas las props de un Element Link de react router.
 */
export const ProtectedLinkFicha = (props) => {
	const globalState = useContext(store);
	const isSmallCollection = globalState?.state?.cantidadLibros < 40;

	return (
		<Link
			{...props}
            style={{...props?.style, pointerEvents:isSmallCollection?"none":props?.style?.pointerEvents}}
			onClick={(e) => {
				if (isSmallCollection) {
					e.preventDefault();
					return false;
				} else {
					props?.onClick && props.onClick(e);
				}
			}}
		></Link>
	);
};
