import React from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

import { BaseModal } from "../../components/modals/base-modal";

/**
 * Ventana modal que indica inicio de sesión
 * @param {Object} props 
 * @returns {JSX.Element} EventsLogInRequest
 */
export const EventsLogInRequest = ({ visible, setVisible, redirectionParam, textosInterfaz }) => {
    const history = useHistory();

    return (
        <BaseModal
            windowClassName={"default-modal-window col-10 col-sm-9 col-md-6 col-lg-6 col-xl-3"}
            visible={visible}
            setVisible={setVisible}
            backdropClose={true}
        >
            <div className="d-flex flex-row-reverse">
                <button
                    aria-label="Cerrar ventana de dialogo"
                    className="close-button onbackground-primary--color focusable-red"
                    data-micromodal-close
                    onClick={() => setVisible && setVisible(false)}
                >
                    <Icon icon={'mdi:close-thick'} width={35} />
                </button>
            </div>
            <div className="modal__content my-2" id="dialogo-modal-alertas">
                <p
                    className="second-category-item no-lineheight text-center mb-3 secondary-inverse--color"
                    id="alert-message"
                >
                    {textosInterfaz?.exclusivo?.title || "¡Contenido exclusivo!"}
                </p>
                <p className="mb-3 text-center onbackground-third--color" id="alert-description">
                    {textosInterfaz?.exclusivo?.description || "Para registrarte a este evento inicia sesión."}
                </p>
            </div>

            <button
                className="btn btn-primary d-block mx-auto focusable-red"
                onClick={() => {
                    history.push({ pathname: '/login', search: `?redirectto=${encodeURIComponent(redirectionParam || '/')}` });
                    setVisible && setVisible(false);
                }}
            >
                {textosInterfaz?.exclusivo?.button || "Iniciar sesión"}
            </button>

        </BaseModal>
    )
}