import React, { useContext, useState, useEffect, useRef } from "react";
import getAjax from "../js/utilities/getAjax";
import postAjax from "../js/utilities/postAjax";
import { Link, useLocation } from "react-router-dom";
import {
  GENERAL_URL_API,
  ASSETS_URL,
  store,
  STREAMER_URL_API,
} from "../components/Store.js";
import { Icon } from "@iconify/react";
import MicroModal from "micromodal";
/* import 'animate.css'; */
import { ModalVisor } from "../components/modals/ModalVisor";

import HTMLFlipBook from "react-pageflip";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import getUrlParam from "../js/utilities/getUrl";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { ModalCompartir } from "../components/modals/modal-compartir";
import { FocusScope } from "react-aria";
import { ReactReader } from "react-reader";
import { ReadingLimitModal } from "../components/modals/modal-reading-limit";
import { SessionLimitModal } from "../components/modals/modal-session-limit";

import { BookTypeModal } from "../components/modals/modal-tipo-libro";
import { IncompatibleBook } from "../components/modals/modal-libro-incompatible";
import { useQueryParams } from "../hooks/useQueryParams";
import { setStatsLibro } from "../js/functions/estadisticas";
import { SesionAPI } from "../services/api/Sesion";
import { AccesibilidadContext } from "../contexts/AccesibilidadContext.js";

const screenfull = require("screenfull");
let statsTimer = null;

/**
 * Este componente muestra el visor de libros. Su función principal es obtener la información del libro basado en el código de la url (ej: MM0248). A partir de esto, reconoce el formato del libro e incrusta el lector correspondiente del libro.  Para esto hace uso de las APIS /assets/visor, /assets/marcador. También usa componentes como ModalCompartir y ModalVisor para las ventanas modales.
 * @param {object} props
 * @returns {JSX.Element} Visor
 */
const Visor = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;

  const { isReady, textosSubSecciones: visor } =
    useObtenerSubsecciones("visor");

  const [isLoading, setIsloading] = useState(true);
  const [datosLibro, setDatosLibro] = useState("");
  const [manifest, setManifest] = useState("");
  const [fontSize, setFontSize] = useState("130%");
  const [renditionPointer, setRenditionPointer] = useState(null);
  const [location, setCurrentLocation] = useState(0);
  const [isShowFontSize, setIsShowFontSize] = useState(false);
  const [isShowDescubre, setisShowDescubre] = useState({
    animation: false,
    appear: false,
  });
  const visorScreen = useRef(null);
  const [isScreenFull, setIsScreenFull] = useState(false);
  /*   const [isShowFontSize, setIsShowFontSize] = useState(false); */
  const [sameLocation, setSameLocation] = useState(false);

  const botonDescubre = useRef(null);
  const visorHeader = useRef(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageFlip, setPageFlip] = useState(null);
  const [cookies, setCookie] = useCookies(["marcadores"]);
  const [isOverBtn, setIsOverBtn] = useState(false);

  const [mostrarBlop, setMostrarBlop] = useState(false);
  const [showSessionLimitModal, setShowSessionLimitModal] = useState(false);
  const [showReadingLimitModal, setShowReadingLimitModal] = useState(false);
  const [showBookTypeModal, setBookTypeModal] = useState(false);
  const [showIncompatibleBook, setIncompatibleBook] = useState(false);
  const queryParams = useQueryParams();
  const mobileAppMode = state?.visor?.mobileAppMode;
  const isAuth = state?.sesionActiva === 'SI' ? true : false;
  const history = useHistory();
  const currentLocation = useLocation();

  const [esTemaClasico, setEsTemaClasico] = useState(true);
  const accebilidadState = useContext(AccesibilidadContext);

   /*Actualiza el estado de EsTemaClasico. */
   useEffect(() => {
    let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
    setEsTemaClasico(tema);
  }, [accebilidadState])

  const handleAppAuth = async () => {
    const hash = queryParams.get('apphash');
    if (currentLocation.pathname.includes('visor') && hash && mobileAppMode && isAuth === false) {
      await SesionAPI.cerrarSesion()
        .catch(error => console.log(error));
      await SesionAPI.hashSignIn({ abortSignal: null, data: { hash: hash } })
        .then(result => {
          console.log('APP HASH AUTH: SUCCESS', result)
          loadBookData();
        })
        .catch(error => {
          console.log('APP HASH AUTH: ERROR', error)
        })
    }
  }

  useEffect(() => {
    handleAppAuth();
  }, [currentLocation, queryParams, mobileAppMode, isAuth])

  //Abre la ventana modal de compatibilidad en dispositivos móviles
  useEffect(() => {
    if (window.innerWidth < 992) {
      if (datosLibro.compatibilidad === "desktop" || datosLibro.compatibilidad === "Solo desktop") {
        setIncompatibleBook(true);
      }
    }
  }, [datosLibro.compatibilidad])

  useEffect(() => {
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) { return; }

      if (screenfull.isFullscreen === false) {
        setIsScreenFull(false);
      }
    }
  }, [screenfull.isEnabled, screenfull.isFullscreen])


  // Recopilar las estadisticas del libro cada 21 segundos.
  useEffect(() => {
    statsTimer = setInterval(() => {
      setStatsLibro();
    }, 21 * 1000);
    return () => {
      clearInterval(statsTimer);
    }
  }, [])

  // Recopila las estadisticas al cargarse el visor, cancelar el statsTimer si hay un límite de concurrencias o de lecturas.
  useEffect(() => {
    if (showReadingLimitModal === false && showSessionLimitModal === false) {
      setStatsLibro();
    }
    if (showReadingLimitModal || showSessionLimitModal) {
      console.log('Cancelando stats del libro.');
      clearInterval(statsTimer);
    }
  }, [showReadingLimitModal, showSessionLimitModal])

  // Con base a el query param 'mode' verifica si está o no en modo aplicación movil y actualiza el estado global (visor.mobileAppMode) para que se oculten o muestren ciertas partes del visor.
  useEffect(() => {
    const param = queryParams.get('mode');
    if (param === 'mobileapp') {
      globalState.dispatch({
        type: 'CHANGE',
        path: 'visor.mobileAppMode',
        value: true
      })
    } else {
      globalState.dispatch({
        type: 'CHANGE',
        path: 'visor.mobileAppMode',
        value: false
      })
    }
  }, [queryParams])

  // al finalizar el ciclo de vida del componente establece nuevamente el modo de aplicación a falso.
  useEffect(() => {
    return () => {
      globalState.dispatch({
        type: 'CHANGE',
        path: 'visor.mobileAppMode',
        value: false
      })
    }
  }, [])



  useEffect(() => {
    //EVITAR LA DESCARGA DE IMAGENES CON EL CLICK DERECHO
    const cancelRightClickActions = () => {
      document.addEventListener(
        "contextmenu",
        (e) => {
          e.preventDefault();
        },
        true
      );
    };
    cancelRightClickActions();
  }, []);

  //muestra el personaje Blop después de 4 min
  useEffect(() => {
    if (mostrarBlop === false) {
      setTimeout(() => {
        setMostrarBlop(true);
      }, 1000 * 240); //4min
    }
  }, []);

  const loadBookData = () => {
    let id = props.match.params.idLibro;
    getAjax(`${GENERAL_URL_API}/assets/visor`, { idLibro: id }, (response) => {
      let _data = {};
      try {
        _data = JSON.parse(response);
        if (_data?.error) {
          if (_data?.error?.toLowerCase().includes('concurrencias')) {
            setShowSessionLimitModal(true);
          }
          else if (_data?.error?.toLowerCase()?.includes('lecturas')) {
            setShowReadingLimitModal(true);
          }
        } else {
          setDatosLibro(_data);
          setIsloading(false);
        }
      } catch (error) {
        console.log('visor error: respuesta del api de visor con un formato json no valido.');
      }
      //setCurrentLocation(null);//Aqui se puede poner la ubicacion de inicio del libro
      //cacheBook(JSON.parse(response));//Aqui se puede poner la ubicacion de inicio del libro
    });

    if (navigator.onLine) {
      getAjax(
        `${GENERAL_URL_API}/assets/marcador`,
        { idLibro: id },
        (response) => {
          if (
            response !== "Solo cookie" &&
            response !== "No marcador" &&
            response !== "Error"
          )
            setCurrentLocation(response); //Aqui se puede poner la ubicacion de inicio del libro
        }
      );
    }
  }

  //obtiene la información del libro
  useEffect(() => {
    loadBookData();
  }, [props.match.params.idLibro]);

  //guarda la información del libro eb getManifest
  useEffect(() => {
    if (datosLibro?.formato === "epub") {
      getAjax(
        STREAMER_URL_API + `/getManifest`,
        { url: datosLibro.Archivos[0] },
        (response) => {
          const res = JSON.parse(response);
          setManifest(res.manifest);
          //setCurrentLocation(null);//Aqui se puede poner la ubicacion de inicio del libro
          //cacheBook(JSON.parse(response));//Aqui se puede poner la ubicacion de inicio del libro
        }
      );
    }
  }, [datosLibro]);

  useEffect(() => {
    positionDescubre();
  });

  useEffect(() => {
    let jsonMarcadores = cookies.marcadores;
    if (jsonMarcadores) {
      if (jsonMarcadores[props.match.params.idLibro])
        setCurrentLocation(jsonMarcadores[props.match.params.idLibro]);
    }
  }, [props.match.params.idLibro, cookies]);

  useEffect(() => {
    positionDescubre();
    window.addEventListener("resize", positionDescubre);
    document.body.addEventListener("resize", positionDescubre);
    return () => {
      window.addEventListener("resize", positionDescubre);
      document.body.addEventListener("resize", positionDescubre);
    };
  }, []);

  const guardarMarcador = (marcador) => {
    let idLibro = props.match.params.idLibro;
    let jsonMarcadores = cookies.marcadores;
    if (!jsonMarcadores) {
      jsonMarcadores = {};
    }

    jsonMarcadores[idLibro] = marcador;

    setCookie("marcadores", jsonMarcadores, { path: "/" });

    postAjax(
      `${GENERAL_URL_API}/assets/marcador`,
      { idLibro: idLibro, marcador: marcador },
      (response) => {
        if (response.includes("Marcador Guardado")) {
          //console.log("guardado el marcador: ", marcador);
        } else {
          //console.log(response)
        }
      }
    );
  };

  const increaseFontSize = () => {
    let num = parseInt(fontSize.replace(/[^0-9]/g, ""), 10);
    num += 10;
    setFontSize(num + "%");
    renditionPointer.themes.fontSize(num + "%");
  };

  const decreaseFontSize = () => {
    let num = parseInt(fontSize.replace(/[^0-9]/g, ""), 10);
    num -= 10;
    setFontSize(num + "%");
    renditionPointer.themes.fontSize(num + "%");
  };

  const getRendition = (rendition) => {
    // Set inital font-size, and add a pointer to rendition for later updates
    const spine_get = rendition.book.spine.get.bind(rendition.book.spine);
    rendition.book.spine.get = function (target) {
      let t = spine_get(target);
      while (t == null && target.startsWith("../")) {
        target = target.substring(3);
        t = spine_get(target);
      }
      return t;
    };
    setRenditionPointer(rendition);
  };

  const onLocationChanged = (epubcifi) => {
    setCurrentLocation(epubcifi);
    let idLibro = props.match.params.idLibro;
    if (cookies.marcadores) {
      if (cookies.marcadores[idLibro]) {
        if (epubcifi == cookies.marcadores[idLibro]) {
          setSameLocation(true);
        } else {
          setSameLocation(true);
        }
      }
    }
  };
  const cacheBook = (datosLibroInt) => {
    datosLibroInt.Archivos.forEach((archivo) => fetch(`/makemake${archivo}`));
    //fetch(`${ASSETS_URL}${datosLibroInt.Archivos[0]}`);
    //console.log("cacheado el libro");
  };

  const PageFlip = React.forwardRef((props, ref) => {
    return <img alt="" ref={ref} src={props.src} page={props.page} />;
  });

  /**
   * Guarda la pagina actual si el libro es jpgNuevo
   * @param {Event} e
   */
  const onPage = (e) => {
    setCurrentPage(e.data);
    //console.log("Pagina= " + e.data);
  };

  /**
   * Según el formato del libro se incrusta el contenido del libro
   * @returns {HTMLElement} renderVisor
   */
  const renderVisor = () => {
    if (datosLibro.formato === "epub") {
      if (!navigator.onLine || mobileAppMode) {
        return (
          <div style={{ position: "relative", height: "100%" }}>
            <ReactReader
              url={`${origin === 'https://makemake.com.co' ? `/makemake${datosLibro.Archivos[0]}` : `https://rc.makemake.reallabs.com.co${datosLibro.Archivos[0]}`}`}
              title={datosLibro.titulo}
              location={location}
              locationChanged={onLocationChanged}
              getRendition={getRendition}
              className="algo"
            />
            <div className="title-controls">
              <button
                className="btn btn-default settings-button mr-2 px-2"
                disable={sameLocation}
                onClick={() => guardarMarcador(location)}
              >
                <i className="fas fa-bookmark"></i>
              </button>
              <button
                className="btn btn-default settings-button"
                onClick={() => setIsShowFontSize(!isShowFontSize)}
              >
                <i className="fas fa-cog"></i>
              </button>
            </div>
            {isReady && isShowFontSize && (
              <>
                <div className="font-size-modal">
                  <div className="">
                    <div className="d-flex justify-content-center align-items-center titulo-texto">
                      <h3 className="col-12 py-0">
                        {visor?.ajustes?.titulo || "Ajustes"}
                      </h3>
                      <i
                        className="fas fa-times-circle cerrar"
                        onClick={() => setIsShowFontSize(false)}
                      />
                    </div>
                    <div className="tamano-texto">
                      <span className="mr-4">
                        {visor?.ajustes?.tamanio || "Tamaño del texto"}
                      </span>
                      <button
                        className="size-font"
                        onClick={() => decreaseFontSize()}
                      >
                        <img
                          alt="Reducir letra"
                          className="smaller-font"
                          src={`/img/menos.png`}
                        />
                      </button>
                      <button
                        className="size-font"
                        onClick={() => increaseFontSize()}
                      >
                        <img
                          alt="Aumentar letra"
                          className="bigger-font"
                          src={`/img/mas.png`}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="overlay"
                  onClick={() => setIsShowFontSize(false)}
                ></div>
              </>
            )}
          </div>
        );
      } else {
        return (
          <iframe
            title="Lector"
            style={{ pointerEvents: "auto", border: "none" }}
            id="cuadro"
            src={`/epub-standalone/?manifest=${manifest}&titulo=${datosLibro.titulo}`}
            height="100%"
            width="100%"
            allowFullScreen=""
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            oallowfullscreen="true"
            msallowfullscreen="true"
          ></iframe>
        );
      }
    } else if (datosLibro.formato === "jpgNuevo") {
      // los jpgNuevos son una prueba. En la bd no hay estos libros
      return (
        <div style={{ position: "relative", height: "100%" }}>
          {" "}
          <HTMLFlipBook
            width={datosLibro.image_width}
            height={datosLibro.image_height}
            size="stretch"
            showCover={true}
            minWidth={315}
            maxWidth={2000}
            minHeight={400}
            maxHeight={2000}
            onFlip={onPage}
            mobileScrollSupport={true}
            autoSize={true}
            ref={(component) => setPageFlip(component)}
          >
            {datosLibro.Archivos.map((archivo, i) => (
              <img
                alt="pagina"
                key={`${ASSETS_URL}${archivo}`}
                page={i}
                src={`${ASSETS_URL}${archivo}`}
              ></img>
            ))}
          </HTMLFlipBook>
        </div>
      );
    } else if (datosLibro.formato === "pdf") {
      return (
        <iframe
          title="Lector"
          style={{ pointerEvents: "auto" }}
          id="cuadro"
          src={`/standalone/visor/?idLibro=${datosLibro.idLibro}&api_endpoint=${GENERAL_URL_API}${mobileAppMode === true ? '&mode=mobileapp' : ''}`}
          height="100%"
          width="100%"
          allowFullScreen=""
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
        ></iframe>
      );
    } else if (datosLibro.formato === "jpg") {
      return (
        <iframe
          title="Lector"
          style={{ pointerEvents: "auto" }}
          id="cuadro"
          src={`/standalone/visor/?idLibro=${datosLibro.idLibro}&api_endpoint=${GENERAL_URL_API}${mobileAppMode === true ? '&mode=mobileapp' : ''}`}
          height="100%"
          width="100%"
          allowFullScreen=""
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
        ></iframe>
      );
    } else if (datosLibro.formato === "htmlScroll") {
      return (
        <iframe
          title="Lector"
          style={{ pointerEvents: "auto" }}
          className="iframeClass"
          id="cuadro"
          src={`${ASSETS_URL}${datosLibro.Index}`}
          height="100%"
          width="100%"
          allowFullScreen=""
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
        ></iframe>
      );
    } else {
      return (
        //Asi se renderizan los HTML, es decir los libros interactivos
        <iframe
          title="Lector"
          scrolling="no"
          style={{ pointerEvents: "auto" }}
          className="iframeClass"
          id="cuadro"
          src={`${ASSETS_URL}${datosLibro.Index}`}
          height="100%"
          width="100%"
          allowFullScreen=""
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
        ></iframe>
      );
    }
  };

  /**
   * En caso de que datosLibro tenga un link, esta función retorna un enlace con la ruta correspondiente.
   * @param {number} number -numero del link
   * @returns {HTMLElement} linkDescubre
   */
  const linkDescubre = (number) => {
    return (
      <a
        href={datosLibro[`link${number}`]}
        target="_blank"
        className="link-descubre focusable-red"
      >
        <div className="my-4 row justify-content-between align-items-center px-3 py-2 card">
          <p className="col-10">{datosLibro[`textLink${number}`]}</p>
          <div className="col-2">
            <i className="fas fa-arrow-right"></i>
          </div>
        </div>
      </a>
    );
  };

  /**
   * Esta función retorna el menú lateral que contiene las opciones de descubre más
   * @returns {HTMLElement} descubreContent
   */
  const descubreContent = () => {
    if (isReady) {
      return (
        <FocusScope contain restoreFocus autoFocus>
          <div
            className={`descubre-container row align-items-center justify-content-center flex-column p-3 ${isShowDescubre.animation ? "appear-container" : ""
              }`}
            id="descubre-container"
          >
            <button
              className="btn btn-secondary close-descubre py-0 px-2 focusable-red"
              id="close-descubre"
              onClick={() => hideDescubre()}
              aria-label={"cerrar el contenido de descubre más"}
            >
              <i className="fas fa-times"></i>
            </button>
            <div className="descubre px-4 mt-3">
              {datosLibro.Actividades && (
                <>
                  <h3 className="my-3 ">
                    {visor?.descubre?.actividades || "Actividades"}
                  </h3>
                  {datosLibro.Actividades.map((actividad) => (
                    <a
                      key={actividad.idActividad}
                      href={`${ASSETS_URL}${actividad.webURL}`}
                      target="_blank"
                      className="link-descubre focusable-red"
                    >
                      <div className="my-4 row justify-content-between align-items-center px-3 py-2 card">
                        <p className="col-10">{actividad.titleActividad}</p>
                        <div className="col-2">
                          <i className="fas fa-arrow-right"></i>
                        </div>
                      </div>
                    </a>
                  ))}
                </>
              )}
              {datosLibro.link1 !== "" && (
                <>
                  <h3 className="my-3 ">
                    {visor?.descubre?.contenidos || "Contenidos de interés"}
                  </h3>
                  {linkDescubre(1)}
                </>
              )}
              {datosLibro.link2 !== "" && <> {linkDescubre(2)} </>}
              {datosLibro.link3 !== "" && <>{linkDescubre(3)}</>}

              <div className="recomendados mt-4">
                <h3 className="my-3">
                  {visor?.descubre?.relacionados || "Libros relacionados"}
                </h3>
                <div className="row m-0">
                  <div className="cover-book col-5">
                    <Link
                      to={datosLibro.hrefRecomen1}
                      className={"focusable-red"}
                    >
                      <img
                        alt="Portada libro recomendado 1"
                        className="mx-auto d-block img-fluid"
                        src={`${ASSETS_URL}${datosLibro.hrefRecomen1Portada}`}
                      />
                    </Link>
                  </div>
                  <div className="cover-book col-5">
                    <Link
                      to={datosLibro.hrefRecomen2}
                      className={"focusable-red"}
                    >
                      <img
                        alt="Portada libro recomendado 2"
                        className="mx-auto d-block img-fluid"
                        src={`${ASSETS_URL}${datosLibro.hrefRecomen2Portada}`}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FocusScope>
      );
    }
  };

  /**
   * Actualiza el estado setisShowDescubre, el cual activa la animación y visibilidad del menú descubre más.
   */
  const showDescubre = () => {
    setisShowDescubre({ animation: false, appear: true });
    setTimeout(() => {
      setisShowDescubre({ animation: true, appear: true });
    }, 100);
  };

  /**
   * Actualiza el estado setisShowDescubre, el cual activa la animación y visibilidad del menú descubre más.
   */
  const hideDescubre = () => {
    setisShowDescubre({ animation: false, appear: true });
    setTimeout(() => {
      setisShowDescubre({ animation: false, appear: false });
    }, 300);
  };

  /**
   * actualiza la posición del menú descubre más según el acho del dispositivo.
   */
  const positionDescubre = () => {
    if (visorHeader.current !== null && botonDescubre.current !== null) {
      if (window.innerWidth < 992) {
        botonDescubre.current.style.top = `${visorHeader.current.offsetHeight}px`;
      } else {
        botonDescubre.current.style.top = 0;
      }

      if (window.matchMedia("(orientation: landscape)").matches) {
        if (window.innerWidth < 992) {
          botonDescubre.current.style.top = `${visorHeader.current.offsetHeight}px`;
        } else {
          botonDescubre.current.style.top = 0;
        }
      }
    }
  };

  /**
   * Esta función muestra la ventana modal de compartir libro
   */
  const abrirModalCompartirVisor = () => {
    MicroModal.show("modal-compartir-visor", {
      awaitCloseAnimation: true,
    });
  };

  /**
   * Esta función muestra la ventana modal con los libros recomendados
   */
  const abrirModalRecomendadosVisor = () => {
    setMostrarBlop(false);
    MicroModal.show("modal-visor-recomendados", {
      awaitCloseAnimation: true,
    });
  };

  /**
   * Esta función cierra la ventana modal de libros recomendados
   */
  const cerrarModalRecomendados = () => {
    MicroModal.close("modal-visor-recomendados", {
      awaitCloseAnimation: true,
    });
  };

  /**
   * Retorna el botón de descubrir más. Este se encuentra en el header del visor.
   * @returns {HTMLElement} mostrarBotonDescubrir
   */
  const mostrarBotonDescubrir = () => {
    /*  console.log('datosLibro-----------------------', datosLibro) */

    if (navigator.onLine && (datosLibro.Actividades || datosLibro.link1 !== "")) {
      return (
        <button
          className="btn btn-primary ml-3 focusable-red d-flex align-items-center"
          onClick={() => showDescubre()}
          onMouseOver={() => setIsOverBtn(true)}
          onMouseLeave={() => setIsOverBtn(false)}
        >
          {
            (datosLibro.Actividades) ?
              <>
                {" "}
                <span className="show-lg">
                  {visor?.descubre?.actividades || "Guía de lectura"}
                </span>{" "}

                <img
                  src={`${isOverBtn ? esTemaClasico ? "/img/icons/libreta-white.svg" : "/img/icons/libreta-alt.svg" : "/img/icons/libreta-black.svg"}`}
                  alt="imagen de libreta"
                  style={{ width: 23 }}
                  className="ml-lg-2"
                />
              </>

              :

              <>
                {" "}
                <span className="show-lg">
                  {visor?.descubre?.boton_descubre || "Descubre"}
                </span>{" "}
                <i className="fas fa-plus ml-lg-2"></i>
              </>
          }

        </button>
      );
    }
  };

  return (
    <div className="cover-visor">
      {isLoading && (
        <>
          <div className=" pb-3 py-4 col-12" style={{ overflow: "hidden" }}>
            <div
              className="secondary-inverse--color d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "600px" }}
            >
              <Icon
                icon="icomoon-free:spinner9"
                width={60}
                className={"spinner-rotation"}
              />
            </div>
          </div>
        </>
      )}
      {isReady && !isLoading && (
        <div className="visor">
          {
            mobileAppMode === false &&
            <div role={"banner"} className="visor-header" ref={visorHeader}>
              <div className="mx-1 mx-lg-4 py-2 row align-items-center justify-content-between">
                <div className="col-3 col-md-2 d-flex order-md-1 ">
                  <button
                    className="btn btn-primary focusable-red"
                    onClick={() => {
                      window.speechSynthesis.cancel();
                      var param = getUrlParam("returnUrl");
                      if (!!param) {
                        history.push(
                          decodeURIComponent(getUrlParam("returnUrl"))
                        );
                      } else {
                        if (
                          state.sesionActiva === "SI" &&
                          state.cantidadLibros <= 40
                        ) {
                          history.push("/home");
                        } else {
                          history.push(datosLibro.link);
                        }
                      }
                    }}
                  >
                    <i className="fas fa-arrow-left"></i>{" "}
                    <span className="show-lg">
                      {visor?.descubre?.boton_volver || "Volver"}
                    </span>
                  </button>
                </div>

                <div className="col-6 col-md-4 col-lg-5 col-xl-4 d-flex justify-content-end order-md-3">

                  {mostrarBotonDescubrir()}

                  <button
                    className="btn btn-primary focusable-red ml-3"
                    onClick={() => abrirModalCompartirVisor()}
                  >
                    {/* <span className="show-lg mr-lg-1">
                      {visor?.compartir?.boton || "Compartir"}
                    </span> */}
                    <Icon icon="ci:share" width={22} />
                  </button>

                  {/* Botón nuevo de tipo de libro */}
                  {
                    navigator.onLine &&
                    <button
                      className="btn btn-primary focusable-red ml-3"
                      onClick={() =>
                        setBookTypeModal(!showBookTypeModal)}
                    >
                      <Icon icon="bi:question-lg" width={25} />
                    </button>
                  }

                  {/* BTN FULLSCREEN OUT HEADER*/}
                  <button
                    className="d-none d-md-block btn btn-primary focusable-red ml-3"
                    id="btn-fullscreen"
                    aria-label="ver pantalla completa"
                    onClick={() => {
                      if (screenfull.isEnabled) {
                        screenfull.toggle(visorScreen.current);
                        if (screenfull.isFullscreen) {
                          setIsScreenFull(false);
                        } else {
                          setIsScreenFull(true);
                        }
                      }
                    }}
                  >
                    <i className="fas fa-expand" />
                  </button>
                </div>

                <div className="d-none d-md-block ml-0 title col-12 col-md-6 col-lg-5 col-xl-6 mt-2 mt-md-0 order-md-2">
                  <h2 className="text-md-center">{datosLibro.titulo}</h2>
                  <p className="text-md-center">{datosLibro.autor}</p>
                </div>
              </div>
            </div>
          }
          {isShowDescubre.appear && (
            <>
              <div
                className="close-container-menu"
                onClick={() => hideDescubre()}
              >
                {descubreContent()}
              </div>
            </>
          )}
          <div className={`mb-bookvisor-container ${mobileAppMode === false && 'mb-bookvisor-container-h'} row justify-content-center mx-0`} style={{ height: mobileAppMode === true && `100vh`, width: mobileAppMode === true && `100vw` }}>
            <div className="mb-bookvisor" ref={visorScreen}>
              {renderVisor()}

              {/* BTN FULLSCREEN OUT */}
              {isScreenFull &&
                <button
                  className="btn btn-primary focusable-red ml-3 position-absolute"
                  id="btn-fullscreen"
                  aria-label="salir de pantalla completa"
                  style={{
                    zIndex: 5000,
                    bottom: 15,
                    right: 15
                  }}
                  onClick={() => {
                    if (screenfull.isEnabled) {
                      screenfull.toggle(visorScreen.current);
                      if (screenfull.isFullscreen) {
                        setIsScreenFull(false);
                      } else {
                        setIsScreenFull(true);
                      }
                    }
                  }}
                >
                  <i className="fas fa-compress" />
                </button>
              }
            </div>
          </div>

          {/* RECOMENDADOS BTN */}
          {
            mobileAppMode === false &&
            <div
              className={`cont-recomendados ${mostrarBlop ? "cont-activo" : "cont-inactivo"
                }`}
            >
              <div className={`position-relative w-100 h-100`}>
                <button
                  className="btn-active-recomendado focusable-red"
                  onClick={() => setMostrarBlop(!mostrarBlop)}
                >
                  <i
                    className={`fas ${mostrarBlop ? "fa-chevron-right" : "fa-chevron-left"
                      }`}
                  ></i>
                </button>

                <button
                  className="btn-recomendado focusable-secondary"
                  aria-hidden={mostrarBlop ? false : true}
                  onClick={() => abrirModalRecomendadosVisor()}
                >
                  {visor?.recomendados?.btn ||
                    "Mira nuestros libros recomendados"}
                </button>
              </div>
            </div>
          }
          {
            mobileAppMode === false &&
            <footer className="footer-copyright py-1 ">
              <p>
                {visor?.footer?.descripcion1 || "Todos los derechos reservados."}
                <span className="copy-terms">
                  {" "}
                  {visor?.footer?.descripcion2 || "Consulta nuestros"}{" "}
                  <a
                    className="links-tyc focusable-red  px-2"
                    href="/terminos-y-condiciones.php"
                  >
                    {visor?.footer?.descripcion3 || "Términos y condiciones"}
                  </a>{" "}
                  {visor?.footer?.descripcion4 || "y"}{" "}
                  <a
                    className="links-tyc focusable-red px-2"
                    href="/politica-de-privacidad.php"
                  >
                    {visor?.footer?.descripcion5 || "Política de privacidad"}
                  </a>
                  .
                </span>
              </p>
            </footer>
          }

        </div>
      )}

      <ModalCompartir idModal={"modal-compartir-visor"} libro={datosLibro} />

      <ModalVisor
        idModal={"modal-visor-recomendados"}
        datosLibro={datosLibro}
        cerrarModal={cerrarModalRecomendados}
      />
      <SessionLimitModal visible={showSessionLimitModal} setVisible={setShowSessionLimitModal} mode={mobileAppMode ? 'app' : ''} />
      <ReadingLimitModal visible={showReadingLimitModal} setVisible={setShowReadingLimitModal} mode={mobileAppMode ? 'app' : ''} />
      <BookTypeModal visible={showBookTypeModal} setVisible={setBookTypeModal} typeBook={datosLibro?.tipoLibro} />
      <IncompatibleBook visible={showIncompatibleBook} setVisible={setIncompatibleBook} />
    </div>
  );
};

export default Visor;

{
  /* <div style={{ position: "relative", height: "100%" }}>
          {" "}
          <ReactReader
            url={`${ASSETS_URL}${datosLibro.Archivos[0]}`}
            title={datosLibro.titulo}
            location={location}
            locationChanged={onLocationChanged}
            getRendition={getRendition}
            className="algo"

          />

          <div className="title-controls">
            <button className="btn btn-default settings-button mr-2 px-2"
              disable={sameLocation}
              onClick={() => guardarMarcador(location)}
            >
              <i className="fas fa-bookmark"></i>
            </button>
            <button className="btn btn-default settings-button"
              onClick={() => setIsShowFontSize(!isShowFontSize)}
            >
              <i className="fas fa-cog"></i>
            </button>
          </div>
          {isReady && isShowFontSize &&
            <>
              <div className="font-size-modal">
                <div className="">
                  <div className="d-flex justify-content-center align-items-center titulo-texto">
                    <h3 className="col-12 py-0">{visor?.ajustes?.titulo || 'Ajustes'}</h3>
                    <i
                      className="fas fa-times-circle cerrar"
                      onClick={() => setIsShowFontSize(false)}
                    />
                  </div>
                  <div className="tamano-texto">
                    <span className="mr-4">{visor?.ajustes?.tamanio || 'Tamaño del texto'}</span>
                    <button className="size-font" onClick={() => decreaseFontSize()}>
                      <img alt="Reducir letra" className="smaller-font" src={`/img/menos.png`} />
                    </button>
                    <button className="size-font" onClick={() => increaseFontSize()}>
                      <img alt="Aumentar letra" className="bigger-font" src={`/img/mas.png`} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="overlay"
                onClick={() => setIsShowFontSize(false)}
              ></div>
            </>
          }
        </div> */
}
