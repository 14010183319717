import React, { useContext, useEffect } from 'react';
import useObtenerSubsecciones from '../js/translations/useObtenerSubsecciones';
import AyudaBasica from './acordeones/AyudaBasica';
import AyudaDescarga from './acordeones/AyudaDescarga';
import { ModoPWAContext } from '../contexts/ModoPwaContext.js';
import { Icon } from '@iconify/react';
import { Helmet } from "react-helmet";

/**
 * Componente que actua como el componente principal (index) que representa la sección de Ayuda.
 * @component
 * @returns {JSX.Element} Elemento JSX que representa la sección de Ayuda.
 */
const Ayuda = () => {
  const { isReady, textosSubSecciones: descarga } = useObtenerSubsecciones('instrucciones_descarga');

  const pwaState = useContext(ModoPWAContext);


  return (

    <>
      <Helmet>
        <title>{`${descarga?.metadata?.title || 'MakeMake - Ayuda'}`}</title>
        <meta name="description" content={descarga?.metadata?.description || 'Resuelve dudas y preguntas frecuentes con vídeos tutoriales de ayuda.'} />
      </Helmet>
      {isReady &&
        <>
          <div className="main-container pb-5 focusable-secondary" tabIndex={0}>
            <div className="main-content container py-5">
              <div className='instrucciones-app'>
                <h2 tabIndex={0} className="focusable-secondary mb-2 text-center">{descarga?.instrucciones?.titulo || 'Ayuda'}</h2>
                <div className="row m-0 mt-5">

                  {/* datos de contacto */}
                  <div className="col-lg-4 mb-3 mb-lg-0 p-0 order-lg-2">
                    <div className='datos-de-contacto'>
                      <img src='../img/eyex-pregunta.png' className='instrucciones-app-eyex' alt={'eyex-pregunta.png'} />
                      <h3 className='text-center  text-lg-left' >{descarga?.datos_contacto?.titulo || 'Datos de contacto America latina'}</h3>
                      <div className='mt-3 row justify-content-center justify-content-lg-start m-0'>
                        <Icon icon="ic:round-email" width={30} />
                        <a href={`mailto:${descarga?.datos_contacto?.correo || 'contacto@makemake.com.co'}`} className='focusable-secondary ml-2'> {descarga?.datos_contacto?.correo || 'contacto@makemake.com.co'}</a>
                      </div>
                      <div className='mt-3 row m-0 justify-content-center justify-content-lg-start align-items-start'>
                        <Icon icon="ri:whatsapp-fill" width={30} />
                        <div className='ml-2'>
                          <a
                            href={descarga?.datos_contacto?.num_uno?.link || "https://wa.link/vuju91"}
                            className="focusable-secondary d-block"
                          >
                            +{descarga?.datos_contacto?.num_uno?.numero || '57 313 4509992'}
                          </a>
                          <a
                            href={descarga?.datos_contacto?.num_dos?.link || "https://wa.link/aozzbk"}
                            className="focusable-secondary d-block"
                          >
                            +{descarga?.datos_contacto?.num_dos?.numero || '57 350 2367037'}
                          </a>
                        </div>
                      </div>
                      <h3 className='text-center  text-lg-left mt-3' >{descarga?.datos_contacto?.usa?.titulo || 'Datos de contacto Estados Unidos'}</h3>
                      <div className='mt-3 row justify-content-center justify-content-lg-start m-0'>
                        <Icon icon="ic:round-email" width={30} className='mt-1' />
                        <div>
                          <a href={`mailto:acorrea@lectorum.com`} className='focusable-secondary ml-2'> acorrea@lectorum.com</a>
                          <br />
                          <a href={`mailto:makemake@lectorum.com`} className='focusable-secondary ml-2'> makemake@lectorum.com</a>
                        </div>
                      </div>
                      <div className='mt-3 row m-0 justify-content-center justify-content-lg-start align-items-start'>
                        <Icon icon="ic:baseline-local-phone" width={30} />
                        <div className='ml-2'>
                          <p
                            className="focusable-secondary d-block"
                          >
                            {`+1 (201) 559-2200`}
                          </p>
                          <p
                            className="focusable-secondary d-block"
                          >
                            {`+1 (800) 345-5946`}
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="col-lg-8 p-0 pr-lg-5 order-lg-1">

                    {/* Ayuda básica general --------------------------------------------------------------------------- */}
                    <h3 className='onbackground-primary--color mb-2'>{descarga?.ayuda_basica?.titulo || 'Ayuda básica general'}</h3>
                    <p className='onbackground-primary--color my-3'>{descarga?.ayuda_basica?.parrafo_uno || 'MakeMake es una plataforma basada en la web, diseñada para que el acceso a sus contenidos sea óptimo en diferentes navegadores y dispositivos.'} </p>
                    <p className='onbackground-primary--color my-3'>{descarga?.ayuda_basica?.parrafo_dos || 'Conoce más sobre cómo usar MakeMake:'}</p>

                    <AyudaBasica txtAyudaB={descarga?.ayuda_preguntas} />

                    {/* Ayuda para la descarga de libros---------------------------------------------------------------------- */}
                    {
                      pwaState.clienteHabilitado &&
                      <>
                        <h4 className='onbackground-primary--color mt-5 mb-2'>{descarga?.ayuda_descarga?.titulo || 'Ayuda para la descarga de libros'}</h4>
                        <p className='onbackground-primary--color my-3'>{descarga?.ayuda_descarga?.parrafo_uno || 'Aunque MakeMake está pensada principalmente para la consulta en línea (conectado a una red de internet), recientemente creamos una nueva versión que te permite la descarga de libros para consulta offline de forma segura y efectiva.'}</p>
                        <p className='onbackground-primary--color my-3'>{descarga?.ayuda_descarga?.parrafo_dos || 'Conoce más sobre cómo descargar libros en MakeMake'}</p>

                        <AyudaDescarga
                          txtAyudaD={descarga?.descarga_preguntas}
                          datosContacto={descarga?.datos_contacto}
                        />
                      </>
                    }

                  </div>

                </div>
              </div>

            </div>
          </div>
        </>

      }

    </>
  )
}

export default Ayuda;