import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import useIsMobile from "../../js/utilities/useIsMobile";

/**
 * Componente que retorna la bandera que indica el tipo de formulario (público o exclusivo)
 * @param {object} props 
 * @returns {JSX.Element} EventFlag
 */
export const EventFlag = ({ tipoEvento, textosInterfaz }) => {
    const isMobile = useIsMobile();

    return (
        <div 
            className="flag-event py-2 px-3 p-lg-0"
            style={{background: isMobile ? tipoEvento === "publico" ? "#fed116" : "#1e9bd8" : ""}}
        >
            <div className="position-relative">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 547 271"
                    fill={`${tipoEvento === "publico" ? "#fed116" : "#1e9bd8"}`}
                    className="d-none d-lg-block"
                >
                    <path
                        d="M458.3,22.7L457.4,66H33l80.1,76.4L33,222.2c2.8,0.3,432.8,0,432.8,0s31.2-1.6,31.2-21.8V40.7 C497,40.7,494.2,18.1,458.3,22.7z"
                    />
                </svg>
                <div className={`text-flag d-flex align-items-center justify-content-end`}
                    style={{
                        color: tipoEvento === "publico" ? "#ea5817" : "#ffffff"
                    }}
                >
                    <p className="secondary-font mr-3" style={{fontWeight: 600}}>
                        {tipoEvento === "publico" ? textosInterfaz?.flag?.publico || 'Evento público' : textosInterfaz?.flag?.exclusivo || 'Evento exclusivo'}
                    </p>
                    {
                        tipoEvento === "publico" ?
                            <Icon icon="ph:users-three" width={40}/>
                            :
                            <Icon icon="mdi:badge-outline" width={40}/>
                    }
                </div>

            </div>

        </div>
    )
}