import React, { useContext } from "react";
import { AccesibilidadContext } from "../contexts/AccesibilidadContext";

/**
 * Componente de titulos principales en las páginas
 * 
 * @typedef {Object} props - Propiedades del componente.
 * @param  {string} hexBackground - Hexadecimal que indica el color en modo clasico
 * @param  {string} title - texto principal 
 * @param  {string} description - descripción principal 
 * @param  {string} color - clase definina que establece el color de la fuente
 * @param  {Boolean} darkShapes - cambia las figuras de fondo
 * @returns {JSX.Element} Componente Titles.
 */
export const Titles = ({ hexBackground, title, description, color, darkShapes }) => {
  const accebilidadState = useContext(AccesibilidadContext);
  const back = hexBackground ? hexBackground : "#fff";
  return (
    <div
      className={`titlePage`}
      style={{
        backgroundColor: accebilidadState?.temaActual !== 'clasico' ? "#000" : back,
        backgroundImage: `url(${darkShapes ? '/img/backs/back-titles2.svg' : '/img/backs/back-titles.svg'})`,
      }}
    >
      <div className={`container  d-lg-flex align-items-center h-100`}>
        <h1 className={` ${color} ${description ? 'col-lg-5 col-xl-6' : ''}`}>{title ? title : 'TITULO'}</h1>
        {description &&
          <p className={`${color} ${description ? 'col-lg-7 col-xl-6' : ''}`}>{description ? description : 'DESCRIPTION'}</p>
        }

      </div>
    </div>
  )
}