import React, { useRef, useContext } from "react";
import { ASSETS_URL } from "../../components/Store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Navigation } from "swiper";
import { memo } from "react";
import { Book } from "../../components/shared/book";
import { store } from "../../components/Store";
import { FavoritesStorage } from "../../util/favorites-storage";
import { useFavoritesBooks } from "../../hooks/useFavoritesBooks";
import { useHistory } from "react-router-dom";

/**
 * Componente de sección de libros.
 * @param {Object} props - Props del componente.
 * @param {Array} props.books - Libros para mostrar.
 * @param {number} props.autoPlayDelay - Retraso de reproducción automática de los libros.
 * @param {Object} props.navigationButtons - Referencias a los botones de navegación.
 * @returns {JSX.Element} Elemento JSX que representa la sección de libros.
 */
export const BooksSection = memo(({ books, autoPlayDelay, navigationButtons }) => {
    const swiperRef = useRef(null);
    books = Array.isArray(books) ? books : [];
    const globalState = useContext(store);
    const isAuthenticatedUser = globalState?.state?.sesionActiva === 'SI';
    const user = globalState.state.username;
    const favorites = useFavoritesBooks({userName: user})
    const history = useHistory();

    const updateNavigation = (swiper)=>{
        swiper.params.navigation.prevEl = navigationButtons?.prevButtonRef?.current
        swiper.params.navigation.nextEl = navigationButtons?.nextButtonRef?.current
        swiper.navigation.destroy()
        swiper.navigation.init()
        swiper.navigation.update()
    }


    /**
     * Función que añade o remueve el libro de favoritos en el localstorage
     * @param {string} libro -ide libro
     * @param {boolean} favorito -indica el estado del botón de favoritos
     */
    const agregarRemoverAFavoritos =  (libro, favorito) => {
        const bookID = libro?.idLibro || 'N/A';
        if(favorito === true){
            FavoritesStorage.saveFavorites(user, bookID);
        }else{
            FavoritesStorage.removeFavorites(user, bookID);
        }
    }

    return (
        <section>
        <div className="relative">
            <Swiper
                ref={swiperRef}
                className="main-covers"
                style={{overflowX:'hidden'}}
                slidesPerGroup={2}
                slidesPerView={2}
                spaceBetween={40}
                breakpoints={{
                    526: {
                      slidesPerView: 3,
                      slidesPerGroup: 3,
                    },
                    992: {
                      slidesPerView: 5,
                      slidesPerGroup: 5,
                    },
                    1400: {
                      slidesPerView: 6,
                      slidesPerGroup: 6,
                    },
                  }}
                autoplay={{
                    delay: autoPlayDelay || 5000,
                    disableOnInteraction: false,
                }}
                mousewheel={{
                    forceToAxis: true,
                }}
                navigation={{
                    nextEl:navigationButtons?.nextButtonRef?.current,
                    prevEl:navigationButtons?.prevButtonRef?.current,
                }}
                onSwiper={(swiper) => {
                    updateNavigation(swiper);
                }}
                modules={[Autoplay, Navigation, Mousewheel]}
            >
                {books.map((book, i) => {
                    return (
                        <SwiperSlide
                            className="focusable-by-children-secondary d-flex justify-content-center"
                            key={`banner${i}`}
                        >
                                <Book
                                libro={{
                                    ...book,
                                    portada:`${ASSETS_URL}${book?.url}`,
                                    title:book?.nombreLibro
                                }}
                                alSeleccionar={()=>history.push(book?.link)}
                                ocultarOpciones={{
                                    checkbox:true,
                                    bookType:false,
                                    botonFavoritos: isAuthenticatedUser ? false : true
                                }}
                                alAgregarFav={(book) => agregarRemoverAFavoritos(book, true)}
                                alRemoverFav={(book) => agregarRemoverAFavoritos(book, false)}
                                clasesPersonalizadas={{
                                    contenedor:' m-4'
                                }}
                                estadoInicial={{
                                    esFavorito: favorites.includes(book?.idLibro) 
                                }}
                                />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    </section>
    );
});