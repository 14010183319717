import React from "react";
import { Route, Switch } from "react-router-dom";
//Componentes
import IngresarDatos from './IngresarDatos';
import MensajeInscripcion from './MensajeInscripcion';

/**
 * Este componente no recibe ningún prop. Este se encarga de cargar el contenido adecuado según la URL activa. 
 * @returns {JSX.Element} SuscripcionBoletin
 */
const SuscripcionBoletin = () => {
  return (
    <>
      <Switch>
        <Route exact path='/boletin/ingresar-datos' component={IngresarDatos}/>
        <Route exact path='/boletin/mensaje' component={MensajeInscripcion}/>
      </Switch>
    </>
  )
}


export default SuscripcionBoletin;