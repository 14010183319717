let departamentos = [
  'ANTIOQUIA',
  'ATLANTICO',
  'BOGOTA',
  'BOLIVAR',
  'BOYACA',
  'CALDAS',
  'CAQUETA',
  'CAUCA',
  'CESAR',
  'CORDOBA',
  'CUNDINAMARCA',
  'CHOCO',
  'HUILA',
  'LA GUAJIRA',
  'MAGDALENA',
  'META',
  'NARIÑO',
  'N. DE SANTANDER',
  'QUINDIO',
  'RISARALDA',
  'SANTANDER',
  'SUCRE',
  'TOLIMA',
  'VALLE DEL CAUCA',
  'ARAUCA',
  'CASANARE',
  'PUTUMAYO',
  'SAN ANDRES',
  'AMAZONAS',
  'GUAINIA',
  'GUAVIARE',
  'VAUPES',
  'VICHADA',
]

export default departamentos;