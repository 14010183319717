import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { GENERAL_URL_API } from "../components/Store";
import getUrlParam from './../js/utilities/getUrl';
import postAjax from './../js/utilities/postAjax';
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Este componente no recibe props. Si en el primer paso se selecciona una institución, este componente renderizar el segundo paso de demo (¡Tu institución ya tiene MakeMake!). Sus principales funciones son obtener la institución activa desde la URL y enviar el formulario con el correo diligenciado.
 * @returns DemoIngresarCorreo
 */
const DemoIngresarCorreo = () => {
  const { isReady, textosSubSecciones: textosDemo } = useObtenerSubsecciones('demo');

  const refCorreo = useRef(null);
  const refFormulario = useRef(null);
  const [instituto, setInstituto] = useState('');
  const [notificationCorreo, setNotificationCorreo] = useState(false);

  const history = useHistory();

   useEffect(() => {
     const institucion = getUrlParam('it');
     if (!!institucion) {
       setInstituto(decodeURIComponent(institucion));
     } else {
       history.push('/')
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   
   /**
   * Función que valida el formulario y redirecciona al siguiente paso
   * @param {Event} e 
   */
  const enviarFormulario = (e) => {

    if (refCorreo.current.reportValidity()) {
      e.preventDefault();

      const parametros = {
        email: refCorreo.current.value,
        itVal: instituto
      }

      postAjax(`${GENERAL_URL_API}/assets/guardarCorreoDemoConInst`, parametros, response => {
        const respuesta = response.toLowerCase().search('error');
        if (respuesta === -1) {
          history.push(`/informacion/datos-institucion?it=${instituto}`)
        }
      })
      // refFormulario.current.submit();
    }else{
      if (refCorreo.current) {
        refCorreo.current.addEventListener("invalid", () => {
          setNotificationCorreo(true)
        });
      }
    }
  }


  return (
    <>
      {isReady &&
        <div className="col-lg-8 mx-auto pt-3 pb-5 mb-5">
          <h2 className="mm-redimir_title secondary-inverse--color mm-redimir_title--institution mt-3 mb-4 text-center secondary-item">{textosDemo?.ingresar_correo?.titulo?.parte_uno || '¡Tu institución '} {' '}{textosDemo?.ingresar_correo?.titulo?.parte_dos || 'ya tiene MakeMake!'}</h2>

          <p className="text-center mb-5 onbackground-third--color">{textosDemo?.ingresar_correo?.descripcion || 'Ingresa tu correo para entregarte la información de contacto de tu institución.'}</p>

          <form ref={refFormulario} className="mm-redimir_form" id="formAddNewEmailInstitute">
            <p className="pl-lg-4 secondary-item secondary-inverse--color"><strong>{textosDemo?.ingresar_correo?.correo?.texto || 'Correo:'}</strong></p>
            <div className="row mx-0 align-items-start">
              <div className="col-md-9  mb-4 mb-lg-0">
                <label htmlFor="inputCode" className="sr-only">{textosDemo?.ingresar_correo?.correo?.label || 'Correo electrónico'}</label>
                <input
                  ref={refCorreo}
                  type="email"
                  className="form-control surface--bg  onbackground-third--color focusable-secondary"
                  id="inputCode"
                  placeholder={textosDemo?.ingresar_correo?.correo?.placeholder || "Escribe tu correo"}
                  required
                  onChange={() => setNotificationCorreo(false)}
                />
                <span role={"alert"} aria-live="assertive" className="notificacion-form">
                  {notificationCorreo ? textosDemo?.ingresar_correo?.correo?.alert || 'Para enviar este formulario, debes ingresar un correo' : ''}
                </span>
              </div>

              <button
                onClick={enviarFormulario}
                id='submitNewClient'
                className="btn btn-primary mm-redimir_submit d-block col-md-3 focusable-red"
                type="submit"
              >
                {textosDemo?.botones?.enviar || 'Enviar'}
              </button>
            </div>
          </form>

        </div>
      }

    </>
  )
}
export default DemoIngresarCorreo;