import React from "react";
import { useState } from "react";
import useIsMobile from "../../../js/utilities/useIsMobile";
import { useEffect } from "react";
import { FOOTER_CONTACTS } from "../data/contacts";
import { Icon } from "@iconify/react";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

export const ContactList = () => {
	const contactList = [...FOOTER_CONTACTS];
	return (
		<div className="row p-0 m-0">
			{contactList?.map((contactData, i) => {
				return (
					<div key={i} className="col-12 col-sm-6 col-lg">
						<ContactCard key={i} data={contactData} />
					</div>
				);
			})}
		</div>
	);
};

export const ContactCard = ({ data }) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const texts = useTranslations({ section: "footer", lang: lang });
	const [expanded, setExpanded] = useState(false);
	const isMobile = useIsMobile();

	const title = _.get(texts, data?.title?.translationPath) || data?.title?.default || "N/A";
	const emails = Array.isArray(data?.emails) ? data?.emails : [];
	const contactNumbers = Array.isArray(data?.contactNumbers) ? data?.contactNumbers : [];
	const adresses = Array.isArray(data?.adresses) ? data?.adresses : [];

	useEffect(() => {
		if (isMobile === false) {
			setExpanded(true);
		} else {
			setExpanded(false);
		}
	}, [isMobile]);

	const handleMobileExpandedState = (_expanded) => {
		if (isMobile) {
			setExpanded(_expanded);
		}
	};

	return (
		<div className="contact-card p-2">
			<h3
				className="title d-flex justify-content-between"
				style={{ cursor: isMobile ? "pointer" : "default" }}
				role={`${isMobile ? "button" : "none"}`}
				aria-label={`${title} ${expanded ? "expandido/expanded" : "contraido/collapsed"}`}
				onClick={() => handleMobileExpandedState(!expanded)}
			>
				<span>{title}</span>

				<Icon
					className={`dropdown-arrow-transition ${isMobile === false && "d-none"} ${
						expanded ? "expanded-dropdown" : "collapsed-dropdown"
					}`}
					icon="fe:arrow-down"
					width={"1.5em"}
				/>
			</h3>
			<div className={`info ${expanded ? "d-block" : "d-none"}`} style={{ maxWidth: 300 }}>
				<ul className={`my-2`}>
					{emails?.map((email, i) => {
						return (
							<li key={i}>
								{email?.website ? (
									<a
										className="info font-weight-bold default-link d-block"
										href={email.website}
										target="_blank"
										rel="noopener noreferrer"
									>
										{email?.contactName}
									</a>
								) : (
									<p className="info font-weight-bold">{email?.contactName}</p>
								)}
								<a
									className="info font-weight-bold default-link focusable-secondary"
									href={`mailto:${email?.email}`}
								>
									{email?.email}
								</a>
							</li>
						);
					})}
				</ul>
				<ul className="my-2">
					{contactNumbers?.map((contact, i) => {
						return (
							<li key={i}>
								<p className="info font-weight-bold">{contact}</p>
							</li>
						);
					})}
				</ul>
				<ul className="my-2">
					{adresses?.map((adress, i) => {
						return (
							<li key={i}>
								<p className="info font-weight-normal">{adress}</p>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};
