import React from "react";
import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../../config/constants";
import { CommunityTitle } from "../../../../components/community/community-title";
import { PAST_READING_PLANS } from "../../../data/reading-plans";
import { useTranslations } from "../../../../hooks/use-translations";
import _ from "lodash";

export const PastReadingPlans = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "comunidad_club", lang: lang });
	const pastPlans = [...PAST_READING_PLANS];

	return (
		<div className="container">
			<div className="mt-5 mb-4">
				<CommunityTitle.H3 title={_.get(texts, 'planes.planes_pasados.titulo') || "Planes de lectura pasados"} />
			</div>
			<ul className="row justify-content-center align-items-center p-0 m-0">
				{pastPlans?.map((plan, i) => {
					return (
						<div
							key={plan?.id}
							className="col-auto col-md m-2 p-2 d-flex justify-content-center align-items-center"
						>
							<li className="m-2 p-0 past-reading-plan-card">
								<a
									className="focusable-red"
									href={`${plan?.file?.[lang] || plan?.file?.es}`}
									aria-label={`plan-${plan?.id}`}
									target="_blank"
									rel="noopener noreferrer"
									download={true}
								>
									<img
										className="img img-fluid"
										src={plan?.img}
										alt={`${plan?.id}`}
									/>
								</a>
							</li>
						</div>
					);
				})}
			</ul>
		</div>
	);
};
