import React, { useEffect, useState } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/*Componentes */
import DemoConfirmarCorreo from "./DemoConfirmarCorreo";
import DemoEscogerInstitucion from "./DemoEscogerInstitucion";
import DemoIngresarCorreo from "./DemoIngresarCorreo";
import DemoIngresarDatos from "./DemoIngresarDatos";
import DemoMensaje from "./DemoMensaje";

/**
 * Este componente no recibe props. Su función principal es renderizar los pasos de la sección DEMO y define el paso actual. Además retorna el componente indicado dependiendo de la url. Este hace uso de los componentes DemoConfirmarCorreo, DemoEscogerInstitucion, DemoIngresarCorreo, DemoIngresarDatos y DemoMensaje.
 * @returns {JSX.Element} DemoConfirmarCorreo, DemoEscogerInstitucion, DemoIngresarCorreo, DemoIngresarDatos y DemoMensaje.
 */
const Demo = () => {
  const { isReady, textosSubSecciones: textosDemo } = useObtenerSubsecciones('demo');
  const pasos = [
    {
      url: 'demo-escoger-institucion',
      nombre: '¿Ya estás en MakeMake?',
      traduccion: 'paso_uno',
    },
    {
      url: [
        'demo-confirmar-correo',
        'demo-ingresar-correo',
        'demo-ingresar-datos',
      ],
      nombre: 'Ingresa tus datos',
      traduccion: 'paso_dos',
    },
    {
      url: 'demo-mensaje',
      nombre: 'Datos de tu institución',
      traduccion: 'paso_tres',
    }
  ];

  const [pasoActivo, setPasoActivo] = useState(0)

  const param = useParams()
  const { section } = param;


  useEffect(() => {
    const pasoUrl = pasos.findIndex(paso => {
      let match;
      if (typeof paso.url === 'string') {
        if (paso.url === section) match = paso
      } else {
        const index = paso.url.findIndex(subUrls => subUrls === section)
        if (index !== -1) match = paso;
      }
      // console.log(  paso.url)
      return match
    });

    setPasoActivo(pasoUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])


  return (
    <>
      {isReady &&
        <div className="main-container mm-redimir" style={{minHeight: '70vh'}}>
          <div className="container main-content surface--bg px-0 pb-0 ">
            <ol className="mm-redimir_steps p-0 mb-5 mx-5 mx-md-2 mx-lg-5 row justify-content-between align-items-start">
              {
                pasos.map((paso, index) => (
                  <li key={`paso${index}`} aria-current={pasoActivo === index ? 'step' : false}  >
                    <div className="cont-paso">
                      <p className="mx-auto text-center onbackground-third--color">
                        {
                          index < pasoActivo
                            ? <i style={{ color: '#F0581F', fontSize: 24 }} className="fas fa-check-circle fa-fw mr-2"></i>
                            : <span className={`step mr-2 ${pasoActivo === index ? 'step-active' : ''}`}>{index + 1}</span>
                        }
                        {textosDemo?.escoger_institucion?.pasos[paso.traduccion] || paso.nombre}
                      </p>
                    </div>
                    {index + 1 !== pasos.length && <hr aria-hidden={true}/>}
                  </li>
                ))
              }
            </ol>


            <div className="contenedor-altura-minima">
              <Switch>
                <Route exact path='/demo/demo-escoger-institucion' component={DemoEscogerInstitucion} />
                <Route exact path='/demo/demo-ingresar-correo' component={DemoIngresarCorreo} />
                <Route exact path='/demo/demo-confirmar-correo' component={DemoConfirmarCorreo} />
                <Route exact path='/demo/demo-ingresar-datos' component={DemoIngresarDatos} />
                <Route exact path='/demo/demo-mensaje' component={DemoMensaje} />
              </Switch>
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default Demo;