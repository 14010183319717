import { useContext } from "react";
import { store } from "../components/Store";

/**
 * El hook de conexión retorna si hay conexión a internet o no, el valor retornado viene del estado global 'isOnline'.
 * @returns {{
 * online:boolean
 * }} - Un objeto que contiene el estado de conexión.
 * @property {boolean} online - Indica si el dispositivo está conectado a Internet.
 */
export const useNetworkStatus = () => {
	const appState = useContext(store);
	const online = appState?.state?.isOnline;
	return {
		online,
	};
};
