import React from "react";
import { BaseModal } from "../../components/modals/base-modal";
import { Icon } from "@iconify/react/dist/iconify.js";

/**
 * Ventana modal que indica un alerta de exito o advertencia despues de ingresar los datos del formulario
 * @param {*} param0 
 * @returns {JSX.Element} ModalEventAlert
 */
export const ModalEventAlert = ({ visible, setVisible, textosInterfaz, alertType }) => {
    return (
        <BaseModal
            windowClassName={"modal-events col-10 col-sm-9 col-md-6"}
            visible={visible}
            setVisible={setVisible}
            backdropClose={true}
        >
            <div className="d-flex flex-row-reverse">
                <button
                    aria-label="Cerrar ventana de dialogo"
                    className="close-button onbackground-primary--color focusable-red"
                    data-micromodal-close
                    onClick={() => setVisible && setVisible(false)}
                >
                    <Icon icon={'mdi:close-thick'} width={35} />
                </button>
            </div>

            <p className="text-center">
                {alertType ?
                    <Icon icon="ooui:alert" className="red-inverse--color" width={100} />
                    :
                    <Icon icon="ooui:success" className="green-inverse--color" width={100} />
                }
            </p>

            <div className="modal__content my-2" id="dialogo-modal-alertas">
                <p
                    className="second-category-item no-lineheight text-center mb-3 onbackground-third--color"
                    id="alert-message"
                >
                    {alertType ?
                        textosInterfaz?.alerta?.subtitle || '¡Ups! El correo ya se ha usado anteriormente'
                        :
                        textosInterfaz?.exito?.subtitle || 'Tu inscripción ha sido exitosa'
                    }
                </p>
                <p className="mb-5 text-center onbackground-third--color" id="alert-description">
                    {alertType ?
                        textosInterfaz?.alerta?.description ||
                        'Si no recibiste la información solicitada escríbenos a contacto@makemake.com.co'
                        :
                        textosInterfaz?.exito?.description || 'En breve recibirás un correo de confirmación y la información de acceso al evento. Revisa tu carpeta de spam si no llega la información'
                    }
                </p>

            </div>
            <button
                className="btn btn-primary d-block mx-auto focusable-red"
                onClick={() => setVisible(false)}
                data-micromodal-close
            >
                {textosInterfaz?.alerta?.button || 'Aceptar'}
            </button>
        </BaseModal>
    )
}