
import React from "react";

import { Img } from "../../components/shared/img";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

/**
 * Componente que retorna el mensaje si el formulario NO EXISTE
 * @returns {JSX.Element} FormDontExist
 */
export const FormDontExist = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textosInterfaz = useTranslations({ section: "eventos", lang: lang, array: false });

    return (
        <div className="event-back">
            <div className="container py-5">
                <div className="event-form">
                    <div className="row p-4">
                        <div className="col-lg-7 d-flex justify-content-center align-items-center m-3 mx-lg-0 my-lg-5 order-lg-2">
                            <div className="py-lg-5 secondary-font onprimary--color">

                                <h1 className="text-center text-line-h-20 my-3">
                                    {textosInterfaz?.no_existe?.title || '¡Este formulario no existe!'}
                                </h1>
                                <p className="text-center text-18" style={{ fontWeight: 600 }}>
                                    {textosInterfaz?.no_existe?.description || 'Asegurate que la URL contenga el identificador del evento existente'}
                                </p>
                                <p className="text-center text-18" style={{ fontWeight: 600 }}>
                                    {textosInterfaz?.no_existe?.example || 'Ejemplo: /eventos/formularios/2'}
                                </p>

                            </div>
                        </div>

                        <div className="col-lg-5 order-lg-1">
                            <Img src="/img/noresults.png" className="w-100 p-4 p-lg-0" style={{ maxWidth: 250 }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}