import { useEffect, useState } from "react";
import { useBookCollectionManager } from "../../mi-panel/MiColeccion/use-book-collection-manager";
import { ColeccionLibrosAPI } from "../../services/api/coleccion-libros";
import { CancelRequestController } from "../../util/cancel-request-controller";
/**
 * Hook personalizado para gestionar la selección de libros en una colección.
 *
 * @param {Object} params - Parámetros del hook.
 * @param {number} params.bookId - ID del libro que se está gestionando.
 * 
 * @returns {Object} Objeto con los siguientes valores y funciones:
 * - `loading` {boolean}: Indica si se están cargando los libros seleccionados.
 * - `add` {Function}: Función para añadir el libro a la colección.
 * - `remove` {Function}: Función para eliminar el libro de la colección.
 * - `isBookSelectionMode` {boolean}: Indica si el modo de selección de libros está activo.
 * - `maxBooks` {number}: Número máximo de libros que se pueden seleccionar.
 * - `currentSelectedBooksLength` {number}: Cantidad de libros actualmente seleccionados.
 * - `isCurrentAdded` {boolean}: Indica si el libro actual ya ha sido añadido a la colección.
 */

export const useBookSelection = ({bookId})=>{

    const bookSelectionManager = useBookCollectionManager({filters:null})
    const isBookSelectionMode = bookSelectionManager?.currentMode === 'BOOKS-SELECTION';
    const maxBooks = bookSelectionManager.maxBooks;
    const [currentSelectedBooksLength, setCurrentSelectedBooksLength] = useState(0);
    const [isAdded, setIsAdded] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchCurrentBooks = async ({signal})=>{
        setLoading(true);
        ColeccionLibrosAPI.obtenerListadoLibrosEscogidos({abortSignal:signal})
        .then(res=>{
            const books = Array.isArray(res?.libros)?res?.libros:[];
            const hasBook = books?.find(book=>book?.idLibro===bookId);
            setIsAdded(hasBook?true:false);
            const length = books?.length;
            setCurrentSelectedBooksLength(length);
        })
        .catch(error=>console.log(error))
        .finally(()=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        const abortController = new CancelRequestController();
        fetchCurrentBooks({signal:abortController.signal});
        return ()=>{
            abortController.abort();
        }
    },[bookId])

    const add = async ()=>{
        ColeccionLibrosAPI.agregarEliminarLibros({abortSignal:null, add:true, booksId:[bookId], selectionFinished:false})
        .then(res=>{
          setIsAdded(true);
          setCurrentSelectedBooksLength(value=>value+1)
        })
        .catch(error=>console.log(error))
    }
    
    const remove = async ()=>{
        ColeccionLibrosAPI.agregarEliminarLibros({abortSignal:null, add:false, booksId:[bookId], selectionFinished:false})
        .then(res=>{
            setIsAdded(false);
            setCurrentSelectedBooksLength(value=>value-1)
        })
        .catch(error=>console.log(error))
    }

    return {
        loading,
        add,
        remove,
        isBookSelectionMode,
        maxBooks,
        currentSelectedBooksLength,
        isCurrentAdded:isAdded
    }
}