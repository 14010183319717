import { Icon } from "@iconify/react";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Componente de notificación de estado de configuración de PWA, es una barra que aparece en la parte de abajo de la pantalla, tiene 3 estados:
 * - aparece en morado con letras blancas cuando se está confirgurando (activando,cacheando,instalando el service worker)
 * - aparece en verde con letras blancas cuando se ha configurado correctamente
 * - aparece en rojo con letras blancas cuando no se pudo configurar debido a un error.
 * 
 * @param {object} props - Propiedades del componente.
 * @param {boolean} props.configurado - Indica si la configuración está completada.
 * @returns {JSX.Element} Componente NotificacionEstadoPWAconfig.
 */
export const NotificacionEstadoPWAconfig = ({configurado}) => {
    const [visibilidad, setVisibilidad] = useState('visible');
    const {textosSubSecciones:textos} = useObtenerSubsecciones("global");
    const [configFallida, setConfigFallida] = useState(false);
    
    // Si no se puede configurar en el transcurso de 5 minutos, se indica que falló la configuración.

    useEffect(()=>{
        let timer;
        if(configurado===false){
            timer = setTimeout(()=>{
                setConfigFallida(true);
            },5 * 1000 * 60);
        }
        return ()=>{
            clearTimeout(timer);
            setConfigFallida(false);
        }
    },[configurado])



    // ocultar luego de configurar correctamente o al fallar.
    useEffect(()=>{
        let timer;
        if(configurado || configFallida){
            timer = setTimeout(()=>{
                setVisibilidad('hidden');
            },10 * 1000);
        }
        return ()=>{
            clearTimeout(timer);
            setVisibilidad('visible');
        }
    },[configurado, configFallida])

    return (
        <>
            <div ref={null} className={`notificacion-config-pwa ${Boolean(configFallida)?'configuracion-fallida':Boolean(configurado)?'configurado':'configurando'} ${visibilidad}`}>
                <div className="configurando" role={"alert"}>
                    <Icon icon="icomoon-free:spinner9" className={'icon spinner-rotation'} />
                    <p>{textos?.notificacion?.pwa?.config?.configurando || "Configurando descarga y lectura de libros offline, por favor espera..."}</p>
                </div>
                <div className="configurado" role={"alert"}>
                    <Icon icon="ci:check-bold" className={'icon'} />
                    <p>{textos?.notificacion?.pwa?.config?.configurado || 'Descarga y lectura de libros offline listo para usarse'}</p>
                </div>
                <div className="configuracion-fallida" role={"alert"}>
                    <Icon icon="bxs:error" className={'icon'} />
                    <p>{textos?.notificacion?.pwa?.config?.configFallida || 'No se pudo configurar la descarga y lectura de libros offline'}</p>
                </div>
            </div>
        </>
    )
}