export const COMMUNITY_SERVICES = [
    {
        title:{
            default:'Eventos en vivo con autores y artistas',
            translationPath:'eventos.titulo'
        },
        img:'/img/que-es/home/community/eventos.png',
        shapeColor:'rgba(142, 192, 69, 1)'
    },
    {
        title:{
            default:'Talleres y capacitaciones',
            translationPath:'talleres.titulo'
        },
        img:'/img/que-es/home/community/talleres.png',
        shapeColor:'rgba(212, 121, 243, 1)'
    },
    {
        title:{
            default:'Plan anual de lecturas recomendadas',
            translationPath:'lecturas_recomendadas.titulo'
        },
        img:'/img/que-es/home/community/plan-lecturas.png',
        shapeColor:'rgba(130, 85, 158, 1)'
    },
    {
        title:{
            default:'curadurías y colecciones temáticas',
            translationPath:'curadurias.titulo'
        },
        img:'/img/que-es/home/community/curadurias.png',
        shapeColor:'rgba(255, 209, 3, 1)'
    },
    {
        title:{
            default:'Guías para mediadores',
            translationPath:'guias.titulo'
        },
        img:'/img/que-es/home/community/guias.png',
        shapeColor:'rgba(235, 61, 77, 1)'
    },
]