import React, { useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import { useBlogMonths } from "../hooks/use-blog-months";
import { useBlogTags } from "../hooks/use-blog-tags";
import getUrlParam from "../../js/utilities/getUrl";

/**
 * renderiza los elementos input de búsqueda, selector de mes y tags para la pagina principal del blog
 * @param {object} textosBlog -contiene los textos de interfaz
 * @param {Function} setOptions - actualiza las opciones de búsqueda
 * @param {object} options - opciones seleccionadas
 * @returns {JSX.Element} SearchFormBlog
 */
export const SearchFormBlog = ({ textosBlog, setOptions, options }) => {
    const [optionSelect, setOptionSelect] = useState({ value: "", array: [] });
    const [selectedTag, setSelectedTag] = useState(getUrlParam("tags") !== undefined ? decodeURIComponent(getUrlParam("tags")) : "");
    const [searchItem, setSearchItem] = useState(getUrlParam("sch") !== undefined ? decodeURIComponent(getUrlParam("sch")) : "");

    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
    const mainSearchBlog = useRef(null);

    const months = useBlogMonths();
    const tags = useBlogTags();


    useEffect(() => {
        const opciones = {
            sch: searchItem,
            date: optionSelect,
            tag: selectedTag
        }
        setOptions(opciones)
    }, [optionSelect, selectedTag, searchItem])


    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    setSelectedTag("");
                    setOptionSelect({
                        ...optionSelect,
                        value: "",
                        array: []
                    })
                    setSearchItem(mainSearchBlog.current.value);
                }}
            >
                <fieldset className="row">
                    <div className="col-lg-6 col-xl-8">
                        <div className="blog-input-wrapper">
                            <button className="btn p-0 focusable-secondary" type="submit" aria-label={'Buscar el texto introducido'}>
                                <i
                                    className="search-icon fas fa-search onbackground-third--color"
                                    aria-hidden="true"
                                    id="search-icon"
                                ></i>
                            </button>
                            <input
                                aria-label="Search"
                                type="text"
                                className="focusable-secondary form-control"
                                defaultValue={searchItem}
                                ref={mainSearchBlog}
                                name="sch"
                                placeholder={textosBlog?.blog?.buscador || "Búsqueda por título, autor, editorial"}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 col-xl-4 my-4 my-lg-0">
                        <div className="blog-select-wrapper">
                            <i className="fas fa-chevron-down pr-2"></i>
                            <select
                                className="focusable-secondary"
                                value={options?.date?.value}
                                onChange={(e) => {
                                    Object.keys(months.listMonths).map((item) => {
                                        setSelectedTag("");
                                        mainSearchBlog.current.value = "";
                                        setSearchItem("");

                                        if (e.target.value === item) {
                                            setOptionSelect({
                                                ...optionSelect,
                                                value: e.target.value,
                                                array: months.listMonths[item]
                                            })
                                        }

                                        if(e.target.value === ""){
                                            setOptionSelect({
                                                ...optionSelect,
                                                value: e.target.value,
                                                array: []
                                            })
                                        }
                                    })
                                }}
                            >
                                {months.loading ?
                                    <option className="primary-font">
                                        {textosBlog?.blog?.cargando || 'cargando...'}
                                    </option>
                                    :
                                    <>
                                        <option
                                            className="primary-font"
                                            value=""
                                        >
                                            {textosBlog?.botones?.select || 'Selecciona un mes'}
                                        </option>
                                        {
                                            Object.keys(months.listMonths).map((item, i) => {
                                                return (
                                                    <option
                                                        key={i}
                                                        className="primary-font"
                                                        value={item}
                                                    >
                                                        {item}
                                                    </option>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </select>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="row d-flex align-items-center mb-4 my-lg-4">
                    <div className="col-lg-1 mb-4 mb-lg-0">
                        <p className="secondary-font secondary-inverse--color text-16 font-weight-bold">{textosBlog?.blog?.temas || 'Temas'}</p>
                    </div>

                    <div className="col-lg-11">
                        <div className="d-flex flex-wrap">
                            <div className="px-0 col-2 col-lg-1 d-flex justify-content-center align-items-center">
                                <button ref={prevButtonRef} className="blog-swiper-button-prev">
                                    <Icon icon="iconamoon:arrow-left-2-duotone" width={30} />
                                </button>
                            </div>

                            <div className="px-0 col-8 col-lg-10 ">
                                <Swiper
                                    className="blog-swiper w-100"
                                    slidesPerView={1}
                                    navigation={{
                                        nextEl: nextButtonRef?.current,
                                        prevEl: prevButtonRef?.current,
                                    }}
                                    modules={[Navigation]}
                                    spaceBetween={10}
                                    breakpoints={{
                                        526: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 4,
                                        },
                                        992: {
                                            slidesPerView: 5,
                                        },
                                        1200: {
                                            slidesPerView: 7,
                                        }
                                    }}
                                >
                                    {
                                        tags.loading ?
                                            <SwiperSlide className="blog-swiper-slide">
                                                <div className="blog-tag">
                                                    <i className="fas fa-spinner fa-pulse"></i>
                                                </div>
                                            </SwiperSlide>
                                            :
                                            <>
                                                {
                                                    tags?.data.map((item, i) => {
                                                        return (
                                                            <SwiperSlide className="blog-swiper-slide" key={`item-${i}`}>
                                                                <button
                                                                    className={`blog-tag ${options?.tag === item?.nombre ? 'activeMode' : ''}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (options?.tag !== item?.nombre) {
                                                                            mainSearchBlog.current.value = "";
                                                                            setSearchItem("");
                                                                            setSelectedTag(item?.nombre);
                                                                            setOptionSelect({
                                                                                ...optionSelect,
                                                                                value: "",
                                                                                array: []
                                                                            })
                                                                        }else{
                                                                            mainSearchBlog.current.value = "";
                                                                            setSearchItem("");
                                                                            setSelectedTag("");
                                                                            setOptionSelect({
                                                                                ...optionSelect,
                                                                                value: "",
                                                                                array: []
                                                                            })
                                                                        }
                                                                        
                                                                    }}
                                                                >
                                                                    {item?.nombre}
                                                                </button>
                                                            </SwiperSlide>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </Swiper>
                            </div>

                            <div className="px-0 col-2 col-lg-1  d-flex justify-content-center align-items-center">
                                <button ref={nextButtonRef} className="blog-swiper-button-next">
                                    <Icon icon="iconamoon:arrow-right-2-duotone" width={30} />
                                </button>
                            </div>
                        </div>

                    </div>
                </fieldset>
            </form>
        </>
    )
}