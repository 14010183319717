import React, { useContext, useState } from "react";

import _ from "lodash";
import { Icon } from "@iconify/react/dist/iconify.js";

import { CURSOS_DATA } from "../data/cursos";

import { store } from "../../../components/Store";
import { ReadFlag } from "../components/read-flag";
import { PlanCard } from "../components/plan-card";

import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { useWindowOpen } from "../../../hooks/useWindowOpen";
import { useEpayco } from "../../../hooks/useEpayco";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import ReactPixel from 'react-facebook-pixel';

/**
 * Componente que renderiza la sección de "Aprender algo nuevo" en el plan casa
 * @returns {JSX.Element} AprenderMakeMake
 */
export const AprenderMakeMake = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosAcademia = useTranslations({ section: "academia", lang: lang });
    const textosTienda = useTranslations({ section: "tienda_makemake", lang: lang });

    const courses = CURSOS_DATA;
    const globalState = useContext(store);
    const { state } = globalState;
    const { pais } = state;

    const [loading, setLoading] = useState({
       "tiempo-irrepetible": false,
        "leer-la-web": false,
        "ilustracion-botanica": false
    });

    const newWindowTab = useWindowOpen();
    const epayco = useEpayco();

    return (
        <>
            <ReadFlag title={textosTienda?.tabpanel?.aprender || "Aprender algo nuevo"} />

            <div className="d-flex flex-nowrap justify-content-center align-items-center mt-4">
                <p className="flex-grow secondary-inverse--color secondary-font text-16 font-weight-bold">
                    {textosTienda?.tienda?.casa?.subtitulo2 ||  "Educación complementaria para cualquier persona en cualquier lugar."}
                </p>
                <div className="flex-grow-1 secondary-inverse--bg ml-4" style={{ height: 2 }}></div>
            </div>

            <div className="col-xl-10 mx-auto px-0 mt-4">
                {courses.map((curso, index) => {
                    const finalPrice = curso?.precio && pais === 'CO' ? curso?.precio?.cop || 'n/a' : curso?.precio?.usd || 'n/a';
                    const moneda = pais === 'CO' ? 'COP' : 'USD';
                    const isOffer = curso?.descuento?.activado;
                    const offer = curso?.descuento && pais === 'CO' ? curso?.descuento?.cop || 'n/a' : curso?.descuento?.usd || 'n/a';
                    const isPreSale = curso?.preventa?.activado;
                    const idCurso = curso?.id; 

                    return (
                        <PlanCard.box key={`curso-${index}`}>
                            <PlanCard.columnLeft
                                className={curso?.className}
                                image={curso?.imgUrl}
                            >
                                <div
                                    className="card-description"
                                    tabIndex={0}
                                    aria-label={'descripción del curso'}
                                >
                                    <div>
                                        <p className="general-description">{_.get(textosAcademia, curso?.descripcion?.translationPath) || curso?.descripcion?.default}</p>
                                        <h4>{_.get(textosAcademia, curso?.nombre?.translationPath) || curso?.nombre?.default || 'N/A'}</h4>
                                        <p
                                            className="creator"
                                            dangerouslySetInnerHTML={{ __html: _.get(textosAcademia, curso?.autor?.translationPath) || curso?.autor?.default || 'N/A' }}
                                        ></p>
                                    </div>
                                </div>
                            </PlanCard.columnLeft>

                            <PlanCard.columnRight className={'column-right'}>
                                <PlanCard.details>
                                    <>
                                        <ul className="features-list mb-4 mb-lg-0 mt-lg-4">
                                            {curso.detalles.map((element, i) => {
                                                return (
                                                    <li key={`element-${i}`} dangerouslySetInnerHTML={{ __html: _.get(textosAcademia, element?.translationPath) || element?.default || 'N/A' }}></li>
                                                )
                                            })}
                                        </ul>
                                        {curso?.extraInformation?.activado &&
                                            <>
                                                <p
                                                    className="red-inverse--color font-weight-bold"
                                                    dangerouslySetInnerHTML={{ __html: _.get(textosAcademia, curso?.extraInformation?.titulo?.translationPath) || curso?.extraInformation?.titulo?.default || 'N/A' }}
                                                ></p>

                                                <ul className="features-list mb-4">
                                                    {curso.extraInformation.detalles.map((element, i) => {
                                                        return (
                                                            <li key={`element-${i}`} dangerouslySetInnerHTML={{ __html: _.get(textosAcademia, element?.translationPath) || element?.default || 'N/A' }}></li>
                                                        )
                                                    })}
                                                </ul>
                                            </>
                                        }
                                    </>
                                </PlanCard.details>
                                <PlanCard.PriceDescription>
                                    <div className="price-description mb-3 mb-lg-0 secondary-font">
                                        {
                                            isOffer &&
                                            <p className="font-weight-normal" >
                                                <span style={{ fontSize: '.7em', textDecorationLine: 'line-through' }}>
                                                    ${finalPrice} {moneda}
                                                </span>
                                            </p>
                                        }
                                        <p
                                            aria-label="moneda"
                                            className="font-weight-bold  onprimary--color"
                                        >
                                            {moneda}
                                        </p>
                                        <p className="font-weight-bold text-20 onprimary--color">${isOffer ? offer?.precio : finalPrice}</p>
                                        <p className="font-weight-bold onprimary--color">{textosAcademia?.interfaz?.precio || 'Precio por persona'}</p>
                                        {isPreSale &&
                                            <p
                                                className="red-inverse--color font-weight-bold text-14"
                                                dangerouslySetInnerHTML={{ __html: _.get(textosAcademia, curso?.preventa?.text?.translationPath) || curso?.preventa?.text?.default || 'N/A' }}
                                            ></p>
                                        }
                                    </div>
                                </PlanCard.PriceDescription>

                                <PlanCard.getButtons>
                                    <button
                                        className="btn-trailer mr-3 focusable-red"
                                        onClick={() => {
                                            ReactPixel.trackCustom('DescripcionCurso', {name:curso?.nombre?.default})
                                            newWindowTab.open('_blank', curso?.trailer)
                                        }}
                                    >
                                        <i className="fas fa-play mx-auto"></i>
                                        <p>{textosAcademia?.interfaz?.trailer || 'Ver trailer'}</p>
                                    </button>

                                    <button
                                        className="btn-tarjeta-plan focusable-red"
                                        onClick={() => {
                                            ReactPixel.trackCustom('CompraCurso', {name:curso?.nombre?.default})
                                            epayco.buy(curso?.epayco);
                                            
                                            setLoading({
                                                ...loading,
                                                [idCurso]:true
                                            })
                                        }}
                                    >
                                        {textosAcademia?.interfaz?.btn_comprar || 'Comprar'}  {' '}
                                        {loading?.[idCurso]  && <Icon icon="fa:spinner" className={'spinner-rotation'} />}
                                    </button>
                                </PlanCard.getButtons>
                            </PlanCard.columnRight>

                        </PlanCard.box>
                    )
                })}
            </div>
        </>
    )
}

