import React from "react";
import { Link } from "react-router-dom";
import { useTranslations } from "../../../hooks/use-translations";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

import { Helmet } from "react-helmet";

export const Hero = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const hero = textos?.hero;

	return (
		<div className="hero p-3 primary--bg d-md-flex justify-content-around primary-inverse--bordered">
			<Helmet>
                <title>{textos?.metadata?.title || 'MakeMake - Biblioteca Digital'}</title>
                <meta name="description" content={textos?.metadata?.description || 'Con la Biblioteca Digital MakeMake accede a una cuidadosa selección de libros digitales de +80 editoriales de América Latina y el mundo de gran reconocimiento. Diseñada para expandir los servicios bibliotecarios, impactar la lectura en colegios y promover el libro en familia.'} />
            </Helmet>
			<div className="d-flex justify-content-center align-items-center">
				<img src="/img/que-es/home/hero/purplehead.png" alt="imagen" />
			</div>
			<div className="d-flex flex-column justify-content-center align-items-center text-center px-4">
				<img src="/img/logos/logo-mkmk.svg" alt="imagen" className="my-3" style={{width:220}} />	
				<h1 className="secondary-font mb-3" style={{ lineHeight: "1.3em" }}>
					{hero?.titulo_1 || "Con la Biblioteca Digital MakeMake"}
					<br />
					{hero?.titulo_2 || "fomenta la lectura en tu colegio y en tu biblioteca"}
				</h1>
				<h3 className="font-weight-bold d-none d-lg-block">
					{hero?.titulo_3 || "Tenemos los mejores libros de América latina."}
				</h3>
				<Link className="btn btn-secondary my-3 focusable-red" to={"/tienda/demo"}>
					{" "}
					{hero?.boton?.demo || "Agendar un demo"}
				</Link>
			</div>
			<div className="d-none d-lg-flex flex-column justify-content-center align-items-center text-center">
				<img className="mb-4" src="/img/que-es/home/hero/blueman.png" alt="imagen" />
				<img src="/img/que-es/home/hero/matilda.png" alt="imagen" />
			</div>
		</div>
	);
};
