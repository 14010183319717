import React, { useEffect, useState, useRef, useContext } from "react";

import {
    AccesibilidadContext,
    FuentesDisponibles,
    TemasDisponibles,
} from "../../contexts/AccesibilidadContext";

import { Icon } from "@iconify/react";
import { FocusScope } from "react-aria";

import { useAccessibility } from "../../hooks/useAccessibility";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

import { LanguageSelector } from "../advanced-search/language-selector";
import { store } from "../Store";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";


/**
 * Menu que contiene la configuración de accesibilidad del sitio
 * @returns {React.Component}
 */
export const Accessibility = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });

    const accebilidadState = useContext(AccesibilidadContext);
    const [esTemaClasico, setEsTemaClasico] = useState(true);
    const InputTamanoref = useRef(null);

    const showAccessibility = useAccessibility();
    const visible = showAccessibility?.state?.visible;
    const globalState = useContext(store);
    const triggerButton = globalState?.state?.accessibility?.triggerButton;
    const ref = useRef(null);

    const hide = () => {
        showAccessibility.setVisible(false);
    }

    const handleOutsideClick = (event) => {
        if (triggerButton?.current?.contains(event.target)) return;
        if (ref.current && !ref.current.contains(event.target)) {
            showAccessibility.setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [triggerButton]);


    /**Cada vez que se monte este componente se activara el focus en el input tamaño de fuente */
    useEffect(() => {
        if (InputTamanoref.current) {
            InputTamanoref.current.focus();
        }
    }, []);

    /*al hacer scroll se actualiza el estado de mostrar u ocultar el panel de accesibilidad */
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 1 && window.scrollY < 10) {
                showAccessibility.setVisible(false);
            }
        });
    }, [visible]);

    /**Actualiza el estado temaClasico */
    useEffect(() => {
        let tema = accebilidadState?.temaActual !== "clasico" ? false : true;
        setEsTemaClasico(tema);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accebilidadState]);

    const cambiarTipoFuente = (valor) => {
        accebilidadState.actualizarFuente(valor || "predeterminado");
    };

    const cambiarTamanoFuente = (valor) => {
        accebilidadState.actualizarTamanioFuente(valor);
    };

    const cambiarTema = (valor) => {
        accebilidadState.actualizarTema(valor);
    };

    const restablecerConfiguracion = () => {
        accebilidadState.actualizarTema(TemasDisponibles.clasico.id);
        accebilidadState.actualizarTamanioFuente(18);
        accebilidadState.actualizarFuente(FuentesDisponibles.predeterminado.id);
    };

    return (
        <>
            <div className={`menu-accesibilidad-flotante ${visible === false && 'collapsedMode'}`}>
                <FocusScope contain restoreFocus autoFocus>
                    <section
                        ref={ref}
                        onClick={(e) => e.target === e.currentTarget && hide()}
                        className={`menu-accesibilidad ${visible ? "expanded" : "collapsed"
                            } text-fontSize`}
                    >
                        <div className="item-languaje">
                            <p className="text-idioma d-none d-md-block">
                                {textosInterfaz?.idioma || "Idioma:"}{" "}
                            </p>
                            <div className="d-flex justify-content-center align-items-center">
                                <LanguageSelector />
                            </div>
                        </div>

                        <div className="item">
                            <label className="label text-fontSize">
                                {textosInterfaz?.tamano_fuente || "Tamaño de fuente:"}{" "}
                                {/* <strong className="numeroPx onprimary-color">
								<span>{accebilidadState?.tamanioFuente}</span>px
							</strong> */}
                            </label>
                            <input
                                type="range"
                                min="16"
                                max="32"
                                step="2"
                                value={accebilidadState?.tamanioFuente}
                                ref={InputTamanoref}
                                aria-label={textosInterfaz?.tamano_fuente || "Tamaño de fuente:"}
                                className={`focusable-red range-slider__range_settings ${!esTemaClasico && "onbackground-primary--bordered"
                                    }`}
                                onChange={(e) => {
                                    cambiarTamanoFuente(e.currentTarget.value);
                                }}
                            />
                            <div
                                className="d-none d-xl-flex px-0 primary-inverse--color"
                                style={{ fontSize: "16px" }}
                            >
                                <div className="col-6 px-0">100%</div>
                                <div className="col-6 px-0 d-flex justify-content-end">150%</div>
                            </div>
                        </div>
                        <div className="item">
                            <label className="label text-fontSize">
                                {textosInterfaz?.tipo_fuente || "Tipo de fuente:"}
                            </label>
                            <div className="d-flex px-0 mt-3">
                                {Object.values(FuentesDisponibles).map((fuente, index) => {
                                    const font = fuente?.id === "predeterminado" ? "Quicksand" : fuente?.id === "dyslexic" ? "OpenDyslexic" : fuente.id;

                                    return (
                                        <div
                                            key={`fuente${index}`}
                                            className="col-3 px-0 d-flex justify-content-center"
                                        >
                                            <button
                                                className={`tipo-fuente ${fuente?.id === accebilidadState?.fuente && "activeMode"}`}
                                                onClick={() => {
                                                    cambiarTipoFuente(fuente?.id);
                                                }}
                                            >
                                                <div
                                                    className="letra"
                                                    style={{
                                                        fontFamily: font,
                                                        fontWeight: index === 0 ? "600" : "500"
                                                    }}
                                                >
                                                    {fuente?.id !== "dyslexic" ? "A" : "a"}
                                                </div>
                                                {textosInterfaz?.[fuente.id]
                                                    ? textosInterfaz?.[fuente.id]
                                                    : fuente?.nombre}
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>


                        </div>
                        <div className="item">
                            <label className="label mb-0 text-fontSize">
                                {textosInterfaz?.color_contraste || "Color y contraste:"}
                            </label>
                            <div className="d-flex px-0 mt-3">
                                <div className="col-6 px-0">
                                    <button
                                        className={`btn-original`}
                                        onClick={() => {
                                            cambiarTema(TemasDisponibles.clasico.id);
                                        }}
                                    >
                                        {textosInterfaz?.[TemasDisponibles.clasico.id]
                                            ? textosInterfaz?.[TemasDisponibles.clasico.id]
                                            : TemasDisponibles?.clasico?.nombre}
                                    </button>
                                </div>
                                <div
                                    className="col-6 px-0"
                                    onClick={() => {
                                        cambiarTema(TemasDisponibles.altoContraste.id);
                                    }}
                                >
                                    <button className={`btn-alto-contraste`}>
                                        {textosInterfaz?.[TemasDisponibles.altoContraste.id]
                                            ? textosInterfaz?.[TemasDisponibles.altoContraste.id]
                                            : TemasDisponibles?.altoContraste?.nombre}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button
                            className={`reset-button text-fontSize focusable-red ${!esTemaClasico && "onprimary--border"
                                }`}
                            onClick={() => restablecerConfiguracion()}
                        >
                            {textosInterfaz?.restablecer || "Restablecer"}
                        </button>
                        <button
                            aria-label="cerrar ajustes de accesibilidad"
                            className=" close-button focusable-red"
                            onClick={() => showAccessibility.setVisible(false)}
                        >
                            <Icon icon="mdi:close-thick" />
                        </button>
                    </section>
                </FocusScope>
            </div>

        </>
    );
};