import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const SkeletonFichaEditorial = (props) => {
    return (
        <SkeletonTheme color="#e8e8e8" highlightColor="#f7f7f7">
            <div className="main-container">
                <div className="coleccion-container" style={{ backgroundColor: "#fff" }}>
                    <div className="coleccion-background coleccion-editorial" style={{ background: "#fff url('/img/background.png') 50%/cover fixed repeat" }}></div>
                    <div className="container pb-lg-5">
                        <div className="row pt-5 mb-5">
                            <div className="col-lg-6 mb-5 mb-lg-0">
                                <h1>
                                    <Skeleton className="col-1 pr-5 pr-lg-0 col-lg-12 pl-0 mb-3" count={1} height={45} />
                                </h1>
                                <div className="row justify-content-end mt-4 mx-0">
                                    <Skeleton height={200} width={200} />
                                </div>
                            </div>
                            <div className="col-lg-6 pl-lg-5 ">
                                <div className="fichalibro-data fichalibro-data--text fichaeditorial p-4 p-sm-3 p-lg-5">
                                    <div className="data--tab tab-active " id="editorial-resena">
                                        <div className="container-tabs">
                                            <Skeleton className="mb-1" height={25} width={200}/>
                                            <Skeleton className="mb-1" count={6} height={25}/>
                                            <div className="row justify-content-end mt-4 mx-0">
                                                <Skeleton className="px-4 mt-3" height={50} width={180}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 pb-5 mt-lg-0 align-items-end">
                            <div className="row">
                                {(()=>{
                                    var libros=[];
                                    for (let i = 0; i < 6; i++) {
                                        libros.push(
                                            <div className={'col-6 col-sm-4 col-md-3 col-lg-2 mb-4'} key={`skeleton-libro-editorial-${i}`}>
                                                <Skeleton height={250}/>
                                            </div>
                                            
                                        )                                        
                                    }
                                    return libros;
                                })()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

export default SkeletonFichaEditorial;

