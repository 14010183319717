import { useEffect, useMemo, useRef, useState } from "react";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { EventosAPI } from "../../services/api/Eventos";
import moment from "moment";

let timer;
export const useEvents = ({ month }) => {
	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState([]);
	const prevAbortControllerRef = useRef();
	const memoizedMonth = useMemo(() => {
		return month;
	}, [month]);

	const fetchEvents = async () => {
		if (timer) {
			clearTimeout(timer);
		}
		if (prevAbortControllerRef.current) {
			prevAbortControllerRef.current.abort();
		}
		const abortController = new CancelRequestController();
		prevAbortControllerRef.current = abortController;
		setLoading(true);
		await EventosAPI.getAll({ abortSignal: abortController.signal })
			.then((res) => {
				let filtered = res.filter(
					(month) =>
						moment(memoizedMonth?.date).isSame(month?.fecha, "month") &&
						moment(memoizedMonth?.date).isSame(month?.date, "year")
				);
				setEvents(filtered);
			})
			.catch((error) => {
				setEvents([]);
			})
			.finally(() => {
				timer = setTimeout(() => {
					setLoading(false);
				}, 100);
			});
	};
	useEffect(() => {
		fetchEvents();
	}, [memoizedMonth]);

	return {
		loading,
		events,
	};
};
