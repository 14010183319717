import React, {  useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import _ from 'lodash';

import { PLANES_DATA } from "../data/planes";

import { BarraCompra } from "../components/barra-compra";
import { Accordion } from "../../../components/shared/accordion";
import { Breadcrumbs } from "../../../components/shared/breadcrums";

import { useEpayco } from "../../../hooks/useEpayco";
import { useQueryParams } from "../../../hooks/useQueryParams";
import useObtenerSubsecciones from "../../../js/translations/useObtenerSubsecciones";
import ReactPixel from 'react-facebook-pixel';


/**
 * Este componente renderiza la pantalla con la información del plan 'libro favorito', 
 * internamente identifica que tipo de plan es (trimestral o semestral) a través de los
 * query params y muestra los precios e información correspondientes al plan.
 * @returns {JSX.Element} PlanLibroFavorito
 */
export const PlanLibroFavorito = () => {
    const { textosSubSecciones: textos } = useObtenerSubsecciones('tienda_makemake');
    const params = useQueryParams();
    const availableTypes = ['semestral'];
    const [type, setType] = useState(null);
    const epayco = useEpayco();

    const getPlan = () => {
        const plan = {
            ...PLANES_DATA?.[type]?.find(plan => plan?.id?.includes('libro-favorito'))
        };
        return plan ? plan : {};
    }

    const data = getPlan();
    const preguntasFrecuentes = Array.isArray(data?.preguntasFrecuentes) ? data?.preguntasFrecuentes : [];


    const QuestionAccordion = () => {
        return <Accordion.Container>
            {
                preguntasFrecuentes.map((item, i) => {
                    return (
                        <Accordion.Item key={i} itemId={`item-${i}`}>
                            <Accordion.ItemButton label={_.get(textos, item?.pregunta?.translationPath) || item?.pregunta?.default || 'N/A'} />
                            <Accordion.Content>
                                <p className="onbackground-primary--color">
                                    {
                                        item?.respuesta?.html ?
                                            <span dangerouslySetInnerHTML={{ __html: _.get(textos, item?.respuesta?.translationPath) || item?.respuesta?.default || 'N/A' }}></span>
                                            :
                                            <>{_.get(textos, item?.respuesta?.translationPath) || item?.respuesta?.default || 'N/A'}</>
                                    }
                                </p>
                            </Accordion.Content>
                        </Accordion.Item>

                    )
                })
            }
        </Accordion.Container>
    }



    useEffect(() => {
        const _type = params.get('tipo');
        if (availableTypes.includes(type)) {
            setType(_type);
        } else {
            setType('semestral');

        }
    }, [params])

    return (<section>
        <Breadcrumbs.Box className={'plCasa-breadcrums  p-3'}>
            <Breadcrumbs.Crumb
                className={`crumb una-linea-texto`}
                activeClassName={'current'}
                name={textos?.individual?.libro_favorito?.breadcrumb_1 || 'Leer en MakeMake'}
                url={'/tienda/plan-casa?tipo=semestral'} />
            <Breadcrumbs.Crumb
                className={`crumb una-linea-texto`}
                activeClassName={'current'}
                name={textos?.individual?.libro_favorito?.breadcrumb_2 || 'Mi libro favorito'}
                url={`/tienda/plan-casa/libro-favorito?tipo=${type}`}
                last={true} />
        </Breadcrumbs.Box>
        <div className="plan-section-layout libro-favorito">
            <div className="d-lg-flex justify-content-between">
                <div className="sidenav" >
                    <h1 className="title">
                        {_.get(textos, data?.nombre?.translationPath) || data?.nombre?.default || 'N/A'}
                    </h1>
                    <div className="details"  >
                        {
                            data?.detalles?.map((detalle, i) => {
                                return <p className="detail" key={i}>
                                    <Icon className="icon" icon="mdi:check-bold" />
                                    {
                                        detalle?.html ?
                                            <span dangerouslySetInnerHTML={{ __html: _.get(textos, detalle?.translationPath) || detalle?.default || 'N/A' }}></span>
                                            :
                                            <>{_.get(textos, detalle?.translationPath) || detalle?.default || 'N/A'}</>
                                    }
                                </p>
                            })
                        }

                    </div>
                    <div className="desktop-accordion">
                        {QuestionAccordion()}
                    </div>
                </div>
                <div className="content_layout">
                    <div className="section-plan-card d-xl-flex">
                        <div className="yellow-plan image col-xl-7 px-0 order-lg-2">
                            <div
                                className="img"
                                style={{ backgroundImage: `url(${data?.imgUrl?.normal || '/img/no-imagen3.png'}) ` }}></div>
                        </div>
                        <div className="col-xl-5 p-0 order-lg-1">
                            <div className="text-background py-3">
                                <h2 className="mx-auto text-center ">  {_.get(textos, data?.nombre?.translationPath) || data?.nombre?.default || 'N/A'}</h2>
                            </div>
                            <div className="d-flex flex-column my-auto book-card p-3">
                                <h2 className="mx-autor p-3 onprimary--color">  {_.get(textos, data?.descripcionFicha?.translationPath) || data?.descripcionFicha?.default || 'N/A'}</h2>
                                <p className="secondary-inverse--color font-weight-bold p-3"> {textos?.individual?.libro_favorito?.ideal_para || 'Ideal para:'}</p>
                                <ul className="onbackground-primary--color p-3" style={{ listStyle: "square" }}>
                                    {
                                        data?.idealPara?.map((item, i) => {
                                            return <li key={i}>{_.get(textos, item?.translationPath) || item?.default || 'N/A'}</li>
                                        })
                                    }
                                </ul>
                                <BarraCompra
                                    enableBuyButton={true}
                                    stylePrice={"price-info-new"}
                                    offer={data?.descuento}
                                    priceText={data?.precio}
                                    onClick={() => {
                                        ReactPixel.trackCustom('CompraPlan', {name:data?.nombre?.default});
                                        epayco.buy(data?.epayco)
                                    }} />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="mobile-accordion">
                    {QuestionAccordion()}
                </div>
            </div>
        </div>

    </section>
    )
}