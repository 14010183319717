import { useContext } from "react";
import { store } from "../../components/Store";
/**
 * Hook personalizado para gestionar la visibilidad del buzón de notificaciones.
 * 
 * Este hook proporciona funciones para mostrar, ocultar y verificar la visibilidad del buzón de notificaciones.
 * 
 * @returns {{show: Function, hide: Function, isVisible: boolean}} Objeto con las siguientes propiedades:
 * - show: Función para mostrar el buzón de notificaciones.
 * - hide: Función para ocultar el buzón de notificaciones.
 * - isVisible: Booleano que indica si el buzón de notificaciones está visible.
 */

export const useNotificationInboxVisibility = () => {
    const globalState = useContext(store);
    const isVisible = globalState?.state?.notifications?.visibleInbox;


    const show = () => {
        globalState.dispatch({
            type: "CHANGE",
            path: "notifications.visibleInbox",
            value: true,
        });
    }

    const hide = () => {
        globalState.dispatch({
            type: "CHANGE",
            path: "notifications.visibleInbox",
            value: false,
        });
    }
    return { show, hide, isVisible }
}