import React, { useRef, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { GENERAL_URL_API } from "../components/Store";
import postAjax from './../js/utilities/postAjax';
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Este componente no recibe props. Este se encarga de renderizar el primer paso de demo (¿Ya estás en MakeMake?). Sus principales funciones son obtener las instituciones activas y enviar el formulario con la institución elegida.
 * @returns DemoEscogerInstitucion
 */
const DemoEscogerInstitucion = () => {
  const { isReady, textosSubSecciones: textosDemo } = useObtenerSubsecciones('demo');
  const [instituciones, setInstituciones] = useState([]);
  const [notificacionInstituto, setNotificacionInstituto] = useState(false);

  const refSelectInstituto = useRef(null);
  const history = useHistory();


  const enviarInstituto = (e) => {
    e.preventDefault();
    const value = refSelectInstituto.current.value;

    if (value === 'noInst') {
      history.push('/demo/demo-ingresar-datos')
      setNotificacionInstituto(false)
    } else if (value !== 'noSel') {
      history.push(`/demo/demo-ingresar-correo?it=${value}`)
      setNotificacionInstituto(false)
    }else{
      setNotificacionInstituto(true)
    }
  }

  useEffect(() => {
    // postAjax(`${GENERAL_URL_API}/assets/institucionesActivas`, {}, response => {
    postAjax(`${GENERAL_URL_API}/assets/institucionesActivas`, {}, response => {
      const data = JSON.parse(response)
      setInstituciones(data.institutos);
    })
  }, [])

  return (
    <>
      {isReady &&
        <div className="col-lg-8 mx-auto pt-3 pb-4">
          <h2 className="mm-redimir_title mt-3 mb-4 text-center pb-3">
            <span aria-hidden={true}>1</span> {textosDemo?.ya_estas_mkmk?.titulo || '¿Ya estás en MakeMake?'}
          </h2>

          <p className="text-center onbackground-third--color mb-5">
            {textosDemo?.ya_estas_mkmk?.descripcion || 'Revisa esta lista de instituciones suscritas a MakeMake y selecciona si haces parte o estas afiliado a alguna de ellas.'}
          </p>

          <form className="mm-redimir_form my-5" id="revisarTipoDemo">
            <p className="secondary-item secondary-inverse--color"><strong>{textosDemo?.ya_estas_mkmk?.lista || 'Revisa esta lista:'}</strong></p>
            <div className="row mx-0 align-items-start">
              <div className="col-md-9 pl-0">
                <div className="form-group mb-0">

                  <select
                    className="col-12 form-control surface--bg  onbackground-third--color mb-4 mb-md-0 focusable-secondary"
                    ref={refSelectInstituto}
                    defaultValue={textosDemo?.ya_estas_mkmk?.select_instituto?.placeholder || 'Selecciona un instituto'}
                    onChange={()=> setNotificacionInstituto(false)}
                  >
                    <option value="noSel">
                      {textosDemo?.ya_estas_mkmk?.select_instituto?.placeholder || 'Selecciona un instituto'}
                    </option>
                    {
                      instituciones.length !== 0 && instituciones.map((instituto, index) => {
                        return <option key={`option-${index}`} value={instituto}>{instituto}</option>
                      })
                    }
                    <option value="noInst">{textosDemo?.ya_estas_mkmk?.select_instituto?.no_esta || 'Mi instituto no aparece en la lista'}</option>
                  </select>

                  <span role={"alert"} aria-live="assertive" className="notificacion-form">
                    {notificacionInstituto ? textosDemo?.ya_estas_mkmk?.alert || 'Para enviar este formulario debes seleccionar una institucion' : ''}
                  </span>
                </div>
              </div>
              <button className="btn btn-primary d-block col-md-3 focusable-red" type="submit" onClick={enviarInstituto}>
                {textosDemo?.botones?.continuar || 'Continuar'}
              </button>
            </div>
          </form>

          <div className="mm-redimir_form row py-4">
            <div className="mm-redimir_questioncode relative">
              <p
                className="pl-4 mm-redimir_questioncodeactivate onbackground-third--color pointer already-mm"
                tabIndex={0}
                aria-label={textosDemo?.ya_estas_mkmk?.tooltip || 'Puede que tu colegio, biblioteca o empresa ya sea usuaria de MakeMake y no necesites solicitar un demo. Nosotros te ayudaremos a contactar a tu institución para que puedas ingresar a MakeMake'}
              >
                {textosDemo?.ya_estas_mkmk?.pregunta || '¿Por qué tengo que ingresar el nombre de una institución?'}
                <i className="fas fa-question-circle fa-fw fa-lg tooltip">
                  <span className="tooltiptext" id="descript-tooltip" aria-live="assertive">
                    {textosDemo?.ya_estas_mkmk?.tooltip || 'Puede que tu colegio, biblioteca o empresa ya sea usuaria de MakeMake y no necesites solicitar un demo. Nosotros te ayudaremos a contactar a tu institución para que puedas ingresar a MakeMake'}
                  </span>
                </i>
              </p>
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default DemoEscogerInstitucion;