import { Icon } from "@iconify/react"
import React from "react"
/**
 * Componente de tipo 'footer' que muestra la información de contacto.
 * @returns {React.Component}
 */
export const Footer = ()=>{
    return ( <footer className="search-footer">
      <Icon icon="material-symbols:contact-phone" className="onsecondary--color mx-2" width={25} />
    <a href="mailto:contacto@makemake.com.co"
          className="d-block focusable-red" aria-label="correo: contacto@makemake.com.co ">
          contacto@makemake.com.co
        </a>
        <span className="d-none d-md-inline-block mx-2 onsecondary--color"> / </span>
        <a
          aria-label="télefono 1: +57 350 236 7037"
          href="tel:+573502367037"
          className="focusable-red"
        >
          +57 350 236 7037
        </a>
        <span className="onsecondary--color mx-2"> / </span>
        <a
          aria-label="télefono 2: +57 350 201 1041"
          href="tel:+573502011041"
          className="focusable-red"
        >
          +57 350 201 1041
        </a>
        <span className="onsecondary--color mx-2"> / </span>
        <a
          aria-label="télefono 3: +1 (800) 345-5946"
          href="tel:+1(800)345-5946"
          className="focusable-red"
        >
          +1 (800) 345-5946
        </a>
    </footer>)
}
