
import postAjax from "../utilities/postAjax";

import { GENERAL_URL_API } from '../../components/Store.js';

/**
 * Actualiza el valor del api a través de las funciones setTSConc, setStatsSession y setStatsLibro
 */
export function setTSConc() {
  // Setup jQuery ajax mock:

  var url = `${GENERAL_URL_API}/assets/bpc`;

  postAjax(url, { "updateTS": "1" }, function (data) { });


}

export function setStatsSession() {

  var url = `${GENERAL_URL_API}/assets/bpc`;

  postAjax(url, { "updateSession": "1" }, function (data) { });
}

export function setStatsLibro() {
  if (window.location.pathname.startsWith('/visor'))//if(window.location.pathname=="/visor/")
  {
    window.location.pathname.split("/").forEach((subString) => {
      if (subString.startsWith("MM")) {
        var idLibro = subString;
        var url = `${GENERAL_URL_API}/assets/bpc`;
        postAjax(url, { "updateBookStats": "1", "bookId": idLibro }, function (data) { });
      }
    });

  }
  // Setup jQuery ajax mock:

}


