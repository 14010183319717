import React, { useContext, useState, useEffect, useRef } from "react";
import { store } from "../../../components/Store";
import { useImmer } from "use-immer";

import { CancelRequestController } from "../../../util/cancel-request-controller";
import { DatosPaisesAPI } from "../../../services/api/datosPaises";

import { ModalEventAlert } from "../modal-event-alert";
import { EventsLogInRequest } from "../events-log-in-request";

import { ModalLimitAlert } from "../modal-limit-alert";

import { EventosFormularioAPI } from "../../../services/api/FormularioEventos";
import { EventHeader } from "../EventHeader";
import getUrlParam from "../../../js/utilities/getUrl";

/**
 * Componente del formulario exclusivo
 * @param {object} props 
 * @returns {JSX.Element} FormExclusiveEvent
 */
export const FormExclusiveEvent = ({ eventData, textosInterfaz, lang }) => {

    const globalState = useContext(store);
    const { state } = globalState;
    const country = state?.pais;
    const language = state?.idioma;
    const institution = state?.datosHeader?.institucion || 'N/A';
    const isLogged = globalState.state.sesionActiva !== null && globalState.state.sesionActiva === "SI";

    const [sortedCountries, setSortedCountries] = useState([]);

    const [validacionCorreo, setValidacionCorreo] = useState(true);
    const [showModalLogIn, setShowModalLogIn] = useState(false);
    const [showModalAlert, setShowModalAlert] = useState(false);
    const [isModalAlert, setIsModalAlert] = useState(false);
    const [otherCountry, setOtherCountry] = useState(false);
    const [showModalLimit, setShowModalLimit] = useState(false);
    const [paisSelecccionado, setPaisSelecccionado] = useImmer({ nombrePais: "", codigoPais: "" });
    const [paises, setPaises] = useState([]);
    const [preloader, setPreloader] = useState(false);
    const [group, setGroup] = useImmer({ otherGroup: false, value: "" });

    const [checkAge, setCheckAge] = useState(false);

    const refFormulario = useRef(null);
    const refNombre = useRef(null);
    const refCorreo = useRef(null);
    const refConfirmarCorreo = useRef(null);
    const refPais = useRef(null);
    const refOtroPais = useRef(null);
    const prevAbortControllerRef3 = useRef();
    const refGrupo = useRef(null);
    const refSelectGrupo = useRef(null);

    const nombreEvento = eventData?.id || '';

    /*Pone de primeras el pais del usuario en la lista de paises.*/
    const myCountryFirst = (data) => {
        const con = country ? country : "CO";
        if (Array.isArray(data) && con) {
            const _countries = [...data].filter((c) => c?.codigo !== con);
            const c = [...data].find((c) => c?.codigo === con);
            if (c) {
                if (con === "US") {
                    language === "en"
                        ? (c.nombre = "United States of America")
                        : (c.nombre = "Estados Unidos de América");
                }
                setSortedCountries([c, ..._countries]);
            }
        }
    };

    useEffect(() => {
        let abortController = new CancelRequestController();

        //Utiliza el servicio obtenerLatamUSA de DatosPaisesAPI y obtiene la lista de paises de Latam más USA
        const listaPaises = async () => {
            await DatosPaisesAPI.obtenerLatamUSA({
                abortSignal: abortController.signal,
            })
                .then((res) => {
                    setPaises(Array.isArray(res) ? res : []);
                })
                .catch((error) => console.log(error));
        };
        listaPaises();
        return () => {
            abortController.abort();
        };
    }, []);


    useEffect(() => {
        myCountryFirst(paises);
    }, [paises, country, language]);

    useEffect(() => {
        let nombre = getUrlParam("nombre");
        let correo = getUrlParam("correo");
        let otro = getUrlParam("otro");
        let pais = getUrlParam("pais");
        let edad = getUrlParam("edad");
        let grupo = getUrlParam("grupo");
        let otroGrupo = getUrlParam("otroGrupo");

        if (nombre !== undefined && refNombre.current) {
            refNombre.current.value = decodeURIComponent(nombre);
        }

        if (correo !== undefined && refCorreo.current) {
            refCorreo.current.value = correo;
            refConfirmarCorreo.current.value = correo;
        }

        if (otro !== undefined &&  pais !== undefined && refPais.current) {
            let _pais = decodeURIComponent(pais);
            let isOther = otro === "true" ? true : false;
            setOtherCountry(isOther);

            if(isOther  && refOtroPais.current){
                refPais.current.value = "Otro";
                refOtroPais.current.value = _pais;
            }else{
                sortedCountries.forEach((item) => {
                    if (item?.nombre === _pais) {
                        refPais.current.value = item?.codigo;
                        setPaisSelecccionado((draft) => {
                            draft.nombrePais = item?.nombre;
                            draft.codigoPais = item?.codigo;
                        });
                    }
                });
            }
        }

        if (otroGrupo !== undefined && grupo !== undefined && refSelectGrupo.current) {
            let _grupo = decodeURIComponent(grupo);
            let isOtherGroup = otroGrupo === "true" ? true : false;

            setGroup((draft) => {
                draft.otherGroup = isOtherGroup;
                draft.value = _grupo;
            });

            if (isOtherGroup && refGrupo.current) {
                refSelectGrupo.current.value = "Otro";
                refGrupo.current.value = group?.value;
            } else {
                refSelectGrupo.current.value = group?.value;
            }
        }

        if (edad !== undefined) {
            let _edad = edad === "true" ? true : false;
            setCheckAge(_edad);
        }
    }, [sortedCountries, refNombre.current, refCorreo.current, refConfirmarCorreo.current, refPais.current, refSelectGrupo.current]);

    const validarPais = (valor) => {
        let isOther = refPais.current.value === "Otro" ? true : false;
        setOtherCountry(isOther);

        sortedCountries.map((pais) => {
            if (pais?.codigo === valor) {
                setPaisSelecccionado((draft) => {
                    draft.nombrePais = pais?.nombre;
                    draft.codigoPais = pais?.codigo;
                });
            }
        });
    };

    /**
     * Esta función retorna una alerta en caso de que se ejecute un evento de tipo copiar o pegar en un campo determinado.
     * @param {Event} e
     * @returns {alert}
     */
    const alertaEvento = (e) => {
        e.preventDefault();
        return alert("Está acción no está permitida.");
    };

    const validacionCamposCorreo = () =>
        setValidacionCorreo(
            refCorreo.current.value === refConfirmarCorreo.current.value
                ? true
                : false
        );


    const enviarFormulario = async (e) => {
        e.preventDefault();

        if (refFormulario?.current.reportValidity() && validacionCorreo) {
            setPreloader(true);

            if (isLogged) {

                if (prevAbortControllerRef3.current) {
                    prevAbortControllerRef3.current.abort();
                }
                const abortController = new CancelRequestController();
                prevAbortControllerRef3.current = abortController;

                const propiedades = {
                    idFormulario: eventData?.id || '',
                    tipoEvento: eventData?.tipoEvento || '',
                    nombreCompleto: refNombre.current.value,
                    correo: refCorreo.current.value,
                    pais: otherCountry
                        ? `Otro - ${refOtroPais.current.value}`
                        : paisSelecccionado?.nombrePais || "",
                    perteneceInstitucion: 1,
                    nombreInstitucion:  institution,
                    accesoMakeMake: 1,
                    edad: checkAge ? 1 : 0,
                    grupo: group?.otherGroup
                        ? `Otro - ${refGrupo?.current.value}`
                        : group?.value,
                };

                await EventosFormularioAPI.sendRegistration({
                    abortSignal: abortController.signal,
                    dataForm: propiedades
                })
                    .then((response) => {
                        const data = response;
                        const error = data.status === 0;

                        if (!error) {
                            if(data.info === "Registro exitoso, el formulario ha alcanzado el límite de inscritos."){
                                setShowModalLimit(true);
                                setShowModalAlert(false);
                                setShowModalAlert(false);
                            }else{
                                setIsModalAlert(false)
                                setShowModalAlert(true);

                                refNombre.current.value = "";
                                refCorreo.current.value = "";
                                refConfirmarCorreo.current.value = ""; 
                                refPais.current.value = "";
                                refSelectGrupo.current.value = "";
                                if(refOtroPais.current){
                                    refOtroPais.current.value = "";
                                }
                                if (refGrupo.current) {
                                    refGrupo.current.value = "";
                                }
                                setCheckAge(false);
                            }
                        } else {
                            setShowModalLimit(false);
                            setIsModalAlert(true)
                            setShowModalAlert(true);                            
                        }
                    })
                    .catch((error) => console.log(error))
                    .finally(() => {
                        setPreloader(false);
                    });
            } else {
                setShowModalLogIn(true)
            }

        }
    }

    const validarGrupo = (valor) => {
        let isOther = valor === "Otro" ? true : false;

        setGroup((draft) => {
            draft.otherGroup = isOther;
            draft.value = valor;
        });
    };

    return (
        <>
            <EventHeader
                eventData={eventData}
                lang={lang}
                textosInterfaz={textosInterfaz}
            />

            <form
                ref={refFormulario}
                className="p-3 py-lg-4 px-lg-5 secondary-font font-weight-bold"
            >
                <div className="mb-3">
                    <label htmlFor="inputName" className="onprimary--color">
                        {textosInterfaz?.formularios?.nombre?.label || "Nombre completo"}*
                    </label>
                    <input
                        type="text"
                        id="inputName"
                        name="nombre"
                        ref={refNombre}
                        placeholder={textosInterfaz?.formularios?.nombre?.placeholder || "Escribe tu nombre y apellido aquí"}
                        className={"focusable-secondary form-control text-16"}
                        required
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="inputName" className="onprimary--color">
                        {textosInterfaz?.formularios?.correo?.label || "Correo electrónico"}*
                    </label>
                    <input
                        type="email"
                        className={"focusable-secondary form-control text-16"}
                        id="inputEmail"
                        placeholder={textosInterfaz?.formularios?.correo?.placeholder || "Escribe tu correo electrónico"}
                        name="email"
                        required
                        ref={refCorreo}
                        onPaste={(e) => { alertaEvento(e); }}
                        onCopy={(e) => { alertaEvento(e); }}
                        onChange={validacionCamposCorreo}
                    />
                    {!validacionCorreo && (
                        <p className="validacion-campo-error red-inverse--color">
                            {textosInterfaz?.formularios?.confirmar_correo?.error || 'Los datos ingresados no son iguales'}
                        </p>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="inputEmail" className="onprimary--color">
                        {textosInterfaz?.formularios?.confirmar_correo?.label || 'Confirmar correo electrónico'}*
                    </label>
                    <input
                        className={`focusable-secondary form-control text-16`}
                        id="inputEmailConfirm"
                        name="correoConfirm"
                        placeholder={textosInterfaz?.formularios?.confirmar_correo?.placeholder || 'Escribe de nuevo tu correo electrónico'}
                        required
                        type="email"
                        ref={refConfirmarCorreo}
                        onChange={validacionCamposCorreo}
                    />
                    {!validacionCorreo && (
                        <p className="validacion-campo-error red-inverse--color">
                            {textosInterfaz?.formularios?.confirmar_correo?.error || 'Los datos ingresados no son iguales'}
                        </p>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="form-select-pais" className="onprimary--color">
                        {textosInterfaz?.formularios?.pais?.label || "País"}*
                    </label>

                    <select
                        name="paisP"
                        ref={refPais}
                        className={"focusable-secondary form-control text-16"}
                        id="form-select-pais"
                        onChange={(e) => validarPais(e.target.value)}
                        required
                    >
                        <option value="" hidden>
                            {textosInterfaz?.formularios?.pais?.placeholder || "Escoge tu país"}
                        </option>
                        {sortedCountries.length !== 0 &&
                            sortedCountries?.map((pais) => (
                                <option
                                    key={pais?.codigo}
                                    value={pais?.codigo}
                                >
                                    {pais?.nombre}
                                </option>
                            ))}
                        <option value="Otro">
                            {textosInterfaz?.formularios?.pais?.other || "Otro"}
                        </option>
                    </select>
                </div>

                {otherCountry && (
                    <div className="mb-3">
                        <label htmlFor="inputOtroPais" className="onprimary--color">
                            {textosInterfaz?.formularios?.nombre_pais?.label || "Nombre del país"}*
                        </label>
                        <input
                            ref={refOtroPais}
                            type="text"
                            className={"focusable-secondary form-control text-16"}
                            id="inputOtroPais"
                            placeholder={textosInterfaz?.formularios?.nombre_pais?.placeholder || "Escribe el nombre de tu país"}
                            name="inputOtroPais"
                            required={otherCountry ? true : false}
                        />
                    </div>
                )}

                <div className="mb-3">
                    <label htmlFor="form-select-grupo" className="onprimary--color">
                        {textosInterfaz?.formularios?.grupo?.label || "¿Con qué grupo te identificas?"}*
                    </label>

                    <select
                        name="grupo"
                        className={"focusable-secondary form-control text-16"}
                        ref={refSelectGrupo}
                        onChange={(e) => validarGrupo(e.target.value)}
                        required
                    >
                        <option value="" hidden>
                            {textosInterfaz?.formularios?.grupo?.option1 || "Escoge tu grupo"}
                        </option>
                        <option value="Papá o mamá">{textosInterfaz?.formularios?.grupo?.option2 || 'Papá o mamá'}</option>
                        <option value={"Promotor(a) de lectura o bibliotecario(a"}>{textosInterfaz?.formularios?.grupo?.option3 || 'Promotor(a) de lectura o bibliotecario(a)'}</option>
                        <option value={"Profesor o profesora"}>{textosInterfaz?.formularios?.grupo?.option4 || 'Profesor o profesora'}</option>
                        <option value="Otro">
                            {textosInterfaz?.formularios?.grupo?.other || "Otro"}
                        </option>
                    </select>
                </div>

                {
                    group?.otherGroup &&

                    <div className="mb-4">
                        <label className="onprimary--color">
                            {textosInterfaz?.formularios?.otro_grupo?.label || "Otro grupo:"}
                        </label>
                        <input
                            type="text"
                            ref={refGrupo}
                            className={"focusable-secondary form-control text-16"}
                            placeholder={textosInterfaz?.formularios?.otro_grupo?.placeholder || "Escribe el grupo con el que te identificas"}
                            name="inputOtroGrupo"
                            required={group?.otherGroup}
                        />
                    </div>

                }

                <div className="my-5">
                    <label
                        className="d-flex align-items-center w-100 focusable-red pointer"
                        tabIndex={0}
                        role="checkbox"
                        checked={checkAge}
                        aria-checked={checkAge}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                setCheckAge(!checkAge);
                            }
                        }}
                    >
                        <input
                            type="checkbox"
                            className="modal-checkbox"
                            checked={checkAge}
                            onChange={(e) => {
                                setCheckAge(e?.target.checked);
                            }}
                        />
                        <i className="check"></i>
                        <p className="ml-2 onprimary--color">
                            {textosInterfaz?.formularios?.edad?.check || "Soy mayor de edad"}
                        </p>
                    </label>
                </div>

                <div className="row mt-lg-5">
                    <div className="col-lg-8 col-xl-9 my-4 my-lg-0 d-flex align-items-center">
                        <p
                            className="onprimary--color"
                            style={{
                                fontSize: '.9em',
                                fontWeight: 400
                            }}
                        >
                            {
                                textosInterfaz?.formularios?.terminos?.terms1 || "Al ingresar tus datos estás aceptando"}{" "}
                            <a
                                className="focusable-secondary font-weight-bold"
                                href="/terminos-y-condiciones"
                            >
                                <u>{textosInterfaz?.formularios?.terminos?.terms2 || "Términos y condiciones"}</u>
                            </a>{" "}
                            {textosInterfaz?.formularios?.terminos?.terms3 || "y"}{" "}
                            <a
                                className="focusable-secondary font-weight-bold"
                                href="/politica-de-privacidad"
                            >
                                <u>{textosInterfaz?.formularios?.terminos?.terms4 || "Política de privacidad"}</u>
                            </a>
                        </p>
                    </div>

                    <div className="col-lg-4 col-xl-3">
                        <button
                            className="focusable-secondary btn  btn-inscribirse"
                            type="submit"
                            id="btn-enviar-datos"
                            onClick={enviarFormulario}
                            disabled={preloader}
                        >
                            {preloader ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                            ) : (
                                textosInterfaz?.formularios?.button || "Inscribirse"
                            )}
                        </button>
                    </div>
                </div>

            </form>

            <EventsLogInRequest
                visible={showModalLogIn}
                setVisible={setShowModalLogIn}
                redirectionParam={`/eventos/formularios/${nombreEvento}?nombre=${refNombre?.current?.value}&correo=${refCorreo?.current?.value}&otro=${otherCountry}&pais=${otherCountry ? refOtroPais?.current?.value : paisSelecccionado?.nombrePais}&edad=${checkAge}&grupo=${group?.otherGroup ? refGrupo?.current?.value : group?.value}&otroGrupo=${group?.otherGroup}`}
                textosInterfaz={textosInterfaz}
            />

            <ModalEventAlert
                visible={showModalAlert}
                setVisible={setShowModalAlert}
                textosInterfaz={textosInterfaz}
                alertType={isModalAlert}
            />

            <ModalLimitAlert
                visible={showModalLimit}
                setVisible={setShowModalLimit}
                textosInterfaz={textosInterfaz}
            />
        </>
    )
}