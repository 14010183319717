import { useEffect, useRef, useState } from "react"
import { CancelRequestController } from "../../util/cancel-request-controller";
import { RecursosAPI } from "../../services/api/Recursos";
let timer;

export const useResources = ({type})=>{
    const [loading,setLoading] = useState(true);
    const [resources,setResources] = useState([]);
    const prevAbortControllerRef = useRef();

    const fetchResources = async ()=>{
        if (timer) {
			clearTimeout(timer);
		}
		if (prevAbortControllerRef.current) {
			prevAbortControllerRef.current.abort();
		}
		const abortController = new CancelRequestController();
		prevAbortControllerRef.current = abortController;
		setLoading(true);
        await RecursosAPI.getAll({abortSignal:abortController.signal})
        .then(res=>{
            const filtered = res.filter(r=>r.tipoDeRecurso?.toLowerCase()?.includes(type?.toLowerCase()));
            setResources(filtered);
        })
        .finally(()=>{
            timer = setTimeout(() => {
                setLoading(false);
            }, 100);
        })
    }

    useEffect(()=>{
        fetchResources();
    },[type])

    return {
        loading,
        data:resources
    }
}