import { CancelRequestController } from "../util/cancel-request-controller"
import { useContext } from "react";
import { store } from "../components/Store";
import { useEffect } from "react";
import { BusquedaAPI } from "../services/api/Busqueda";

let abortController = new CancelRequestController();
/**
 * Hook personalizado para obtener los libros de un usuario.
 * Realiza una solicitud de obtención de libros una vez y los guarda en el estado.
 * Devuelve los libros guardados desde el estado.
 *
 * @returns {{books:array, loadCompleted:boolean, loading:boolean}} Objeto que contiene los libros, el estado de carga y el estado de completado.
 *   - books: (Array) Los libros del usuario.
 *   - loadCompleted: (boolean) Indica si la carga de los libros se ha completado.
 *   - loading: (boolean) Indica si los libros se están cargando actualmente.
 */
export const useUserBooks = () => {
    const globalState = useContext(store);
    const userBooks = globalState?.state?.userBooksData?.books;
    const userBooksLoading = globalState?.state?.userBooksData?.loading;
    const userBooksLoadCompleted = globalState?.state?.userBooksData?.loadCompleted;
    const fetching = globalState?.state?.userBooksData?.fetching;

    const fetchAll = async () => {
        if (abortController?.signal?.aborted) {
            abortController = new CancelRequestController();
        }
        await BusquedaAPI
            .obtenerLibros({ abortSignal: abortController.signal, data: {ignoreStats:true} })
            .then(res => {
                globalState.dispatch({
                    type: 'CHANGE',
                    path: 'userBooksData',
                    value: {
                        ...globalState?.state?.userBooksData,
                        books: res,
                        loading: false,
                        loadCompleted: true,
                    }
                })
            })
            .catch(err => console.log(err))
    }
    useEffect(() => {
        if (userBooks === undefined && !userBooksLoadCompleted) {
            if(fetching===false){
                globalState.dispatch({
                    type: 'CHANGE',
                    path: 'userBooksData.fetching',
                    value: true
                })
                fetchAll();
            }
        }
        return () => {
            abortController.abort();
        }
    }, [userBooks, userBooksLoadCompleted])

    useEffect(() => {
        const length = userBooks === undefined? -1: Array.isArray(userBooks)?userBooks.length:0
        globalState.dispatch({
            type: 'CHANGE',
            path: 'cantidadLibros',
            value:length
        })
    }, [userBooks?.length])


    return { books: Array.isArray(userBooks) ? userBooks : [], loadCompleted: userBooksLoadCompleted, loading: userBooksLoading };
}