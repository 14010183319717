import { useEffect, useRef, useState } from "react";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { BlogAPI } from "../../services/api/Blog";

/**
 * Este hook devuelve un objeto con el estado de carga y el arreglo de meses usados en la pagina principal del blog 
 * @returns {object< loading: Boolean; listMonths: array>}
 */
export const useBlogMonths = () => {
	const [months, setMonths] = useState([]);
	const [loading, setLoading] = useState(true);
	const prevAbortController = useRef();

	const fetchMonths = async () => {
		if (prevAbortController.current) {
			prevAbortController.current.abort();
		}
		const abortController = new CancelRequestController();
		prevAbortController.current = abortController;
		setLoading(true);
		await BlogAPI.getMonths({ abortSignal: abortController.signal })
			.then((_months) => {
                const listMonths = {};
                Object.keys(_months).reverse().map(year =>{
                    Object.keys(_months[year]).reverse().map(month =>{
						listMonths[month + ' ' + year] =  _months[year][month]
                    })
                })

                setMonths(listMonths);
			})
			.catch((err) => {
				setMonths([]);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchMonths();
		return () => {
			if (prevAbortController.current) {
				prevAbortController.current.abort();
			}
		};
	}, []);

	return {
		loading,
		listMonths: months,
	};
};
