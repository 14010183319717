import { Icon } from "@iconify/react"
import React from "react"
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones"


/**
 * Este componente se encarga de mostrar un mensaje en el cual se indica que el libro ( inicialmente aparece en la ficha de un libro) no está disponible en el catalogo de makemake.
 * @returns {JSX.Element} Elemento React
 */
export const NotAvailableBook = () => {
    const { textosSubSecciones: textos } = useObtenerSubsecciones('ficha_libro');
    return (<div className="d-flex flex-wrap  p-3 my-2 justify-content-center align-items-center w-100 h-100 red-inverse--bg onred-inverse--color">
        <Icon icon="jam:triangle-danger-f" width={40} className="m-2" />
        <p className="font-weight-bolder">{textos?.disponibilidad_libro?.no_disponible || 'Lo sentimos, Este libro actualmente no se encuentra disponible en nuestro catálogo.'}</p>
    </div>)
}