import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'proxy-polyfill/proxy.min.js';
import './scss/styles.scss';
import 'react-toastify/scss/main.scss';
import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
} from "react-router-dom";

import Application from './Application';
import { StateProvider } from './components/Store.js';
//import './registerServiceWorker.js';
import { CookiesProvider } from 'react-cookie';
import { PWAProvider } from './contexts/ModoPwaContext';
import { ManejadorConexionInternet } from './components/ManejadorConexionInternet';
import { VerificarSesion } from './Verificaciones';
import { AccesibilidadProvider } from './contexts/AccesibilidadContext';
import { PWAconsole } from './util/service-worker-messges.js';
import { NetworkStatusDetector } from './components/network-status-detector.js';

const unregisterAllServiceWorkers = async () => {
  PWAconsole.info('NO SERVICE WORKER V1.0');
  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    
    if (registrations && registrations.length > 0) {
      for (const registration of registrations) {
        await registration.unregister();
      }
      PWAconsole.info('All Service Workers unregistered.');
    } else {
      PWAconsole.info('No Service Workers found to unregister.');
    }
  } catch (error) {
    PWAconsole.info('Error during Service Worker unregistration:', error);
  }
};

  // unregisterAllServiceWorkers();


/**
 * Este componente es el que React renderiza a través del método 'ReactDOM.render', contiene una cierta cantidad de componentes anidados que cumplen un propósito el cual se explicará a continuación.
 * 
 * * **Router**: Provider que viene de la librería [React router](#https://reactrouter.com/en/main) la cual permite crear y gestionar el enrutamiento entre las diferentes vistas del sitio.

* **CookiesProvider**: Provider que viene de la librería [react-cookie](#https://www.npmjs.com/package/react-cookie) la cual permite gestionar cookies adaptado a la arquitectura de React, solamente se está utilizando en el componente 'Visor'.

* **StateProvider**: Provider de un contexto de React en el cual se está utilizando como 'Store' para el almacenamiento de la información relacionada al cliente el cual permite facilitar el acceso a los mismos desde cualquier componente de React.

* **VerificarSesion**: Componente que actúa como wrapper el cual verifica el estado de la sesión del usuario y manipula el dom dependiendo del estado, este componente es importante para la seguridad de los datos en el frontend.

* **PWAProvider**: Provider de un contexto de React en el cual se almacena y gestiona el estado del Service worker además de otras cosas.El contexto que maneja este provider es muy importante y es utilizado en toda la aplicación para realizar lógica con base a su estado.

* **AccesibilidadProvider**: Provider de un contexto de React en el cual se almacena y gestiona el estado de toda la configuración de accesibilidad que está disponible para el cliente.

* **ManejadorConexionInternet**: Componente que actúa como wrapper en el cual se verifica el estado de conexión a internet y se lo indica al cliente además de realizar lógica y manipulación de la aplicación con base a sus cambios de estado.

* **Application**: Componente 'main' es donde está todas las rutas y donde se ejecuta la lógica y renderizado esencial del sitio, sin este componente no se renderizará nada en pantalla.
 */
const App = () => {

  return (
		<Router>
			<CookiesProvider>
				<StateProvider>
					<NetworkStatusDetector>
						<PWAProvider>
							<VerificarSesion>
								<AccesibilidadProvider>
									<ManejadorConexionInternet>
										<Application />
									</ManejadorConexionInternet>
								</AccesibilidadProvider>
							</VerificarSesion>
						</PWAProvider>
					</NetworkStatusDetector>
				</StateProvider>
			</CookiesProvider>
		</Router>
  );
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
  , document.getElementById("app"));

