import { useEffect, useRef } from "react";

/**
 * Hook personalizado que ejecuta una función cuando se hace clic fuera de un elemento especificado por su referencia.
 * @param {Object} props - Propiedades del hook.
 * @param {function} props.onClickOutside - Función a ejecutar cuando se hace clic fuera del elemento referenciado, tiene como parámetro el elemento al que se le hizo click.
 * @returns {React.RefObject} Referencia al elemento que se desea monitorizar.
 */
export const useClickOutside = ({ onClickOutside }) => {
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside(event.target);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClickOutside]);

    return ref;
};