import React from "react"
import { Img } from "../components/shared/img"
import PropTypes from 'prop-types';

/**
 * tarjeta botón que muestra la información de una colección.
 */
export const CuraduriaCard = ({ data, onClick, imgProps, lang }) => {
  const name = lang==='en'?data?.nombreIng || data?.nombre:data?.nombre;
  const description = lang==='en'?data?.descripcionIng || data?.descripcion:data?.descripcion;
    return <button className="focusable-secondary curaduria-card p-2" onClick={() => onClick && onClick()}>
        <div className="image-container" >
          <Img {...imgProps} className={`img-fluid curadurias-img ${imgProps?.className}`} src={`${data?.imgUrl}`} alt={data?.nombre ||'N/A'}/>
        </div>
        <div className="p-2">
        <p className="w-100 title text-center secondary-font py-3">{name || 'N/A'}</p>
        <p className="w-100 description text-left">{description || 'N/A'}</p>
        </div>
    </button>
}

CuraduriaCard.propTypes = {
  /**
   * Objeto con la info de la colección.
   */
  data:PropTypes.shape({
    nombre:PropTypes.string,
    descripcion:PropTypes.string,
    imgUrl:PropTypes.string,
  }).isRequired,
  /**
   * objeto con props de elemento img que se pasan a la imagen
   */
  imgProps: PropTypes.object,
  /**
   * Evento click para el botón (tarjeta)
   */
  onClick:PropTypes.func,
  /**
   * Idioma en que va la tarjeta "en"|"es"
   */
  lang:PropTypes.string
}
