
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";



/**
 * Verifica el estado de la conexi n a internet.
 * @param {{abortSignal:AbortSignal}} options - Opciones de configuraci n.
 * @param {AbortSignal} options.abortSignal - Se al de aborto para cancelar la solicitud.
 * @returns {Promise<Boolean>} - Una promesa que resuelve en true si la conexi n a internet est  disponible, false de lo contrario.
 */
const checkNetworkStatus = async ({abortSignal=null})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

    
        const response = await axios({
          method: 'POST',
          url: `assets/network-status`,
          signal: abortSignal || null,
          headers:{'Content-Type':'application/x-www-form-urlencoded'},
        })
        return response.status === 200
      } catch (error) {
        return Promise.reject(error);
      }
    
}


export const NetworkAPI = {
   checkNetworkStatus
}