import React from "react";
import { Link } from "react-router-dom";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

export const Logos = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const texts = useTranslations({ section: "footer", lang: lang });

	return (
		<section className="d-flex align-items-center">
			<Link className="focusable-secondary" aria-label="MakeMake home, link" to="/">
				<img className="makemake-logo" src="/img/logo-bn.png" alt="makemake logo" />
			</Link>
			<p className="px-3">
				{texts?.producto_de?.parte1 || "Es un"} <br />{" "}
				{texts?.producto_de?.parte2 || "producto de"}
			</p>
			<a
				className="focusable-secondary"
				aria-label="Makina Editorial, link."
				href="https://makinaeditorial.com/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					className="makina-logo"
					src="/img/logo-makina.png"
					alt="Logo de Makina Editorial"
				/>
			</a>
		</section>
	);
};
