import React from "react";
import { Img } from "../../../../components/shared/img";
import { ASSETS_URL } from "../../../../components/Store";
import moment from "moment";

export const PostCard = ({ data, onClick }) => {
	const coverPath = `${ASSETS_URL}${data?.portada}`;
	const date = moment(data?.date, "D/M/YYYY").format("D/MMM/YYYY");
	return (
		<a
			className="focusable-red w-100 h-100 blog-post-card row p-0 m-0"
			href={data?.url || "/"}
			target="_blank"
			rel="noopener noreferrer"
		>
			<div className="col-5 p-0 m-0">
				<Img
					className="w-100 h-100"
					src={coverPath}
					alt={data?.title || "image"}
					defaultImage={"/img/no-imagen.png"}
				/>
			</div>
			<div className="col-7 m-0 p-2 py-3">
				<p className="date text-capitalize font-weight-bold secondary-inverse--color">
					{date}
				</p>
				<p className="title font-weight-bold">{data?.title || 'N/A'}</p>
				<p className="description text-14">{data?.description || 'N/A'}</p>
			</div>
		</a>
	);
};
