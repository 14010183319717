
export const BOOKS_PREVIEW_OPTIONS = [
    {
        type: 'genero',
        value: 'Interactivo',
        text: {
            default: 'Interactivos',
            translationPath: 'categorias.interactivos'
        }
    },
    {
        type: 'genero',
        value: 'Informativo',
        text: {
            default: 'Informativos',
            translationPath: 'categorias.Informativos'
        }
    },
    {
        type: 'gratis',
        value: 1,
        text: {
            default: 'Libros gratuitos',
            translationPath: 'categorias.gratuitos'
        }
    },
    {
        type: 'genero',
        value: 'Libro álbum',
        text: {
            default: 'Libros álbum',
            translationPath: 'categorias.libro_album'
        }
    },
    {
        type: 'genero',
        value: 'Cómic',
        text: {
            default: 'Cómics',
            translationPath: 'categorias.comic'
        }
    },
    {
        type: 'genero',
        value: 'Novela',
        text: {
            default: 'Novelas',
            translationPath: 'categorias.novelas'
        }
    },
    {
        type: 'genero',
        value: 'Cuento ilustrado',
        text: {
            default: 'Cuentos ilustrados',
            translationPath: 'categorias.cuentos_ilustrados'
        }
    },
    {
        type: 'genero',
        value: 'Poesía',
        text: {
            default: 'Poesía',
            translationPath: 'categorias.poesia'
        }
    },
]
