import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useImmer } from "use-immer";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { DemoCleverAPI } from "../services/api/DemoClever";

/**
 * Este componente tiene toda la lógica para mostrar el formulario de demo, actualmente se usa para mostrase solo a Clever, desde Application.js
 */
export const DemoPopUp = ({ visible, setVisible }) => {
	const { textosSubSecciones: textos } = useObtenerSubsecciones("popup_demo", false, "en");
	const [showForm, setShowForm] = useState(true);
	const [formData, setFormData] = useImmer({
		fullName: "",
		email: "",
		message: "",
	});
	const [fetching, setFetching] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const initialFormInputRef = useRef(null);
	const openModalButtonRef = useRef(null);

	const onSubmit = async () => {
		if (fetching) return;
		setFetching(true);
		await DemoCleverAPI.solicitarDemo({ abortSignal: null, data: formData })
			.then((result) => {
				setFetching(false);
				setShowConfirmation(true);
				setShowForm(false);
			})
			.catch((error) => console.log(error));
	};

	const openPopUp = () => {
		setVisible && setVisible(true);
		setShowForm(true);
		setShowConfirmation(false);
		setFormData((draft) => {
			draft.email = "";
			draft.fullName = "";
			draft.message = "";
		});
		if (initialFormInputRef.current) {
			initialFormInputRef.current.focus();
		}
	};

	const hidePopUp = () => {
		setVisible && setVisible(false);
	};

	useEffect(() => {
		if (!visible && openModalButtonRef.current) {
			openModalButtonRef.current.focus();
		}
		if (visible && initialFormInputRef.current) {
			initialFormInputRef.current.focus();
		}
	}, [visible, openModalButtonRef, initialFormInputRef]);

	return (
		<>
			<div
				role={"alertdialog"}
				aria-modal={"true"}
				className={`focusable-red demo-popup-backdrop p-2 ${
					visible ? "visible" : visible !== null && "hidden"
				}`}
				id="demo-popup-backdrop"
			>
				<div className="demo-popup-window">
					<div className="d-flex justify-content-end">
						<button
							className="demo-popup-close-button focusable-red mt-3 mx-3"
							onClick={() => hidePopUp()}
						>
							<div className="icon"></div>
						</button>
					</div>

					<div className="demo-popup-content">
						<section
							className={`demo-popup-confirmation-view ${
								showConfirmation && !showForm ? "visible" : "hidden"
							}`}
						>
							<img className="logo" src="/img/demo-popup/submitted.png" alt="" />
							<h4 className="title">
								{textos?.confirmacion?.titulo || "Submitted!"}
							</h4>
							<p className="description">
								{textos?.confirmacion?.descripcion ||
									"Thanks! We will contact you soon."}
							</p>
							<button className="button focusable-red" onClick={() => hidePopUp()}>
								{textos?.confirmacion?.boton || "Ok"}
							</button>
						</section>
						<section
							className={`demo-popup-form-view ${
								!showConfirmation && showForm ? "visible" : "hidden"
							}`}
							id="demo-popup-form-view"
						>
							<h4
								className="demo-popup-title"
								style={{ maxWidth: 600 }}
								dangerouslySetInnerHTML={{
									__html: `${
										textos?.formulario?.titulo ||
										" Do you want a full<br />demo for your school?"
									}`,
								}}
							></h4>
							<img
								className="demo-popup-logo"
								src="/img/demo-popup/logo.png"
								alt=""
							/>
							<form
								className="demo-popup-form"
								action=""
								autocomplete="off"
								onSubmit={(e) => {
									e.preventDefault();
									onSubmit();
								}}
							>
								<fieldset>
									<input
										ref={initialFormInputRef}
										className="focusable-red"
										type="text"
										id="demo-popup-name"
										name="name"
										placeholder={
											textos?.formulario?.input?.nombre?.placeholder ||
											"Your name"
										}
										required
										value={formData.fullName}
										onChange={(e) => {
											e.persist();
											setFormData((draft) => {
												draft.fullName = e.target.value || "";
											});
										}}
									/>
									<label for="demo-popup-name">
										{textos?.formulario?.input?.nombre?.label || "Full name"}
									</label>
								</fieldset>
								<fieldset>
									<input
										className="focusable-red"
										type="email"
										id="demo-popup-email"
										name="email"
										placeholder={
											textos?.formulario?.input?.email?.placeholder ||
											"Your email"
										}
										required
										value={formData.email}
										onChange={(e) => {
											e.persist();
											setFormData((draft) => {
												draft.email = e.target.value || "";
											});
										}}
									/>
									<label for="demo-popup-email">
										{textos?.formulario?.input?.email?.label || "Email"}
									</label>
								</fieldset>
								<fieldset>
									<textarea
										className="focusable-red"
										type="email"
										id="demo-popup-message"
										name="message"
										placeholder={
											textos?.formulario?.input?.mensaje?.placeholder ||
											"Your message"
										}
										rows="6"
										required
										value={formData.message}
										onChange={(e) => {
											e.persist();
											setFormData((draft) => {
												draft.message = e.target.value || "";
											});
										}}
									></textarea>
									<label for="demo-popup-message">
										{textos?.formulario?.input?.mensaje?.label || "Message"}
									</label>
								</fieldset>
								<button
									type="submit"
									className={`demo-popup-submit focusable-red ${
										fetching ? "sending-form" : ""
									}`}
									id="demo-popup-submit"
								>
									{!fetching
										? `${
												textos?.formulario?.boton?.nombre ||
												"Please contact me! →"
										  }`
										: `${
												textos?.formulario?.boton?.enviando ||
												"Submitting..."
										  }`}
								</button>
							</form>
						</section>
					</div>
				</div>
			</div>
			<div
				className="demo-popup-fixed-container d-flex justify-content-center align-items-center p-2"
				style={{ pointerEvents: "none" }}
			>
				<div
					className="d-flex flex-sm-row flex-column-reverse p-2 demo-popup-open-card visible"
					style={{ maxWidth: 580, pointerEvents: "all" }}
					id="demo-popup-open-card"
				>
					<div className="d-flex flex-column justify-content-center align-items-center">
						<p className="title text-center" style={{ maxWidth: 350 }}>
							{textos?.tarjeta_principal?.titulo || "Request a full demo account"}
						</p>
						<button
							ref={openModalButtonRef}
							className="button focusable-red my-2"
							onClick={() => openPopUp()}
						>
							{textos?.tarjeta_principal?.boton || "Please contact me! →"}
						</button>
					</div>
					<div className="p-2 d-flex justify-content-center align-items-center">
						<img className="logo" src="/img/demo-popup/logo.png" alt="" />
					</div>
				</div>
			</div>
		</>
	);
};
