import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

/**
 * Sección de ayuda donde se muestra un acordeón para ayudar al usuario con temas relacionados a la descarga de libros entre otras cosas,  la cual solo se muestra cuando el usuario tiene la opción de descarga de libros activada.
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.txtAyudaD - Texto de ayuda para la descarga.
 * @param {Object} props.datosContacto - Datos de contacto.
 * @returns {JSX.Element} Componente de AyudaDescarga.
 */
const AyudaDescarga = (props) => {

  const { txtAyudaD, datosContacto } = props;

  const [acordeonActivoD, setAcordionActivoD] = useState();
  const [alturaAcordeonD, setAlturaAcordeonD] = useState();
  const [activadorD, setActivadorD] = useState(false);

  const contenedorAcordeonD = useRef(null);

  useEffect(() => {
    datoAlturaAcordeonD();
    window.addEventListener('resize', datoAlturaAcordeonD)
    return () => {
      window.removeEventListener('resize', datoAlturaAcordeonD)
    }
  }, [acordeonActivoD, alturaAcordeonD])

  const datoAlturaAcordeonD = () => {
    if (!!contenedorAcordeonD.current) return setAlturaAcordeonD(contenedorAcordeonD.current.scrollHeight);
    setAlturaAcordeonD(0);
  }

  const activadorAcordeonD = (index) => {
    let nuevoValor = acordeonActivoD === index ? "" : index;
    setActivadorD(acordeonActivoD === index ? false : true)
    setAcordionActivoD(nuevoValor)
  }

  const descargarLibros = (parrafo) => {
    return (
      <p>{parrafo?.p_uno || 'null'} <button className="focusable-secondary enlace" onClick={() => { activadorAcordeonD(1) }}>{parrafo?.a_uno || 'null'} </button>. {parrafo?.p_dos || 'null'}</p>
    )
  }

  const imagen = (img) => { return <img role="presentation" alt="imagen" src={img} className='img-fluid my-2' /> }

  const parrafo = (parrafo) => { return <p>{parrafo}</p> }

  const strong = (strong) => { return <strong className='d-block mb-2'>{strong}</strong> }

  const u = (u) => { return <u className='d-block my-3'>{u}</u> }

  const listaOrdenada = (lista) => {
    return (
      <ol>
        {
          Object.keys(lista).map((li, index) => {
            return <li key={`ol${index}`}>{lista[li]}</li>
          })
        }
      </ol>
    )
  }

  const listaOrdenadaAlfabeticamente = (lista) => {
    return (
      <ol type='a'>
        {
          Object.keys(lista).map((li, index) => {
            return <li key={`ol${index}`}>{lista[li]}</li>
          })
        }
      </ol>
    )
  }

  const listaDescargoLibro = (lista) => {
    return (
      <ol>
        <li>{lista?.li_uno || 'Null'}</li>
        <img role="presentation" alt="imagen" src={lista?.img_uno || './img/instrucciones/portada.png'} />
        <li className='mt-3'>{lista?.li_dos || 'Null'}</li>
        <img role="presentation" alt="imagen" src={lista?.img_dos || './img/instrucciones/descargar.png'} />
        <li>{lista?.li_tres || 'Null'}</li>
        <li>{lista?.li_cuatro_p || 'Null'} <Link className="enlace" to={'/descargados'}>{lista?.li_cuatro_a || 'Null'}</Link></li>
      </ol>
    )
  }

  const listaInstaloApp = (lista) => {
    return (
      <ol className='mt-3'>
        <li>{lista?.li_uno_p || 'null'} <button className="focusable-secondary enlace" onClick={() => { activadorAcordeonD(12) }}>{lista?.li_uno_a || 'null'}</button></li>
        <li>{lista?.li_dos || 'null'}</li>
        <li>{lista?.li_tres_p_uno || 'null'} <button className="focusable-secondary enlace" onClick={() => { activadorAcordeonD(1) }}>{lista?.li_tres_a || 'null'}</button> {lista?.li_tres_p_dos || 'null'}</li>
        <strong>{lista?.strong_uno || 'null'}</strong>
        <img role="presentation" alt="imagen" src={lista?.img_uno || './img/instrucciones/btn-descargar-app.png'} className="img-btn-descargar" />
        <li> {lista?.li_cuatro || 'null'}</li>
        <img role="presentation" alt="imagen" src={lista?.img_dos || './img/instrucciones/pantallas.png'} className='img-fluid' />
        <li> {lista?.li_cinco || 'null'}</li>
        <strong>{lista?.strong_dos || 'null'} </strong>
      </ol>
    )
  }

  const listaInstalacionMac = (lista) => {
    return (
      <ol>
        <strong className='mt-3'>{lista?.strong || 'null'}</strong>
        <li className='mt-2'>{lista?.li_uno || 'null'} <button className="focusable-secondary enlace" onClick={() => { activadorAcordeonD(1) }}>{lista?.li_uno_a || 'null'}</button></li>
        <li>{lista?.li_dos || 'null'}</li>
        <img role="presentation" alt="imagen" src={lista?.img_uno || './img/instrucciones/icono-de-descarga.png'} className='img-btn-descargar' />
        <li>{lista?.li_tres || 'null'}</li>
        <img role="presentation" alt="imagen" src={lista?.img_dos || './img/instrucciones/install.png'} className='img-fluid' />
      </ol>
    )
  }

  const listaInstalacionIos = (lista) => {
    return (
      <ol>
        <strong>{lista?.strong || 'null'}</strong>
        <li className='mt-2'>{lista?.li_uno || 'null'}</li>
        <li>{lista?.li_dos || 'null'}</li>
        <li>{lista?.li_tres || 'null'}</li>
      </ol>
    )
  }

  const cuantosLibrosParrafo = (parrafo) => {
    return <p>{parrafo?.p_uno || 'null'}</p>
  }

  const listaNoSale = (lista) => {
    return (
      <ol type='a'>
        <li>{lista?.li_uno_p || 'null'}  <button className="focusable-secondary enlace" onClick={() => { activadorAcordeonD(12) }}>{lista?.li_uno_a || 'null'}</button></li>
        <li>{lista?.li_dos_p || 'null'}  <button className="focusable-secondary enlace" onClick={() => { activadorAcordeonD(1) }}>{lista?.li_dos_a || 'null'}</button></li>
      </ol>
    )
  }

  const noPuedoParrafo = (parrafo) => {
    return <p>{parrafo?.p_dos || 'null'} <button className="focusable-secondary enlace" onClick={() => { activadorAcordeonD(5) }}>{parrafo?.a_uno || 'null'}</button>.</p>
  }

  const comoEliminoParrafo = (parrafo) => {
    return <p>{parrafo?.p_uno || 'null'} <button className="enlace focusable-secondary " onClick={() => { activadorAcordeonD(1) }}> {parrafo?.a_uno || 'null'}</button> {parrafo?.p_dos || 'null'}</p>
  }

  const descargoParrafoUno = (parrafo) => {
    return <p>{parrafo?.p_uno || 'null'} <a href={parrafo?.a_uno || 'null'} className="focusable-secondary enlace" target='_blank'>{parrafo?.a_uno || 'null'}</a></p>
  }

  return (
    <>
      {
        Object.keys(txtAyudaD).map((item, index) => {
          return (
            <div key={index}>
              {Object.keys(txtAyudaD[item]).map((elem) => {
                if (elem === 'titulo') {
                  return (
                    <div key={`tituloD${index}`}>
                      <button
                        aria-expanded={activadorD && acordeonActivoD === index}
                        className={`focusable-secondary ${acordeonActivoD === index ? "acordeon_active" : ""} acordeon px-3 py-2`}
                        onClick={(e) => {activadorAcordeonD(index)}}
                      >
                        {txtAyudaD[item][elem]}
                        <i className={`fas fa-chevron-down fa-sm ${acordeonActivoD === index && "icono-activo"} `}></i>
                      </button>
                    </div>
                  )
                }
              })}
              {
                activadorD && acordeonActivoD === index ?
                  <div
                    tabIndex={0}
                    ref={acordeonActivoD ? contenedorAcordeonD : null}
                    style={{ maxHeight: acordeonActivoD === index && activadorD ? ' ' : 0 }}
                    className={`opcionAcordeon ${acordeonActivoD === index ? 'p-3' : 'p-0'} relative`}
                  >
                    {Object.keys(txtAyudaD[item]).map((elemTwo) => {
                      if (elemTwo === 'p_especial_uno') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {descargarLibros(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      let keyImg = 'img';
                      let img = elemTwo.indexOf(keyImg);
                      if (img >= 0) {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {imagen(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      let keyP = 'p_';
                      let txtP = elemTwo.indexOf(keyP);
                      if (txtP >= 0) {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {parrafo(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      let keyU = 'u_';
                      let txtU = elemTwo.indexOf(keyU);
                      if (txtU >= 0) {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {u(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      let keyListaOrdenada = 'lista_ordenada';
                      let txtListaOrdenada = elemTwo.indexOf(keyListaOrdenada);
                      if (txtListaOrdenada >= 0) {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {listaOrdenada(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      let keyListaDesordenada = 'lista_desordenada';
                      let txtListaDesodenada = elemTwo.indexOf(keyListaDesordenada);
                      if (txtListaDesodenada >= 0) {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {listaOrdenadaAlfabeticamente(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      let keyStrong = 'strong';
                      let txtStrong = elemTwo.search(keyStrong);
                      if (txtStrong >= 0) {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {strong(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'lista_descargo_libro') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {listaDescargoLibro(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'lista_instalo_app') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {listaInstaloApp(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'lista_instalacion_mac') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {listaInstalacionMac(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'lista_instalacion_ios') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {listaInstalacionIos(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'cuantos_libros_parrafo') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {cuantosLibrosParrafo(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'lista_no_sale') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {listaNoSale(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'lista_no_puedo') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {listaOrdenadaAlfabeticamente(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'no_puedo_parrafo') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {noPuedoParrafo(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'como_elimino_parrafo') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {comoEliminoParrafo(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                      if (elemTwo === 'video') {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            <iframe
                              src={txtAyudaD[item][elemTwo]}
                              title={txtAyudaD[item].titulo}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; fullscreen" allowFullScreen
                              className='videoInstruccion'
                            ></iframe>
                          </div>
                        )
                      }

                      let keyDescargoParrafo = 'descargo_parrafo';
                      let txtDescargoParrafo = elemTwo.search(keyDescargoParrafo);
                      if (txtDescargoParrafo >= 0) {
                        return (
                          <div key={`${item}/${elemTwo}`}>
                            {descargoParrafoUno(txtAyudaD[item][elemTwo])}
                          </div>
                        )
                      }

                    })}

                    {
                      item === 'habilitar_caracteristicas' &&
                      <>
                        <div className='mt-3 flex'>
                          <img role="presentation" alt="imagen" src='./img/correo.svg' className='instruccion-icono' />
                          <a href={`mailto:${datosContacto?.correo || 'contacto@makemake.com.co'}`} className="focusable-secondary ml-2"> {datosContacto?.correo || 'contacto@makemake.com.co'}</a>
                        </div>
                        <div className='mt-3'>
                          <div className='flex'>
                            <img role="presentation" alt="imagen" src='./img/whatsapp.svg' className='instruccion-icono' />
                            <a href={datosContacto?.num_uno?.link || "https://wa.link/vuju91"} className="focusable-secondary ml-2">+{datosContacto?.num_uno?.numero || '57 313 4509992'}  </a>|<a href={datosContacto?.num_dos?.link || "https://wa.link/aozzbk"} className="focusable-secondary ml-2">+{datosContacto?.num_dos?.numero || '57 350 2367037'}</a>
                          </div>
                        </div>
                      </>
                    }

                  </div>
                  :
                  <></>
              }
            </div>
          )
        })
      }
    </>
  )
}

export default AyudaDescarga;