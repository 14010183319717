import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { useTranslations } from "../../../hooks/use-translations";
import _ from "lodash";

export const Resources = React.forwardRef((props, ref) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "comunidad_club", lang: lang });

	const cards = [
		{
			name: { default: "Corre la voz", translationPath: "recursos.tarjeta.tarjeta1" },
			imgUrl: "/img/club-makemake/recursos/img1.jpg",
		},
		{
			name: { default: "Aprende todo sobre MakeMake", translationPath: "recursos.tarjeta.tarjeta2" },
			imgUrl: "/img/club-makemake/recursos/img2.jpg",
		},
	];
	return (
		<div className="resources-section w-100 my-5" ref={ref} style={{scrollMarginTop:60}}>
			<div className="container my-5">
				<div
					className="row p-0 m-0  d-flex justify-content-center align-items-center"
					style={{ position: "relative" }}
				>
					<div className="resource-shape w-100 h-100">
						<svg
							width="100%"
							height="100%"
							viewBox="0 0 1199 539"
							fill="none"
							preserveAspectRatio="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M682 40C682 17.9086 664.091 0 642 0H40C17.9086 0 0 17.9086 0 40V168V500C0 522.091 17.9086 540 40 540H682H1159C1181.09 540 1199 522.091 1199 500V208C1199 185.909 1181.09 168 1159 168H722C699.909 168 682 150.091 682 128V40Z"
								fill="#4A90E2"
							/>
						</svg>
					</div>
					<div
						className="w-100 h-100 d-block d-lg-none"
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							background: "#4A90E2",
							borderRadius: "1rem",
						}}
					></div>
					<div className="col-12 col-lg-7 d-flex flex-column justify-content-center align-items-center">
						<div className="pt-5 px-sm-5">
							<div className="row m-0 p-0">
								<div className="">
									<h1 className="text-center text-lg-left secondary-font onsecondary--color">
										{_.get(texts, 'recursos.titulo') || 'Centro de recursos'}
									</h1>
									<p className="d-none d-md-flex onsecondary--color py-3 px-3 text-center text-lg-left px-md-0">
										{_.get(texts, 'recursos.descripcion') || '	MakeMake te acompaña con eventos, recomendaciones y marketing digital para hacer crecer tu comunidad lectora'}
									
									</p>
								</div>
							</div>
						</div>

						<div className="row p-0 m-0 justify-content-center pb-5 px-2 px-xl-5">
							{cards?.map((card, i) => {
								return (
									<div
										key={i}
										className="d-flex flex-column col-10 col-md m-3 p-0 background--bg onbackground-primary--color overflow-hidden"
										style={{
											borderRadius: ".5rem",
											maxWidth: 300,
										}}
									>
										<div className="flex-grow-1 d-flex align-items-center p-3">
											<p
												className="secondary-font"
												style={{
													fontWeight: 700,
												}}
											>
												{_.get(texts, card?.name?.translationPath) || card?.name?.default}
											</p>
										</div>
										<img className="img-fluid" src={card?.imgUrl} alt="card" />
									</div>
								);
							})}
						</div>
					</div>
					<div
						className="col-12 col-lg-5 cta-container d-flex justify-content-center align-items-center"
						style={{ position: "relative" }}
					>
						<img
							className="img-fluid p-3"
							style={{ maxWidth: 350, position: "relative", top: "-25px" }}
							alt="img"
							src="/img/club-makemake/girl-ipad.png"
						/>
						<div className="float-button d-flex justify-content-center align-items-center px-3">
							<Link className="focusable-red btn btn-primary p-2 px-3" to={"/centro-de-recursos"}>
								{_.get(texts, 'recursos.boton.explorar_recursos') || `Explora los recursos MakeMake `}
								<Icon
									className="ml-3"
									icon="teenyicons:arrow-right-solid"
									width={30}
								/>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
