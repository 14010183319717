import React from "react";

/**
 * Componente que representa la lista de opciones del Select.
 * @param {Object} props - Propiedades del componente `List`.
 * @param {ReactNode} props.children - Elementos secundarios renderizados dentro de la lista.
 * @param {number} props.maxHeight - Altura máxima de la lista.
 * @param {string} props.maxWidth - Ancho máximo de la lista.
 * @param {boolean} props.relative - Indica si la posición de la lista es relativa o absoluta al contenedor padre.
 * @returns {JSX.Element} Elemento JSX que representa el componente `List`.
 */
export const List = ({ children, maxHeight, maxWidth, relative }) => {
	return <div className={`list-wrapper ${relative?'relative':'absolute'}`} style={{maxHeight:maxHeight?maxHeight:200, maxWidth:maxWidth?maxWidth:'100%'}}>{children}</div>;
};

