import React, { useState, useEffect, useRef } from 'react';

/**
 * Componente que representa el acordeón de Ayuda Básica.
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.txtAyudaB - Texto de ayuda básica.
 * @returns {JSX.Element} Elemento JSX que representa el acordeón de Ayuda Básica.
 */
const AyudaBasica = (props) => {

  const { txtAyudaB } = props;

  const [acordeonActivo, setAcordionActivo] = useState();
  const [alturaAcordeon, setAlturaAcordeon] = useState();
  const [activador, setActivador] = useState(false);

  const contenedorAcordeon = useRef(null);

  useEffect(() => {
    datoAlturaAcordeon();
    window.addEventListener('resize', datoAlturaAcordeon)
    return () => {
      window.removeEventListener('resize', datoAlturaAcordeon)
    }
  }, [acordeonActivo, alturaAcordeon])


  const datoAlturaAcordeon = () => {
    if (!!contenedorAcordeon.current) return setAlturaAcordeon(contenedorAcordeon.current.scrollHeight);
    setAlturaAcordeon(0);
  }

  const activadorAcordeon = (index) => {
    let nuevoValor = acordeonActivo === index ? "" : index;
    setActivador(acordeonActivo === index ? false : true)
    setAcordionActivo(nuevoValor)
  }

  return (
    <>
      {
        Object.keys(txtAyudaB).map((item, index) => {
          return (
            <div key={index}>
              {Object.keys(txtAyudaB[item]).map((elem) => {
                if (elem === 'titulo') {
                  return (
                    <div key={`titulo${index}`}>
                      <button
                        aria-expanded={activador&&acordeonActivo===index}
                        className={`focusable-secondary ${acordeonActivo === index ? "acordeon_active" : ""} acordeon px-3 py-2`}
                        onClick={() => { activadorAcordeon(index) }}
                      >
                        {txtAyudaB[item][elem]}
                        <i className={`fas fa-chevron-down fa-sm ${acordeonActivo === index && "icono-activo"} `}></i>
                      </button>
                    </div>
                  )
                }
              })}
              {
                activador && acordeonActivo===index?
                <div
                tabIndex={0}         
                ref={acordeonActivo ? contenedorAcordeon : null}
                style={{ maxHeight: acordeonActivo === index && activador ? ' ' : 0 }}
                className={`focusable-secondary opcionAcordeon ${acordeonActivo === index ? 'p-3' : 'p-0'} relative`}
              >
                {Object.keys(txtAyudaB[item]).map((elemTwo, i) => {
                  if (elemTwo === 'url') {
                    return (
                      <div key={`video${index}`} >
                        <iframe
                          src={txtAyudaB[item][elemTwo]}
                          title={txtAyudaB[item].titulo}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; fullscreen" allowFullScreen
                          className='focusable-secondary videoInstruccion'
                        ></iframe>
                      </div>
                    )
                  }
                  if (elemTwo === 'p_uno') {
                    return <p key={`txt_ayudaB_${index}`}>{txtAyudaB[item][elemTwo]}</p>
                  }
                  if (elemTwo === 'lista') {
                    return (
                      <ul key={`list_ayudaB_${index}`}>
                        <li><button className="focusable-secondary enlace" onClick={() => { activadorAcordeon(0) }}>{txtAyudaB[item][elemTwo].a_uno}</button></li>
                        <li><button className="focusable-secondary enlace" onClick={() => { activadorAcordeon(1) }}>{txtAyudaB[item][elemTwo].a_dos}</button></li>
                      </ul>
                    )
                  }
                })}
              </div>
              :<></>
              }
      
            </div>

          )
        })
      }
    </>
  )
}
export default AyudaBasica;