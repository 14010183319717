import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { store } from "../components/Store.js";
import getUrlParam from "../js/utilities/getUrl.js";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones.js";
import { DatosPaisesAPI } from "../services/api/datosPaises";
import { CancelRequestController } from "../util/cancel-request-controller";
import { SolicitudesDemoAPI } from "../services/api/SolicitudesDemo.js";
import normalize from "../js/utilities/normalizeString.js";
import { useImmer } from "use-immer";
import ModalAlertas from "./../components/ModalAlertas";
import MicroModal from "micromodal";
import { Helmet } from "react-helmet";

/**
 * Este componente no recibe prop y se encarga de mostrar el formulario de suscripción al boletín. Entre sus funciones estan: obtener la lista de paises haciendo uso del API /assets/datosPaises, enviar la información del formulario diligenciado haciendo uso del API /assets/quieroDemo/enviarCorreoDemo y redirigir el sitio a la pantalla de mensajeInscripcion.
 * @returns {JSX.Element} IngresarDatos
 */
const IngresarDatos = () => {
  const { isReady, textosSubSecciones: textos } = useObtenerSubsecciones('boletin');
  const globalState = useContext(store);
  const { state } = globalState;
  const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
  const institution = state?.datosHeader?.institucion || 'N/A';
  const [isLoading, setIsloading] = useState();
  const [paises, setPaises] = useState([]);
  const [preloader, setPreloader] = useState(false);
  const [validacionCorreo, setValidacionCorreo] = useState(true);
  const [isOtherCountry, setOtherCountry] = useState(false);
  const [paisSelecccionado, setPaisSelecccionado] = useImmer({ nombrePais: '', codigoPais: '' });
  const [tipoSuscripcion, setTipoSuscripcion] = useState('Suscripción al boletín');

  const [notificacionNombre, setNotificacionNombre] = useState(false);
  const [notificationAge, setNotificationAge] = useState(false);
  const [check, setCheck] = useState(false);
  const [notificacionCorreo, setNotificacionCorreo] = useState(false);
  const [notificacionCorreoConf, setNotificacionCorreoConf] = useState(false);
  const [mostrarNotificaciones, setMostrarNotificaciones] = useState(false);

  const [respuestaApi, setRespuestaApi] = useState({
    description: "",
    message: "",
    icono: "",
    color: "",
  });

  const refNombre = useRef(null);
  const refCorreo = useRef(null);
  const refPais = useRef(null);
  const refOtroPais = useRef(null);
  const refCiudad = useRef(null);
  const refConfirmarCorreo = useRef(null);
  const refFormulario = useRef(null);
  const refCheck1 = useRef(null);
  const refCheck2 = useRef(null);
  const refCheck3 = useRef(null);

  //Otras librerias
  const history = useHistory();

  useEffect(() => {
    let abortController = new CancelRequestController();

    //Utiliza el servicio obtenerLatamUSA de DatosPaisesAPI y obtiene la lista de paises de Latam más USA
    const listaPaises = async () => {
      await DatosPaisesAPI.obtenerLatamUSA({
        abortSignal: abortController.signal,
      })
        .then(
          res => {
            setPaises(Array.isArray(res) ? res : []);
          }
        )
        .catch(error => console.log(error));
    }
    listaPaises();
    return () => {
      abortController.abort();
    }
  }, []);

  useEffect(() => {
    if (state.mainDataLoaded === true && isLoading) {
      setIsloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.mainDataLoaded]);

  //actualiza el campo de correo, en caso de que se haya diligenciado el correo con anterioridad
  useEffect(() => {
    var correo = getUrlParam("email");
    if (correo !== undefined && refCorreo.current) {
      refCorreo.current.value = correo;
      refConfirmarCorreo.current.value = correo;
      setNotificacionCorreo(true);
      setNotificacionCorreoConf(true);
    }
    
  }, [isLoading, refCorreo.current]);


  useEffect(() => {
    var name = decodeURIComponent(getUrlParam("fullname"));
    if (name !== undefined && refNombre.current) {
      refNombre.current.value = name;
      setNotificacionNombre(true);
    }
    
  }, [isLoading, refNombre.current]);

  /**
   * Esta función retorna una alerta en caso de que se ejecute un evento de tipo copiar o pegar en un campo determinado.
   * @param {Event} e 
   * @returns {alert}
   */
  const alertaEvento = (e) => {
    e.preventDefault();
    return alert("Está acción no está permitida.");
  };

  /**
   * Esta función valida si el input de tipo text tiene algún valor. De ser asi, marca el estado como TRUE, de lo contrario sera FALSE.
   * @param {HTMLElement} referencia -Referencia de un campo
   * @param {Function} estado - función que actualiza el estado del campo
   */
  const validarText = (referencia, estado) => {
    estado(
      referencia.current.value === ''
        ? false
        : true
    );
  }

  /**
     * Esta función valida que la edad sea mayor a 18 años.
     * También actualiza el estado notificationAge para mostrar la
     * notificación y bloquear el botón de enviar
     */
  const validacionEdad = () => {
    let isLess = check === false ? true : false;
    setNotificationAge(isLess);
  };


  /**
   * Esta función valida que los campos del formulario esten diligenciados. De ser asi envia la información correspondiente haciendo uso del API /assets/quieroDemo/enviarCorreoDemo. Si la respuesta del API es exitosa, esta función se encarga de redirigir a la pantalla de mensajeInscripcion.
   * @param {Event} e 
   */
  const enviarFormulario = async (e) => {
    e.preventDefault();

    validacionEdad();

    if (refFormulario.current.reportValidity()) {
      
      if (validacionCorreo) {
        setPreloader(true);

        let abortController = new CancelRequestController();

        const propiedades = {
          nombreP: refNombre.current.value,
          correoP: refCorreo.current.value,
          paisP: isOtherCountry ?  `Otro - ${refOtroPais.current.value}`: paisSelecccionado?.nombrePais || '',
          codigoPaisP: isOtherCountry ? 'CO' : paisSelecccionado?.codigoPais || '',
          ciudadP: refCiudad.current.value,
          edadP: refCheck3 ? "Mayor de edad" : "N/A",
          tipoSolicitudDemo: tipoSuscripcion,
          institucionP:  isLogged ? institution : 'No aplica'
        };

        await SolicitudesDemoAPI.suscripcionBoletin({
          abortSignal: abortController.signal,
          dataForm: propiedades
        }).then(response => {
          const respuesta = response;
          const error = respuesta.status === 0;
          let messageModal = {};

          if (!error) {
            history.push(
              `/boletin/mensaje?uso${respuesta.info}&pais=${isOtherCountry ? normalize(refOtroPais.current.value) : normalize(refPais.current.value)}&em=${respuesta.correo}&solicitud=${normalize(tipoSuscripcion)}`
            );
          }else{    
            messageModal = {
              description: textos?.modal_alerta?.mensaje || 'Si no recibiste la información solicitada escríbenos a contacto@makemake.com.co',
              message: textos?.modal_alerta?.titulo || '¡Ups! El correo ya se ha usado anteriormente',
              color: null,
              icono: null,
            };
          }
          
          refNombre.current.value= '';
          refCorreo.current.value='';
          refConfirmarCorreo.current.value ='';
          if(isOtherCountry === true){
            refOtroPais.current.value = ''
          };
          refPais.current.value ='';
          setPaisSelecccionado(draft => {
            draft.nombrePais = '';
            draft.codigoPais = '';
          });
          refCiudad.current.value = '';
          setOtherCountry(false);
          refCheck1.current.checked = false;
          refCheck2.current.checked = false;
          refCheck3.current.checked = false;
          
          setTipoSuscripcion('Suscripción al boletín'); 

          MicroModal.show("modal-alertas-registrado", {
            awaitCloseAnimation: true,
          });
          setRespuestaApi({ ...respuestaApi, ...messageModal });

          setPreloader(false);
        }).catch(error => console.log(error))
      }
    } else {
      setMostrarNotificaciones(true)
    }
  };

  /**
   * Esta función valida si la confirmación del correo es igual al primer dato de correo diligenciado. 
   */
  const validacionCamposCorreo = () => {
    setValidacionCorreo(
      refCorreo.current.value === refConfirmarCorreo.current.value
        ? true
        : false
    );

    setNotificacionCorreoConf(
      refConfirmarCorreo.current.value == '' ? false : true
    )
  }

  /**
   * Esta función actualiza el estado isOtherCountry si la selección
   *  previa del país es "Otro". Esto habilita el campo nombre del país.  
   */
  const validarPais = (valor) => {
    let isOther = refPais.current.value === "Otro" ? true : false;
    setOtherCountry(isOther)

    paises.map(pais => {
      if(pais?.codigo === valor){
        setPaisSelecccionado( draft => {
          draft.nombrePais = pais?.nombre;
          draft.codigoPais = pais?.codigo;
        })
      }
    })
  }

  /**
   * Esta función se encarga de actualizar el tipo de suscripción según los Checks seleccionados
   */
  const validarChecks = () => {
    if(refCheck1.current.checked && refCheck2.current.checked){
      setTipoSuscripcion('Suscripción al boletín cliente e información')
    }else if(refCheck1.current.checked && !refCheck2.current.checked){
      setTipoSuscripcion('Suscripción al boletín cliente')
    }else if(!refCheck1.current.checked && refCheck2.current.checked){
      setTipoSuscripcion('Suscripción al boletín e información')
    }else{
      setTipoSuscripcion('Suscripción al boletín')
    }
  }

  if (isLoading) {
    return <></>;
  } else {
    return (
      <>
        <Helmet>
          <title>{textos?.metadata?.title || 'MakeMake - Boletín'}</title>
          <meta name="description" content={textos?.metadata?.description || 'Suscríbete al boletín de MakeMake y recibe información sobre novedades, eventos, planes de lectura y más.'} />
        </Helmet>
        {isReady &&
          <div className="main-container mm-redimir " id="demo-ingresar-datos">
            <div className="container main-content p-3 pt-lg-5 px-lg-5 pb-5">
              <div className="col-lg-8 mx-auto pb-5">
                <h2 className="mm-redimir_title mb-4 text-center pb-3">
                  {textos?.ingresar_datos?.titulo || 'Suscríbete a nuestro boletín'}
                </h2>
                <div className="mm-redimir_alerts">
                  <p
                    className="mm-redimir_redalert"
                    style={{ display: "none" }}
                  ></p>
                </div>
                <p className="mm-redmir_textlogin text-center onbackground-third--color">
                  {textos?.ingresar_datos?.descripción || 'Esta información nos permitirá enviarte novedades y noticias de nuestros libros.'}
                </p>
              </div>

              <form ref={refFormulario} id="contact-form" name="contactoForm">
                <div className="mm-redimir_createaccount col-lg-9 mx-auto pt-3 pb-5">
                  <div className="row mb-0 mb-lg-4">
                    <div className="col-12 mb-4 mb-lg-0">
                      <p className="secondary-item">
                        <strong>{textos?.ingresar_datos?.nombre?.texto || 'Nombre completo*:'}</strong>
                      </p>
                      <label htmlFor="inputName" className="sr-only" aria-hidden={true}>
                        {textos?.ingresar_datos?.nombre?.label || 'Nombre completo'}
                      </label>
                      <input
                        ref={refNombre}
                        type="text"
                        className="form-control surface--bg onbackground-third--color focusable-secondary"
                        id="inputName"
                        placeholder={textos?.ingresar_datos?.nombre?.placeholder || "Escribe tu nombre y apellido aquí"}
                        name="nombreP"
                        required
                        onChange={() => validarText(refNombre, setNotificacionNombre)}
                      />
                      {
                        mostrarNotificaciones &&
                        <span role={'alert'} aria-live={'assertive'} className="notificacion-form">
                          {notificacionNombre ? '' : textos?.ingresar_datos?.nombre?.alert || 'Por favor, escribe tu nombre y apellido'}
                        </span>
                      }

                    </div>
                  </div>
                  <div className="row mb-0 mb-lg-4">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <p className="secondary-item">
                        <strong>{textos?.ingresar_datos?.correo?.texto || 'Correo electrónico*:'}</strong>
                      </p>
                      <label htmlFor="inputEmail" className="sr-only" aria-hidden={true}>
                        {textos?.ingresar_datos?.correo?.label || 'Correo electrónico'}
                      </label>
                      <input
                        ref={refCorreo}
                        onPaste={(e) => {
                          alertaEvento(e);
                        }}
                        onCopy={(e) => {
                          alertaEvento(e);
                        }}
                        type="email"
                        className="form-control surface--bg  onbackground-third--color focusable-secondary"
                        id="inputEmail"
                        placeholder={textos?.ingresar_datos?.correo?.placeholder || "Escribe tu correo electrónico"}
                        name="correoP"
                        required
                        onChange={() => validarText(refCorreo, setNotificacionCorreo)}
                      />
                      {!validacionCorreo && (
                        <span className="validacion-campo-error" role={"alert"} aria-live="assertive">
                          {textos?.ingresar_datos?.correo?.error || 'Los datos ingresados no son iguales'}
                        </span>
                      )}

                      {
                        mostrarNotificaciones &&
                        <span role={'alert'} aria-live={'assertive'} className="notificacion-form">
                          {notificacionCorreo ? '' : textos?.ingresar_datos?.correo?.alert || 'Por favor, ingresa tu correo electrónico'}
                        </span>
                      }

                    </div>
                    <div className="col-lg-6  mb-4 mb-lg-0">
                      <p className="secondary-item">
                        <strong>{textos?.ingresar_datos?.confirmar_correo?.texto || 'Confirmar correo electrónico*:'}</strong>
                      </p>
                      <label htmlFor="inputEmail" className="sr-only" aria-hidden={true}>
                        {textos?.ingresar_datos?.confirmar_correo?.label || 'Confirmar correo electrónico'}
                      </label>
                      <input
                        ref={refConfirmarCorreo}
                        onPaste={(e) => {
                          alertaEvento(e);
                        }}
                        onCopy={(e) => {
                          alertaEvento(e);
                        }}
                        onChange={validacionCamposCorreo}
                        type="email"
                        className="form-control surface--bg  onbackground-third--color focusable-secondary"
                        id="inputEmailConfirm"
                        placeholder={textos?.ingresar_datos?.confirmar_correo?.placeholder || "Escribe de nuevo tu correo electrónico"}
                        name="correoPConfirm"
                        required
                      />
                      {!validacionCorreo && (
                        <span className="validacion-campo-error" role={"alert"} aria-live="assertive">
                          {textos?.ingresar_datos?.confirmar_correo?.error || 'Los datos ingresados no son iguales'}
                        </span>
                      )}

                      {
                        mostrarNotificaciones &&
                        <span role={'alert'} aria-live={'assertive'} className="notificacion-form">
                          {notificacionCorreoConf ? '' : textos?.ingresar_datos?.confirmar_correo?.alert || 'Por favor, ingresa nuevamente tu correo electrónico'}
                        </span>
                      }
                    </div>
                  </div>

                  <div className="row mb-0 mb-lg-4">

                    <div className="col-lg-6  mb-4 mb-lg-0">
                      <p className="secondary-item">
                        <strong>{textos?.ingresar_datos?.pais?.texto || 'País:'}</strong>
                      </p>
                      <label htmlFor="institutoId" className="sr-only" aria-hidden={true}>
                        {textos?.ingresar_datos?.pais?.label || 'País'}
                      </label>
                      <select
                        ref={refPais}
                        name="paisP"
                        className="form-control surface--bg  onbackground-third--color focusable-secondary"
                        id="form-select-pais"
                        onChange={(e) => validarPais(e.target.value)}
                      >
                        <option value="" hidden>
                          {textos?.ingresar_datos?.pais?.placeholder || 'Escoge tu país'}
                        </option>
                        {paises.length !== 0 &&
                          paises.map((pais) => (
                            <option key={pais.codigo} value={pais.codigo}>
                              {pais.nombre}
                            </option>
                          ))}
                        <option value='Otro'>{textos?.ingresar_datos?.otro?.texto || 'Otro'}</option>
                      </select>
                    </div>

                    {isOtherCountry &&
                      <div className="col-lg-6 mb-4">
                        <p className="secondary-item">
                          <strong>{textos?.ingresar_datos?.otro_pais?.texto || 'Nombre del país:'}</strong>
                        </p>
                        <label htmlFor="inputOtroPais" className="sr-only">
                          {textos?.ingresar_datos?.otro_pais?.label || 'Nombre del país'}
                        </label>
                        <input
                          ref={refOtroPais}
                          type="text"
                          className={"focusable-secondary form-control"}
                          id="inputOtroPais"
                          placeholder={textos?.ingresar_datos?.otro_pais?.placeholder || "Escribe el nombre de tu país"}
                          name="inputOtroPais"
                        />
                      </div>
                    }

                    <div className="col-lg-6  mb-4 mb-lg-0">
                      <p className="secondary-item">
                        <strong>{textos?.ingresar_datos?.ciudad?.texto || 'Ciudad:'}</strong>
                      </p>
                      <label htmlFor="inputPhone" className="sr-only" aria-hidden={true}>
                        {textos?.ingresar_datos?.ciudad?.label || 'Ciudad'}
                      </label>
                      <input
                        ref={refCiudad}
                        type="text"
                        className="form-control surface--bg  onbackground-third--color focusable-secondary"
                        id="inputCiudad"
                        placeholder={textos?.ingresar_datos?.ciudad?.placeholder || "Escribe tu ciudad"}
                        name="ciudadP"
                      />
                    </div>
                  </div>
                  <div className="px-lg-4 p-3 onbackground-third--color">

                    <label>                     
                      <input
                        ref={refCheck3}
                        className="form-check-input focusable-secondary"
                        type="checkbox"
                        checked={check}
                        onChange={(e) => {
                          setNotificationAge(false);
                          setCheck(e?.target.checked);
                        }}
                        required
                      />
                       {textos?.ingresar_datos?.check3 || 'Soy mayor de edad'}
                    </label>
                    <p>
                      {notificationAge && (
                        <span className="validacion-campo-error red-inverse--color">
                          {textos?.ingresar_datos?.edad?.tooltip || "Es necesario que seas mayor de edad"}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="px-lg-4 p-3 onbackground-third--color">
                    <label>
                      <input
                        ref={refCheck1}
                        className="form-check-input focusable-secondary"
                        type="checkbox"
                        value="Cliente existente"
                        name="check1"
                        onChange={() => validarChecks()}
                      />
                      {textos?.ingresar_datos?.check1 || 'Ya tengo acceso a MakeMake'}
                    </label>
                  </div>
                  <div className="px-lg-4 p-3 onbackground-third--color">                    
                    <label>
                      <input
                        ref={refCheck2}
                        className="form-check-input focusable-secondary"
                        type="checkbox"
                        value="Uso personal / institucional"
                        name="check1"
                        onChange={()=> validarChecks()}
                      />
                      {textos?.ingresar_datos?.check2 || 'Me interesa conocer acerca de los planes y precios para instituciones (Bibliotecas, colegios, fundaciones y/o cajas de compensación)'}
                    </label>
                  </div>
                </div>
                <div className="col-lg-10 mx-auto mb-4">
                  <p className="p-0 p-lg-5 mm-redimir-terms">
                    {textos?.ingresar_datos?.terminos?.ingresar || 'Al ingresar tus datos estás aceptando'}
                    <Link
                      className="links-tyc secondary-item focusable-red px-2"
                      to="/terminos-y-condiciones"
                    >
                      {textos?.ingresar_datos?.terminos?.term_condiciones || 'Términos y condiciones'}
                    </Link>
                    {""}{textos?.ingresar_datos?.terminos?.y || 'y'} {""}
                    <Link
                      className="links-tyc secondary-item focusable-red px-2"
                      to="/politica-de-privacidad"
                    >
                      {textos?.ingresar_datos?.terminos?.politica || 'Política de privacidad'}
                    </Link>
                  </p>
                </div>
                {/* Acompañamiento lector con actividades, */}
                <div className="row mx-0">
                  <button
                    className="btn btn-primary col-6 col-lg-4 d-block mx-auto focusable-red"
                    type="submit"
                    onClick={enviarFormulario}
                    disabled={notificationAge}
                  >
                    {preloader ? (
                      <i className="fas fa-spinner fa-pulse"></i>
                    ) : (
                      textos?.ingresar_datos?.boton || "Enviar"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>}
          <ModalAlertas
            id="modal-alertas-registrado"
            description={respuestaApi.description}
            message={respuestaApi.message}
            color={respuestaApi.color}
            icono={respuestaApi.icono}
            boton={textos?.modal_alerta?.boton || 'Aceptar'}
          />
      </>
    );
  }
};

export default IngresarDatos;
