import React from "react";

export const StaticBagde = () => {
  return (
    <>
      <div
        className="staticBagde"
      >
        <a 
          href="https://www.bolognachildrensbookfair.com/en/awards/bolognaragazzi-crossmedia-award/bolognarag[…]4-winners/digital-reading-experience-2024/13263.html"
          target="_blank"
          className="badge"
        ></a>
      </div>
    </>

  )
}