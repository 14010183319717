
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";

/**
 * Este servicio verifica si el cliente actual tiene habilitado el modo PWA
 * @param {{abortSignal:AbortSignal}} param0 
 * @returns {Promise<boolean>} retorna true si el cliente actual tiene habilitado el modo PWA, false si no
 */
const obtenerDatos = async ({abortSignal=null})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;
        const response = await axios({
          method: 'GET',
          url: 'assets/datosPanel',
          signal: abortSignal || null
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos || {};
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}

export const DatosPanelAPI = {
  obtenerDatos,
}