import React from "react";
import { Img } from "../../../../components/shared/img";
import { FormularioDemo } from "../../../../components/solicitud-demo/components/FormularioDemo";
import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../../config/constants";
import { Helmet } from "react-helmet";

/**
 * Componente que devuelve el contenido del formulario Demo -"Solicitar información", de la tienda para instituciones
 * @returns {JSX.Element} Demo
 */
export const Demo = ({urlLang}) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: urlLang });
    const textosTienda = useTranslations({ section: "tienda_makemake", lang: lang });

    return (
        <>
            <Helmet>
                <title>{textosTienda?.metadata?.demo?.title || 'MakeMake - Agendar demo'}</title>
                <meta name="description" content={textosTienda?.metadata?.demo?.description || 'Un agente de MakeMake te contactará para explicarte más sobre nuestros servicios'} />
            </Helmet>
            <div className="subsection">
                <div className={`d-flex pt-4 pb-2 px-3 justify-content-end`}>
                    <div>
                        <Img src="/img/tienda-makemake/icons/demo.png" alt="icono demo" />
                    </div>
                </div>
                <div>
                    <div className="col-lg-9 mt-3 mx-auto">
                        <p className="text-center onsecondary--color font-weight-normal">

                        </p>
                    </div>
                    <FormularioDemo
                        lang={lang}
                        formDescription={textosTienda?.institucional?.demo?.descripcion || "Déjanos tus datos y un agente de MakeMake te contactará para explicarte más sobre nuestros servicios"}
                    />
                </div>
            </div>
        </>
    )
}