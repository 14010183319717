import React, { useEffect, useRef } from "react";
import { Button } from "./button";
import { List } from "./list";
import { useElementClickListener } from "../../../../hooks/use-element-click-listener";
// import { useElementClickListener } from "../../../hooks/use-element-click-listener";

/**
 * Componente que representa el contenedor del Select.
 * @param {Object} props - Propiedades del componente `Container`.
 * @param {boolean} props.expanded - Indica si el Select está expandido o colapsado.
 * @param {function} props.setExpanded - Función para establecer el estado de expansión del Select.
 * @param {ReactNode} props.children - Elementos secundarios renderizados dentro del contenedor.
 * @param {number} props.zIndex - Valor del índice de apilamiento (z-index) del contenedor.
 * @param {string} props.maxWidth - Ancho máximo del contenedor.
 * @returns {JSX.Element} Elemento JSX que representa el componente `Container`.
 */
export const Container = ({ expanded, setExpanded, children, zIndex, maxWidth }) => {
   const childrenElements = React.Children.toArray(children);
   const button = childrenElements.find(child=>child?.type === Button);
   const list = childrenElements.find(child=>child?.type === List);
   const ref = useRef(null);
   const clickListener = useElementClickListener({ref})

   useEffect(()=>{
    if(clickListener.clickedOutside){
        setExpanded&&setExpanded(false);
    }
   },[clickListener?.clickedOutside])

	return <div  ref={ref} className={`base-select ${expanded ? "expanded" : "collapsed"}`} style={{zIndex:zIndex || 1, maxWidth:maxWidth?maxWidth:'100%'}}>
        {button}
        {list}
    </div>;
};

