import React, { useContext, useRef, useEffect } from "react";
import { store } from "../../../Store";
import { Link } from "react-router-dom";

import { useAccessibility } from "../../../../hooks/useAccessibility";

/**
 * Componente con las opciones Ayuda, Contacto y Accesibilidad
 * @param {Function} setOpenDropdown - actualiza el estado openDropdown
 * @param {boolean} openDropdown - estado que indica si se debe ver las opciones de planes
 * @param {object} textosInterfaz- contiene los textos de interfaz
 * @returns {JSX.Element} DropdownGeneralMenu
 */
export const DropdownGeneralMenu = ({ setOpenDropdown, openDropdown, textosInterfaz }) => {
  const globalState = useContext(store);
  const accessibility = useAccessibility();
  const accessibilityButtonRef = useRef(null);

  /*Asigna el botón que abre las opciones de accesibilidad */
  useEffect(() => {
    if (accessibilityButtonRef.current) {
      globalState.dispatch({
        type: "CHANGE",
        path: "accessibility.triggerButton",
        value: accessibilityButtonRef,
      });
    }
  }, [accessibilityButtonRef]);

  return (
    <ul>
      <li className={`${openDropdown ? 'activeMode' : 'inactiveMode'}`}>
        <Link
          to="/ayuda"
          onClick={() => setOpenDropdown(false)}
          className="focusable-red"
        >{textosInterfaz?.ayuda || 'Ayuda'}</Link>
      </li>
      <li className={`${openDropdown ? 'activeMode' : 'inactiveMode'}`}>
        <button
          ref={accessibilityButtonRef}
          onClick={() => {
            accessibility.setVisible(true);
            setOpenDropdown(false)
          }}
          className="focusable-red"
        >{textosInterfaz?.accesibilidad || 'Accesibilidad'}</button>
      </li>
    </ul>
  )
}