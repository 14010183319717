import React, {useState, useEffect, useRef} from "react"
import { useQueryParams } from "../hooks/useQueryParams";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import FiltrosBusqueda from "./filtros-busqueda";
import { SearchItems } from "./search-item";

/**
 * Componente de filtro para la búsqueda. Permite al usuario aplicar filtros y actualizar el estado de los mismos.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.filters - Objeto que contiene los filtros actuales.
 * @param {Function} props.setFilters - Función para actualizar los filtros.
 * @param {Function} props.onChange - Función que se ejecuta cuando se aplican los filtros.
 * @param {Function} props.onDeleteFilters - Función que se ejecuta cuando se eliminan los filtros.
 * @param {boolean} props.loadingData - Indica si los datos se están cargando.
 * @param {boolean} props.booksAwarded - Indica si hay libros premiados disponibles.
 * @param {boolean} props.assistedBooks - Indica si hay libros accesibles disponibles.
 * @returns {JSX.Element} - Elemento JSX que representa el componente Filtro.
 */
export const Filtro = React.memo(({ filters, setFilters, onChange, onDeleteFilters, loadingData, booksAwarded, assistedBooks }) => {
    const { textosSubSecciones: textosBusqueda } = useObtenerSubsecciones('busqueda');
    const [activeFilters, setActiveFilters] = useState([]);
    const [openedOnMobile, setOpenedOnMobile] = useState(false);
    const params = useQueryParams();

    const searchInputRef = useRef(null);


    const borrarFiltros = () => {
        setFilters({...FiltrosBusqueda})
        setActiveFilters([]);
        searchInputRef.current.value = '';
    }

    const changeFilter = (property, data) => {
        setFilters(draft=>{
            draft[property] = data;
        })
    }


    const getFiltersByStateOrParams = ()=>{
        let _filters =  {};
        for (const key in filters) {
            const element = filters[key];
            _filters[key] = {...element};
            if(element?.renderType === 'input-text'){
                const param =  decodeURIComponent(params?.get(element?.id));
                _filters[key].value = param !== "null"?param:null
            }
            else  if(element?.renderType === 'checkbox-selector'){
                const param =  decodeURIComponent(params?.get(element?.id));
                if(element?.id === 'dificultadLectora'){
                    _filters[key].items = _filters[key].items.map(item=>{
                        const itemsplitted = item.value.split(";");
                        return {...item, 
                        checked: itemsplitted.find(value=>value===param)?true:false
                        }
                    })
                }else{
                    _filters[key].items = _filters[key].items.map(item=>{
                        const splitted = Boolean(param)?param.split(";"):"";
                        return {...item, 
                        checked: splitted.find(value=>value===item?.id)?true:false
                        }
                    })
                }
            }
            else  if(element?.renderType === 'checkbox'){
                const param =  decodeURIComponent(params?.get(element?.id));
                _filters[key].checked = param==="true"?true:false
            }
        }
        return _filters;
    }

    const getActiveFilters = (_filters)=>{
        return Object.entries(_filters).reduce((acc, curr) => {
            if (curr[1].renderType === 'checkbox-selector') {
              const items = curr[1].items.filter(item => item.checked === true);
              if(items.length > 0) acc = [...acc, {...curr[1], items:items}]
            }
            else if (curr[1].renderType === 'checkbox') {
              if (curr[1].checked) acc.push(curr[1])
            }
            else if (curr[1].renderType === 'input-text') {
              if (Boolean(curr[1].value)) acc.push({
                ...curr[1], name: {
                  defaultValue: curr[1].value,
                  translationId: curr[1].value
                }
              })
            }
            return acc;
          }, [])
      }
    

    useEffect(() => {
        const currentActiveFilters= getActiveFilters(filters);
        setActiveFilters(getActiveFilters(currentActiveFilters));
        // console.log('filtros', filtros)
    }, [JSON.stringify(filters)])


    useEffect(()=>{
        if(activeFilters.length <= 0 ){
            onDeleteFilters&&onDeleteFilters([]);
        }
    },[activeFilters])



    useEffect(()=>{
        const loadInitialFilters = async ()=>{
            const filtrosActualizados = getFiltersByStateOrParams();
            setFilters(filtrosActualizados);
            const currentActiveFilters= getActiveFilters(filtrosActualizados);
            setActiveFilters(getActiveFilters(currentActiveFilters));
            onChange&&onChange(getActiveFilters(filtrosActualizados));
        }
        loadInitialFilters();
    },[params])


    return (<div className={`filtro-container ${openedOnMobile ? 'show' : 'hide'}`}>


        <button
            type="button"
            className="focusable-primary mobile-button primary-inverse--bordered primary--bg  btn-primary rotatebtn btn onprimary--color"
            onClick={() => {
                setOpenedOnMobile(!openedOnMobile)
            }}
        >
            <span>
                <i className="fas fa-filter fa-sm"></i> {textosBusqueda?.filtro?.boton_filtros || 'Filtros'}{" "}
                <span className="numFiltros"></span>
            </span>

            <i className="fa fa-caret-down flecha" style={{rotate:openedOnMobile?"180deg":"0deg"}}></i>
        </button>

        <form
            className="menu"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <label className="d-flex flex-row justify-content-center align-items-center">
                <input
                    ref ={searchInputRef}
                    className="focusable-primary w-100 onbackground-fourth--placeholder search-item--bar search-input"
                    type="text"
                    placeholder={textosBusqueda?.filtro?.buscar || "Buscar"}
                    aria-label="Search"
                    id="search-item--bar"
                    value={filters?.sch.value || ''}
                // defaultValue={searchQuery}
                    onChange={(e) => changeFilter("sch", {
                        ...FiltrosBusqueda.sch,
                        value: e.target.value
                    })}
                />
                <button
                    className="focusable-secondary surface--bg"
                    style={{outline:"none", border:'none'}} 
                    type="submit"
                    name="Buscar"
                    aria-label="Buscar"
                    onClick={() => onChange && onChange(activeFilters)}
                >
                    <i
                        className="m-2 secondary-inverse--color search-icon fas fa-search secondary-item"
                        aria-hidden="true"
                        id="search-icon-busqueda"
                    ></i>
                </button>
              
            </label>


            <div className="d-flex flex-column py-3" >
                <button
                    type="submit"
                    className="col-12 btn btn-secondary text-center"
                    id="send-search"
                    disabled={!(Boolean(activeFilters) && Object.keys(activeFilters).length > 0)}
                    onClick={() => onChange && onChange(activeFilters)}
                >
                    {
                        Boolean(loadingData) === true ?
                            <i className="fas fa-spinner fa-pulse"></i>
                            :
                            <span>{textosBusqueda?.filtro?.aplicar_filtro || 'Aplicar filtros'}</span>
                    }

                </button>
                {
                Boolean(activeFilters) && Object.keys(activeFilters).length > 0 &&
                <button
                    type="button"
                    name="Borrar filtros"
                    className="focusable-secondary onbackground-primary--color btnEliminar  btn d-flex justify-content-between align-items-center"
                    onClick={() => borrarFiltros()}
                >
                    <span className="mr-1">{textosBusqueda?.filtro?.borrar_filtros || 'Borrar todos los filtros'}</span>
                    <i className="fas fa-times fa-sm"></i>
                </button>
            }
            </div>


            <ul className="search-menu p-0 w-100">
            {Object.entries(filters)
                    .filter(fil => fil[1].renderType === 'checkbox')
                    .map((filter, i) => {
                        if (filter[0] === "libroPremiado" && booksAwarded) {
                            return (
                                <li className="search-item" key={i}>
                                    <SearchItems.CheckBox
                                        itemData={filter[1]}
                                        onChange={(data) => changeFilter(filter[0], data)}
                                    />
                                </li>
                            )
                        }
                        
                        if (filter[0] === "libroAccesible" && assistedBooks) {
                            return (
                                <li className="search-item" key={i}>
                                    <SearchItems.CheckBox
                                        itemData={filter[1]}
                                        onChange={(data) => changeFilter(filter[0], data)}
                                    />
                                </li>
                            )
                        }
                        
                        if (filter[0] === "actividad"){
                            return (
                                <li className="search-item" key={i}>
                                    <SearchItems.CheckBox
                                        itemData={filter[1]}
                                        onChange={(data) => changeFilter(filter[0], data)}
                                    />
                                </li>
                            )
                        }
                    })
                }

                {Object.entries(filters)
                    .filter(fil => fil[1].renderType === 'checkbox-selector')
                    .map((filter, i) => {
                        return (
                            <SearchItems.CheckBoxSelector
                                key={i}
                                itemData={filter[1]}
                                onChange={(data) => changeFilter(filter[0], data)}
                            />
                        )
                    })}
            </ul>
        </form>
    </div>)
})