import React, { useEffect, useState } from "react";
// import Swiper from "swiper";
import getUrlParam from './../js/utilities/getUrl';
import { useHistory } from 'react-router-dom';
import { useRef } from "react";
import { GENERAL_URL_API } from "../components/Store";
import postAjax from './../js/utilities/postAjax';
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Componente que muestra un formulario para ingresar un correo electrónico para guardarlo en la base de datos. Es el paso 2 del flujo de información institucional.
 */
const IngresarCorreo = () => {
  const { isReady, textosSubSecciones: textosInfo } = useObtenerSubsecciones('informacion');

  const refCorreo = useRef(null);
  const refFormulario = useRef(null);

  const [instituto, setInstituto] = useState('')
  const [notificationCorreo, setNotificationCorreo] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const institucion = getUrlParam('it');
    if (!!institucion) {
      setInstituto(decodeURIComponent(institucion));
    } else {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const enviarFormulario = (e) => {
    if (refCorreo.current.checkValidity()) {
      e.preventDefault();

      const propiedades = {
        email: refCorreo.current.value,
        itVal: instituto
      }

      postAjax(`${GENERAL_URL_API}/assets/guardarCorreoDemoConInst`, propiedades, response => {
        const respuesta = response.toLowerCase().search('error');

        if (respuesta === -1) {
          history.push(`/informacion/datos-institucion?it=${instituto}`)
        }
      });
    }else{
      setNotificationCorreo(true)
    }
  }

  return (
    <>
      {isReady &&
        <div className="col-lg-8 mx-auto pt-3 pb-5 mb-5">
          <h2 className="mm-redimir_title mm-redimir_title--institution mt-3 mb-4 text-center secondary-item">{textosInfo?.ingresar_correo?.titulo?.parte_uno || '¡Tu institución'} <br aria-hidden={true}/>{textosInfo?.ingresar_correo?.titulo?.parte_dos || 'ya tiene MakeMake!'}</h2>

          <p className="text-center mb-5 onbackground-third--color">{textosInfo?.ingresar_correo?.descripcion || 'Ingresa tu correo para entregarte la información de contacto de tu institución.'}
          </p>

          <form ref={refFormulario} className="mm-redimir_form" >
            <p className="secondary-item"><strong>{textosInfo?.ingresar_correo?.correo?.texto || 'Correo:'}</strong></p>
            <div className="row mx-0 align-items-start">
              <div className="col-md-9 px-0 pr-lg-3 mb-4 mb-lg-0">
                <label
                  htmlFor="inputCode"
                  className="sr-only"
                  aria-hidden={true}
                >
                  {textosInfo?.ingresar_correo?.correo?.label || 'Correo electrónico'}
                </label>
                <input
                  ref={refCorreo}
                  type="email"
                  className="form-control surface--bg  onbackground-third--color focusable-secondary"
                  id="inputCode"
                  placeholder={textosInfo?.ingresar_correo?.correo?.placeholder || "Escribe el nombre de tu institución"}
                  required
                  onChange={() => setNotificationCorreo(false)}
                />
                <span
                  role={"alert"}
                  aria-live="assertive"
                  className="notificacion-form"
                >
                  {notificationCorreo ? textosInfo?.ingresar_correo?.correo?.alert || 'Para enviar este formulario debes ingresar un correo' : ''}
                </span>
              </div>
              <button
                onClick={enviarFormulario}
                className="btn btn-primary mm-redimir_submit d-block col-md-3 focusable-red"
                type="submit"
              >
                {textosInfo?.botones?.enviar || 'Enviar'}
              </button>
            </div>
          </form>
        </div>
      }
    </>
  )
}


export default IngresarCorreo;