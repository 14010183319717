import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import useObtenerTraducciones from "../js/translations/useObtenerTraducciones";
/**
 * 
 * @param {{section:string, lang:"es"|"en", array:boolean}} props 
 * @param {string} props.section - nombre de la sección. 
 * @param {string} props.lang - idioma en que se requiere el texto.
 * @param {string} props.array - si es un arreglo, es decir si no tiene subsecciones.
 * @returns {object || null || undefined || false || {}} - devuelve los textos.  
 */
export const useTranslations = ({ section, lang, array }) => {
	const { textosSubSecciones } = useObtenerSubsecciones(section, null, lang);
	const { textosSeccion } = useObtenerTraducciones(section, null, lang);
	return array===true?textosSeccion || []:textosSubSecciones || {};
};

