import React, { useRef, useState, useContext } from "react";
import { Icon } from "@iconify/react";
import { SectionTitle } from "./section-title";
import { TutorialCard } from "./tutorial-card";
import { BaseModal } from "../../../components/modals/base-modal";
import { AccesibilidadContext, TemasDisponibles } from "../../../contexts/AccesibilidadContext";
export const Tutorials = () => {
	const accesibility = useContext(AccesibilidadContext);
	const classicsTheme = accesibility?.temaActual === TemasDisponibles.clasico.id;
	const [currentvideoTutorial, setCurrentVideoTutorial] = useState({
		tutorialLink: "",
		description: "",
	});

	const [showVideoTutorial, setShowVideoTutorial] = useState(false);
	const inputRef = useRef();

	const firstSectionData = [
		{
			imagePath: "/img/piloto-rnbp/Thumb6.png",
			tutorialLink:
				"https://player.vimeo.com/video/1022255772?h=31db52739a&badge=0&autopause=0&player_id=0&app_id=58479",
			description: "¡Descarga la app MakeMake en tu celular!",
			type: "video",
		},
		{
			imagePath: "/img/piloto-rnbp/Thumb1.png",
			tutorialLink:
				"https://player.vimeo.com/video/1022255797?h=2a5f0dae5b&badge=0&autopause=0&player_id=0&app_id=58479",

			description: "Ver videotutorial de descarga de libros y uso offline",
			type: "video",
		},
		{
			imagePath: "/img/piloto-rnbp/Thumb2.png",
			tutorialLink: "/document/piloto-rnbp/descargar-libros-instructivo.pdf",
			description: "Descarga el instructivo de descarga de libros y uso offline",
			type: "file",
		},
	];

	const secondSectionData = [
		{
			imagePath: "/img/piloto-rnbp/Thumb3.png",
			tutorialLink:
				"https://player.vimeo.com/video/1022255734?h=49ede897ee&badge=0&autopause=0&player_id=0&app_id=58479",
			description: "Cómo ingresar a MakeMake",
			type: "video",
		},
		{
			imagePath: "/img/piloto-rnbp/Thumb4.png",
			tutorialLink:
				"https://player.vimeo.com/video/1022255820?h=55ad856961&badge=0&autopause=0&player_id=0&app_id=58479",
			description: "Recorrido por MakeMake",
			type: "video",
		},
		{
			imagePath: "/img/piloto-rnbp/Thumb5.png",
			tutorialLink:
				"https://www.dropbox.com/scl/fo/chqn8n8mnqjb50fikidar/AFWICv-KCZN7LxW5HV1rp4k?rlkey=n1axjm172bvhezy3dus3fjm7k&e=1&st=rsn1suv4&dl=0",
			description: "Descarga el kit de divulgación",
			type: "file",
		},
		{
			imagePath: "/img/piloto-rnbp/Thumb7.png",
			tutorialLink: "/document/piloto-rnbp/makemake-instructivo.pdf",
			description: "Instructivo MakeMake",
			type: "file",
		},
	];

	const handleOnClick = ({ tutorial }) => {
		if (tutorial.type === "video") {
			setCurrentVideoTutorial(tutorial);
			setShowVideoTutorial(true);
		}
	};

	const copyTutorialLink = (link) => {
		navigator.clipboard.writeText(link);
		if(inputRef.current) {
			inputRef.current.focus();
			inputRef.current.select();
		}
	};

	return (
		<>
			<section className="p-3 py-5">
				<SectionTitle title="Descarga libros y uso offline" />

				<div className="row p-3 pt-5 justify-content-center">
					{firstSectionData.map((tutorial, index) => (
						<div key={index} className="p-3 col-12 col-sm-6 col-lg-4 col-xl-3 ">
							<TutorialCard
								key={index}
								data={tutorial}
								isVideo={tutorial.type === "video"}
								onClick={() => handleOnClick({ tutorial })}
							/>
						</div>
					))}
				</div>
			</section>
			<section className="p-3">
				<SectionTitle title="Tutoriales y divulgación" />

				<div className="row p-3 pt-5 justify-content-center">
					{secondSectionData.map((tutorial, index) => (
						<div key={index} className="p-3 col-12 col-sm-6 col-lg-4 col-xl-3 ">
							<TutorialCard
								key={index}
								data={tutorial}
								isVideo={tutorial.type === "video"}
								onClick={() => handleOnClick({ tutorial })}
							/>
						</div>
					))}
				</div>
			</section>
			{showVideoTutorial && (
				<BaseModal
					windowClassName="default-modal-window"
					setVisible={setShowVideoTutorial}
					visible={showVideoTutorial}
					backdropClose={true}
				>
					<div style={{ width: "40vw", minWidth: 300, maxWidth: 800 }}>
						<div className=" d-flex justify-content-end">
							<button onClick={() => setShowVideoTutorial(false)}>
								<Icon
									className="secondary-inverse--color"
									icon="mdi:close-thick"
									width={30}
								/>
							</button>
						</div>
						<h3 className="my-3 text-center">{currentvideoTutorial.description}</h3>
						<iframe
							className=""
							title={currentvideoTutorial.description}
							key={currentvideoTutorial}
							src={currentvideoTutorial.tutorialLink}
							allowFullScreen
							style={{
								width: "100%",
								height: "315px",
								border: "none",
								outline: "none",
							}}
						/>
						<div className="copy-box onbackground-primary--color mt-2 my-3 mx-auto">
							<input
								ref={inputRef}
								className={`copy-box-input background--bg  onbackground-primary--color ${
									!classicsTheme && "onbackground-primary--bordered"
								}`}
								type="text"
								contentEditable={false}
								value={currentvideoTutorial?.tutorialLink}
							/>
							<button
								aria-label="Copiar link"
								className="copy-box-button  focusable-red"
								onClick={() => copyTutorialLink(currentvideoTutorial?.tutorialLink)}
							>
								<Icon icon={"clarity:copy-line"} width={30} />
							</button>
						</div>
					</div>
				</BaseModal>
			)}
		</>
	);
};
