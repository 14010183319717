import React from "react";
import {  Img } from "../../components/shared/img";
import { ASSETS_URL } from "../../components/Store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper";
import { memo } from "react";
import {  trackWindowScroll } from "react-lazy-load-image-component";

/**
 * Componente de banner con imagen.
 * @param {Object} props - Props del componente.
 * @param {string} props.url - URL de la imagen del banner.
 * @param {Function} props.onClick - Función de clic del banner.
 * @param {string} props.className - Clases CSS adicionales para el banner.
 * @param {Object} props.style - Estilos CSS adicionales para el banner.
 * @param {number} props.scrollPosition - Posición de desplazamiento de la ventana.
 * @returns {JSX.Element} Elemento JSX que representa el banner con imagen.
 */
export const Banner = memo(({ url, onClick, className, style, scrollPosition }) => {
    return (
        <Img
            src={url}
            onClick={() => onClick && onClick()}
            className={className}
            wrapperClassName={className}
            style={style}
            scrollPosition={scrollPosition}
            width={300}
            height={300}  
        />
    );
});

/**
 * Componente de sección de banners.
 * @param {Object} props - Props del componente.
 * @param {Array} props.banners - Banners para mostrar.
 * @param {number} props.scrollPosition - Posición de desplazamiento de la ventana.
 * @returns {JSX.Element} Elemento JSX que representa la sección de banners.
 */
export const BannerSection =trackWindowScroll( memo(({ banners, scrollPosition }) => {
    banners = Array.isArray(banners) ? banners : [];
    return (
        <section>
            <div className="relative">
                <Swiper
                    className="focusable-secondary home-banners pb-5"
                    slidesPerView={'1'}
                    spaceBetween={20}
                    // loop
                    breakpoints={{
                        800: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1300: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                    }}
                    autoplay={{
                        delay: 3400,
                        disableOnInteraction: false,
                    }}
                    mousewheel={{
                        forceToAxis: true,
                    }}
                    pagination={{
                        clickable: true,
                        enabled:true,
                    }}
                    modules={[Autoplay, Pagination, Navigation, Mousewheel]}
                >
                    {banners.map((banner, i) => {
                        return (
                            <SwiperSlide
                                className="focusable-by-children-secondary "
                                key={`banner${i}`}
                            >
                                <a
                                    key={i}
                                    href={banner?.link || ""}
                                    aria-label={banner?.link}
                                    target={banner?.link?.includes("http") ? "_blank" : "_self"}
                                >
                                    <Banner
                                        key={i}
                                        url={`${ASSETS_URL}${banner?.url}`}
                                        className={'w-100 h-100'}
                                        scrollPosition={scrollPosition}
                                    />
                                </a>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </section>
    );
}));