import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Muestra un mensaje con la información después de enviar el formulario de demo.
 * @returns {JSX.Element} MensajeSolicitudDemo
 */
export const MensajeSolicitudDemo = ({lang}) => {
    const lang2 = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:lang ? lang :'en' });
    const textosInfo = useTranslations({ section: "mensajeCompra", lang: lang2, array: true});

    return (
        <div id="demo-mensaje">
            <div>
                <div className="onbackground-primary--color p-3 pl-lg-5">
                    <div
                        tabIndex={0}
                        role={"region"}
                        aria-label={textosInfo?.titulo || '¡Hemos enviado tu solicitud!'}
                        className={"focusable-secondary"} id="onbackground-primary--color mm-mensaje-institucion"
                    >
                        <div className="onbackground-primary--color col-lg-10 mx-auto pt-3 pb-4">
                            <h2 className="mm-redimir_title mb-5 text-center onsecondary--color">
                                {textosInfo?.titulo || '¡Hemos enviado tu solicitud! '}
                            </h2>

                            <p className="mm-redmir_textlogin text-center onsecondary--color">
                                {textosInfo?.descripcion_uno || 'Los accesos demo son exclusivos para uso institucional. Nuestros asesores analizarán tu solicitud y si cumples con los requisitos recibirás tu acceso demo.'}{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


