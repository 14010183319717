import React from "react";
/**
 * Componente que muestra los datos de una institución seleccionada.
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.institutionData - Datos de la institución seleccionada.
 * @param {string} props.institutionData.name - Nombre de la institución.
 * @param {string} [props.institutionData.city="N/A"] - Ciudad de la institución. Valor predeterminado: "N/A".
 * @param {string} [props.institutionData.country="N/A"] - País de la institución. Valor predeterminado: "N/A".
 * @param {boolean} [props.removable=false] - Indica si se puede eliminar la institución. Valor predeterminado: `false`, si esta en falso no se muestra el botón de remover
 * @param {Function} [props.onRemove] - Función que se ejecuta al hacer clic en el botón de eliminar.
 * @returns {JSX.Element} Elemento JSX que muestra los datos de una institución seleccionada.
 */
export const SelectedInstitution = ({ institutionData, removable, onRemove }) => {
	return (
		<div className="selected-institution p-2 px-3  d-flex justify-content-between align-items-center">
			<div className=" d-flex flex-column justify-content-center align-items-center flex-grow-1">
				<span className="name text-22">{institutionData?.name || "N/A"}</span>
				<span className="location">
					{institutionData?.city || "N/A"},{institutionData?.country || "N/A"}{" "}
				</span>
			</div>
			{removable === true && (
				<button
					className="remove-button d-flex justify-content-center align-items-center focusable-primary"
					onClick={() => onRemove && onRemove()}
				>
					<span className="onprimary-inv-var1--color">x</span>
				</button>
			)}
		</div>
	);
};
