import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import departamentos from "./Departamentos";
import MicroModal from "micromodal";
import ReCAPTCHA from "react-google-recaptcha";

import { DatosFormInstitutoAPI } from "../services/api/LoginInstitucional";
import { CancelRequestController } from "../util/cancel-request-controller";
import ModalAlertas from "../components/ModalAlertas";
import PropTypes from "prop-types";
import { useQueryParams } from "../hooks/useQueryParams";

/**
 * Este componente se encarga de renderizar el formulario de acceso institucional de tipo formExten ('exten' de extendido). Este fue nombrado de esa manera (FormValle) ya que  anteriormente comfenalco_valle hacia uso de este formulario. Su función principal es validar los campos y redireccionar el inicio de sesión. Este hace uso del componente ModalAlertas.
 */
const FormValle = (props) => {
  const { traducciones, instituto } = props;
  const { institucional, login } = traducciones;
  const { form } = institucional;
  const { tipoId, numId, correo, celular, genero, fechaNacimiento } = form;

  const [preloader, setPreloader] = useState(false);

  const [notificacionTipoDoc, setNotificacionTipoDoc] = useState(false);
  const [notificacionNumId, setNotificacionNumId] = useState(false);
  const [notificacionNombre, setNotificacionNombre] = useState(false);
  const [notificacionCorreo, setNotificacionCorreo] = useState(false);
  const [notificacionCel, setNotificacionCel] = useState(false);
  const [notificacionGenero, setNotificacionGenero] = useState(false);
  const [notificacionNacimiento, setNotificacionNacimiento] = useState(false);
  const [notificacionDepartamento, setNotificacionDepartamento] = useState(false);
  const [captchaValido, setCaptchaValido] = useState(null);
  const [mostrarMensajeCaptcha, setMostrarMensajeCaptcha] = useState(false);
  const [isDevelopment, setIsdevelopment] = useState(false);

  const refForm = useRef(null);

  const tipoIdRef = useRef(null);
  const numeroIDRef = useRef(null);
  const nombreRef = useRef(null);
  const correoRef = useRef(null);
  const celularRef = useRef(null);
  const generoRef = useRef(null);
  const fechaNacimientoRef = useRef(null);
  const departamentoRef = useRef(null);
  const captcha = useRef(null);
  const location = useLocation();
  const params = useQueryParams();

  useEffect(()=>{
    const origin = window?.location?.origin;
    const development = origin === 'https://makemake.com.co' ?   false : true;
    setIsdevelopment(development)
  },[])

  const enviarDatosAcceso = async (e) => {

    e.preventDefault();

    tipoIdRef.current.addEventListener("invalid", () => { setNotificacionTipoDoc(true) });
    numeroIDRef.current.addEventListener("invalid", () => { setNotificacionNumId(true) });
    nombreRef.current.addEventListener("invalid", () => { setNotificacionNombre(true) });
    correoRef.current.addEventListener("invalid", () => { setNotificacionCorreo(true) });
    celularRef.current.addEventListener("invalid", () => { setNotificacionCel(true) });
    generoRef.current.addEventListener("invalid", () => { setNotificacionGenero(true) });
    fechaNacimientoRef.current.addEventListener("invalid", () => { setNotificacionNacimiento(true) });
    departamentoRef.current.addEventListener("invalid", () => { setNotificacionDepartamento(true) });

    if (refForm.current.reportValidity()) {
      if (isDevelopment === false) {
        if (captchaValido === true) {
          enviarDatos()
        } else {
          setMostrarMensajeCaptcha(true)
        }
      } else {
        enviarDatos()
      }
    }
  }

  const enviarDatos = async () => {
    setPreloader(true)
    setMostrarMensajeCaptcha(false)
    let abortController = new CancelRequestController();

    let tipoIngreso = '';
    if (location.pathname === '/institucional/formulario' && params.get('access') === 'app') {
      tipoIngreso = 'app';
    }
    const propiedades = {
      inst: instituto,
      tipoId: tipoIdRef.current.value,
      cedula: numeroIDRef.current.value,
      nombre: nombreRef.current.value,
      correo: correoRef.current.value,
      celular: celularRef.current.value,
      genero: generoRef.current.value,
      fechaNacimiento: fechaNacimientoRef.current.value,
      departamento: departamentoRef.current.value,
      tipoIngreso: tipoIngreso
    }

    await DatosFormInstitutoAPI.enviarDatos({
      abortSignal: abortController.signal,
      data: propiedades
    }).then(respuesta => {
      const error = respuesta.status;

      if (error === 0) {
        setPreloader(false)
        MicroModal.show('modal-form-institucional', {
          awaitCloseAnimation: true
        });
      } else {
        setPreloader(true)
        window.location.href = window.location.origin + respuesta.info;
      }
    }).catch(error => console.log(error));

    return () => {
      abortController.abort();
    }
  }

  /**
   * Esta función cambia el estado de setCaptchaValido en caso de que el captcha sea correcto
   */
  const onChange = () => {
    if (captcha.current.getValue()) {
      setCaptchaValido(true)
    }
  }
  return (
    <>
      <div className="col-lg-9 mx-auto pt-3 pb-5">
        <h2 className="mt-3 mb-4 text-center pb-3">{form?.titulo || 'Ingresa tus datos'}</h2>

        <form
          className="row mx-0 my-3 align-items-end"
          ref={refForm}
        >

          {/* Tipo de identificación */}
          <div className="col-lg-6 mb-3">
            <p className="secondary-item">
              <strong>{tipoId?.label || 'Tipo de identificación*:'}</strong>
            </p>
            <label htmlFor="inputTipoId" className="sr-only" aria-hidden={true}>
              {tipoId?.label || 'Tipo de identificación'}
            </label>
            <select
              className="form-control focusable-secondary"
              id="inputTipoId"
              required
              ref={tipoIdRef}
              onChange={() => setNotificacionTipoDoc(false)}
            >
              <option hidden value="">
                {tipoId?.placeholder || 'Escoge el tipo de Identificación'}
              </option>
              <option value={tipoId?.cc || 'Cédula de ciudadanía'}>{tipoId?.cc || 'Cédula de ciudadanía'}</option>
              <option value={tipoId?.ce || 'Cédula de extranjería'}>{tipoId?.ce || 'Cédula de extranjería'}</option>
              <option value={tipoId?.ti || 'Tarjeta de identidad'}>{tipoId?.ti || 'Tarjeta de identidad'}</option>
            </select>

            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionTipoDoc ? tipoId?.alert || 'Selecciona el tipo de documento' : ''}
            </span>
          </div>

          {/* Número de identificación */}
          <div className="col-lg-6 mb-3 ">
            <p className="secondary-item">
              <strong>{numId?.label || 'Número de identificación*:'}</strong>
            </p>
            <label htmlFor="numIdentificacion" className="sr-only" aria-hidden={true}>
              {numId?.label || 'Número de identificación'}
            </label>
            <input
              ref={numeroIDRef}
              type="number"
              className="form-control focusable-secondary"
              id="numIdentificacion"
              placeholder={numId?.placeholder || "Escribe tu número de identificación"}
              required
              onChange={() => setNotificacionNumId(false)}
            />
            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionNumId ? numId?.alert || 'Debes escribir tu número de identificación' : ''}
            </span>
          </div>

          {/* nombre y apellido */}
          <div className="col-lg-6 mb-3 ">
            <p className="secondary-item">
              <strong>{form?.nombre?.label || 'Nombre y apellido*:'}</strong>
            </p>
            <p className="onbackground-third--color">{form?.nombre?.descripcion || 'Escríbelos como aparecen en tu cédula'}</p>
            <label htmlFor="nombreYapellido" className="sr-only" aria-hidden={true}>
              {form?.nombre?.label || 'Nombre y apellido*:'}
            </label>
            <input
              type="text"
              ref={nombreRef}
              className="form-control focusable-secondary"
              id="nombreYapellido"
              placeholder={form?.nombre?.placeholder || "Escribe tu nombre y apellido aquí"}
              required
              onChange={() => setNotificacionNombre(false)}
            />
            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionNombre ? form?.nombre?.alert || 'Debes escribir tu nombre y apellido' : ''}
            </span>
          </div>

          {/* Correo electronico */}
          <div className="col-lg-6 mb-3">
            <p className="secondary-item">
              <strong>{correo?.label || 'Correo electrónico*:'}</strong>
            </p>
            <label htmlFor="correo" className="sr-only" aria-hidden={true}>
              {correo?.label || 'Correo electrónico'}
            </label>
            <input
              type="email"
              className="form-control focusable-secondary"
              id="correo"
              placeholder={correo?.placeholder || "usuario@correo.com"}
              required
              ref={correoRef}
              onChange={() => setNotificacionCorreo(false)}
            />
            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionCorreo ? correo?.alert || 'Por favor, escribe tu correo electrónico' : ''}
            </span>
          </div>

          {/* Número de celular */}
          <div className="col-lg-6 mb-3">
            <p className="secondary-item">
              <strong>{celular?.label || 'Número de celular*:'}</strong>
            </p>
            <label htmlFor="celular" className="sr-only" aria-hidden={true}>
              {celular?.label || 'Número de celular'}
            </label>
            <input
              type="tel"
              className="form-control focusable-secondary"
              id="celular"
              placeholder={celular?.placeholder || "Escribe tu número de celular"}
              required
              ref={celularRef}
              onChange={() => setNotificacionCel(false)}
            />

            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionCel ? celular?.alert || 'Por favor, escribe tu número de celular' : ''}
            </span>
          </div>

          {/* Género */}
          <div className="col-lg-6 mb-3">
            <p className="secondary-item">
              <strong>{genero?.label || 'Género*:'}</strong>
            </p>
            <label htmlFor="genero" className="sr-only" aria-hidden={true}>
              {genero?.label || 'Género'}
            </label>
            <select
              className="form-control focusable-secondary"
              id="genero"
              required
              ref={generoRef}
              onChange={() => setNotificacionGenero(false)}
            >
              <option hidden value="">
                {genero?.placeholder || 'Escoge tu género'}
              </option>
              <option value={genero?.femenino || 'Femenino'}>{genero?.femenino || 'Femenino'}</option>
              <option value={genero?.masculino || 'Masculino'}>{genero?.masculino || 'Masculino'}</option>
            </select>

            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionGenero ? genero?.alert || 'Debes escoger tu género' : ''}
            </span>
          </div>

          {/* Fecha de nacimiento */}
          <div className="col-lg-6 mb-3 mb-lg-0">
            <p className="secondary-item" aria-hidden={true}>
              <strong>{fechaNacimiento?.label || 'Fecha de nacimiento*:'}</strong>
            </p>
            <label htmlFor="fechaNacimiento" className="sr-only" >
              {fechaNacimiento?.label || 'Fecha de nacimiento'}
            </label>
            <input
              type="date"
              min="1960-01-01"
              className="form-control focusable-secondary"
              id="fechaNacimiento"
              required
              ref={fechaNacimientoRef}
              onChange={() => setNotificacionNacimiento(false)}
            />
            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionNacimiento ? fechaNacimiento?.alert || 'Por favor, elige tu fecha de nacimiento' : ''}
            </span>
          </div>

          {/* departamento */}
          <div className="col-lg-6">
            <p className="secondary-item">
              <strong>{form?.departamento?.label || 'Departamento* (Departamento de expedición de cédula):'}</strong>
            </p>
            <label htmlFor="departamento" className="sr-only" aria-hidden={true}>
              {form?.departamento?.label || 'Departamento* (Departamento de expedición de cédula):'}
            </label>
            <select
              className="form-control focusable-secondary"
              id="departamento"
              required
              ref={departamentoRef}
              onChange={() => setNotificacionDepartamento(false)}
            >
              <option hidden value="">
                {form?.departamento?.placeholder || 'Escoge tu departamento'}
              </option>
              {departamentos.map(item => {
                return (
                  <option key={item} value={item}>{item}</option>
                )
              })}
            </select>

            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionDepartamento ? form?.departamento?.alert || 'Debes elegir el departamento donde expediste tu cédula' : ''}
            </span>

          </div>


          <p className="my-5 my-lg-0 p-2 p-lg-5 text-center mm-redimir-terms">
            {traducciones?.institucional.form?.terminos?.ingresar || 'Al ingresar tus datos estás aceptando'}
            <Link
              className="links-tyc secondary-item focusable-red"
              to="/terminos-y-condiciones"
            >
              {" "}{traducciones?.institucional.form?.terminos?.term_condiciones || 'Términos y condiciones'}
            </Link>
            {" "}{traducciones?.institucional.form?.terminos?.y || 'y'} {" "}
            <Link
              className="links-tyc secondary-item focusable-red"
              to="/politica-de-privacidad"
            >
              {traducciones?.institucional.form?.terminos?.politica || 'Política de privacidad'}
            </Link>
          </p>

          {isDevelopment === false &&
            <div className="w-100 d-flex justify-content-center">
              <ReCAPTCHA
                ref={captcha}
                sitekey="6LfaebskAAAAAF4jRB9WbC3pOOgmAaPZnOf-kn5-"
                onChange={onChange}
              />
            </div>
          }
          {mostrarMensajeCaptcha &&
            <span role={"alert"} aria-live="assertive" className="notificacion-form d-block w-100 mt-3 text-center">{traducciones?.institucional.form?.captcha || 'Por favor acepta el captcha'}</span>
          }

          <button
            className={`btn col-6 col-lg-4 d-block mx-auto focusable-red mt-5 ${preloader ? 'btn-disabled' : 'btn-primary'}`}
            type="submit"
            onClick={(e) => enviarDatosAcceso(e)}
            disabled={preloader ? true : false}
          >
            {preloader ?
              <i className="fas fa-spinner fa-pulse"></i>
              :
              traducciones?.login?.botones?.enviar || "Enviar"
            }
          </button>

        </form>
      </div>

      <ModalAlertas
        id={"modal-form-institucional"}
        alertType={form?.modal?.titulo || 'Alerta'}
        message={form?.modal?.mensaje || 'Error de acceso'}
        description={form?.modal?.descripcion || 'El nombre ingresado no coincide con el de la cédula'}
        boton={login?.botones?.aceptar || "Aceptar"} />
    </>
  )
}

export default FormValle;

FormValle.propTypes = {
  /**
   * String del nombre del instituto. Este valor se usa para los datos requeridos del api assets/formulario/loginFormulario
   */
  instituto: PropTypes.string.isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  traducciones: PropTypes.object.isRequired,
}