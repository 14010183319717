import { useContext, useEffect, useMemo } from "react";
import { store } from "../../components/Store";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { InstitutionalAPI } from "../../services/api/institutional";
import { useState } from "react";

/**
 * Hook personalizado que obtiene una lista de bibliotecas a partir de datos de instituciones.
 * Utiliza `useImmer` para manejar el estado inmutable de las instituciones.
 * @returns {{data:[]}} Un objeto que contiene:
 *   - `data`: Una lista de bibliotecas generada a partir de las instituciones. Tipo: `Array`.
 */
export const useLibraryList = () => {
	const storeContext = useContext(store);
	const appState = storeContext?.state;
	const institutions = appState?.institutionalAuth?.institutions;
	const fetchCompleted = appState?.institutionalAuth?.fetchCompleted;
	const [loading, setLoading] = useState(true);

	const data = useMemo(() => {
		const _libraries = [];
		institutions.forEach(institution => {
			const _inst = { ...institution };
			delete _inst?.sedes;
			const _libs = institution?.sedes?.map(library => {
				const _lib = {
					instituto: _inst,
					...library
				}
				return _lib;
			})
			_libraries.push(..._libs)
		})
		return _libraries;
	}, [institutions]);


	const fetchInstitutions = async ({ abortSignal }) => {
		setLoading(true);
		await InstitutionalAPI.getAllInstitutions({ abortSignal: abortSignal })
			.then(result => {
				storeContext.dispatch({
					type: 'CHANGE',
					path: 'institutionalAuth.institutions',
					value: result
				})
				storeContext.dispatch({
					type: 'CHANGE',
					path: 'institutionalAuth.fetchCompleted',
					value: true
				})
			})
			.catch(error => console.log(error))
			.finally(() => {
				setLoading(false);
			})
	}

	useEffect(() => {
		let abortController = new CancelRequestController();
		if (fetchCompleted !== true) {
			fetchInstitutions({ abortSignal: abortController.signal });
		}
		if (fetchCompleted) {
			setLoading(false);
		}
		return () => {
			abortController.abort();
		}
	}, [fetchCompleted])


	return {
		data: Array.isArray(data) ? data : [],
		loading: loading
	};
};
