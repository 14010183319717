import React from "react";

/**
 * El componente DataPrivacy representa una sección de la página "Estándares Abiertos para Integraciones en EdTech" relacionada con la privacidad de datos.
 * Renderiza un iframe que muestra contenido HTML específico.
 *
 * @component
 * @example
 * // Uso del componente DataPrivacy
 * <DataPrivacy />
 *
 * @returns {JSX.Element} - El componente DataPrivacy.
 */
export const DataPrivacy = ()=>{
    return (<div className="w-100 overflow-auto">
    <iframe className="w-100 onbackground-primary--bordered" src="/edtech/data-privacy.html" style={{height:'1000px'}}/>
</div>)
} 