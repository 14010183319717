import React, { useEffect, useState } from "react";
import { EventLayer } from "./event-layer";
import { Icon } from "@iconify/react";
import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../../config/constants";
import { useTranslations } from "../../../../hooks/use-translations";
import _ from "lodash";

export const Share = ({ link, description, visible, setVisible }) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "comunidad_club", lang: lang });

	const [copied, setCopied] = useState(false);

	useEffect(() => {
		return () => {
			setCopied(false);
		};
	}, [visible, setVisible]);

	const copyLink = (link) => {
		try {
			navigator.clipboard.writeText(link);
			setCopied(true);
		} catch (err) {
			console.error("Error al copiar: ", link, err);
		}
	};
	return (
		<EventLayer title={_.get(texts, 'makemake_en_vivo.evento.boton.compartir') || "Compartir"} visible={visible} setVisible={setVisible}>
			<div className="share d-flex w-100 flex-column flex-grow-1">
				<p className="onbackground-primary--color my-2">{description || "N/A"}</p>
				<div className="w-100 d-flex flex-nowrap align-items-center">
					<input
						className="input p-2 form-control ondisabled-color--bg disabled-color--color focusable-secondary"
						value={link}
						contentEditable={false}
						onClick={() => copyLink(link)}
						onKeyDown={() => copyLink(link)}
						onKeyUp={() => copyLink(link)}
					/>
					<button className="focusable-red copy-button" onClick={() => copyLink(link)}>
						<Icon icon="solar:copy-bold" width={40} />
					</button>
				</div>
				{copied ? (
					<p className="font-weight-bold green-inverse--color text-center my-2">
						{_.get(texts, 'makemake_en_vivo.evento.compartir.copiado') || '¡Link copiado!'}
					</p>
				) : null}
			</div>
		</EventLayer>
	);
};
