import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GENERAL_URL_API, store } from "../components/Store";
import { DatosPaisesAPI } from "../services/api/datosPaises";
import { SolicitudesDemoAPI } from "../services/api/SolicitudesDemo";
import { CancelRequestController } from "../util/cancel-request-controller";
import ModalAlertas from "./../components/ModalAlertas";
import MicroModal from "micromodal";
import { useImmer } from "use-immer";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";

/**
 * Este componente no recibe props. Su función principal es renderizar los campos del formulario "Podemos ayudar". También se encarga de enviar los datos diligenciados al correo correspondiente. Este hace uso del componente ModalAlertas.
 * @returns PodemosAyudar
 */
const PodemosAyudar = () => {
	const globalState = useContext(store);
	const { state } = globalState;
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textoAyudar = useTranslations({ section: "podemos_ayudar", lang: lang });

	const [paises, setPaises] = useState([]);
	const [preloader, setPreloader] = useState(false);
	const [respuestaApi, setRespuestaApi] = useState({
		description: "",
		message: "",
		icono: "",
		color: "",
	});
	const [paisSelecccionado, setPaisSelecccionado] = useImmer({ nombrePais: "", codigoPais: "" });
	const [notificationAge, setNotificationAge] = useState(false);
	const [isOtherCountry, setOtherCountry] = useState(false);
	const [check, setCheck] = useState(false);

	const refNombre = useRef(null);
	const refCorreo = useRef(null);
	const refPais = useRef(null);
	const refOtroPais = useRef(null);
	const refCiudad = useRef(null);
	const refEdad = useRef(null);
	const refTel = useRef(null);
	const refFormulario = useRef(null);

	/**
	 * Obtiene la lista de paises gracias al API datosPaises
	 */
	useEffect(() => {
		let abortController = new CancelRequestController();
		const listaPaises = async () => {
			await DatosPaisesAPI.obtenerLatamUSA({
				abortSignal: abortController.signal,
			})
				.then((res) => {
					setPaises(Array.isArray(res) ? res : []);
				})
				.catch((error) => console.log(error));
		};
		listaPaises();
		return () => {
			abortController.abort();
		};
	}, []);

	/**
	 *Esta función valida que los campos del formulario esten diligenciados. De ser asi envia la información correspondiente haciendo uso del sevicio SolicitudesDemoAPI.solicitudInformacionPlanes.
	 * @param {Event} e
	 */
	const enviarFormulario = async (e) => {
		e.preventDefault();

		validacionEdad();

		if (refFormulario.current.reportValidity()) {
			setPreloader(true);

			let abortController = new CancelRequestController();

			const propiedades = {
				nombreP: refNombre.current.value,
				correoP: refCorreo.current.value,
				telP: refTel.current.value,
				edadP: check ? "Mayor de edad" : "N/A",
				ciudadP: refCiudad.current.value,
				paisP: isOtherCountry
					? `Otro - ${refOtroPais.current.value}`
					: paisSelecccionado?.nombrePais || "",
				codigoPaisP: isOtherCountry ? "CO" : paisSelecccionado?.codigoPais || "",
			};

			await SolicitudesDemoAPI.solicitudInformacionPlanes({
				abortSignal: abortController.signal,
				dataForm: propiedades,
			})
				.then((response) => {
					const respuesta = response;
					const error = respuesta.status === 0;
					const info = respuesta.info;

					let messageModal = {};
					if (!error) {
						messageModal = {
							color: "primary-success",
							icono: "thumbs-up primary-success",
							message:
								textoAyudar?.modal_alerta?.enviado?.mensaje || "Correo enviado",
							description:
								textoAyudar?.modal_alerta?.enviado?.descripcion ||
								"En unos días uno de nuestros especialistas se pondrá en contacto para ayudarte a elegir la mejor opción para tu institución.",
						};

						// history.push('/compra/mensaje');
					} else {
						/* console.log(`info: ${info}`); */

						messageModal = {
							description:
								textoAyudar?.modal_alerta?.registrado?.mensaje ||
								"Si no recibiste la información solicitado escríbenos a contacto@makemake.com.co",
							message:
								textoAyudar?.modal_alerta?.registrado?.titulo ||
								"¡Ups! El correo ya se ha usado  anteriormente",
							color: null,
							icono: null,
						};
					}

					refNombre.current.value = "";
					refCorreo.current.value = "";
					refTel.current.value = "";
					setCheck(false);
					refCiudad.current.value = "";
					if (isOtherCountry === true) {
						refOtroPais.current.value = "";
					}
					refPais.current.value = "";
					setPaisSelecccionado((draft) => {
						draft.nombrePais = "";
						draft.codigoPais = "";
					});
					setOtherCountry(false);

					/* console.log(`messageModal: ${messageModal}`); */

					MicroModal.show("modal-alertas-podemos-ayudar", {
						awaitCloseAnimation: true,
					});
					setRespuestaApi({ ...respuestaApi, ...messageModal });
					setPreloader(false);
				})
				.catch((error) => console.log(error));

			return () => {
				abortController.abort();
			};
		}
	};

	/**
	 * Esta función actualiza el estado isOtherCountry si la selección
	 *  previa del país es "Otro". Esto habilita el campo nombre del país.  También actualiza el valor del país seleccionado
	 */
	const countrySelected = (valor) => {
		let isOther = refPais.current.value === "Otro" ? true : false;
		setOtherCountry(isOther);
		paises.map((pais) => {
			if (pais?.codigo === valor) {
				setPaisSelecccionado((draft) => {
					draft.nombrePais = pais?.nombre;
					draft.codigoPais = pais?.codigo;
				});
			}
		});
	};

	/**
	 * Esta función valida que la edad sea mayor a 18 años.
	 * También actualiza el estado notificationAge para mostrar la
	 * notificación y bloquear el botón de enviar
	 */
	const validacionEdad = () => {
		let isLess = check === false ? true : false;
		setNotificationAge(isLess);
	};

	return (
		<>
			<>
				<div className="onbackground-primary--color  seccion-gris">
					<div className="container-podemos-ayudar row mx-auto col-lg-12 pb-lg-5 px-xl-4 px-3">
						<div className="col-lg-5">
							<p
								tabIndex={0}
								className="focusable-secondary titulo-podemosAyudar my-5"
							>
								{textoAyudar?.intro?.titulo || "¿Quieres ser parte de MakeMake?"}
							</p>
							<p className="my-4">
								{textoAyudar?.intro?.descripcion ||
									"Suscríbete a MakeMake y descubre una nueva forma de llevar los mejores libros a tu comunidad. Contamos con diferentes planes que se adaptan a las características y necesidades de tu institución."}
							</p>
							<p
								dangerouslySetInnerHTML={{
									__html:
										textoAyudar?.intro?.llamanos ||
										"Llámanos, escríbenos o llena este formulario para más información.",
								}}
							></p>
							{state.idioma === "es"
								? (() => {
										switch (state.pais) {
											case "CO":
												return (
													<div className="data-country" id="data-CO">
														<p className="my-4">
															<strong>
																{textoAyudar?.intro?.telefono ||
																	"Tels:"}
															</strong>{" "}
															<a
																className="focusable-secondary"
																href="tel:+573502367037"
															>
																(57) 350 236 7037
															</a>{" "}
															-{" "}
															<a
																className="focusable-secondary"
																href="tel:+573502011041"
															>
																(57) 350 201 1041{" "}
															</a>
														</p>
													</div>
												);
											case "CL":
												return (
													<div className="data-country" id="data-CL">
														<p className="my-4">
															<strong>
																{textoAyudar?.intro?.telefono ||
																	"Tels:"}
															</strong>
															<a
																className="focusable-secondary"
																href="tel:+56994421606"
															>
																(56 9) 94421606
															</a>{" "}
															-{" "}
															<a
																className="focusable-secondary"
																href="tel:+56227957133"
															>
																(56 2) 27957133
															</a>
														</p>
													</div>
												);
											case "EC":
												return (
													<div className="data-country" id="data-EC">
														<p className="my-4">
															<strong>
																{textoAyudar?.intro?.telefono ||
																	"Tels:"}
															</strong>{" "}
															<a
																className="focusable-secondary"
																href="tel:+59325102447"
															>
																+(593 2) 510 2447
															</a>{" "}
															-{" "}
															<a
																className="focusable-secondary"
																href="tel:+56227957133"
															>
																+(593 9) 93586637
															</a>
														</p>
													</div>
												);
											case "US":
												return (
													<div className="data-country" id="data-US">
														<p className="my-4">
															<strong>
																{textoAyudar?.intro?.telefono ||
																	"Tels:"}
															</strong>{" "}
															<a
																className="focusable-secondary"
																href="tel:+12015592200"
															>
																+1 (201) 559-2200
															</a>{" "}
															-{" "}
															<a
																className="focusable-secondary"
																href="tel:+18003455946"
															>
																+1 (800) 345-5946
															</a>
														</p>
													</div>
												);
											default:
										}
								  })()
								: (() => {
										return (
											<div className="data-country" id="data-CO">
												<p className="my-4">
													<strong>
														{textoAyudar?.intro?.telefono || "Tels:"}
													</strong>{" "}
													<a
														className="focusable-secondary"
														href="tel:+18003455946"
													>
														+1 (800) 345-5946
													</a>
												</p>
											</div>
										);
								  })()}

							{state.idioma === "es" ? (
								<>
									<p className="text-break">
										<strong>{textoAyudar?.intro?.correo || "Email:"}</strong>{" "}
										<a
											className="focusable-secondary"
											href="mailto:contacto@makemake.com.co"
										>
											contacto@makemake.com.co
										</a>
									</p>
								</>
							) : (
								<>
									<p className="text-break">
										<strong>{textoAyudar?.intro?.correo || "Email:"}</strong>{" "}
										<a
											className="focusable-secondary"
											href="mailto:contacto@makemake.com.co"
										>
											contacto@makemake.com.co
										</a>
										<br />
										<a
											className="focusable-secondary"
											href="mailto:acorrea@lectorum.com"
										>
											acorrea@lectorum.com
										</a>
									</p>
								</>
							)}

							{/* <Link to="/tienda/institucional" className="link-agendar secondary-inverse--color font-weight-bold my-3 d-block" >{textoAyudar?.intro?.cita_ya || '¡O agenda tu cita ya mismo acá! '}</Link> */}

							{state.idioma === "es" ? (
								<>
									<img
										src="../../img/podemosAyudar.png"
										alt="imagen podemos ayudar"
										className="d-block mx-auto ml-lg-5"
									/>
								</>
							) : (
								<>
									<img
										src="../../img/canwehelp.png"
										alt="imagen podemos ayudar"
										className="d-block imagenAyudar mx-auto ml-lg-0 mt-5"
									/>
								</>
							)}
						</div>
						<div className="col-lg-7 pt-3 pt-md-5 pt-lg-0 d-flex  align-items-center pr-0">
							<form
								className="mx-auto"
								ref={refFormulario}
								action={`${GENERAL_URL_API}/assets/enviarCorreoDemoNuevo`}
								method="POST"
							>
								<div className="pt-lg-5">
									<div className="d-flex flex-wrap">
										<div className="mb-4 campos-formulario">
											<p className="secondary-item label">
												<strong>
													{textoAyudar?.nombre?.label ||
														"Nombre completo*:"}
												</strong>
											</p>
											<label htmlFor="inputName" className="sr-only">
												{textoAyudar?.nombre?.label || "Nombre completo*:"}
											</label>
											<input
												ref={refNombre}
												type="text"
												className="focusable-secondary form-control"
												id="inputName"
												placeholder={
													textoAyudar?.nombre?.placeholder ||
													"Escribe tu nombre y apellido aquí"
												}
												name="nombreP"
												required
											/>
										</div>

										<div className="mb-4 campos-formulario">
											<p className="secondary-item label">
												<strong>
													{textoAyudar?.correo?.primer_correo ||
														"Correo electrónico*:"}
												</strong>
											</p>
											<label htmlFor="inputEmail" className="sr-only">
												{textoAyudar?.correo?.primer_correo ||
													"Correo electrónico*:"}
											</label>
											<input
												ref={refCorreo}
												type="email"
												className={`focusable-secondary form-control `}
												id="inputEmail"
												placeholder={
													textoAyudar?.correo?.placeholder ||
													"tucorreo@correo.com"
												}
												name="correoP"
												required
											/>
										</div>

										<div className="mb-4 campos-formulario">
											<p className="secondary-item label">
												<strong>
													{textoAyudar?.telefono?.label || "Télefono*:"}
												</strong>
											</p>
											<label htmlFor="ConfirmTelefono" className="sr-only">
												{textoAyudar?.telefono?.label || "Télefono*:"}
											</label>
											<input
												ref={refTel}
												type="phone"
												className={`focusable-secondary form-control`}
												id="ConfirmTelefono"
												placeholder={"+57 300-000-0000"}
												name="telefono"
												required
											/>
										</div>

										<div className="mb-4 campos-formulario">
											<p className="secondary-item label">
												<strong>
													{textoAyudar?.pais?.label || "País:"}
												</strong>
											</p>
											<label htmlFor="inputPais" className="sr-only">
												{textoAyudar?.pais?.label || "País*:"}
											</label>
											<select
												ref={refPais}
												className="focusable-secondary form-control"
												id="inputPais"
												name="seleccionarPais"
												required
												onChange={(e) => {
													countrySelected(e.currentTarget.value);
												}}
											>
												<option value="">
													{textoAyudar?.pais?.placeholder ||
														"Selecciona tu país"}
												</option>
												{paises.length !== 0 &&
													paises.map((pais) => (
														<option
															key={pais.codigo}
															value={pais.codigo}
														>
															{pais.nombre}
														</option>
													))}
												<option value="Otro">
													{textoAyudar?.pais?.otro || "Otro"}
												</option>
											</select>
										</div>

										{isOtherCountry && (
											<div className="mb-4 campos-formulario">
												<p className="secondary-item label">
													<strong>
														{textoAyudar?.otro_pais?.label ||
															"Nombre del país:"}
													</strong>
												</p>
												<label htmlFor="inputOtroPais" className="sr-only">
													{textoAyudar?.otro_pais?.label ||
														"Nombre del país"}
												</label>
												<input
													ref={refOtroPais}
													type="text"
													className={"focusable-secondary form-control"}
													id="inputOtroPais"
													placeholder={
														textoAyudar?.otro_pais?.placeholder ||
														"Escribe el nombre de tu país"
													}
													name="inputOtroPais"
													required=""
												/>
											</div>
										)}
										<div className="mb-4 campos-formulario">
											<p className="secondary-item label">
												<strong>
													{textoAyudar?.ciudad?.label || "Ciudad:"}
												</strong>
											</p>
											<label htmlFor="inputCiudad" className="sr-only">
												{textoAyudar?.ciudad?.label || "Ciudad:"}
											</label>
											<input
												ref={refCiudad}
												type="text"
												className="focusable-secondary form-control"
												id="inputCiudad"
												placeholder={
													textoAyudar?.ciudad?.placeholder ||
													"Escribe tu ciudad"
												}
												name="ciudad"
											/>
										</div>
									</div>

									<div className="px-3">
										<label
											className="d-flex align-items-center w-100 focusable-red pointer"
											tabIndex={0}
											role="checkbox"
											checked={check}
											aria-checked={check}
											onKeyDown={(event) => {
												if (event.key === "Enter") {
													setCheck(!check);
												}
											}}
										>
											<input
												type="checkbox"
												className="modal-checkbox"
												ref={refEdad}
												checked={check}
												onChange={(e) => {
													setNotificationAge(false);
													setCheck(e?.target.checked);
												}}
												required
											/>
											<i className="check"></i>
											<p className="ml-2 onbackground-primary--color">
												{textoAyudar?.edad?.placeholder ||
													"Soy mayor de edad"}
											</p>
										</label>
									</div>

									<p>
										{notificationAge && (
											<span className="validacion-campo-error ml-3">
												{textoAyudar?.edad?.tooltip ||
													"Es necesario que seas mayor de edad"}
											</span>
										)}
									</p>

									<div className="d-flex flex-wrap justify-content-between align-items-center px-3 pt-3 pb-5 py-lg-5">
										<div
											className="focusable-secondary col-md-9 px-0"
											tabIndex={0}
											role={"region"}
											aria-label={`${
												textoAyudar?.aceptar_terminos?.terminos ||
												"Términos y condiciones"
											}, ${
												textoAyudar?.aceptar_terminos?.politica ||
												"Política de privacidad"
											}`}
										>
											<p className="mm-redimir-terms">
												{textoAyudar?.aceptar_terminos?.ingresar_datos ||
													"Al ingresar tus datos estás aceptando"}{" "}
												<Link
													className="focusable-secondary links-tyc secondary-item"
													to="/terminos-y-condiciones"
													target="_blank"
												>
													{textoAyudar?.aceptar_terminos?.terminos ||
														"Términos y condiciones"}
												</Link>{" "}
												{textoAyudar?.aceptar_terminos?.y || "y"}{" "}
												<Link
													to="/politica-de-privacidad"
													className="focusable-secondary links-tyc secondary-item"
													target="_blank"
												>
													{textoAyudar?.aceptar_terminos?.politica ||
														"Política de privacidad"}
												</Link>
											</p>
										</div>
										<button
											className="focusable-primary btn btn-primary col-auto d-block px-4 py-2 mt-3 mt-md-0"
											onClick={enviarFormulario}
											type="submit"
											disabled={notificationAge}
										>
											{preloader ? (
												<i className="fas fa-spinner fa-pulse"></i>
											) : (
												textoAyudar?.enviar?.boton || "Enviar"
											)}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<ModalAlertas
					id="modal-alertas-podemos-ayudar"
					description={respuestaApi.description}
					message={respuestaApi.message}
					color={respuestaApi.color}
					icono={respuestaApi.icono}
					boton={textoAyudar?.modal_alerta?.boton || "Aceptar"}
				/>
			</>
		</>
	);
};
export default PodemosAyudar;
