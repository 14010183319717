import React, { useEffect, useState } from "react";
import { ASSETS_URL } from "../../../components/Store.js";
import { FormPublicEvent } from "./formPublicEvent.js";
import { ViewEvent } from "../ViewEvent";
import { EventFlag } from "../event-flag.js";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants.js";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country.js";
import { useTranslations } from "../../../hooks/use-translations.js";

/**
 * Componente del formulario público. Su principal función es mostrar el formulario o la pantalla de ver evento
 * @param {object} props contiene los datos del formulario
 * @returns {JSX.Element} PublicEvent
 */
export const PublicEvent = ({ eventData }) => {
    const [eventStatus, setEventStatus] = useState(0);
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textosInterfaz = useTranslations({ section: "eventos", lang: lang, array: false });

    useEffect(() => {
        let status = eventData?.estado || 0;
        setEventStatus(status)
    }, [eventData])

    return (
        <>
            <div
                className="d-none d-lg-block event-banner"
                style={{ backgroundColor: eventData?.color?.fondo || "var(--secondary-color)" }}
            >
                <div
                    className="container"
                    style={{ backgroundImage: `url(${ASSETS_URL}${eventData?.imagenUrl || '/img/no-image-3.png'})` }}
                ></div>
            </div>

            <div className="event-back">
                <div className="container py-5">
                    <div className="event-form">
                        <EventFlag tipoEvento={eventData?.tipoEvento} textosInterfaz={textosInterfaz}/>
                        {
                            eventStatus === 1 ?
                                <FormPublicEvent eventData={eventData}  textosInterfaz={textosInterfaz} lang={lang}/>
                                :
                                <ViewEvent dataViewEvent={eventData}  textosInterfaz={textosInterfaz}/>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}