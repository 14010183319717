import { useContext, useEffect, useState, useRef } from "react";
import { store } from "./Store";
import { NetworkAPI } from "../services/api/network";
import { useLocation } from "react-router-dom";
import { CancelRequestController } from "../util/cancel-request-controller";

let abortController = null;
const TIMEOUT = 60 * 2 * 1000;
const DEBOUNCE = 1000;
const CHECK_INTERVAL = 10 * 1000;

const fetchWithTimeout = (timeout = 5000, abortSignal = null) =>
	Promise.race([
		NetworkAPI.checkNetworkStatus({ abortSignal }),
		new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout")), timeout)),
	]);

export const NetworkStatusDetector = ({ children }) => {
	const { dispatch } = useContext(store);
	const [online, setOnline] = useState(undefined);
	const location = useLocation();
	const debounceTimeout = useRef(null);

	const detectNetwork = async ({ abortSignal }) => {
		try {
			const response = await fetchWithTimeout(TIMEOUT, abortSignal);
			if (response) {
				console.log("Conexión activa");
				setOnline(true);
			} else {
				console.log("Sin conexión", response);
				setOnline(false);
			}
		} catch (error) {
			console.error("Error en la verificación de conexión:", error);
			const isCanceled = error?.message === "canceled";
			if (!isCanceled) {
				setOnline(false);
			}
		}
	};

	useEffect(() => {
		dispatch({
			type: "CHANGE_VALUE",
			property: "isOnline",
			value: online,
		});
	}, [online, dispatch]);

	useEffect(() => {
		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}

		debounceTimeout.current = setTimeout(() => {
			abortController = new CancelRequestController();
			detectNetwork({ abortSignal: abortController.signal });
		}, DEBOUNCE);

		const interval = setInterval(() => {
			detectNetwork({ abortSignal: abortController.signal });
		}, CHECK_INTERVAL);

		return () => {
			clearInterval(interval);
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
			if (abortController) {
				abortController.abort();
			}
		};
	}, [location.pathname]);

	return children;
};
