import React, { useEffect, useState, useContext } from "react";
import { Icon } from "@iconify/react";
import _ from "lodash";

import { PLANES_DATA } from "../data/planes";

import { useEpayco } from "../../../hooks/useEpayco";
import { useQueryParams } from "../../../hooks/useQueryParams";
import useObtenerSubsecciones from "../../../js/translations/useObtenerSubsecciones";

import { store } from "../../../components/Store";
import { Breadcrumbs } from "../../../components/shared/breadcrums";
import { Accordion } from "../../../components/shared/accordion";
import { BarraCompra } from "../components/barra-compra";
import ReactPixel from 'react-facebook-pixel';


/**
 * Este componente renderiza la pantalla de mi colección en la tienda. 
 * Su principal función es identificar el plan, es decir si es mensual o trimestral,  
 * para mostrar la información correspondiente.
 * @returns PlanMiColeccion
 */
export const PlanMiColeccion = ({ }) => {
    const { isReady, textosSubSecciones: textos } = useObtenerSubsecciones('tienda_makemake');
    const params = useQueryParams();
    const [mostrarContenido, setMostrarContenido] = useState();
    const [tipoColeccion, setTipoColeccion] = useState();
    const coleccionMensual = { ...PLANES_DATA.mensual.find(plan => plan.id === 'mi-coleccion-mensual') };
    const coleccionTrimestral = { ...PLANES_DATA.trimestral.find(plan => plan.id === 'mi-coleccion-trimestral') };
    const epayco = useEpayco();

    const globalState = useContext(store);
    const country = globalState?.state?.pais;
    const moneda = country !== 'CO' ? 'USD' : 'COP';

    /**
     * Según el parametro 'Tipo' del path de la Url, se carga la información y el contenido  correspondiente
     */
    useEffect(() => {
        const tipo = params.get('tipo');
        setTipoColeccion(tipo)
        const cargarContenido = !!tipo ? tipo === 'mensual' ? coleccionMensual : coleccionTrimestral : coleccionMensual;
        if (isReady) {
            setMostrarContenido(contenidoColeccion(cargarContenido, textos))
        }
    }, [params, isReady, textos])


    /**
     * Esta función ernderiza el contenido según el tiempo elegido, es decir mensual o trimestral
     * @param {object} coleccion - hace referencia al objeto que contiene la información de colección
     * @param {object} textos - objeto de los textos de interfaz
     * @returns contenidoColeccion
     */
    const contenidoColeccion = (coleccion, textos) => {
        const listaIdeal = coleccion?.idealPara || [];
        let loading = false;

        const QuestionAccordion = () => {
            return <Accordion.Container>
                {
                    coleccion.preguntasFrecuentes.map((item, i) => {
                        return (
                            <Accordion.Item key={i} itemId={`item-${i}`}>
                                <Accordion.ItemButton label={_.get(textos, item?.pregunta?.translationPath) || item?.pregunta?.default || 'N/A'} />
                                <Accordion.Content>
                                    <p className="onbackground-primary--color">
                                        {
                                            item?.respuesta?.html ?
                                                <span dangerouslySetInnerHTML={{ __html: _.get(textos, item?.respuesta?.translationPath) || item?.respuesta?.default || 'N/A' }}></span>
                                                :
                                                <>{_.get(textos, item?.respuesta?.translationPath) || item?.respuesta?.default || 'N/A'}</>
                                        }
                                    </p>
                                </Accordion.Content>
                            </Accordion.Item>

                        )
                    })
                }
            </Accordion.Container>
        }

        return (
            <>
                <div className="plan-section-layout mi-coleccion">
                    <div className="d-lg-flex justify-content-between">
                        <div className="sidenav " >
                            <h1 className="title">
                                {_.get(textos, coleccion?.nombre?.translationPath) || coleccion?.nombre?.default || 'N/A'}
                            </h1>
                            <div className="details">
                                {
                                    coleccion?.detalles?.map((detalle, i) => {
                                        return <p className="detail" key={i}>
                                            <Icon className="icon" icon="mdi:check-bold" />
                                            {
                                                detalle?.html ?
                                                    <span dangerouslySetInnerHTML={{ __html: _.get(textos, detalle?.translationPath) || detalle?.default || 'N/A' }}></span>
                                                    :
                                                    <>{_.get(textos, detalle?.translationPath) || detalle?.default || 'N/A'}</>
                                            }
                                        </p>
                                    })
                                }
                            </div>
                            <div className="desktop-accordion">
                                {QuestionAccordion()}
                            </div>
                        </div>
                        <div className="content_layout">
                            <div className="collection-card d-xl-flex">
                                <div className="blue-plan img-coleccion col-xl-7 px-0 order-lg-2">
                                        <div
                                            className="img"
                                            style={{ backgroundImage: `url(${coleccion?.imgUrl?.normal || '/img/no-imagen3.png'}) ` }}></div>
                                    </div>
                                <div className="col-xl-5 p-0 order-lg-1">
                                    <div className="text-background py-3">
                                        <h2 className="mx-auto text-center "> {_.get(textos, coleccion?.nombre?.translationPath) || coleccion?.nombre?.default || 'N/A'}</h2>
                                    </div>
                                    <div className="p-3">
                                        <h2 className="p-3 onprimary--color">{_.get(textos, coleccion?.descripcionFicha?.translationPath) || coleccion?.descripcionFicha?.default || 'N/A'}</h2>
                                        <p className="p-3">{textos?.individual?.interfaz?.ideal}</p>
                                        <ul className="p-3">
                                            {listaIdeal.map((item, i) => {
                                                return (
                                                    <li key={`list-${i}`}>{_.get(textos, item?.translationPath) || item?.default || 'N/A'}</li>
                                                )
                                            })}
                                        </ul>
                                        {coleccion?.extraInformation?.activado &&
                                            <>
                                                <div className="p-3">
                                                    <p
                                                        className="red-inverse--color font-weight-bold"
                                                        dangerouslySetInnerHTML={{ __html: _.get(textos, coleccion?.extraInformation?.titulo?.translationPath) || coleccion?.extraInformation?.titulo?.default || 'N/A' }}
                                                    ></p>

                                                    <ul className="features-list pt-3">
                                                        {coleccion.extraInformation.detalles.map((element, i) => {
                                                            return (
                                                                <li key={`element-${i}`} dangerouslySetInnerHTML={{ __html: _.get(textos, element?.translationPath) || element?.default || 'N/A' }}></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>


                                            </>

                                        }
                                        <div className="price-collection">
                                            <BarraCompra
                                                enableBuyButton={true}
                                                stylePrice={"price-info-new"}
                                                offer={coleccion?.descuento}
                                                priceText={coleccion?.precio}
                                                onClick={() => {
                                                    ReactPixel.trackCustom('CompraPlan', {name:coleccion?.nombre?.default});
                                                    epayco.buy(coleccion?.epayco);
                                                }} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mobile-accordion">
                            {QuestionAccordion()}
                        </div>
                    </div>

                    {/*<BarraCompra
                        enableBuyButton={true}
                        stylePrice={"price-info-new"}
                        priceText={coleccion?.precio}
                        onClick={() => epayco.buy(coleccion?.epayco)} />*/}
                </div>

            </>
        )
    }

    return (
        <section>
            <Breadcrumbs.Box className={'plCasa-breadcrums  p-3'}>
                <Breadcrumbs.Crumb
                    className={`crumb una-linea-texto`}
                    activeClassName={'current'}
                    name={textos?.individual?.mi_coleccion?.breadcrumb_1 || 'Leer en MakeMake'}
                    url={`/tienda/plan-casa?tipo=${tipoColeccion}`} />
                <Breadcrumbs.Crumb
                    className={`crumb una-linea-texto`}
                    activeClassName={'current'}
                    name={textos?.individual?.mi_coleccion?.breadcrumb_2 || 'Mi colección'}
                    url={'/tienda/plan-casa/mi-coleccion'}
                    last={true} />
            </Breadcrumbs.Box>

            {mostrarContenido}
        </section>
    )
}
