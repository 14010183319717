import React, { useEffect, useState } from "react";

/**
 * Hook personalizado que detecta si se ha hecho clic fuera de un elemento especificado por su referencia.
 * @param {Object} props - Propiedades del hook.
 * @param {React.RefObject} props.ref - Referencia al elemento que se desea monitorizar.
 * @returns {{clickedOutside:boolean}} Objeto con el estado de `clickedOutside`.
 * @property {boolean} clickedOutside - Indica si se ha hecho clic fuera del elemento referenciado.
 */
export const useElementClickListener = ({ ref }) => {
	const [clickedOutside, setClickedOutside] = useState(false);
	useEffect(() => {
		let timer = null;
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				timer = setTimeout(() => {
					setClickedOutside(true);
				}, 70);
			} else {
				setClickedOutside(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			clearTimeout(timer);
		};
	}, [ref]);
	return {
		clickedOutside,
	};
};