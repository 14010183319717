import { useContext, useEffect, useState } from "react";
import { Workbox, messageSW } from "workbox-window";
import { PWAconsole } from "../util/service-worker-messges";
import { ModoPWAContext } from "../contexts/ModoPwaContext";

/**
 * Hook personalizado para gestionar el Service Worker en una aplicación Progressive Web App (PWA).
 *
 * @returns {Object} - Objeto que contiene funciones y datos relacionados con el Service Worker.
 * @property {Function} register - Función para registrar el Service Worker.
 * @property {Function} clearRegistrations - Función para desregistrar todos los Service Workers existentes.
 * @property {Object} registration - Objeto que representa el registro del Service Worker.
 * @property {Function} handleSkipWaiting - Función para manejar la omisión de la espera del nuevo Service Worker.
 * @property {Object} wb - Objeto Workbox que proporciona funciones para interactuar con el Service Worker.
 */
export const useServiceWorkerManager = () => {
  const pwaContext = useContext(ModoPWAContext);
  const [registration, setRegistration] = useState(null);
  const [wb, setWb] = useState(null);

  /**
   * Función para registrar el Service Worker.
   * @async
   */
  const register = async () => {
    try {
      if ('serviceWorker' in navigator) {
        const wb = new Workbox('/sw.js');

    
        wb.addEventListener('controlling', () => {
          setTimeout(() => {
            pwaContext.dispatch({
              type: "CHANGE_VALUE",
              payload: {
                property: "updateStatus",
                value: 'FINISHED',
              },
            });
          }, 3000);
  
          setTimeout(() => {
            window.location.reload();
          }, 6000);
        });

 // Escuchar mensajes del Service Worker
 navigator.serviceWorker.addEventListener('message', (event) => {
  if (event.data === 'SW_UPDATING') {
    pwaContext.dispatch({
      type: "CHANGE_VALUE",
      payload: {
        property: "updateStatus",
        value: 'UPDATING',
      },
    });
  }
});

        let registrationVar;
        wb.register().then(registration => {
          PWAconsole.info('registration successfully!');
          setRegistration(registration);
          registrationVar = registration;
        });

        setWb(wb);
      }
    } catch (error) {
      console.error('Error during Service Worker registration:', error);
    }
  };

  /**
   * Función para desregistrar todos los Service Workers existentes.
   * @async
   */
  const clearRegistrations = async () => {
    try {
      if ('serviceWorker' in navigator) {
        await navigator.serviceWorker.getRegistrations().then(registrations => {
          registrations.forEach(registration => {
            registration.unregister();
          });
        });

        PWAconsole.info('All Service Workers unregistered.');
      }
    } catch (error) {
      console.error('Error during Service Worker unregistration:', error);
    }
  };

  /**
   * Maneja el evento de Service Worker activado y listo para su uso.
   */
  const handleReadyAndActivated = () => {
    navigator.serviceWorker.ready.then((registration) => {
      PWAconsole.info(`A service worker is active and ready to use`);
      pwaContext.dispatch({
        type: "CHANGE_VALUE",
        payload: {
          property: "serviceWorkerActivado",
          value: true,
        },
      });
      pwaContext.dispatch({
        type: "CHANGE_VALUE",
        payload: {
          property: "serviceWorkerInstalado",
          value: true,
        },
      });
    });

    wb.addEventListener("activated", (event) => {
      pwaContext.dispatch({
        type: "CHANGE_VALUE",
        payload: {
          property: "serviceWorkerActivado",
          value: true,
        },
      });
    });
  };

  /**
   * Muestra la versión del Service Worker.
   */
  const showSWversion = () => {
    wb.messageSW({ type: "GET_VERSION" }).then((swVersion) => {
      PWAconsole.info("Service Worker version:", swVersion);
    });
  };

  /**
   * Limpia la caché del Service Worker.
   * @async
   */
  const clearCache = async () => {
    return await caches
      .keys()
      .then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      })
      .then(() => {
        PWAconsole.info("Cache was cleared!");
      })
      .catch((err) => PWAconsole.log(err));
  };

  /**
   * Maneja la omisión de la espera del nuevo Service Worker.
   * @async
   */
  const handleSkipWaiting = async () => {
    if (registration && registration.waiting) {
      await clearRegistrations();
      await clearCache().then(res => {
        messageSW(registration.waiting, { type: "SKIP_WAITING" });
        PWAconsole.log(`Skipping`);
        window.location.reload();
      })
        .catch(error => PWAconsole.error(error))
    }
  };

  useEffect(() => {
    if (wb !== null) {
      showSWversion();
      handleReadyAndActivated();
    }
  }, [wb]);



  return {
    register,
    clearRegistrations,
    registration,
    handleSkipWaiting,
    wb,
  };
};
