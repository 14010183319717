import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonInstruccion = () => {
    return (
        <SkeletonTheme color="#d8d3d3" highlightColor='#f0f0f0'>
            <div className="highlight-container demo--instruction-2 py-4 ">
                <div className="row mx-0 py-3">
                    <div className="col-lg-8 mx-auto">
                        <div className="align-items-center justify-content-between flex-column">
                            <div >
                                <Skeleton height={39} className="col-8 col-lg-6 d-block mx-auto mb-3" />
                                <Skeleton height={39} className=" col-lg-7 d-block mx-auto mb-3" />
                            </div>
                            <Skeleton className="col-6 col-lg-3 py-2 d-block mx-auto" height={49} />
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

export default SkeletonInstruccion;