import React from "react";
import { useHistory } from "react-router-dom";
import { BaseModal } from "../modals/base-modal";
import { Icon } from "@iconify/react";
/**
Componente Modal el cual le dice al usuario que debe iniciar sesión para poder ver o inscribirse al evento.
@param {object} props - Propiedades del componente
@param {string} props.visible - Indica si se debe mostrar o no el modal
@param {function} props.setVisible - Función la cual actualiza el estado de visibilidad
@param {string} props.redirectionParam - link a donde debe redirigir (dentro de makemake) luego de iniciar sesión.
@param {string} props.title - titulo del modal
@param {string} props.description - descripción del modal.
@param {string} props.buttonText - texto del botón que lleva a la página de login.
@returns {JSX.Element} Elemento JSX que representa el componente modal
*/
export const CommunitySignInRequestModal = ({ visible, setVisible, redirectionParam, title, description, buttonText }) => {
	const history = useHistory();

	return (
		<BaseModal
			windowClassName={"default-modal-window col-10 col-sm-9 col-md-6 col-lg-6 col-xl-3"}
			visible={visible}
			setVisible={setVisible}
			backdropClose={true}
		>
			                        <div className="d-flex flex-row-reverse">
                            <button
                                aria-label="Cerrar ventana de dialogo"
                                className="close-button onbackground-primary--color focusable-red"
                                data-micromodal-close
                                onClick={() => setVisible&&setVisible(false)}
                            >
                                <Icon icon={'mdi:close-thick'} width={35} />
                            </button>
                        </div>
			<div className="modal__content my-2" id="dialogo-modal-alertas">
				<p
					className="second-category-item no-lineheight text-center mb-3 secondary-inverse--color"
					id="alert-message"
				>
					{title}
				</p>
				<p className="mb-3 text-center onbackground-third--color" id="alert-description">
					{description}
				</p>
			</div>

			<button
				className="btn btn-primary d-block mx-auto focusable-red"
				onClick={() => {
					history.push({pathname:'/login', search:`?redirectto=${encodeURIComponent(redirectionParam || '/') }`});
                    setVisible&&setVisible(false);
				}}
			>
				{buttonText}
			</button>
		</BaseModal>
	);
};
