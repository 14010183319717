import React from "react";

/**
 * Elemento contenedor de las opciones del submenu de navegación
 * @param {any} children 
 * @returns {JSX.Element} tabpanel
 */
const tabpanel = ({ children }) => {
    return (
        <div className={'plCasa-tabpanel py-3'}>
            <div className="container">
                <ul className="w-100  d-sm-flex">
                    {children}
                </ul>
            </div>
        </div>
    )
}

/**
 * opción del submenu de navegación
 * @param {string} title - texto del botón
 * @param {Function} onClick - funciónd el botón
 * @param {boolean} active - indica si el botón esta activo
 * @returns {JSX.Element} tab
 */
const tab = ({ title, onClick, active }) => {
    return (
        <>
            {
                title !== undefined &&

                <li>
                    <button
                        className={` ${active ? "current" : ""} focusable-red tab mr-sm-4 d-block  mx-auto `}
                        onClick={() => onClick && onClick()}
                    >
                        {title}
                    </button>
                </li>
            }
        </>
    )
}

/**
 * Objeto que retorna los componentes que conforman el subMenu de opciones en PLAN CASA
 */
export const PlanCasaNav = {
    tabpanel,
    tab
};
