import React, { useEffect, useState} from "react";
import PropTypes from 'prop-types';
/**
 * Botón que actua como un switch permitiendo activarse o desactivarse.
 */
export const SwitchButton = ({ name, onChange, checked, disabled }) => {
    const [_checked, setChecked] = useState(false);

    const handleState = (checked)=>{
        setChecked(checked)
        onChange&&onChange({checked:checked})
    }
    useEffect(() => {
        setChecked(checked ? true : false);
    }, [checked])


    return (<button 
        className={`focusable-primary switch-button ${_checked?'checked':''}`}
        aria-label={`${name}`} 
        role="switch" 
        aria-checked={_checked} 
        onClick={() => handleState(!_checked)} 
        disabled={disabled}>
    </button>)
} 

SwitchButton.propTypes = {
    /**
     * nombre que tendrá el switch, es necesario para accesibilidad.
     */
    name:PropTypes.string,
    /**
     * función que es llamada cuando se actualiza el estado del botón, tiene como parametro un objeto con la propiedad 'checked' que indica si está activo o no.
     */
    onChange:PropTypes.func,
    /**
     * Propiedad booleana que indica si el componente debe marcarse por defecto como activo o no.
     */
    checked:PropTypes.bool,
    /**
     * Propiedad booleana que indica si el componente estará deshabilitado o no.
     */
    disabled:PropTypes.bool
}