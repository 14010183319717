import React from "react";
import { Img } from "../../components/shared/img";

/**
 * Retorna el texto introductorio al home sin login
 * @param {object} texts contiene los textos de interfaz
 * @returns {JSX.Element} IntroHome
 */
export const IntroHome = ({texts}) => {
    return (
        <section className="container">
            <div className="row px-0 ">
                <div className="col-lg-5 secondary-font onbackground-primary--color font-weight-bold py-4 d-flex align-items-center">
                    <div>
                        <p className="text-24">
                            <span className="secondary-inverse--color">{texts?.descripcion?.linea1 || '¡Libros de verdad!'}</span> {' '}
                            {texts?.descripcion?.linea2 || 'El 99% de la colección de MakeMake proviene de editoriales impresas.'}
                        </p>
                        <p className="mt-3">{texts?.parrafo || 'En MakeMake te ofrecemos una colección de ebooks narrativos e informativos para niños y jóvenes de altísima calidad.'}</p>
                    </div>

                </div>
                <div className="col-lg-7">
                    <div className="img-home-default">
                        <Img src={"/img/home/blop-matilda.png"} className="w-100" />
                    </div>
                </div>
            </div>
        </section>
    )
}