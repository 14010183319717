import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SkeletonLibrosHome from "./SkeletonHomeGeneral/SkeletonLibrosHome";
import SkeletonColeccion from "./SkeletonHomeGeneral/SkeletonColeccion";

export const SkeletonHomeDefault = () => {
    return (
        <SkeletonTheme color="#d8d3d3" highlightColor="#e8e8e8">
            <div className={`titlePage`} style={{ backgroundColor: "#6666CC" }}>
                <div className="container">
                    <div className="col-lg-5 col-xl-6">
                        <Skeleton height={65} maxWidth={435} />
                    </div>
                </div>
            </div>

            <section className="container">
                <div className="row px-0 ">
                    <div className="col-lg-5 secondary-font onbackground-primary--color font-weight-bold py-4 d-flex align-items-center">
                        <div className="col">
                            <Skeleton height={230} maxWidth={445} />
                        </div>

                    </div>
                    <div className="col-lg-7">
                        <Skeleton height={300} maxWidth={445} />
                    </div>
                </div>
            </section>


            <section>
                <div className="highlight-container py-4 home-row-yellow ">
                    <h3 className="pl-3 pl-lg-5 mb-4">
                        <Skeleton height={50} width={300} />
                    </h3>

                    <SkeletonLibrosHome seccion="main-covers" />
                </div>
            </section>

            <section className="d-flex col-md-10 mx-auto  mt-5 mt-lg-0 pb-3 py-lg-5 flex-wrap contenedor-caracteristicas justify-content-around">
                <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0 align-items-center">
                    <Skeleton height={100} width={100} />
                    <Skeleton height={35} width={100} />
                </div>
                <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0 align-items-center">
                    <Skeleton height={100} width={100} />
                    <Skeleton height={35} width={100} />
                </div>
                <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0 align-items-center">
                    <Skeleton height={100} width={100} />
                    <Skeleton height={35} width={100} />
                </div>
                <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0 align-items-center">
                    <Skeleton height={100} width={100} />
                    <Skeleton height={35} width={100} />
                </div>
            </section>

            <div className="home-row-purple py-5">
                <h3 className="pl-3 pl-lg-5 mb-4">
                    <Skeleton height={50} width={300} />
                </h3>
                <SkeletonColeccion />
            </div>

        </SkeletonTheme >

    )
}