
import React, { useRef, useEffect } from "react";

import { useQueryParams } from "../../hooks/useQueryParams";
import useIsMobile from "../../js/utilities/useIsMobile";

import { Hero } from "./components/hero"
import { Clients } from "./components/clients"
import { Community } from "./components/community"
import { OurPublishers } from "./components/our-publishers"
import { MainPlans } from "./components/main-plans"
import { DemoRequest } from "./components/demo-request"
import { Newsletter } from "./components/newsletter"
import { BooksPreview } from "./components/books-preview"
import { MorePlans } from "./components/more-plans"
import { Attributes } from "./components/attributes"
import { Awards } from "./components/awards"
import { StaticBagde } from "../components/badge"

export const Home = () => {
    const params = useQueryParams();
    const isMobile = useIsMobile();

    const refMainPlans = useRef(null);

    const SECCIONES_HOME = [
        {
            id: "MainPlans",
            ref: refMainPlans,
        }
    ];

    useEffect(() => {
        /**
            dependiendo de la ruta del navegador si coincide con alguna url 
            de las secciones, entonces muestra la sección respectiva
        */
        SECCIONES_HOME.map(item => {
            const section = params?.get("section");

            if (section === item.id) {
                const element = item?.ref.current;
                
                window.scrollTo({
                    top: isMobile ? element?.offsetTop + 100 : element?.offsetTop + 300,
                    behavior: "smooth",
                });
            }
        })
    }, [params]);

    return (<div className="que-es min-vh-100">
        <StaticBagde />
        <Hero/>
        <Clients />
        <Community/>
        <Attributes />
        <Newsletter />
        <MainPlans ref={refMainPlans}/>
        <BooksPreview />
        <OurPublishers/>
        <Awards />
        <DemoRequest />
        <MorePlans/>
    </div>)
}