import React, { useState, useContext } from "react";
import { useParams, Link } from 'react-router-dom';
import { Icon } from "@iconify/react/dist/iconify.js";

import { BannerDemo } from "./components/BannerDemo";
import { ASSETS_URL } from "../../components/Store";
import { Img } from "../../components/shared/img";
import { store } from "../../components/Store";
import { SolicitudDemo } from "../../components/solicitud-demo";
import { RecommendedBooks } from "./components/RecommendedBooks";

import { useContentBlog } from "./hooks/use-content-blog";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { Helmet } from "react-helmet";

/**
 * Componente que renderiza la entrada del blog.
 * @returns {JSX.Element} BlogPost
 */
export const BlogPost = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosBlog = useTranslations({ section: "blog", lang: lang, array: false });

    const params = useParams();
    const { id } = params;

    const infoBlog = useContentBlog({ id });
    const { loading, info, content } = infoBlog;
    const [visible, setVisible] = useState(false);

    const globalState = useContext(store);
    const isLogged = globalState.state.sesionActiva !== null && globalState.state.sesionActiva === "SI";


    /**
   * Función que retorna la lista de tags relacionados a la entrada
   * @returns {JSX.Element} lista de tags
   */
    const Tags = () => {
        let tags = [];
        Object.keys(info).map(item => {
            if (item.startsWith('tag')) {
                if (info[item] !== '') tags.push(info[item])
            }
        })
        return (
            <>
                {tags.map((item, index) =>
                    <Link
                        to={`/blog/?tags=${encodeURIComponent(item)}`}
                        key={`tag-${index}`}
                        className="focusable-secondary btn-blog-tag text-14 mr-2"
                    >{item}</Link>
                )}
            </>
        )
    }

    /**
   * Esta función se encarga de devolver el contenido html de la entrada.
   * @param {Element} body 
   * @returns {JSX.Element} Contenido del body entrada
   */
    const createMarkup = (body) => {
        return { __html: body };
    }

    return (
        <div>
            <Helmet>
                <title>{`MakeMake - ${info?.title || 'titulo'}`}</title>
                <meta name="description" content={info?.description || 'description'} />
            </Helmet>
            {loading ?
                <>
                    <div
                        className="secondary-inverse--color d-flex justify-content-center align-items-center"
                        style={{ minHeight: '400px' }}
                    >
                        <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                    </div>
                </>
                :
                <>
                    {!isLogged &&
                        <BannerDemo textosBlog={textosBlog} />
                    }

                    <div className="blog-post">
                        <div
                            className="blog-image"
                            style={{
                                maxHeight: isLogged ? 150 : 30
                            }}
                        >
                            <Img
                                className="w-100"
                                src={`${ASSETS_URL}${info?.rutaBanner}`}
                                alt={`imagen de ${info?.title || 'entrada'}`}
                            />
                        </div>

                        <div className="blog-post-container container position-relative">
                            <div
                                style={{
                                    paddingTop: 10,
                                    height: !isLogged && !visible ? 800 : 'auto',
                                    overflow: !isLogged && !visible ? 'hidden' : 'visible'
                                }}
                            >
                                <Link
                                    to="/blog"
                                    className="btn-volver-blog focusable-secondary"
                                >
                                    <Icon icon="lucide:arrow-left" width={20} className="mr-2" />
                                    {textosBlog?.entrada?.volver || 'Volver al blog'}
                                </Link>

                                <h1 className="mt-5 secondary-font onprimary--color text-38 text-line-h-20" style={{ fontWeight: 600 }}>
                                    {info?.title || 'titulo'}
                                </h1>

                                <p className="secondary-font secondary-inverse--color font-weight-bold my-2">{textosBlog?.ficha?.escrito_por || 'Escrito por:'} {info?.author || 'autor'}</p>

                                <p className="secondary-font onprimary--color text-18" style={{ fontWeight: 600 }}>{info?.description || 'description'}</p>

                                <div className="row mt-5">
                                    <div className="col-lg-9 d-flex flex-wrap">
                                        {Tags()}
                                    </div>
                                    <div className="col-lg-3">
                                        <p className="text-right secondary-font secondary-inverse--color font-weight-bold text-18">{textosBlog?.ficha?.fecha || 'Fecha:'} {info?.date || '-/-/-'}</p>
                                    </div>
                                </div>

                                <hr className="line-blog" />

                                <div
                                    className="mb-content"
                                    dangerouslySetInnerHTML={createMarkup(content)}
                                ></div>


                                <div>
                                    <h2 className="text-center mb-4 secondary-font onprimary--color text-30">
                                        {textosBlog?.ficha?.recomendaciones || 'Nuestras recomendaciones'}
                                    </h2>
                                    <RecommendedBooks />
                                </div>
                            </div>

                            {!isLogged && !visible &&
                                <div className="gradient-blog-demo"></div>
                            }
                        </div>

                        {!isLogged && !visible &&
                            <SolicitudDemo className={'blog-demo'} keepReading={()=>setVisible(true)} />
                        }
                    </div>
                </>
            }
        </div>
    )
}