import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";

import { FormularioDemo } from "./components/FormularioDemo";
import { ProminentClients } from "../prominent-clients";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

import { useIntersectionScroll } from "../../hooks/use-intersection-scroll";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

/**
 * Componente con el formulario de solicitud de demo
 * Este se utiliza en la sección de búsqueda y la ficha del libro
 * @param {string} imgForm - Url de la ubicación de la imagen que acompaña el formulario
 * @param {string} className - clases aplicacdas al container del formulario
 * @returns {JSX.Element} SolicitudDemo
 */
export const SolicitudDemo = ({ imgForm, className, keepReading }) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosDemo = useTranslations({ section: "demo", lang: lang });

    const [isIntersecting, ref] = useIntersectionScroll({
        threshold: [0.2, 1],
    });

    return (

        <div className="solicitud-demo">
            <div className={`container ${className && className}`}>
                <div className={`form-demo ${isIntersecting ? 'anim-demo' : ''}`} ref={ref}>
                    <div className="d-lg-flex  align-items-center justify-content-center pt-5">
                        <hr className="d-none d-lg-block"/>

                        {
                            keepReading &&
                            <button
                                onClick={()=>keepReading()}
                                className="d-block d-lg-inline-block mb-3 mb-lg-0 btn-solicitud-demo-leer"
                            >
                                {textosDemo?.solicitud?.seguir_leyendo || 'Seguir leyendo'}
                            </button>
                        }

                        <Link
                            to={'/home'}
                            className="d-block d-lg-inline-block btn-solicitud-demo"
                        >
                            {textosDemo?.solicitud?.button || 'Conoce el catálogo de MakeMake'}
                        </Link>
                        <hr className="d-none d-lg-block"/>
                    </div>

                    <div className="demo-form">
                        <div
                            className={`${imgForm ? 'img-form' : 'margin-form'}`}
                            style={{ backgroundImage: `url(${imgForm ? imgForm : ''})` }}
                        ></div>


                        <div className="content-form">
                            <span className="shape-form"></span>

                            <div className="d-lg-flex">
                                <div className="col-lg-5 px-5 pt-5 pb-lg-5 onsecondary--color">
                                    <h3 className="text-32">{textosDemo?.solicitud?.titulo || 'Solicita tu demo'}</h3>
                                    <p
                                        className="font-secondary text-20 text-line-h-22 my-3"
                                        style={{ fontWeight: 600 }}
                                    >
                                        {textosDemo?.solicitud?.descripcion || 'Descubre la combinación perfecta con MakeMake'}
                                    </p>


                                    <ul className="primary-font" style={{ fontWeight: 500 }}>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div width={30} className="mr-2">
                                                <Icon icon="fa:check" width={15} className=" primary-inverse--color" />
                                            </div>
                                            {textosDemo?.solicitud?.lista?.li1 || 'Agenda una cita con una de nuestras representantes de ventas.'}
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div width={30} className="mr-2">
                                                <Icon icon="fa:check" width={15} className=" primary-inverse--color" />
                                            </div>
                                            {textosDemo?.solicitud?.lista?.li2 || 'Conoce el catálogo, los servicios y las características de MakeMake.'}
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-7 p-3 px-lg-0">
                                    <FormularioDemo />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ProminentClients />

        </div>
    )
}