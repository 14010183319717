import { Cita } from "./cita";
import { Demo } from "./demo";
import { Brochure } from "./brochure";
import { Contactanos } from "./contactanos";

/** Objeto que devuelve los componentes de las subsecciones de la tienda para instituciones */
export const Subsections = {
    Cita,
    Demo,
    Brochure,
    Contactanos
}