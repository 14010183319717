
import React, { useEffect } from "react"

import useObtenerTraducciones from "../js/translations/useObtenerTraducciones";

/**
 * Componente de popup de sin conexión, este componente se muestra cuando se ha ido la conexión a internet y el usuario no está logueado y tampoco tiene el pwa habilitado desde el admin.
 * 
 * @returns {JSX.Element} Componente SinConexionPopup.
 */
export const SinConexionPopup = () => {
    const { isReady, textosSeccion: textoConexion } = useObtenerTraducciones('sin_conexion');

    return (
        <>
            {
                isReady  && 
                <div className="sin-conexion-popup">
                    <div className="window">
                        <img alt="Blop sin conexión" className="image" src="/img/sinconexion-image.png" />
                        <h2 className="title">{textoConexion?.sin_conexion || 'Sin conexión'}</h2>
                        <p className="description">{textoConexion?.descripcion || 'Conéctate para explorar más de 400 libros de las  mejores editoriales de Latinoamérica'}</p>
                    </div>

                </div>
            }

        </>
    )
}