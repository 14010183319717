import React from "react"
import { Img } from "../shared/img"
import { useQueryParams } from "../../hooks/useQueryParams";
import { useEffect } from "react";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { AppView } from "./app-view";
import { SesionAPI } from "../../services/api/Sesion";
import { Link } from "react-router-dom";


export const Success = () => {
    const params = useQueryParams();
    const [authHash, setAuthHash] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const appLink = `https://makemake.app/open-app/auth?hash=${authHash}`;
    const navLanguage = navigator?.language?.includes('en-US')?"en":"es";
    const texts = {
        title:{
            es:'¡Ingreso exitoso!',
            en:'Successful login!'
        },
        description:{
            es:'¡Abre la aplicación y empieza a leer. No cierres esta ventana.',
            en:'Enter the app and start reading. Do not close this window.'
        },
        loading:{
            es:'Verificando sesión, por favor espera...',
            en:'Checking user session, please wait...'
        },
        no_app:{
            es:'¿No tienes el app de MakeMake? Descárgala acá:',
            en:'Download the MakeMake App:'
        },
        open_app:{
            es:'Abrir aplicación',
            en:'Open App'
        },
        download_app:{
            es:'MakeMake App (Android & iOS)',
            en:'MakeMake App (Android & iOS)'
        },
    }


    const handleSamlAndClasslinkAuth = async () => {
        setLoading(true);
        console.log('SAML/CLASSLINK AUTH');
        await SesionAPI.generateAuthHash({ abortSignal: null })
            .then(result => {
                if (result) {
                    setAuthHash(result);
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
                setLoading(false);
            })
    }
    useEffect(() => {
        const _authHash = params.get('hash');
        const authType = params.get('access');
        if (_authHash) {
            if (authType === 'saml_classlink' && _authHash === '0') {
                handleSamlAndClasslinkAuth();
            } else {
                setLoading(false);
                console.log('ANOTHER TYPE OF AUTH');
                setAuthHash(_authHash);
            }
        } else {
            window.location.replace('/home');
        }
    }, [params])


    return (
        <>
            <div className="w-100 min-vh-100 secondary--bg  p-2">
                {
                    loading ?
                        <div className="w-100 min-vh-100 d-flex flex-column justify-content-center align-items-center">
                            <Img className="img-fluid m-2 my-3" src="\img\logo-makemake.png" style={{ maxWidth: 200 }} />
                            <p className="onsecondary--color" style={{ fontWeight: 700 }}>{texts?.loading?.[navLanguage]}</p>
                            <Icon icon="fa:spinner" className={'spinner-rotation m-3 onsecondary--color'} width={35} />
                        </div>
                        :
                        <div className="container d-flex flex-column align-items-center">
                            <Img className="img-fluid m-2 my-3" src="\img\logo-makemake.png" style={{ maxWidth: 200 }} />
                            <h1 className="onsecondary--color text-center">{texts?.title?.[navLanguage]}</h1>
                            <p className="onsecondary--color m-2 text-center">{texts?.description?.[navLanguage]}</p>
                            {
                                authHash &&
                                <>
                                    <div className="my-3 d-flex flex-column">
                                        <a className="btn btn-green my-2" href={`https://makemake.app/open-app/auth?hash=${authHash}`}>{texts?.open_app?.[navLanguage]}</a>
                                        {/* <Link className="onsecondary-inverse--color font-weight-bold mb-3 text-center" to={'/'} style={{ borderBottom: 'solid 2px' }}>Volver a MakeMake</Link> */}
                                    </div>
                                    <p className="primary-inverse--color m-2 text-center" style={{ fontWeight: 700, fontSize: 13 }}>{texts?.no_app?.[navLanguage]}</p>
                                        <a className="onsecondary-inverse--color font-weight-bold mb-3 text-center"  style={{ borderBottom: 'solid 2px' }} href={`https://makemake.app/home`}>{texts?.download_app?.[navLanguage]}</a>
                                    {/* <div className="row" style={{ maxWidth: 350 }}>
                                        <a href={'https://apps.apple.com/co/app/apple-store/id375380948'} className="col-12 btn btn-light my-2  p-2 text-left"  ><Icon icon="ri:apple-fill" width={30} /> Instalación en iOS</a>
                                        <a href={'https://play.google.com/store/apps/details?id=com.makemake.app'} className="col-12 btn btn-light p-2 text-left" > <Icon icon="uil:android" width={30} /> Instalación en Android</a>
                                    </div> */}
                                </>
                            }
                        </div>

                }

            </div>

        </>
    )

}