import React from "react";
import { NotificationIcons } from "./icons";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslations } from "../../hooks/use-translations";
import _ from "lodash";

/**
 * Componente de formulario para suscripción al boletín.
 * 
 * Este componente muestra un formulario donde los usuarios pueden suscribirse al boletín.
 * Al enviar el formulario, redirige a la página de ingreso de datos con la información proporcionada.
 * 
 * @component
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.onSubmitForm - Función a ejecutar al enviar el formulario.
 * @param {string} props.lang - Idioma utilizado para las traducciones.
 */

export const NewsLetterForm = ({onSubmitForm, lang}) => {
    const texts = useTranslations({ section: "notificaciones", lang: lang });
	const { register, handleSubmit } = useForm();
	const emailIconSize = 80;
    const history = useHistory();

    const onSubmit = (data)=>{
        onSubmitForm&&onSubmitForm();
        history.push({
            pathname:'/boletin/ingresar-datos',
            search:`?email=${decodeURIComponent(data?.email)}&fullname=${decodeURIComponent(data?.name)}`
        })
    }
	return (
		<div className="newsletter-form p-3 my-2 onbackground-primary--color">
			<h2 className="text-22">{_.get(texts, 'buzon.boletin.titulo') || `Suscríbete al Makeboletín`}</h2>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<NotificationIcons.EmailIcon width={emailIconSize} height={emailIconSize} />
				</div>
				<p className="pl-2 description">{_.get(texts, 'buzon.boletin.descripcion') || `Recibe información de nuestros libros y eventos en vivo.`}</p>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} className="onbackground-primary--color">
				<div>
					<label className="form-label" htmlFor="notification-newsletter-form-name">
						{_.get(texts, 'buzon.boletin.label.nombre') || 'Nombre'}:
					</label>
					<input
						className="focusable-secondary form-control"
						type="text"
						id="notification-newsletter-form-name"
						name="name"
                        {...register('name', {required:true})}
						required
					/>
				</div>
				<div>
					<label className="form-label" htmlFor="notification-newsletter-form-email">
						{_.get(texts, 'buzon.boletin.label.correo') || 'Correo'}:
					</label>
					<input
						className="focusable-secondary form-control"
						type="email"
						id="notification-newsletter-form-email"
						name="email"
                        {...register('email', {required:true})}
						required
					/>
				</div>
				<div className="d-flex justify-content-end">
					<button
						className="focusable-red btn btn-secondary my-3 px-5 py-2"
						type="submit"
					>
						{_.get(texts, 'buzon.boletin.boton.enviar') || 'Enviar'}
					</button>
				</div>
			</form>
		</div>
	);
};
