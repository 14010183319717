import React from "react";

/**
 * Icono de usuario
 * @param {string} color -color por defecto de la fuente
 * @param {string} className - clase aplicada al icono
 * @returns {JSX.Element} IconUser
 */
export const IconUser = ({color, className}) => {
    return (
        <svg width="23" height="30" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className? className : ''}>
            <path d="M6.38454 13.9941C2.67755 13.9941 1.23595 21.3309 0.978516 24.9993H22.0234L21.2167 21.9338C20.7997 20.3493 19.895 18.936 18.6306 17.894C17.2318 16.7412 15.458 16.2014 13.6794 15.8523C10.8022 15.2876 9.2823 13.9941 6.38454 13.9941Z" fill={`${color? color : "#262626"}`} />
            <path d="M17.8222 6.32027C17.8222 7.80222 17.3121 9.16504 16.458 10.2428C15.3004 11.7035 13.5106 12.6405 11.5019 12.6405C8.01132 12.6405 5.18164 9.81086 5.18164 6.32027C5.18164 2.82968 8.01132 0 11.5019 0C13.5162 0 15.3105 0.942325 16.4678 2.41019C17.316 3.48596 17.8222 4.84401 17.8222 6.32027Z" fill={`${color? color : "#262626"}`} />
        </svg>

    )
}