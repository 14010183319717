/**
 * Arreglo con los países de latinoamerica, sirve para poder organizar estos países de primeras en la sección de colecciones por países.
 */
export const LATAM_COUNTRIES = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guayana Francesa",
    "Granada",
    "Guatemala",
    "Guayana",
    "Haití",
    "Honduras",
    "Jamaica",
    "México",
    "Nicaragua",
    "Paraguay",
    "Panamá",
    "Perú",
    "Puerto Rico",
    "República Dominicana",
    "Surinam",
    "Uruguay",
    "Venezuela",
];