import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { TRAINING_DATA } from "../data/training_data";
import * as _ from "lodash";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 *  Componente de "'Acompañamiento y capacitación permanente" para que es colegios
 * @returns {JSX.Element} Componente Training.
 */
export const Training = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textoColegios = useTranslations({ section: "que_es_colegios", lang: lang });
	const data = [...TRAINING_DATA];

	return (
		<>
			<div className="training py-5">
				<div className="shape"></div>
				<div className="container py-3">
					<h1
						tabIndex={0}
						className="focusable-secondary onbackground-primary--color secondary-font font-weight-bold text-center"
					>
						{textoColegios?.acompanamiento?.titulo ||
							"Acompañamiento y capacitación permanente"}
					</h1>

					<div className="col-lg-10 mx-auto mt-5">
						<ul className="row px-0">
							{data?.map((option, i) => {
								return (
									<li
										key={`opction${i}`}
										tabIndex={0}
										className="focusable-secondary col-md-6 col-lg-3 mb-4"
									>
										<div className="card p-3 text-center">
											<img
												role={"presentation"}
												alt={option?.alt || "N/A"}
												src={option?.src || "N/A"}
												style={{
													width: option?.width || "100px",
													height: "80px",
												}}
											/>
											<p
												className="py-3 onbackground-primary--color"
												style={{ fontSize: "0.8em" }}
												dangerouslySetInnerHTML={{
													__html:
														_.get(
															textoColegios,
															option?.text?.translationPath
														) ||
														option?.text?.default ||
														"N/A",
												}}
											></p>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};
