import React, { useContext, useEffect, useRef, useState } from "react";
import { CommunityTitle } from "../../../components/community/community-title";
import { CommunityMonthSelector } from "../../../components/community/month-selector";
import { READING_PLAN_MONTHS } from "../../data/reading-plans";
import { CommunitySelector } from "../../../components/community/community-selector";
import { BookCard } from "./components/book-card";
import { useReadingPlan } from "../../hooks/use-reading-plan";
import { LibrosAPIs } from "../../../services/api/Libros";
import { CancelRequestController } from "../../../util/cancel-request-controller";
import { READING_LEVELS } from "../../data/reading-levels";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { PastReadingPlans } from "./components/past-reading-plans";
import moment from "moment";
import { useTranslations } from "../../../hooks/use-translations";
import _ from "lodash";

export const ReadingPlans = React.forwardRef((props, ref) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "comunidad_club", lang: lang });
	const months = [...READING_PLAN_MONTHS].map((month) => {
		return {
			...month,
			name: _.get(texts, month?.name?.translationPath) || month?.name?.default,
		};
	});
	const defaultMonth = months.find(
		(month) => moment().isSame(month?.date, "month") && moment().isSame(month?.date, "year")
	)?.id;
	const defaultReadingLevelId = "primeros lectores";
	const [currentMonth, setCurrentMonth] = useState(defaultMonth);
	const [currentReadingLevelId, setCurrentReadingLevelId] = useState(defaultReadingLevelId);
	const readingPlan = useReadingPlan({ month: currentMonth });
	const [loadingBook, setLoadingBook] = useState(true);
	const [book, setBook] = useState({});
	const prevControllerRef = useRef(null);
	const readingLevels = [...READING_LEVELS].map((readingLevel) => {
		return {
			...readingLevel,
			name: _.get(texts, readingLevel?.name?.translationPath) || readingLevel?.name?.default,
		};
	});

	const handleReadingLevelSelection = async ({ readingLevelId }) => {
		if (prevControllerRef.current) {
			prevControllerRef.current.abort();
		}
		const controller = new CancelRequestController();
		prevControllerRef.current = controller;
		setLoadingBook(true);
		setCurrentReadingLevelId(readingLevelId);
		let bookId = null;
		if (readingLevelId?.toLowerCase() === "primeros lectores") {
			bookId = readingPlan?.plan?.books[0];
		} else if (readingLevelId?.toLowerCase() === "lectores intermedios") {
			bookId = readingPlan?.plan?.books[1];
		} else if (readingLevelId?.toLowerCase() === "lectores avanzados") {
			bookId = readingPlan?.plan?.books[2];
		}
		if (bookId) {
			await LibrosAPIs.obtenerDatosFichaLibro({
				abortSignal: controller.signal,
				idLibro: bookId,
			})
				.then((_book) => {
					setBook({ ..._book, idLibro: bookId });
					setLoadingBook(false);
				})
				.catch((error) => console.log(error));
		}
	};

	useEffect(() => {
		handleReadingLevelSelection({
			readingLevelId: defaultReadingLevelId,
		});
	}, [readingPlan?.plan?.month]);

	return (
		<div className="reading-plans w-100 mb-4 pb-3 mt-4 mt-md-0">
			<div className="container">
				<div className="d-none row d-md-flex w-100 p-0 m-0 px-lg-5 flex-nowrap flex-column-reverse flex-lg-row align-items-center justify-content-between">
					<p
						className="onbackground-primary--color  col-12 col-lg-7 text-22 flex-grow-1 my-5"
						style={{ fontWeight: 500 }}
					>
						{_.get(texts, "planes.descripcion") ||
							`"MakeMake te acompaña con eventos, recomendaciones y marketing digital para hacer crecer tu comunidad lectora."`}
					</p>
					<div className="col-12 col-lg-5 flex-grow-0 d-flex justify-content-center align-items-center">
						<img
							className="img-fluid"
							src="/img/club-makemake/2-girls-1-cloud.png"
							alt=""
						/>
					</div>
				</div>
				<div ref={ref} style={{ scrollMarginTop: 80 }}>
					<CommunityTitle.H1
						title={_.get(texts, "planes.titulo") || `Plan de lecturas mensual`}
					/>
				</div>
				<CommunityMonthSelector
					months={months}
					defaultMonthId={defaultMonth}
					onSelect={(month) => {
						setCurrentMonth(month?.id);
					}}
				/>
				<div className="d-flex justify-content-center align-items-center mb-3">
					<div
						className="row m-0 p-0 mb-4 justify-content-center align-items-center secondary-inverse--color"
						style={{ maxWidth: 800 }}
					>
						<h2 className="col-12 col-md-6 font-weight-bold p-0 text-center my-2">
							{readingPlan?.plan?.theme?.[lang] || readingPlan?.plan?.theme?.["es"]}
						</h2>
						<p className="col-12 col-md-6 p-0 text-center" style={{ fontWeight: 700 }}>
							{readingPlan?.plan?.question?.[lang] ||
								readingPlan?.plan?.question?.["es"]}
						</p>
					</div>
				</div>
				<div className="row m-0 p-0 d-flex justify-content-center">
					<div className="col-12 col-lg-auto p-0 m-0 d-flex justify-content-lg-end align-items-lg-start mt-lg-5">
						<CommunitySelector
							defaultItemId={currentReadingLevelId}
							data={readingLevels}
							onSelect={(readingLevel) => {
								handleReadingLevelSelection({
									readingLevelId: readingLevel?.id,
								});
							}}
						/>
					</div>
					<div className="col-12 col-lg-8 p-0 m-0">
						<BookCard book={book} loading={loadingBook} lang={lang} />
					</div>
				</div>
			</div>
			<PastReadingPlans />
		</div>
	);
});
