import { Icon } from "@iconify/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { Book } from "../../../components/shared/book";
import { CheckBox } from "../../../components/shared/checkbox";
import PropTypes from 'prop-types';
import SwiperCore, { Autoplay, Keyboard, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef } from "react";
import _ from 'lodash';
import useObtenerSubsecciones from "../../../js/translations/useObtenerSubsecciones";

/**
 * Este componente es una tarjeta que se expande y muestra la información de un plan y sus libros pertenecientes al plan de suscripción "Lecturas MakeMake"
 */
export const PlanLectura = ({ data, isSelected, onChangeSelection }) => {
    const {textosSubSecciones:textos} = useObtenerSubsecciones('tienda_makemake');

    const swiperPrevButton = useRef();
    const swiperNextButton = useRef();
    data = {
        planName: _.get(textos, data?.nombre?.translationPath) || data?.nombre?.default || 'N/A',
        description:  _.get(textos, data?.descripcion?.translationPath) || data?.descripcion?.default || 'N/A',
        books: Array.isArray(data?.libros) ? data?.libros : [],
        pdfUrl: data?.pdfUrl || '',
        idealPara:Array.isArray(data?.idealPara)?data?.idealPara:[]
    }
    const history = useHistory();
    const [selected, setSelected] = useState(isSelected ? true : false);


    const goToBook = (url) => {
        history.push(url)
    }


    useEffect(() => {
        setSelected(isSelected ? true : false);
    }, [isSelected])

    useEffect(() => {
        onChangeSelection && onChangeSelection(selected, data)
    }, [selected])

    return (<div role={'region'} aria-label={`Plan: ${data?.planName}`} tabIndex={0} className={`plan-lectura-card focusable-primary mb-3 ${selected ? 'selected' : ''}`} >
        <div className="section-1">
            <h2 className="title">{data?.planName}</h2>
            <p className="description my-3">{data?.description}</p>

        </div>
        <div className="section-2">
            <div className="swipper-buttons-container">
                <button ref={swiperPrevButton} className="swipper-button focusable-secondary" aria-label="Anterior libro">
                    <Icon className="icon" icon="material-symbols:arrow-back-rounded" width={20} />
                </button>
                <button ref={swiperNextButton} className="swipper-button focusable-secondary ml-4" aria-label="Siguiente libro">
                    <Icon className="icon" icon="material-symbols:arrow-forward-rounded" width={20} />
                </button>
            </div>
            <Swiper
                autoplay={{ delay: 7000 }}
                effect={"slide"}
                breakpoints={{
                    526: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                    },
                    992: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                    },
                    1400: {
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                    },
                }}
                className="my-3"
                keyboard={true}
                loop={true}
                parallax={true}
                navigation={{ nextEl: swiperNextButton?.current, prevEl: swiperPrevButton?.current }}
                modules={[Autoplay, Keyboard, Navigation, Pagination]}
                // ref={swiperContainer}
                spaceBetween={40}
                slidesPerGroup={2}
                slidesPerView={2}
                onSwiper={(swiper) => {
                    swiper.params.navigation.prevEl = swiperPrevButton.current
                    swiper.params.navigation.nextEl = swiperNextButton.current
                    swiper.navigation.destroy()
                    swiper.navigation.init()
                    swiper.navigation.update()

                }}
            >
                {data?.books?.map((book, i) => {
                    return <SwiperSlide key={i}>
                        <Book
                            key={i}
                            libro={book}
                            esVisible={true}
                            alSeleccionar={(book) => goToBook(book?.webUrl || book?.weburl)}
                            ocultarOpciones={{
                                botonFavoritos: true,
                                checkbox: true,
                            }} />
                    </SwiperSlide>
                })}
            </Swiper>

            <div className="d-flex flex-column w-100 justify-end align-items-end">
                <label className={`selector-button mb-2 ${selected ? "selected" : ""}`}>
                    <CheckBox ariaLabel={'selección plan/plan selection'} checked={selected} focusColor={'focusable-primary'} onChange={(e) => setSelected(e?.target?.checked)} />
                    {textos?.plan_lectura?.seleccionar_plan || 'Seleccionar este plan'}
                </label>
                <a className="download-pdf-button focusable-primary" role={'button'} href={data?.pdfUrl || ''} download={data?.planName}>
                    {textos?.plan_lectura?.descargar_listado || 'Descargar listado de libros'}
                    <Icon icon="material-symbols:arrow-downward-rounded" className={`icon`} />
                </a>
            </div>

        </div>
    </div>)
}

PlanLectura.propTypes = {
    /**
     * Objeto con la información del plan, por ahora se requiere esta estructura.
     * Cada libro debe tener IdLibro, titulo|title|nombre, webUrl y portada.
     */
    data: PropTypes.shape({
        id: PropTypes.string,
        nombre: PropTypes.any,
        descripcion: PropTypes.any,
        libros: PropTypes.array,
        pdfUrl:PropTypes.string,
        idealPara:PropTypes.array
    }),
    /**
     * Indica si el plan debe marcarse o no por defecto al inicio del ciclo de vida.
     */
    isSelected: PropTypes.bool,
    /**
     * función que se ejecuta cuando hay un cambio de estado en el checkbox del plan, la función tiene 2 parametros: checked y data.
     */
    onChangeSelection: PropTypes.func
}