
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";

/**
 * Realiza la autenticación de un usuario mediante una solicitud a la API.
 * @async
 * @param {Object} options - Opciones de la solicitud de autenticación.
 * @param {AbortSignal} options.abortSignal - Señal de aborto para la solicitud.
 * @param {Object} options.data - Datos del usuario para la autenticación.
 * @param {string} options.data.username - Nombre de usuario.
 * @param {string} options.data.password - Contraseña del usuario.
 * @returns {Promise<boolean>} - retorna true si se autenticó correctamente, de lo contrario retorna false.
 * @throws {Error} - Error en caso de fallo en la autenticación.
 */
const defaultSignIn = async ({ abortSignal, data }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const fullData = {
			username: data?.username || "",
			password: data?.password || "",
		};
		const formData = new FormData();
		formData.append("username", fullData?.username);
		formData.append("password", fullData?.username);

		const response = await axios({
			method: "POST",
			url: `assets/login`,
			signal: abortSignal || null,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: fullData,
		});
		const responseData = response?.data;
		if (
			responseData?.error ||
			(typeof responseData === "string" && responseData?.toLowerCase()?.includes("error"))
		) {
			throw new Error(responseData?.error || responseData);
		}
		return responseData?.toLowerCase()?.includes('exito')?true:false
	} catch (error) {
		console.log("API ERROR:", error);
		return Promise.reject(error);
	}
};


/**
 * Realiza la autenticación de un usuario a través de un hash de autenticación.
 * @async
 * @param {Object} options - Opciones de la solicitud de autenticación.
 * @param {AbortSignal} options.abortSignal - Señal de aborto para la solicitud.
 * @param {Object} options.data - Datos del usuario para la autenticación.
 * @param {string} options.data.hash - hash de sesión.
 * @returns {Promise<string>} - Token de autenticación.
 * @throws {Error} - Error en caso de fallo en la autenticación.
 */
const hashSignIn = async ({ abortSignal, data }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const formData = new FormData();
		formData.append('hash', data?.hash);
		const response = await axios({
			method: "POST",
			url: `assetsApp/login`,
			signal: abortSignal || null,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		});
		const responseData = response?.data;
		if (
			responseData?.error ||
			(typeof responseData === "string" && responseData?.toLowerCase()?.includes("error"))
		) {
			throw new Error(responseData?.error || responseData);
		}
		return responseData?.token || responseData?.toLowerCase()?.includes('exito')?true:false;
	} catch (error) {
		console.log("API ERROR:", error);
		return Promise.reject(error);
	}
};
/**
 * Este servicio verifica si el usuario esta autenticado
 * @param {{abortSignal:AbortSignal}} param0 
 * @returns {Promise<boolean>} retorna true si el usuario esta autenticado, false si no
 */
const usuarioAutenticado = async ({abortSignal})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;
        const response = await axios({
          method: 'GET',
          url: 'assets/verificarSesion',
          signal: abortSignal
        })
        const autenticacion = response.data;
        const autenticado = typeof autenticacion == "string" && !autenticacion.toLowerCase().includes("sin sesion") ? true:false;
        return autenticado;
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}
/**
 * Este servicio cierra la sesión del usuario
 * @param {{abortSignal:AbortSignal}} param0 
 * @returns {Promise<boolean>} retorna true si se cerró la sesión correctamente, de lo contrario retornará false.
 */
const cerrarSesion = async ({abortSignal})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;
        const response = await axios({
          method: 'GET',
          url: 'assets/logout',
          signal: abortSignal || null
        })
        const autenticacion = response.data;
        const sesionCerrada = Boolean(autenticacion) && autenticacion.includes('Exito') ? true:false;
        return sesionCerrada;
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}


/**
 * Crea un hash de autenticación único a partir de una sesión, esta API  se utiliza normalmente para generar el hash y enviarlo a la app para que se cree la sesión.
 * @param {Object} options - Opciones para la petición.
 * @param {AbortSignal} options.abortSignal - Señal de aborto para la petición.
 * @returns {Promise<string|null>} Una promesa que se resuelve en un hash de acceso o en null si no se puede generar el hash.
 * @throws {Error} Si hay un error en la petición o se recibe una respuesta de error.
 */
const generateAuthHash = async ({ abortSignal }) => {
	try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

		const response = await axios({
			method: "POST",
			url: `assetsApp/visorEmbebido/onsa2DF232riLLA4`,
			signal: abortSignal || null,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		const responseData = response?.data;
		if (
			responseData?.error ||
			(responseData?.status === 0) ||
			(typeof responseData === "string" && responseData?.toLowerCase()?.includes("error"))
		) {
			throw new Error(responseData?.info || responseData?.error || responseData);
		}
		return responseData?.hash || null;
	} catch (error) {
		console.log("API ERROR:", error);
		return Promise.reject(error);
	}
};

export const SesionAPI = {
    usuarioAutenticado,
    cerrarSesion,
    generateAuthHash,
    defaultSignIn,
	hashSignIn
}