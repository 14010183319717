import React, { useEffect, useState, useContext, useRef } from 'react';
import { ModalAdvertenciasInstalacion } from './modals/ModalAdvertenciasInstalacion.js';
import { ModoPWAContext } from '../contexts/ModoPwaContext.js';
import useObtenerSubsecciones from '../js/translations/useObtenerSubsecciones.js';
import MicroModal from 'micromodal';
import { store } from './Store.js';

/**
 * Componente que representa al botón para instalar la PWA en el dispositivo del usuario, este componente se activa una vez que el service worker se registra, activa y si el usuario tiene habilitado la descarga de libros.
 * @component
 * @returns {JSX.Element} Elemento JSX que representa el botón de instalación de la aplicación.
 */
export const AppInstallButton = () => {
  const { isReady, textosSubSecciones: textoHome } = useObtenerSubsecciones('home');
  const globalStateContext = useContext(store);
  const state = globalStateContext?.state;
  const isAuth = state?.sesionActiva !== null && state?.sesionActiva === 'SI';
  const pwaState = useContext(ModoPWAContext);
  const ModalAdvertenciasInstalacionId = 'modal-advertencias-instalacion';
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    setVisible(isAuth && pwaState?.instalable && pwaState?.appInstalada === false);
  }, [isAuth, pwaState?.instalable, pwaState?.appInstalada])


  useEffect(() => {
    if (visible) {
      buttonRef.current && buttonRef.current.focus();
    }
  }, [buttonRef, visible])


  const installPWA = () => {
    if (pwaState?.promptEvent &&
      pwaState?.appInstalada === false &&
      pwaState?.promptEvent.prompt &&
      pwaState?.promptEvent.userChoice) {
      pwaState.promptEvent.prompt();
      pwaState.promptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          pwaState.dispatch({ type: 'CHANGE_VALUE', payload: { property: 'appInstalada', value: true } });
          pwaState.dispatch({ type: 'CHANGE_VALUE', payload: { property: 'instalable', value: false } });
        } else {
          pwaState.dispatch({ type: 'CHANGE_VALUE', payload: { property: 'appInstalada', value: false } });
        }
      });
    } else {
      try {
        MicroModal.show(ModalAdvertenciasInstalacionId, {
          awaitCloseAnimation: true,
        });
      } catch (error) {
        console.log('modal advertencias', error);
      }
    }


  };
  return (
    <>
      {isReady && visible &&
        <button
          ref={buttonRef}
          aria-live="assertive"
          className="focusable-primary btn action-button download-app-button"
          onClick={() => installPWA()}>
          <img
            aria-hidden={true}
            alt="icono de la aplicación"
            src="/img/logos/logo192.png" />
          <p>{textoHome?.descargar?.texto || 'Descarga nuestra app'}</p>
        </button>
      }
      <ModalAdvertenciasInstalacion idModal={ModalAdvertenciasInstalacionId} />
    </>
  )
}

