import React from "react";
import { SectionTitle } from "./section-title";

export const RequirementsSection = () => {
	const requirements = [
		"Participación en las sesiones de capacitación.",
		"Hacer parte del grupo de WhatsApp del piloto.",
		"Contestar una (1) encuesta que se enviará al final del piloto.",
		"Participación en una (1) entrevista grupal o individual.",
	];

	return (
		<section className="p-3">
			<SectionTitle title="Obligaciones de los participantes" />

			<div className="row p-3 justify-content-center align-items-center">
				<ul className="secondary-inverse--bg onsecondary-inverse--color p-5" style={{borderRadius:'1rem'}}>
					{requirements.map((requirement, index) => (
						<li key={index} className="" style={{fontSize:'1.2em'}}>
							<span className="font-weight-bold primary-inverse--color">{index+1}.</span>
							 {" "} {requirement}
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};
