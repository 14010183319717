import { Icon } from "@iconify/react";
import React from "react";

/**
 * Componente de pantalla de inicio.
 * 
 * @returns {JSX.Element} Componente SplashScreen.
 */
export const SplashScreen = ()=>{
    return (
        <div role={"alert"} aria-live={"assertive"} aria-label={"Cargando MakeMake"} className="splash-screen">
            <img role={"presentation"} src="/img/makemake-logo-principal.png" alt="logo makemake"/>
        <Icon icon={'icomoon-free:spinner9'} className={'spinner-rotation spinner'} width={40} />
        </div>
    )
}