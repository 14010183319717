/**
 *Objeto que contiene los filtros de búsqueda que se manipulan en el componente Filtro
 */
let FiltrosBusqueda = {
  // INPUT TEXT
  //--------------------------------------------------------
  sch:{
    id: 'sch',
    renderType: 'input-text',
    value:'',
    name: {
      defaultValue: 'Buscar',
      translationId: 'buscar'
    }
  },
    // CHECKBOX SELECTOR
  //--------------------------------------------------------
  // ordenAlfabetico: {
  //   id: 'ordenAlfabetico',
  //   renderType: 'checkbox-selector',
  //   name: {
  //     defaultValue: 'Orden alfabetico',
  //     translationId: 'orden-alfabetico'
  //   },
  //   items: [
  //     {
  //       id: 'az',
  //       renderType: 'checkbox',
  //       checked: false,
  //       name: {
  //         defaultValue: 'A - Z',
  //         translationId: 'az'
  //       }
  //     },
  //     {
  //       id: 'za',
  //       renderType: 'checkbox',
  //       checked: false,
  //       name: {
  //         defaultValue: 'Z - A',
  //         translationId: 'za'
  //       }
  //     },
  //   ]
  // },
  tema: {
    id: 'tema',
    renderType: 'checkbox-selector',
    name: {
      defaultValue: 'Materias',
      translationId: 'materia'
    },
    items: [
      {
        id: 'Ciencias',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Ciencias',
          translationId: 'ciencias'
        }
      },
      {
        id: 'Historia y ensayo',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Historia y ensayo',
          translationId: 'historia-y-ensayo'
        }
      },
      {
        id: 'Letras y números',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Letras y números',
          translationId: 'letras-y-numeros'
        }
      },
      {
        id: 'Artes y manualidades',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Artes y manualidades',
          translationId: 'artes-y-manualidades'
        }
      },
      {
        id: 'Canción y poesía',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Canción y poesía',
          translationId: 'cancion-y-poesia'
        }
      },
      {
        id: 'Narrativa',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Narrativa',
          translationId: 'narrativa'
        }
      },
    ]
  },
  nivel: {
    id: 'nivel',
    renderType: 'checkbox-selector',
    name: {
      defaultValue: 'Nivel de lectura',
      translationId: 'nivel-de-lectura'
    },
    items: [
      {
        id: 'Primeros lectores',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Primeros lectores',
          translationId: 'primeros-lectores'
        }
      },
      {
        id: 'Lectores intermedios',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Lectores intermedios',
          translationId: 'lectores-intermedios'
        }
      },
      {
        id: 'Lectores avanzados',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Lectores avanzados',
          translationId: 'lectores-avanzados'
        }
      },
    ]
  },
  genero: {
    id: 'genero',
    renderType: 'checkbox-selector',
    name: {
      defaultValue: 'Género',
      translationId: 'genero'
    },
    items: [
      {
        id: 'Cómic',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Cómic',
          translationId: 'comic'
        }
      },
      {
        id: 'Libro álbum',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Libro álbum',
          translationId: 'libro-album'
        }
      },
      {
        id: 'Cuento ilustrado',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Cuento ilustrado',
          translationId: 'cuento-ilustrado'
        }
      },
      {
        id: 'Poesía',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Poesía',
          translationId: 'poesia'
        }
      },
      {
        id: 'Crónica y ensayo',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Crónica y ensayo',
          translationId: 'cronica-y-ensayo'
        }
      },
      {
        id: 'Interactivo',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Interactivo',
          translationId: 'interactivo'
        }
      },
      {
        id: 'Didáctico',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Didáctico',
          translationId: 'didactico'
        }
      },
      {
        id: 'Teatro',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Teatro',
          translationId: 'teatro'
        }
      },
      {
        id: 'Cuento',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Cuento',
          translationId: 'cuento'
        }
      },
      {
        id: 'Novela',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Novela',
          translationId: 'novela'
        }
      },
      {
        id: 'Informativo',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Informativo',
          translationId: 'informativo'
        }
      },
    ]
  },
  edad: {
    id: 'edad',
    renderType: 'checkbox-selector',
    name: {
      defaultValue: 'Edad',
      translationId: 'edad'
    },
    items: [
      {
        id: '3',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Desde 3 años',
          translationId: 'desde-3-anos'
        }
      },
      {
        id: '5',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Desde 5 años',
          translationId: 'desde-5-anos'
        }
      },
      {
        id: '7',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Desde 7 años',
          translationId: 'desde-7-anos'
        }
      },
      {
        id: '9',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Desde 9 años',
          translationId: 'desde-9-anos'
        }
      },
      {
        id: '11',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Desde 11 años',
          translationId: 'desde-11-anos'
        }
      },
      {
        id: '13',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Desde 13 años',
          translationId: 'desde-13-anos'
        }
      },
      {
        id: '15',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Desde 15 años',
          translationId: 'desde-15-anos'
        }
      },
    ]
  },
  dificultadLectora:{
    id: 'dificultadLectora',
    renderType: 'checkbox-selector',
    name:{
      defaultValue: 'Dificultad lectora',
      translationId: 'dificultadLectora'
    },
    items: [
      {
        id: 'A-B-C',
        value: 'A;B;C',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'A-B-C',
          translationId: null
        }
      },
      {
        id: 'D-E-F-G-H-I-J',
        value: 'D;E;F;G;H;I;J',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'D-E-F-G-H-I-J',
          translationId: null
        }    
      },
      {
        id: 'K-L-M',
        value: 'K;L;M',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'K-L-M',
          translationId: null
        }    
      },
      {
        id: 'N-O-P',
        value: 'N;O;P',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'N-O-P',
          translationId: null
        }   
      },
      {
        id: 'Q-R-S',
        value: 'Q;R;S',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'Q-R-S',
          translationId: null
        }   
      },
      {
        id: 'T-U-V',
        value: 'T;U;V',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'T-U-V',
          translationId: null
        }
      },
      {
        id: 'W-X-Y-Z-Z+',
        value: 'W;X;Y;Z;Z+',
        renderType: 'checkbox',
        checked: false,
        name: {
          defaultValue: 'W-X-Y-Z-Z+',
          translationId: null
        }
      },
    ]
  },
  // tiempoDeLectura: {
  //   id: 'tiempo',
  //   renderType: 'checkbox-selector',
  //   name: {
  //     defaultValue: 'Tiempo de lectura',
  //     translationId: 'tiempo-de-lectura'
  //   },
  //   items: [
  //     {
  //       id: '5-10',
  //       renderType: 'checkbox',
  //       checked: false,
  //       name: {
  //         defaultValue: 'De 5 a 10 minutos',
  //         translationId: 'de-5-a-10-minutos'
  //       }
  //     },
  //     {
  //       id: '10-20',
  //       renderType: 'checkbox',
  //       checked: false,
  //       name: {
  //         defaultValue: 'De 10 a 20 minutos',
  //         translationId: 'de-10-a-20-minutos'
  //       }
  //     },
  //     {
  //       id: '20',
  //       renderType: 'checkbox',
  //       checked: false,
  //       name: {
  //         defaultValue: 'Más de 20 minutos',
  //         translationId: 'mas-de-20-minutos'
  //       }
  //     },
  //   ]
  // },
  // editoriales: {
  //   id: 'editoriales',
  //   renderType: 'checkbox-selector',
  //   name: {
  //     defaultValue: 'Editoriales',
  //     translationId: 'editoriales'
  //   },
  //   items: [
  //     {
  //       id: 'editorial-1',
  //       renderType: 'checkbox',
  //       checked: false,
  //       name: {
  //         defaultValue: 'Editorial 1',
  //         translationId: 'editorial-1'
  //       }
  //     },
  //     {
  //       id: 'editorial-2',
  //       renderType: 'checkbox',
  //       checked: false,
  //       name: {
  //         defaultValue: 'Editorial 2',
  //         translationId: 'editorial-2'
  //       }
  //     },
  //     {
  //       id: 'editorial-3',
  //       renderType: 'checkbox',
  //       checked: false,
  //       name: {
  //         defaultValue: 'Editorial 3',
  //         translationId: 'editorial-3'
  //       }
  //     },
  //   ]
  // },
  // CHECKBOX
  //--------------------------------------------------------
  actividad: {
    id: 'actividad',
    renderType: 'checkbox',
    checked: false,
    name: {
      defaultValue: 'Actividad',
      translationId: 'actividad'
    }
  },
  libroPremiado: {
    id: 'libroPremiado',
    renderType: 'checkbox',
    checked: false,
    name: {
      defaultValue: 'Libro premiado',
      translationId: 'libroPremiado'
    }
  },
  libroAccesible: {
    id: 'libroAccesible',
    renderType: 'checkbox',
    checked: false,
    name: {
      defaultValue: 'Libro con lectura asistida',
      translationId: 'libroAccesible'
    }
  },
};

export default FiltrosBusqueda;
