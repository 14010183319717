import React from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
export const HelpSection = () => {
	return (
		<section className="p-3 my-5 d-flex flex-column flex-lg-row justify-content-center align-items-center">
			<div className="">
				<img
					src="../img/eyex-pregunta.png"
					className="img-fluid"
					style={{ maxWidth: "200px" }}
					alt={"eyex-pregunta.png"}
				/>
			</div>
			<div className="d-flex flex-column align-items-start">
				<h3 className="text-center  text-lg-left secondary-font font-weight-bold secondary-inverse--color" style={{fontSize:'1.7em'}}>¿Eres parte del grupo del piloto <br/> y necesitas ayuda? Contáctanos</h3>
				<div className="mt-4 row justify-content-center justify-content-lg-start m-0">
					<Icon className=" secondary-inverse--color" icon="ic:round-email" width={30} />
					<a
						href={`mailto:${"contacto@makemake.com.co"}`}
						className="focusable-secondary ml-2 font-weight-bold"
					>
						{" "}
						{"contacto@makemake.com.co"}
					</a>
				</div>
				<div className="mt-3 row m-0 justify-content-center justify-content-lg-start align-items-start">
					<Icon className=" secondary-inverse--color" icon="ri:whatsapp-fill" width={30} />
					<div className="ml-2">
						<a href={"https://wa.link/vuju91"} className="focusable-secondary d-block font-weight-bold">
							+57 313 4509992
						</a>
						<a href={"https://wa.link/aozzbk"} className="focusable-secondary d-block font-weight-bold">
							+57 350 2011041
						</a>
					</div>
				</div>
				<Link to={'/ayuda'} className="btn btn-primary mt-4 p-3 px-5">Ir a la sección de ayuda</Link>
			</div>
		</section>
	);
};
