/**
 * arreglo con todos los niveles de lectura  que hay en MakeMake
 */
export const READING_LEVEL = [
    {
        id: 'Primeros lectores',
        type: 'nivel',
        name: {
            defaultValue: 'Primeros lectores',
            translationPath:'content.seccion.nivel_lectura.primeros_lectores'
        },
        imgPath:'/img/icons/nivel1-gris.png'
    },
    {
        id: 'Lectores intermedios',
        type: 'nivel',
        name: {
            defaultValue: 'Lectores intermedios',
            translationPath:'content.seccion.nivel_lectura.lectores_intermedios'
        },
        imgPath:'/img/icons/nivel2-gris.png'

    },
    {
        id: 'Lectores avanzados',
        type: 'nivel',
        name: {
            defaultValue: 'Lectores avanzados',
            translationPath:'content.seccion.nivel_lectura.lectores_avanzados'
        },
        imgPath:'/img/icons/nivel3-gris.png'

    },
]