import React from "react";
import { useEffect, useState } from "react";
import getUrlParam from './../js/utilities/getUrl';
import { useHistory } from 'react-router-dom';
import postAjax from './../js/utilities/postAjax';
import { GENERAL_URL_API } from "../components/Store";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Componente que muestra los datos de contacto de una institución, la institución se pasa como it='nombre de la institución' en la ruta como query param, con base a este param se procede a traer la información de la institución desde la correspondiente API.
 */
const DatosInstitucion = () => {
  const { isReady, textosSubSecciones: textosInfo } = useObtenerSubsecciones('informacion');

  const [datosInstituto, setInstituto] = useState({
    correoContacto: 'mbohorma@banrep.gov.co',
    instituo: 'Banco de la República - Centros culturales y bibliotecas',
    nombreContacto: 'Mario Bohórquez',
    link1Mostrar: 'http://www.banrepcultural.org/biblioteca-virtual/recursos-electronicos/libros',
    telefonoContacto: '(571) 343 1011',
    link2Mostrar: 'https://www.youtube.com/watch?v=GcAtJbcQ91w&t=2s',
  })


  const history = useHistory()


    useEffect(() => {
      const institucion = getUrlParam('it');
      if (!!institucion) {
        const instituto = decodeURIComponent(institucion);
        const propiedades = { institucion: instituto }
        
        postAjax(`${GENERAL_URL_API}/assets/datosInstitucion`, { ...propiedades }, response => {
          const respuesta = JSON.parse(response);
  
          let datos = {};
          for (const key in respuesta) {
            const value = respuesta[key];
            datos[key] = value === 'null' || value === '' ? null : value;
          }
  
          setInstituto({
            ...datos,
            instituto: instituto
          })
        } )
      } else {
        history.push('/')
      }
    }, [])

  return (
    <>
      {isReady &&
        <div className="mx-auto pt-3 pb-5">
          <div className="col-lg-8 mx-auto pt-3 pb-5">
            <h2 className="mm-redimir_title mt-3 mb-4 text-center pb-3"><span aria-hidden={true}>3</span> {textosInfo?.datos_institucion?.titulo || 'Datos de contacto de tu institución'}</h2>


            <p className="mm-redmir_textlogin text-center onbackground-third--color">
              {textosInfo?.datos_institucion?.descripcion?.parte_uno || 'El encargado(a) de recursos digitales de'} <span className='font-weight-bold'>{datosInstituto.instituto}</span> {textosInfo?.datos_institucion?.descripcion?.parte_dos ||'es:'} <span className='font-weight-bold'>{datosInstituto.nombreContacto}</span> <br aria-hidden={true}/>
              {textosInfo?.datos_institucion?.descripcion?.parte_tres || 'Esta persona te indicará cómo acceder a MakeMake desde tu institución.'}<br aria-hidden={true}/>
              <span className='font-weight-bold'> {textosInfo?.datos_institucion?.descripcion?.parte_cuatro || 'Por favor copia o almacena esta información para que puedas disfrutar de MakeMake.'} </span>
            </p>
          </div>

          <section className="mm-redimir_form my-5 mm-redimir_data py-5">
            <div className="col-lg-8 mx-auto pt-3 pb-4">
              <h2 className="text-center mb-4 secondary-item">{textosInfo?.datos_institucion?.encargado?.titulo || 'Datos del encargado(a)'}</h2>
              <div className="col-lg-12 mb-3 onbackground-third--color">
                <h3 className="text-center">{textosInfo?.datos_institucion?.encargado?.nombre || 'Nombre'}</h3>
                <p className="text-center">{datosInstituto.nombreContacto}</p>
              </div>
              {
                !!datosInstituto.correoContacto &&
                <div className="col-lg-12 mb-3 onbackground-third--color">
                  <h3 className="text-center">{textosInfo?.datos_institucion?.encargado?.correo ||  'E-mail'}</h3>
                  <p className="text-center">{datosInstituto.correoContacto}</p>
                </div>
              }
              {
                !!datosInstituto.telefonoContacto &&
                <div className="col-lg-12 mb-3 onbackground-third--color">
                  <h3 className="text-center">{textosInfo?.datos_institucion?.encargado?.telefono || 'Teléfono'}</h3>
                  <p className="text-center">{datosInstituto.telefonoContacto} </p>
                </div>
              }
              {
                !!datosInstituto.link1Mostrar &&
                <div className="col-lg-12 mb-3 onbackground-third--color">
                  <h3 className="text-center">{textosInfo?.datos_institucion?.encargado?.tutorial ||  'Tutorial de ingreso a la plataforma'}</h3>
                  <p className="text-center">
                    <a className="secondary-item focusable-red" href={datosInstituto.link1Mostrar} target="_blank" rel="noopener noreferrer"><strong>{datosInstituto.link1Mostrar}</strong></a>
                  </p>
                </div>
              }
              {
                !!datosInstituto.link2Mostrar &&
                <div className="col-lg-12 mb-3 onbackground-third--color">
                  <h3 className="text-center">{textosInfo?.datos_institucion?.encargado?.plataforma ||  'Ir a la plataforma'}</h3>
                  <p className="text-center"><a className="secondary-item focusable-red" href={datosInstituto.link2Mostrar} target="_blank" rel="noopener noreferrer"><strong>{datosInstituto.link2Mostrar}</strong></a></p>
                </div>
              }
            </div>
          </section>
        </div>
      }
    </>
  )
}

export default DatosInstitucion