import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useIsMobile from "../../js/utilities/useIsMobile";

export const SkeletonTagsRecomendados = () => {
  const isMobile = useIsMobile();
  return (
    <SkeletonTheme color="#e8e8e8" highlightColor="#f7f7f7">
      {isMobile ?
        <>
          <Skeleton className="ficha-tema mx-5 mx-lg-4 mb-3" width={160} height={165} />
          <Skeleton className="ficha-tema mx-5 mx-lg-4 mb-3" width={160} height={165} />
        </>
        :
        <>
          <Skeleton className="ficha-tema mx-5 mx-lg-4 mb-3" width={160} height={165} />
          <Skeleton className="ficha-tema mx-5 mx-lg-4 mb-3" width={160} height={165} />
          <Skeleton className="ficha-tema mx-5 mx-lg-4 mb-3" width={160} height={165} />
          <Skeleton className="ficha-tema mx-5 mx-lg-4 mb-3" width={160} height={165} />
          <Skeleton className="ficha-tema mx-5 mx-lg-4 mb-3" width={160} height={165} />
        </>
      }
    </SkeletonTheme>
  );
};

