
const TITLE = "PWA MESSAGES";

/**
     * Imprime un mensaje en la consola con el nivel "log".
     *
     * @param {...any} message - Los mensajes a imprimir en la consola.
     */
const log = (...message) => {
    console.log(`${TITLE}:`, ...message);
}
   /**
     * Imprime un mensaje en la consola con el nivel "error".
     *
     * @param {...any} message - Los mensajes a imprimir en la consola.
     */
const error = (...message) => {
    console.error(`${TITLE}:`, ...message);
}
/**
     * Imprime un mensaje en la consola con el nivel "warn".
     *
     * @param {...any} message - Los mensajes a imprimir en la consola.
     */
const warn = (...message) => {
    console.warn(`${TITLE}:`, ...message);
}
  /**
     * Imprime un mensaje en la consola con el nivel "info".
     *
     * @param {...any} message - Los mensajes a imprimir en la consola.
     */
const info = (...message) => {
    console.info(`${TITLE}:`, ...message);
}

/**
 * Objeto PWAconsole que proporciona métodos para imprimir mensajes en la consola con un título específico.
 */
export const PWAconsole = {
    log,
    error,
    warn,
    info

}