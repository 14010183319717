import { useEffect, useRef, useState } from "react";
import { CancelRequestController } from "../../../util/cancel-request-controller";
import { BlogAPI } from "../../../services/api/Blog";

/**
 * Este hook devuelve un objeto con el estado de carga, la información y el contenido HTML de la entrada del blog. 
 * @param {string} id corresponde al identificador del blog
 * @returns {object< loading: Boolean; info: object; content: HTML>}
 */
export const useContentBlog = ({ id }) => {
    const [loading, setLoading] = useState(true);
    const [infoBlog, setInfoBlog] = useState({});
    const [contentBlog, setContentBlog] = useState("");
    const prevAbortController = useRef();
    const prevAbort2Controller = useRef();

    const fetchInfoBlog = async () => {
        if (prevAbortController.current) {
            prevAbortController.current.abort();
        }
        const abortController = new CancelRequestController();
        prevAbortController.current = abortController;
        setLoading(true);
        await BlogAPI.dataBlog({ abortSignal: abortController.signal, id })
            .then((_data) => {
                setInfoBlog(_data);
            })
            .catch((err) => {
                setInfoBlog({});
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchContentBlog = async () => {
        if (prevAbort2Controller.current) {
            prevAbort2Controller.current.abort();
        }
        const abortController = new CancelRequestController();
        prevAbort2Controller.current = abortController;
        setLoading(true);
        await BlogAPI.contentBlog({ abortSignal: abortController.signal, id })
            .then((_content) => {
                const patternFichas = /href\=\"fichas/g             
                const replacement = () => {return `href="https://makemake.com.co/fichas`};
                const replacedFicha = _content.replace(patternFichas, replacement)

                const patternColeccion = /href\=\"coleccion/g     
                const replacementCollection = () => {return `href="https://makemake.com.co/coleccion`};
                const replacedCollection = replacedFicha.replace(patternColeccion, replacementCollection)

                setContentBlog(replacedCollection);
            })
            .catch((err) => {
                setContentBlog("");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchInfoBlog();
        fetchContentBlog();
        return () => {
            if (prevAbortController.current && prevAbort2Controller.current) {
                prevAbortController.current.abort();
                prevAbort2Controller.current.abort();
            }
        };
    }, []);

    return {
        loading,
        info: infoBlog,
        content: contentBlog,
    };
};
