import React, { useState } from "react";
import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import { useSearchBooksManager } from "../../hooks/advanced-search/use-search-manager";
import { useAdvancedSearch } from "../../hooks/advanced-search/use-advanced-search";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import _ from "lodash";

/**
 * Componente que contiene el input de búsqueda y que  se encarga de mostrar el listado de coincidencias al escribir en el input y de redirigir a la sección de búsqueda en donde muestra las coincidencias encontradas.
 * @returns {React.Component}
 */
export const SearchInput = () => {
    const {textosSubSecciones:textos} = useObtenerSubsecciones('busqueda_avanzada');
    const [text, setText] = useState('');
    const [shouldShowMatchesList, setShouldShowMatchesList] = useState(false);
    const searchManager = useSearchBooksManager({ text });
    const matches = Array.isArray(searchManager?.matches) ? searchManager?.matches : [];
    const history = useHistory();
    const advancedSearchManager = useAdvancedSearch();
    const formRef = useRef(null);


    const onSubmit = (e) => {
        e.preventDefault();
        search(text);
    }

    const search = (_text) => {
        history.push({
            pathname: '/busqueda',
            search: `?sch=${encodeURIComponent(_text)}`
        })
        advancedSearchManager.setVisible(false);
    }

    useEffect(() => {
        setShouldShowMatchesList(true);
    }, [text])


    useEffect(() => {
        let timer = null;
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                timer = setTimeout(() => {
                    setShouldShowMatchesList(false);
                }, 70);
            } else {
                setShouldShowMatchesList(true);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            clearTimeout(timer)
        };
    }, [formRef]);

    return (<form ref={formRef} className="w-100" onSubmit={onSubmit}>
        <div className="search-input-wrapper focusable-by-children-primary">
            <button className="focusable-primary onsecondary--color" type="submit">
                <Icon icon="mingcute:search-2-fill" width={40} />
            </button>

            <input
                className="focusable-primary search-input"
                value={text}
                placeholder={_.get(textos, `header.input_busqueda.placeholder`) || 'Búsqueda por título, tema, etiqueta, autor, editorial...'}
                onChange={(e) => setText(e?.target?.value)}
            />
            {
                
                <button
                    type="button"
                    aria-label="Limpiar/Clear"
                    className={`focusable-primary clear-button ${text && text?.trim().length > 0 ?'d-flex':'d-none'}`}
                    onClick={() => setText('')}>
                    <Icon icon="iconamoon:close-bold" />
                </button>
            }
            {
                shouldShowMatchesList && text && text?.trim().length > 0 &&
                <ul className="matches-list">
                    {
                        searchManager?.loading ?
                            <>
                                <li className="p-2 w-100 text-center secondary-inverse--color">
                                    <Icon icon="icomoon-free:spinner9" width={30} className={'spinner-rotation'} />
                                </li>
                            </>
                            :
                            <>
                                {/* {matches?.length <= 0 &&
                                    <li className="p-2 w-100 text-center onbackground-primary--color font-weight-bold">{_.get(textos, `header.input_busqueda.sin_resultados`) || 'Sin resultados'}</li>
                                } */}
                                {matches?.map((match,i) => {
                                    return <li key={`${match?.title}-${i}`}>
                                        <button type="button" className="focusable-primary match-item"
                                            onClick={() => search(match?.title)}>
                                            {match?.title || 'N/A'}
                                        </button>
                                    </li>
                                })}
                            </>
                    }
                </ul>
            }

        </div>
    </form>

    )
}


