
export const TRAINING_DATA = [
  {
    alt: 'imagen plan de acompanamiento',
    src: '/img/bibliotecas-que-es/plan_acompanamiento.png',
    width: '100px',
    text: {
        default: 'Plan anual de lecturas con libros recomendados',
        translationPath: 'acompanamiento.capacitacion'
    }
  },
  {
    alt: 'imagen marketing',
    src: '/img/que-es-lector/curso-online.png',
    width: '100px',
    text: {
        default: 'Métricas de lectura detalladas',
        translationPath: 'acompanamiento.curso'
    }
  },
  {
    alt: 'imagen marketing',
    src: '/img/bibliotecas-que-es/marketing.png',
    width: '150px',
    text: {
        default: 'Guías de lectura asociadas a un creciente número de libros de nuestra colección',
        translationPath: 'acompanamiento.guias'
    }
  },
  {
    alt: 'icono eventos virtuales',
    src: '/img/bibliotecas-que-es/eventos_virtuales.png',
    width: '130px',
    text: {
        default: 'Eventos virtuales en vivo con autores y expertos  en lectura y educación',
        translationPath: 'acompanamiento.eventos'
    }
  },
]