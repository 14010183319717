import axios from "axios";
import { GENERAL_URL_API } from "../../components/Store";

/**
 * Este servicio devuelve un arreglo con las entradas del blog.
 * @param {{abortSignal:AbortSignal}} param0 
 * @returns {Promise<[]>} retorna un arreglo con las entradas.
 */
const getPosts = async ({ abortSignal = null }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const response = await axios({
			method: "POST",
			url: `assets/listaBlogs`,
			signal: abortSignal || null,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		});
		const datos = response.data;
		if (datos === "Error") {
			return new Error(datos);
		}
		return datos && Array.isArray(datos?.entradas) ? datos?.entradas : [];
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * Este servicio devuelve un arreglo con los años que contienen entradas del blog por mes
 * @param {{abortSignal:AbortSignal}} abortSignal 
 * @returns {Promise<{}>} retorna un objeto con los años 
 */
const getMonths = async ({ abortSignal = null }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const response = await axios({
			method: "POST",
			url: `assets/listaBlogs`,
			signal: abortSignal || null,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		});
		const datos = response.data;
		if (datos === "Error") {
			return new Error(datos);
		}
		return datos && typeof datos?.acordeon === "object" ? datos?.acordeon : {};
	} catch (error) {
		return Promise.reject(error);
	}
}

/**
 * Este servicio devuelve un arreglo con los tags del blog.
 * @param {{abortSignal:AbortSignal}} abortSignal 
 * @returns {Promise<[]>} retorna un arreglo con los tags
 */
const getTags = async ({ abortSignal = null }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const response = await axios({
			method: "POST",
			url: `assets/listaBlogs`,
			signal: abortSignal || null,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		});
		const datos = response.data;
		if (datos === "Error") {
			return new Error(datos);
		}
		return datos && Array.isArray(datos?.tags) ? datos?.tags : [];
	} catch (error) {
		return Promise.reject(error);
	}
}


/**
 * Este servicio devuelve un arreglo con las entradas filtradas por sch o por un tag.
 * @param {{abortSignal:AbortSignal}} abortSignal 
 * @param {object<{
 * 	sch: string,
 * 	tags: string
 * }>} data  Objeto con los datos de búsqueda
 * @returns {Promise<[]>} retorna un arreglo con las entradas.
 */
const searchPost = async ({ abortSignal = null, data }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;
		
		const response = await axios({
			method: "POST",
			url: `assets/listaBlogs${data}`,
			signal: abortSignal || null,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
		});

		const datos = response.data;

		if (datos === "Error") {
			return new Error(datos);
		}
		return datos && Array.isArray(datos?.entradas) ? datos?.entradas : [];
	} catch (error) {
		return Promise.reject(error);
	}
};


const dataBlog = async ({ abortSignal = null, id }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;
		
		const idBlog = parseInt(id);

		const response = await axios({
			method: "POST",
			url: `assets/datosBlog?idBlog=${idBlog}`,
			signal: abortSignal || null,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
		});

		const datos =  response.data;

		if (datos === "Error") {
			return new Error(datos);
		}
		return datos && typeof datos === "object" ? datos : {};
	} catch (error) {
		return Promise.reject(error);
	}
};

const contentBlog = async ({ abortSignal = null, id }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;
		
		const idBlog = parseInt(id);
		const response = await axios({
			method: "POST",
			url: `assets/contenidoBlog?idBlog=${idBlog}`,
			signal: abortSignal || null,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
		});

		const datos = response.data;

		if (datos === "Error") {
			return new Error(datos);
		}
		return datos;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const BlogAPI = {
	getPosts,
	getMonths,
	getTags,
	searchPost,
	dataBlog,
	contentBlog
};
