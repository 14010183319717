import { useContext, useEffect, useState } from "react";
import { NotificacionesAPI } from "../../services/api/notificaciones";
import { store } from "../../components/Store";
import _ from "lodash";
import moment from "moment";
import { useSeenNotificationStorage } from "./use-notitication-storage";

/**
 * Hook personalizado para gestionar las notificaciones.
 * 
 * Este hook proporciona funciones y estados relacionados con la gestión de notificaciones.
 * 
 * @param {Object} props - Opciones para el hook.
 * @param {number} props.itemsPerPage - Número de elementos por página. Por defecto es 3.
 * 
 * @returns {{size: number, notifications: Array, loadMore: Function, hasMore: boolean, markAsSeen: Function, hasNewNotifications: boolean, fetchingCompleted: boolean}} Objeto con las siguientes propiedades:
 * - size: Número total de notificaciones.
 * - notifications: Notificaciones paginadas.
 * - loadMore: Función para cargar más notificaciones.
 * - hasMore: Indica si hay más notificaciones disponibles para cargar.
 * - markAsSeen: Función para marcar una notificación como vista.
 * - hasNewNotifications: Indica si hay nuevas notificaciones sin ver.
 * - fetchingCompleted: Indica si la carga de notificaciones ha finalizado.
 */
export const useNotifications = ({ itemsPerPage = 3 }) => {
	const storageManager = useSeenNotificationStorage();
	const globalState = useContext(store);
	const isAuth = globalState?.state?.sesionActiva === "SI";
	const institutionUsername = globalState?.state?.username;
	const notifications = globalState?.state?.notifications?.data;
	const loaded = globalState?.state?.notifications?.loaded;
	const hasNewNotifications = globalState?.state?.notifications?.newNotifications;
	const [paginatedData, setPaginatedData] = useState(notifications.slice(0, itemsPerPage));
	const totalDataLength = notifications.length;
	const paginatedDataLength = paginatedData.length;
	const [currentPage, setCurrentPage] = useState(1);
	const [hasMore, setHasMore] = useState(false);

	const fetchData = async () => {
		if (loaded !== true) {
			await NotificacionesAPI.getAll({})
				.then((res) => {
					const _notifications = res
						.filter((notification) => {
							const startDate = moment(notification?.fechaVisibilidad?.inicio);
							const endDate = moment(notification?.fechaVisibilidad?.fin);
							const today = moment(moment().format("YYYY-MM-DD"));
							const isBetweenDates = today.isBetween(
								startDate,
								endDate,
								undefined,
								"[]"
							);
							return isBetweenDates;
						})
						.filter((notification)=>{
							return notification?.metadatos?.titulo?.es && notification?.metadatos?.titulo?.en
						})
						.map((noti) => {
							return {
								...noti,
								isSeen: storageManager.hasNotification({
									isAuth,
									institutionName: institutionUsername,
									id: noti?.id,
								}),
							};
						});

					_notifications.sort((a, b) => {
						const dateA = moment(a.fechaPublicacion);
						const dateB = moment(b.fechaPublicacion);
						if (dateA.isBefore(dateB)) {
							return 1;
						}
						if (dateA.isAfter(dateB)) {
							return -1;
						}
						return 0;
					});

					globalState.dispatch({
						type: "CHANGE",
						path: "notifications.data",
						value: _notifications,
					});
				})
				.catch((error) => console.log(error))
				.finally(() => {
					globalState.dispatch({
						type: "CHANGE",
						path: "notifications.loaded",
						value: true,
					});
				});
		}
	};

	useEffect(() => {
		if (loaded) {
			setPaginatedData(notifications.slice(0, itemsPerPage * currentPage));
		}
	}, [notifications, loaded, currentPage, itemsPerPage]);

	useEffect(() => {
		if (Array.isArray(notifications)) {
			const hasNew = notifications.find((noti) => noti.isSeen === false);
			globalState.dispatch({
				type: "CHANGE",
				path: "notifications.newNotifications",
				value: hasNew ? true : false,
			});
		}
	}, [notifications]);

	/**
	 * Verificación y actualización de la información de notificaciones cada minuto.
	 */
	useEffect(() => {
		fetchData();
		let interval = setInterval(() => {
			fetchData();
		}, 60 * 1000);
		return ()=>{
			clearInterval(interval)
		}
	}, []);

	useEffect(() => {
		if (loaded) {
			setHasMore(paginatedDataLength < totalDataLength);
		} else {
			setHasMore(false);
		}
	}, [loaded, totalDataLength, paginatedDataLength]);

	const loadMore = () => {
		if (currentPage < Math.ceil(totalDataLength / itemsPerPage)) {
			setCurrentPage((page) => page + 1);
			setPaginatedData(notifications.slice(0, paginatedData.length + itemsPerPage));
		}
	};

	const markAsSeen = ({ id }) => {
		const updatedNotifications = Array.isArray(notifications) ? _.cloneDeep(notifications) : [];
		const index = updatedNotifications.findIndex((noti) => noti?.id === id);
		if (index >= 0) {
			updatedNotifications[index].isSeen = true;
			globalState.dispatch({
				type: "CHANGE",
				path: "notifications.data",
				value: updatedNotifications,
			});
		}
	};

	return {
        size:notifications?.length || 0,
		notifications: paginatedData,
		loadMore,
		hasMore: hasMore,
		markAsSeen,
		hasNewNotifications,
		fetchingCompleted: loaded,
	};
};
