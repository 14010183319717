import React, { useRef, useState } from "react";
import { ContentHeader } from "./components/content-header";
import { CommunityNav } from "../components/community/community-nav";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";
import { useTranslations } from "../hooks/use-translations";
import _ from "lodash";
import { CommunityDemoRequest } from "../components/community/demo-request";
import { PrimarySection } from "./components/primary-section";
import { SecondarySection } from "./components/secondary-section";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

export const ResourcesCenter = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "centro_recursos", lang: lang });
	const mainSectionRef = useRef();
	const primarySectionRef = useRef();
	const secondSectionRef = useRef();
	const SECTIONS = [
		{
			id: "primary-section",
			title: _.get(texts, "nav.item.corre_voz") || "Corre la voz",
			ref: primarySectionRef,
		},
		{
			id: "secondary-section",
			title: _.get(texts, "nav.item.aprende_sobre_makemake") || "Aprende todo sobre MakeMake",
			ref: secondSectionRef,
		},
	];

	const defaulSection = SECTIONS[0];
	const [currentSectionId, setCurrentSectionId] = useState(defaulSection.id);

	const goToSection = ({ref, smooth}) => {
		if (ref?.current) {
			const element = ref?.current;
			element.scrollIntoView({ behavior: smooth?"smooth":"auto", block: "start" });
		}
	};

	return (
		<div className="resources-center w-100 min-vh-100" ref={mainSectionRef}>
			<Helmet>
				<title>{texts?.metadata?.title || 'MakeMake - Centro de recursos'}</title>
				<meta name="description" content={texts?.metadata?.description || 'El centro de recursos reúne material de marketing y tutoriales de uso para máximizar el uso de las colecciones.'} />
			</Helmet>

			<ContentHeader />
			<CommunityNav.Container>
				{SECTIONS.map((section, i) => {
					return (
						<CommunityNav.Item
							key={i}
							title={section.title}
							onClick={() => {
								// setCurrentSectionId(section.id);
								goToSection({ref:section.ref, smooth:true});
							}}
							// active={section.id === currentSectionId}
						/>
					);
				})}
			</CommunityNav.Container>
			<div className="container p-0 my-3 mt-4">
				<Link to={'/club-makemake'} className="d-inline align-items-center justify-content-center bold text-26 secondary-inverse--color"> <Icon className=" mr-2" icon="material-symbols:arrow-back" width={40} />{lang === 'en'?'Community':`Comunidad`}</Link>
			</div>
			<PrimarySection ref={primarySectionRef} />
			<CommunityDemoRequest />
			<SecondarySection ref={secondSectionRef} />
		</div>
	);
};
