import React, { useEffect, useState } from "react";
import { MORE_PLANS } from "../data/more-plans";
import useIsMobile from "../../../js/utilities/useIsMobile";
import ReactTooltip from "react-tooltip";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

export const MorePlans = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const texts = textos?.planes_alternativos;

	const plans = [...MORE_PLANS];
	return (
		<div className="more-plans primary--bg onprimary--color py-5">
			<div className="container">
				<div className="d-flex flex-column justify-content-center align-items-center text-center">
					<h1 className="secondary-font">
						{texts?.titulo || "MakeMakte te ofrece más opciones "}
					</h1>
				</div>
			</div>
			<div className="w-100 d-flex justify-content-center align-items-center">
				<div className={`container`} style={{ maxWidth: 1400, margin: "auto" }}>
					<div className="row justify-content-center p-0 m-0 h-100">
						{plans?.map((plan, i) => {
							return (
								<div key={i} className="col-12 col-sm-9 col-md m-1 my-3">
									<AlternativePlanCard key={i} data={plan} />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export const AlternativePlanCard = ({ data }) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const texts = textos?.planes_alternativos?.plan;
	const [expandedFeatures, setExpandedFeatures] = useState(false);
	const mobile = useIsMobile();
	const features = Array.isArray(data?.features) ? data.features : [];

	useEffect(() => {
		if (mobile === false) {
			setExpandedFeatures(true);
		} else {
			setExpandedFeatures(false);
		}
	}, [mobile]);

	return (
		<div className="alternative-plan-card">
			<div className="row p-0 m-0 h-100">
				<div
					className="col-12 col-xl-6 d-flex flex-column justify-content-between"
					style={{ padding: "1.4em" }}
				>
					<div>
						<h2 className="title secondary-font font-weight-bold my-3">
							{_.get(texts, data?.title?.translationPath) ||
								data?.title?.default ||
								"N/A"}
						</h2>
						<p className="font-weight-bold" style={{ fontSize: "0.9em" }}>
							{_.get(texts, data?.description?.translationPath) ||
								data?.description?.default ||
								"N/A"}
						</p>
					</div>
					{mobile && (
						<div className="onbackground-primary--color mt-3">
							<div className="onbackground-primary--bg w-100" style={{ height: 1 }} />
							<button
								className="my-3 font-weight-bold d-flex justify-content-between w-100 focusable-red"
								onClick={() => setExpandedFeatures(!expandedFeatures)}
							>
								<span> {texts?.mobile?.expandible || "Ver característicass"}</span>
								<Icon
									className={`mx-2 dropdown-arrow-transition ${
										expandedFeatures
											? "expanded-dropdown"
											: "collapsed-dropdown"
									}`}
									icon="fe:arrow-down"
									width={"1.5em"}
								/>
							</button>
						</div>
					)}
					<ul className={`features py-2 ${expandedFeatures ? "visible" : "hidden"}`}>
						{features?.map((feature, i) => {
							return (
								<li key={i} className="feature my-2 row p-0 mx-0">
									<div className="d-flex justify-content-center align-items-center">
										<Icon
											className=""
											icon="mingcute:check-fill"
											width={"1em"}
										/>
									</div>
									<span className="col">
										{_.get(texts, feature?.description?.translationPath) ||
											feature?.description?.default ||
											"N/A"}
									</span>
									{feature?.tooltip && (
										<>
											<span
												data-for={`tooltip-${i}`}
												data-tip={
													_.get(
														texts,
														feature?.tooltip?.translationPath
													) ||
													feature?.tooltip?.default ||
													"N/A"
												}
												style={{
													cursor: "pointer",
													transform: "scale(1.5)",
												}}
											>
												<i className="ml-2 fas fa-question-circle onbackground-third--color"></i>
											</span>

											<ReactTooltip
												className={"tooltiptext"}
												id={`tooltip-${i}`}
												place="bottom"
											/>
										</>
									)}
								</li>
							);
						})}
					</ul>
					<div className="d-flex flex-column flex-lg-row p-0 m-0 my-3">
						<Link
							className="focusable-red flex-grow-1 btn btn-primary my-1 mx-0 mr-lg-2 p-1"
							to={data?.moreInfoUrl}
						>
							{texts?.boton || "Conoce los planes"}
							<Icon className="mx-2" icon="maki:arrow" width={"1.5em"} />
						</Link>
					</div>
				</div>
				{mobile === false && (
					<div className="d-none d-xl-flex  justify-content-end align-items-end col-xl-6 p-0 m-0 position-relative">
						<div className="image w-100 h-100 d-flex justify-content-end align-items-end">
							<img
								className="w-100 accesible-image"
								style={{ height: "90%" }}
								src={data?.imgUrl}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
