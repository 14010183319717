import React, { useContext, useState, useEffect, useRef } from "react";
import { store, ASSETS_URL } from "../../Store";
import { Link, useLocation, useHistory } from "react-router-dom";

import { useQueryParams } from "../../../hooks/useQueryParams";
import { useAdvancedSearch } from "../../../hooks/advanced-search/use-advanced-search";
import { CancelRequestController } from "../../../util/cancel-request-controller";
import { DatosFormInstitutoAPI } from "../../../services/api/LoginInstitucional";
import { AccesibilidadContext } from "../../../contexts/AccesibilidadContext";
import useIsMobile from "../../../js/utilities/useIsMobile";

import { ExploreIcon } from "./explore-icon";
import { useNetworkStatus } from "../../../hooks/use-network-status";

/**
 * Componente que retorna el logo de la institución y el icono de home
 * @returns {JSX.Element} NavbarBrand
 */
export const NavbarBrand = ({ textosInterfaz }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const accebilidadState = useContext(AccesibilidadContext);

  const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
  const isIndividualUser = state?.cantidadLibros <= 40 && globalState?.state?.cantidadLibros >= 0;
  const location = useLocation();
  const params = useQueryParams();
  const history = useHistory();
  const isMobile = useIsMobile();
  const networkStatus = useNetworkStatus();

  const [linkInstitucional, setLinkInstitucional] = useState(false);
  const [infoInstitucional, setInfoInstitucional] = useState({});
  const [esTemaClasico, setEsTemaClasico] = useState(true);
  const [colorOverIcon, setColorOverIcon] = useState(false);
  const [buscarOver, setBuscarOver] = useState(false);

  const advancedSearch = useAdvancedSearch();
  const advancedSearchButtonRef = useRef(null);


  /*Actualiza el estado de EsTemaClasico. */
  useEffect(() => {
    let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
    setEsTemaClasico(tema);
  }, [accebilidadState])

  useEffect(() => {
    const institucion = params.get("inst");
    if (!!institucion) {
      setLinkInstitucional(true);
      const instituto = decodeURIComponent(institucion);

      datosInstitucionales({ instituto });
    } else {
      setLinkInstitucional(false);
    }
  }, [params]);

  /*Asigna el botón que abre las opciones de búsqueda */
  useEffect(() => {
    if (advancedSearchButtonRef.current) {
      globalState.dispatch({
        type: "CHANGE",
        path: "advancedSearch.triggerButton",
        value: advancedSearchButtonRef,
      });
    }
  }, [advancedSearchButtonRef]);


  /* Función que obtiene la información de dicha institución.*/
  const datosInstitucionales = async ({ instituto }) => {
    let datos = {};
    let abortController = new CancelRequestController();
    await DatosFormInstitutoAPI.obtenerDatos({
      abortSignal: abortController.signal,
      data: instituto,
    })
      .then((respuesta) => {
        if (respuesta.redirect === 1) {
          history.push(respuesta.url);
        } else {
          for (const key in respuesta) {
            const value = respuesta[key];
            datos[key] = value === "null" || value === "" ? null : value;
          }
          setInfoInstitucional({
            ...datos,
            instituto: instituto,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Link
        to={"/"}
        className="logo px-2 pt-1 focusable-red"
        style={{
          background: isLogged ?
            isIndividualUser ?
              '#262626'
              :
              esTemaClasico ?
                state?.datosHeader?.colorHeader !== null ?
                  state?.datosHeader?.colorHeader
                  :
                  "#262626"
                :
                '#262626'
            :
            linkInstitucional ?
              esTemaClasico ?
                infoInstitucional?.data?.colorHeader
                :
                '#262626'
              :
              '#262626'
        }}
      >
        <img
          alt="Logo institución"
          src={isLogged && state.datosHeader?.logo != null ?
            `${ASSETS_URL}${state.datosHeader?.logo}`
            :
            linkInstitucional ?
              `${ASSETS_URL}${infoInstitucional?.data?.logo}`
              :
              '/img/logo-makemake.png'
          }
        />
      </Link>
      {
        !isMobile &&
        <Link
          to={"/"}
          className={`focusable-red 
            ${isLogged && !location.pathname.includes('/visor') && state.cantidadLibros !== "NULL" && state.cantidadLibros > 40 ?
              location.pathname === '/que-es' || location.pathname === '/home' ?
                'icono-general-home ml-3 activeMode'
                :
                "icono-general-home ml-3"
              :
              location.pathname === '/que-es' || location.pathname === '/home' ?
                'icono-home ml-3 activeMode'
                :
                'icono-home ml-3'
            }
          `}

          style={{
            borderBottomColor: isLogged && !location.pathname.includes('/visor') && state.cantidadLibros !== "NULL" && state.cantidadLibros > 40 && esTemaClasico ? state?.datosHeader?.colorLetra !== null ? state?.datosHeader?.colorLetra : 'var(--onsecondary-color)' : 'var(--primary-inverse)'
          }}
          onMouseOver={() => setColorOverIcon(true)}
          onMouseLeave={() => setColorOverIcon(false)}
        >
          <ExploreIcon colorLetra={
            isLogged && !location.pathname.includes('/visor') && state.cantidadLibros !== "NULL" && state.cantidadLibros > 40 ?
              esTemaClasico ? state.datosHeader?.colorLetra !== null ? state?.datosHeader?.colorLetra : 'var(--onsecondary-color)' : 'var(--primary-inverse)'
              :
              location.pathname === '/que-es' || location.pathname === '/home' ?
                'var(--primary-inverse)'
                :
                colorOverIcon ?
                  'var(--primary-inverse)'
                  :
                  'var(--onsecondary-color)'} />
        </Link>
      }

      {
        !isMobile && isLogged && networkStatus.online && !location.pathname.includes('/visor') && state.cantidadLibros !== "NULL" && state.cantidadLibros > 40 &&
        <div className={`btn-buscar mx-2 
          ${isLogged && !location.pathname.includes('/visor') && state.cantidadLibros !== "NULL" && state.cantidadLibros > 40 ?
            buscarOver ?
              "btn-buscar"
              :
              "btn-buscar-general-home"
            :
            "btn-buscar"
          }
        `}>
          <button
            ref={advancedSearchButtonRef}
            className={`focusable-red ${advancedSearch?.state?.visible && 'activeMode'}`}
            onClick={() => {
              advancedSearch.setVisible(!advancedSearch?.state?.visible);
            }}

            onMouseOver={() => setBuscarOver(true)}
            onMouseLeave={() => setBuscarOver(false)}

            style={{
              color: isLogged && !location.pathname.includes('/visor') && state.cantidadLibros !== "NULL" && state.cantidadLibros > 40 ?
                (buscarOver || advancedSearch?.state?.visible) ?
                  ""
                  :
                  esTemaClasico ?
                    state.datosHeader?.colorLetra !== null ?
                      state.datosHeader?.colorLetra
                      :
                      'var(--onsecondary-color)'
                    :
                    'var(--primary-inverse)'
                :
                "",
              borderColor: isLogged && !location.pathname.includes('/visor') && state.cantidadLibros !== "NULL" && state.cantidadLibros > 40 ?
                advancedSearch?.state?.visible ?
                  " var(--onprimary-inverse)"
                  :
                  esTemaClasico ?
                    state.datosHeader?.colorLetra !== null ?
                      state.datosHeader?.colorLetra
                      :
                      'var(--onsecondary-color)'
                    :
                    'var(--primary-inverse)'
                :
                "",
            }}
          >
            <i className="d-none d-xl-inline-block search-icon fas fa-search mr-2" aria-hidden="true"></i>
            {textosInterfaz?.buscar || "Buscar"}
          </button>
        </div >
      }

    </>
  )
}