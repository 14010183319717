export	const READING_LEVELS = [
    {
        id: "Primeros Lectores",
        name: {
            default: "Primeros lectores",
            translationPath: "planes.niveles.primeros_lectores",
        },
    },
    {
        id: "Lectores intermedios",
        name: {
            default: "Lectores intermedios",
            translationPath: "planes.niveles.lectores_intermedios",
        },
    },
    {
        id: "Lectores avanzados",
        name: {
            default: "Lectores avanzados",
            translationPath: "planes.niveles.lectores_avanzados",
        },
    },
];