import { SEEN_NOTIFICATIONS_STORAGE_KEY } from "../../config/constants";

/**
 * Hook personalizado para gestionar el almacenamiento de notificaciones vistas.
 *
 * Este hook proporciona funciones para interactuar con el almacenamiento local de notificaciones vistas.
 *
 * @returns {{hasNotification: Function, getAllSeenNotifications: Function, saveSeenNotification: Function}} Objeto con las siguientes propiedades:
 * - hasNotification: Función para verificar si una notificación ha sido vista.
 *                    @param {Object} options - Opciones para la verificación.
 *                    @param {boolean} options.isAuth - Indica si el usuario está autenticado.
 *                    @param {string} options.institutionName - Nombre de la institución.
 *                    @param {string} options.id - Identificador de la notificación.
 *                    @returns {boolean} Devuelve true si la notificación ha sido vista, de lo contrario, false.
 * - getAllSeenNotifications: Función para obtener todas las notificaciones vistas.
 *                             @param {Object} options - Opciones para la obtención.
 *                             @param {boolean} options.isAuth - Indica si el usuario está autenticado.
 *                             @param {string} options.institutionName - Nombre de la institución.
 *                             @returns {Array} Devuelve un array con los identificadores de las notificaciones vistas.
 * - saveSeenNotification: Función para guardar una notificación como vista.
 *                          @param {Object} options - Opciones para guardar la notificación vista.
 *                          @param {boolean} options.isAuth - Indica si el usuario está autenticado.
 *                          @param {string} options.institutionName - Nombre de la institución.
 *                          @param {string} options.id - Identificador de la notificación vista.
 */
export const useSeenNotificationStorage = () => {
	const DEFAULT_INSTITUTION_NAME = "NONE";

	const getStorage = () => {
		try {
			return JSON.parse(localStorage.getItem(SEEN_NOTIFICATIONS_STORAGE_KEY)) || {};
		} catch (error) {
			console.error("Error parsing storage:", error);
			return {};
		}
	};

	const setStorage = (data) => {
		try {
			localStorage.setItem(SEEN_NOTIFICATIONS_STORAGE_KEY, JSON.stringify(data));
		} catch (error) {
			console.error("Error setting storage:", error);
		}
	};

	const hasNotification = ({ isAuth, institutionName, id }) => {
		const storage = getStorage();
		const institution = storage[isAuth ? institutionName : DEFAULT_INSTITUTION_NAME];
		return Array.isArray(institution) && institution.includes(id);
	};

	const getAllSeenNotifications = ({ isAuth, institutionName }) => {
		const storage = getStorage();
		const institution = storage[isAuth ? institutionName : DEFAULT_INSTITUTION_NAME];
		return Array.isArray(institution) ? institution : [];
	};

	const saveSeenNotification = ({ isAuth, institutionName, id }) => {
		const storage = getStorage();
		const name = isAuth ? institutionName : DEFAULT_INSTITUTION_NAME;
		const institution = storage[name];
		if (institution) {
			const notificationsIds = Array.isArray(institution) ? [...institution] : [];
			if (!notificationsIds.includes(id)) {
				notificationsIds.push(id);
			}
			storage[name] = notificationsIds;
		} else {
			storage[name] = [id];
		}
		setStorage(storage);
	};

	return {
		hasNotification,
		getAllSeenNotifications,
		saveSeenNotification,
	};
};
