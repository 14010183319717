import React, { useContext, useState, useEffect, useCallback } from "react";
import normalize from "../js/utilities/normalizeString";
import { Link, useParams } from "react-router-dom";
import useIsMobile from "../js/utilities/useIsMobile";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { store } from '../components/Store.js';

/**
 * Este componente no recibe Props. Su principal función es renderizar el menú de la sección de mi panel. Este define el estilo del menú dependiendo del dispositivo o tamaño de pantalla. También determina la opción activa. 
 * @returns MenuPanel
 */
const MenuPanel = () => {

    const globalState = useContext(store);
    const { state } = globalState;
    const userType = state?.tipo;
    const { isReady, textosSubSecciones: textosMenuPanel } = useObtenerSubsecciones('mi_cuenta');
    const isMobile = useIsMobile();
    const [opcion, setOpcion] = useState();
    const [dropMenu, setDropMenu] = useState(false);
    const [height, setHeight] = useState()
    const [categorias, setCategorias] = useState(['Mi cuenta', 'Mi colección', 'Estadísticas', 'Recursos', 'Soporte']);
    const { section } = useParams();

    useEffect(() => {
        if(userType === 'Individual'){
            const temporal = categorias.filter((item) => item != "Recursos")
            setCategorias(temporal)
        }
        categorias.map(item => {
            if (section === normalize(item)) {
                cambiarOpcion(item);
            }
        })
    }, [section, userType])

    /**
     * Esta función se encarga de actualizar la altura de las opciones del menú cuando se encuentra en modo Mobile.
     */
    const boxDropDown = useCallback(node => {

        const heightDropDown = () => {
            if (node !== null) {
                node.style.height = "auto";
                var alto = node.getBoundingClientRect().height;
                setHeight(alto);
                node.style.height = !dropMenu ? 0 : alto;
            }
        }

        heightDropDown();
        window.addEventListener("resize", () => heightDropDown());
        return () => {
            window.removeEventListener("resize", () => heightDropDown());
        }
    }, []);

    /**
     * Esta función actualiza el nombre de la sección activa cuando se esta en estado mobile. 
     * @param {string} value - Nombre de la sección en Menú panel
     */
    const cambiarOpcion = (value) => {
        setDropMenu(false);
        setOpcion(value);
    }

    /**
     * Despliega el menú panel cuando se esta en modo mobile
     */
    const verOpciones = () => {
        setDropMenu(!dropMenu);
    }

    return (
        <>
            {
                isReady && !isMobile && (
                    <ul className={`p-0 row m-0 nav-menu align-items-end  ${userType === 'Individual' ? ' justify-content-start' : ' justify-content-between'}`}>
                        {
                            categorias.map((item, index) => {
                                let url = `/mi-panel/${normalize(item)}`;
                                return (
                                    <li className={`col-lg-2 p-0 ${item === 'Mi colección' && 'collection-step1'} ${item === 'Soporte' && 'collection-step9'} ${userType === 'Individual' ? 'mx-3 ' : null}`} key={`menu${index}`}>
                                        <Link
                                            to={url}
                                            className={`nav-button nav-menu--panel ${section === normalize(item) ? 'nav-menu--active' : 'py-3 py-lg-2'}`}
                                            aria-current={section === normalize(item) ? 'page': false}
                                        >
                                            {textosMenuPanel?.menu[normalize(item)]}
                                        </Link>
                                    </li>
                                )  
                            })
                        }
                    </ul>
                )
            }
            {
                isReady && isMobile && (
                    <div className={`${opcion === 'Mi colección' && 'collection-step1'} p-0 row m-0 justify-content-between nav-menu align-items-end`} >
                        <div
                            className="col-12 nav-menu--responsive d-flex justify-content-between position-relative"
                            onClick={() => { verOpciones() }}
                        >
                            <p className="col-12 text-center">{textosMenuPanel?.menu[normalize(opcion)]}</p>
                            <i
                                className={`fas ${dropMenu ? "fa-caret-up" : "fa-sort-down"} position-absolute`}
                            />
                        </div>
                        <ul className="col-12 px-0 nav-menu-opciones-movil" id={`tourCollection-accordion`} ref={boxDropDown}
                            style={{ height: dropMenu ? height : 0 }}
                        >
                            {
                                categorias.map((item, index) => {
                                    let url = `/mi-panel/${normalize(item)}`;
                                    if (section !== normalize(item)) {
                                        return (
                                            <li key={`menu${index}`} 
                                                className={`${item === 'Soporte' && 'collection-step9'} nav-button-2 ${section === normalize(item) ? 'nav-menu--active' : 'py-3 py-lg-2'}`} 
                                                >
                                                <Link
                                                    to={url}
                                                    onClick={() => { cambiarOpcion(item) }}
                                                >
                                                    {textosMenuPanel?.menu[normalize(item)]}
                                                </Link>
                                            </li>
                                        )
                                    }

                                })
                            }
                        </ul>
                    </div>
                )
            }
        </>
    )

}

export default MenuPanel;