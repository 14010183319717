import { useContext } from "react";
import { store } from "../components/Store";

/**
 * Este hook permite mostrar u ocultar las opciones de accesibilidad,
 * así como tambíen devuelve el estado actual desde el global state.
 * @returns{{
    setVisible:(visible:boolean),
    state:object
 * }}
 */
export const useAccessibility = () => {
    const storeContext = useContext(store);
    const globalState = storeContext?.state;

    const setVisible = (visible) => {
        storeContext.dispatch({
            type: "CHANGE",
            path: "accessibility.visible",
            value: visible ? true : false,
        });
    }

    return {
        setVisible,
        state: globalState?.accessibility
    }
}