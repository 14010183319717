import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LeerMakeMake } from "./leer-makemake";
import { AprenderMakeMake } from "./aprender-makemake";
import { PlanCasaNav } from "./components/plan-casa-nav";
import { DemoRequest } from "../../que-es/home/components/demo-request";
import { CustomPlan } from "./components/custom-plan";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import useIsMobile from "../../js/utilities/useIsMobile";
import { useIntersectionScroll } from "../../hooks/use-intersection-scroll";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

/**
 * Componente principal del plan Casa. Este reune la información de planes y cursos
 * @returns {JSX.Element} Casa
 */
export const Casa = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosTienda = useTranslations({ section: "tienda_makemake", lang: lang });

    const history = useHistory();
    const location = useLocation();

    const isMobile = useIsMobile();

    const [isIntersectingLeer, leerMakeMake] = useIntersectionScroll({ threshold: 0,});
    const [isIntersectingAprender, aprenderMakeMake] = useIntersectionScroll({ threshold: 0,});

    const SECCIONES_PLAN_CASA = [
        {
            id: "leer-makemake",
            url: '/tienda/plan-casa/leer',
            title: textosTienda?.tabpanel?.leer || "Leer en makemake",
            ref: leerMakeMake,
        },
        {
            id: "aprender-makemake",
            url: '/tienda/plan-casa/aprender',
            title: textosTienda?.tabpanel?.aprender || "Aprender algo nuevo",
            ref: aprenderMakeMake,
        }
    ];

    useEffect(() => {
        /**
            dependiendo de la ruta del navegador si coincide con alguna url 
            de las secciones, entonces muestra la sección respectiva
        */
        SECCIONES_PLAN_CASA.map(item => {
            if (location.pathname === item.url) {
                const element = item?.ref.current;

                window.scrollTo({
                    top: isMobile ?  element?.offsetTop - 160 : element?.offsetTop - 125,
                    behavior: "smooth",
                });
            }
        })


    }, [location]);

    const navigateTo = (ref, url) => {
        
        const link = url ? url : '/tienda/plan-casa'

        if (ref?.current) {
            const element = ref?.current;

            window.scrollTo({
                top: isMobile ?  element?.offsetTop - 160 : element?.offsetTop - 125,
                behavior: "smooth",
            });

        } else {
            history.push(link);
        }
    };

    return (
        <>
            <PlanCasaNav.tabpanel>
                {SECCIONES_PLAN_CASA.map((tab, i) => {
                    return (
                        <PlanCasaNav.tab
                            key={i}
                            title={tab.title}
                            active={tab.id === "leer-makemake" ?    isIntersectingLeer : isIntersectingAprender}
                            onClick={() => {
                                navigateTo(tab.ref, tab.url);
                            }}
                        />
                    );
                })}
                <PlanCasaNav.tab></PlanCasaNav.tab>
            </PlanCasaNav.tabpanel>

            <div>
                <div className="container">
                    <div ref={leerMakeMake} className="mt-4">
                        <LeerMakeMake />
                    </div>
                </div>

                <CustomPlan/>
                
                <div className="container">
                    <div ref={aprenderMakeMake} className="mt-4">
                        <AprenderMakeMake />
                    </div>
                </div>
                
                <DemoRequest/>
            </div>
        </>


    )
};