import axios from "axios";
import { GENERAL_URL_API } from "../../components/Store";
import moment from "moment";

/**
 * Este servicio devuelve un arreglo con las entradas del blog.
 * @param {{abortSignal:AbortSignal}} param0
 * @returns {Promise<[]>} retorna un arreglo con las entradas.
 */
const getAll = async ({ abortSignal = null }) => {
	try {
		axios.defaults.baseURL = GENERAL_URL_API;
		axios.defaults.withCredentials = true;

		const response = await axios({
			method: "GET",
			url: `assets/listaEventos`,
			signal: abortSignal || null,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		});
		const datos = response.data;
		if (datos === "Error") {
			return new Error(datos);
		}
		if (datos && Array.isArray(datos?.data)) {
			const events = datos?.data.map((event) => {
				const _event = { ...event };
				delete _event?.["Nombre del evento"];
				delete _event?.["Invitados"];
				return {
					..._event,
					titulo: event["Nombre del evento"],
					invitados:event['Invitados']
				};
			});

			events.sort((a, b) => {
				if (moment(a?.fechasZonas?.co).isAfter(b?.fechasZonas?.co)) {
					return 1;
				}
				if (moment(a?.fechasZonas?.co).isBefore(b?.fechasZonas?.co)) {
					return -1;
				} else {
					return 0;
				}
			});

			return events;
		} else {
			return [];
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const EventosAPI = {
	getAll,
};
