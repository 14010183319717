import React, { useEffect, useState } from "react";
import getUrlParam from './../js/utilities/getUrl';
import { useHistory } from 'react-router-dom';
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 *  Este componente no recibe props. Su principal función es obtener el parametro "em" de la url y renderizar la pantalla de Demo Mensaje
 * @returns DemoMensaje
 */
const DemoMensaje = () => {
  const { isReady, textosSubSecciones: textosDemo } = useObtenerSubsecciones('demo');
  const [correo, setCorreo] = useState('');

  const hitory = useHistory();


  useEffect(() => {
    const em = getUrlParam('em')
    if (!!em) {
      setCorreo(em);
    } else {
      hitory.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      {isReady &&
        <div className="col-lg-8 mx-auto pt-3 pb-4">
          <h2 className="mm-redimir_title mt-3 mb-4 text-center pb-3">
            <span aria-hidden={true}>3</span> {textosDemo?.mensaje?.titulo || 'Revisa tu correo'}
          </h2>

          <p className="mm-redmir_textlogin text-center onbackground-third--color">
            {textosDemo?.mensaje?.descripcion?.parte_uno ||  'En unos días te llegará un correo a'} <strong>{correo}</strong> {textosDemo?.mensaje?.descripcion?.parte_dos || 'con la información para que puedas iniciar sesión. Tan sólo tardará un par de días en llegar.'}
          </p>
          <div className="col-lg-8 mx-auto onbackground-third--color">
            <p className="text-center">{textosDemo?.mensaje?.sugerencia || '¿Tienes alguna sugerencia?. No dudes en escribirnos a'} <a className="secondary-item secondary-inverse--color focusable-secondary" href="mailto:catalina@makemake.com.co"> catalina@makemake.com.co </a></p>
          </div>
        </div>
      }
    </>
  )
}
export default DemoMensaje;