
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Este servicio devuelve los datos relacionados a los datos de la colección de libros de un cliente
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<{
  fecha: string, //fecha Limite
  maxLibros: number, //cantidad de libros devueltos
  definitivos: boolean | null,
  libros: Array<{
    idLibro: string, 
    title: string, 
    portada: string, 
    author: string, 
    webURL: string, 
    edad: string, 
    genero: string, 
    novedad: string, 
    elegido: string
  }>
}
>} retorna un objeto con los datos de la colección de libros
 */
const obtenerListadoLibros = async ({abortSignal=null, filtros})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const formData = new FormData();
        if(typeof filtros==='object' ){
            Object.entries(filtros).forEach(entry=>{
                let value = typeof entry[1] === "boolean"?(entry[1]?"YES":"NO"):entry[1];
                value = Array.isArray(value)?value.join(';'):value;
                formData.append(`${entry[0]}`, value)
            })
        }
        const response = await axios({
          method: 'POST',
          url: 'assets/listadoLibrosParaEscoger',
          signal: abortSignal || null,
          headers:{"Content-Type":"multipart/form-data"},
          data: formData
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos || {};
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}
/**
 * Este servicio devuelve los libros que un usuario tiene agregados al paquete temporal en el estado de selección de libros.
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<{
  fecha: string, //fecha Limite
  maxLibros: number, //cantidad de libros devueltos
  definitivos: boolean | null,
  libros: Array<{
    idLibro: string, 
    title: string, 
    portada: string, 
    author: string, 
    webURL: string, 
    edad: string, 
    genero: string, 
    novedad: string, 
    elegido: string
  }>
}
>} retorna un objeto con los datos de la colección de libros
 */
const obtenerListadoLibrosEscogidos = async ({abortSignal=null})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const response = await axios({
          method: 'POST',
          url: 'assets/listadoLibrosEscogidos',
          signal: abortSignal || null,
          headers:{"Content-Type":"multipart/form-data"},
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos || {};
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}


/**
 * Agrega o elimina libros mediante una solicitud de fetch.
 *
 * @async
 * @param {Object} options - Opciones para la solicitud de fetch.
 * @param {AbortSignal} [options.abortSignal=null] - Objeto AbortSignal para cancelar la solicitud.
 * @param {string} options.booksId - ID de los libros a agregar o eliminar.
 * @param {boolean} options.add - Indica si se agregarán los libros (`true`) o se eliminarán (`false`).
 * @param {boolean} options.selectionFinished - Indica si la selección ha finalizado (`true`) o no (`false`).
 * @returns {Promise<Object|Error>} Promise que resuelve en un objeto de datos o rechaza con un error.
 */
const agregarEliminarLibros = async ({ abortSignal = null, booksId, add, selectionFinished }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('idLibros', booksId)
    formData.append('accion', add?'Agregar':'Borrar')
    formData.append('confirmarSeleccion', selectionFinished ? 1 : 0)
    const response = await axios({
      method: 'POST',
      url: 'assets/escogenciaLibros',
      signal: abortSignal || null,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    })
    const datos = response.data;
    if (datos === 'Error') {
      return new Error(datos);
    }
    return datos || {};

  } catch (error) {
    return Promise.reject(error);
  }

}

/**
 * Confirma un paquete mediante una solicitud de fetch.
 *
 * @async
 * @param {Object} options - Opciones para la solicitud de fetch.
 * @param {AbortSignal} [options.abortSignal=null] - Objeto AbortSignal para cancelar la solicitud.
 * @param {boolean} options.confirmar - Indica si se debe confirmar el paquete (`true`) o no (`false`).
 * @returns {Promise<Object|Error>} Promise que resuelve en un objeto de datos o rechaza con un error.
 */
const confirmarPaquete = async ({ abortSignal = null, confirmar}) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('confirmarSeleccion', confirmar ? 1 : 0)
    formData.append('idLibros', [])
    formData.append('accion', 'Borrar')
    const response = await axios({
      method: 'POST',
      url: 'assets/escogenciaLibros',
      signal: abortSignal || null,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    })
    const datos = response.data;
    if (datos === 'Error') {
      return new Error(datos);
    }
    return datos || {};

  } catch (error) {
    return Promise.reject(error);
  }

}

export const ColeccionLibrosAPI = {
    obtenerListadoLibros,
    obtenerListadoLibrosEscogidos,
    agregarEliminarLibros,
    confirmarPaquete
}