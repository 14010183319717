import React, { useContext } from "react";
import { store } from "../Store.js";
import { LanguageSelector } from "../advanced-search/language-selector.js";
import { ClientFooter } from "./components/client-footer.js";
import { SocialMedia } from "./components/social-media.js";
import { Logos } from "./components/logos.js";
import { FooterLinks } from "./components/footer-links.js";
import { ContactList } from "./components/contact.js";
import { Sponsors } from "./components/sponsors.js";
import { LapizAcero } from "./components/lapiz-acero.js";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country.js";
import { useTranslations } from "../../hooks/use-translations.js";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants.js";

/**
 * Componente de pie de página.
 * @returns {JSX.Element} El elemento del pie de página.
 */
const Footer = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const texts = useTranslations({ section: "footer", lang: lang });
	const globalStateContext = useContext(store);
	const state = globalStateContext?.state;
	const isAuth = state?.sesionActiva !== null && state?.sesionActiva === "SI";
	const footerData = state?.datosHeader || {};

	return (
		<>
			{isAuth && <ClientFooter clientData={footerData} />}
			<footer className="footer">
				<div className="row p-0 m-0">
					<div className="col-12 col-lg-8 col-xl-8 ">
						<div className="w-100 d-flex flex-column justify-content-center align-items-center flex-md-row  justify-content-md-between">
							<Logos />
							<SocialMedia />
						</div>
						<div className="divider my-3"></div>
					</div>
					<div className="col-12 col-md">
						<div className="d-flex w-100 h-100 justify-content-center justify-content-lg-end align-items-center">
							<LanguageSelector />
						</div>
					</div>
				</div>
				<div className="row p-0 m-0 flex-row-reverse">
					<div className="col-12 col-lg-3 col-xl-4">
						<FooterLinks />
						<div className="divider my-3 d-block d-lg-none"></div>
					</div>
					<div className="col-12 col-lg">
						<h3 className="title text-center text-md-left">
							{texts?.representantes?.titulo || "Representantes comerciales"}
						</h3>
						<div className=" d-flex align-items-center justify-content-center">
							<ContactList />
						</div>
					</div>
					<div className="divider my-3 d-block mx-3 d-lg-none"></div>
				</div>
				<div className="row p-0 m-0 mt-3">
					<div className="col-12 col-lg d-flex justify-content-center justify-content-lg-start align-items-center">
						<Sponsors />
					</div>
					<div className="col-12 col-lg d-flex justify-content-center align-items-center align-items-lg-end font-weight-bolder my-5 my-lg-0">
						<p>{texts?.copyright?.titulo || "Todos los derechos reservados"}</p>
					</div>
					<div className="col-12 col-lg d-flex justify-content-lg-end  justify-content-center  align-items-center">
						<LapizAcero />
					</div>
				</div>
				
			</footer>
		</>
	);
};

export default Footer;
