import React, { useContext, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { store } from "../../Store";

import { ExploreIcon } from "../../header/components/explore-icon";

import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { useAccessibility } from "../../../hooks/useAccessibility";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { MobileBottomNavBarNotificationButton } from "../../header/components/notification-button";

/**
 * Componente que contiene las opciones del menu inferior en modo mobile. 
 * Estas opciones son las de la cuenta individual 
 * (explorar, notificaciones y ajustes)
 * @returns {JSX.Element} IndividualMenu
 */
export const IndividualMenu = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });

    const location = useLocation();

    const globalState = useContext(store);
    const accessibility = useAccessibility();
    const accessibilityButtonRef = useRef(null);

    /*Asigna el botón que abre las opciones de accesibilidad */
    useEffect(() => {
        if (accessibilityButtonRef.current) {
            globalState.dispatch({
                type: "CHANGE",
                path: "accessibility.triggerButton",
                value: accessibilityButtonRef,
            });
        }
    }, [accessibilityButtonRef]);

    return (
        <>

            <ul className="d-flex justify-content-center">
                <li className="col-3 px-0">
                    <Link
                        to="/home"
                        className={`btn-option-menu focusable-red ${location.pathname === '/home' ? "activeMode" : ""}`}
                    >
                        <ExploreIcon colorLetra={location.pathname === '/home' ? 'var(--onsecondary-inverse)' : 'var(--onbackground-primary)'} />
                        <span>{textosInterfaz?.explorar || 'Explorar'}</span>
                    </Link>
                </li>
                <MobileBottomNavBarNotificationButton onClick={()=>{}} translations={textosInterfaz}/>
                <li className="col-3 px-0">
                    <button
                        ref={accessibilityButtonRef}
                        className={`btn-option-menu focusable-red ${accessibility.state.visible ? 'activeMode' : ''}`}
                        onClick={() => accessibility.setVisible(true)}
                    >
                        <Icon icon="material-symbols:settings" width="30" />
                        <span>{textosInterfaz?.ajustes || 'Ajustes'}</span>
                    </button>
                </li>
            </ul>
        </>
    )
} 