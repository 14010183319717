import React from "react";
import { Route, Switch } from "react-router";

import { Titles } from "../../components/Titles";
import Error404 from "../../404";
import { LecturasMakeMake } from "./lecturas-makemake";
import { PlanLibroFavorito } from "./libro-favorito";
import { PlanMiColeccion } from "./mi-coleccion";
import { Casa } from "./casa";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

import { Helmet } from "react-helmet";

/**
 * Componente enrutador de las secciones del plan casa
 * @returns {JSX.Element} PlanCasa
 */
export const PlanCasa = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textosTienda = useTranslations({ section: "tienda_makemake", lang: lang });

    return (
        <>
            <Helmet>
                <title>{textosTienda?.metadata?.title || 'MakeMake - Plan casa'}</title>
                <meta name="description" content={textosTienda?.metadata?.description || 'Con el plan casa accede a tus libros favoritos con los diferentes planes de suscripción que ofrece MakeMake.'} />
            </Helmet>

            <Titles
                hexBackground="#FFD103"
                color="onprimary--color"
                title={textosTienda?.tienda?.casa?.titulo || 'Plan Casa'}
                darkShapes
                description={`${textosTienda?.tienda?.casa?.subtitulo || 'Suscríbete a MakeMake'}, ${textosTienda?.tienda?.casa?.descripcion || '¡Explora los paquetes de libros y cursos que tenemos para ti!'}`}
            />

            <div className="plan-casa">
                <Switch>
                    <Route
                        exact
                        path={["/tienda/plan-casa", "/tienda/plan-casa/leer", "/tienda/plan-casa/aprender"]}
                        render={() => <Casa />}
                    />
                    <Route exact path="/tienda/plan-casa/lecturas-makemake" component={LecturasMakeMake} />
                    <Route exact path="/tienda/plan-casa/libro-favorito" component={PlanLibroFavorito} />
                    <Route exact path="/tienda/plan-casa/mi-coleccion" component={PlanMiColeccion} />
                    <Route component={Error404} />
                </Switch>
            </div>
        </>
    )
}