import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { SelectedInstitution } from "./selected-institution";
import { BackButtonHeader } from "./back-button";
import { store } from "../../components/Store";
import { SolicitudesDemoAPI } from "../../services/api/SolicitudesDemo";
import { Icon } from "@iconify/react";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

/**
 * Este componente muestra la pantalla de encontre mi institución pero no puedo ingresar
 * Su principal función es recibir el correo para proporcionar la información de contacto
 */

export const ContactFormInstitution = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	const storeContext = useContext(store);
	const appState = storeContext?.state;
	const isSelectedLibrary = appState?.institutionalAuth?.isSelectedLibrary;

	const history = useHistory();
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	// información de la institución que se seleccionó previamente en el buscador de instituciones.
	const selectedInstitution = appState?.institutionalAuth?.selectedLibrary;
	const [loading, setLoading] = useState(false);
	const emailPattern =
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	const sendData = async (data) => {
		setLoading(true);
		const cancelRequestController = new CancelRequestController();
		await SolicitudesDemoAPI.saveEmail({
			abortSignal: cancelRequestController,
			data: {
				email: data?.email,
				institutoNombre: selectedInstitution?.instituto?.nombre,
			},
		})
			.then((result) => {
				handleInstitutionContactInfo();
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleInstitutionContactInfo = async () => {
		// guardar el correo de la persona que solicita la info en la base de datos luego llevar al usuario a la pantalla de información de contacto.
		history.push({
			pathname: "/institutional-auth/contact/info",
			state: {
				name: `(${selectedInstitution?.instituto?.nombre}) ${selectedInstitution?.nombreSede} `,
				email: selectedInstitution?.instituto?.correo,
				linkTutorial: selectedInstitution?.instituto?.linkTutorial,
			},
		});
	};

	useEffect(() => {
		if (isSelectedLibrary !== true) {
			history.replace("/institutional-auth/search");
		}
	}, [isSelectedLibrary]);

	return (
		<>
			<div className="institutional-auth">
				<div className="row justify-content-center">
					<div className="bg-shape"></div>
					<div className="container page-content">
						<BackButtonHeader />
						<div className="mt-5 row justify-content-center">
							<h1 className="col-12 col-md-8 mt-5 mt-lg-2 text-center onsecondary-title">
								{textos?.no_puedo_ingresar?.formulario?.titulo ||
									"No puedo ingresar"}
							</h1>
							<p className="col-12 col-md-8 text-center onsecondary--color">
								{textos?.no_puedo_ingresar?.formulario?.descripcion ||
									"Encontré mi institución pero no puedo ingresar."}
							</p>
						</div>
						<div className="my-3 row justify-content-center">
							<img
								className="inst-auth-avatar"
								src={"/img/avatares/avatar31.png"}
								width={30}
								height={30}
								alt={"avatar"}
							/>
						</div>
						<div className="my-3 row justify-content-center px-5">
							<SelectedInstitution
								institutionData={{
									name: selectedInstitution?.nombreSede || "N/A",
									city: selectedInstitution?.ciudad || "N/A",
									country: selectedInstitution?.pais || "N/A",
								}}
							/>
						</div>
						<div className="my-3   row justify-content-center">
							<div className="col-9 col-md-6">
								<p className="mt-3 mb-0  text-center onsecondary--color font-weight-bold">
									{textos?.no_puedo_ingresar?.formulario?.titulo_formulario ||
										"Déjanos tus datos"}
								</p>
							</div>
						</div>
						<div className="row justify-content-center">
							<form
								onSubmit={handleSubmit((data) => {
									sendData(data);
								})}
								className="col-10 col-md-6 col-lg-5"
							>
								<label
									className="row text-center justify-content-center"
									htmlFor="mce-EMAIL"
								>
									<span className="col-12 text-left onsecondary--color px-0 py-0 pb-2">
										{textos?.no_puedo_ingresar?.formulario?.input
											?.label_correo || "Correo"}
									</span>
									<input
										type="email"
										className="col-12 text-field form-control focusable-primary"
										placeholder={
											textos?.no_puedo_ingresar?.formulario?.input
												?.placeholder_correo ||
											"Escribe tu correo electrónico"
										}
										{...register("email", {
											required: true,
											pattern: emailPattern,
										})}
										value={watch("email", "")?.trim()}
									/>
								</label>

								{errors?.email && (
									<>
										{errors?.email?.type === "pattern" && (
											<p className="form-field-error">
												{textos?.no_puedo_ingresar?.formulario?.error
													?.correo_invalido || "Correo invalido"}
											</p>
										)}
										{errors?.email?.type === "required" && (
											<p className="form-field-error">
												{textos?.no_puedo_ingresar?.formulario?.error
													?.campo_vacio || "Ingresa tu correo"}
											</p>
										)}
									</>
								)}

								<div className="row justify-content-center my-3">
									<button
										type="submit"
										disabled={loading}
										className={`col-12 col-lg-6 my-3 primary-button focusable-red`}
									>
										{loading ? (
											<Icon
												icon="fa:spinner"
												width={30}
												className={`spinner-rotation`}
											/>
										) : (
											<p>
												{textos?.no_puedo_ingresar?.formulario?.boton
													?.enviar || "Enviar"}
											</p>
										)}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
