import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { COMMUNITY_SERVICES } from "../data/community-services";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper";
import useObtenerSubsecciones from "../../../js/translations/useObtenerSubsecciones";
import useIsMobile from "../../../js/utilities/useIsMobile";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

export const CommunityServiceCard = ({ title, shapeColor, img }) => {
	return (
		<div className="community-service-card m-2 d-flex flex-column justify-content-between secondary-inverse--bordered">
			<p className="title p-3 d-flex align-items-center justify-content-center">
				<span>{title || "N/A"}</span>
			</p>
			<div className="image-container">
				<div className="shape" style={{ backgroundColor: shapeColor }}></div>
				<img className="image flex-grow-1 w-100 h-100" src={img} alt={title || "image"} />
			</div>
		</div>
	);
};

export const CommunityServicesMobile = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const communityText = textos?.comunidad_servicios;

	const [servicesData, setServicesData] = useState([...COMMUNITY_SERVICES]);

	return (
		<Swiper
			className="p-2 pb-5 sample-slider"
			watchSlidesProgress={true}
			centeredSlides={true}
			slidesPerView={"auto"}
			initialSlide={2}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
			mousewheel={{
				forceToAxis: true,
			}}
			pagination={{
				clickable: true,
				enabled: true,
				bulletActiveClass: "onsecondary--bg",
			}}
			modules={[Autoplay, Pagination, Navigation, Mousewheel]}
		>
			{servicesData.map((service, i) => {
				return (
					<SwiperSlide
						key={i}
						className="d-flex justify-content-center align-items-center m-0 p-0"
						style={{ width: 220, height: 300 }}
					>
						<CommunityServiceCard
							key={i}
							title={
								_.get(communityText, service?.title?.translationPath) ||
								service?.title?.default ||
								"N/A"
							}
							img={service?.img}
							shapeColor={service?.shapeColor}
						/>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export const CommunityServices = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const communityText = textos?.comunidad_servicios;

	const [servicesData, setServicesData] = useState([...COMMUNITY_SERVICES]);
	return (
		<Swiper
			className="p-2 pb-5 sample-slider"
			style={{ overflowX: "hidden" }}
			slidesPerView={1}
			slidesPerGroup={1}
			breakpoints={{
				500: {
					slidesPerView: 2,
				},
				680: {
					slidesPerView: 3,
				},
				1000: {
					slidesPerView: 4,
				},
				1200: {
					slidesPerView: 5,
				},
			}}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
			mousewheel={{
				forceToAxis: true,
			}}
			pagination={{
				clickable: true,
				enabled: true,
				bulletActiveClass: "onsecondary--bg",
			}}
			modules={[Autoplay, Pagination, Navigation, Mousewheel]}
		>
			{servicesData.map((service, i) => {
				return (
					<SwiperSlide
						key={i}
						className="d-flex justify-content-center align-items-center m-0 p-0"
						style={{ width: 220, height: 300 }}
					>
						<CommunityServiceCard
							key={i}
							title={
								_.get(communityText, service?.title?.translationPath) ||
								service?.title?.default ||
								"N/A"
							}
							img={service?.img}
							shapeColor={service?.shapeColor}
						/>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export const Community = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const communityText = textos?.comunidad_servicios;
	const isMobile = useIsMobile();

	return (
		<div className="secondary--bg onsecondary--color p-3 py-4">
			<div className="container">
				<div className="d-flex flex-column justify-content-center align-items-center text-center">
					<div style={{ maxWidth: 600 }}>
						<h1 className="my-4 secondary-font" style={{ lineHeight: "1.3em" }}>
							{communityText?.titulo ||
								"Acompañamiento y servicio centrado en los lectores"}
						</h1>
					</div>
				</div>
				{isMobile ? <CommunityServicesMobile /> : <CommunityServices />}
				<div className="d-flex justify-content-center align-items-center">
					<Link
						className="btn primary-onsecondary-button my-4 focusable-red"
						to={"/club-makemake"}
					>
						{communityText?.boton || "Da un vistazo a nuestra comunidad"}
					</Link>
				</div>
			</div>
		</div>
	);
};
