import React, { useContext, useState } from "react";
import { ASSETS_URL, store } from "../../../../components/Store";
import { Icon } from "@iconify/react";
import moment from "moment";
import "moment/locale/es";
import { TimeZones } from "./time-zones";
import { Share } from "./share";
import { ScheduleEvent } from "./schedule-event";
import { useTranslations } from "../../../../hooks/use-translations";
import _ from "lodash";
import { CommunitySignInRequestModal } from "../../../../components/community/community-sign-in-request-modal";
import { useTimeZonesDates } from "../../../../hooks/use-timezones-dates";

export const EventCard = ({ eventData, lang }) => {
	const colombianTimezone = "America/Bogota";
	const date = eventData?.fechasZonas?.co || `${eventData?.fecha} ${eventData?.hora}`;
	const timezonesDates = useTimeZonesDates({
		fullDate: date,
		timeZoneSource: colombianTimezone,
	});

	const globalState = useContext(store);
	const isAuth = globalState?.state?.sesionActiva === "SI";
	const texts = useTranslations({ section: "comunidad_club", lang: lang });
	const [showTimeZoneInfo, setShowTimeZoneInfo] = useState(false);
	const [showShare, setShowShare] = useState(false);
	const [showScheduleEvent, setShowScheduleEvent] = useState(false);
	const eventType = eventData?.tipoEvento?.toLowerCase();
	const isPrivateEvent = eventType?.includes("exclusivo");
	const [showSignInModal, setShowSignInModal] = useState(false);
	const title =
		lang === "en"
			? eventData?.titulo?.en || eventData?.titulo?.es || "N/A"
			: eventData?.titulo?.es || "N/A";
	const description =
		lang === "en"
			? eventData?.descripcion?.en || eventData?.descripcion?.es || "N/A"
			: eventData?.descripcion?.es || "N/A";
	const guests = Array.isArray(eventData?.invitados) ? eventData?.invitados?.join(",") : "N/A";

	const getMainDataByLang = ({ lang }) => {
		const date = eventData?.fechasZonas?.co;
		if (lang === "es") {
			moment.locale("es");
		} else {
			moment.locale("en");
		}
		return {
			month: moment(date).format("MMMM[,] YYYY").charAt(0).toUpperCase() + moment(date).format("MMMM[,] YYYY").slice(1).toLowerCase(),
			day: moment(date).format("DD"),
			dayName: moment(date).format("dddd").charAt(0).toUpperCase() + moment(date).format("dddd").slice(1).toLowerCase(),
			hour: moment(date).format("h:mm A"),
		};
	};

	const mainDateData = getMainDataByLang({ lang });

	// const imageUrl =
	// 	"https://www.ptf.caltech.edu/system/avm_image_sqls/binaries/2/jpg_original/m42.jpg?1433985441";
	const imageUrl = `${ASSETS_URL}${eventData?.imagenUrl}`;
	const eventAvailable =
		eventData?.estadoBoton && eventData?.estadoBoton?.toLowerCase()?.includes("ver");

	const getTimezones = () => {
		const timezones = [
			`${"Colombia"} - ${timezonesDates?.hours?.colombia}`,
			`${"México"} - ${timezonesDates?.hours?.mexico}`,
			`${"Argentina"} - ${timezonesDates?.hours?.argentina}`,
			`${"Chile"} - ${timezonesDates?.hours?.chile}`,
			`${`US (${
				timezonesDates?.timezonesInfo?.usa?.toLowerCase() === "cdt"
					? "Central Daylight Time"
					: "Central Standard Time"
			})`} - ${timezonesDates?.hours?.usa}`,
		];
		return timezones;
	};

	const handleLinkAccess = (link) => {
		if (isAuth === false && isPrivateEvent) {
			setShowSignInModal(true);
			return;
		} else {
			window.open(link, "_blank");
		}
	};

	return (
		<>
			<article
				className="row w-100 p-0 m-0 event-card my-3 onbackground-primary--color"
				style={{ maxWidth: 800, minHeight: 300 }}
			>
				<div className="SECTION-1 col-12 col-md-8 p-0">
					<div style={{ position: "relative" }}>
						<img
							className="img d-none d-md-flex"
							src={imageUrl}
							alt={`${title}-img`}
							loading="lazy"
							width={"100%"}
							height={100}
						/>
						<p className="px-2 py-1 text-12 event-type secondary-font">
							{`${
								isPrivateEvent
									? `${
											_.get(
												texts,
												"makemake_en_vivo.evento.tipo_evento.privado"
											) || "Evento exclusivo"
									  }`
									: `${
											_.get(
												texts,
												"makemake_en_vivo.evento.tipo_evento.publico"
											) || "Evento público"
									  }`
							}`}
						</p>
					</div>
					<div className="px-4 py-3">
						<h3 className="title secondary-font my-2 mt-4 mt-md-2">{title}</h3>
						<h4 className="guests secondary-font">
							{`${_.get(texts, "makemake_en_vivo.evento.invitados") || "Invitado/s"}`}
							: {guests}
						</h4>
						<p className="description my-2">{description}</p>
					</div>
				</div>
				<div className="SECTION-2 col-12 col-md py-3 date-info-container d-flex flex-column align-items-center justify-content-center overflow-hidden">
					<div className="d-flex flex-column align-items-center justify-content-center">
						<p className="secondary-font font-weight-bold">{mainDateData.dayName}</p>
						<p
							className="text-50 font-weight-bolder secondary-font"
							style={{ lineHeight: "1em" }}
						>
							{mainDateData.day}
						</p>
						<p className="secondary-font font-weight-bold">{mainDateData.month}</p>
						<p
							className="text-30 font-weight-bolder secondary-font mt-2"
							style={{ lineHeight: "1em" }}
						>
							{mainDateData.hour}
						</p>
						<button
							className="focusable-red d-flex flex-column"
							onClick={() => setShowTimeZoneInfo(true)}
						>
							<p className="p-0 d-flex align-items-center font-weight-bold secondary-font secondary-inverse--color">
								{`${
									_.get(texts, "makemake_en_vivo.evento.boton.horarios") ||
									"Horarios"
								}`}
								<Icon icon="iconamoon:arrow-right-2-bold" width={22} />
							</p>
						</button>
					</div>
					<div className="my-2 d-flex flex-column">
						<>
							{eventAvailable ? (
								<button
									className="focusable-red watch-button d-flex px-4 py-2 my-1 justify-content-center align-items-center text-center"
									onClick={() => handleLinkAccess(eventData?.link)}
								>{`${
									_.get(texts, "makemake_en_vivo.evento.boton.ver") || "Ver"
								}`}</button>
							) : null}

							{eventAvailable === false ? (
								<button
									className="focusable-red light-button d-flex px-4 py-2 my-1 justify-content-center align-items-center text-center"
									onClick={() => handleLinkAccess(eventData?.link)}
								>{`${
									_.get(texts, "makemake_en_vivo.evento.boton.inscribirse") ||
									"Inscríbete"
								}`}</button>
							) : null}

							<button
								className="focusable-red light-button d-flex px-4 py-2 my-1 justify-content-center align-items-center text-center"
								onClick={() => setShowShare(true)}
							>
								{`${
									_.get(texts, "makemake_en_vivo.evento.boton.compartir") ||
									"Compartir"
								}`}{" "}
								<Icon className="pl-2" icon="gridicons:share" width={30} />
							</button>
						</>
						{eventAvailable === false ? (
							<button
								className="focusable-red light-button d-flex px-4 py-2 my-1 justify-content-center align-items-center text-center"
								onClick={() => setShowScheduleEvent(true)}
							>
								{`${
									_.get(texts, "makemake_en_vivo.evento.boton.agendar") ||
									"Agendar"
								}`}{" "}
								<Icon icon="mingcute:schedule-fill" className="pl-2" width={30} />
							</button>
						) : null}
						<TimeZones
							list={getTimezones()}
							visible={showTimeZoneInfo}
							setVisible={setShowTimeZoneInfo}
						/>
						<Share
							link={eventData?.link || ""}
							description={
								eventAvailable
									? _.get(
											texts,
											"makemake_en_vivo.evento.compartir.link_evento"
									  ) || "Copia y comparte el link del evento."
									: _.get(
											texts,
											"makemake_en_vivo.evento.compartir.inscripcion"
									  ) ||
									  "Copia y comparte el link del formulario de inscripción al evento."
							}
							visible={showShare}
							setVisible={setShowShare}
						/>
						<ScheduleEvent
							eventData={eventData}
							visible={showScheduleEvent}
							setVisible={setShowScheduleEvent}
							lang={lang}
						/>
					</div>
				</div>
			</article>
			<CommunitySignInRequestModal
				visible={showSignInModal}
				setVisible={setShowSignInModal}
				title={texts?.popups?.iniciar_sesion?.titulo || "¡Evento exclusivo!"}
				description={
					texts?.popups?.iniciar_sesion?.descripcion ||
					"Para acceder a este contenido inicia sesión."
				}
				buttonText={texts?.popups?.iniciar_sesion?.boton || "Iniciar sesión"}
				redirectionParam={"/club-makemake?section=makemake-en-vivo"}
			/>
		</>
	);
};
