import React from "react";
import FormLlenarDatos from "../components/FormLlenarDatos";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 *  Este componente no recibe props. Si en el primer paso se selecciona "Mi institución no aparece en la lista", este componente renderizar el segundo paso de demo (Ingresa tus datos). Este hace uso del componente FormLlenarDatos.
 * @returns {JSX.Element} FormLlenarDatos
 */
const DemoIngresarDatos = () => {
  const { isReady, textosSubSecciones: textosDemo } = useObtenerSubsecciones('demo');

  return (
    <>
      {isReady &&
        <div className="pb-5">
          <div className="col-lg-8 mx-auto pt-3 pb-4">
            <h2 className="mm-redimir_title mt-3 mb-4 text-center pb-3"><span aria-hidden={true}>2</span> {textosDemo?.ingresar_datos?.titulo || 'Ingresa tus datos'}</h2>

            <div className="mm-redimir_alerts">
              <p className="mm-redimir_redalert" style={{ display: 'none' }} ></p>
            </div>

            <p className="mm-redmir_text login  onbackground-third--color text-center">
              {textosDemo?.ingresar_datos?.descripcion || 'Esta información nos permitirá conocer acerca de tu interés en MakeMake (a veces te mandaremos novedades y noticias de nuestros libros).'}
            </p>
          </div>

          <FormLlenarDatos textosDemo={textosDemo}/>
        </div>
      }
    </>

  )
}
export default DemoIngresarDatos;