import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
/**
 * Componente que renderiza la lista de items de un acordeón
 */
const Container = ({ children, className }) => {
    return <ul className={`accordion ${className ? className : ''}`}>
        {children}
    </ul>
}

Container.propTypes = {
    /**
     * los hijos de este componente solo pueden ser de tipo Accordion.Item
     */
    children:PropTypes.any,
        /**
     * clases que se añádiran al componente que es de tipo ul
     */
    className:PropTypes.string,
}


/**
 * Componente que renderiza un item, tanto el botón que expande y contrae (Accordion.ItemButton) asi como también el contenido (Accordion.Content)
 * Este componente debe ser hijo directo de Accordion.Container.
 */
const Item = ({ children, className,  expanded }) => {
    const [isExpanded, setExpanded] = useState(false);
    const ref = useRef(null);

    useEffect(()=>{
        setExpanded(expanded?true:false);
    },[expanded])

    useEffect(() => {
        let timer = null;
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                timer = setTimeout(() => {
                    setExpanded(false);
                }, 70);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            clearTimeout(timer)
        };
    }, [ref]);


    return (<li ref={ref} className={`item ${isExpanded?'expanded':'collapsed'} ${className ? className : ''}`}>
          {
       React.Children.map(children,(child, index) => {
        if(child?.type === ItemButton){
            return React.cloneElement(child, {...child.props, onClick:()=>{
                child.props.onClick&&child.props.onClick();
                setExpanded(!isExpanded)
            }})
        }else{
            return child;
        }
       })
         
    }
    </li>)
}
Item.propTypes = {
    /**
     * solo debe recibir 2 hijos Accordion.ItemButton y Accordion.Content.
     */
    children:PropTypes.any,
        /**
     * clases que se añádiran al componente que es de tipo li.
     */
    className:PropTypes.string,
    /**
     * Indica si el item debe estar expandido o contraido, por defecto está contraido.
     */
    expanded:PropTypes.bool,
}

/**
 * Botón que expande o contrae un item del acordeón, debe ir dentro de Accordion.Item.
 */
const ItemButton = ({ children, className, label, onClick }) => {
    return <button className={`itemButton focusable-secondary ${className ? className : ''}`} onClick={()=>onClick&&onClick()}>
        {
            children ? children : <>
                <span> {label || 'N/A'}</span>
                <Icon className="icon" icon="material-symbols:keyboard-arrow-down" />
            </>
        }

    </button>
}

ItemButton.propTypes = {
    /**
     * Cualquier elemento html o react component, tener en cuenta que este children se pondrá dentro de un botón.
     */
    children:PropTypes.any,
    /**
     * clases que se añádiran al componente que es de tipo button.
     */
    className:PropTypes.string,
    /**
     * nombre del botón.
     */
    label:PropTypes.string,
    /**
     * función que se ejecuta cuando se da click en el botón.
     */
    onClick:PropTypes.func,
}

/**
 * componente que se utiliza dentro de un Componente de Tipo Accordion.Item 
 * dentro del cual puede ir cualquier tipo de contenido.
 */
const Content = ({ children, className }) => {
    return (<div className={`content ${className ? className : ''}`} role={"region"}>
        {children}
    </div>)
}
Content.propTypes = {
    /**
     * Cualquier elemento html o react component, tener en cuenta que este children se pondrá dentro de un elemento div.
     */
    children:PropTypes.any,
    /**
     * clases que se añádiran al componente que es de tipo div.
     */
    className:PropTypes.string,
}


/**
 * Objeto con todos los componentes necesarios para crear un acordeón.
 */
export const Accordion = {
    Container,
    Item,
    Content,
    ItemButton
}