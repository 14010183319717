import axios from "axios";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { store } from "../components/Store";
import { SesionAPI } from "../services/api/Sesion";
import { CancelRequestController } from "../util/cancel-request-controller";
import { ClearStorage } from "../util/clear-storage";

let abortController = new CancelRequestController();

/**
 * Hook personalizado para manejar el cierre de sesión.
 * @returns {{logout: Function, loading: boolean}} - Un objeto que contiene una función de cierre de sesión y el estado de carga.
 */
export const useLogout = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { state, dispatch } = useContext(store);

    const logout = async () => {
        setLoading(true);
        await SesionAPI.cerrarSesion({ abortSignal: abortController.signal })
            .then(result => {
                dispatch({
                    type: "CHANGE_VALUE",
                    property: "sesionActiva",
                    value: 'NO',
                });
                history.push('/');
                ClearStorage();
                window.location.reload();
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        return () => {
            setLoading(false);
            abortController.abort();
            abortController = new CancelRequestController();
        }
    }, [])


    return {
        logout,
        loading
    }
}