
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Este servicio devuelve arreglos con la información que se muestra en el home (usuario no autenticado y usuario no individual si se está autenticado).
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<{
    {
      banners:[],
      autores:[],
      librosRecomendados:[],
      colecciones:[],
      paises:[],
      librosIlustrados:[],
      librosInteractivos:[],
    }
 }>} retorna un objeto con los diferentes arreglos (filas)
 */
const obtenerFilas = async ({abortSignal=null})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;


        const response = await axios({
          method: 'GET',
          url: `assets/datosHome`,
          signal: abortSignal || null,
          headers:{'Content-Type':'application/x-www-form-urlencoded'},
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos || [];
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}
/**
 * Este servicio devuelve los datos que se sirven para mostrar la información del home de un cliente individual
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<{
    "usuario": String,
    "instituto": String,
    "libros":[
       {
        "idLibro": string,
        "title": string,
        "author": string,
        "portada": string,
        "webUrl": string,
        "edad": string,
        "genero": string,
        "editorial": string,
        "descripcion": string,
        "botonLeer": string,
      }
    ] }>} retorna un objeto con los datos del home del usuario individual
 */
const obtenerHomeUsuarioIndividual = async ({abortSignal=null})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;


        const response = await axios({
          method: 'GET',
          url: `assets/listaLibrosPocos`,
          signal: abortSignal || null,
          headers:{'Content-Type':'application/x-www-form-urlencoded'},
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos || [];
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}


export const HomeAPI = {
  obtenerFilas,
  obtenerHomeUsuarioIndividual
}