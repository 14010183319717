import React, { useContext, useState, useEffect, useRef } from "react";
import { store } from "../../../components/Store";
import { useImmer } from "use-immer";

import { CancelRequestController } from "../../../util/cancel-request-controller";
import { DatosPaisesAPI } from "../../../services/api/datosPaises";

import { EventosFormularioAPI } from "../../../services/api/FormularioEventos";

import { ModalEventAlert } from "../modal-event-alert";
import { ModalLimitAlert } from "../modal-limit-alert";

import { EventHeader } from "../EventHeader";

/**
 * Componente del formulario público
 * @param {object} props 
 * @returns {JSX.Element} FormPublicEvent
 */
export const FormPublicEvent = ({ eventData, textosInterfaz, lang }) => {

    const globalState = useContext(store);
    const { state } = globalState;
    const country = state?.pais;
    const language = state?.idioma;

    const [sortedCountries, setSortedCountries] = useState([]);

    const [validacionCorreo, setValidacionCorreo] = useState(true);
    const [otherCountry, setOtherCountry] = useState(false);
    const [paisSelecccionado, setPaisSelecccionado] = useImmer({ nombrePais: "", codigoPais: "" });
    const [paises, setPaises] = useState([]);
    const [loading, setLoading] = useState(false);

    const [group, setGroup] = useImmer({ otherGroup: false, value: "" });

    const [checkInstitution, setCheckInstitution] = useState(false);
    const [checkAccMakeMake, setCheckAccMakeMake] = useState(false);
    const [checkAge, setCheckAge] = useState(false);

    const [showModalAlert, setShowModalAlert] = useState(false);
    const [isModalAlert, setIsModalAlert] = useState(false);
    const [showModalLimit, setShowModalLimit] = useState(false);

    const refFormulario = useRef(null);
    const refNombre = useRef(null);
    const refCorreo = useRef(null);
    const refConfirmarCorreo = useRef(null);
    const refPais = useRef(null);
    const refOtroPais = useRef(null);
    const refGrupo = useRef(null);
    const refInstitution = useRef(null);

    const prevAbortControllerRef2 = useRef();

    /*Pone de primeras el pais del usuario en la lista de paises.*/
    const myCountryFirst = (data) => {
        const con = country ? country : "CO";
        if (Array.isArray(data) && con) {
            const _countries = [...data].filter((c) => c?.codigo !== con);
            const c = [...data].find((c) => c?.codigo === con);
            if (c) {
                if (con === "US") {
                    language === "en"
                        ? (c.nombre = "United States of America")
                        : (c.nombre = "Estados Unidos de América");
                }
                setSortedCountries([c, ..._countries]);
            }
        }
    };

    useEffect(() => {
        let abortController = new CancelRequestController();

        //Utiliza el servicio obtenerLatamUSA de DatosPaisesAPI y obtiene la lista de paises de Latam más USA
        const listaPaises = async () => {
            await DatosPaisesAPI.obtenerLatamUSA({
                abortSignal: abortController.signal,
            })
                .then((res) => {
                    setPaises(Array.isArray(res) ? res : []);
                })
                .catch((error) => console.log(error));
        };
        listaPaises();
        return () => {
            abortController.abort();
        };
    }, []);


    useEffect(() => {
        myCountryFirst(paises);
    }, [paises, country, language]);

    const validarPais = (valor) => {
        let isOther = refPais.current.value === "Otro" ? true : false;
        setOtherCountry(isOther);

        sortedCountries.map((pais) => {
            if (pais?.codigo === valor) {
                setPaisSelecccionado((draft) => {
                    draft.nombrePais = pais?.nombre;
                    draft.codigoPais = pais?.codigo;
                });
            }
        });
    };

    /**
     * Esta función retorna una alerta en caso de que se ejecute un evento de tipo copiar o pegar en un campo determinado.
     * @param {Event} e
     * @returns {alert}
     */
    const alertaEvento = (e) => {
        e.preventDefault();
        return alert("Está acción no está permitida.");
    };

    const validacionCamposCorreo = () =>
        setValidacionCorreo(
            refCorreo.current.value === refConfirmarCorreo.current.value
                ? true
                : false
        );

    const validarGrupo = (valor) => {
        let isOther = valor === "Otro" ? true : false;

        setGroup((draft) => {
            draft.otherGroup = isOther;
            draft.value = valor;
        });
    };

    const enviarFormulario = async (e) => {
        e.preventDefault();


        if (refFormulario?.current.reportValidity() && validacionCorreo) {
            setLoading(true);

            if (prevAbortControllerRef2.current) {
                prevAbortControllerRef2.current.abort();
            }
            const abortController = new CancelRequestController();
            prevAbortControllerRef2.current = abortController;

            const propiedades = {
                idFormulario: eventData?.id || '',
                tipoEvento: eventData?.tipoEvento || '',
                nombreCompleto: refNombre.current.value,
                correo: refCorreo.current.value,
                pais: otherCountry
                    ? `Otro - ${refOtroPais.current.value}`
                    : paisSelecccionado?.nombrePais || "",
                grupo: group?.otherGroup
                    ? `Otro - ${refGrupo?.current.value}`
                    : group?.value,
                perteneceInstitucion: checkInstitution ? 1 : 0,
                nombreInstitucion: checkInstitution ? refInstitution.current.value : "N/A",
                accesoMakeMake: checkAccMakeMake ? 1 : 0,
                edad: checkAge ? 1 : 0,
            };

            await EventosFormularioAPI.sendRegistration({
                abortSignal: abortController.signal,
                dataForm: propiedades
            })
                .then((response) => {
                    const data = response;
                    const error = data.status === 0;

                    if (!error) {
                        if(data.info === "Registro exitoso, el formulario ha alcanzado el límite de inscritos."){
                            setShowModalLimit(true);
                            setShowModalAlert(false);
                            setShowModalAlert(false);
                        }else{
                            setIsModalAlert(false)
                            setShowModalAlert(true);

                            refNombre.current.value = "";
                            refCorreo.current.value = "";
                            refConfirmarCorreo.current.value = "";
                            refPais.current.value = "";

                            if (refOtroPais.current) {
                                refOtroPais.current.value = "";
                            }

                            setGroup((draft) => {
                                draft.otherGroup = false;
                                draft.value = "";
                            });

                            if (refGrupo?.current) {
                                refGrupo.current.value = "";
                            }

                            setCheckInstitution(false);
                            setCheckAccMakeMake(false);
                            setCheckAge(false);
                        }
                    } else {
                        setShowModalLimit(false);
                        setIsModalAlert(true)
                        setShowModalAlert(true);
                    }
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    setLoading(false);
                });

        }
    }

    return (
        <>
            <EventHeader
                eventData={eventData}
                lang={lang}
                textosInterfaz={textosInterfaz}
            />

            <form
                ref={refFormulario}
                className="p-3 py-lg-4 px-lg-5 secondary-font font-weight-bold"
            >
                <div className="mb-3">
                    <label htmlFor="inputName" className="onprimary--color">
                        {textosInterfaz?.formularios?.nombre?.label || "Nombre completo"}*
                    </label>
                    <input
                        type="text"
                        id="inputName"
                        name="nombre"
                        ref={refNombre}
                        placeholder={textosInterfaz?.formularios?.nombre?.placeholder || "Escribe tu nombre y apellido aquí"}
                        className={"focusable-secondary form-control text-16"}
                        required
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="inputName" className="onprimary--color">
                        {textosInterfaz?.formularios?.correo?.label || "Correo electrónico"}*
                    </label>
                    <input
                        type="email"
                        className={"focusable-secondary form-control text-16"}
                        id="inputEmail"
                        placeholder={textosInterfaz?.formularios?.correo?.placeholder || "Escribe tu correo electrónico"}
                        name="email"
                        required
                        ref={refCorreo}
                        onPaste={(e) => { alertaEvento(e); }}
                        onCopy={(e) => { alertaEvento(e); }}
                        onChange={validacionCamposCorreo}
                    />
                    {!validacionCorreo && (
                        <p className="validacion-campo-error red-inverse--color">
                            {textosInterfaz?.formularios?.confirmar_correo?.error || 'Los datos ingresados no son iguales'}
                        </p>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="inputEmail" className="onprimary--color">
                        {textosInterfaz?.formularios?.confirmar_correo?.label || 'Confirmar correo electrónico'}*
                    </label>
                    <input
                        className={`focusable-secondary form-control text-16`}
                        id="inputEmailConfirm"
                        name="correoConfirm"
                        placeholder={textosInterfaz?.formularios?.confirmar_correo?.placeholder || 'Escribe de nuevo tu correo electrónico'}
                        required
                        type="email"
                        ref={refConfirmarCorreo}
                        onChange={validacionCamposCorreo}
                    />
                    {!validacionCorreo && (
                        <p className="validacion-campo-error red-inverse--color">
                            {textosInterfaz?.formularios?.confirmar_correo?.error || 'Los datos ingresados no son iguales'}
                        </p>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="form-select-pais" className="onprimary--color">
                        {textosInterfaz?.formularios?.pais?.label || "País"}*
                    </label>

                    <select
                        name="paisP"
                        ref={refPais}
                        className={"focusable-secondary form-control text-16"}
                        id="form-select-pais"
                        onChange={(e) => validarPais(e.target.value)}
                        required
                    >
                        <option value="" hidden>
                            {textosInterfaz?.formularios?.pais?.placeholder || "Escoge tu país"}
                        </option>
                        {sortedCountries.length !== 0 &&
                            sortedCountries?.map((pais) => (
                                <option
                                    key={pais?.codigo}
                                    value={pais?.codigo}
                                >
                                    {pais?.nombre}
                                </option>
                            ))}
                        <option value="Otro">
                            {textosInterfaz?.formularios?.pais?.other || "Otro"}
                        </option>
                    </select>
                </div>

                {otherCountry && (
                    <div className="mb-3">
                        <label htmlFor="inputOtroPais" className="onprimary--color">
                            {textosInterfaz?.formularios?.nombre_pais?.label || "Nombre del país"}*
                        </label>
                        <input
                            ref={refOtroPais}
                            type="text"
                            className={"focusable-secondary form-control text-16"}
                            id="inputOtroPais"
                            placeholder={textosInterfaz?.formularios?.nombre_pais?.placeholder || "Escribe el nombre de tu país"}
                            name="inputOtroPais"
                            required={otherCountry ? true : false}
                        />
                    </div>
                )}

                <div className="mb-3">
                    <label htmlFor="form-select-grupo" className="onprimary--color">
                        {textosInterfaz?.formularios?.grupo?.label || "¿Con qué grupo te identificas?"}*
                    </label>

                    <select
                        name="grupo"
                        className={"focusable-secondary form-control text-16"}
                        id="form-select-grupo"
                        onChange={(e) => validarGrupo(e.target.value)}
                        required
                    >
                        <option value="" hidden>
                            {textosInterfaz?.formularios?.grupo?.option1 || "Escoge tu grupo"}
                        </option>
                        <option value="Papá o mamá">{textosInterfaz?.formularios?.grupo?.option2 || 'Papá o mamá'}</option>
                        <option value={"Promotor(a) de lectura o bibliotecario(a"}>{textosInterfaz?.formularios?.grupo?.option3 || 'Promotor(a) de lectura o bibliotecario(a)'}</option>
                        <option value={"Profesor o profesora"}>{textosInterfaz?.formularios?.grupo?.option4 || 'Profesor o profesora'}</option>
                        <option value="Otro">
                            {textosInterfaz?.formularios?.grupo?.other || "Otro"}
                        </option>
                    </select>
                </div>

                {
                    group?.otherGroup &&

                    <div className="mb-4">
                        <label htmlFor="inputOtroGrupo" className="onprimary--color">
                            {textosInterfaz?.formularios?.otro_grupo?.label || "Otro grupo:"}
                        </label>
                        <input
                            type="text"
                            ref={refGrupo}
                            className={"focusable-secondary form-control text-16"}
                            id="inputOtroGrupo"
                            placeholder={textosInterfaz?.formularios?.otro_grupo?.placeholder || "Escribe el grupo con el que te identificas"}
                            name="inputOtroGrupo"
                            required={group?.otherGroup}
                        />
                    </div>

                }


                <div className="mb-3">
                    <div className="row mb-3">
                        <div className="col-lg-6 mb-3 mb-lg-0 d-flex align-items-center">
                            <label
                                className="d-flex align-items-center w-100 focusable-red pointer"
                                tabIndex={0}
                                role="checkbox"
                                checked={checkInstitution}
                                aria-checked={checkInstitution}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        setCheckInstitution(!checkInstitution);
                                    }
                                }}
                            >
                                <input
                                    type="checkbox"
                                    className="modal-checkbox"

                                    checked={checkInstitution}
                                    onChange={(e) => {
                                        setCheckInstitution(e?.target.checked);
                                    }}
                                />
                                <i className="check"></i>
                                <p className="ml-2 onprimary--color text-16">
                                    {textosInterfaz?.formularios?.institucion?.check || "Pertenezco a una institución"}
                                </p>
                            </label>
                        </div>
                        {
                            checkInstitution &&
                            <div className="col-lg-6 ">
                                <div className="d-flex align-items-center">
                                    <label
                                        htmlFor="nombreInstituto"
                                        className="mr-3 onprimary--color"
                                        style={{
                                            fontSize: '.9em',
                                            fontWeight: 400
                                        }}
                                    >
                                        {textosInterfaz?.formularios?.institucion?.label || "Cuál?"}
                                    </label>
                                    <input
                                        type="text"
                                        ref={refInstitution}
                                        className={"focusable-secondary form-control col-10 text-16"}
                                        id="nombreInstituto"
                                        placeholder={textosInterfaz?.formularios?.institucion?.placeholder || "Nombre de la institución"}
                                        name="nombreInstituto"
                                        required={checkInstitution}
                                    />
                                </div>
                            </div>
                        }

                    </div>

                    <div className="mb-3">
                        <label
                            className="d-flex align-items-center w-100 focusable-red pointer"
                            tabIndex={0}
                            role="checkbox"
                            checked={checkAccMakeMake}
                            aria-checked={checkAccMakeMake}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setCheckAccMakeMake(!checkAccMakeMake);
                                }
                            }}
                        >
                            <input
                                type="checkbox"
                                className="modal-checkbox"
                                checked={checkAccMakeMake}
                                onChange={(e) => {
                                    setCheckAccMakeMake(e?.target.checked);
                                }}
                            />
                            <i className="check"></i>
                            <p className="ml-2 onprimary--color">
                                {textosInterfaz?.formularios?.acceso?.check || "Tengo acceso a MakeMake"}
                            </p>
                        </label>
                    </div>

                    <div className="mb-3">
                        <label
                            className="d-flex align-items-center w-100 focusable-red pointer"
                            tabIndex={0}
                            role="checkbox"
                            checked={checkAge}
                            aria-checked={checkAge}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setCheckAge(!checkAge);
                                }
                            }}
                        >
                            <input
                                type="checkbox"
                                className="modal-checkbox"
                                checked={checkAge}
                                onChange={(e) => {
                                    setCheckAge(e?.target.checked);
                                }}
                            />
                            <i className="check"></i>
                            <p className="ml-2 onprimary--color">
                                {textosInterfaz?.formularios?.edad?.check || "Soy mayor de edad"}
                            </p>
                        </label>
                    </div>

                </div>

                <div className="row mt-lg-5">
                    <div className="col-lg-8 col-xl-9 my-4 my-lg-0 d-flex align-items-center">
                        <p
                            className="onprimary--color"
                            style={{
                                fontSize: '.9em',
                                fontWeight: 400
                            }}
                        >
                            {
                                textosInterfaz?.formularios?.terminos?.terms1 || "Al ingresar tus datos estás aceptando"}{" "}
                            <a
                                className="focusable-secondary font-weight-bold"
                                href="/terminos-y-condiciones"
                            >
                                <u>{textosInterfaz?.formularios?.terminos?.terms2 || "Términos y condiciones"}</u>
                            </a>{" "}
                            {textosInterfaz?.formularios?.terminos?.terms3 || "y"}{" "}
                            <a
                                className="focusable-secondary font-weight-bold"
                                href="/politica-de-privacidad"
                            >
                                <u>{textosInterfaz?.formularios?.terminos?.terms4 || "Política de privacidad"}</u>
                            </a>
                        </p>
                    </div>

                    <div className="col-lg-4 col-xl-3">
                        <button
                            className="focusable-primary btn  btn-inscribirse"
                            type="submit"
                            id="btn-enviar-datos"
                            onClick={enviarFormulario}
                            disabled={loading}
                        >
                            {loading ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                            ) : (
                                textosInterfaz?.formularios?.button || "Inscribirse"
                            )}
                        </button>
                    </div>
                </div>

            </form>

            <ModalEventAlert
                visible={showModalAlert}
                setVisible={setShowModalAlert}
                textosInterfaz={textosInterfaz}
                alertType={isModalAlert}
            />

            <ModalLimitAlert
                visible={showModalLimit}
                setVisible={setShowModalLimit}
                textosInterfaz={textosInterfaz}
            />
        </>
    )
}