import { READER_MESSAGE_EVENT_PREFIX } from "../../config/constants";


const sendMessage = ({eventId, payload}) => {
    eventId = `${READER_MESSAGE_EVENT_PREFIX}${eventId}`;
	window.postMessage({eventId, payload}, '*');
};

export const ReaderMessageService = {
    sendMessage
};
