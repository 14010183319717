
import React, { useState } from "react";
import { useContext } from "react";
import { store } from "../Store";
import { useEffect } from "react";
import { SwitchButton } from "../shared/switch-button";

/**
 * Componente que contiene el switch el cual de manera implicita puede cambiar el idioma del sitio completo entre 'Español' e 'Inglés'.
 * @returns {React.Component}
 */
export const LanguageSelector = ()=>{
    const [checked, setChecked] = useState(false);
    const storeContext = useContext(store);
    const availableLanguages = ['en', 'es'];
    const language = storeContext?.state?.idioma;

    useEffect(()=>{
        const language = localStorage.getItem('language');
        const checked = language === 'en'?true:false;

        if(language && availableLanguages.includes(language)){
            setChecked(checked)
            storeContext.dispatch({ 
                type: "CHANGE_VALUE",
                 property: "idioma", 
                 value: checked?'en':'es' 
            });
        }else{
            setChecked(false)
            storeContext.dispatch({ 
                type: "CHANGE_VALUE",
                 property: "idioma", 
                 value:'es' 
            });
        }
    },[])

    const updateLanguage = (_checked)=>{
        const language = _checked?'en':'es';
        storeContext.dispatch({ 
            type: "CHANGE_VALUE",
             property: "idioma", 
             value: _checked?'en':'es' 
        });
        localStorage.setItem('language', language);
        setChecked(_checked);
    }

    useEffect(()=>{
        const _checked = language === 'en';
        setChecked(_checked);
    },[language])



    return (<div className="d-flex align-items-center justify-content-center language-selector">
        <p className={`lang ${!checked?'active':''}`}>Español</p>
        <div className="px-3">
        <SwitchButton onChange={({checked})=>updateLanguage(checked)} checked={language==='es'?false:true} />
        </div>

        <p className={`lang ${checked?'active':''}`}>English</p>
    </div>)
}


