import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { ColeccionLibrosAPI } from "../../services/api/coleccion-libros";
import { CancelRequestController } from "../../util/cancel-request-controller";


const MODES = {
    booksSelection: 'BOOKS-SELECTION',
    booksSelectionCompleted: 'BOOKS-SELECTION-COMPLETED',
    activeSuscription: 'ACTIVE-SUSCRIPTION'
}


let abortController = new CancelRequestController();

/**
 * Este es un hook que permite obtener, actualizar y mostrar progresivamente los libros de una colección. Para esto el Hook recibe un objeto con los filtros seleccionados y retorna el estado(objeto) que  contiene la información de la colección.
 * @param {object} filters - objeto que contiene los filtros seleccionado
 * @returns {{
 *  loadingData: boolean,
 *  currentMode: string | null,
 *  maxDate: string,
 *  maxBooks: number,
 *  books: Array<object>,
 *  packageName: string,
 *  totalBooksLength: number,
 *  totalPackageBooks:number,
 *  paginator: {{
 *      currentPage: number,
 *      booksPerPage: number
 *  }}
 *  setters:{{
 *   setBooks : function(array): array,
 *   updateBook: function(array): array,
 *   nextFetch: function(array): array,
 *  }}
 * }}
 */
export const useBookCollectionManager = ({ filters }) => {
    const [state, setState] = useImmer({
        loadingData: true,
        currentMode: null,
        maxDate: '',
        maxBooks: 0,
        books: [],
        packageName:'',
        totalBooksLength:0,
        totalPackageBooks:0,
        paginator: {
            currentPage: 1,
            booksPerPage: 20
        }
    })

    /**
     * Esta función actualiza la información de un libro en específico
     * @param {string} idLibro - código del libro
     * @param {Object<string, boolean>} data - información del libro. Indica si es elegido o si el libro tiene un valor de checked
     */
    const updateBook = (idLibro, data) => {
        setState(currentState => {
            currentState.books = currentState.books.map(b => {
                if (idLibro === b?.idLibro) {
                    return {
                        ...b,
                        ...data
                    };
                } else {
                    return b;
                }
            })
        })
    }

    /**
     * Actualiza el arreglo de libros en el estado 
     * @param {array} books -arreglo de libros 
     */
    const setBooks = (books) => {
        setState(currentState => {
            currentState.books = books;
        })
    }

 
    /**
     * Esta función actualiza el estado "paginator". Para esto incrementa el número de la página para mostrar progresivamente el número de libros.  
     * @returns {number} - número de la página 
     */
    const incrementPage = () => {
        let page = 1;
        setState(currentState => {
            page = currentState.paginator.currentPage+1;
            currentState.paginator.currentPage = page;
        })
        return page;
    }

    /**
     * Obtiene la lista de libros basado en los filtros aplicados y actualiza el estado con la información proporcionada del API
     */
    const fetchData = useCallback(async () => {
        await ColeccionLibrosAPI
            .obtenerListadoLibros({
                abortSignal: abortController.signal,
                filtros: {
                    ...filters,
                    librosPorPagina: state.paginator.booksPerPage,
                    numeroPagina: 1,
                }
            })
            .then(result => {
                setState(currentState => {
                    currentState.totalBooksLength = result?.numeroDeLibros || 0
                    currentState.totalPackageBooks = result?.totalLibros || 0
                    currentState.loadingData = false;
                    currentState.packageName = result?.NombrePaquete || 'N/A';
                    currentState.maxDate = result?.fecha;
                    currentState.maxBooks = result?.maxLibros;
                    currentState.books = Array.isArray(result?.libros) ? result?.libros : []
                    if (result?.definitivos) {
                        currentState.currentMode = MODES.activeSuscription
                    } else {
                        if(result?.librosConfirmadosCliente){
                            currentState.currentMode = MODES.booksSelectionCompleted
                        }else{

                            currentState.currentMode = MODES.booksSelection
                        }
                    }
                })

            })
            .catch(error => console.log(error))

    }, [filters])

    /**
     * Obtiene la siguiente lista de libros basado en los filtros aplicados y actualiza el estado con la información proporcionada del API. Esto se hace para que carguen progresivamente los libros, esto mejora el rendimiento del sitio
     */
    const nextFetch = useCallback( async ()=>{
        const page = incrementPage();
         await ColeccionLibrosAPI
            .obtenerListadoLibros({
                abortSignal: abortController.signal,
                filtros: {
                    ...filters,
                    librosPorPagina: state.paginator.booksPerPage,
                    numeroPagina: page,
                }
            })
            .then(result => {
                setState(currentState => {
                    const books = Array.isArray(result?.libros) ? result?.libros : [];
                    currentState.books = [...currentState.books, ...books]
                })
            })
            .catch(error => console.log(error))
    }, [state.paginator.currentPage, filters])


    useEffect(() => {
        fetchData();
        return () => {
            abortController.abort();
            abortController = new CancelRequestController();
            setState(currentState => {
                currentState.loadingData = true;
                currentState.paginator.currentPage = 1;
               
            })
        }
    }, [filters])


    return {
        ...state,
        setters: {
            setBooks,
            updateBook,
            nextFetch
        },
    };

}