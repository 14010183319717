import React, { useState, useEffect } from "react";
import { BlogCard } from "./BlogCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Img } from "../../components/shared/img";

/**
 * Renderiza las reseñas o entradas en la pagina principal del blog
 * @param {object} resena - contiene la información de las reseñas
 * @param {object} textosBlog - contiene los textos de interfaz
 * @returns {JSX.Element} Posts
 */
export const Posts = ({ resena, textosBlog }) => {
    const [resenasAMostrar, setResenasAMostrar] = useState([]);
    const [resenasCargadas, setResenasCargadas] = useState(false);

    const cantidadResenasAMostrar = 6;
    const resenas = resena?.data || [];

    useEffect(() => {

        // Carga las 6 primeras reseñas 
        const cargaInicial = () => {
            if (Array.isArray(resenas)) {
                setResenasAMostrar(resenas.slice(0, cantidadResenasAMostrar))
                resenas.length <= cantidadResenasAMostrar && setResenasCargadas(true)
            }
        }
        setResenasCargadas(false);
        cargaInicial();
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resenas])

    /**Carga progresicamente las reseñas*/
    const cargaProgresiva = async () => {
        let timer = null;
        return await new Promise((resolve) => {
            if (timer) { clearTimeout(timer) }
            timer = setTimeout(() => {
                let contador = 0;
                const nuevasResenas = [];
                for (let i = resenasAMostrar.length; i < resenas.length; i++) {
                    const post = resenas[i];
                    if (contador < cantidadResenasAMostrar) {
                        nuevasResenas.push(post)
                    }
                    contador++;
                }
                setResenasAMostrar([...resenasAMostrar, ...nuevasResenas])
                if (resenasAMostrar.length === resenas.length) {
                    setResenasCargadas(true);
                }
                resolve(true);
            }, 1000);
        })
    }

    /* useEffect(() => {
        if (resenasAMostrar.length >= resenas.length) {
            setResenasCargadas(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resenasAMostrar])
 */
    return (
        <>
            {
                resena?.loading ?
                    <>
                        <div
                            className="secondary-inverse--color container-fluid w-100 d-flex justify-content-center align-items-center col-md-10 col-lg-10"
                            style={{minHeight: 400}}    
                        >
                            <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                        </div>
                    </>
                    :
                    <>

                        {
                            resenasAMostrar.length === 0 ?
                                <div >
                                    <Img
                                        src={`/img/noresults.png`}
                                        style={{ maxWidth: 150 }}
                                    />
                                    <p className="text-center onbackground-primary--color py-3">
                                        {textosBlog?.entrada?.no_resultados || 'No se encontraron resultados en la búsqueda :('}
                                    </p>
                                </div>

                                :

                                <>
                                    <InfiniteScroll
                                        style={{ overflow: 'visible' }}
                                        className='row mt-4 mt-lg-5'
                                        dataLength={resenasAMostrar.length}
                                        hasMore={resenasCargadas ? false : true}
                                        scrollThreshold={0.86}
                                    >
                                        {
                                            resenasAMostrar.map((item, i) => {
                                                return (
                                                    <div key={`reseña${i}`} className="col-lg-6 mb-5 mb-lg-4">
                                                        <BlogCard InfoCard={item} textosBlog={textosBlog} />
                                                    </div>
                                                )
                                            })
                                        }

                                    </InfiniteScroll>

                                    {
                                        resenasCargadas ? false : true &&
                                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                                <button
                                                    onClick={() => cargaProgresiva()}
                                                    className="btn btn-blog mx-auto">
                                                    {textosBlog?.botones?.ver_mas || 'Ver más'}
                                                </button>
                                            </div>
                                    }

                                </>

                        }

                    </>
            }

        </>
    )
}