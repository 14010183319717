import { useContext } from "react";
import { store } from "../../components/Store";
import { useEffect } from "react";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { HomeAPI } from "../../services/api/Home";
import { useImmer } from "use-immer";

/**
 * Hook personalizado para obtener y gestionar los datos de la página de inicio del usuario para usuario no logueado y de colecciones grandes.
 * @returns {{
        inStore: false,
        fetching: false,
        loaded: false,
        data: {}
 * }} Objeto con la información necesaria del home.
 */
export const useUserHomeData = () => {
	const globalState = useContext(store);
	const homeDataFromStore = globalState?.state?.homeData;
	const isAuthenticatedUser = globalState?.state?.mainDataLoaded;
	const isIndividualUser = globalState?.state?.cantidadLibros < 40;
	const [userHomeData, setUserHomeData] = useImmer({
		default: {
			inStore: false,
			fetching: false,
			loaded: false,
			data: {},
		},
	});

	const fetchHomeRows = async ({ abortSignal }) => {
		setUserHomeData((draft) => {
			draft.default.fetching = true;
		});
		await HomeAPI.obtenerFilas({ abortSignal: abortSignal })
			.then((result) => {
				const data = {
					fetching: false,
					loaded: true,
					data: result,
					inStore: true,
				};
				setUserHomeData((draft) => {
					draft.default = data;
				});
				globalState.dispatch({
					type: "CHANGE",
					path: "homeData.default",
					value: data,
				});
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setUserHomeData((draft) => {
					draft.default.fetching = false;
				});
			});
	};

	useEffect(() => {
		const cancelRequestController = new CancelRequestController();
		if (!isIndividualUser) {
			if (homeDataFromStore?.default?.inStore === false) {
				fetchHomeRows({ abortSignal: cancelRequestController?.signal });
			} else {
				setUserHomeData({ ...homeDataFromStore });
			}
		}
		return () => {
			cancelRequestController.abort();
		};
	}, [isAuthenticatedUser, isIndividualUser, homeDataFromStore]);

	return userHomeData.default;
};
