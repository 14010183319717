import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TabPanel } from "../components/shared/tabpanel";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { ColeccionesList } from "./colecciones";
import { SeriesList } from "./series";
import { PaisesList } from "./paises";
import { store } from "../components/Store";

/**
 * Este componente tab panel carga los 3 tipos de curadurias (colecciones, series y países) y tambíen se encargá de enrutar cada sección.
 */
export const TabPanelCuradurias = ({ curadurias }) => {
  const {textosSubSecciones:textos} = useObtenerSubsecciones('curadurias');
  const appState = useContext(store);
  const lang = appState?.state?.idioma;
    const history = useHistory();
    const location = useLocation();
    const tabPanelIds = {
        colecciones: '/curadurias/colecciones',
        series: '/curadurias/series',
        paises: '/curadurias/paises',
    }
    const colecciones = Array.isArray(curadurias?.colecciones)?curadurias?.colecciones:[];
    const series = Array.isArray(curadurias?.series)?curadurias?.series:[];
    const paises = Array.isArray(curadurias?.paises)?curadurias?.paises:[];
    /**
     * define cual es el tab panel (tienda) que se debe mostrar en pantalla.
     */
    const [currentTabPanel, setCurrentTabPanel] = useState(tabPanelIds.colecciones)


    useEffect(() => {
        /**
            dependiendo de la ruta del navegador si coincide con algún id 
            de los tab panel, entonces muestra el panel respectivo, 
            si no es así mostrará por defecto el de colecciones
         */
        if (Object.values(tabPanelIds).includes(location.pathname)) {
            setCurrentTabPanel(location.pathname)
        } else {
            setCurrentTabPanel(tabPanelIds.colecciones)
        }
    }, [location])



    useEffect(() => {
        if (
            (location.pathname.includes('colecciones') && colecciones?.length === 0)
            ||
            (location.pathname.includes('series') && series?.length === 0)
            ||
            (location.pathname.includes('paises') && paises?.length === 0)) {
            history.push('/home');
        }
    }, [location, curadurias])

    /**
     * cambia la ruta actual, debería llevar a la ruta de una sección de curaduría especifica.
     * Si la ruta coincide con el id de algún tab panel se mostrará ese panel automaticamente, 
     * de no existir se mostrará por defecto el tab de colecciones
     */
    const tabPanelNavigateTo = (path) => {
        history.push(path);
    }

    return (
        <TabPanel.Box currentPanelId={currentTabPanel} className={'tab-panel'}>
            <TabPanel.Tabs className={'tabs header-bg'}>
                {
                    colecciones?.length > 0 &&
                    <TabPanel.Tab
                    tabPanel={tabPanelIds.colecciones}>
                    <button
                        className={`focusable-red tab individual ${currentTabPanel === tabPanelIds.colecciones ? 'current' : ''}`}
                        onClick={() => tabPanelNavigateTo(tabPanelIds.colecciones)}>
                        <p className="d-flex align-items-center">{textos?.colecciones?.titulo?.plural || 'Colecciones'}</p>
                    </button>
                </TabPanel.Tab>
                }
               {
                     series?.length > 0 &&
                    <TabPanel.Tab
                    tabPanel={tabPanelIds.series}>
                    <button
                        className={`focusable-red tab institucional ${currentTabPanel === tabPanelIds.series ? 'current' : ''}`}
                        onClick={() => tabPanelNavigateTo(tabPanelIds.series)}>
                        <p className="d-flex align-items-center">{textos?.series?.titulo?.plural || 'Series'}</p>
                    </button>
                </TabPanel.Tab>
               }
                {
                      paises?.length > 0 &&
                    <TabPanel.Tab
                    tabPanel={tabPanelIds.paises}>
                    <button
                        className={`focusable-red tab cursos ${currentTabPanel === tabPanelIds.paises ? 'current' : ''}`}
                        onClick={() => tabPanelNavigateTo(tabPanelIds.paises)}>
                        <p className="d-flex align-items-center">{textos?.paises?.titulo?.plural || 'Países'}</p>
                    </button>
                </TabPanel.Tab>
                }
            </TabPanel.Tabs>
            <TabPanel.Panel panelId={tabPanelIds.colecciones}>
                <ColeccionesList colecciones={colecciones} lang={lang} />
            </TabPanel.Panel>
            <TabPanel.Panel panelId={tabPanelIds.series}>
                <SeriesList series={series} lang={lang} />
            </TabPanel.Panel>
            <TabPanel.Panel panelId={tabPanelIds.paises}>
                <PaisesList paises={paises} lang={lang} />
            </TabPanel.Panel>
        </TabPanel.Box>
    )
}


