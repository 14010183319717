
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Este servicio devuelve los datos de un libro incluyendo sus archivos
 * @param {{abortSignal:AbortSignal, data:{bookId:String}}} param0 
 * @returns {Promise<Libro>} 
 */
const getAssets = async ({abortSignal=null, data})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

    
        const fullData = {
          idLibro:data?.bookId || ''
        }
        
        const params =Object.entries(fullData)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')

        const response = await axios({
          method: 'GET',
          url: `assets/visor?${params}`,
          signal: abortSignal || null,
          headers:{'Content-Type':'application/x-www-form-urlencoded'},
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos.Archivos || [];
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}
/**
 * Este servicio devuelve los datos de un libro incluyendo sus archivos
 * @param {{abortSignal:AbortSignal, data:{bookId:String}}} param0 
 * @returns {Promise<Libro>} 
 */
const getBookData = async ({abortSignal=null, data})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

    
        const fullData = {
          idLibro:data?.bookId || ''
        }
        
        const params =Object.entries(fullData)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')

        const response = await axios({
          method: 'GET',
          url: `assets/visor?${params}`,
          signal: abortSignal || null,
          headers:{'Content-Type':'application/x-www-form-urlencoded'},
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos || [];
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}

export const VisorAPI = {
   getAssets,
   getBookData
}