import React, { useContext} from "react";
import { store } from "../components/Store.js";
import { Link, useLocation } from "react-router-dom";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Componente para mostrar información y acciones relacionadas a un libro descargado offline, al dar click en la portada del libro es redirigido al visor para que renderice de manera offline el correspondiente libro.
 *
 * @component
 * @example
 * import { LibroOffline } from "./LibroOffline";
 *
 * function App() {
 *   return (
 *     <div>
 *       <LibroOffline idLibro="123" />
 *     </div>
 *   );
 * }
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.book - metadatos del libro.
 * @param {Function} props.onDelete - función que se ejecuta cuando se da click a eliminar libro.
 * @returns {JSX.Element} Componente LibroOffline.
 */
export const LibroOffline = ({ book, onDelete }) => {
	const { textosSubSecciones: descargados } = useObtenerSubsecciones("descargados");
	const globalState = useContext(store);
	const { state } = globalState;
	const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
	const location = useLocation();

	return (
		<>
			<div
				tabIndex={0}
				className="focusable-secondary row mx-2 my-4 pb-3 py-4 libro-descargado"
			>
				<div className="col-md-5 col-lg-3 d-flex resource-content pr-lg-5 pl-0 mb-1">
					<img
						role={"presentation"}
						className="img-fluid cover mx-auto mx-sm-0"
						src={book?.cover}
						alt="Portada del recurso"
					/>
				</div>
				<div className="col-md-7 col-lg-9 resource-content px-0 pl-lg-2 d-flex flex-column  ">
					<div>
						<h3 tabIndex={0} className="focusable-secondary mb-0 titulo">
							{book?.title || "N/A"}
						</h3>
						<p tabIndex={0} className="focusable-secondary mb-1 autor">
							{book?.author || "N/A"}
						</p>

						<p tabIndex={0} className="focusable-secondary tiempo-disponible">
							{descargados?.libro?.disponible || "Disponible hasta: "}
							<span className="primary-item"> {book?.dueDate}</span>
						</p>
					</div>

					<div className="btn-leer d-flex d-sm-block">
						{(() => {
							let href = isLogged
								? `/visor/${book?.bookId}?returnUrl=${encodeURIComponent(
										location.pathname
								  )}`
								: "/login";
							return (
								<Link
									className="focusable-primary mx-auto mx-sm-0 btn btn-primary px-4 py-2 mt-3 mb-3 mb-sm-0"
									to={href}
								>
									{descargados?.libro?.boton_leer || "Leer"}
								</Link>
							);
						})()}
					</div>
					<div>
						<button
							className="focusable-primary btn btn-eliminar mt-3"
							onClick={() => {
								onDelete&&onDelete();
							}}
						>
							{descargados?.libro?.boton_eliminar || "Eliminar de mis descargas"}{" "}
							<i className="far fa-trash-alt"></i>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
