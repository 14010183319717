import React, { useState, useEffect } from "react";
import { LibrosAPIs } from "../../../services/api/Libros";
import { Icon } from "@iconify/react/dist/iconify.js";

import Libro from "../../../components/Libro";

/**
 * Componente que muestra los libros recomendados en la entrada del blog.
 * Estos libros son aleatorios por ahora y aparecen cuando hay una sesión activa
 * @returns {JSX.Element} RecommendedBooks
 */
export const RecommendedBooks = () => {
    const [recommendedBooks, setRecommendBooks] = useState([]);
    const [loadingRecommendedBooks, setLoadingRecommendedBooks] = useState(true);

    useEffect(() => {
        if (recommendedBooks.length === 0) {
            loadRecommendedBooks();
            return;
        }
    }, [recommendedBooks])

    /**Función que obtiene los libros recomendados.*/
    const loadRecommendedBooks = async () => {
        setLoadingRecommendedBooks(true)
        await LibrosAPIs.obtenerRecomendados({ abortSignal: null, idLibro: '' })
            .then(recommended => {
                setRecommendBooks(recommended);
                setLoadingRecommendedBooks(false);
            })
            .catch(error => console.log(error))
    }

    return (
        <>
            <div className="col-md-10 mx-auto">
                <div className="row m-0 justify-content-center">
                    {recommendedBooks.map((item, index) => {
                        if (!!item?.link && index !== 2) {
                            return (
                                <div
                                    className="cover-book col-6 col-sm-4 col-lg-3 mb-4 mb-lg-0"
                                    key={index}
                                    onClick={() =>
                                    (document.getElementsByTagName(
                                        "html"
                                    )[0].scrollTop = 0)
                                    }
                                    style={{
                                        maxWidth: 250
                                    }}
                                >
                                    {loadingRecommendedBooks ?
                                        <div
                                            className="secondary-inverse--color d-flex justify-content-center align-items-center"
                                            style={{ minHeight: '400px' }}>
                                            <Icon icon="fa:spinner" width={30} className={'spinner-rotation'} />
                                        </div>
                                        :
                                        <Libro
                                            libro={item}
                                            portada={item.portada}
                                            webUrl={item.link}
                                            alto={280}
                                        />
                                    }
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </>
    )
}