import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonFichaColeccion = () => {
  return (
    <SkeletonTheme color="#e8e8e8" highlightColor="#f7f7f7">
      <div className="coleccion-container" style={{ backgroundColor: "#fff" }}>
        <div className="coleccion-background" style={{ background: `#fff url('/img/background.png') center/cover repeat` }}></div>
        <div className="container">
          <div className="row pt-5 align-items-center mb-3">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <Skeleton height={45} />
            </div>
            <div className="col-lg-6 pl-lg-5">
              <Skeleton className="mb-1" count={3} height={25} />
            </div>
          </div>
          <div className="row mt-5 pb-5 mt-lg-0">
            <div className="mb-5 mb-lg-0 col-lg-6 px-0">
              <div className="coleccion-imagen px-4">
                <Skeleton height={450} />
              </div>
            </div>
            <div className='col-lg-6 pl-lg-5'>
              <div className="coleccion-books row">
                {(() => {
                  var libros = [];
                  for (let i = 0; i < 6; i++) {
                    libros.push(
                      <div className={`cover-book col-6 col-lg-4 mb-4`} key={`skeleton-libro-coleccion-${i}`}>
                        <Skeleton height={250} />
                      </div>
                    )
                  }
                  return libros;
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  )
}



export default SkeletonFichaColeccion;

