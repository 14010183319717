import React from "react";

/**
 * Retorna un arreglo con el texto dividido por líneas. 
 * @param {string} caracter - caracter que se encuentra dentro del texto y marca un salto de línea. 
 * @param {string} texto 
 * @returns 
 */
export function cambiarSaltoLinea(caracter, texto) {
    let cadenaTexto = String(texto).split(caracter);

    let nuevoTexto = [];
    for (var i = 0; i <= cadenaTexto.length; i++) {
        var num = i;
        nuevoTexto.push(
            <p
                key={`cadena${num}`}
            >
                {cadenaTexto[num]}
            </p>
        )
    }
    return nuevoTexto;
}