export const PRIMARY_SECTION_OPTIONS = [
	{
		id: "anuncia",
		name: { default: "Anuncia MakeMake en tu portal", translationPath: "corre_voz.tipo_recurso.anuncia" },
	},
	{
		id: "publica",
		name: { default: "Publica MakeMake en redes sociales", translationPath: "corre_voz.tipo_recurso.publica" },
	},
	{
		id: "imprime",
		name: { default: "Imprime información sobre MakeMake", translationPath: "corre_voz.tipo_recurso.imprime" },
	},
	{
		id: "descarga app",
		name: { default: "Descarga el App MakeMake", translationPath: "corre_voz.tipo_recurso.descarga_app" },
	},
];
