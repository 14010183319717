import React from "react"

/**
 * Componente de casilla de verificación.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.checked - Estado de la casilla de verificación.
 * @param {function} props.onChange - Función de cambio de estado.
 * @param {string} props.focusColor - Color de enfoque para la casilla de verificación.
 * @param {string} props.ariaLabel - Texto alternativo para la casilla de verificación.
 * @returns {JSX.Element} Elemento JSX que representa el componente.
 */
export const CheckBox = ({ checked, onChange, focusColor, ariaLabel }) => {
    return (
            <input
                type={"checkbox"}
                className={`checkbox ${focusColor&&'focus'}`}
                checked={checked}
                aria-label={ariaLabel || 'Checkbox'}
                onChange={(e) => onChange&&onChange(e)} /> 
    )
}
