import { useRef, useEffect, useState } from "react";

/**
 * Hook que permite identificar cuando un elemento esta viendose en pantalla al hacer scroll
 * @param {Element} root - elemento usado como viewport para comprobar la visibilidad del target
 * @param {string} rootMargin - Margen alrededor del elemento root
 * @param {number | numerbe[]} threshold - Numero o arreglo de numeros que indica el porcentaje de visibilidad del target. vade 0 a 1, donde 1 es el 100 del target
 * @param {(arg: any) => void} callback - función si queremos hacer algo diferente
 * @return {[ boolean, ref ]} isIntersectiong - ref, devuelve el valor true o false si esta en pantalla y la referencia del target 
 */
export const useIntersectionScroll = ({
    root,
    rootMargin = "0px",
    threshold = 0,
    callback
}) => {
    const ref = useRef(null);
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const { current } = ref;
        if(!current) return;

        const handleIntersection = ([entry]) =>{
            setIsIntersecting(entry.isIntersecting);
            if(callback) callback(entry.isIntersecting)
        }

        const options ={
            root,
            rootMargin,
            threshold
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        observer.observe(current);

        return()=>current && observer.unobserve(current);

    }, [ref, root, rootMargin, threshold, callback]);

    return [isIntersecting, ref];
}