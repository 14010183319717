import React from "react";

/**
 * Icono del blog
 * @param {string} color -color por defecto de la fuente
 * @param {string} className - clase aplicada al icono
 * @returns {JSX.Element} IconBlog
 */
export const IconBlog = ({color, className}) => {
    return (
        <svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className? className : ''}>
            <path d="M7.25111 2.18691L19.3109 7.38348C20.574 7.92775 21.3036 9.26184 21.0807 10.6198L20.519 14.0421C20.3123 15.3016 19.3314 16.2937 18.0739 16.5149L4.92876 18.827C2.91063 19.182 1.14091 17.4559 1.44613 15.4303L3.09316 4.49926C3.38651 2.5524 5.44394 1.4082 7.25111 2.18691Z" fill={`${color? color : "#262626"}`} />
            <rect width="6.00057" height="8.04749" rx="2" transform="matrix(-0.995819 -0.091346 -0.0928339 0.995682 30.1738 10.1055)" fill={`${color? color : "#262626"}`} />
        </svg>
    )
}