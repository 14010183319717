import React from "react";
import { Link } from "react-router-dom";

/**
 * Componente con las opciones plan de lecturas, mkmk en vivo, blog y centro de recursos
 * @param {Function} setOpenDropdown - actualiza el estado openDropdown
 * @param {boolean} openDropdown - estado que indica si se debe ver las opciones de planes
 * @param {object} textosInterfaz- contiene los textos de interfaz
 * @returns {JSX.Element} DropdownComunidad
 */
export const DropdownComunidad = ({ setOpenDropdown, openDropdown, textosInterfaz }) => {
    const subSecciones = [
        {
            link: "/club-makemake",
            name: textosInterfaz?.club || 'Club MakeMake',
        },
        {
            link: "/club-makemake?section=planes-de-lectura",
            name: textosInterfaz?.plan_lectura || 'Plan de lectura',
        },
        {
            link: "/club-makemake?section=makemake-en-vivo",
            name: textosInterfaz?.mkmk_vivo || 'MakeMake en vivo',
        },
        {
            link: "/blog",
            name: textosInterfaz?.blog || 'Blog',
        },
        {
            link: "/centro-de-recursos",
            name: textosInterfaz?.recursos || 'Centro de recursos',
        }
    ];

    return (
        <ul>
            {
                subSecciones.map((item, i) => {
                    return (
                        <li 
                            key={`comunidad-${i}`}
                            className={`${openDropdown ? 'activeMode' : 'inactiveMode'}`}>
                            <Link
                                to={item?.link || "/club-makemake"}
                                className="focusable-red"
                                onClick={() => setOpenDropdown(false)}
                            >
                                {item?.name || 'opcion'}
                            </Link>
                        </li>
                    )
                })
            }
        </ul>
    )
}