import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import getPromise from '../js/utilities/getPromise';

/**
 * Componente que redirecciona según los datos obtenidos por url. Este no recibe ninguna propiedad.
 */
const BlogRedirection = () => {
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const { date, title } = params;
  const history = useHistory();

  useEffect(() => {
    if (title !== undefined) {
      loadInformation();
    }
  }, [title])

  const loadInformation = async () => {
    setIsLoading(true)
    let idBlog = await getPromise('/assets/obtenerIDBlog', { dateBlog: date })
    idBlog = JSON.parse(idBlog)
    if(idBlog.id !== 0){
        history.push("/blog/" + idBlog.id);
    }
    setIsLoading(false);
  }

  return (
    <>
      {isLoading &&
        <>
          <div className="statistics-preloader" />
        </>
      }
      {!isLoading &&
        <>
          
        </>
      }
    </>
  )
}

export default BlogRedirection;
