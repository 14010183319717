import React, { useEffect, useState } from "react";
import { LazyLoadImage, } from 'react-lazy-load-image-component';
import { Icon } from "@iconify/react";
import PropTypes from 'prop-types'

let loadDelay;

/**
 * Componente para mostrar imágenes con carga diferida.
 * @param {React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>} props 
 * @returns 
 */
export const Img = (props) => {

    const defaultImgUrl = '/img/no-imagen3.png';
    const [errorLoadingSourceImage, setErrorLoadingSourceImage] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(()=>{
        return ()=>{
            if(loadDelay){
                clearTimeout(loadDelay)
            }
        }
    },[])


    return (
        <div className="lazy-image">
            {
                loaded === false &&
                <div className="loading-placeholder">
                    <Icon icon="fa:spinner" width={30} className={`spinner-rotation`} />
                </div>
            }
            <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{opacity:loaded?'1':'0'}}>
            <LazyLoadImage
                {...props}
                useIntersectionObserver
                threshold={props?.threshold || 5}
                src={errorLoadingSourceImage ? props?.defaultImage || defaultImgUrl : props?.src}
                onError={() => {setErrorLoadingSourceImage(true); setLoaded(false)}}
                afterLoad={() => setLoaded(true)}
                visibleByDefault={loaded}
                placeholderSrc={defaultImgUrl}
            />
            </div>
        </div>
    )
}

Img.propTypes = {
    defaultImage: PropTypes.string
}

