import React from "react";//Swiper
import SwiperCore, { Navigation, Pagination, Keyboard, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
import Skeleton from "react-loading-skeleton";

SwiperCore.use([Navigation, Pagination, Keyboard, Virtual]);
const SkeletonBanner = (props) => {
  return (
    <>
      <Swiper
        autoplay={{ delay: 10000 }}
        centeredSlides={true}
        className="home-banners"
        keyboard={true}
        loop={true}
        slidesPerView="auto"
        spaceBetween={20}
      >
        {(() => {
          var banners = [];
          for (let i = 0; i < 5; i++) {
            banners.push(
              <SwiperSlide key={`banner${i}`}>
                <Skeleton className='img-fluid' height={300} />
              </SwiperSlide>
            )
          }
          return banners;
        })()}
      </Swiper>
    </>
  );
}

export default SkeletonBanner;
