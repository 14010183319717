import React from "react";

/**
 * Componente contenedor de la tarjeta del plan. 
 * Solo puede tener 2 tipos de hijos directos, box.columnLeft y box.columnRight
 * @param {JSX.Element} children -hijos directos (box.columnLeft y box.columnRight)
 * @param {string} label - indicador de accesibilidad, indica el tipo de plan
 * @param {string} className - cadena de clases css que se pueden aplicar al componente
 * @returns {JSX.Element} box
 */
const box = ({ children, label, className }) => {
    return (
        <aside
            aria-label={label ? label : 'plan casa'}
            className={`PlanCard-box d-md-flex ${className && className}`}
        >
            {
                React.Children.toArray(children).filter(element => element?.type === columnLeft)
            }
            {
                React.Children.toArray(children).filter(element => element?.type === columnRight)
            }
        </aside>
    )
}


/**
 * Columna izquierda contiene la imagen y el nombre del plan
 * @param {Element} children - Elemento con el nombre e información extra del plan
 * @param {string} className - cadena de clases css que se pueden aplicar al componente. Color de fondo
 * @param {string} image - ruta de la imagen
 * @returns {JSX.Element} columnLeft
 */
const columnLeft = ({ children, className, image }) => {
    return (
        <div className={`col-md-5 px-0 column-left ${className && className}`}>
            <div
                className="img-card order-md-2"
                style={{ backgroundImage: `url(${image ? image : '/img/no-imagen3.png'})` }}
            ></div>
            <div className="order-md-1 p-4 h-50 d-flex align-items-center">
                {children}
            </div>

        </div>
    )
}


/**
 * Columna derecha contiene los detalles, el precio y el botón de compra
 * @param {JSX.Element} children -hijos directos (columnRight.details, columnRight.PriceDescription y columnRight.getButtons)
 * @param {string} className - cadena de clases css que se pueden aplicar al componente. Color de fondo
 * @returns {JSX.element} columnRight
 */
const columnRight = ({ children, className }) => {
    return (
        <div className={`col-md-7 p-3 d-flex flex-column  ${className && className}`}>
            {
                React.Children.toArray(children).filter(element => element?.type === details)
            }

            <div className="d-lg-flex align-items-center justify-content-between mt-auto px-md-3">
                {
                    React.Children.toArray(children).filter(element => element?.type === PriceDescription)
                }

                <div className="">
                    {
                        React.Children.toArray(children).filter(element => element?.type === getButtons)
                    }
                </div>
            </div>
        </div>
    )
}

/**
 * Descripción del plan 
 * @param {element} children - contenido descripción
 * @param {string} className - cadena de clases css que se pueden aplicar al div
 * @returns {JSX.Element} details
 */
const details = ({ children, className }) => {
    return <div className={className && className}>{children}</div>
}

/**
 * Precio del plan 
 * @param {element} children - contenido del precio y descuentos
 * @param {string} className - cadena de clases css que se pueden aplicar al div
 * @returns {JSX.Element} details
 */
const PriceDescription = ({ children, className }) => {
    return (
        <div className={`mb-3 mb-lg-0 ${className && className}`}>
            {children}
        </div>
    )
}

/**
 * botones del plan (trailer y comprar o adquirir)
 * @param {element} children - contenido del botón 
 * @param {string} className - cadena de clases css que se pueden aplicar al div
 * @returns {JSX.Element} details
 */
const getButtons = ({ children, className }) => {
    return (
        <div className={`${className && className} d-flex align-items-center justify-content-lg-end`}>{children}</div>
    )
}

/**
 * Objeto que retorna los componentes que conforman las tarjetas en PLAN CASA
 */
export const PlanCard = {
    box,
    columnLeft,
    columnRight,
    details,
    PriceDescription,
    getButtons
}