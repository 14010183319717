import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PropTypes from 'prop-types';

/**
 * Componente que actua como componente Contenedor del tabPanel, 
 * a partir del currentPanelId muestra el panel correspondiente y oculta los demás.
 */
const Box = ({ children, currentPanelId, className }) => {
    return <div className={className ? className : 'w-100'}>
        {
             React.Children.toArray(children).filter(element=>element?.type===Tabs)
        }
        {
             React.Children.toArray(children).filter(element=>element?.type===Panel).find(panel=>panel?.props?.panelId===currentPanelId)
        }
    </div>;
}

Box.propTypes = {
    /**
     * Solo puede tener 2 tipos de hijos directos, TabPanel.Tabs y TabPanel.Panel
     */
    children:PropTypes.any, 
    /**
     * id del panel que se quiere mostrar, debe coincidir con el id de algun panel dentro del tabPanel, de lo contrario no mostrara nada.
     */
    currentPanelId:PropTypes.string.isRequired, 
    /**
     * cadena de clases css que se quieren aplicar al componente (div)
     */
    className:PropTypes.string
}

/**
 * Contenedor de botones cuyo proposito es cambiar entre paneles.
 */
const Tabs = ({ children, className }) => {
    return <div className={className ? className : `w-100 justify-content-center align-items-center d-flex flex-column flex-md-row`}>
        {React.Children.map(children, element => {
            return element;
        })}
    </div>;
}

Tabs.propTypes = {
    /**
     * Solo puede tener hijos de tipo TabPanel.Tab
     */
    children:PropTypes.any, 
    /**
     * cadena de clases css que se quieren aplicar al componente (div)
     */
    className:PropTypes.string
}


/**
 * botón que se mostrará en el tabpanel, su proposito es cambiar entre paneles.
 */
const Tab = ({ name, className, onClick, children }) => {
    return (<>{children ? children : <button className={className} onClick={onClick}>{name || 'N/A'}</button>}</>)
}
Tab.propTypes = {

    /**
     * Texto que tendrá el botón, solo aplica si no se pasa children.
     */
    name:PropTypes.string, 
    /**
     * función que se ejecuta cuando se da click en el botón, solo aplica si no se pasa children.
     */
    onclick:PropTypes.func, 
    /**
     * Recibe cualquier tipo de elemento html o react component, si recibe este prop onClick, className y name ya no serán utilizados.
     */
    children:PropTypes.any, 
    /**
     * cadena de clases css que se quieren aplicar al componente (button)
     */
    className:PropTypes.string
}

/**
 * Panel que mostrara un contenido especifico pero que solo 
 * se renderizará si el panelId es igual al currentPanelId del TabPanel.Box
 */
const Panel = ({ panelId, children, className }) => {
    return <section className={className ? className : 'w-100'}>
        {children}
    </section>;
}
Panel.propTypes = {

    /**
     * id que tendrá el panel con el proposito de poder reconcer que panel debe mostrarse.
     */
    panelId:PropTypes.string.isRequired, 
    /**
     * Recibe cualquier tipo de elemento html o react component
     */
    children:PropTypes.any, 
    /**
     * cadena de clases css que se quieren aplicar al componente (button)
     */
    className:PropTypes.string
}


/**
 * Objeto que retorna todos los componentes para crear un tabPanel (Box, Tab, Tabs, Panel)
 */
export const TabPanel = {
    Box,
    Tab,
    Tabs,
    Panel
}