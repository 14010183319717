import { Icon } from "@iconify/react";
import React from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

/**
 * Componente de dropdown regular.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.itemsData - Datos de los elementos del dropdown.
 * @param {string} props.title - Título del dropdown.
 * @param {string} props.defaultItemId - ID del elemento seleccionado por defecto.
 * @param {function} props.onChange - Función de cambio de elemento seleccionado.
 * @param {Object} props.style - Estilos para aplicar al componente.
 * @param {string} props.className - Clases CSS adicionales para el componente.
 * @returns {JSX.Element} Elemento JSX que representa el componente.
 */
export const RegularDropdown = ({
    itemsData,
    title,
    defaultItemId,
    onChange,
    style,
    className }) => {

    const [opened, setOpened] = useState(false);
    const ref = useRef(null);
    const [currentItem, setCurrentItem] = useState(null);

    const dropdownButtonRef = useRef(null);

    const changeCurrent = (item)=>{
        setCurrentItem(item);
        setOpened(false);
        dropdownButtonRef.current&&dropdownButtonRef.current.focus()
    }

    const onKeyEnter = (event, callback)=>{
        const key = event.key || event.code || '';
        if(key.toLowerCase().includes('enter')){
            callback();
        }
    }


    useEffect(() => {
        if (defaultItemId) {
            const item = Array.isArray(itemsData) ? itemsData.find(it => it?.id === defaultItemId) : null;
            setCurrentItem(item);
        }

        if(currentItem){
            const item = Array.isArray(itemsData) ? itemsData.find(it => it?.id === currentItem?.id) : currentItem;
            setCurrentItem(item);
        }
    }, [defaultItemId, itemsData])


    useEffect(() => {
        onChange && onChange(currentItem);
    }, [currentItem])


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpened(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);


    return (
        <div
            className={`focusable-by-children-secondary dropdown-component ${opened ? 'visible' : 'hidden'} ${className}`}
            ref={ref}
            style={style}>
            <button
                ref={dropdownButtonRef}
                aria-haspopup="listbox"
                className="dropdown-button"
                aria-expanded={opened}
                onClick={() => setOpened(!opened)}>
                <span>{currentItem?.name || title || 'N/A'}</span>
                <Icon className="caret" icon="ant-design:caret-down-filled" />
            </button>
            <ul 
            role={"listbox"}
            aria-label={title || 'N/A'}
            className="dropdown-content" >
                <li  role={"option"} aria-selected={false} className="dropdown-item-separator">{title || 'N/A'}</li>
                {
                    opened && Array.isArray(itemsData) &&
                    itemsData.map((item, i) => {
                        return <li
                            tabIndex={0}
                            role={"option"}
                            aria-selected={item?.id===currentItem?.id}
                            key={i}
                            className="focusable-secondary dropdown-item"
                            onKeyUp={(e)=> onKeyEnter(e, ()=>changeCurrent(item)) }
                            onClick={() => changeCurrent(item)}
                        >
                            {item?.name || 'N/A'}
                        </li>
                    })
                }
            </ul>
        </div>
    )
}

export const Dropdown = {
    Regular: RegularDropdown,
}