import { useEffect, useRef, useState } from "react";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { BlogAPI } from "../../services/api/Blog";

export const useBlogPosts = () => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const prevAbortController = useRef();

	const fetchPosts = async () => {
		if (prevAbortController.current) {
			prevAbortController.current.abort();
		}
		const abortController = new CancelRequestController();
		prevAbortController.current = abortController;
		setLoading(true);
		await BlogAPI.getPosts({ abortSignal: abortController.signal })
			.then((_posts) => {
				setPosts(_posts.slice(0, 3));
			})
			.catch((err) => {
				setPosts([]);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchPosts();
		return () => {
			if (prevAbortController.current) {
				prevAbortController.current.abort();
			}
		};
	}, []);

	return {
		loading,
		data: posts,
	};
};
